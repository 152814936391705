import { Component, Input, OnChanges } from '@angular/core';
import { ProfileMenuDialogComponent } from '../../dialogs/profile-menu-dialog/profile-menu-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';
import { UtilsService } from 'src/app/core/services/utils.service';
import { DialogName, DialogOpenMessage, MessageBusService } from 'src/app/core/services/message-bus.service';

@Component({
  selector: 'app-logged-in-menu-button',
  templateUrl: './logged-in-menu-button.component.html',
  styleUrls: ['./logged-in-menu-button.component.css']
})
export class LoggedInMenuButtonComponent implements OnChanges {

  @Input() loggedInUser: UserPerson;

  constructor(
    public dialog: MatDialog,
    private utilsService: UtilsService,
    private messageBusService: MessageBusService
  ) { }

  ngOnChanges(): void {
    this.setProfilePhoto();
  }

  private setProfilePhoto(): void {
    if (!this.loggedInUser.ubicacionLogo) {
      let feNacimiento = this.loggedInUser.fechaNacimiento? new Date(this.loggedInUser.fechaNacimiento) : null;
      this.loggedInUser.ubicacionLogo = this.utilsService.getPersonDefaultAvatarUrl(feNacimiento, this.loggedInUser.sexo);
    }
  }

  onMenuClick() {
    const dialogRef = this.dialog.open(ProfileMenuDialogComponent, {
        data: {
          id: 'profile-menu-dialog',
          animationIn: 'public-profile-animation-in',
          animationOut: 'public-profile-animation-out'
        },        
        panelClass: ['profile-menu-dialog', 'public-profile-animation-in']
    });

    this.messageBusService.dialogOpen(new DialogOpenMessage(DialogName.UserProfileMenu));

    dialogRef.afterClosed().subscribe(accepted => {    
      this.messageBusService.dialogClose(new DialogOpenMessage(DialogName.UserProfileMenu));
    });
  }

}
