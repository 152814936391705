import { Component, OnInit } from '@angular/core';
import { ComponentLookup } from '../../decorators/component-lookup.decorator';
import { IControlComponent } from '../../interfaces/control-component';
import { FormComponentData } from '../../models/people/form-control.model';
import { TerritorialDivision } from '../../models/systems/territorial-division.model';
import { ComponentDataSetMessage, ComponentFinishLoadingMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { ComponentName } from '../../enums/component-name.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { TerritorialDivisionsService } from 'src/app/core/services/territorial-divisions.service';
import { AppStorageService, STORAGE } from 'src/app/core/services/app-storage.service';

@Component({
  selector: 'app-country-form-checkout',
  templateUrl: './country-form-checkout.component.html',
  styleUrls: ['./country-form-checkout.component.css']
})
@ComponentLookup('CountryFormCheckoutComponent')
export class CountryFormCheckoutComponent implements IControlComponent, OnInit {
  data: FormComponentData;
  country: TerritorialDivision;

  errorSubject = new BehaviorSubject<string>('');
  get errorObservable(): Observable<string> {
    return this.errorSubject.asObservable();
  }

  constructor(
    private userService: UserService,
    private territorialDivisionService: TerritorialDivisionsService,
    private appStorageService: AppStorageService,
    private messageBusService: MessageBusService
  ) { }

  ngOnInit(): void {
    this.loadCountry();

    this.sendComponentFinishLoadingMessage();
  }

  onCountrySelected(country: TerritorialDivision){

    this.userService.updateUserResidenceCountry(country.idPais).subscribe(result=>{

      // SET COUNTRY APP
      this.territorialDivisionService.setCountryApp(country.idPais);

      // UPDATE USER
      this.userService.getUserPersonLoggedIn().subscribe(user=>{

        if(user){
          user.idPaisResidencia = country.idPais;

          this.userService.updateUserLoggedIn(user);
        }
      });

      let message = new ComponentDataSetMessage();
      message.componentName = ComponentName.SELECT_COUNTRY;
      message.data = country;
  
      this.messageBusService.onComponentDataSetMessage(message);
      
      this.appStorageService.removeSessionItem(STORAGE.SELECT_COUNTRY);
      
    }, error =>{
      
      this.errorSubject.next("Hubo un error al grabar el país, intente nuevamente");
    });
  }

  sendComponentFinishLoadingMessage(){
    let event = new ComponentFinishLoadingMessage();
      event.idFormulario = this.data.idFormulario;
      event.idControl = this.data.idControlPadre;

    this.messageBusService.componentFinishLoading(event);
  }

  private loadCountry() {
    if (this.data && this.data.configurationData) {
      this.country = this.data.configurationData as TerritorialDivision;        
    }     
  }
}
