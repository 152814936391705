<div class="default-back-with-link-header-container">
    <a class="btn-close" (click)="onBackClick()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Volver"></mat-icon> 
    </a>
    <div class="header-open-link-container">
        <a (click)="onOpenInNewWindow()">
            <span>{{ 'shared.defaultBackWithLinkHeader.text1' | translate:locale.language }}</span>            
            <mat-icon fontSet="phr-iconset" class="phr-iconset-new-window"></mat-icon>
        </a>
    </div>
</div>