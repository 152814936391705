
import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { L10nTranslationService } from 'angular-l10n';

@Injectable()
export class MatPaginatorI18nService extends MatPaginatorIntl {
    
    private ofStr : string;

    public constructor(private translation: L10nTranslationService) {
        super();

        this.translation.onChange().subscribe({
            next: () => {        
                this.itemsPerPageLabel = this.translation.translate('paginator.itemPerPage');  
                this.nextPageLabel = this.translation.translate('paginator.nextPage');  
                this.previousPageLabel = this.translation.translate('paginator.previousPage');  
                this.firstPageLabel = this.translation.translate('paginator.firstPage');  
                this.lastPageLabel = this.translation.translate('paginator.lastPage');  
                this.ofStr = this.translation.translate('paginator.of');  
            }
        });  
    }

    public getRangeLabel = (page: number, pageSize: number, length: number): string => {
        if (length === 0 || pageSize === 0) {
            return `0 / ${length}`;
        }

        length = Math.max(length, 0);

        const of = this.ofStr;
        const startIndex: number = page * pageSize;
        const endIndex: number = startIndex < length
            ? Math.min(startIndex + pageSize, length)
            : startIndex + pageSize;

        //return `${startIndex + 1} - ${endIndex} / ${length}`;
        return `${endIndex} ${of} ${length}`;
    }

}