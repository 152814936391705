import { Pipe, PipeTransform } from '@angular/core';
import { LayoutService } from 'src/app/core/services/layout.service';

@Pipe({
  name: 'i18nText'
})
export class I18nTextPipe implements PipeTransform {

  constructor(private layoutService: LayoutService){}

  transform(value: any, language:string): unknown {
    return this.layoutService.getTextByLanguage(value, language);
  }
}
