import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { AuthOidcService } from 'src/app/core/services/auth-oidc.service';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { OAuthFlowType, OAuthSourceType, TokenState } from 'src/app/shared/models/token.model';

@Component({
  selector: 'app-login-form-google',
  templateUrl: './login-form-google.component.html',
  styleUrls: ['./login-form-google.component.css']
})
export class LoginFormGoogleComponent implements OnInit {

  @Input() username:string;

  loadingByGoogle: boolean = false;
  returnUrl: string;

  constructor(
    private route: ActivatedRoute,
    private authOidcService: AuthOidcService,
    private workflowService: WorkflowService,
    private translation: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale

  ) { }

  ngOnInit(): void {
    this.getQsParams();
  }

  getQsParams(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];

    if (this.returnUrl)
      this.returnUrl = decodeURIComponent(this.returnUrl);
  }

  loginByGoogle(){
    this.loadingByGoogle = true;

    var url = this.returnUrl ? this.returnUrl : "/";

    let oidcModel: TokenState;
    let workflowType = this.workflowService.getWorkflowTypeActive();

    if (workflowType) {
      oidcModel = this.workflowService.getTokenStateFromWorkflow();
    }
    else {
      oidcModel = new TokenState();
    }

    oidcModel.returnUrl = url;
    oidcModel.flowType = OAuthFlowType.Login;
    oidcModel.sourceType = OAuthSourceType.Google;

    let stringState = JSON.stringify(oidcModel);

    this.authOidcService.startAuthentication(stringState, this.username);
  }

}
