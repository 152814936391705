<div class="chat-history-dialog-container">

    <div class="close">
        <a (click)="onCloseClick()">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-close icon-close"></mat-icon>    
        </a>
    </div>

    <!--Chat Header -->
    <div class="chat-history-dialog-header">
        <div class="header-title">{{getChatDate(chatDate, true) | uppercase }}</div>
        <!--<div class="header-subtitle">
            Tiempo total:
            <span>
                {{timeElapsed}}
            </span>
        </div>-->
        <div class="loading" *ngIf="loading">
            <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
        </div>
    </div>

    <div mat-dialog-content>
        <!--Chat Main Container -->
        <div class="chat-history-msgs-container" [ngClass]="{'loading': loading}"
             infiniteScroll [fromRoot]="true" [infiniteScrollDistance]="2" [infiniteScrollContainer]="'.chat-history-msgs-container'" 
             [infiniteScrollThrottle]="50" (scrolledUp)="onScrollUp()" >
            <div *ngFor="let messageWrapper of chatWrapper.messages; index as i">          
            
                <div class="date-timestamp" *ngIf="chatWrapper.showDateStamp(i)">
                    {{ getChatDate(messageWrapper.message.fechaCreacion) }}
                </div>

                <!--User Message -->
                <div class="user-message" *ngIf="messageWrapper.isUserMessage()" [ngClass]="{'user-msg-block': chatWrapper.isUserBlockMessage(i)}">              
                    <div class="message-box">
                        <span class="message-text" [innerHtml]="messageWrapper.message.valor"></span>
                    </div>  
                    <span class="message-date" *ngIf="chatWrapper.showMessageTime(i)">
                        {{messageWrapper.message.fechaCreacion | date:'shortTime' }}
                    </span> 
                </div>

                <!--Agent Message -->
                <div class="agent-message" *ngIf="!messageWrapper.isUserMessage()" [ngClass]="{'agent-msg-block': chatWrapper.isAgentBlockMessage(i)}">              
                    <img class="agent-img" [src]="messageWrapper.message.urlImagenEmisor"/>
                    <span class="agent-msg">
                        <span class="agent-name">{{chatWrapper.getDoctorName()}}</span>  
                        <div class="message-box">
                            <span class="message-text" [innerHtml]="messageWrapper.message.valor"></span>
                        </div>  
                        <span class="message-date" *ngIf="chatWrapper.showMessageTime(i)">{{messageWrapper.message.fechaCreacion | date:'shortTime' }}</span>
                    </span>               
                </div>
            </div> 
        </div>
    </div>    
</div>

