import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { PublicProfileAvatarComponentModel } from '../../../public-profile-avatar/public-profile-avatar.component';
import { ProfileResultModel, PublicProfileType } from 'src/app/shared/models/people/profile-search-results.model';
import { BaseService } from 'src/app/core/services/base.service';
import { FeatureConceptDto } from '../provider-card.component';
import { TextLanguage } from 'src/app/shared/models/people/text-language.model';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

@Component({
  selector: 'app-provider-card-profile',
  templateUrl: './provider-card-profile.component.html',
  styleUrls: ['./provider-card-profile.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProviderCardProfileComponent implements OnChanges {

  @Input() isClinicProfile = false;
  @Input() isDoctorProfile = true;
  @Input() set profile(provider: ProfileResultModel) {
    this.provider = provider;   
  }

  avatarModel: PublicProfileAvatarComponentModel;
  featureConceptIconClassName = 'phr-iconset-';
  featuresConceptsText: string | TextLanguage[] = '';
  isCliniwebCompany = false;
  isPharmacyProfile = false;
  provider = new ProfileResultModel();
  profileTypeIconClassName = '';
  showAvatarInitials = false;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private baseService: BaseService
  ) {
    this.isCliniwebCompany = this.baseService.isCliniwebCompany();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.profile) {
      this.setAvatarModel();
      this.setIsPharmacyProfile();
      this.setFeatureConcepts();
      this.setShowAvatarInitials();
      this.setProfileTypeIconClassName();
    }
  }

  private setAvatarModel(): void {
    this.avatarModel = new PublicProfileAvatarComponentModel();
    this.avatarModel.personId = this.provider.idPersona;
    this.avatarModel.avatarUrl = this.provider.foto;
    this.avatarModel.fullName = this.provider.nombre;
    this.avatarModel.sex = this.provider.sexo;
    this.avatarModel.profileType = this.provider.tipoPerfil; 
  }

  private setShowAvatarInitials(): void {
    this.showAvatarInitials = !!this.provider.foto || this.provider.tipoPerfil == PublicProfileType.Doctor;
  }

  private setIsPharmacyProfile(): void {
    this.isPharmacyProfile = this.provider.tipoPerfil == PublicProfileType.Farmacia;
  }

  private setFeatureConcepts(): void {
    try{
      const featuresConceptsObject: FeatureConceptDto = JSON.parse(this.provider.conceptosDestacados);

      this.featureConceptIconClassName = featuresConceptsObject && featuresConceptsObject.iconClassName ? `phr-iconset-${featuresConceptsObject.iconClassName}` : '';
      this.featuresConceptsText = featuresConceptsObject && featuresConceptsObject.text ? featuresConceptsObject.text : '';
    } catch(e) {
      this.featureConceptIconClassName = '';
      this.featuresConceptsText = '';
    }
  }

  private setProfileTypeIconClassName(): void {
    const profileType = this.provider.tipoPerfil;
    this.profileTypeIconClassName = `phr-iconset-${this.getProfileTypeIconClassName(profileType)}`;
  }

  private getProfileTypeIconClassName(profileType: PublicProfileType): string {
    return profileTypeIconMap[profileType] || 'estetoscopio';
  }

}

export const profileTypeIconMap: { [key in PublicProfileType]: string } = {
  [PublicProfileType.Doctor]: 'estetoscopio',
  [PublicProfileType.Farmacia]: 'farmacia',
  [PublicProfileType.Clinica]: 'hospital',
  [PublicProfileType.Fisioterapia]: 'fisioterapia',
  [PublicProfileType.Laboratorio]: 'laboratorios',
  [PublicProfileType.CentroDeImagenes]: 'imagenologia',
  [PublicProfileType.ServiciosOdontologicos]: 'odontologia',
  [PublicProfileType.Opticas]: 'open-eye',
  [PublicProfileType.ClinicasDeAtencionPrimaria]: 'clinic',
  [PublicProfileType.Todos]: '',
  [PublicProfileType.SedeSura]: '',
  [PublicProfileType.SaludYBelleza]: ''
}; 
