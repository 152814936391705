<div class="most-common-specialties-container">
    <mat-card class="card">
        
        <mat-card-header>
            <mat-card-title>
                {{ 'shared.mostCommonSpecialties.text1' | translate:locale.language }}
            </mat-card-title>
            <mat-card-subtitle>
                {{ 'shared.mostCommonSpecialties.text2' | translate:locale.language }}
            </mat-card-subtitle>
        </mat-card-header>
        
        <mat-card-content>
            <div class="box-container">
                <div class="box" (click)="onDentistClick()">
                    <div class="picture">
                        <img src="assets/images/specialties/odontologia.png">
                    </div>
                    <div class="specialty">
                        {{ 'shared.mostCommonSpecialties.text3' | translate:locale.language }}
                    </div>
                    <div class="description">
                        {{ 'shared.mostCommonSpecialties.text4' | translate:locale.language }}
                    </div>
                </div>
                <div class="box" (click)="onNutritionClick()">
                    <div class="picture">
                        <img src="assets/images/specialties/nutricion.png">
                    </div>
                    <div class="specialty">
                        {{ 'shared.mostCommonSpecialties.text5' | translate:locale.language }}
                    </div>
                    <div class="description">
                        {{ 'shared.mostCommonSpecialties.text6' | translate:locale.language }}
                    </div>
                </div>
                <div class="box" (click)="onGynecologyClick()">
                    <div class="picture">
                        <img src="assets/images/specialties/ginecologia.png">
                    </div>
                    <div class="specialty">
                        {{ 'shared.mostCommonSpecialties.text7' | translate:locale.language }}
                    </div>
                    <div class="description">
                        {{ 'shared.mostCommonSpecialties.text8' | translate:locale.language }}
                    </div>
                </div>
                <div class="box" (click)="onPediatricsClick()">
                    <div class="picture">
                        <img src="assets/images/specialties/pediatria.png">
                    </div>
                    <div class="specialty">
                        {{ 'shared.mostCommonSpecialties.text9' | translate:locale.language }}
                    </div>
                    <div class="description">
                        {{ 'shared.mostCommonSpecialties.text10' | translate:locale.language }}
                    </div>
                </div>                
            </div>
        </mat-card-content>
    </mat-card>   
</div>