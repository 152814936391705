<div class="reset-password-container" [ngClass]="{'directory-background': changePassword}" [hidden]="licenseLoading">
    <div *ngIf="showCustomHeader" class="reset-password-header">
        <a class="btn-close" [routerLink]="['/login']">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Volver"></mat-icon> 
        </a>
    </div>
    <mat-card class="card-container" *ngIf="!isRequestExpired && !isRequestCompleted">
        
        <mat-card-header>
            <mat-card-title>{{'resetPassword.title' | translate:locale.language }}</mat-card-title>
        </mat-card-header>
        
        <mat-card-content>
            
            <form class="reset-password-form" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                
                <mat-form-field appearance="outline" *ngIf="changePassword">
                    <mat-label>{{'resetPassword.currentPassword' | translate:locale.language}}</mat-label>
                    <input matInput 
                        [type]="showCurrentPassword ? 'text' : 'password'" 
                        formControlName="currentPassword" 
                        placeholder="{{ 'resetPassword.currentPassword' | translate:locale.language }}">
                    <button type="button" mat-icon-button matSuffix (click)="showCurrentPassword = !showCurrentPassword">
                        <mat-icon>{{showCurrentPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="isControlInvalid('currentPassword') && resetPasswordForm.controls['currentPassword'].hasError('required')">{{'resetPassword.currentPasswordRequired' | translate:locale.language}}</mat-error>
                    <mat-error *ngIf="isControlInvalid('currentPassword') && resetPasswordForm.controls['currentPassword'].hasError('incorrect')">{{'resetPassword.currentPasswordInvalid' | translate:locale.language}}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'resetPassword.password' | translate:locale.language}}</mat-label>
                    <input matInput 
                        [type]="showPassword ? 'text' : 'password'" 
                        formControlName="password" 
                        placeholder="{{ 'resetPassword.password' | translate:locale.language }}">
                    <button type="button" mat-icon-button matSuffix (click)="showPassword = !showPassword">
                        <mat-icon>{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                    </button>
                </mat-form-field>
                
                <div class="validation-wrapper">
                    <span>{{ 'resetPassword.passwordValidationText' | translate:locale.language }}</span>
                    <div class="item-validation" [ngClass]="(submitted || resetPasswordForm.controls['password'].touched)
                        ? resetPasswordForm.controls['password'].hasError('required') || resetPasswordForm.controls['password'].hasError('minlength')
                            ? 'error' 
                            : 'success'
                        : ''">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-ok-circle"></mat-icon>
                        <span>{{ 'resetPassword.characterLengthRequired' | translate:locale.language }}</span>
                    </div>
                    <div class="item-validation" [ngClass]="(submitted || resetPasswordForm.controls['password'].touched)
                        ? resetPasswordForm.controls['password'].hasError('required') || resetPasswordForm.controls['password'].hasError('pattern')
                            ? 'error' 
                            : 'success'
                        : ''">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-ok-circle"></mat-icon>
                        <span>{{ 'resetPassword.numberAndLetterRequired' | translate:locale.language }}</span>
                    </div>
                    <div *ngIf="resetPasswordForm.controls['password'].hasError('whitespace')" class="item-validation" [ngClass]="(submitted || resetPasswordForm.controls['password'].touched)
                        ? resetPasswordForm.controls['password'].hasError('required') || resetPasswordForm.controls['password'].hasError('whitespace')
                            ? 'error' 
                            : 'success'
                        : ''">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-ok-circle"></mat-icon>
                        <span>{{ 'resetPassword.whiteSpaceRequired' | translate:locale.language }}</span>
                    </div>
                </div>

                <button mat-button mat-flat-button class="primary" [ngClass]="{'loading': loading}">                    
                    <span *ngIf="!loading && !changePassword">{{ 'resetPassword.buttonSubmit' | translate:locale.language }}</span>
                    <span *ngIf="!loading && changePassword">{{ 'resetPassword.buttonChangePasswordSubmit' | translate:locale.language }}</span>
                </button>

                <span *ngIf="error">{{error}}</span>

                <a class="init-session-link" *ngIf="!changePassword" [routerLink]="['/login']">{{ 'resetPassword.initSession' | translate:locale.language }}</a>
            </form>

        </mat-card-content>
    </mat-card>
   
    <!-- Expired/Completed request -->
    <div *ngIf="isRequestExpired || isRequestCompleted">
        <app-states-request-verify [model]="statesRequestVerifyDataModel"></app-states-request-verify>
    </div>    
</div>