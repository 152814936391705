<div [hidden]="!isFormVisible()">

    <!-- Form title -->
    <h3 class="form-title primary-color" *ngIf="!formConfiguration || formConfiguration.showFormTitle">{{form.nombre| i18nText:locale.language}}</h3>

    <!-- Form subtitle -->
    <p class="form-subtitle" *ngIf="(!formConfiguration || formConfiguration.showFormSubtitle) && form.descripcion">{{form.descripcion | i18nText:locale.language}}</p>

    <!-- Form Controlls -->
    <ng-container *ngFor="let control of controles">    
        <div class="control-container" class="{{control.className}}" *ngIf="leakedComponents(control.componentes).length > 0" [hidden]="!control.isVisible()">
            
            <!-- Control title -->
            <h5 class="control-title primary-color" *ngIf="(!formConfiguration || formConfiguration.showControlTitle) && control.titulo">{{control.titulo | i18nText:locale.language}}</h5>
            
            <div class="component-container" dir="ltr">
                <!-- Control components -->
                <div *ngFor="let component of leakedComponents(control.componentes)"
                    [ngClass]="{'component-link': component.tipo == tipoComponente.LINK}"
                    [style.flex]="getFlexStyle(control)"
                    [hidden]="!component.isVisible()">
                    <app-dynamic [componentName]="component.nombre" [componentData]="getComponentData(component)"></app-dynamic>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<ng-container *ngIf="isAnonymousComponentVisible()">
    <app-dynamic [componentName]="anonymousComponentName"></app-dynamic>
</ng-container>

