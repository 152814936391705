<!-- User menu -->
<app-logged-in-menu-button *ngIf="loggedInUser && (showLoggedInMenu | async)" [loggedInUser]="loggedInUser"></app-logged-in-menu-button>

<div *ngIf="!loggedInUser" class="anonymous-menu-container">

    <!-- Language -->
    <app-anonymous-language-menu></app-anonymous-language-menu>

    <!-- Menu -->
    <app-anonymous-login-signup-menu *ngIf="showAnonymousMenu"></app-anonymous-login-signup-menu>

</div>