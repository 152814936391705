import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { IStepFlow } from "../../../interfaces/step-flow";
import { WorkflowItem } from "../../systems/workflow.model";
import { OnlineAppointmentCliniwebState } from "../states/online-appointment-cliniweb-state.model";
import { AppointmentInfoDataModel } from "../models/appointment-info-data.model";
import { BaseStep } from "./base-step.model";
import { ComponentDataSetMessage, MessageBusService, ServiceErrorMessage } from "src/app/core/services/message-bus.service";
import { ComponentName } from "src/app/shared/enums/component-name.enum";
import { PatientContactFormDataModel } from "../models/patient-contact-form-data.model";
import { UserService } from "src/app/core/services/user.service";

@Injectable({
    providedIn: 'root',
})
export class PatientContactFormAppointmentCheckoutStep extends BaseStep<OnlineAppointmentCliniwebState> {

    constructor(
        protected messageBusService: MessageBusService,
        private userService: UserService
    ){
        super(messageBusService);
        
        this.showHeader = true;
        this.showButtonBack = true;
        this.showProgressBar = true;
        this.showButtonClose = true;
    }

    setItem(item: WorkflowItem): void {
        this.item = item;
    }

    setState(stateData: any): void {
        this.stateData = new OnlineAppointmentCliniwebState(stateData);
    }

    isCompleted(): Observable<boolean> {
        let contactType = this.stateData.getContactType();

        switch(contactType){
            case 0:
            default:
                return of(false);
            case 1:
                let userPhone = this.stateData.getUserPhone();
                return of(userPhone ? true : false);
            case 2:
                let patientPhone = this.stateData.getPatientPhone();
                let patientEmail = this.stateData.getPatientEmail();
    
                return of((patientPhone && patientEmail) ? true : false);
        }
    }

    showStepInActionBack(): boolean {
        return true;
    }
    
    showStep(): boolean {
        return true;
    }

    protected handleComponentSetDataMessage(message: ComponentDataSetMessage) {
        if (message.componentName == ComponentName.PATIENT_CONTACT_FORM){

            let dataModel = message.data as PatientContactFormDataModel;

            this.stateData.setContactType(dataModel.contactType);

            if(dataModel.contactType == 1){
                this.stateData.setUserPhone(dataModel.userPhone);
                this.stateData.setPatientPhone(dataModel.userPhone);
                this.updateUserPhoneNumberLoggedIn(dataModel.userPhone);
            }
            else{
                this.stateData.setPatientPhone(dataModel.patientPhone);
                this.stateData.setPatientEmail(dataModel.patientEmail);
            }
        }
    }

    protected buildComponentConfigurationData() : { [componentName: string]: any; } {
        let formConfigurationData : { [componentName: string]: any; } = {};
        
        formConfigurationData[ComponentName.PATIENT_CONTACT_FORM] = this.getPatientContactFormDataModel();

        return formConfigurationData;
    }

    protected messageForThisStep(message: ComponentDataSetMessage): boolean {
        return message.componentName == ComponentName.PATIENT_CONTACT_FORM;
    }

    private getPatientContactFormDataModel(): PatientContactFormDataModel{
        let dataModel = new PatientContactFormDataModel();

        dataModel.appointmentInfo = this.stateData.getAppointmentInfo();
        dataModel.patientRelationship = this.stateData.getPatientRelationship();
        dataModel.userPhone = this.stateData.getUserPhone();
        dataModel.patientName = this.stateData.getPatientName();
        dataModel.patientPhone = this.stateData.getPatientPhone();
        dataModel.patientEmail = this.stateData.getPatientEmail();
        dataModel.contactType = this.stateData.getContactType();

        return dataModel;
    }

    private updateUserPhoneNumberLoggedIn(phoneNumber: string){
        this.userService.getUserPersonLoggedIn()
        .subscribe(user=>{           
            if(user){
                user.numeroTelCelular = phoneNumber;
                this.userService.updateUserLoggedIn(user);
            }
        },
        error=>{
            this.messageBusService.serviceError(new ServiceErrorMessage("Error getting user logged in"));
        });
    }
}

export interface IPatientContactFormAppointmentCheckoutWorkflowState{
    getAppointmentInfo():AppointmentInfoDataModel;
    getPatientName():string;
    getUserPhone():string;    
    getPatientPhone(): string;    
    getPatientEmail():string;    
    getContactType():number;    

    setUserPhone(userPhone:string):void;
    setPatientPhone(patientPhone:string):void;
    setPatientEmail(patientEmail:string):void;
    setContactType(contactType: number):void;
}