import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-workflow-progress-bar',
  templateUrl: './workflow-progress-bar.component.html',
  styleUrls: ['./workflow-progress-bar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WorkflowProgressBarComponent implements OnInit {

  @Input() model: WorkflowProgressBarComponentData;

  constructor() { }

  ngOnInit(): void {
  }

}

export class WorkflowProgressBarComponentData {
  show: boolean;
  showButtonBack: boolean;
  showButtonClose: boolean;
  showProgressBar: boolean;
  percentComplete: number = 0;
  backFn: any;
  closeFn: any;
}