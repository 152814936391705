import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base-service';
import { PeopleSupportRequest } from 'src/app/shared/interfaces/people-support-request';
import { Observable } from 'rxjs';
import { PatientPartial } from 'src/app/shared/interfaces/patient-partial';
import { IdentifierPatient } from 'src/app/shared/models/people/identifier-patient.model';
import { Relation } from 'src/app/shared/models/people/relation.model';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  private baseUrl = 'api/pacientes';

  constructor(private http: HttpBaseService) { }

  getPartial(idPaciente: number): Observable<PatientPartial> {
    return this.http.get<PatientPartial>(`${this.baseUrl}/partial?idPaciente=${idPaciente}`);
  }

  getIdentifierPatient(idPacientePortal: number, idEmpresaPhr: number){
    return this.http.get<IdentifierPatient>(`${this.baseUrl}?idPacientePortal=${idPacientePortal}&idEmpresaPhr=${idEmpresaPhr}`, null, false);
  }

  getRelations(idPaciente: number){
    return this.http.get<Relation[]>(`${this.baseUrl}/${idPaciente}/relaciones`);
  }

  postSupport(model: PeopleSupportRequest) {
    const url = `${this.baseUrl}/soporte`;
    return this.http.post(url, model, null, true);    
  }

}
