<div class="cliniweb-appointment-tabs-container">
    <div class="title">{{ 'shared.cliniwebAppointmentTabs.text1' | translate:locale.language }}</div>

    <mat-tab-group class="primary" animationDuration="0ms" color="accent">
        
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="tab-header">                    
                    <span class="tab-title">{{ 'shared.cliniwebAppointmentTabs.text2' | translate:locale.language }}</span>      
                </div>                          
            </ng-template>    
            <div class="tab-content">
                <app-cliniweb-future-appointment></app-cliniweb-future-appointment>
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>     
                <div class="tab-header">                    
                    <span class="tab-title">{{ 'shared.cliniwebAppointmentTabs.text3' | translate:locale.language }}</span> 
                </div>                               
            </ng-template> 
            
            <!-- Lazy load this tab content (it will be loaded when clicking the tab) -->
            <ng-template matTabContent>
                <div class="tab-content">  
                    <!--<app-past-appointment-container></app-past-appointment-container>-->      
                    <app-cliniweb-past-appointment></app-cliniweb-past-appointment>        
                </div>
            </ng-template>
            
        </mat-tab>  

    </mat-tab-group>
</div>






