import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WorkflowService } from '../services/workflow.service';
import { STORAGE } from '../services/app-storage.service';
import { MedicalOrderWorkflowState } from 'src/app/shared/models/workflow/states/medical-order-workflow-state.model';

@Injectable()
export class OidcWorkflowInterceptor implements HttpInterceptor {

  constructor(private workflowService: WorkflowService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const workflowType: string = this.workflowService.getWorkflowTypeActive();
    let request2: HttpRequest<any> = request.clone();
    if (workflowType == STORAGE.MEDICAL_ORDER_WORKFLOW_STATE || workflowType == STORAGE.GROWTH_CURVES_WORKFLOW_STATE) {
        this.procesarMedicalOrderWorklow(request2);
    }
    return next.handle(request2);
  }

  private procesarMedicalOrderWorklow(request: HttpRequest<any>) {
    if (request.url.includes('api/authentication/oidc')) {
        const workflowState: MedicalOrderWorkflowState = this.workflowService.getWorkflowDataState();
        const idVerificacionSolicitudPorVerificar = workflowState.getIdVerificacion();
        request.body.idVerificacionSolicitudPorVerificar = idVerificacionSolicitudPorVerificar;
    }
  }
}
