import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { MatDialog } from '@angular/material/dialog';
import { TimelineWrapperItem, TimelineWrapperItemType, TimelineWrapperSkeletonItem } from '../../timeline-list/timeline-list.component';
import { VirtualAttention } from 'src/app/shared/models/process/virtual-attention.model';
import { VirtualAttentionService } from 'src/app/core/services/virtual-attention.service';
import { BaseService } from 'src/app/core/services/base.service';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { ChatHistoryDialogComponent } from '../../dialogs/chat-history-dialog/chat-history-dialog.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-past-virtual-consultation-container',
  templateUrl: './past-virtual-consultation.component.html',
  styleUrls: ['./past-virtual-consultation.component.css']
})
export class PastVirtualConsultationComponent implements IControlComponent, OnInit, OnDestroy, AfterViewInit  {
  private ngUnsubscribe = new Subject();
  data: FormComponentData;  
  consultations: TimelineVirtualAttention[];  
  completedStateName: string;
  notCompletedStateName: string;
  cancelledStateName: string;
  loading = true;
  skeletonItems: TimelineWrapperSkeletonItem[] = [new TimelineWrapperSkeletonItem(), new TimelineWrapperSkeletonItem()];

  ngAfterViewInit() {    
  }  

  constructor(
    private virtualAttentionService: VirtualAttentionService, 
    private translation: L10nTranslationService,    
    private baseService: BaseService,
    public dialog: MatDialog,
    @Inject(L10N_LOCALE) public locale: L10nLocale) {
  }
  
  ngOnInit(): void {
    this.translation.onChange()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
      next: () => {
          this.completedStateName = this.translation.translate('shared.pastAppointments.text2');                    
          this.notCompletedStateName = this.translation.translate('shared.pastAppointments.text3');      
          this.cancelledStateName = this.translation.translate('shared.pastAppointments.text7');                       
      }
    });

    this.loading = true;

    this.virtualAttentionService.getPastAttentions()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(consultations =>{
      this.consultations = consultations.map(a => new TimelineVirtualAttention(a));

      this.loading = false;
    },
    error => {
      this.loading = false;

      this.baseService.handleServiceError(error, "Error getting past virtual consultations");      
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  appointmentTrackBy(index : number, item: TimelineVirtualAttention){
    return item.consultation.idSolicitud; 
  } 

  getAvatarUrl(item : VirtualAttention) {
    return item.data? item.data.urlAvatarPaciente : "";
  }

  getStateClass(item : VirtualAttention) {
    if (item.idEstado == 7)
      return "cancelled";    
    else 
      return "completed";
  }

  getStateName(item : VirtualAttention) {
    if (item.idEstado == 7)
      return this.cancelledStateName;   
    else 
      return this.completedStateName;
  }

  isIncomplete(item : VirtualAttention) {
    return item.idEstado == 7;
  }

  openChatHistory(virtualAttention: VirtualAttention) {

    let dialogData = new DialogData();
    dialogData.data = virtualAttention;

    const dialogRef = this.dialog.open(ChatHistoryDialogComponent, {
      data: dialogData,
      panelClass: 'chat-history-modal-container'
      //autoFocus: false //disable auto focus in dialog
    });
  }

  navigateToVirtualConsultationPage() {
    window.location.reload();
  }
}

export class TimelineVirtualAttention implements TimelineWrapperItem {  
  consultation : VirtualAttention;

  constructor(consultation : VirtualAttention) {
    this.consultation = consultation;
  }
  
  getGroupKey() : number {
    return new Date(this.consultation.fechaInicio).getFullYear();
  }

  getDate() : Date {
    return new Date(this.consultation.fechaInicio);
  }

  getItemType(): TimelineWrapperItemType{
    return this.consultation.idEstado == 7 
      ? TimelineWrapperItemType.CANCELLED
      : TimelineWrapperItemType.CHECKED;
  }

  showTime():boolean{
    return true;
  }

  showDate():boolean{
    return true;
  }
}