<div class="header-container" [ngClass]="routeClassName">
    <mat-toolbar class="header-toolbar">
        <button mat-icon-button (click)="openMenuMobile()">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-menu icon"></mat-icon>
        </button>
        <img src="/assets/images/mapfre/logo-mapfre-blanco.svg"/>

        <!-- Anonymous Menu -->
        <app-anonymous-menu></app-anonymous-menu>
    </mat-toolbar>
</div>
<div class="header-desktop-container" [ngClass]="routeClassName">
    <!-- Anonymous Menu -->
    <app-anonymous-menu></app-anonymous-menu>
</div>