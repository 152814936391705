<div class="container">

    <h1>{{ 'policiesSura.text1' | translate:locale.language }}</h1>
 
    <p>
        {{ 'policiesSura.text2' | translate:locale.language }}
    </p>
     
    <h2>{{ 'policiesSura.text3' | translate:locale.language }}</h2>
    <p>{{ 'policiesSura.text4' | translate:locale.language }}</p>
    
     <h2>{{ 'policiesSura.text5' | translate:locale.language }}</h2>
     <p>
        {{ 'policiesSura.text6' | translate:locale.language }}
     </p> 
    
    
     <h2>{{ 'policiesSura.text7' | translate:locale.language }}</h2>
     <p>{{ 'policiesSura.text8' | translate:locale.language }}</p>
     <p>{{ 'policiesSura.text9' | translate:locale.language }}</p>
     <p>{{ 'policiesSura.text10' | translate:locale.language }}</p>
     <p>{{ 'policiesSura.text11' | translate:locale.language }}</p>
     <p>{{ 'policiesSura.text12' | translate:locale.language }}</p>
     <p>{{ 'policiesSura.text13' | translate:locale.language }}</p>
     
    
    <h2>{{ 'policiesSura.text14' | translate:locale.language }}</h2> 
    <p>{{ 'policiesSura.text15' | translate:locale.language }}</p>
     
    <h2>{{ 'policiesSura.text16' | translate:locale.language }}</h2>    
    <p>{{ 'policiesSura.text17' | translate:locale.language }}</p>
    <p>{{ 'policiesSura.text18' | translate:locale.language }}</p>    
    <p><b>{{ 'policiesSura.text19' | translate:locale.language }}</b>{{ 'policiesSura.text20' | translate:locale.language }}</p>
    <p>{{ 'policiesSura.text21' | translate:locale.language }}</p>
    <p>{{ 'policiesSura.text22' | translate:locale.language }}</p>   

</div>

