<div class="provider-card-location-container">

    <div class="location-wrapper" (click)="goToProfileEvent($event)">

        <mat-icon fontSet="phr-iconset" class="{{locationIconClass}} icon"></mat-icon>

        <div class="location-title" [title]="locationTitle" *ngIf="isDoctorProfile">
            {{ locationTitle }}
        </div>

        <div class="location-availability" [title]="locationAvailability" *ngIf="isDoctorProfile && location.teleconsulta != 'S'">
            {{ locationAvailability | translate:locale.language }}
        </div>

        <span class="location-address" [title]="locationAddress">{{locationAddress}}</span>

        <div *ngIf="location.teleconsulta == 'S'">
            <span *ngIf="location.citaEnLineaHabilitada == 'N'" class="location-address">
                {{ 'shared.searchResults.providerCard.bookAppointmentByPhone' | translate:locale.language }}
            </span>
            <a *ngIf="location.citaEnLineaHabilitada == 'S'" class="see-attention-hours-link" (click)="goToProfileEvent($event)">
                {{ 'shared.searchResults.providerCard.seeAttentionHours' | translate:locale.language }}
            </a>
        </div>

        <button mat-button (click)="onClickHowToGetButton(location, $event)" *ngIf="location.teleconsulta != 'S'">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-nav"></mat-icon>
            {{ 'shared.searchResults.providerCard.howToGetButton' | translate:locale.language }}
        </button>

    </div>

</div>
