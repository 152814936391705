<div class="loading-dialog-wrapper" ngClass.lt-md="mobile">
    
    <div class="header">
        <mat-icon fontSet="phr-iconset" class="header-icon {{data.headerIconClass}}" *ngIf="data.showHeaderIcon"></mat-icon>          
    </div>

    <div mat-dialog-content>        
        <!-- Spiner -->
        <mat-spinner class="loading-spinner" *ngIf="data.loading" diameter="90" color="primary"></mat-spinner>

        <div class="title" innerHtml="{{ data.title }}"></div>
        <div class="description" innerHtml="{{ data.description }}"></div>        
    </div>

    <div *ngIf="data.showCancelButton || data.showConfirmButton" mat-dialog-actions 
        fxLayout="row" 
        fxLayout.lt-md="column-reverse" 
        fxLayoutAlign="center center" 
        fxLayoutGap="40px"
        fxLayoutGap.lt-md="20px">
        <button mat-button mat-stroked-button
            [color]="data.colorConfirmButton ? data.colorCancelButton : 'primary'"
            (click)="cancel()"
            *ngIf="data.showCancelButton">
            {{ data.cancelText | uppercase }}
        </button>
        <button mat-button mat-raised-button 
            [color]="data.colorConfirmButton ? data.colorConfirmButton : 'primary'" 
            (click)="confirm()" 
            *ngIf="data.showConfirmButton">
            {{ data.confirmText | uppercase }}
        </button>
    </div>
</div>
