<div class="past-appointment-container">
  
  <app-timeline-list-container [items]="appointments" [skeletonItems]="skeletonItems" [loading]="loading">    
    
    <!-- Skeleton -->
    <ng-template let-item="item" #TimelineSkeletonContent>
      <mat-card class="past-appointment-card">               
        <mat-card-content>
          <div class="past-app-doctor-container">
            <span class="past-app-doctor-img">
              <ngx-skeleton-loader [theme]="{ 'width': '50px', 'height': '50px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '50%'}"></ngx-skeleton-loader>
            </span>            
            <div class="past-app-doctor-right-container">
                <div class="past-app-doctor-specialty">
                  <ngx-skeleton-loader [theme]="{ 'width': '140px', 'height': '14px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>
                </div>
                <div class="past-app-doctor-name">
                  <ngx-skeleton-loader [theme]="{ 'width': '80px', 'height': '14px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>                  
                </div>                                
            </div>
            <a class="past-app-cancel-action">
              <ngx-skeleton-loader [theme]="{ 'width': '150px', 'height': '14px', 'background-color': 'rgba(0, 174, 199, 0.3)', 'border-radius': '2px'}"></ngx-skeleton-loader>                                
            </a>
          </div>
          
          <div class="location-container">
            <div class="location-name">
              <ngx-skeleton-loader [theme]="{ 'width': '100px', 'height': '14px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>                  
            </div>
            <div class="state">
              <ngx-skeleton-loader [theme]="{ 'width': '60px', 'height': '14px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>                  
            </div>
          </div>  
        </mat-card-content>
      </mat-card>
    </ng-template>

    <!-- Content -->
    <ng-template let-item="item" #TimelineContent *ngIf="appointments">
      
      <mat-card class="past-appointment-card {{getStateClass(item.patient)}}">               
        <mat-card-content>
          <div class="past-app-doctor-container">
            <img class="past-app-doctor-img" [src]="getAvatarUrl(item.patient)"/>
            <div class="past-app-doctor-right-container">
                <div class="past-app-doctor-specialty">{{item.patient.nombreResponsableServicio}}</div>
                <div class="past-app-doctor-name">
                  {{item.patient.especialidadResponsableServicio}}
                    <span *ngIf="item.patient.sedeResponsable">{{ 'shared.pastAppointments.text1' | translate:locale.language }}</span>
                </div>                                
            </div>
            <a class="past-app-cancel-action" (click)="navigateToPublicProfile(item.patient)">{{ 'shared.pastAppointments.programAgain' | translate:locale.language }}</a>
          </div>
          
          <div class="location-container">
            <div class="location-name">{{item.patient.nombreLocalidad}}</div>
            <div class="state">{{ getStateName(item.patient) }}</div>
          </div>
  
        </mat-card-content>
      </mat-card> 

    </ng-template>

    <!-- Empty State -->
    <ng-template let-item="item" #TimelineEmptyStateContent>
      <div class="empty-state-title">{{ 'shared.pastAppointments.text8' | translate:locale.language }}</div>
      <div class="empty-state-text">
        <span>{{ 'shared.pastAppointments.text9' | translate:locale.language }}</span>
        <a (click)="navigateToAppointmentPage()">{{ 'shared.pastAppointments.text10' | translate:locale.language }}</a>
      </div>
    </ng-template>

  </app-timeline-list-container>
    
</div>