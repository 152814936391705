<div class="search-results-container" ngClass.lt-lg="mobile">
    <mat-divider class="divider" *ngIf="showDivider == true"></mat-divider>    

    <div #resultWrapper class="results-wrapper">
        <!-- Custom filters -->
        <div class="filters-container">
            <app-provider-filters-content 
                [profileTypes]="profileTypes"
                [providerSearchMessage]="providerSearchMessage">
            </app-provider-filters-content>
        </div>
        
        <app-search-result-title [nombreFormulario]="data.nombreFormulario"></app-search-result-title>
        
        <div class="subtitle-container" *ngIf="subtitle">
            <span>{{subtitle | i18nText:locale.language}}</span>
        </div>
        <div class="description-container" *ngIf="description && !showFullDescription">
            <span>{{description | i18nText:locale.language}}</span>
            <a *ngIf="fullDescription" (click)="onShowFullDescription()">{{ 'shared.searchResults.results.viewMore' | translate:locale.language }}</a>
        </div>
        <div class="description-container" *ngIf="fullDescription && showFullDescription">
            <span [innerHTML]="fullDescription | i18nText:locale.language"></span>
        </div>
                
        <!-- Search results -->
        <div class="results-container">
            <div class="total" *ngIf="!loadingInitialResults && showResults()">
                {{providersResult.cantidadTotalResulados}} {{ 'shared.searchResults.results.text1' | translate:locale.language }}
            </div>
            <div class="infinite-scroll" 
                infiniteScroll [fromRoot]="true" 
                [infiniteScrollContainer]="'.mat-sidenav-content'" 
                [infiniteScrollDistance]="2" 
                [infiniteScrollThrottle]="50" 
                (scrolled)="onScrollDown()">

                <ng-container *ngIf="!loadingInitialResults && showResults()">
                    <div *ngFor="let provider of providersResult.perfiles; index as i; trackBy: providerTrackBy">
                        <app-provider-card [profile]="provider" [locations]="getLocationsByProvider(provider)"></app-provider-card>
                    </div>
                </ng-container>
                
            </div>
            <mat-spinner class="spinner-loading-more" [diameter]="50" color="primary" *ngIf="loadingResults"></mat-spinner>

            <div class="empty-result-container" *ngIf="showEmptyState()">
                <img src="assets/images/empty-result.svg"/>                
                <span class="empty-title">{{'shared.searchResults.results.emptyText1' | translate:locale.language }}</span>
                <span class="empty-description">{{'shared.searchResults.results.emptyText2' | translate:locale.language }}</span>
            </div>

            <div class="skeleton-container" *ngIf="loadingInitialResults">
                <div class="skeleton-item"></div>
                <div class="skeleton-card-container">
                    <mat-card class="skeleton-card">
                        <mat-card-content>
                            <div class="skeleton-card-header-container">
                                <div class="skeleton-item circle"></div>
                                <div class="skeleton-title-container">
                                    <div class="skeleton-item"></div>
                                    <div class="skeleton-item"></div>
                                </div>
                            </div>
                            <div class="skeleton-card-button-container">
                                <div class="skeleton-item"></div>
                            </div>
                            <div class="skeleton-card-location-container">
                                <div class="skeleton-item"></div>
                                <div class="skeleton-item"></div>
                                <div class="skeleton-item-wrapper">
                                    <div class="skeleton-item"></div>
                                    <div class="skeleton-item"></div>
                                </div>                                
                                <div class="skeleton-item"></div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="skeleton-card">
                        <mat-card-content>
                            <div class="skeleton-card-header-container">
                                <div class="skeleton-item circle"></div>
                                <div class="skeleton-title-container">
                                    <div class="skeleton-item"></div>
                                    <div class="skeleton-item"></div>
                                </div>
                            </div>
                            <div class="skeleton-card-button-container">
                                <div class="skeleton-item"></div>
                            </div>
                            <div class="skeleton-card-location-container">
                                <div class="skeleton-item"></div>
                                <div class="skeleton-item"></div>
                                <div class="skeleton-item-wrapper">
                                    <div class="skeleton-item"></div>
                                    <div class="skeleton-item"></div>
                                </div>                                
                                <div class="skeleton-item"></div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="skeleton-card">
                        <mat-card-content>
                            <div class="skeleton-card-header-container">
                                <div class="skeleton-item circle"></div>
                                <div class="skeleton-title-container">
                                    <div class="skeleton-item"></div>
                                    <div class="skeleton-item"></div>
                                </div>
                            </div>
                            <div class="skeleton-card-button-container">
                                <div class="skeleton-item"></div>
                            </div>
                            <div class="skeleton-card-location-container">
                                <div class="skeleton-item"></div>
                                <div class="skeleton-item"></div>
                                <div class="skeleton-item-wrapper">
                                    <div class="skeleton-item"></div>
                                    <div class="skeleton-item"></div>
                                </div>                                
                                <div class="skeleton-item"></div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="skeleton-card">
                        <mat-card-content>
                            <div class="skeleton-card-header-container">
                                <div class="skeleton-item circle"></div>
                                <div class="skeleton-title-container">
                                    <div class="skeleton-item"></div>
                                    <div class="skeleton-item"></div>
                                </div>
                            </div>
                            <div class="skeleton-card-button-container">
                                <div class="skeleton-item"></div>
                            </div>
                            <div class="skeleton-card-location-container">
                                <div class="skeleton-item"></div>
                                <div class="skeleton-item"></div>
                                <div class="skeleton-item-wrapper">
                                    <div class="skeleton-item"></div>
                                    <div class="skeleton-item"></div>
                                </div>                                
                                <div class="skeleton-item"></div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>