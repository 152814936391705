export class ClinicHistoryConfiguration {
    tabs: TabClinicHistoryConfiguration[];
}

export class TabClinicHistoryConfiguration{
    titulo: string;
    componentes: string;
    componentesArray: string[];

    constructor(obj:TabClinicHistoryConfiguration){
        this.titulo = obj.titulo;
        this.componentes = obj.componentes;
        this.componentesArray = obj.componentes.split('|')
    }
}