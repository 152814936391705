<div class="provider-card-profile-container">

    <div class="label-container" *ngIf="!isCliniwebCompany">
        <span *ngIf="isPharmacyProfile && provider.delivery == 'S'">Delivery</span>
        <mat-icon *ngIf="featureConceptIconClassName" fontSet="phr-iconset" [ngClass]="featureConceptIconClassName"></mat-icon>
        <span *ngIf="featuresConceptsText">{{featuresConceptsText | i18nText:locale.language }}</span>
    </div>

    <div class="avatar">
        <app-public-profile-avatar *ngIf="avatarModel" [model]="avatarModel"></app-public-profile-avatar>
    </div>
                   
    <!--<app-avatar-initials class="avatar"
        [url]="provider.foto"
        [text]="provider.nombre"
        [id]="provider.id"
        *ngIf="showAvatarInitials">
    </app-avatar-initials>
    <div class="avatar icon" *ngIf="!showAvatarInitials">
        <mat-icon fontSet="phr-iconset" [ngClass]="getProfileTypeIconClassName"></mat-icon>
    </div> -->

    <div class="title-card-container">
        <span class="networks" *ngIf="!isCliniwebCompany" [title]="provider.redes">{{ provider.redes }}</span>
        <span class="name" [title]="provider.nombre">{{provider.nombre}}</span>
        <div class="description-container" *ngIf="isDoctorProfile || isClinicProfile">
            <span class="specialty" *ngIf="provider.especialidad" [title]="provider.especialidad">{{provider.especialidad}}</span>
            <span class="subspecialty" *ngIf="provider.subespecialidad" [title]="provider.subespecialidad">{{provider.subespecialidad}}</span>
        </div>
    </div>

    <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-flechaderecha"></mat-icon>

</div>