import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { L10nLocale, L10N_LOCALE, L10nTranslationService } from 'angular-l10n';
import { UserService } from 'src/app/core/services/user.service';
import { ViewDependentModelDto } from '../view-dependent/view-dependent.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DependentRequest } from 'src/app/shared/models/people/dependent.model';
import { Subject } from 'rxjs';
import { CloseRouteDialogMessage, MessageBusService, NavigationBackMessage, OpenSnackBarMessage, SnackBarType } from 'src/app/core/services/message-bus.service';
import { takeUntil } from 'rxjs/operators';
import { AvatarUploadComponentOptions, AvatarUploadedFileModel } from '../../avatar-upload/avatar-upload.component';
import { DOCUMENT } from '@angular/common';
import { UtilsService } from 'src/app/core/services/utils.service';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-dependent-user',
  templateUrl: './edit-dependent.component.html',
  styleUrls: ['./edit-dependent.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class EditDependentComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject();
  
  dependent: ViewDependentModelDto;
  formGroup: FormGroup;
  formSubmitted: boolean = false;
  saving: boolean = false;
  translations = {
    other: "",
    son: "",
    daughter: "",
    father: "",
    mother: "",
    language: "",
    man: "",
    women: ""      
  };
  avatarUploadOptions : AvatarUploadComponentOptions;
  minDate: Date;
  maxDate: Date;
  
  constructor(
    private userService: UserService,  
    private route: ActivatedRoute,
    @Inject(L10N_LOCALE) public locale: L10nLocale,   
    private translation: L10nTranslationService,
    private formBuilder: FormBuilder,
    private messageBusService: MessageBusService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private el: ElementRef,
    private dialog: MatDialog,
    private utilsService: UtilsService
  ) {
    this.avatarUploadOptions = new AvatarUploadComponentOptions();
    this.avatarUploadOptions.requiredFileType = "image/png,image/jpeg";
    this.avatarUploadOptions.selectFileLabel = "añadir foto";
    this.avatarUploadOptions.changeFileLabel = "Editar";
    this.avatarUploadOptions.showFileOnceUpload = true;
    this.avatarUploadOptions.edit = true;

    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 120, 0, 1);
    this.maxDate = new Date();
  }

  ngOnInit(): void {
    const dependentIdQs = this.route.snapshot.paramMap.get('id');

    if(!dependentIdQs) {
        // TODO: redirect to home???
        return;
    }

    let dependentId = parseInt(dependentIdQs);

    this.formGroup = this.formBuilder.group({      
        nameControl: ['', [Validators.required, Validators.maxLength(200)]],
        lastNameControl: ['', [Validators.required, Validators.maxLength(200)]],
        birthDateControl: ['', Validators.required],
        sexControl: ['', Validators.required],
        relationshipType: ['', Validators.required]    
    });

    this.translation.onChange()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
      next: () => {
          this.translations.other = this.translation.translate('shared.profileMenu.text8');
          this.translations.son = this.translation.translate('shared.profileMenu.text9');
          this.translations.daughter = this.translation.translate('shared.profileMenu.text10');
          this.translations.father = this.translation.translate('shared.profileMenu.text11');
          this.translations.mother = this.translation.translate('shared.profileMenu.text12');
          this.translations.man = this.translation.translate('man');
          this.translations.women = this.translation.translate('women');
          
          this.avatarUploadOptions.selectFileLabel = this.translation.translate('shared.viewUser.text8');
          this.avatarUploadOptions.changeFileLabel = this.translation.translate('shared.viewUser.text3');
          this.avatarUploadOptions.changePictureLabel = this.translation.translate('shared.viewUser.text9');
          this.avatarUploadOptions.deleteFileLabel = this.translation.translate('shared.viewUser.text10');
      }
    });

    this.userService.getUserDependents()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(d => {
      let dependents = d as ViewDependentModelDto[];

      // Lookup dependent by Id
      var dependent = dependents.find(d => d.id == dependentId);            

      if (!dependent)
        this.dependent = new ViewDependentModelDto();
      else 
        this.dependent = dependent as ViewDependentModelDto;

      this.setUpDependentExtraData();

      this.loadDependentFormData(this.dependent);
    });

    // On Header Back click
    this.messageBusService.onNavigateBack().pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.onNavigateBackNext.bind(this));

  }

  private onNavigateBackNext(_: NavigationBackMessage): void {
    if (this.dependent.id > 0) {
      if (this.formGroup.dirty && this.formGroup.touched) {
        this.beforeCloseAlert();
      } else
        this.router.navigate(['configuracion/dependiente/' + this.dependent.id]);
    } else 
      this.messageBusService.closeRouteDialog(new CloseRouteDialogMessage());
  }

  private beforeCloseAlert(): void {
    let dialogData = new DialogData();
    dialogData.title = this.translation.translate("shared.editDependent.cancelTitle");
    dialogData.message = this.translation.translate("shared.editDependent.cancelMessage");
    dialogData.confirmText = this.translation.translate("shared.editDependent.cancelConfirmText");   
    dialogData.cancelText = this.translation.translate("shared.editDependent.cancelCancelText");
    dialogData.headerIconClass = 'phr-iconset-alert';
    dialogData.showHeaderIcon = true;
    dialogData.showCancelButton = true;
    dialogData.loadLicense = true;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      panelClass: 'cancel-edit-person-dialog'
    });

    dialogRef.afterClosed().subscribe(accepted => {

      if(accepted){
        this.router.navigate(['configuracion/dependiente/' + this.dependent.id]);
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.messageBusService.refreshProfileMenu();
  }

  setUpDependentExtraData() {
    if (!this.dependent)
        return;

    this.dependent.fullName = this.dependent.nombre + " " + this.dependent.apellido;      
    this.dependent.relationshipName = this.userService.getDependentRelationshipName(this.dependent);

    let feNacimiento = this.dependent.fechaNacimiento? new Date(this.dependent.fechaNacimiento) : null;

    /*if (!this.dependent.ubicacionLogo)
      this.dependent.ubicacionLogo = this.utilsService.getPersonDefaultAvatarUrl(feNacimiento, this.dependent.sexo);*/

    if (this.dependent.sexo && this.dependent.sexo != "9") {
        if (this.dependent.sexo.toLocaleLowerCase() == "m") {
          this.dependent.sexName = this.translations.man;
          this.dependent.sexIconClass = "phr-iconset-hombre";
        }
        else {
          this.dependent.sexName = this.translations.women;
          this.dependent.sexIconClass = "phr-iconset-mujer";
        }              
    }
  }  

  loadDependentFormData(dependent: ViewDependentModelDto) {
    if (dependent.nombre)
      this.setControlValue("nameControl", dependent.nombre);

    if (dependent.apellido)
      this.setControlValue("lastNameControl", dependent.apellido);

    if (dependent.fechaNacimiento)
      this.setControlValue("birthDateControl", dependent.fechaNacimiento);

    if (dependent.sexo && dependent.sexo != "9")
      this.setControlValue("sexControl", dependent.sexo);

    if (dependent.tipoRelacion)
      this.setControlValue("relationshipType", dependent.tipoRelacion.toString());
  }

  getControl(controlName: string) {
    return this.formGroup.controls[controlName];
  }

  isControlInvalid(controlName: string) {
    let control = this.getControl(controlName);

    // First check if the form is submitted or control is dirty
    if (!this.formSubmitted && !control.touched)
      return false;

    return control.invalid;
  }

  controlHasError(controlName: string, errorName: string) {
    return this.getControl(controlName).hasError(errorName);
  }
  
  getControlValue(controlName: string) {
    return this.getControl(controlName).value;
  }

  setControlValue(controlName: string, value: any) {
    this.getControl(controlName).setValue(value);
  }

  onSaveClick() {
    this.formGroup.markAllAsTouched();
    this.formSubmitted = true;

    if (!this.formGroup.valid) {
      this.scrollToInvalidField();
      return;
    }      

    this.saving = true;

    var model = new DependentRequest();

    model.idPersona = this.dependent.idPersona;    
    model.idRelacionDependiente = this.dependent.id;
    model.nombre = this.getControlValue("nameControl");
    model.apellido = this.getControlValue("lastNameControl");
    model.fechaNacimiento = new Date(this.getControlValue("birthDateControl"));
    model.sexo = this.getControlValue("sexControl");
    model.tipoRelacion = parseInt(this.getControlValue("relationshipType"));
    model.ubicacionLogo = this.dependent.ubicacionLogo;

    this.userService.createOrUpdateDependent(model).subscribe({
      next: this.createOrUpdateDependentSuccess.bind(this),
      error: _ => this.saving = false
    });   
  }

  getBirthdateControl():FormControl | null{
    return this.formGroup.get('birthDateControl') 
      ? this.formGroup.get('birthDateControl') as FormControl 
      : null;
  }

  private createOrUpdateDependentSuccess(_: unknown): void {
    if (!this.dependent.ubicacionLogo && this.dependent.id && this.dependent.id > 0) {
      this.userService.deleteUploadedImage(this.dependent.id, this.dependent.idPersona).subscribe({
        next: this.onSuccess.bind(this),
        error: error => this.showErrorToast(error)
      });
    } else {
      this.onSuccess();
    }
  }

  private onSuccess(_?: unknown): void {
    this.saving = false;
    this.showSuccessToast();
    if (this.dependent.id > 0)
      this.router.navigate(['configuracion/dependiente/' + this.dependent.id]);
    else 
      this.messageBusService.closeRouteDialog(new CloseRouteDialogMessage());
  }

  onAvatarUploaded(file: AvatarUploadedFileModel) {
    this.dependent.ubicacionLogo = file?.webUrl;
  }

  showSuccessToast() {
    this.showToast(this.translation.translate('shared.editDependent.text17'), SnackBarType.SUCCESS);
  }

  showErrorToast(message: string) {
    this.showToast(message, SnackBarType.ERROR);
  }

  private showToast(text: string, type: SnackBarType) {
    let message = new OpenSnackBarMessage();
    message.text = text;
    message.type = type;
    message.duration = 3000;
   
    this.messageBusService.openSnackBar(message);
  }

  private scrollToInvalidField() {   
    let container = this.document.getElementsByClassName("edit-dependent-container")[0]; 

      for (const key of Object.keys(this.formGroup.controls)) {
        if (this.formGroup.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]') as Element;         
          
          this.utilsService.scrollToElementWithAnimation(container, invalidControl);          
          break;
        }
      }
  }  
}

