import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { TerritorialDivisionsService } from 'src/app/core/services/territorial-divisions.service';
import { TerritorialDivision } from '../../models/systems/territorial-division.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-country-form',
  templateUrl: './country-form.component.html',
  styleUrls: ['./country-form.component.css']
})
export class CountryFormComponent implements OnInit {

  availableCountries: TerritorialDivision[] = [];

  countryForm: FormGroup;
  loading:boolean = false;
  submitted: boolean = false;
  error: string;

  countryIdSelected:number;

  @Input() errorObs: Observable<string>;
  @Input() country: TerritorialDivision | undefined;  
  @Output() countrySelected: EventEmitter<TerritorialDivision> = new EventEmitter();

  constructor(
    private territorialDivisionService: TerritorialDivisionsService,
    private formBuilder: FormBuilder,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) { }

  ngOnInit(): void {
    if(this.errorObs){
      this.errorObs.subscribe(message=>{
        this.loading = false;
  
        this.error = message;
      });
    }

    this.availableCountries = this.territorialDivisionService.getAvailableCountries();

    // si el pais no esta en el listado de paises disponibles, se debe seleccionar la opcion "Otros"
    if(this.country && !this.availableCountries.find(c => c.id == this.country?.id)){
      this.country = this.availableCountries.find(c => c.id == 1);
    }

    if(this.country){
      this.countryIdSelected = this.country.id;
    }

    this.countryForm = this.formBuilder.group(
      {
        country: [this.countryIdSelected, [Validators.required]]
      }
    );
  }

  onSubmit(){
    this.submitted = true;

    if(this.countryForm.invalid)
      return;

    this.loading = true;

    let countrySelected = this.availableCountries.find(c => c.id == this.countryForm.controls.country.value);

    this.countrySelected.emit(countrySelected);
  }
}
