import { DoctorSearchResult } from "../cdr/doctor-search-result.model";
import { TextLanguage } from "../people/text-language.model";
import { ConceptDefaultModel } from "./concept-default.model";
import { LocationDefaultModel, LocationV2DefaultModel } from "./location-default.model";

export class SearchProvidersConfiguration{
    searchType: SearchProvidersType = SearchProvidersType.DEFAULT;
    searchParamMap: string;

    profileTypes:string = '1';
  
    titleMobile: TextLanguage[] = [];
    placeholderMobile: TextLanguage[] = [];
  
    profilesPlaceholderClosed: TextLanguage[] = [];
    profilesPlaceholderOpened: TextLanguage[] = [];
    profilesLabel: TextLanguage[] = [];
    profilesDefault: ConceptDefaultModel[] = [];
  
    showLocationsAutocomplete: boolean = true;
    locationsPlaceholderClosed: TextLanguage[] = [];
    locationsPlaceholderOpened: TextLanguage[] = [];
    locationsLabel: TextLanguage[];
    locationsDefault:LocationV2DefaultModel[] = [];
    locationsLikeCombo:boolean = false;
  
    showInsurersAutocomplete: boolean = false;
    insurersPlaceholderClosed: TextLanguage[] = [];
    insurersPlaceholderOpened: TextLanguage[] = [];
    insurersLabel: TextLanguage[] = [];
    insurersDefault: ConceptDefaultModel[] = [];
    insurersLikeCombo: boolean = true;
  
    constructor();
    constructor(obj: SearchProvidersConfiguration);
    constructor(obj?:any){

      if(obj){
        this.searchType = obj.searchType || SearchProvidersType.DEFAULT;
        this.searchParamMap = obj.searchParamMap || '';

        this.profileTypes = obj.profileTypes || '1';
  
        this.titleMobile = obj.titleMobile || [];
        this.placeholderMobile = obj.placeholderMobile || [];
    
        this.profilesPlaceholderClosed = obj.profilesPlaceholderClosed || [];
        this.profilesPlaceholderOpened = obj.profilesPlaceholderOpened || [];
        this.profilesLabel = obj.profilesLabel || [];
        this.profilesDefault = obj.profilesDefault || [];
    
        this.showLocationsAutocomplete = obj.showLocationsAutocomplete == undefined ? true : obj.showLocationsAutocomplete;
        this.locationsPlaceholderClosed = obj.locationsPlaceholderClosed || [];
        this.locationsPlaceholderOpened = obj.locationsPlaceholderOpened || [];
        this.locationsLabel = obj.locationsLabel || [];
        this.locationsDefault = obj.locationsDefault || [];
        this.locationsLikeCombo = obj.locationsLikeCombo == undefined ? false: obj.locationsLikeCombo;
    
        this.showInsurersAutocomplete = obj.showInsurersAutocomplete == undefined ? false : obj.showInsurersAutocomplete;
        this.insurersPlaceholderClosed = obj.insurersPlaceholderClosed || [];
        this.insurersPlaceholderOpened = obj.insurersPlaceholderOpened || [];
        this.insurersLabel = obj.insurersLabel || [];
        this.insurersDefault = obj.insurersDefault || [];
        this.insurersLikeCombo = obj.insurersLikeCombo == undefined ? true : obj.insurersLikeCombo;
      }
    }
}

export enum SearchProvidersType{
  DEFAULT = 'default',
  POPUP = 'popup',
  WIDGET = 'widget'
}

export enum SearchProvidersActionType{
  FOCUS_ON_PROVIDER = 1,
  FOCUS_ON_LOCATION = 2,
  FOCUS_ON_INSURANCE = 3,
  REMOVE_PROVIDER = 4,
  REMOVE_LOCATION = 5,
  REMOVE_INSURANCE = 6,
  REMOVE_ALL = 7
}

export class DoctorSearchResultGroup {
  classId: number;
  className: string;
  results: DoctorSearchResult[];
}

export class LocationSearchResultGroup {
  id: number;
  displayName: string;
  results: LocationSearchResultItem[];
}

export class LocationSearchResultItem {
  id: number;
  nombre: string;
  url: string;
  icono?: string;
}

export class InsuranceSearchResultGroup {
  id: number;
  displayName: string;
  results: InsuranceSearchResultItem[];
}

export class InsuranceSearchResultItem{
  id: number;
  nombre: string;
  url: string;
  icono?: string;
}