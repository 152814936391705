<div class="cancel-virtual-attention" ngClass.lt-md="mobile">
    
    <div *ngIf="!completed">
        <div class="header">
            <h3 mat-dialog-title>
                {{ 'shared.cancelVirtualAppointmentDialog.text1' | translate:locale.language }}
            </h3>
        </div>
        <div mat-dialog-content>
            <!--<mat-chip-list class="chip-list" [multiple]="true" aria-label="reason selection" fxLayout="row wrap" fxLayoutAlign="flex-start center">
                <mat-chip *ngFor="let chip of chips" 
                    class="chip-outline accent" [selected]="isChipSelected(chip)" (click)="toggleSelection(chip)">Opcion {{chip}}
                </mat-chip>
            </mat-chip-list>-->
            
            <mat-radio-group class="options-radio-group" [formControl]="selectionControl">
                <mat-radio-button class="radio-option" *ngFor="let option of options" value="{{option.id}}">{{option.text}}</mat-radio-button>
            </mat-radio-group>
            
            <div class="text-area-container">
                <mat-form-field appearance="legacy" class="input-field" floatLabel="never" *ngIf="showTextarea()">
                    <mat-label>{{ 'shared.cancelVirtualAppointmentDialog.text2' | translate:locale.language }}</mat-label> 
                    <textarea matInput placeholder="" [(ngModel)]="reasonText"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div mat-dialog-actions 
            fxLayout="row" fxLayout.lt-md="column-reverse" fxLayoutAlign="center center" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">
            <button mat-stroked-button color="button-disabled" [disabled]="loading" (click)="cancel()">
                {{ 'shared.cancelVirtualAppointmentDialog.text3' | translate:locale.language }}
            </button>
            <button mat-raised-button color="accent" [disabled]="!isSaveEnabled()" class="btn-spinner" (click)="confirm()">
                <mat-spinner *ngIf="loading" diameter="25" color="primary"></mat-spinner>
                <span>{{ 'shared.cancelVirtualAppointmentDialog.text4' | translate:locale.language }}</span>
            </button>
        </div>
    </div>

    <div *ngIf="completed">
        <div class="header">
        </div>
        <div mat-dialog-content>
            <mat-icon fontSet="phr-iconset" color="primary" class="phr-iconset-cancelarcita"></mat-icon>
            <p class="dialog-message completed">
                {{ 'shared.cancelVirtualAppointmentDialog.text5' | translate:locale.language }}
            </p>
        </div>
        <div mat-dialog-actions fxLayout="row" fxLayout.lt-md="column-reverse" fxLayoutAlign="center center" fxLayoutGap="30px" fxLayoutGap.lt-md="20px">
            <button mat-raised-button color="accent" (click)="close(true)">                
                {{ 'shared.cancelVirtualAppointmentDialog.text6' | translate:locale.language }}
            </button>
        </div>
    </div>
    
</div>