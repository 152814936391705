<div class="edit-dependent-container" appTouchMoveListener>    
    <mat-card class="payment-card" *ngIf="dependent">

        <div class="title" *ngIf="dependent && dependent.id > 0">
            {{'shared.editDependent.text1' | translate:locale.language}}            
        </div>
        <div class="title" *ngIf="!dependent || !dependent.id">
            {{'shared.editDependent.text16' | translate:locale.language}}            
        </div>
        <div class="user-avatar-container">
            <app-avatar-upload
                [uploadData]="{idPersona: dependent.idPersona, idRelacionDependiente: dependent.id}"
                [fileUrl]="dependent.ubicacionLogo"
                [options]="avatarUploadOptions"
                (onFileUploaded)="onAvatarUploaded($event)"
            ></app-avatar-upload>            
        </div>

        <div class="form-container">
            <form [formGroup]="formGroup">                
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{'shared.editDependent.text3' | translate:locale.language}}</mat-label>
                        <input appCenterInput matInput required="required" formControlName="nameControl" class="capitalize-text" appCapitalize>
                        <mat-error *ngIf="isControlInvalid('nameControl')">{{'shared.editDependent.text4' | translate:locale.language}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{'shared.editDependent.text5' | translate:locale.language}}</mat-label>
                        <input appCenterInput matInput required="required" formControlName="lastNameControl" class="capitalize-text" appCapitalize>
                        <mat-error *ngIf="isControlInvalid('lastNameControl')">{{'shared.editDependent.text6' | translate:locale.language}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <app-datepicker-flat-field
                        [label]="'shared.editDependent.text7' | translate:locale.language"
                        [maxDate]="maxDate"
                        [minDate]="minDate"
                        [errorMessage]="'shared.editDependent.text8' | translate:locale.language"
                        formControlName="birthDateControl"
                    ></app-datepicker-flat-field>
                </div>
                <div>
                    <div class="gender-container form-field-container">
                        <label>{{'shared.editDependent.text9' | translate:locale.language}}</label>
                        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" formControlName="sexControl" [ngClass]="{'invalid-control': isControlInvalid('sexControl')}">
                            <mat-button-toggle value="M">
                                <mat-icon fontSet="phr-iconset" class="icon phr-iconset phr-iconset-hombre"></mat-icon>
                                <span>{{'man' | translate:locale.language}}</span>
                            </mat-button-toggle>
                            <mat-button-toggle value="F">
                                <mat-icon fontSet="phr-iconset" class="icon phr-iconset phr-iconset-mujer"></mat-icon>
                                <span>{{'women' | translate:locale.language}}</span>
                            </mat-button-toggle>                            
                        </mat-button-toggle-group>
                        <mat-error *ngIf="isControlInvalid('sexControl')">{{'shared.editDependent.text10' | translate:locale.language}}</mat-error>
                    </div>
                </div>
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{'shared.editDependent.text12' | translate:locale.language}}</mat-label>
                        <mat-select formControlName="relationshipType">                            
                            <mat-option value="294">
                                {{'shared.editDependent.text13' | translate:locale.language}}
                            </mat-option>
                            <mat-option value="295">                                
                                {{'shared.editDependent.text14' | translate:locale.language}}
                            </mat-option>                            
                            <mat-option value="480">                                
                                {{'shared.editDependent.text19' | translate:locale.language}}
                            </mat-option>
                            <mat-option value="481">                                
                                {{'shared.editDependent.text20' | translate:locale.language}}
                            </mat-option>
                            <mat-option value="482">                                
                                {{'shared.editDependent.text21' | translate:locale.language}}
                            </mat-option>
                            <mat-option value="483">                                
                                {{'shared.editDependent.text22' | translate:locale.language}}
                            </mat-option>
                            <mat-option value="36">                                
                                {{'shared.editDependent.text18' | translate:locale.language}}
                            </mat-option>

                        </mat-select>
                        <mat-error *ngIf="isControlInvalid('relationshipType')">{{'shared.editDependent.text15' | translate:locale.language}}</mat-error>
                      </mat-form-field>
                </div>
            </form>
        </div>

    </mat-card>

    <div class="buttons-container">
        <button mat-button mat-flat-button (click)="onSaveClick()" class="primary" [ngClass]="{'loading': saving}">
            {{'shared.editDependent.text11' | translate:locale.language}}
        </button>        
    </div>
</div>