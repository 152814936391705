import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeLoaderService {
  insertedElement: HTMLElement;

  init(themeName: string) {


    import(
      /* webpackChunkName: "[request]" */
      `../../../themes/${themeName}/theme.js`
    )
      .then((s) => s.default)
      .then(this.insertToDom);


    // const head = document.getElementsByTagName('head')[0];

    // const link = document.createElement('link');
    // link.rel = 'stylesheet';
    // link.href = `${themeName}.css`;
    // head.appendChild(link);
  }

  insertToDom = (content: string) => {
    const element = document.createElement('style');
    element.textContent = content;
    document.head.appendChild(element);

    if (this.insertedElement) this.insertedElement.remove();
    this.insertedElement = element;
  };
}