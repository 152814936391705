import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageBusService, PageScrollMessage } from 'src/app/core/services/message-bus.service';
import { Direction } from 'src/app/shared/enums/direction.enum';

@Component({
  selector: 'app-route-dialog',
  templateUrl: './route-dialog.component.html',
  styleUrls: ['./route-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RouteDialogComponent implements OnInit, OnDestroy {

  currentRoute: string;
  headerModel: RouteDialogHeaderModel = new RouteDialogHeaderModel();
  private ngUnsubscribe = new Subject();
  scrollTop: number = 0;

  loading:boolean = true;

  constructor( 
    @Inject(MAT_DIALOG_DATA) public data: RouteDialogModel,
    private router: Router,
    private messageBusService: MessageBusService,
    private mdDialogRef: MatDialogRef<RouteDialogComponent>,
    @Inject(DOCUMENT) private document: Document
    
  ) {
    this.headerModel.dialogRef = this.mdDialogRef;
    this.headerModel.closeFn = this.onCloseClick.bind(this);
    this.headerModel.dialogModel = data;    
  }

  ngOnInit(): void {
    this.document.body.classList.add('route-dialog-opened');

    if(this.data.route.split('?').length > 1){
      this.router.navigateByUrl(this.data.route, {state: this.data.state, });
    }
    else{
      this.router.navigate([this.data.route], {state: this.data.state, queryParams: this.data.queryParams});
    }

    this.messageBusService.onCloseRouteDialog()
      .pipe(takeUntil(this.ngUnsubscribe))  
      .subscribe(m => {
        this.onCloseClick();
      }
    );

    this.router.events
    .pipe(takeUntil(this.ngUnsubscribe)) 
    .subscribe((val)=>{
      if(val instanceof NavigationEnd){
        this.loading = false;
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onCloseClick(preventDialogClose = false){

    this.loading = true;

    if(this.data.animationOut){
      this.document.getElementsByClassName(this.data.className)[0].classList.remove(this.data.animationIn);
      this.document.getElementsByClassName(this.data.className)[0].classList.add(this.data.animationOut);
    }

    if (!preventDialogClose)
      setTimeout(()=>{      
        this.mdDialogRef.close();
      }, 300);

    setTimeout(()=>{
      if(this.document.getElementsByClassName("route-dialog-container").length == 0){
        this.document.body.classList.remove('route-dialog-opened');
      }
    }, 600);    
  }

  onScroll($event:Event){

    const scrollTop = ($event.target as Element).scrollTop;
    const offset = this.scrollTop - scrollTop;
    const elOffsetTop = ($event.target as HTMLElement).offsetTop

    if(Math.abs(offset) < 50) return; 

    const direction = offset < 0
      ? Direction.Down
      : Direction.Up;

    this.scrollTop = scrollTop;    

    this.messageBusService.pageScroll(new PageScrollMessage(direction, scrollTop, elOffsetTop, true));
  }
}

export class RouteDialogModel{
  id: number;
  headerName: string;
  route: string;
  state: any;
  queryParams: any;
  className: string;
  backgroundClassName: string;
  animationIn: string;
  animationOut: string;
  disableClose: boolean = false;
}

export class AppointmentRouteDialogModel extends RouteDialogModel{
  id = RouteDialogType.APPOINTMENT;
  route = 'citas/checkout';
  className = 'appointment-dialog';
  animationIn = 'appointment-animation-in';
  animationOut = 'appointment-animation-out';
}

export class PaymentRouteDialogModel extends RouteDialogModel{
  id = RouteDialogType.PAYMENT;
  headerName = 'PaymentHeaderComponent';
  route = 'pagos/checkout';
  className = 'appointment-dialog';
  animationIn = 'appointment-animation-in';
  animationOut = 'appointment-animation-out';
}

export class PublicProfileRouteDialogModel extends RouteDialogModel{
  id = RouteDialogType.PUBLIC_PROFILE;
  headerName = 'PublicProfileHeaderComponent';
  className = 'public-profile-dialog';
  animationIn = 'public-profile-animation-in';
  animationOut = 'public-profile-animation-out';
}

export class ProfileMenuDialogModel extends RouteDialogModel {
  id = RouteDialogType.PROFILE_MENU;
  headerName = 'DefaultHeaderComponent';
  route = 'configuracion/menu';
  className = 'profile-menu-route-dialog-container';
  animationIn = 'public-profile-animation-in';
  animationOut = 'public-profile-animation-out';
}

export class ConfigurationPageDialogModel extends RouteDialogModel {
  id = RouteDialogType.CONFIGURATION_PAGE;
  headerName = 'DefaultBackHeaderComponent';
  route = '';
  className = 'configuration-page-route-dialog-container';
  animationIn = 'public-profile-animation-in';
  animationOut = 'public-profile-animation-out';
}

export class AppointmentSummaryRouteDialogModel extends RouteDialogModel{
  id = RouteDialogType.APPOINTMENT_SUMMARY;
  route = '';
  headerName = 'DefaultBackWithLinkHeaderComponent';
  className = 'appointment-dialog';
  animationIn = 'public-profile-animation-in';
  animationOut = 'public-profile-animation-out';
}

export class MyOrderSummaryRouteDialogModel extends RouteDialogModel{
  id = RouteDialogType.MY_ORDER_SUMMARY;
  route = '';
  headerName = 'DefaultBackWithLinkHeaderComponent';
  className = 'my-order-summary-dialog';
  animationIn = 'public-profile-animation-in';
  animationOut = 'public-profile-animation-out';
}

export class AppointmentCancelationRouteDialogModel extends RouteDialogModel{
  id = RouteDialogType.CANCEL_APPOINTMENT;
  route = '';
  headerName = 'DefaultBackHeaderComponent';
  className = 'cancel-appointment-route-dialog';
  backgroundClassName = 'directory-background';
  animationIn = 'public-profile-animation-in';
  animationOut = 'public-profile-animation-out';
}

export class RescheduleAppointmentRouteDialogModel extends RouteDialogModel{
  id = RouteDialogType.RESCHEDULE_APPOINTMENT;
  route = '';
  headerName = 'DefaultBackWithLinkHeaderComponent';  
  backgroundClassName = 'directory-background';
  className = 'reschedule-appointment-route-dialog';
  animationIn = 'public-profile-animation-in';
  animationOut = 'public-profile-animation-out';
}

export class LoginRouteDialogModel extends RouteDialogModel {
  id = RouteDialogType.LOGIN;
  route = '';   
  className = 'login-route-dialog';
  animationIn = 'public-profile-animation-in';
  animationOut = 'public-profile-animation-out';
}

export class LegalAgreementsRouteDialogModel extends RouteDialogModel {
  id = RouteDialogType.LEGALAGREEMENTS;
  animationIn = 'public-profile-animation-in';
  animationOut = 'public-profile-animation-out';
  className = 'legal-agreements-route-dialog';
  headerName = 'DefaultLogoHeaderComponent';
}

export class LearnMoreRouteDialogModel extends RouteDialogModel {
  id = RouteDialogType.LEARN_MORE;
  route = 'conoce-mas';   
  headerName = 'DefaultLogoHeaderComponent';  
  className = 'login-route-dialog';
  animationIn = 'public-profile-animation-in';
  animationOut = 'public-profile-animation-out';
}

export class RouteDialogHeaderModel{
  dialogRef: any;
  closeFn: any;
  dialogModel: RouteDialogModel;
}

export class ContactRouteDialogModel extends RouteDialogModel {
  id = RouteDialogType.CONTACT;
  animationIn = 'public-profile-animation-in';
  animationOut = 'public-profile-animation-out';
  className = 'contact-route-dialog';
  headerName = 'DefaultBackHeaderComponent';
}

export class ChangePasswordRouteDialogModel extends RouteDialogModel {
  id = RouteDialogType.CHANGE_PASSWORD;
  route: "login/restablecer";
  animationIn = 'public-profile-animation-in';
  animationOut = 'public-profile-animation-out';
  className = 'change-password-route-dialog';
  headerName = 'DefaultBackHeaderComponent';
}

export enum RouteDialogType{
  APPOINTMENT = 1,
  PAYMENT = 2,
  PUBLIC_PROFILE = 3,
  PROFILE_MENU = 4,
  CONFIGURATION_PAGE = 5,
  APPOINTMENT_SUMMARY = 6,
  CANCEL_APPOINTMENT = 7,
  RESCHEDULE_APPOINTMENT = 8,
  LOGIN = 9,
  LEARN_MORE = 10,
  LEGALAGREEMENTS = 11,
  CONTACT = 12,
  CHANGE_PASSWORD = 13,
  MY_ORDER_SUMMARY = 14
}
