<div class="cliniweb-future-appointment-cards-container" >
    
    <div class="cliniweb-future-appointment-cards skeleton-cards" *ngIf="loading">
        
        <!-- Skeleton -->
        <app-cliniweb-appointment [loading]="true"></app-cliniweb-appointment>        
     
    </div>
    
    <div class="cliniweb-future-appointment-cards" *ngIf="!loading && this.appointments && this.appointments.length > 0">
        
        <!-- Appointment Card -->
        <app-cliniweb-appointment 
            [directory]="directory" [dependents]="dependents" [loggedInUser]="loggedInUser" [app]="app" 
            *ngFor="let app of appointmentsToShow;">
        </app-cliniweb-appointment>

        <div class="show-more" *ngIf="!showAll && this.appointments && this.appointments.length > 1" (click)="onShowAll()">
            <span>{{'shared.cliniwebFutureAppointments.text1' | translate:locale.language}}</span>
            <mat-icon fontSet="phr-iconset" class="phr-iconset-right-arrow"></mat-icon>
        </div>        
    </div>

    <!-- Empty State -->
    <div class="cliniweb-future-appointment-cards empty-state" *ngIf="!loading && (!this.appointments || this.appointments.length == 0)">
        <mat-card>            
            <mat-card-header>
                <img src="/assets/images/cliniweb/future-appointments-empty-state.png">
            </mat-card-header>
            
            <mat-card-content>
                <span>{{'shared.cliniwebFutureAppointments.text2' | translate:locale.language}}</span>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="mobile-overlay">

    </div>

    <button mat-button mat-flat-button class="new-appointment-btn" (click)="onNewAppointmentClick()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-plus-heavier"></mat-icon>
        <span class="desktop">{{'shared.cliniwebFutureAppointments.text3' | translate:locale.language}}</span>
        <span class="mobile">{{'shared.cliniwebFutureAppointments.text4' | translate:locale.language}}</span>
    </button>
          
</div>
    
