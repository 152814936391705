import { Person } from "./person.model";

export class Dependent extends Person{
    idPersona: number;
    idEmpresa: number;
    numeroTelCelular: string;
    titulo: string;
    idPaisResidencia: number;
    idPaisNacimiento: number;
    emailParticular: string;
    tipoRelacion: number;
    idEstadoValidacionIdentidadPersona: number;
}

export enum DependentRelationshipType {
    FatherOrMother = 294,
    SonOrDaughter = 295,
    Family = 36,   
    HusbandOrWife = 480,
    BrotherOrSister = 481,
    GrandfatherOrGrandmother = 482,
    Warded = 483,
}

export class DependentRequest {
    nombre: string;
    apellido: string;
    sexo: string;
    tipoRelacion: number;
    fechaNacimiento: Date;
    idPersona: number;
    idRelacionDependiente: number;
    ubicacionLogo: string;
}