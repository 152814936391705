import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';
import { LayoutService } from 'src/app/core/services/layout.service';
import { MessageBusService, SessionExpiredMessage } from 'src/app/core/services/message-bus.service';
import { UserService } from 'src/app/core/services/user.service';
import { Direction } from 'src/app/shared/enums/direction.enum';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  private scrollTop: number = 0;
  public menuComponentName: string = "";
  loggedInUser?: UserPerson;  

  constructor(
    private layoutService: LayoutService,
    private baseService: BaseService,
    private messageBusService: MessageBusService,
    private userService: UserService    
  ) { }

  ngOnInit(): void {
    this.menuComponentName = this.baseService.getMenuComponentName();    

    this.userService.getUserPersonLoggedIn()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(p => {
      if (p)
        this.loggedInUser = p;
    });
    this.messageBusService.onSessionExpired()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((_: SessionExpiredMessage) => this.loggedInUser = undefined);
    this.messageBusService.onLoginCompleted()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(msg => {
      if (msg.user) {
        this.loggedInUser = msg.user;
      }      
    });    
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll($event:Event){
    const scrollTop = ($event.target as Element).scrollTop;

    const offset = this.scrollTop - scrollTop;

    if(Math.abs(offset) < 50) return; 

    const direction = offset < 0
      ? Direction.Down
      : Direction.Up;

    this.scrollTop = scrollTop;
    
    setTimeout(()=>{
      this.layoutService.onScrollingBody(direction);
    }, 100);


    this.messageBusService.scrollTopBody(scrollTop);
  }
}
