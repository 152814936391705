<div class="summary-order-footer-container">
    <button mat-button mat-flat-button class="secondary" (click)="irInicio()" *ngIf="inWorflow">
        {{ buttonSecondaryText | translate:locale.language }}
    </button>
    <button mat-button mat-flat-button class="primary" (click)="verOrdenes()" *ngIf="inWorflow">
        <span> {{ buttonPrimaryText | translate:locale.language }} </span>
    </button>
    <app-summary-order-download-button
        *ngIf="!inWorflow"
        [file]="file">
    </app-summary-order-download-button>
    <app-summary-order-download-button
        *ngIf="!inWorflow"
        [share]="true"
        [file]="file">
    </app-summary-order-download-button>
</div>