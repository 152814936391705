<div class="cancelled-appointment-container" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="desktop-solution">
        <table mat-table [dataSource]="appointments" class="mat-elevation-z8">        
        
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="app-column-header"> {{ 'name' | translate:locale.language }} </th>
            <td mat-cell *matCellDef="let app" class="app-row-cell name">               
              <span>{{app.nombreResponsableServicio}}</span>
              <span *ngIf="app.sedeResponsable">{{ 'shared.cancelledAppointments.text1' | translate:locale.language }}</span>
            </td>
          </ng-container>
        
          <!-- Specialty Column -->
          <ng-container matColumnDef="specialty">
            <th mat-header-cell *matHeaderCellDef class="app-column-header"> {{ 'specialty' | translate:locale.language }}  </th>
            <td mat-cell *matCellDef="let app" class="app-row-cell specialty"> {{app.especialidadResponsableServicio}} </td>
          </ng-container>
        
          <!-- Date Column -->
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef class="app-column-header"> {{ 'date' | translate:locale.language }}  </th>
            <td mat-cell *matCellDef="let app" class="app-row-cell"> {{app.fechaRequerido | date:'dd/MM/yyyy' }} </td>
          </ng-container>

          <!-- State Column -->
          <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef class="app-column-header"> {{ 'state' | translate:locale.language }}  </th>
            <td mat-cell *matCellDef="let app" class="app-row-cell"> {{ getStateName(app) }} </td>
          </ng-container>
        
          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="app-column-header action"></th>
            <td mat-cell *matCellDef="let app" class="app-row-cell new-app" (click)="navigateToPublicProfile(app)"> {{ 'shared.cancelledAppointments.programAgain' | translate:locale.language }} </td>
          </ng-container>
        
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="app-row"></tr>        
      </table>      
    </div>
    
    <div class="mobile-solution">
        <div class="item-container" *ngFor="let app of appointmentsPage; index as i; trackBy: appointmentTrackBy">
          <div class="doctor-name">
              <span>{{app.nombreResponsableServicio}} </span>
              <span *ngIf="app.sedeResponsable">{{ 'shared.cancelledAppointments.text1' | translate:locale.language }}</span>
          </div>
          <div class="app-date">
            {{ 'date' | translate:locale.language }}: {{app.fechaRequerido | date:'dd/MM/yyyy' }}
          </div>
          <div class="doctor-specialty">
            {{ 'specialty' | translate:locale.language }}: {{app.especialidadResponsableServicio}} 
          </div>
          <div class="doctor-specialty">
            {{ 'state' | translate:locale.language }}: {{ getStateName(app) }}
          </div>
          <div class="app-row-cell new-app" (click)="navigateToPublicProfile(app)">
            {{ 'shared.cancelledAppointments.programAgain' | translate:locale.language }}
          </div>
        </div>
    </div>
  
    <mat-paginator [pageSize]="5" [length]="resultsCount" (page)="OnPageChange($event)" [hidePageSize]="true" class="mat-elevation-z8 app-paginator">
    </mat-paginator>
    
</div>