 <!-- Policy content -->
 <div class="policy-container" *ngIf="policies">
    <div *ngFor="let policy of policies; index as i; trackBy: policyTrackBy">    
        <div class="divider"></div>  
        
        <div class="menu-subtitle">{{policy.plan}}</div>

        <mat-chip-list aria-label="active" class="chip-list">            
            <mat-chip class="chip-outline accent">{{ 'policyMenu.text6' | translate:locale.language }}</mat-chip>
        </mat-chip-list>

        <div class="menu-text-item">
            <span class="item-title">{{ 'policyMenu.text2' | translate:locale.language }}: </span>
            <span class="item-value">{{policy.redMedica}}</span>
        </div>
        <div class="menu-text-item">
            <span class="item-title">{{ 'policyMenu.text3' | translate:locale.language }}: </span>
            <span class="item-value">{{policy.numeroPoliza}}</span>
        </div>

        <a class="menu-text-link" (click)="goToPolicy(policy.numeroPoliza)">{{ 'policyMenu.text4' | translate:locale.language }}</a>                  
    </div>
</div>            