import { Injectable } from '@angular/core';
import { ChatCancellationMessage, ChatConversacion, ChatConversacionMessage } from "src/app/shared/models/people/chat-conversation.model";
import { Observable } from "rxjs";
import { HttpBaseService } from './http-base-service';

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    constructor(private http: HttpBaseService) {}

    getByEtapaSolicitudId(etapaSolicitudId: number) : Observable<ChatConversacion> {
        return this.http.get<ChatConversacion>("api/chats?etapaSolicitudId=" + etapaSolicitudId);
    }

    getChatMessages(idSolicitud: number, from: number, pageSize: number): Observable<ChatConversacionMessage[]> {
        return this.http.get<ChatConversacionMessage[]>("api/chats/mensajes?idSolicitud=" + idSolicitud + "&from=" + from + "&pageSize=" + pageSize);
    }

    sendMessage(chatId: number, message: ChatConversacionMessage){
        return this.http.post("api/chats/" + chatId + "/mensaje", message);
    }

    sendCancellationMessage(chatId: number, message: ChatCancellationMessage) {
        return this.http.post("api/chats/" + chatId + "/mensaje/cancelacion", message);
    }
}