import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { WorkflowItem } from "../../../systems/workflow.model";
import { OnlinePaymentWorkflowState } from "../../states/online-payment-workflow-state.model";
import { BaseStep } from "../base-step.model";
import { ComponentDataSetMessage, MessageBusService } from "src/app/core/services/message-bus.service";
import { ComponentName } from "src/app/shared/enums/component-name.enum";
import { PaymentComponentData } from "src/app/shared/components/payment/payment.component";

@Injectable({
    providedIn: 'root',
})
export class PaymentFormStep extends BaseStep<OnlinePaymentWorkflowState> {
    
    constructor(
        protected messageBusService: MessageBusService,
    ){    
        super(messageBusService);
        
        this.showHeader = true;
        this.showProgressBar = true;
        this.showButtonBack = true;
        this.showButtonClose = true;
    }    

    protected handleComponentSetDataMessage(message: ComponentDataSetMessage) {
        if (message.componentName == ComponentName.PAYMENT_FORM) {
            let model = message.data as PaymentComponentData;

            if (model) {
                this.stateData.setCreditCardType(model.creditCardType);
                this.stateData.setCreditCardNumber(model.creditCardNumber);
                this.stateData.setCreditCardExpirationMonth(model.creditCardExpMonth);
                this.stateData.setCreditCardExpirationYear(model.creditCardExpYear);
                this.stateData.setCreditCardCvc(model.creditCardCvc);
                this.stateData.setCreditCardFullName(model.creditCardFullName);
                this.stateData.setPhoneNumber(model.phoneNumber);                
            }
        }
    }   

    protected buildComponentConfigurationData() : { [componentName: string]: any; } {
        let formConfigurationData : { [componentName: string]: any; } = {};
        
        formConfigurationData[ComponentName.PAYMENT_FORM] = this.getPaymenComponentData();

        return formConfigurationData;
    }

    protected messageForThisStep(message: ComponentDataSetMessage): boolean {
        return message.componentName == ComponentName.PAYMENT_FORM;
    }

    private getPaymenComponentData() {
        var data = new PaymentComponentData();

        data.amount = this.stateData.getAmount();
        data.creditCardNumber = this.stateData.getCreditCardNumber();
        data.creditCardExpMonth = this.stateData.getCreditCardExpirationMonth();
        data.creditCardExpYear = this.stateData.getCreditCardExpirationYear();
        data.creditCardFullName = this.stateData.getCreditCardFullName();
        data.phoneNumber = this.stateData.getPhoneNumber();       
        data.publicProfile = this.stateData.getPublicProfile(); 

        return data;
    }   

    setItem(item: WorkflowItem): void {
        this.item = item;
    }

    setState(stateData: any): void {
        this.stateData = new OnlinePaymentWorkflowState(stateData);
    }

    isCompleted(): Observable<boolean> {
        let result = (
            this.stateData
            && !this.isNullOrEmpty(this.stateData.getCreditCardNumber())
            && !this.isNullOrEmpty(this.stateData.getCreditCardExpirationMonth())
            && !this.isNullOrEmpty(this.stateData.getCreditCardExpirationYear())
            && !this.isNullOrEmpty(this.stateData.getCreditCardCvc())
            && !this.isNullOrEmpty(this.stateData.getCreditCardFullName())
            && !this.isNullOrEmpty(this.stateData.getPhoneNumber())
        );

        return of(result);
    }

    showStepInActionBack(): boolean {
        return true;
    }

    showStep(): boolean {
        return true;
    }

    private isNullOrEmpty(str: string) {
        return str == null || str == undefined || str == "";
    }
}

export interface IPaymentWorkflowState {    
    getAmount():string;

    setCreditCardType(cardType: string) : void;

    getCreditCardNumber() : string;
    setCreditCardNumber(cardNumber: string) : void;

    getCreditCardExpirationMonth() : string;
    setCreditCardExpirationMonth(expMonth: string) : void;
    getCreditCardExpirationYear() : string;
    setCreditCardExpirationYear(expYear: string) : void;

    getCreditCardCvc() : string;
    setCreditCardCvc(cvc: string) : void;

    getCreditCardFullName() : string;
    setCreditCardFullName(fullName: string) : void;

    getPhoneNumber() : string;
    setPhoneNumber(fullName: string) : void;
}