import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { UserService } from 'src/app/core/services/user.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';

@Component({
  selector: 'app-cliniweb-home-layout',
  templateUrl: './cliniweb-home-layout.component.html',
  styleUrls: ['./cliniweb-home-layout.component.css']
})
@ComponentLookup('HomeCliniwebComponent')
export class CliniwebHomeLayoutComponent implements OnInit, IControlComponent, OnDestroy {
  data: FormComponentData;
  user: UserPerson | null = null;
  userName: string;

  private ngUnsubscribe = new Subject();

  constructor(
    private userService: UserService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private messageBusService: MessageBusService,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.messageBusService.onRefreshProfileMenu()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(m => {
        this.getUser();
      }
    );

    this.authService.isLoggedInFull().subscribe(loggedIn => {
      if (loggedIn)
        this.getUser();
    });
  } 

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private getUser() {
    this.userService.getUserPersonLoggedIn().subscribe(person => {
      if (person) {
        this.user = person;
        this.userName = this.user?.nombre.split("@")[0];
      }      
    });
  }
  
}