<div class="file-viewer-main-container" [ngClass]="{'zoomIn': selectedImgZooms > 0, 'non-preview-file': !selectedFileHasPreview()}">
    <div class="file-viewer-header">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-back" (click)="onCloseClick()"></mat-icon>  
        <span class="file-name">{{selectedFile.title}}</span>
        <span class="header-actions">
            <span>
                <mat-icon fontSet="phr-iconset" class="phr-iconset-new-window" (click)="openOnNewTab()"></mat-icon>  
            </span>
        </span>
    </div>
    <div class="file-viewer-content">
        <div class="left-arrow-container" (click)="previous()" *ngIf="isPreviousVisible()">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-arrow-down"></mat-icon>  
        </div>
        <div class="right-arrow-container" (click)="next()" *ngIf="isNextVisible()">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-arrow-down"></mat-icon>  
        </div>
        <div class="image-container" *ngIf="!loadingFileUrl">
            <img #selectedImg cdkDrag [cdkDragDisabled]="!showZoomOut()" [cdkDragFreeDragPosition]="dragPosition" [src]="selectedFileUrl" *ngIf="!useGoogleViewer(selectedFile)" (click)="onFileClick(selectedFile)">
            <div class="google-viewer-container" *ngIf="useGoogleViewer(selectedFile)">
                <iframe class="embebed-doc" [src]="getSelectedFileViewerUrl() | safeUrl" frameBorder="0" scrolling="auto"></iframe>                
            </div>
        </div>
        <div class="image-container" *ngIf="loadingFileUrl">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <div class="file-viewer-footer">
        <span class="footer-actions">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-menosbold" (click)="zoomOut()" [ngClass]="{'disabled': selectedImgZooms == 0}"></mat-icon>  
            <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-zoom-in" (click)="zoomIn()" *ngIf="showZoomIn()"></mat-icon>  
            <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-zoom-out" (click)="resetZoom()" *ngIf="showZoomOut()"></mat-icon>  
            <mat-icon fontSet="phr-iconset" class="phr-iconset-plus-heavier" (click)="zoomIn()"></mat-icon>  
        </span>
    </div>
</div>