import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { MenuMobileComponent } from '../../dialogs/menu-mobile/menu-mobile.component';

@Component({
  selector: 'app-header-mapfre-panama',
  templateUrl: './header-mapfre-panama.component.html',
  styleUrls: ['./header-mapfre-panama.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('HeaderMapfrePanamaComponent')
export class HeaderMapfrePanamaComponent implements OnInit, IControlComponent {
  data: FormComponentData;

  routeClassName: string = '';
  
  constructor(
    public dialog: MatDialog,
    private router: Router) {

    this.router.events.subscribe(()=>{
      this.initRouteClassName();

    });
  }
  
  ngOnInit(): void {  
    
  }

  openMenuMobile(){
    const dialogRef = this.dialog.open(MenuMobileComponent, {
      panelClass: 'menu-mobile',
      width:"338px",
      height: "100%",
      position: { left: "0px"}
    });
  }

  private initRouteClassName(){
    this.routeClassName = this.router.url.split('/')[1];
  }
}
