import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';

@Component({
  selector: 'app-learn-more',
  templateUrl: './learn-more.component.html',
  styleUrls: ['./learn-more.component.css']
})
@ComponentLookup('LearnMoreComponent')
export class LearnMoreComponent implements OnInit, OnDestroy {
  data: FormComponentData;
  private ngUnsubscribe = new Subject();

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private messageBusService: MessageBusService,
    private routeDialogService: RouteDialogService
  ) { }

  ngOnInit(): void {    
    // On Header Back click
    this.messageBusService.onNavigateBack()
        .pipe(takeUntil(this.ngUnsubscribe))  
        .subscribe(m => {
          this.routeDialogService.closeDialogsAndNavigate("/citas/checkout");
        });
  } 
  
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}