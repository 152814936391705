import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageBusService, ProviderSearchMessage } from 'src/app/core/services/message-bus.service';
import { PublicProfileType } from 'src/app/shared/models/people/profile-search-results.model';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { ProviderFilterModel } from '../provider-filters-content/provider-filters-content.component';

@Component({
  selector: 'app-provider-filters-dialog',
  templateUrl: './provider-filters-dialog.component.html',
  styleUrls: ['./provider-filters-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProviderFiltersDialogComponent implements OnInit {

  constructor(
    private messageBusService: MessageBusService,
    @Inject(MAT_DIALOG_DATA) public data: ProviderFiltersDialogComponentData,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
  ) { }

  ngOnInit(): void {
  }

  clearFilters(){
    this.messageBusService.clearProviderFilters();
  }
}

export class ProviderFiltersDialogComponentData{
  filterModel: ProviderFilterModel;
  publicProfileTypes:PublicProfileType[] ;
  providerSearchMessage: ProviderSearchMessage;
}


