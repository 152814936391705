<div class="provider-filters-dialog-container">
    <div class="header">
        <h2 mat-dialog-title>{{'shared.searchResults.providerFiltersDialog.title' | translate:locale.language }}</h2>
        <button mat-icon-button class="btn-close" mat-button mat-dialog-close tabindex="-1">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Cerrar"></mat-icon> 
        </button>
    </div>
    <div mat-dialog-content>
        <app-provider-filters-body 
            [filterModel]="data.filterModel"
            [publicProfileTypes]="data.publicProfileTypes"
            [providerSearchMessage]="data.providerSearchMessage">
        </app-provider-filters-body>
    </div>
    <div mat-dialog-actions>
        <button class="btn-clear-all" mat-button (click)="clearFilters()">
            {{'shared.searchResults.providerFiltersDialog.button1' | translate:locale.language }}
        </button>
        <button mat-button mat-flat-button mat-dialog-close class="primary2 small btn-apply">
            {{'shared.searchResults.providerFiltersDialog.button2' | translate:locale.language }}
        </button>
    </div>
</div>
