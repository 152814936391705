import { PublicProfileType } from "../people/profile-search-results.model";

export class Directory {
    url: string;
    idEmpresa?: number;
    tipo: DirectoryType;
    nombre: string;
    nombreClaseEstilo: string;
    nombreEncabezado: string;
    localizacionDefecto: string;
    urlCorporativo: string;
    tituloPHR: string;
    urlFavicon: string;
    clienteOidc: string;
    nombreCuentaPaciente: string;
    filtros: string;
    nombrePoliticasPhr: string;
    idEmpresaDestinoConsultaVirtual?: number;
}

export enum DirectoryType {
    Cliniweb = 1,
    PHR = 2
}

export class DirectoryFilters {
    providers: DirectoryFiltersNetwork[];
}

export class DirectoryFiltersNetwork {
    type: PublicProfileType;
    searchProviderTypes: PublicProfileType[];
    filters: DirectoryFiltersItems[];
}

export class DirectoryFiltersItems {    
    tipo: DirectoryFiltersItemType;
    idConceptoClinico: number;
    idClaseConceptoClinico: number;
    nombreClaseConceptoClinico: string;
}

export enum DirectoryFiltersItemType {
    RedFavorita = 0,
    RedesSura = 1,
    Especialidad = 2,
    AreaEnfoque = 3,
    Diagnostico = 4,
    Procedimiento = 5,
    Teleconsulta = 6,
    SedeSura = 7,
    Abierto24Hrs = 8,
    Delivery = 9,
    TipoCentroImagenes = 10,
    Urgencias = 11,
    Hospitalizacion = 12,
    Cirugia = 13,
    ServiciosSalud = 14,
    LugarAtencion = 15,
    AgendamientoEnLinea = 16,
    Aseguradora = 17
}

