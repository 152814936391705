<div class="appointment-info-dialog-container">
    <div class="header">
        <h3>{{ 'shared.appointmentInfoDialog.title' | translate:locale.language }}</h3>
        <button mat-icon-button class="btn-close" (click)="onClose()" tabindex="-1">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-arrow-down-big"></mat-icon>
        </button>        
    </div>
    <div mat-dialog-content>
        <app-appointment-info-checkout [model]="data"></app-appointment-info-checkout>
    </div>
</div>