import { ErrorHandler, Injectable } from '@angular/core';
import { MessageBusService, ServiceErrorMessage } from '../services/message-bus.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private messageBusService: MessageBusService){

    }

    handleError(error: any): void {

        console.error('GlobalErrorHandler' + error);
        this.messageBusService.serviceError(new ServiceErrorMessage(error.message || 'Error desconocido'));
    }
}