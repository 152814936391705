import { Injectable } from '@angular/core';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';
import { MessageBusService } from './message-bus.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(
    private messageBusService: MessageBusService,
  ) {

    this.messageBusService.onLoginCompleted().subscribe(msg => {
      if (msg.user) {
        this.setUserData(msg.user);
      }      
    });

  }

  static loadGoogleAnalytics(key: string){


    const gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ key }`);

    const gaScript1 = document.createElement('script');
    gaScript1.innerText = `window.dataLayer = window.dataLayer || [];`;
    gaScript1.innerText += `window.gtag=window.gtag||function(){dataLayer.push(arguments);};`;
    gaScript1.innerText += `window.gtag(\'js\', new Date());`;
    // gaScript1.innerText += `window.gtag(\'config\', \'${ key }\', { 'debug_mode':true });`;
    gaScript1.innerText += `window.gtag(\'config\', \'${ key }\');`;

    document.documentElement?.firstChild?.appendChild(gaScript);
    document.documentElement?.firstChild?.appendChild(gaScript1);
  }

  logPageView(url: string){
    if((window as any).gtag){
      (window as any).gtag('event', 'page_view', { page_location: url});
    }
  }

  sendEventInitVirtualConsultation(){
    if((window as any).gtag){
      (window as any).gtag('event', 'init_virtual_consultation');
    }
  }

  sendEventOpenClinicHistory(){
    if((window as any).gtag){
      (window as any).gtag('event', 'open_clinic_history');
    }
  }

  sendEventDownloadClinicHistory(){
    if((window as any).gtag){
      (window as any).gtag('event', 'download_clinic_history');
    }
  }

  sendEventGoToInsuranceCard(){
    if((window as any).gtag){
      (window as any).gtag('event', 'go_to_insurance_card');
    }
  }

  setUserData(user: UserPerson){
    if((window as any).gtag){
      (window as any).gtag('set', 'user_id', user.id);
      (window as any).gtag('set', 'user_properties', {
        'sex': user.sexo
      });
    }
  }
}
