import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { L10nLocale, L10N_LOCALE, L10nTranslationService } from 'angular-l10n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppStorageService, STORAGE } from 'src/app/core/services/app-storage.service';
import { AuthOidcService } from 'src/app/core/services/auth-oidc.service';
import { BaseService } from 'src/app/core/services/base.service';
import { LoginCompletedMessage, MessageBusService, OpenSnackBarMessage, SnackBarType } from 'src/app/core/services/message-bus.service';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { UserService } from 'src/app/core/services/user.service';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { OAuthFlowType, TokenState } from 'src/app/shared/models/token.model';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AuthCallbackComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  showErrorView = false;
  tokenState:string = "";
  isSura : boolean = false;

  skeletonHeightStyles = {
    "h1": "69px",
    "h2": "38px",
    "h3": "24px",
    "h4": "13px",
    "h5": "10px",
    "h6": "8px"    
  };

  skeletonBorderStyles = {
    "b1": "2px"
  };

  skeletonColorStyles = {
    "yellow": "rgba(227, 232, 41, 0.3)",
    "primary": "rgba(0, 51, 160, 0.3)",
    "gray": "rgba(83, 86, 90, 0.3)",
    "linkColor": "rgba(0, 174, 199, 0.3)"
  };

  constructor(
    private authOidcService: AuthOidcService,
    private appStorageService: AppStorageService,
    private userService: UserService,
    private router: Router,
    private messageBusService: MessageBusService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private baseService: BaseService,
    private translation: L10nTranslationService,
    private workflowService: WorkflowService,
    private publicProfile: PublicProfileService
  ) { }

  ngOnInit(): void {    
    let companyName = this.baseService.getCompanyName();

    this.isSura = companyName == "sura-pacientes-pa";

    if (!this.isSura)
      this.messageBusService.hideHeader();

    this.authOidcService.completeAuthentication()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(token => {
      this.messageBusService.showHeader();

      if(token.isInvalid) {

        if(token.invalidMessage && token.state){
          let tokenState = this.parseTokenState(token.state);

          let activeWorkflow = this.workflowService.getWorkflowTypeActive();

          // In case of PWA when the third party redirects back to our site the browser can decide to open the PWA application instead of the browser. 
          // For this case in PWA application the checkout (appointment or payment) may not be initialized, so we need to do it here.
          if (tokenState.workflowType && tokenState.workflowType != activeWorkflow) {
            this.initWorkflow(tokenState);
          }
          else 
            this.baseService.goToRoute(tokenState.returnUrl);
            //this.router.navigate([tokenState.returnUrl]);

          let message = new OpenSnackBarMessage();
          message.type = SnackBarType.ALERT;
          message.text = token.invalidMessage;
          // message.duration = 3000;
          this.messageBusService.openSnackBar(message);
        }
        else{        
          this.tokenState = token.state;
          this.showErrorView = true;
  
          window.parent.postMessage({ eventName: 'login_error' }, '*');

        }
      }
      else {
        this.appStorageService.setToken(token);
        this.appStorageService.removeSessionItem(STORAGE.USER);
        
        let tokenState = this.parseTokenState(token.state);

        this.userService.getUserPersonLoggedIn().subscribe(user => {
          if (user)
            this.messageBusService.loginCompleted(new LoginCompletedMessage(user));          

          // LOGIN
          if (tokenState.flowType == OAuthFlowType.Login) {
            if (token.userCreated) {
              let snackBarMessage = new OpenSnackBarMessage();

              snackBarMessage.type = SnackBarType.SUCCESS;
              snackBarMessage.text = this.translation.translate('authCallback.text4');
              snackBarMessage.duration = 3000;
              this.messageBusService.openSnackBar(snackBarMessage);
            }
          }
          // SIGNUP
          else if (tokenState.flowType == OAuthFlowType.Signup) {
            this.messageBusService.showHeader();

            if (token.userCreated) {
              let snackBarMessage = new OpenSnackBarMessage();

              snackBarMessage.type = SnackBarType.SUCCESS;
              snackBarMessage.text = this.translation.translate('authCallback.text4');
              snackBarMessage.duration = 3000;
              this.messageBusService.openSnackBar(snackBarMessage);
            }
          }

          let activeWorkflow = this.workflowService.getWorkflowTypeActive();

          // In case of PWA when the third party redirects back to our site the browser can decide to open the PWA application instead of the browser. 
          // For this case in PWA application the checkout (appointment or payment) may not be initialized, so we need to do it here.
          if (tokenState.workflowType && tokenState.workflowType != activeWorkflow) {
            this.initWorkflow(tokenState);
          }
          else 
            this.baseService.goToRoute(tokenState.returnUrl);  
            //this.router.navigate([tokenState.returnUrl]);
        });        
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onRetryClick() {
    this.router.navigate(['login'], { queryParams: { returnUrl: this.tokenState } });
  }

  private parseTokenState(state: string): TokenState {
    let tokenState: TokenState;
    
    try {
      tokenState = JSON.parse(state) as TokenState;
    } 
    catch (error) {
      tokenState = new TokenState();
      tokenState.returnUrl = state;
    }

    if (tokenState && tokenState.returnUrl)
      tokenState.returnUrl = decodeURIComponent(tokenState.returnUrl);

    return tokenState;
  }

  initWorkflow(tokenState: TokenState) {
    let companyName = this.baseService.getCliniwebDirectoryCompanyName();
    
    if (!tokenState.publicProfileIdentifier) {
      this.handleWorkflowStartError("Public Profile not found");
      return;
    }
    
    // Get public profile
    this.publicProfile.getPublicProfile(tokenState.publicProfileIdentifier, this.locale.language, companyName)
      .subscribe(
        profile => {
          if (tokenState.workflowType == STORAGE.ONLINE_APPOINTMENT_CLINIWEB_STATE) {            
            let location = profile.localidades.find(l => l.localidad.id == tokenState.locationId);

            if (!location) {
              this.handleWorkflowStartError("Location not found");
              return;
            }

            this.workflowService.initAppointmentWorkflow(profile, location, tokenState.targetDate);
          }
          else if (tokenState.workflowType == STORAGE.ONLINE_PAYMENT_WORKFLOW_STATE) {
            this.workflowService.initPaymentWorkflow(profile, companyName);
          }
        }, 
        error => {
          console.error(error);
        }
      );    
  }

  private handleWorkflowStartError(error: string) {
    let snackBarMessage = new OpenSnackBarMessage();

    snackBarMessage.type = SnackBarType.ERROR;
    snackBarMessage.text = error;
    snackBarMessage.duration = 3000;

    console.log(error);

    this.messageBusService.openSnackBar(snackBarMessage);
  }
}