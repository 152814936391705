<div class="payment-amount-container">
    <mat-card class="payment-card">
        
        <div class="">
            <div class="title">{{ 'shared.payment.text1' | translate:locale.language }}</div>
           
            <div class="content">
                
                <!-- Profile Avantar -->
                <div class="profile-container">
                    <div class="avatar" *ngIf="avatarModel">                
                        <app-public-profile-avatar [model]="avatarModel"></app-public-profile-avatar>
                    </div>
                    <div class="profile-name">
                        {{model.targetUserName}}
                    </div>
                </div>

                <!-- Amount Input -->
                <div class="payment-input">
                    <div class="symbol">$</div>                    
                    <input type="tel" tabindex="1" matInput                 
                        [ngStyle]="{'width.ch': model.paymentAmount.length}"
                        [(ngModel)]="model.paymentAmount" 
                        (click)="onAmountInputClick($event)"                       
                        (keydown)="onAmountInputKeyup($event)" #amountInputEl/>                    
                </div>
                <div class="currency">USD</div>    
                <div class="validation-message" *ngIf="invalidInput">
                    <span>{{ 'shared.payment.text24' | translate:locale.language }}</span>                    
                </div>

            </div>           

            <div class="btn-container">
                <button mat-button mat-flat-button (click)="onNextClick()">{{ 'shared.payment.text3' | translate:locale.language }}</button>
            </div>
                      
        </div>

    </mat-card>
</div>