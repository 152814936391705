<div class="user-payment-container directory-background">
    
    <!-- Skeleton -->
    <mat-card class="skeleton-card" *ngIf="loading">
        <div class="title">
            <ngx-skeleton-loader [theme]="{'width': '45%', 'height': '24px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
        </div>
        
        <mat-card-content>
            <div class="payment-container">
                <div class="payment-header">
                    <div class="doc-name">
                        <ngx-skeleton-loader [theme]="{'width': '94px', 'height': '20px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
                    </div>
                    <div class="payment-amount">
                        <ngx-skeleton-loader [theme]="{'width': '110px', 'height': '30px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
                    </div>
                    <div class="payment-date">
                        <ngx-skeleton-loader [theme]="{'width': '86px', 'height': '18px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
                    </div>
                </div>

                <div class="payment-content">
                    <div class="credit-card-info">
                        <ngx-skeleton-loader [theme]="{'width': '45%', 'height': '18px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
                    </div>
                    <div class="label-item">
                        <label><ngx-skeleton-loader [theme]="{'width': '55px', 'height': '16px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader></label>
                        <div><ngx-skeleton-loader [theme]="{'width': '45%', 'height': '18px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        </div>
                    </div>
                    <div class="details-container">
                        <label><ngx-skeleton-loader [theme]="{'width': '55px', 'height': '16px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader></label>
                        <div class="payment-detail">
                            <span class="concept"><ngx-skeleton-loader [theme]="{'width': '127px', 'height': '18px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        </span>
                            <span class="concept-amount"><ngx-skeleton-loader [theme]="{'width': '80px', 'height': '18px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        </span>                           
                        </div>
                        <div class="totals-container">
                            <span><ngx-skeleton-loader [theme]="{'width': '40px', 'height': '18px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader></span>
                            <span><ngx-skeleton-loader [theme]="{'width': '80px', 'height': '18px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader></span>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    
    
    <mat-card *ngIf="!loading && payment">
        <div class="title">
            {{'shared.userPayment.text1' | translate:locale.language}}
        </div>
        <mat-card-content>            
            
            <div class="payment-container">
                <div class="payment-header">
                    <div class="doc-name">
                        {{payment.nombreResponsableServicio | lowercase }}
                    </div>
                    <div class="payment-amount">
                        ${{getFormatedAmount(payment.monto)}}
                    </div>
                    <div class="payment-date">
                        {{payment.fechaPagoElectronico | date:'d MMM, y' }}
                    </div>
                </div>

                <div class="payment-content">
                    <div class="credit-card-info">
                        <span class="credit-card-type">
                            <img class="credi-card-img visa" *ngIf="payment.tipoTarjeta == 'VISA'" src="/assets/images/payment/card-visa.svg">
                            <img class="credi-card-img master" *ngIf="payment.tipoTarjeta == 'MASTERCARD'" src="/assets/images/payment/card-master.svg">
                            <img class="credi-card-img amex" *ngIf="payment.tipoTarjeta == 'AMEX'" src="/assets/images/payment/card-american.svg">                            
                        </span>
                        <span class="credit-card-number">**** {{payment.numeroTarjeta}}</span>
                    </div>
                    <div class="label-item">
                        <label>{{'shared.userPayment.text2' | translate:locale.language}}</label>
                        <div>{{payment.nombrePacientePhr}}</div>
                    </div>
                    <div class="details-container">
                        <label>{{'shared.userPayment.text3' | translate:locale.language}}</label>
                        <div class="payment-detail" *ngFor="let detail of payment.detalles; index as i;">
                            <span class="concept">{{'shared.userPayment.text4' | translate:locale.language}}</span>
                            <span class="concept-amount">${{getFormatedAmount(detail.montoDetalleTransaccion)}}</span>                           
                        </div>
                        <div class="totals-container">
                            <span>{{'shared.userPayment.text5' | translate:locale.language}}:</span>
                            <span>${{getFormatedAmount(payment.monto)}}</span>
                        </div>
                    </div>
                </div>
            </div>

        </mat-card-content>
    </mat-card>

    <div class="buttons-container">
        <button mat-button mat-flat-button class="primary" [ngClass]="{'loading': loadingPayment}" (click)="onPaymentClick()">            
            <span>{{'shared.userPayment.text6' | translate:locale.language}}</span>
        </button>
        <button mat-button mat-flat-button class="secondary" [ngClass]="{'loading': loadingPdf}" (click)="onDownloadTransactionPdfClick()">            
            <span>{{'shared.userPayment.text7' | translate:locale.language}}</span>
        </button>
    </div>
</div>