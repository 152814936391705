import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthPrivateRoutesGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.validar(route, state);
  }
  private validar(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isRestrictedRoute: boolean = route.data['isRestrictedRoute'];
    const condicion = isRestrictedRoute && !this.authService.getToken();
    if (condicion) {
      const homeUrl: string = state.url;
      this.router.navigate(['welcome', 'login'], { queryParams: { homeUrl } });
    }
    return !condicion;
  }
}
