import { PublicProfile } from "../../people/public-profile.model";

export class AppointmentInfoDataModel{
    appointmentType: AppointmentType;
    appointmentDate:Date;
    cancellationDate?:Date;
    doctorAvatarUrl: string;
    doctorName: string;
    doctorSpecialty: string;
    perfilPublico: PublicProfile;
    attentionPlaceName: string;
    attentionPlaceLatLng: string;
    attentionPlaceAddress: string;
    medicalConsultationType: string;
    medicalConsultationAmount: number;
    patientName: string;
    patientGender: string;
    patientRelationshipType: number;
    
    idEtapaSolicitud: number;
    idEstado: number;
    nombreEstado: string;
    idResponsableServicio: number;
    idEmpresa: number;
  }
  
  export enum AppointmentType {
    FACE_TO_FACE = 0,
    VIRTUAL = 1
  }