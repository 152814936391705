import { AfterViewInit, Component, ContentChild, ElementRef, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { MatDialog } from '@angular/material/dialog';
import { BaseService } from 'src/app/core/services/base.service';
import { LayoutService } from 'src/app/core/services/layout.service';


@Component({
  selector: 'app-timeline-list-container',
  templateUrl: './timeline-list.component.html',
  styleUrls: ['./timeline-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TimelineListComponent implements OnInit, AfterViewInit, OnDestroy  {
  groups: TimelineWrapperGroup<TimelineWrapperItem>[] = [];  
  _items : TimelineWrapperItem[];
  _itemType = TimelineWrapperItemType;

  @ContentChild('TimelineContent') TimelineContent: TemplateRef<ElementRef>;
  @ContentChild('TimelineSkeletonContent') TimelineSkeletonContent: TemplateRef<ElementRef>;
  @ContentChild('TimelineEmptyStateContent') TimelineEmptyStateContent: TemplateRef<ElementRef>;
  
  @Input() loading : boolean = false;
  @Input() skeletonItems : TimelineWrapperSkeletonItem[] = [];

  @Input() 
  get items(): TimelineWrapperItem[] { return this._items; }
  set items(items : TimelineWrapperItem[]) {
    this._items = items;
    this.onItemsChange(items);
  };    

  ngAfterViewInit() {    
  }  

  constructor(
    public dialog: MatDialog,
    private baseService: BaseService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private layoutService: LayoutService) {        
  }  
  
  ngOnInit(): void {    
    this.layoutService.addComponentInitClass("timeline-list-component");
  }

  ngOnDestroy(): void {
    this.layoutService.removeComponentInitClass("timeline-list-component");
  }

  onItemsChange(newItems: TimelineWrapperItem[]) {
    this.groups = [];    

    if (newItems)
        this.populateItems(newItems);
  }
  
  populateItems(items : TimelineWrapperItem[]) {
    for (let index = 0; index < items.length; index++) {
      const item = items[index];           
      
      // Add item to group
      this.addToGroup(item);
    }
  }

  getGroup(item: TimelineWrapperItem) {
    return this.groups.find(g => g.key === item.getGroupKey());
  }

  addNewGroup(groupKey: number) {
    let group = new TimelineWrapperGroup<TimelineWrapperItem>(groupKey);

    this.groups.push(group);
  }

  addToGroup(item: TimelineWrapperItem) {
    let group = this.getGroup(item);

    if (!group) 
      this.addNewGroup(item.getGroupKey());

    this.getGroup(item)?.addItem(item);
  }

  getDateAsStr(date: Date) {
    return this.baseService.getBasicDateFormat(date, false);
  }
 
}

export class TimelineWrapperGroup<T> {
  key : number;
  items: T[];
  collapsed = false;

  constructor(key: number) {
    this.key = key;
    this.items = [];
  }

  addItem(item: T) {
    this.items.push(item);
  }

  onClick() {
    this.collapsed = !this.collapsed;
  }
}

export interface TimelineWrapperItem {
  getGroupKey() : number;
  getDate() : Date;
  getItemType(): TimelineWrapperItemType;
  showTime(): boolean;
  showDate(): boolean;
}

export enum TimelineWrapperItemType {
  DOT = 1,
  CHECKED = 2,
  CANCELLED = 3
}

export class TimelineWrapperSkeletonItem {
}