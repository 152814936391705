import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { WidgetRoutingModule } from './widget-routing.module';
import { SearchComponent } from './pages/search/search.component';



@NgModule({
  declarations: [SearchComponent],
  imports: [
    SharedModule,
    CommonModule,
    WidgetRoutingModule
  ]
})
export class WidgetModule { }
