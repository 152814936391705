<div class="vc-tabs-container">
    <mat-tab-group animationDuration="0ms" color="accent" [(selectedIndex)]="selectedTabIndex">
        
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="tab-header">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-videoconsulta tab-icon"></mat-icon>                
                    <span class="tab-title">{{ 'shared.virtualConsultatiosTabs.text1' | translate:locale.language }}</span>      
                </div>                          
            </ng-template>    
            <div class="tab-content">                
                <app-select-symptoms [parentData]='data'></app-select-symptoms>
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>     
                <div class="tab-header">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-historial tab-icon"></mat-icon>
                    <span class="tab-title">{{ 'shared.virtualConsultatiosTabs.text2' | translate:locale.language }}</span> 
                </div>                               
            </ng-template> 
            <!-- Lazy load this tab content (it will be loaded when clicking the tab) -->
            <ng-template matTabContent>
                <div class="tab-content">                      
                    <app-past-virtual-consultation-container></app-past-virtual-consultation-container>
                </div>
            </ng-template>                        
        </mat-tab>  

    </mat-tab-group>
</div>






