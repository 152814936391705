import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base-service';
import { Observable } from 'rxjs';
import { PatientVerificationResponse } from 'src/app/shared/interfaces/patient-verification-response';
import { BaseService } from './base.service';
import { RequestToVerifySummary } from 'src/app/shared/interfaces/request-to-verify-summary';
import { RequestToVerifyUpdateModel } from 'src/app/shared/models/systems/resquest-to-verify-update.model';

@Injectable({
  providedIn: 'root'
})
export class RequestsToVerifyService {

  private baseUrl = 'api/solicitudes-x-verificar';

  constructor(private http: HttpBaseService, private baseService: BaseService) { }

  confirmation(idVerificacion: string): Observable<RequestsToVerifyConfirmationResponse> {
    const url = `${this.baseUrl}/${idVerificacion}/confirmacion`;
    return this.http.post<RequestsToVerifyConfirmationResponse>(url, null, null, true);
  }

  validate(fechaNacimiento: string, idVerificacion: string): Observable<PatientVerificationResponse> {
    const url = `${this.baseUrl}/${idVerificacion}/validacion-paciente`;
    return this.http.post<PatientVerificationResponse>(url, { fechaNacimiento }, null, false);
  }

  getSummaryData(idVerificacion: string): Observable<RequestToVerifySummary> {
    const url = `${this.baseUrl}/id-verificacion/${idVerificacion}/resumen?idEmpresa=${this.baseService.getCompanyId()}`;
    return this.http.get<RequestToVerifySummary>(url, null, false);
  }

  update(idVerificacion: string, model: RequestToVerifyUpdateModel): Observable<RequestToVerifyUpdateModel>{
    const url = `${this.baseUrl}/${idVerificacion}/actualizacion-solicitud-consulta`;
    return this.http.put<RequestToVerifyUpdateModel>(url, model, null, true);
  }
}

export interface RequestsToVerifyConfirmationResponse {
  idPaciente: number, idRelacionPaciente: number
}
