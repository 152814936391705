import { Component, Inject, Input, OnInit } from '@angular/core';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { MessageBusService, OpenSnackBarMessage, SnackBarType } from 'src/app/core/services/message-bus.service';

@Component({
  selector: 'app-email-callback',
  templateUrl: './email-callback.component.html',
  styleUrls: ['./email-callback.component.css']
})
export class EmailCallbackComponent implements OnInit {

  forwardingCount: number = 0;
  emailSent: boolean = false;
  loading: boolean = false;

  @Input() model: EmailCallbackComponentData;

  constructor(
    private messageBusService: MessageBusService,
    private translation: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) { }

  ngOnInit(): void {
  }

  resendRequest(){
    this.forwardingCount++;

    if(this.forwardingCount <= this.model.forwardingLimit){
      this.loading = true;

      this.model.resend$
        .pipe(first())
        .subscribe({
          next:(response:boolean) =>{
            this.loading = false;
    
            if(this.forwardingCount > 0){
              this.emailSent = true;
    
              setTimeout(() => {
                this.emailSent = false;
              }, 3000);
            }
          },
          error: (error:string) =>{
            this.loading = false;

            this.openSnackBar(error);
          }
        });
    }
  }

  enterDataAgain(){
    this.model.enterDataAgainFn();
  }

  private openSnackBar(error:string){
    let snackBarMessage = new OpenSnackBarMessage();

    snackBarMessage.type = SnackBarType.ERROR;
    snackBarMessage.text = error;

    this.messageBusService.openSnackBar(snackBarMessage);
  }
}
export class EmailCallbackComponentData{
  titleReference: string;
  description: string;
  forwardingLimit: number;

  resend$: Observable<any>;
  resendModel: any;
  enterDataAgainFn: any;
}