import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppointmentsByPatient, PatientAppointment, PatientAppointmentInformation } from 'src/app/shared/models/process/appointment.model';
import { OnlineAppointmentRequest } from 'src/app/shared/models/process/online-appointment-request.model';
import { ScheduleByLocation } from 'src/app/shared/models/process/schedule-by-location.model';
import { BaseService } from './base.service';
import { HttpBaseService } from './http-base-service';
import { OnlineAppointmentStatus } from 'src/app/shared/enums/online-appointment-status.enum';
import { AppointmentDoctor } from 'src/app/shared/models/people/appointment-doctor';
import { AppStorageService, STORAGE } from './app-storage.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(private http: HttpBaseService, private baseService:BaseService, private appStorageService: AppStorageService) { }
  
  getAppointmentById(etapaSolicitudId: number, language: string):Observable<PatientAppointmentInformation> {
    return this.http.get<PatientAppointmentInformation>(`api/citas/${etapaSolicitudId}/informacion?lenguaje=${language}`);
  }

  getLatestDoctors(language: string, maxAmount: number) : Observable<AppointmentDoctor[]> {
    return this.getAppointmentsWithCache(language).pipe(map((appointments: AppointmentsByPatient) => {
      let latestDoctors : AppointmentDoctor[];

      if (appointments.doctores && appointments.doctores.length > maxAmount) {
        latestDoctors = appointments.doctores.slice(0, maxAmount);
      } else {
        latestDoctors = appointments.doctores;
      }

      return latestDoctors;
    }));  
  }

  getNextAppointment(language: string) : Observable<PatientAppointment | undefined> {
    return this.getAppointmentsWithCache(language).pipe(map((appointments: AppointmentsByPatient) => {
      let nextAppointment = appointments.citasFuturas.find(a => a.idEstado != 7);
      
      return nextAppointment;
    }));
  }

  getAppointmentLastTimeShown(appointmentId: number): number {
    let dateNumber = this.appStorageService.getItem(STORAGE.NEXT_APPOINTMENT_LAST_TIME_SHOWN + "_" + appointmentId);

    return dateNumber? dateNumber : 0;
  }

  setAppointmentLastTimeShown(appointmentId: number) {
    this.appStorageService.setItem(STORAGE.NEXT_APPOINTMENT_LAST_TIME_SHOWN + "_" + appointmentId, new Date().getTime());
  }

  getAppointmentsWithCache(language: string):Observable<AppointmentsByPatient>  {
    if (!this.isAppointmentsCacheEmpty()) {
      console.log("getting appointments from cache");
      return of(this.getFromCache());
    }

    return this.getAppointments(language);
  }

  getAppointments(language: string):Observable<AppointmentsByPatient> {
    console.log("getting appointments from server");

    let url = "api/citas?language=" + language;

    return this.http.get<AppointmentsByPatient>(url).pipe(map((appointments: AppointmentsByPatient) => {
      this.addToCache(appointments);
      return appointments;
    }));    
  }

  clearAppointmentsCache() {
    this.appStorageService.setItemSession(STORAGE.APPOINTMENTS, null);
  }

  getFutureAppointments(language: string):Observable<PatientAppointment[]> {
    return this.getAppointmentsWithCache(language).pipe(map((appointments: AppointmentsByPatient) => {
      return appointments.citasFuturas;
    }));  
  }

  getPastAppointments(language: string):Observable<PatientAppointment[]> {
    return this.getAppointmentsWithCache(language).pipe(map((appointments: AppointmentsByPatient) => {
      return appointments.citasPasadas;
    }));  
  }

  getIncompletedAppointments():Observable<PatientAppointment[]> {
    return this.http.get<PatientAppointment[]>("api/citas/incompletas");
  }

  cancelAppointment(idSolicitud: number, idEtapaSolicitud: number, reason: string):Observable<any> {
    return this.http.put("api/citas/" + idSolicitud + "/cancelar/" + idEtapaSolicitud + "?razon=" + reason, {});
  }

  getAppointmentsSchedule(
    idEmpresa: number,
    idResponsableServicio: number, 
    fechaInicio: string, 
    fechaFin: string, 
    idLocalidad: number
  ): Observable<ScheduleByLocation[]>{

    let url = "api/citas/horarios/disponibles";
    url+=`?idEmpresa=${idEmpresa}`;
    url+=`&idResponsableServicio=${idResponsableServicio}`;
    url+=`&fechaInicio=${fechaInicio}`;
    url+=`&fechaFin=${fechaFin}`;
    url+=`&idLocalidad=${idLocalidad}`;

    return this.http.get<ScheduleByLocation[]>(url, null, false);
  }

  getAppointmentsScheduleNextItem(
    idEmpresa: number,
    idResponsableServicio: number, 
    fechaInicio: string, 
    idLocalidad: number
  ): Observable<Date | null>{
    let url = "api/citas/horarios/disponibles/proximo";
    url+=`?idEmpresa=${idEmpresa}`;
    url+=`&idResponsableServicio=${idResponsableServicio}`;
    url+=`&fechaInicio=${fechaInicio}`;
    url+=`&idLocalidad=${idLocalidad}`;

    return this.http.get<Date | null>(url, null, false);
  }

  getOnlineAppointmentRequest(idVerificacion: string): Observable<OnlineAppointmentRequest>{
    return this.http.get<OnlineAppointmentRequest>("api/citas/por-verificar/" + idVerificacion, null, false);
  }

  createOnlineAppointmentRequest(onlineAppointmentRequest: OnlineAppointmentRequest): Observable<string>{
    return this.http.post<string>("api/citas/por-verificar", onlineAppointmentRequest, null, false);
  }

  updateOnlineAppointmentRequest(idVerificacion: string, onlineAppointmentRequest: OnlineAppointmentRequest): Observable<number>{
    let url = `api/citas/por-verificar/${idVerificacion}`;
    return this.http.put<number>(url, onlineAppointmentRequest);
  }

  confirmOnlineAppointmentRequest(idVerificacion:string, onlineAppointmentRequest: OnlineAppointmentRequest): Observable<any>{
    let url = `api/citas/por-verificar/${idVerificacion}/confirmacion`;
    return this.http.put<any>(url, onlineAppointmentRequest);
  }

  getStatus(idVerificacion:string): Observable<OnlineAppointmentStatus> {
    let url = `api/citas/por-verificar/${idVerificacion}/estado`;

    return this.http.get<OnlineAppointmentStatus>(url, null, false);
  }

  isAppointmentsCacheEmpty(): boolean {
    return !this.appStorageService.getItemSession(STORAGE.APPOINTMENTS);
  }

  addToCache(appointments: AppointmentsByPatient) {
    this.appStorageService.setItemSession(STORAGE.APPOINTMENTS, appointments);
  }

  getFromCache(): AppointmentsByPatient {
    return this.appStorageService.getItemSession(STORAGE.APPOINTMENTS);
  }

}