import { AppointmentInfoDataModel } from "./appointment-info-data.model";

export class AppointmentSummaryDataModel{
    appointmentInfo: AppointmentInfoDataModel;
    specialty: string;
    pendingApproval: boolean;
    locationLat: number;
    locationLng: number;
    locationZoom: number = 15;
    phoneNumber: string;
}