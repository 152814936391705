import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PublicProfileType } from 'src/app/shared/models/people/profile-search-results.model';
import { FormConfiguration } from 'src/app/shared/models/people/form.model';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';
import { ClinicHistory } from 'src/app/shared/models/clinic-history/clinic-history.model';
import { PublicProfile } from 'src/app/shared/models/people/public-profile.model';
import { IWorkflowDataState } from 'src/app/shared/interfaces/workflow-data-state';
import { ComponentName } from 'src/app/shared/enums/component-name.enum';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { AppointmentsByPatient } from 'src/app/shared/models/process/appointment.model';
import { TerritorialDivision } from 'src/app/shared/models/systems/territorial-division.model';
import { Direction } from 'src/app/shared/enums/direction.enum';
import { Type } from '@angular/core';

/*
    This service will be used for communication between components
    Each type of event will be a message (with a class that represent its properties)
    For each message we will have a method to fire a new message and a method to subscribe to new messages
*/
@Injectable({
  providedIn: 'root'
})
export class MessageBusService {
    
    private profileSearchSubject = new Subject<ProfileSearchMessage>();
    private profileSearchFilterSubject = new Subject<ProfileSearchFilterMessage>();
    private sendUserIdSubject = new Subject<number>();
    private authIframeSubject = new Subject<void>();
    private pastVirtualRequestsSubject = new Subject<void>();
    private virtualRequestFinishedSubject = new Subject<void>();
    private appointmentCancelledSubject = new Subject<AppointmentCancelledMessage>();
    private loadChatSubject = new BehaviorSubject<LoadChatMessage>(null as any); // BehaviorSubject allway keeps the last value
    private openChatSubject = new BehaviorSubject<OpenChatMessage>(null as any); // BehaviorSubject allway keeps the last value
    private componentFinishLoadingSubject = new Subject<ComponentFinishLoadingMessage>();
    private formConfigurationSubject = new Subject<FormConfigurationMessage>();
    private chatCancelledSubject = new Subject<ChatCancelledMessage>();
    private chatLoadedSubject = new Subject<ChatLoadedMessage>();
    private loginCompletedSubject = new Subject<LoginCompletedMessage>();
    private logoutSubject = new Subject<LogoutMessage>();
    private sessionExpiredSubject = new Subject<SessionExpiredMessage>();
    private serviceErrorSubject = new Subject<ServiceErrorMessage>();
    private sendClinicHistorySubject = new Subject<ClinicHistoryMessage>();
    private providerSearchSubject = new Subject<ProviderSearchMessage>();
    private pageSrollSubject = new Subject<PageScrollMessage>();
    private scrollTopBodySubject = new Subject<number>();
    private closeCheckAutocompleteSubject = new Subject<number>();
    private localeChangeSubject =  new Subject<LocaleChangeMessage>();
    private formFinishLoadingSubject = new Subject<number>();
    private sendPublicProfileSubject = new Subject<PublicProfileMessage>();
    private sendWorkflowDataSubject = new Subject<WorkflowDataMessage>();
    private sendFlowStepCompletedSubject = new Subject<FlowStepCompletedMessage>();
    private componentDataSetSubject = new Subject<ComponentDataSetMessage>();
    private workflowStateUpdateSubject = new Subject<WorkflowStateUpdateMessage>();
    private openSnackBarSubject = new Subject<OpenSnackBarMessage>();
    private hideHeaderSubject = new Subject<void>();
    private showHeaderSubject = new Subject<void>();
    private navigationBackSubject = new Subject<NavigationBackMessage>();
    private closeRouteDialogSubject = new Subject<CloseRouteDialogMessage>();
    private appointmentChangeReceivedSubject = new Subject<AppointmentsByPatient>();
    private closeWorkFlowRequestSubject: Subject<string | undefined> = new Subject();
    private hideAnonymousMenuSubject: Subject<void> = new Subject();
    private showAnonymousMenuSubject: Subject<void> = new Subject();
    private closeSearchProviderDialogSubject = new Subject<void>();
    private showSignupLoginHeaderMenuSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private showWelcomeImageSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private sendFloatingComponentsSubject = new Subject<FloatingComponentsMessage>();
    private refreshProfileMenuSubject = new Subject<void>();
    private clearProviderFiltersSubject = new Subject<void>();
    private openProviderFiltersSubject = new Subject<void>();
    private providerFiltersCountSubject = new Subject<number>();
    private controlComponentClassnameSubject = new Subject<ControlComponentClassnameMessage>();
    private openRouteDialogSubject = new Subject<void>();
    private allRouteDialogClosedSubject = new Subject<void>();
    private publicProfilePageSubject = new Subject<void>();
    private dialogOpenSubject = new Subject<DialogOpenMessage>();
    private dialogCloseSubject = new Subject<DialogCloseMessage>();
    private territorialDivisionCountrySubject = new Subject<TerritorialDivionCountryMessage>();  
    private publicProfileHeaderVisibilityChangeSubject = new Subject<PublicProfileHeaderVisibilityChangeMessage>();
    private publicProfileActionSubject = new Subject<PublicProfileActionMessage>();
    private reloadFormComponentSubject = new Subject<number | undefined>();
    private toggleBottomSheetSubject = new Subject<{action: string, component?: Type<unknown>}>();

    constructor() { }    

    // This is events is fired by 'search-doctors.component' each time a user click on search
    profileSearch(message: ProfileSearchMessage) {
        this.profileSearchSubject.next(message);
    }
    onProfileSearch(): Observable<ProfileSearchMessage> {
        return this.profileSearchSubject.asObservable();
    }
    
    // This is events is fired by 'search-doctors-filters.component' each time a user click on filter
    profileSearchFilter(message: ProfileSearchFilterMessage) {
        this.profileSearchFilterSubject.next(message);
    }
    onProfileSearchFilter(): Observable<ProfileSearchFilterMessage> {
        return this.profileSearchFilterSubject.asObservable();
    }
 
    sendUserId(userId: number){
        this.sendUserIdSubject.next(userId);
    }
    onSendUserId(): Observable<number>{
        return this.sendUserIdSubject.asObservable();
    }

    authIframe(){
        this.authIframeSubject.next();
    }
    onAuthIframe(): Observable<void>{
        return this.authIframeSubject.asObservable();
    }

    appointmentCancelled(message: AppointmentCancelledMessage) {
        this.appointmentCancelledSubject.next(message);
    }
    onAppointmentCancelled(): Observable<AppointmentCancelledMessage> {
        return this.appointmentCancelledSubject.asObservable();
    }

    loadChat(message: LoadChatMessage) {
        this.loadChatSubject.next(message);
    }
    onLoadChat(): Observable<LoadChatMessage> {
        return this.loadChatSubject.asObservable();
    }

    openChat(message: OpenChatMessage) {
        this.openChatSubject.next(message);
    }
    onOpenChat(): Observable<OpenChatMessage> {
        return this.openChatSubject.asObservable();
    }

    chatCancelled(message: ChatCancelledMessage) {
        this.chatCancelledSubject.next(message);
    }
    onChatCancelled(): Observable<ChatCancelledMessage> {
        return this.chatCancelledSubject.asObservable();
    }

    chatLoaded(message: ChatLoadedMessage) {
        this.chatLoadedSubject.next(message);
    }
    onChatLoaded(): Observable<ChatLoadedMessage> {
        return this.chatLoadedSubject.asObservable();
    }

    setFormConfiguration(message: FormConfigurationMessage) {
        this.formConfigurationSubject.next(message);
    }
    onFormConfiguration(): Observable<FormConfigurationMessage> {
        return this.formConfigurationSubject.asObservable();
    }

    componentFinishLoading(message: ComponentFinishLoadingMessage) {
        this.componentFinishLoadingSubject.next(message);
    }
    onComponentFinishLoading(): Observable<ComponentFinishLoadingMessage> {
        return this.componentFinishLoadingSubject.asObservable();
    }

    loginCompleted(message: LoginCompletedMessage) {
        this.loginCompletedSubject.next(message);
    }
    onLoginCompleted(): Observable<LoginCompletedMessage> {
        return this.loginCompletedSubject.asObservable();
    }

    logout(message: LogoutMessage) {
        this.logoutSubject.next(message);
    }
    onLogout(): Observable<LogoutMessage> {
        return this.logoutSubject.asObservable();
    }

    sessionExpired(message: SessionExpiredMessage) {
        this.sessionExpiredSubject.next(message);
    }
    onSessionExpired(): Observable<SessionExpiredMessage> {
        return this.sessionExpiredSubject.asObservable();
    }

    serviceError(message: ServiceErrorMessage) {
        this.serviceErrorSubject.next(message);
    }
    onServiceError(): Observable<ServiceErrorMessage> {
        return this.serviceErrorSubject.asObservable();
    }

    sendClinicHistory(message: ClinicHistoryMessage) {
        this.sendClinicHistorySubject.next(message);
    }

    onSendClinicHistory(): Observable<ClinicHistoryMessage>{
        return this.sendClinicHistorySubject.asObservable();
    }

    providerSearch(message: ProviderSearchMessage) {
        this.providerSearchSubject.next(message);
    }

    onProviderSearch(): Observable<ProviderSearchMessage> {
        return this.providerSearchSubject.asObservable();
    }

    goToPastVirtualRequests() {
        this.pastVirtualRequestsSubject.next();
    }
    
    onGoToPastVirtualRequests(): Observable<void> {
        return this.pastVirtualRequestsSubject.asObservable();
    }
    
    virtualRequestFinished() {
        this.virtualRequestFinishedSubject.next();
    }
    
    onOnVirtualRequestFinished(): Observable<void> {
        return this.virtualRequestFinishedSubject.asObservable();
    }

    appointmentChangeReceived(appointmentsByPatient: AppointmentsByPatient) {
        this.appointmentChangeReceivedSubject.next(appointmentsByPatient);
    }
    
    onAppointmentChangeReceived(): Observable<AppointmentsByPatient> {
        return this.appointmentChangeReceivedSubject.asObservable();
    }

    pageScroll(message: PageScrollMessage) {
        this.pageSrollSubject.next(message);
    }
    onPageScroll(): Observable<PageScrollMessage> {
        return this.pageSrollSubject.asObservable();
    }

    scrollTopBody(message: number) {
        this.scrollTopBodySubject.next(message);
    }
    onScrollTopBody(): Observable<number> {
        return this.scrollTopBodySubject.asObservable();
    }
    closeCheckAutocomplete(originComponentId:number){
        this.closeCheckAutocompleteSubject.next(originComponentId);
    }
    onCloseCheckAutocomplete(): Observable<number>{
        return this.closeCheckAutocompleteSubject.asObservable();
    }

    localeChange(message: LocaleChangeMessage) {
        this.localeChangeSubject.next(message);
    }
    onLocaleChange(): Observable<LocaleChangeMessage> {
        return this.localeChangeSubject.asObservable();
    }
    formFinishLoading(idAreaSistema: number) {
        this.formFinishLoadingSubject.next(idAreaSistema);
    }
    onFormFinishLoading(): Observable<number> {
        return this.formFinishLoadingSubject.asObservable();
    }

    sendPublicProfile(message: PublicProfileMessage){
        this.sendPublicProfileSubject.next(message);
    }
    
    onSendPublicProfile(): Observable<PublicProfileMessage> {
        return this.sendPublicProfileSubject.asObservable();
    }

    sendWorkflowData(message: WorkflowDataMessage){
        this.sendWorkflowDataSubject.next(message);
    }

    onSendWorkflowData(): Observable<WorkflowDataMessage>{
        return this.sendWorkflowDataSubject.asObservable();
    }

    sendFlowStepCompleted(message: FlowStepCompletedMessage){
        this.sendFlowStepCompletedSubject.next(message);
    }

    onSendFlowStepCompleted(): Observable<FlowStepCompletedMessage>{
        return this.sendFlowStepCompletedSubject.asObservable();
    }

    onComponentDataSetMessage(message: ComponentDataSetMessage) {
        this.componentDataSetSubject.next(message);
    }

    onSendComponentDataSetMessage(): Observable<ComponentDataSetMessage>{
        return this.componentDataSetSubject.asObservable();
    }

    onWorkflowStateUpdateMessage(message: WorkflowStateUpdateMessage) {
        this.workflowStateUpdateSubject.next(message);
    }

    onSendWorkflowStateUpdateMessage(): Observable<WorkflowStateUpdateMessage>{
        return this.workflowStateUpdateSubject.asObservable();
    }

    openSnackBar(message: OpenSnackBarMessage){
        this.openSnackBarSubject.next(message);
    }

    onOpenSnackBar():Observable<OpenSnackBarMessage>{
        return this.openSnackBarSubject.asObservable();
    }

    hideHeader(){
        this.hideHeaderSubject.next();
    }
    onHideHeader(): Observable<void>{
        return this.hideHeaderSubject.asObservable();
    }

    showHeader(){
        this.showHeaderSubject.next();
    }
    onShowHeader(): Observable<void>{
        return this.showHeaderSubject.asObservable();
    }

    navigateBack(message: NavigationBackMessage){
        this.navigationBackSubject.next(message);
    }
    onNavigateBack(): Observable<NavigationBackMessage>{
        return this.navigationBackSubject.asObservable();
    }

    closeRouteDialog(message: CloseRouteDialogMessage){
        this.closeRouteDialogSubject.next(message);
    }
    onCloseRouteDialog(): Observable<CloseRouteDialogMessage>{
        return this.closeRouteDialogSubject.asObservable();
    }

    closeSearchProviderDialog(){
        this.closeSearchProviderDialogSubject.next();
    }
    onCloseSearchProviderDialog():Observable<void>{
        return this.closeSearchProviderDialogSubject.asObservable();
    }

    sendFloatingComponents(message: FloatingComponentsMessage){
        this.sendFloatingComponentsSubject.next(message);
    }

    onSendFloatingComponents():Observable<FloatingComponentsMessage>{
        return this.sendFloatingComponentsSubject.asObservable();
    }

    clearProviderFilters(){
        this.clearProviderFiltersSubject.next();
    }

    onClearProviderFilters():Observable<void>{
        return this.clearProviderFiltersSubject.asObservable();
    }

    openProviderFilters(){
        this.openProviderFiltersSubject.next();
    }

    onOpenProviderFilters():Observable<void>{
        return this.openProviderFiltersSubject.asObservable();
    }

    providerFiltersCount(count:number){
        this.providerFiltersCountSubject.next(count);
    }

    onProviderFiltersCount():Observable<number>{
        return this.providerFiltersCountSubject.asObservable();
    }

    closeWorkFlowRequest(ruta?: string): void {
        this.closeWorkFlowRequestSubject.next(ruta);
    }

    onCloseWorkFlowRequest(): Observable<string | undefined>{
        return this.closeWorkFlowRequestSubject.asObservable();
    }

    hideAnonymousMenu(): void {
        this.hideAnonymousMenuSubject.next();
    }

    onHideAnonymousMenu(): Observable<void>{
        return this.hideAnonymousMenuSubject.asObservable();
    }

    showAnonymousMenu(): void {
        this.showAnonymousMenuSubject.next();
    }

    onShowAnonymousMenu(): Observable<void>{
        return this.showAnonymousMenuSubject.asObservable();
    }

    controlComponentClassname(message: ControlComponentClassnameMessage){
        this.controlComponentClassnameSubject.next(message);
    }

    onControlComponentClassname():Observable<ControlComponentClassnameMessage>{
        return this.controlComponentClassnameSubject.asObservable();
    }

	showSignupLoginHeaderMenu(arg: boolean) {
        this.showSignupLoginHeaderMenuSubject.next(arg);
    }

    onShowSignupLoginHeaderMenu(): Observable<boolean> {
        return this.showSignupLoginHeaderMenuSubject.asObservable();
    }

	showWelcomeImage(arg: boolean) {
        this.showWelcomeImageSubject.next(arg);
    }

    onShowWelcomeImage(): Observable<boolean> {
        return this.showWelcomeImageSubject.asObservable();
    }

	refreshProfileMenu() {
        this.refreshProfileMenuSubject.next();
    }

    onRefreshProfileMenu(): Observable<void> {
        return this.refreshProfileMenuSubject.asObservable();
    }

    openRouteDialog(){
        this.openRouteDialogSubject.next();        
    }

    onOpenRouteDialog(){
        return this.openRouteDialogSubject.asObservable();
    }

    allRouteDialogClosed(){
        this.allRouteDialogClosedSubject.next();
    }

    onAllRouteDialogClosed(){
        return this.allRouteDialogClosedSubject.asObservable();
    }

    publicProfilePage(){
        this.publicProfilePageSubject.next();
    }
    
    onPublicProfilePage(){
        return this.publicProfilePageSubject.asObservable();
    }

    dialogOpen(message: DialogOpenMessage){
        this.dialogOpenSubject.next(message);
    }    
    onDialogOpen() : Observable<DialogOpenMessage> {
        return this.dialogOpenSubject.asObservable();
    }

    dialogClose(message: DialogCloseMessage){
        this.dialogCloseSubject.next(message);
    }    
    onDialogClose() : Observable<DialogCloseMessage> {
        return this.dialogCloseSubject.asObservable();
    }

    sendTerritorialDivisionCountry(message: TerritorialDivionCountryMessage){
        this.territorialDivisionCountrySubject.next(message);
    }

    onSendTerritorialDivisionCountry():Observable<TerritorialDivionCountryMessage>{
        return this.territorialDivisionCountrySubject.asObservable();
    }

    sendPublicProfileHeaderVisibilityChange(message: PublicProfileHeaderVisibilityChangeMessage){
        this.publicProfileHeaderVisibilityChangeSubject.next(message);
    }

    onPublicProfileHeaderVisibilityChange():Observable<PublicProfileHeaderVisibilityChangeMessage>{
        return this.publicProfileHeaderVisibilityChangeSubject.asObservable();
    }

    sendPublicProfileAction(message: PublicProfileActionMessage){
        this.publicProfileActionSubject.next(message);
    }

    onPublicProfileAction():Observable<PublicProfileActionMessage>{
        return this.publicProfileActionSubject.asObservable();
    }

    sendReloadFormComponent(idAreaSistema?: number): void {
        this.reloadFormComponentSubject.next(idAreaSistema);
    }

    onReloadFormComponent():Observable<number | undefined>{
        return this.reloadFormComponentSubject.asObservable();
    }

    sendToggleBottomSheetSubject(args: {action: string, component?: Type<unknown>}): void {
        this.toggleBottomSheetSubject.next(args);
    }

    onToggleBottomSheetSubject():Observable<{action: string, component?: Type<unknown>}>{
        return this.toggleBottomSheetSubject.asObservable();
    }
}

export class ClinicHistoryMessage {
    clinicHistory: ClinicHistory;

    constructor(clinicHistory : ClinicHistory) {
        this.clinicHistory = clinicHistory;
    }
}

export class ProfileSearchMessage {
    profileType: PublicProfileType;
    searchText: string;
    conceptNav: string;
    territorialDivisionIdentification: string;    
    doctorSearch: boolean; 
    conceptId : number;
    conceptClaseId : number;
}

export class ProfileSearchFilterMessage {
    conceptsIds: number[];
}

export class AppointmentCancelledMessage {
    idEtapaSolicitud: number;
}

export class LoadChatMessage {
    idEtapaSolicitud: number;
}

export class OpenChatMessage {    
}

export class ChatCancelledMessage {
    reason: string;

    constructor(reason : string) {
        this.reason = reason;
    }
}

export class ChatLoadedMessage {
    hasAgentMessage: boolean;

    constructor(hasAgentMessage : boolean) {
        this.hasAgentMessage = hasAgentMessage;
    }
}

export class FormConfigurationMessage {
    config: FormConfiguration;    
}

export class ComponentFinishLoadingMessage {
    idFormulario: number;
    idControl: number;
}

export class SessionExpiredMessage {    
    returnUrl: string;

    constructor(returnUrl: string) {
        this.returnUrl = returnUrl;
    }
}

export class LogoutMessage {
}

export class LoginCompletedMessage {
    user: UserPerson;
    
    constructor(user: UserPerson) {
        this.user = user;
    }
}

export class AppointmentMessage {
    appointment: AppointmentsByPatient;
    
    constructor(appointment: AppointmentsByPatient) {
        this.appointment = appointment;
    }
}

export class ServiceErrorMessage {
    innerError: any;
    message: string;
    displayMessage: string;

    constructor(message : string, displayMessage : string = "") {
        this.message = message;
        this.displayMessage = displayMessage;
    }
}

export class ProviderSearchMessage {
    searchText: string;
    conceptNav: string;
    territorialDivisionIdentification: string;
    filtros: string;
    conceptId : number;
    conceptClaseId : number;
    countryIdentification: string;
}

export class PageScrollMessage {
    direction: Direction;
    offset: number;
    containerTopPosition: number;
    routeDialogScroll: boolean = false;

    constructor(direction: Direction, offset: number, containerTopPosition: number, routeDialogScroll: boolean) {
        this.direction = direction;
        this.offset = offset;
        this.containerTopPosition = containerTopPosition;
        this.routeDialogScroll = routeDialogScroll;
    }
}

export class LocaleChangeMessage {
    language: string;
}

export class PublicProfileMessage{
    publicProfile: PublicProfile;
}

export class WorkflowDataMessage{
    data: any;
}

export class FlowStepCompletedMessage{
    state: IWorkflowDataState;
}

export class WorkflowStateUpdateMessage{
    state: IWorkflowDataState;
}

export class ComponentDataSetMessage {
    componentName: ComponentName;
    data: any;
}

export class OpenSnackBarMessage {
    text: string;
    action: string;
    onAction: any;
    type: SnackBarType;
    duration?: number;
}

export class NavigationBackMessage {
    dialogId: number;
    
    constructor(dialogId: number) {
        this.dialogId = dialogId
    }
}

export class CloseRouteDialogMessage {    
}

export class FloatingComponentsMessage{
    componentsData: FormComponentData[] = [];
}

export enum SnackBarType{
    SUCCESS = 1,
    ERROR = 2,
    INFO = 3,
    ALERT = 4
}

export class ControlComponentClassnameMessage{
    conponentName: string;
    classname: string;
}

export class DialogOpenMessage {    
    dialogName: DialogName;

    constructor(dialogName: DialogName) {
        this.dialogName = dialogName;
    }
}
export class DialogCloseMessage {   
    dialogName: DialogName;

    constructor(dialogName: DialogName) {
        this.dialogName = dialogName;
    } 
}
export enum DialogName {
    UserProfileMenu = 1,
    BottomSheetMenu = 'bottomSheetMenu'
}
export class TerritorialDivionCountryMessage{
    country: TerritorialDivision;
}
export class PublicProfileHeaderVisibilityChangeMessage {
    headerVisible: boolean;
    profile: PublicProfile;
    publicProfileAvatarComponentModel: any;
    fromRouteDialog: boolean;

    constructor(headerVisible: boolean, profile: PublicProfile, publicProfileAvatarComponentModel: any, fromRouteDialog: boolean) {
        this.headerVisible = headerVisible;
        this.profile = profile;
        this.publicProfileAvatarComponentModel = publicProfileAvatarComponentModel;
        this.fromRouteDialog = fromRouteDialog;
    }
}

export class PublicProfileActionMessage {
    paymentClick: boolean = false;
    appointmentClick: boolean = false;

    constructor(paymentClick: boolean, appointmentClick: boolean) {
        this.paymentClick = paymentClick;
        this.appointmentClick = appointmentClick;
    }
}