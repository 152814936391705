import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
 name: "highlight"
})
export class HighlightPipe implements PipeTransform { 

    transform(value: any, search: string, tagName?: string): any {
        if (!search) {
            return value;
        }

        if(!tagName)
            tagName = "mark";

        var filteredSearch = search.match(/[!^A-zÀ-ú0-9]/gi)?.join('');

        filteredSearch = filteredSearch == undefined ? '' : filteredSearch;

        var re = new RegExp(filteredSearch, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
        
        return value.replace(re, `<${tagName} class="highlight">$&</${tagName}>`);
    }
}