<mat-sidenav-container class="home-sidenav-container" [ngClass]="{'anonymous-user': !loggedInUser}">
    <mat-sidenav #sidenav class="home-sidenav" mode="side" opened [disableClose]="true">
        <!--<app-menu></app-menu>-->
        <app-bottom-sheet-menu></app-bottom-sheet-menu>
        <app-dynamic [componentName]="menuComponentName" [componentData]=""></app-dynamic>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content" (scroll)="onScroll($event)" >
        <div class="sidenav-body-container">
            <div class="sidenav-body-wrapper">
                <router-outlet></router-outlet>
            </div>            
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>