export class DialogData {
    id: string;
    showHeader: boolean = true;
    headerIconClass: string;
    showHeaderIcon: boolean = false;
    title: string;
    description: string;
    message: string;
    colorConfirmButton: string;
    showConfirmButton: boolean = true;
    confirmText: string;
    colorCancelButton: string;
    showCancelButton: boolean = true;
    cancelText: string;
    data: any;
    loading: boolean = false;
    animationIn: string;
    animationOut: string;
    showCloseTextButton: boolean;
    loadLicense:boolean;
}

export class MapDialogData extends DialogData{
    showWazeButton: boolean;
}