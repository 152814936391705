import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { L10nLocale, L10N_LOCALE, L10nTranslationService } from 'angular-l10n';
import { UserService } from 'src/app/core/services/user.service';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { Dependent } from 'src/app/shared/models/people/dependent.model';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { Subject } from 'rxjs';
import { CloseRouteDialogMessage, MessageBusService, OpenSnackBarMessage, SnackBarType } from 'src/app/core/services/message-bus.service';
import { takeUntil } from 'rxjs/operators';
import { AvatarUploadComponentOptions, AvatarUploadedFileModel } from '../../avatar-upload/avatar-upload.component';
import CommonTypesHelper from 'src/app/shared/helpers/common-types-helper';
import * as moment from 'moment';

@Component({
  selector: 'app-view-dependent',
  templateUrl: './view-dependent.component.html',
  styleUrls: ['./view-dependent.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ViewDependentComponent implements OnInit, OnDestroy {
  
  private ngUnsubscribe = new Subject();
  dependent: ViewDependentModelDto | undefined;
  fechaNacimiento: string | null;
  saving: boolean = false;
  translations = {
    other: "",
    son: "",
    daughter: "",
    father: "",
    mother: "",
    language: "",
    man: "",
    women: ""    
  };
  avatarUploadOptions : AvatarUploadComponentOptions;
  
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    @Inject(L10N_LOCALE) public locale: L10nLocale,   
    private translation: L10nTranslationService,
    private router: Router,
    private dialog: MatDialog,
    private messageBusService: MessageBusService,
  ) {
    moment.locale(this.locale.language);
    this.avatarUploadOptions = new AvatarUploadComponentOptions();
    this.avatarUploadOptions.requiredFileType = "image/png,image/jpeg";
    this.avatarUploadOptions.selectFileLabel = "añadir foto";
    this.avatarUploadOptions.changeFileLabel = "Editar";
    this.avatarUploadOptions.showFileOnceUpload = true;
  }

  ngOnInit(): void {
    const dependentIdQs = this.route.snapshot.paramMap.get('id');

    if(!dependentIdQs) {
        // TODO: redirect to home???
        return;
    }

    let dependentId = parseInt(dependentIdQs);

    this.translation.onChange()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
      next: () => {
          this.translations.other = this.translation.translate('shared.profileMenu.text8');
          this.translations.son = this.translation.translate('shared.profileMenu.text9');
          this.translations.daughter = this.translation.translate('shared.profileMenu.text10');
          this.translations.father = this.translation.translate('shared.profileMenu.text11');
          this.translations.mother = this.translation.translate('shared.profileMenu.text12');
          this.translations.man = this.translation.translate('man');
          this.translations.women = this.translation.translate('women');  
          
          this.avatarUploadOptions.selectFileLabel = this.translation.translate('shared.viewUser.text8');
          this.avatarUploadOptions.changeFileLabel = this.translation.translate('shared.viewUser.text3');
          this.avatarUploadOptions.changePictureLabel = this.translation.translate('shared.viewUser.text9');
          this.avatarUploadOptions.deleteFileLabel = this.translation.translate('shared.viewUser.text10');
      }
    });

    this.userService.getUserDependents()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(d => {
      let dependents = d as ViewDependentModelDto[];

      // Lookup dependent by Id
      this.dependent = dependents.find(d => d.id == dependentId);

      if (!this.dependent) {
          // TODO: redirect to home???
          return;
      }

      this.setUpDependentExtraData();
    });

    // On Header Back click
    this.messageBusService.onNavigateBack()
      .pipe(takeUntil(this.ngUnsubscribe))  
      .subscribe(m => {
        this.messageBusService.closeRouteDialog(new CloseRouteDialogMessage());
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.messageBusService.refreshProfileMenu();
  }

  setUpDependentExtraData() {
    if (!this.dependent)
        return;

    this.dependent.fullName = this.dependent.nombre + " " + this.dependent.apellido;      
    this.dependent.relationshipName = this.userService.getDependentRelationshipName(this.dependent);

    let feNacimiento = this.dependent.fechaNacimiento ? new Date(this.dependent.fechaNacimiento) : null;
    this.fechaNacimiento = CommonTypesHelper.dateHasValue(feNacimiento) ? moment(feNacimiento).format('D [de] MMMM[,] yyyy') : null;

      if (this.dependent.sexo && this.dependent.sexo != "9") {
          if (this.dependent.sexo.toLocaleLowerCase() == "m") {
            this.dependent.sexName = this.translations.man;
            this.dependent.sexIconClass = "phr-iconset-hombre";
          }
          else {
            this.dependent.sexName = this.translations.women;
            this.dependent.sexIconClass = "phr-iconset-mujer";
          } 
              
      }
  }  

  onEditDependentClick() {
    this.router.navigate(['configuracion/dependiente/edicion/' + this.dependent?.id]);
  }  

  onDeleteClick() {
    //this.saving = true;

    let dialogData = new DialogData();
      dialogData.title = this.translation.translate('shared.viewDependent.text9');
      dialogData.message = this.translation.translate('shared.viewDependent.text10');
      dialogData.confirmText = this.translation.translate('shared.viewDependent.text14');  
      dialogData.cancelText = this.translation.translate('shared.viewDependent.text12');
      dialogData.headerIconClass = 'phr-iconset-alert';
      dialogData.showHeaderIcon = true;
      dialogData.showCancelButton = true;
      dialogData.id = 'confirm-delete-user-dialog';
      dialogData.animationIn = 'confirm-logout-animation-in';
      dialogData.animationOut = 'confirm-logout-animation-out';
  
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: dialogData,
        panelClass: ['confirm-delete-user-dialog', 'confirm-logout-animation-in']
      });
  
      dialogRef.afterClosed().subscribe(accepted => {
        if(accepted){
          this.saving = true;

          this.userService.deleteDependent(this.dependent as Dependent).subscribe(r => {
            this.saving = false;

            this.showDeleteSuccessToast();

            this.messageBusService.closeRouteDialog(new CloseRouteDialogMessage());

          },
          error => {
            this.saving = false;
          });
        }
      });
  }

  showDeleteSuccessToast() {
    let message = new OpenSnackBarMessage();
    message.text = this.translation.translate('shared.viewDependent.text13');
    message.type = SnackBarType.SUCCESS;
    message.duration = 3000;
   
    this.messageBusService.openSnackBar(message);
  }

  onAvatarUploaded(file: AvatarUploadedFileModel) {
    if (this.dependent) this.dependent.ubicacionLogo = file?.webUrl;
  }

}

export class ViewDependentModelDto extends Dependent {  
    relationshipName: string;
    fullName: string;
    sexName: string;
    sexIconClass: string;  
}
