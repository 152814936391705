<div class="check-autocomplete-container" [ngClass]="clasnameComponent">
    <!--Chip button-->
    <button #button class="button-autocomplete" mat-stroked-button (click)="onClickAutocomplete()" [ngClass]="getClassNameButton()" [dropdownTriggerFor]="dropdown">
        <div class="wrapper">
            <mat-icon fontSet="phr-iconset" class="icon-type" *ngIf="iconClassName" [ngClass]="iconClassName"></mat-icon>
            <span class="button-text">{{getButtonText()}}</span>
            <mat-icon fontSet="phr-iconset" class="arrow phr-iconset-arrow-down" *ngIf="!(loading && !visible)"></mat-icon>        
            <mat-spinner class="spinner" diameter="19" *ngIf="loading && !visible"></mat-spinner>
            <a class="button-clear" (click)="onClearFilter()" *ngIf="selectedOptions.length > 0">
                <mat-icon fontSet="phr-iconset" class="phr-iconset-close-heavy"></mat-icon>
            </a>
        </div>
    </button>

    <!--Dropdown options-->
    <app-dropdown #dropdown panelClass="check-autocomplete-dropdown-container" [fullscreen]="isMobile" [overlayTransparent]="!isMobile" (overlayRef)="setOverlayRef($event)">
        <div class="dropdown-header" *ngIf="isMobile">
            <span>{{placeholder}}</span>
            <button class="button-close" mat-icon-button tabindex="-1" (click)="closeDropdown()">
                <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Cerrar"></mat-icon>
            </button>
        </div>
        <form class="dropdown-content" [formGroup]="checkAutocompleteForm" [hidden]="!visible" [ngClass]="{'input-hidden': !showInputSearch }">
            <mat-form-field class="input-field" floatLabel="never" appearance="outline">
                <input #searchInput
                    type="text" 
                    placeholder="{{placeholder}}"
                    matInput
                    [formControl]="control" 
                    (focus)="onFocusInputSearchDropdown()"
                    formControlName="control" />
            </mat-form-field>
            <div class="options-container" appTouchMoveListener [ngClass]="{'loading': visible && loading, 'use-option-icon': viewConfig && viewConfig.usarIcono }">
                
                <!--Options Groups-->
                <div *ngFor="let group of filteredGroups">
                    <div class="option-group-title" *ngIf="group.titulo && group.results && group.results.length > 0">
                        <span>{{group.titulo}}</span>
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-info" *ngIf="group.tooltip" [matTooltip]="group.tooltip"></mat-icon>
                    </div>

                    <!--Options-->
                    <mat-option *ngFor="let option of group.results; let i=index" [value]="selectedOptions" (click)="optionClicked($event, option)">
                        <mat-checkbox color="primary" [checked]="isOptionSelected(option)" (change)="toggleSelection(option)" (click)="$event.stopPropagation()">
                            <mat-icon fontSet="phr-iconset" [class]="viewConfig.claseCssIcono" *ngIf="viewConfig && viewConfig.usarIcono"></mat-icon>
                            <div class="text-container" [innerHtml]="optionDisplayNameFtn(option)"></div>
                        </mat-checkbox>                
                    </mat-option>
                </div>

                <!--Empty results-->
                <mat-option *ngIf="filteredOptions.length == 0">
                    <span *ngIf="loading">{{ 'shared.checkAutocomplete.searching' | translate:locale.language }}</span>
                    <span *ngIf="!loading">{{ 'shared.checkAutocomplete.emptyResult' | translate:locale.language }}</span>
                </mat-option>    
                <div class="spinner-container">
                    <mat-spinner class="spinner" diameter="19"></mat-spinner>
                </div>
            </div>        
        </form>
        <div class="dropdown-footer" *ngIf="isMobile">
            <button mat-button mat-flat-button class="btn-apply primary2" (click)="closeDropdown()">
                {{ 'shared.checkAutocomplete.apply' | translate:locale.language }}
            </button>
        </div>
    </app-dropdown>

</div>
