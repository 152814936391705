<div class="select-patient-relationship-container">
    <div class="content">
        <span class="title">{{ 'shared.selectPatientRelationship.title' | translate:locale.language }}</span>
        <form class="patient-relationship-type-form" [formGroup]="patientRelationshipTypeForm" (ngSubmit)="onClickContinue()" *ngIf="showForm">
            <div class="patient-relationship-wrapper">
                <mat-radio-group formControlName="relationship" [value]="model.patientRelationship" *ngIf="patientRelationshipTypeForm.get('relationship') as relationshipControl">
                    <mat-radio-button [value]="user.id" [checked]="relationshipControl.value == user.id">
                        <img [src]="getUserAvatar(user)"> 
                        <span class="name">{{ user.nombre }}</span>
                        <span>{{'shared.selectPatientRelationship.userRelationship' | translate:locale.language}}</span>
                    </mat-radio-button>                    
                    <mat-radio-button *ngFor="let dependent of dependents" [value]="dependent.idPersona" [checked]="relationshipControl.value == dependent.idPersona">
                        <img [src]="getDependentAvatar(dependent)"> 
                        <span class="name">{{dependent.nombre}}</span>
                        <span>{{getRelationshipText(dependent)}}</span>
                    </mat-radio-button>
                    <mat-radio-button value="2" [checked]="relationshipControl.value == 2">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-plus-heavier"></mat-icon>
                        <span class="name">{{'shared.selectPatientRelationship.otherText' | translate:locale.language}}</span>
                        <span>{{'shared.selectPatientRelationship.otherDescription' | translate:locale.language}}</span>
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="button-container">
                <button mat-button mat-flat-button class="primary" [ngClass]="{'loading': loading }">
                    {{ 'shared.selectPatientRelationship.buttonText' | translate:locale.language }}
                </button>
            </div>
        </form>
    </div>
</div>