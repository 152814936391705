import { Injectable, Inject, Optional } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

import {
    L10nConfig,
    L10nLoader,
    L10nStorage,
    L10nLocale,
    L10nTranslationLoader,
    L10nProvider,
    L10nValidation,
    L10N_LOCALE,
    L10nNumberFormatOptions,
    L10nDateTimeFormatOptions,
    parseDigits,
    L10nTranslationService
} from "angular-l10n";


export const l10nConfig: L10nConfig = {
    format: 'language',
    providers: [
        { name: 'app', asset: './assets/i18n/app', options: { version: '1.0.0' } }
    ],
    fallback: false,
    cache: true,
    keySeparator: '.',
    defaultLocale: { language: 'es', currency: 'USD', dateLanguage: 'es-ES' },
    schema: [
        { locale: { language: 'es', currency: 'USD', dateLanguage: 'es-ES' }, dir: 'ltr', text: 'Panamá' },
        { locale: { language: 'en', currency: 'USD', dateLanguage: 'en-US' }, dir: 'ltr', text: 'United States' }        
    ],
    defaultRouting: false
};

export function l10nPreload(translation: L10nTranslationService, translationLoader: L10nTranslationLoader): () => Promise<void> {
    return () => new Promise((resolve) => {
        translationLoader.get('es', { name: 'app', asset: './assets/i18n/app', options: { version: '1.0.0' } })
            .subscribe({
                next: (data) => {
                    translation.addData(data, 'es');
                },
                complete: () => resolve()
            });
    });
}

export function initL10n(l10nLoader: L10nLoader): () => Promise<void> {
    return () => l10nLoader.init();
}

@Injectable() export class AppStorage implements L10nStorage {

    private hasStorage: boolean;

    constructor() {
        this.hasStorage = typeof Storage !== 'undefined';
    }

    public async read(): Promise<L10nLocale | null> {
        if (this.hasStorage) {
            let locale = JSON.parse(localStorage.getItem('locale') || '{}');

            if (!locale || !locale.language)
                locale = l10nConfig.defaultLocale;

            return Promise.resolve(locale);
        }
        return Promise.resolve(null);
    }

    public async write(locale: L10nLocale): Promise<void> {
        if (this.hasStorage) {
            localStorage.setItem('locale', JSON.stringify(locale));
        }
    }
}

@Injectable() export class HttpTranslationLoader implements L10nTranslationLoader {

    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(@Optional() private http: HttpClient) { }

    public get(language: string, provider: L10nProvider): Observable<{ [key: string]: any }> {

        const url = `${provider.asset}-${language}.json`;
        const options = {
            headers: this.headers,
            params: new HttpParams().set('v', provider.options.version)
        };
        return this.http.get(url, options);
    }
}

@Injectable() export class LocaleValidation implements L10nValidation {

    constructor(@Inject(L10N_LOCALE) private locale: L10nLocale) { }

    public parseNumber(value: string, options?: L10nNumberFormatOptions, language = this.locale.language): number | null {
        if (value === '' || value == null) return null;

        let format = {
            ...parseDigits(options && options.digits ? options.digits :  '') 
        };

        format.minimumIntegerDigits = 1;
        format.minimumFractionDigits = 0;
        format.maximumFractionDigits = 0;

        let decimalSeparator: string;
        switch (language) {
            case 'es':
                decimalSeparator = ',';
                break;
            default:
                decimalSeparator = '.';
        }

        const pattern = `^-?[\\d]{${format.minimumIntegerDigits},}(\\${decimalSeparator}[\\d]{${format.minimumFractionDigits},${format.maximumFractionDigits}})?$`;
        const regex = new RegExp(pattern);
        return regex.test(value) ? parseFloat(value.replace(decimalSeparator, '.')) : null;
    }

    public parseDate(value: string, options?: L10nDateTimeFormatOptions, language = this.locale.language): Date | null {
        return null;
    }

}