<div class="company-logo-container" *ngIf="!loading" [hidden]="isCliniwebPhr&& !showCompanyImage">
    <div class="company-img" *ngIf="isLicensed && showCompanyImage"></div>
    <div class="company-logo-wrapper" *ngIf="!isLicensed">
        <div class="company-item">
            <div class="company-icon">
                <img [src]="companyLogo" />
            </div>
            <span>{{'shared.companyLogo.directoryName' | translate:locale.language:{ companyName: companyName } }}</span>
        </div>
        <div class="divider"></div>
        <div class="company-item cliniweb">
            <div class="company-icon">
                <img src="assets/images/cliniweb/cliniweb-favicon.png" />
            </div>
            <span>
                {{'shared.companyLogo.allianceText' | translate:locale.language }}
                <a href="https://www.cliniweb.com/" target="_blank">                
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-new-window"></mat-icon>
                </a>
            </span>
        </div>
    </div>
</div>