import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { L10nLocale, L10N_LOCALE, L10nTranslationService } from 'angular-l10n';
import { Subject } from 'rxjs';
import { CloseRouteDialogMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { takeUntil } from 'rxjs/operators';
import { PaymentService } from 'src/app/core/services/payment-service';
import { BaseService } from 'src/app/core/services/base.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserPayment } from 'src/app/shared/models/finance/payments/user-payment.model';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { OnlinePaymentModel } from 'src/app/shared/models/workflow/models/online-payment.model';
import { PaymentRouteDialogModel } from '../../dialogs/route-dialog/route-dialog.component';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { AppStorageService, STORAGE } from 'src/app/core/services/app-storage.service';
import { CustomCurrencyPipe } from 'src/app/shared/pipes/custom-currency.pipe';

@Component({
  selector: 'app-user-payment',
  templateUrl: './user-payment.component.html',
  styleUrls: ['./user-payment.component.css'],
  providers: [CustomCurrencyPipe],
  encapsulation: ViewEncapsulation.None
})
export class UserPaymentComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  payment: UserPayment;
  loading: boolean = false;
  loadingPayment: boolean = false;
  loadingPdf: boolean = false;
  
  constructor(    
    @Inject(L10N_LOCALE) public locale: L10nLocale,   
    private translation: L10nTranslationService,
    private messageBusService: MessageBusService,
    private paymentsService: PaymentService,
    private baseService: BaseService,
    private route: ActivatedRoute,
    private router: Router,
    private publicProfileService: PublicProfileService,
    private routeDialogService: RouteDialogService,
    private appStorageService: AppStorageService,
    private currencyPipe : CustomCurrencyPipe
  ) {    
  }

  ngOnInit(): void {
    const transaccionId = this.route.snapshot.paramMap.get('id');

    if (!transaccionId) {
      this.gotoPayments();
      return;
    }

    this.loading = true;

    this.paymentsService.getUserPayment(parseInt(transaccionId))
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(payment => {
        if (!payment) {
          this.gotoPayments();
          return;
        }
      
        this.payment = payment;        

        //console.log(payment);

        this.loading = false;
    },
    error => {
        this.baseService.handleServiceError(error, "Error getting payment");

        this.loading = false;
    });

    // On Header Back click
    this.messageBusService.onNavigateBack()
      .pipe(takeUntil(this.ngUnsubscribe))  
      .subscribe(m => {
        this.gotoPayments();
      }
    );
  }

  gotoPayments() {
    this.router.navigate(["/configuracion/pagos"]);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getFormatedAmount(amount: number) {
    return this.currencyPipe.transform(amount.toFixed(2), 'USD','','1.2-2','en-US');
  }

  onPaymentClick() {
    /*let url = this.publicProfileService.getPublicProfileUrl(this.payment.identificadorPerfilPublico);
    this.routeDialogService.closeDialogsAndNavigate(url, {payment: true});*/
    let companyName = this.baseService.getCliniwebDirectoryCompanyName();

    this.loadingPayment = true;

    this.publicProfileService.getPublicProfile(this.payment.identificadorPerfilPublico, this.locale.language, companyName)
      .subscribe(publicProfile => {
        let dto = new OnlinePaymentModel();
    
        dto.idResponsableServicio = publicProfile.id;
        dto.nombreResponsableServicio = publicProfile.nombrePersona;
        dto.urlAvatarResponsableServicio = publicProfile.ubicacionFoto;
        dto.nickname = publicProfile.username;
        dto.especialidadResponsableServicio = publicProfile.especialidad;
        dto.idEmpresa = publicProfile.idEmpresa;  
        dto.nombreEmpresa = companyName;
        dto.emailResponsableServicio = publicProfile.emailRemitenteNotificacion;  
        dto.idEmpresaResponsableServicio = publicProfile.idEmpresa;
        dto.perfilPublico = publicProfile;
        
        this.loadingPayment = false;
        
        this.appStorageService.setItemSession(STORAGE.ONLINE_PAYMENT_WORKFLOW_BOOTSTRAP_STATE, dto);
        this.routeDialogService.closeDialogsAndNavigate("/pagos/checkout");

        /*let routeDialogModel = new PaymentRouteDialogModel();
        routeDialogModel.state = dto;        
        this.routeDialogService.openDialog(routeDialogModel);*/
      },
      error => {
        this.baseService.handleServiceError(error, "Public profile not found");

        this.loadingPayment = false;
      });
  }
  
  onDownloadTransactionPdfClick() {
    this.loadingPdf = true;    

    this.paymentsService.getUserPaymentPdf(this.payment.id).subscribe((doc) => {
      let downloadURL = window.URL.createObjectURL(doc);
      let companyName = this.baseService.getCliniwebDirectoryCompanyName();
      let patientName = this.payment.nombrePacientePhr;
      let dateTimestamp = new Date(this.payment.fechaTransaccion).toDateString();
      let transactionTranslation = this.translation.translate("shared.userPayment.text8");

      let link = document.createElement('a');
      link.href = downloadURL;
      link.download = (`${transactionTranslation} ${patientName} - ${companyName} - ${dateTimestamp}`+ ".pdf");

      link.click();    

      this.loadingPdf = false;      
    },
    (error => {
      console.error("Error getting pdf");

      this.loadingPdf = false;      
    }));
  }
}



