import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ComponentLookup } from '../../decorators/component-lookup.decorator';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { AppStorageService, STORAGE } from 'src/app/core/services/app-storage.service';
import { Link } from '../../models/people/link.model';
import { BaseService } from 'src/app/core/services/base.service';
import { LayoutService } from 'src/app/core/services/layout.service';
import { Subject, zip } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';
import { UserPerson } from '../../models/people/user-person.model';
import { ProfileMenuDependentModel } from '../menu/profile/profile-menu.component';
import { SystemAreas } from '../../enums/system-areas.enum';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ConfigurationPageDialogModel } from '../dialogs/route-dialog/route-dialog.component';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { Platform } from '@angular/cdk/platform';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Dependent } from '../../models/people/dependent.model';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-my-health',
  templateUrl: './my-health.component.html',
  styleUrls: ['./my-health.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('MyHealthComponent')
export class MyHealthComponent implements OnDestroy, OnInit {
  @ViewChild('tabGroup') tabGroup!: MatTabGroup;
  user: UserPerson;
  dependents: ProfileMenuDependentModel[] = [];

  loading: boolean;
  loadingDependents: boolean;
  selectedTabIndex: number = 0;

  items: Link[] = [];
  private ngUnsubscribe = new Subject();

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private appStorageService: AppStorageService,
    private baseService: BaseService,
    private userService: UserService,
    private utilsService: UtilsService,
    private routeDialogService: RouteDialogService,
    private messageBusService: MessageBusService,
    private layoutService: LayoutService,
    private router: Router,
    private route: ActivatedRoute,
    public platform: Platform,
  ) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    const links: Link[] = this.appStorageService.getItemSession(STORAGE.MENU, true);
    const link = links.find(x => x.idAreaSistema == SystemAreas.MI_SALUD);
    this.items = link?.subMenu || [];

    this.loadData();

    this.messageBusService.onRefreshProfileMenu()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(m => {
        this.loadData();
      }
    );
  }

  onLinkSelected(link:Link, patientId?: number){
    let linkUrl = link.identificadorUrl;

    if (patientId){
      linkUrl += `?p=${patientId}`;
    }
    
    this.baseService.goToRoute(linkUrl);
    this.layoutService.postNavigate();
  }

  onAddFamily(event:any){
    event.stopPropagation();
    
    let routeDialogModel = new ConfigurationPageDialogModel();
    routeDialogModel.route = "configuracion/dependiente/edicion/0";
    routeDialogModel.state = null;

    this.routeDialogService.openDialog(routeDialogModel);
  }

  setTabSelected(event: any){
    if(this.loading)
      return;

    if(event.index == (this.dependents.length + 1)){
      return;
    }
    else{
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { p: event.index == 0 ? null : this.dependents[event.index - 1].idPersona },
        queryParamsHandling: 'merge'
      });
    }
  }

  private loadData(){
    this.loading = true;

    const _getUserLoggedIn$ = this.userService.getUserPersonLoggedIn();
    const _getUserDependents$ = this.userService.getUserDependents();
    const _queryParams$ = this.route.queryParamMap;

    zip(_getUserLoggedIn$, _getUserDependents$, _queryParams$)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(([user, dependents, paramMap])=>{      
      this.loadUserData(user);
      this.loadDepedentsData(dependents);
      this.configTabIndexByParam(paramMap);

      this.loading = false;
    });
  }

  private loadUserData(user:UserPerson | null){
    if(!user)
      return;

    this.user = user;

    let birthDate = this.user.fechaNacimiento 
      ? new Date(this.user.fechaNacimiento) 
      : null;

    if (!this.user.ubicacionLogo)
      this.user.ubicacionLogo = this.utilsService.getPersonDefaultAvatarUrl(birthDate, this.user.sexo); 
  }

  private loadDepedentsData(dependents: Dependent[]){
    this.dependents = dependents as ProfileMenuDependentModel[];

    // Order by age desc
    this.dependents = this.dependents.sort((d1: ProfileMenuDependentModel, d2: ProfileMenuDependentModel) => {
      let getTime = (date: Date) => {return date != null ? new Date(date).getTime() : 0;};
      
      return getTime(d1.fechaNacimiento) - getTime(d2.fechaNacimiento);
    });

    this.dependents.forEach(dependent => {
      dependent.fullName = dependent.nombre + " " + dependent.apellido;      
      dependent.relationshipName = this.userService.getDependentRelationshipName(dependent);

      let birthDate = dependent.fechaNacimiento
        ? new Date(dependent.fechaNacimiento) 
        : null;

      if (!dependent.ubicacionLogo)
        dependent.ubicacionLogo = this.utilsService.getPersonDefaultAvatarUrl(birthDate, dependent.sexo);
    });
  }

  private configTabIndexByParam(paramMap:ParamMap){

    const idPersona = paramMap.get('p');

    if(!idPersona)
      return;

    const indexDependent = this.dependents.findIndex(d => d.idPersona === +idPersona);

    if(indexDependent != -1){
      this.selectedTabIndex = indexDependent + 1;
    }
  }
}
