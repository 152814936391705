import { Injectable } from '@angular/core';
import { IStepFlow } from 'src/app/shared/interfaces/step-flow';
import { IWorkflowDataState } from 'src/app/shared/interfaces/workflow-data-state';
import { WorkflowItem } from 'src/app/shared/models/systems/workflow.model';
import { AuthenticationStep } from 'src/app/shared/models/workflow/steps/authentication-step.model';
import { SelectPatientAppointmentCheckoutStep } from 'src/app/shared/models/workflow/steps/select-patient-appointment-checkout-step.model';
import { HttpBaseService } from './http-base-service';
import { PatientFormAppointmentCheckoutStep } from 'src/app/shared/models/workflow/steps/patient-form-appointment-checkout-step.model';
import { SelectPatientRelationshipAppointmentCheckoutStep } from 'src/app/shared/models/workflow/steps/select-patient-relationship-appointment-checkout-step.model';
import { PatientContactFormAppointmentCheckoutStep } from 'src/app/shared/models/workflow/steps/patient-contact-form-appointment-checkout-step.model';
import { WorkflowStepName } from 'src/app/shared/enums/workflow-step-name.enum';
import { SummaryAppointmentCheckoutStep } from 'src/app/shared/models/workflow/steps/summary-appointment-checkout-step.model';
import { PaymentAmountStep } from 'src/app/shared/models/workflow/steps/payment/payment-amount-step.model';
import { PaymentFormStep } from 'src/app/shared/models/workflow/steps/payment/payment-form-step.model';
import { environment } from 'src/environments/environment';
import { SelectCountryStep } from 'src/app/shared/models/workflow/steps/select-country-step.model';
import { PatientVerificationStep } from 'src/app/shared/models/workflow/steps/patient-verification-step.model';
import { SummaryMedicalOrderStep } from 'src/app/shared/models/workflow/steps/medical-orders/summary-medical-order-step.model';
import { SelectPatientRelationshipStep } from 'src/app/shared/models/workflow/steps/select-patient-relationship-step.model';
import { CreatePatientRelationshipStep } from 'src/app/shared/models/workflow/steps/create-patient-relationship-step.model';

@Injectable({
  providedIn: 'root'
})
export class WorkflowStepService {

  encryptSessionData = environment.encryptLocalStorage;

  constructor(
    private authenticationStep: AuthenticationStep,
    private selectCountryStep: SelectCountryStep,
    private selectPatientAppointmentCheckoutStep: SelectPatientAppointmentCheckoutStep,
    private patientFormAppointmentCheckoutStep: PatientFormAppointmentCheckoutStep,
    private selectPatientRelationshipAppointmentCheckoutStep: SelectPatientRelationshipAppointmentCheckoutStep,
    private patientContactFormAppointmentCheckoutStep: PatientContactFormAppointmentCheckoutStep,
    private summaryAppointmentCheckoutStep: SummaryAppointmentCheckoutStep,
    private paymentAmountStep: PaymentAmountStep,
    private paymentFormStep: PaymentFormStep,
    private patientVerificationStep: PatientVerificationStep,
    private summaryMedicalOrderStep: SummaryMedicalOrderStep,
    private selectPatientRelationshipStep: SelectPatientRelationshipStep,
    private createPatientRelationshipStep: CreatePatientRelationshipStep
  ) { 

  }

  createSteps(items: WorkflowItem[], state: IWorkflowDataState): IStepFlow[]{

    let steps: IStepFlow[] = [];

    items.forEach(item=>{

      switch(item.nombreClaseItem){
        case WorkflowStepName.PATIENT_VERIFICATION:
          this.patientVerificationStep.setItem(item);
          this.patientVerificationStep.setState(state);
          steps.push(this.patientVerificationStep);
          break;
        case WorkflowStepName.AUTHENTICATION:
          this.authenticationStep.setItem(item);
          this.authenticationStep.setState(state);
          steps.push(this.authenticationStep);
          break;
        case WorkflowStepName.SELECT_COUNTRY:
          this.selectCountryStep.setItem(item);
          this.selectCountryStep.setState(state);
          steps.push(this.selectCountryStep);
          break;          
        case WorkflowStepName.SELECT_PATIENT_APPOINTMENT_CHECKOUT:
          this.selectPatientAppointmentCheckoutStep.setItem(item);
          this.selectPatientAppointmentCheckoutStep.setState(state);
          steps.push(this.selectPatientAppointmentCheckoutStep);
          break;
        case WorkflowStepName.PATIENT_FORM_APPOINTMENT_CHECKOUT:
          this.patientFormAppointmentCheckoutStep.setItem(item);
          this.patientFormAppointmentCheckoutStep.setState(state);
          steps.push(this.patientFormAppointmentCheckoutStep);
          break;
        case WorkflowStepName.SELECT_PATIENT_RELATIONSHIP_APPOINTMENT_CHECKOUT:
          this.selectPatientRelationshipAppointmentCheckoutStep.setItem(item);
          this.selectPatientRelationshipAppointmentCheckoutStep.setState(state);
          steps.push(this.selectPatientRelationshipAppointmentCheckoutStep);
          break;
        case WorkflowStepName.PATIENT_CONTACT_FORM_APPOINTMENT_CHECKOUT:
          this.patientContactFormAppointmentCheckoutStep.setItem(item);
          this.patientContactFormAppointmentCheckoutStep.setState(state);
          steps.push(this.patientContactFormAppointmentCheckoutStep);
          break;
        case WorkflowStepName.SUMMARY_APPOINTMENT_CHECKOUT:
          this.summaryAppointmentCheckoutStep.setItem(item);
          this.summaryAppointmentCheckoutStep.setState(state);
          steps.push(this.summaryAppointmentCheckoutStep);
          break;
        case WorkflowStepName.SUMMARY_MEDICAL_ORDER:
          this.summaryMedicalOrderStep.setItem(item);
          this.summaryMedicalOrderStep.setState(state);
          steps.push(this.summaryMedicalOrderStep);
          break;
        case WorkflowStepName.PAYMENT_AMOUNT:
          this.paymentAmountStep.setItem(item);
          this.paymentAmountStep.setState(state);
          steps.push(this.paymentAmountStep);
          break;
        case WorkflowStepName.PAYMENT_FORM:
          this.paymentFormStep.setItem(item);
          this.paymentFormStep.setState(state);
          steps.push(this.paymentFormStep);
          break;
        case WorkflowStepName.SELECT_PATIENT_RELATIONSHIP:
          this.selectPatientRelationshipStep.setItem(item);
          this.selectPatientRelationshipStep.setState(state);
          steps.push(this.selectPatientRelationshipStep);
          break;
        case WorkflowStepName.CREATE_PATIENT_RELATIONSHIP:
          this.createPatientRelationshipStep.setItem(item);
          this.createPatientRelationshipStep.setState(state);
          steps.push(this.createPatientRelationshipStep);
          break;
        default:
          break;
      }
    });

    return steps;
  }  
}
