import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogData } from 'src/app/shared/models/dialog-data.model';

@Component({
  selector: 'app-profile-menu-dialog',
  templateUrl: './profile-menu-dialog.component.html',
  styleUrls: ['./profile-menu-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileMenuDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject(DOCUMENT) private document: Document,
    public mdDialogRef: MatDialogRef<ProfileMenuDialogComponent>
  ) {
  }

  ngOnInit(): void {
    history.pushState(null, '', location.href);
  } 

  public close() {
    if(this.data.animationOut){
      this.document.getElementsByClassName(this.data.id)[0].classList.remove(this.data.animationIn);
      this.document.getElementsByClassName(this.data.id)[0].classList.add(this.data.animationOut);

      setTimeout(()=>{      
        this.mdDialogRef.close();
      }, 300);
    }
    else{
      this.mdDialogRef.close();
    }
  }  
  
  @HostListener("keydown.esc") 
  public onEsc() {
    this.close();
  }
}