import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivacyPoliciesComponent } from './pages/privacy-policies/privacy-policies.component';

const privacyPoliciesRoutes: Routes = [
  {
    path: '',
    component: PrivacyPoliciesComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(privacyPoliciesRoutes)],
  exports: [RouterModule]
})
export class PrivacyPoliciesRoutingModule { }
