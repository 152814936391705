<div class="menu-container" ngClass.lt-lg="mobile"
    attr.data-title-mapfre="{{'menu.titleMapfre' | translate:locale.language}}">
    <div class="header-wrapper">
        <h4 class="menu-title primary-color">{{ 'menu.title' | translate:locale.language }}</h4>
        <a class="link-back accent-color" (click)="onBackClick()">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-volver-a-portal icon"></mat-icon>    
            <span>{{ 'menu.backLinkText' | translate:locale.language }}</span>
        </a>
    </div>
    
    <!-- Mobile Policies -->
    <div class="mobile-policy-menu">
        <mat-divider class="header-divider"></mat-divider>
        
        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ (user$|async)?.nombre + ' ' +  (user$|async)?.apellido }}
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-divider></mat-divider>
            
            <!-- Policies content -->
            <div class="mobile-policy-menu-content">
                <app-policy-menu-content></app-policy-menu-content>  
            </div>
            
        </mat-expansion-panel>
    </div>

    <mat-divider class="header-divider" fxHide.lt-md></mat-divider>

    <!-- Menu Links -->
    <mat-nav-list>
        <menu-link *ngFor="let link of links" [link]="link"></menu-link>
    </mat-nav-list>
    <div class="powered-by-container">
        <a href="https://www.cliniweb.com/" target="_blank">
            {{'menu.poweredBy' | translate:locale.language}}
        </a>
    </div>
</div>
