<div class="search-providers-container"
    [ngClass]="{
        'anonymous': isAnonymous,
        'search-opened': searchOpened,
        'provider-opened': autocompleteOpen == true && !locationAutocompleteOpen && !insuranceAutocompleteOpen,
        'location-opened': locationAutocompleteOpen == true  && !autocompleteOpen && !insuranceAutocompleteOpen,
        'insurance-opened': insuranceAutocompleteOpen == true  && !locationAutocompleteOpen && !autocompleteOpen,
        'without-location': !model.showLocationsAutocomplete,
        'without-insurance': !model.showInsurersAutocomplete,
        'popup': isPopup(),
        'widget': isWidget()
    }">
    
    <div class="search-preview">
        <div class="desktop">
            <div class="wrapper">
                <div class="item-preview" (click)="onFocusProvider()">
                    <span class="placeholder" *ngIf="showPreviewProviderPlaceholder()">
                        {{ model.profilesPlaceholderClosed | i18nText:locale.language }}
                    </span>
                    <div class="item-selected" *ngIf="showPreviewProviderValue()">
                        <span>{{ getPreviewProviderValue() }}</span>
                        <button mat-button mat-icon-button (click)="clearProviderSelected()">
                            <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Cerrar"></mat-icon>
                        </button>
                    </div>
                </div>
                <div class="item-preview" *ngIf="model.showLocationsAutocomplete" (click)="onFocusLocation()">
                    <span class="placeholder" *ngIf="showPreviewLocationPlaceholder()">
                        {{ model.locationsPlaceholderClosed | i18nText:locale.language }}
                    </span>
                    <div class="item-selected" *ngIf="showPreviewLocationValue()">
                        <span>{{ getPreviewLocationValue() }}</span>
                        <button mat-button mat-icon-button (click)="clearLocationSelected()">
                            <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Cerrar"></mat-icon>
                        </button>
                    </div>
                </div>
                <div class="item-preview" *ngIf="model.showInsurersAutocomplete" (click)="onFocusInsurance()">
                    <span class="placeholder" *ngIf="insuranceObjectSelected == null">
                        {{ model.insurersPlaceholderClosed | i18nText:locale.language }}
                    </span>
                    <div class="item-selected" *ngIf="insuranceObjectSelected != null">
                        <span>{{insuranceObjectSelected.n_cc}}</span>
                        <button mat-button mat-icon-button (click)="clearInsuranceSelected()">
                            <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Cerrar"></mat-icon>
                        </button>
                    </div>                    
                </div>
                <button mat-button (click)="onClickSearchButton()">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-buscar"></mat-icon>
                </button>
            </div>
        </div>
        <div class="mobile">
            <div class="wrapper" (click)="onClickMobilePreview()">
                <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-buscar"></mat-icon>
                <span class="placeholder" *ngIf="showSearchPlaceholderMobile()">
                    {{ model.placeholderMobile | i18nText:locale.language }}
                </span>
                <div class="item-selected" *ngIf="!showSearchPlaceholderMobile()">
                    <span>{{ getSelectionTextMobile() }}</span>
                    <button mat-button mat-icon-button (click)="clearSearchSelected()">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Cerrar"></mat-icon>
                    </button>
                </div>
                <button mat-button (click)="onClickSearchButton()">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-buscar"></mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div class="search-wrapper" [hidden]="!searchOpened">
        <button class="btn-search-close" mat-button mat-icon-button (click)="closeSearch()">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Cerrar"></mat-icon>
        </button>
        <div class="header-mobile" [hidden]="!showHeaderMobile()">
            <button class="btn-search-close-mobile" mat-button mat-icon-button (click)="closeSearch()">
                <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Volver"></mat-icon>
            </button>
        </div>        
        <div class="search-outline-wrapper">
            <!-- AUTOCOMPLETE PROVIDERS -->
            <mat-form-field class="search-form-field" floatLabel="never" appearance="outline">
                <button class="btn-cancel-mobile" mat-icon-button (click)="onCancelMobile()">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Volver"></mat-icon>
                </button>
                <label>{{model.profilesLabel | i18nText:locale.language}}</label>
                <div #inputWrapperProvider class="input-wrapper">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-buscar icon-autocomplete"></mat-icon>
                    <input #searchInput
                        matInput 
                        autocomplete="off"
                        type="text"
                        [placeholder]="getPlaceholderProvider()"
                        class="search-provider-input"
                        (keydown)="onKeydownInputSearch($event)"
                        (focus)="onFocusInputSearch()"
                        (blur)="onBlurInputSearch()"
                        [formControl]="searchProviderCtrl"
                        [matAutocomplete]="auto">                    
                    <button class="btn-remove" mat-icon-button *ngIf="showButtonRemoveProvider()" (click)="removeSearchProviderSelected()">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Cerrar"></mat-icon>
                    </button>                    
                    <mat-spinner *ngIf="loadingSearchProvider" [diameter]="18"></mat-spinner>
                </div>                
                <mat-autocomplete #auto="matAutocomplete"
                    class="search-providers-dropdown-container provider"
                    (optionSelected)="profileSelected($event)"
                    (opened)="autocompleteOpened()"
                    (closed)="autocompleteClosed()"
                    [displayWith]="autoCompleteProviderDisplayWith">
                    <mat-option *ngIf="!filteredResultsCount && isMobile && fullsize && !loadingSearchProvider" disabled>
                        {{'shared.searchResults.searchProvidersV2.noMatchesFound' | translate:locale.language}}
                    </mat-option>
                    <!-- Auto complete search results -->
                    <div class="search-providers-dropdown-wrapper">
                        <div class="search-providers-dropdown-header">
                            <button class="mat-icon-button btn-close-dropdown" (click)="closeDropdownProvider()">
                                <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Cerrar"></mat-icon>
                            </button>
                        </div>                        
                        <div class="lastest-doctors-container" *ngIf="showLastestDoctors()">
                            <!-- <label>{{ 'shared.searchResults.searchProvidersV2.lastestDoctors' | translate:locale.language }}</label> -->
                            <div class="lastest-doctors-wrapper" *ngIf="!loadingLastestDoctors && !platform.ANDROID && !platform.IOS">
                                <button class="mat-icon-button btn-prev" 
                                    [disabled]="lastestDoctorsIndex == 0"
                                    (click)="prevLastestDoctors()">
                                    <mat-icon fontSet="phr-iconset" class="phr-iconset-arrow-down"></mat-icon>
                                </button>
                                <div class="lastest-doctors-items">
                                    <div class="doctor-item" 
                                        *ngFor="let lastestDoctor of lastestDoctors.slice(lastestDoctorsIndex, lastestDoctorsIndex + maxLastestDoctorsToShow)" 
                                        (click)="gotoProfile(lastestDoctor)">
                                        <app-public-profile-avatar class="avatar" [model]="getAvatarModelByLastestDoctor(lastestDoctor)"></app-public-profile-avatar>
                                        <span>{{ lastestDoctor.nombreResponsableServicio }}</span>
                                    </div>
                                </div>
                                <button class="mat-icon-button btn-next" 
                                    [disabled]="lastestDoctorsIndex + maxLastestDoctorsToShow >= lastestDoctors.length"
                                    (click)="nextLastestDoctors()">
                                    <mat-icon fontSet="phr-iconset" class="phr-iconset-arrow-down"></mat-icon>
                                </button>
                            </div>
                            <div class="lastest-doctors-wrapper-mobile" *ngIf="!loadingLastestDoctors && (platform.ANDROID || platform.IOS)">
                                <div class="doctor-item" 
                                    *ngFor="let lastestDoctor of lastestDoctors" 
                                    (click)="gotoProfile(lastestDoctor)">
                                    <app-public-profile-avatar class="avatar" [model]="getAvatarModelByLastestDoctor(lastestDoctor)"></app-public-profile-avatar>
                                    <span>{{ lastestDoctor.nombreResponsableServicio }}</span>
                                </div>
                            </div>
                            <div class="lastest-doctors-skeleton" *ngIf="loadingLastestDoctors">
                                <div class="doctor-item" *ngFor="let item of [1, 2, 3]">
                                    <div class="avatar">
                                        <ngx-skeleton-loader [theme]="{ 'width': '44px', 'height': '44px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '50%', 'margin-bottom': '0px' }"></ngx-skeleton-loader>
                                    </div>
                                    <div class="name">
                                        <ngx-skeleton-loader [theme]="{ 'width': '70px', 'height': '18px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'margin-bottom': '0px' }"></ngx-skeleton-loader>
                                    </div>                    
                                </div> 
                            </div>
                        </div>
                        <div class="search-text-option-mobile" *ngIf="showSearchTextOption()" (click)="onClickSearchTextMobile()">
                            <span [innerHtml]="'shared.searchResults.searchProvidersV2.searchTextOption' | translate:locale.language: { text: getSearchTextOption() }"></span>
                        </div>
                        <mat-optgroup appTouchMoveListener *ngFor="let group of filteredResults$ | async" [label]="group.className">
                            <mat-option *ngFor="let result of group.results" [value]="result">
                                <div class="result-wrapper" [ngClass]="{'profile': showAvatarDropdownOption(result) }"> 
                                    <mat-icon *ngIf="showIconDropdownOption(result)" fontSet="phr-iconset" class="{{getIconClassNameDropdownOption(result)}}"></mat-icon>
    
                                    <!-- <app-avatar-initials class="avatar" *ngIf="showAvatarDropdownOption(result) && (isDoctor(result) || result.urlImagen)"
                                        [url]="result.urlImagen"
                                        [text]="result.nombre"
                                        [id]="result.idPerfilPublico">
                                    </app-avatar-initials>
                                    <div class="avatar icon" *ngIf="showAvatarDropdownOption(result) && !isDoctor(result) && !result.urlImagen">
                                        <mat-icon fontSet="phr-iconset" [ngClass]="getProfileTypeIconClassName(result)"></mat-icon>
                                    </div>-->
    
                                    <div class="avatar" *ngIf="showAvatarDropdownOption(result)">
                                        <app-public-profile-avatar [model]="getAvatarModel(result)"></app-public-profile-avatar>
                                    </div>
    
                                    <div class="text-container">
                                        <span class="title" innerHTML="{{result.nombre | highlight : highlightValue }}"></span>
                                        <span *ngIf="result.nombre != result.descripcion" class="description">{{result.descripcion}}</span>
                                    </div>
                                </div>
                            </mat-option>
                        </mat-optgroup>
                    </div>
                </mat-autocomplete>
            </mat-form-field>

            <!-- AUTOCOMPLETE LOCATION -->
            <mat-form-field class="location-form-field" floatLabel="never" appearance="outline" *ngIf="model.showLocationsAutocomplete == true">
                <button class="btn-cancel-mobile" mat-icon-button (click)="onCancelMobile()">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Volver"></mat-icon>
                </button>
                <label>
                    {{model.locationsLabel | i18nText:locale.language}}
                    <span> {{'shared.searchResults.searchProvidersV2.optionalText' | translate:locale.language}}</span>
                </label>
                <div #inputWrapperLocation class="input-wrapper">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-place icon-place"></mat-icon>
                    <input #searchLocationInput
                        matInput
                        autocomplete="off"
                        type="text"
                        [placeholder]="getPlaceholderLocation()"
                        class="search-location-input"
                        (keydown)="onKeydownInputLocationSearch($event)"
                        (focus)="onFocusInputLocationSearch()"
                        (blur)="onBlurInputLocationSearch()"
                        [formControl]="searchLocationCtrl"
                        [matAutocomplete]="autoLocation">
                    <button class="btn-remove" mat-icon-button *ngIf="showButtonRemoveLocation()" (click)="removeSearchLocationSelected()">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Cerrar"></mat-icon>
                    </button>
                    <mat-spinner *ngIf="loadingSearchLocation" [diameter]="18"></mat-spinner>
                </div>
                <mat-autocomplete #autoLocation="matAutocomplete"
                    class="search-providers-dropdown-container"
                    (optionSelected)="locationSelected($event)"
                    (opened)="autocompleteLocationOpened()"
                    (closed)="autocompleteLocationClosed()"
                    [displayWith]="autoCompleteLocationDisplayWith">
                        <div class="search-providers-dropdown-header">
                            <button class="mat-icon-button btn-close-dropdown" (click)="closeDropdownLocation()">
                                <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Cerrar"></mat-icon>
                            </button>
                        </div>
                        <mat-option *ngIf="!filteredLocationResultsCount && isMobile && fullsize && !loadingSearchLocation" disabled>
                            {{'shared.searchResults.searchProvidersV2.noMatchesFound' | translate:locale.language}}
                        </mat-option>
                        <mat-optgroup appTouchMoveListener *ngFor="let group of filteredLocationResults$ | async" 
                            [label]="group.displayName" 
                            [ngClass]="{'label-hidden': group.displayName == '' }">
                            <mat-option *ngFor="let result of group.results" [value]="result">
                                <div class="result-wrapper">
                                    <mat-icon fontSet="phr-iconset" *ngIf="result.icono" class="{{result.icono}}"></mat-icon>
                                    <span innerHTML="{{result.nombre | highlight : highlightValue }}"></span>
                                </div>
                            </mat-option>
                        </mat-optgroup>
                </mat-autocomplete>
            </mat-form-field>

            <!-- AUTOCOMPLETE INSURANCE -->
            <mat-form-field class="insurance-form-field" floatLabel="never" appearance="outline" *ngIf="model.showInsurersAutocomplete == true">
                <button class="btn-cancel-mobile" mat-icon-button (click)="onCancelMobile()">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Volver"></mat-icon>
                </button>
                <label>
                    {{model.insurersLabel | i18nText:locale.language}}
                    <span> {{'shared.searchResults.searchProvidersV2.optionalText' | translate:locale.language}}</span>
                </label>
                <div #inputWrapperInsurance class="input-wrapper">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-aseguradora2 icon-insure"></mat-icon>
                    <input #searchInsuranceInput
                        matInput
                        autocomplete="off"
                        type="text"
                        [placeholder]="getPlaceholderInsurance()"
                        class="search-insurance-input"
                        (keydown)="onKeydownInputInsuranceSearch($event)"
                        (focus)="onFocusInputInsuranceSearch()"
                        (blur)="onBlurInputInsuranceSearch()"
                        [formControl]="searchInsuranceCtrl"
                        [matAutocomplete]="autoInsurance">
                    <button class="btn-remove" mat-icon-button *ngIf="insuranceObjectSelected" (click)="removeSearchInsuranceSelected()">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Cerrar"></mat-icon>
                    </button>
                    <mat-spinner *ngIf="loadingSearchInsurance" [diameter]="18"></mat-spinner>
                </div>
                <mat-autocomplete #autoInsurance="matAutocomplete"
                    class="search-providers-dropdown-container"                    
                    (optionSelected)="insuranceSelected($event)"
                    (opened)="autocompleteInsuranceOpened()"
                    (closed)="autocompleteInsuranceClosed()"
                    [displayWith]="autoCompleteInsuranceDisplayWith">
                        <div class="search-providers-dropdown-header">
                            <button class="mat-icon-button btn-close-dropdown" (click)="closeDropdownInsurance()">
                                <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Cerrar"></mat-icon>
                            </button>
                        </div>

                        <div *ngFor="let group of filteredInsuranceResults$ | async">
                            <mat-optgroup  *ngIf="group.results && group.results.length > 0"
                                [ngClass]="{'label-hidden': !group.titulo }">
                                
                                <div class="option-group-title" *ngIf="group.titulo && group.results && group.results.length > 0">
                                    <span>{{group.titulo}}</span>
                                    <mat-icon fontSet="phr-iconset" class="phr-iconset-info" *ngIf="group.tooltip" [matTooltip]="group.tooltip"></mat-icon>
                                </div>
                                
                                <mat-option *ngFor="let result of group.results" [value]="result" [ngClass]="{'use-option-icon': insuranceFilter.configuracionVista && insuranceFilter.configuracionVista.usarIcono }">                                
                                    <div class="result-wrapper" [ngClass]="{'selected': insuranceObjectSelected && insuranceObjectSelected.cc == result.cc}">
                                        <mat-icon fontSet="phr-iconset" [class]="insuranceFilter.configuracionVista.claseCssIcono" *ngIf="insuranceFilter.configuracionVista && insuranceFilter.configuracionVista.usarIcono"></mat-icon>
                                        <span innerHTML="{{result.n_cc | highlight : highlightValue }}"></span>
                                    </div>
                                </mat-option>
                                
                            </mat-optgroup>
                        </div>
                        
                </mat-autocomplete>
            </mat-form-field>

            <div class="button-search-container">
                <button mat-button class="button-search" (click)="onClickSearchButton()">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-buscar"></mat-icon>
                    <span class="button-search-text">
                        {{ 'shared.searchResults.searchProvidersV2.textSearchButton' | translate:locale.language }}
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="search-overlay" (click)="closeSearch()"></div>