<div class="confirm-dialog-wrapper" ngClass.lt-md="mobile" [hidden]="licenseLoading">
    <div class="header">
        <mat-icon fontSet="phr-iconset" class="header-icon {{data.headerIconClass}}" *ngIf="data.showHeaderIcon"></mat-icon>  
        <h3 mat-dialog-title>{{ data.title }}</h3>
        <button mat-icon-button tabindex="-1" class="no-focus-overlay" (click)="cancel()"><mat-icon>clear</mat-icon></button>
    </div>
    <div mat-dialog-content>
        <p class="dialog-message" innerHtml="{{ data.message }}"></p>
    </div>
    <div mat-dialog-actions 
        fxLayout="row" 
        fxLayout.lt-md="column-reverse" 
        fxLayoutAlign="center center" 
        fxLayoutGap="40px"
        fxLayoutGap.lt-md="20px">
        <button mat-button mat-raised-button
            tabindex="-1"
            [color]="data.colorCancelButton ? data.colorCancelButton : 'accent'"
            (click)="cancel()"
            *ngIf="data.showCancelButton">
            {{ data.cancelText }}
        </button>
        <button mat-button mat-raised-button 
            [color]="data.colorConfirmButton ? data.colorConfirmButton : 'primary'" 
            (click)="confirm()" 
            *ngIf="data.showConfirmButton">
            {{ data.confirmText }}
        </button>
    </div>
</div>
