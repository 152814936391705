import { Directive, HostListener, Inject } from '@angular/core';
import { Debounce } from '../decorators/debounce.decorator';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[appTouchMoveListener]'
})
export class TouchMoveListenerDirective {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  @HostListener('touchmove', ['$event'])
  @Debounce(200)
  onTouchMove($event: Event) {
    const activeElement = this.document.activeElement;
    if (activeElement && (activeElement as HTMLElement).blur) {
      (activeElement as HTMLElement).blur();
    }
  }
}
