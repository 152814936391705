import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { RouteDialogType } from '../dialogs/route-dialog/route-dialog.component';
import { BaseService } from 'src/app/core/services/base.service';
import { LegalAgreements } from '../../interfaces/legal-agreements';
import { LegalAgreementType } from '../../enums/legal-agreements-type.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-legal-agreements',
  templateUrl: './legal-agreements.component.html',
  styleUrls: ['./legal-agreements.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LegalAgreementsComponent implements OnInit, OnDestroy {

  legalAgreements: LegalAgreements = {
    content: `<p>Contenido no disponible.</p>`,
    id: 'generico',
    language: 'es',
    title: 'No se encontró el acuerdo legal'
  };
  @Input() type: string;

  private ngUnsubscribe = new Subject();

  constructor(
    private baseService: BaseService,
    private messageBusService: MessageBusService,
    private route: ActivatedRoute,
    private routeDialogService: RouteDialogService
  ) {

    this.messageBusService.onNavigateBack()
    .pipe(takeUntil(this.ngUnsubscribe)) 
    .subscribe(x => this.routeDialogService.closeDialog(RouteDialogType.LEGALAGREEMENTS));

    this.route.data
    .pipe(takeUntil(this.ngUnsubscribe)) 
    .subscribe(data => this.type = data['id']);
  }

  ngOnInit(): void {
    switch(this.type) {
      case LegalAgreementType.DISCLAIMER:
        this.legalAgreements = this.baseService.getDisclaimer() ?? this.legalAgreements;
        break;
      case LegalAgreementType.POLITICA_PRIVACIDAD:
        this.legalAgreements = this.baseService.getPrivacyPolicy() ?? this.legalAgreements;
        break;
      case LegalAgreementType.TERMINOS_CONDICIONES:
        this.legalAgreements = this.baseService.getTermsAndConditions() ?? this.legalAgreements;
        break;
      default:
        this.legalAgreements = this.legalAgreements;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
