import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import * as moment from "moment";

export function maxDate(maxDate: Date | null): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value && maxDate ) {
            const fecha1 = moment(control.value).startOf('day');
            const fecha2 = moment(maxDate).startOf('day');
            if (fecha1.isAfter(fecha2)) {
                return {
                    maxDate: { valid: false, message: 'shared.datepicker.maxDate'}
                };
            }
        }

        return null;
    }
}

export function minDate(minDate: Date | null): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value && minDate ) {
            const fecha1 = moment(control.value).startOf('day');
            const fecha2 = moment(minDate).startOf('day');
            if (fecha1.isBefore(fecha2)) {
                return {
                    minDate: { valid: false, message: 'shared.datepicker.minDate'}
                };
            }
        }

        return null;
    }
}