export enum GroupConcept {
    SUBJETIVO = 1,
    OBJETIVO = 2,
    ANALISIS = 3,
    PLAN = 4,
    ANTECEDENTES = 5,
    ALERGIAS = 6,
    PRESCRIPCION_MEDICA = 7,
    LABORATORIOS = 8,
    VACUNAS = 9,
    ENFERMEDADES = 10,
    ENFERMEDADES_FAMILIARES = 11,
    CIRUGIAS = 12,
    HABITOS = 13
}
