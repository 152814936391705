import { PublicProfile } from "../../people/public-profile.model";
import { UserPerson } from "../../people/user-person.model";
import { RequestingUser } from "../../process/base-request.model";
import { WorkflowInitialData } from "../../process/workflow-initial-data.model";
import { IAuthenticationWorkflowState } from "../steps/authentication-step.model";
import { IPatientVerificationStep } from "../steps/patient-verification-step.model";
import { BaseWorkflowState } from "./base-workflow-state.model";
import { ISelectPatientRelationshipWorkflowState } from "../steps/select-patient-relationship-step.model";
import { ICreatePatientRelationshipWorkflowState } from "../steps/create-patient-relationship-step.model";
import { Dependent } from "../../people/dependent.model";
import { Relation } from "../../people/relation.model";

export class GrowthCurvesWorkflowState
    extends BaseWorkflowState
    implements IPatientVerificationStep,
        IAuthenticationWorkflowState,
        ISelectPatientRelationshipWorkflowState,
        ICreatePatientRelationshipWorkflowState {

    requestModel: WorkflowInitialData;
    idVerificacion: string;

    constructor();
    constructor(obj: GrowthCurvesWorkflowState);
    constructor(obj?:any){
      super();
      
      this.requestModel = obj && obj.requestModel || new WorkflowInitialData();
      this.idVerificacion = obj && obj.idVerificacion || null;
      
      if (obj && !this.requestModel)
        this.requestModel = obj.request;
    }

    getIdVerificacion(): string {
        return this.idVerificacion;
    }

    getDoctorAvatarUrl(): string {
        return this.requestModel.urlAvatarResponsableServicioDestino || this.requestModel.publicProfile?.ubicacionFoto;
    }

    getUserId(): number {
        return this.requestModel.usuarioSolicitante && this.requestModel.usuarioSolicitante.id
        ? this.requestModel.usuarioSolicitante.id
        : -1;
    }

    getIdTipoSolicitud(): number {
        return this.requestModel?.idTipoSolicitud ?? -1;
    }

    getPosition(): number {
        return this.requestModel.workflowPosition;
    }

    getPublicProfile(): PublicProfile {
        return this.requestModel.perfilPublico;
    }

    setUserData(userPerson: UserPerson): void {
        this.requestModel.emailPersonaEmisora = userPerson.emailParticular;

      if(!this.requestModel.usuarioSolicitante){
        this.requestModel.usuarioSolicitante = new RequestingUser();
      }

      this.requestModel.usuarioSolicitante.id = userPerson.id;
      this.requestModel.usuarioSolicitante.username = userPerson.emailParticular;      
      this.requestModel.usuarioSolicitante.sexo = userPerson.sexo;
      this.requestModel.usuarioSolicitante.fechaNacimiento = userPerson.fechaNacimiento;
      this.requestModel.usuarioSolicitante.urlAvatar = userPerson.ubicacionLogo;
      this.requestModel.usuarioSolicitante.nombre = userPerson.nombre;
      this.requestModel.usuarioSolicitante.apellido = userPerson.apellido;
      this.requestModel.usuarioSolicitante.idEstadoValidacionIdentidadPersona = userPerson.idEstadoValidacionIdentidadPersona;
      this.requestModel.usuarioSolicitante.idPaisResidencia = userPerson.idPaisResidencia;
    }
    
    setEmmiterPersonId(personId: number): void {
        this.requestModel.idPersonaEmisora = personId;
    }

    setPosition(position: number): void {
        this.requestModel.workflowPosition = position;
    }

    setPublicProfile(profile: PublicProfile): void {
        this.requestModel.perfilPublico = profile;
    }

    getPatientName(): string {
        return this.requestModel.nombrePaciente;
    }

    setPatientName(nombrePaciente: string): void {
        this.requestModel.nombrePaciente = nombrePaciente;
    }

    getPatientEmail(): string {
        return this.requestModel.emailPaciente;
    }

    setPatientEmail() {
        this.requestModel.emailPaciente;
    }

    getPatientValid(): boolean {
        return this.requestModel.isValid;
    }

    setPatientValid(valid: boolean) {
        this.requestModel.isValid = valid;
    }

    getDependents(): Dependent[] {
        return this.requestModel.dependents;
    }

    getPatientRelationship(): number {
        return this.requestModel.relacionEmisorPaciente;
    }

    getPatientIdRelationship(): number {
        return this.requestModel.idPaciente ?? 0;
    }

    setDependents(dependents: Dependent[]): void {
        this.requestModel.dependents = dependents;
    }

    setPatientRelationship(patientRelationshipType: number): void {
        this.requestModel.relacionEmisorPaciente = patientRelationshipType;
    }
    
    setPatientIdRelationship(patientId: number): void {
        this.requestModel.idPaciente = patientId;
    }

    getRelations(): Relation[] {
        return this.requestModel.relations;
    }

    setRelations(relations: Relation[]): void {
        this.requestModel.relations = relations;
    }
    
    getPatientId(): number {
        return this.requestModel.idPaciente ?? 0;
    }

    getUserPhrId(): number {
        return this.requestModel.idUsuario;
    }
}
