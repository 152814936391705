<div class="cliniweb-appointment-card-container">

    <!-- Skeleton -->
    <mat-card class="skeleton-card" *ngIf="loading">
        <mat-card-header>
            <span class="left">
                <ngx-skeleton-loader [theme]="{'width': '45%', 'height': '14px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
            </span>
            <span class="right">                    
                <ngx-skeleton-loader [theme]="{'width': '15%', 'height': '14px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
            </span>
        </mat-card-header>
        
        <mat-card-content>
            <div class="appointment-data">
                <span class="description">
                    <ngx-skeleton-loader [theme]="{'width': '45%', 'height': '14px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
                </span>
                <span class="doc-name">
                    <ngx-skeleton-loader [theme]="{'width': '45%', 'height': '14px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
                </span>
                <span class="location">
                    <ngx-skeleton-loader [theme]="{'width': '45%', 'height': '14px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>                          
                </span>
            </div>

            <div class="doc-img">
                <ngx-skeleton-loader [theme]="{'width': '70px', 'height': '70px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '50%'}"></ngx-skeleton-loader>                          
            </div> 
        </mat-card-content>
    </mat-card>
    
    <!-- Appointment Card -->
    <mat-card [class]="appointment.appointmentStateClassName" *ngIf="!loading">
        
        <!-- Header -->
        <mat-card-header *ngIf="appointment.appointmentStateDisplayName">
            <span class="left" [ngClass]="{'expired-title': appointment.appointmentExpired && appointment.idEstado != 7 && appointment.idEstado != 28}">
                
                <span class="center-vertically" *ngIf="appointment.idEstado != 22 || appointment.appointmentExpired">
                    <mat-icon fontSet="phr-iconset" class="{{appointment.appointmentStateIconClassName}}"></mat-icon>
                    <span>{{appointment.appointmentStateDisplayName}}</span>
                </span>

                <!-- co_estado_confirmar_hora (22) -->
                <span class="center-vertically unconfirmed-link desktop" *ngIf="!appointment.appointmentExpired && appointment.idEstado == 22" matTooltip="{{'shared.cliniwebAppointment.text17' | translate:locale.language}}">
                    <mat-icon fontSet="phr-iconset" class="{{appointment.appointmentStateIconClassName}}"></mat-icon>
                    <span>{{appointment.appointmentStateDisplayName}}</span>
                </span>
                <span class="center-vertically unconfirmed-link mobile" *ngIf="!appointment.appointmentExpired && appointment.idEstado == 22" (click)="onUnconfirmedAppointmentClick()">
                    <mat-icon fontSet="phr-iconset" class="{{appointment.appointmentStateIconClassName}}"></mat-icon>
                    <span>{{appointment.appointmentStateDisplayName}}</span>
                </span>
            </span>

            <!-- Right -->
            <span class="right">
                <span class="action-link" *ngIf="!appointment.appointmentExpired && appointment.idEstado != 7 && !popupView" (click)="onCancelAppointment(appointment)">
                    {{'shared.cliniwebAppointment.text21' | translate:locale.language}}
                </span>
                <span class="cancel-appointment-link" *ngIf="popupView" (click)="closePopupView()">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Cerrar"></mat-icon>
                </span>

                <!-- Cancelada (7) -->
                <span class="action-link" *ngIf="!popupView && (appointment.idEstado == 7 || appointment.appointmentExpired)" (click)="onReScheduleAppointment(appointment)">
                    {{'shared.cliniwebAppointment.text3' | translate:locale.language}}
                </span>
            </span>            
        </mat-card-header>

        <!-- Content -->
        <mat-card-content>
            <div class="appointment-date">{{appointment.appointmentFormattedDate}}, {{appointment.fechaRequerido | date:'h:mm a' | lowercase}}</div>  

            <div class="appointment-data">
                <span class="description" [innerHtml]="appointment.description">                       
                </span>
                <span class="doc-name" (click)="goToPublicProfile(appointment)">
                    {{appointment.nombreResponsableServicio}} 
                </span>
                <span class="location" *ngIf="!appointment.teleconsultation">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-hospital"></mat-icon>
                    <span>{{appointment.locationDescription}}</span>                        
                </span>
                <span class="location" *ngIf="appointment.teleconsultation">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-video"></mat-icon>
                    <span>{{'shared.cliniwebAppointment.text11' | translate:locale.language}}</span>
                </span>
            </div>

            <div class="doc-img" *ngIf="avatarModel" (click)="goToPublicProfile(appointment)">
                <!-- <img [src]="appointment.doctorAvatarUrl"/> -->
                <app-public-profile-avatar [model]="avatarModel"></app-public-profile-avatar>
            </div>                

        </mat-card-content>

        <!-- Footer -->
        <mat-card-footer>
            <button mat-button mat-stroked-button class="secondary" [disabled]="appointment.idEstado == 7 || appointment.appointmentExpired" *ngIf="!appointment.teleconsultation" (click)="onNavigateWithWaze(appointment)">
                <mat-icon fontSet="phr-iconset" class="phr-iconset-waze"></mat-icon>
                <span>{{'shared.cliniwebAppointment.text2' | translate:locale.language}}</span>
            </button>
            <!-- <button mat-button mat-stroked-button class="secondary" *ngIf="appointment.idEstado == 7 || appointment.appointmentExpired" (click)="onReScheduleAppointment(appointment)">                    
                <span>{{'shared.cliniwebAppointment.text3' | translate:locale.language}}</span>
            </button> -->
            <button mat-button mat-stroked-button class="secondary" [disabled]="appointment.idEstado == 7 || appointment.appointmentExpired" *ngIf="appointment.teleconsultation" (click)="openVideConsultationLink(appointment.teleconsultationUrl)">
                <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-video"></mat-icon>
                <span>{{'shared.cliniwebAppointment.text12' | translate:locale.language}}</span>
            </button>
            
            <a class="link-button" (click)="onAppointmentViewMoreClick(appointment)">
                <span>{{'shared.cliniwebAppointment.text4' | translate:locale.language}}</span>
                <mat-icon fontSet="phr-iconset" class="phr-iconset-right-arrow"></mat-icon>                    
            </a>            
        </mat-card-footer>
    </mat-card>

</div>