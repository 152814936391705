import { PublicProfile } from "../../people/public-profile.model";

export class AppointmentCheckout {
    idResponsableServicio: number;
    urlAvatarResponsableServicio: string;
    nombreResponsableServicio: string;
    especialidadResponsableServicio: string;

    perfilPublico: PublicProfile;    

    nickname: string;
    idLocalidad: number;
    nombreLocalidad: string;
    direccionLocalidad: string;
    coordenadasLocalidad: string;
    telefonoLocalidad: string;
    teleconsulta: string;
    fechaInicio: Date;
    fechaFin: Date;
    idVerificacion: string;
    idEmpresa: number;
}
