import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appCenterInput]'
})
export class CenterInputDirective {

  @Input() onlyMobile = true;
  private isMobile: boolean = this.breakpointObserver.isMatched([Breakpoints.Handset, Breakpoints.Tablet]);
  private scrollIntoViewOptions: ScrollIntoViewOptions = {behavior: 'smooth', block: 'center'};
  private thisElementRef: HTMLInputElement;

  constructor(private breakpointObserver: BreakpointObserver, private elementRef: ElementRef) { }

  @HostListener('focus', ['$event'])
  onFocus(event: FocusEvent): void {
    this.setElementRef();
    
    if (!this.isMobile && !this.onlyMobile) 
      return;

    // Need to wait for Android to open the keyboard before running the scroll
    setTimeout(() => {
      this.thisElementRef.scrollIntoView(this.scrollIntoViewOptions);
    }, 300);
  }

  private setElementRef(): void {
    if (!this.thisElementRef) {
      this.thisElementRef = this.elementRef.nativeElement;
    }
  }

}
