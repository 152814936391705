<mat-toolbar color="white" class="public-profile-header-container" 
  [ngClass]="{
    'anonymous-header': !loggedInUser,
    'public-profile': isPublicProfilePage,
    'search-profile': isSearchProfilePage,
    'route-dialog-opened': routeDialogOpened,
    'show-public-profile-header': publicProfileMenuModel && !publicProfileMenuModel.headerVisible
   }">
    <img class="phr-logo" src="{{headerLogo}}" (click)="onLogoClick()">   
  

    <div class="component-container">
      <ng-container *ngFor="let floatingComponent of floatingComponents">
        <app-dynamic *ngIf="floatingComponent" [componentName]="floatingComponent.nombreComponente" [componentData]="floatingComponent"></app-dynamic>
      </ng-container>
    </div>
    
    <a class="home-link" *ngIf="loggedInUser && isPublicProfilePage && !routeDialogOpened" [routerLink]="['inicio']">
      <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-inicio-1"></mat-icon>
      <span>{{ 'shared.header.homeLinkText' | translate:language }}</span>
    </a>
    
    <!-- Anonymous Menu -->
    <app-anonymous-menu class="anonymous-menu" *ngIf="!loading && isLicensedCompany" (onLoggedInUser)="setLoggedInUser($event)"></app-anonymous-menu>

    <!-- Unlicensed Menu -->
    <app-unlicensed-menu class="unlicensed-menu" *ngIf="!loading && !isLicensedCompany"></app-unlicensed-menu>

    <!-- Public Profile menu -->
    <div class="public-profile-menu" *ngIf="publicProfileMenuModel">
      <div mat-card-avatar class="avatar">                
        <app-public-profile-avatar [model]="publicProfileMenuModel.avatarModel"></app-public-profile-avatar>
      </div>
      <span class="profile-name">{{publicProfileMenuModel.profile.nombrePersona}}</span>
      <span class="right">
        <button mat-button mat-flat-button class="btn-pay secondary small" *ngIf="publicProfileMenuModel.profile.receptorPagoElectronico == 'S'" (click)="gotoPayment()">
          {{ 'publicProfiles.doctorHeader.buttonPay' | translate:locale.language }}
        </button>
        <button mat-button mat-flat-button class="btn-appointment primary small" *ngIf="publicProfileMenuModel.profile.citaEnLineaHabilitada == 'S'" (click)="onAppointmentBtnClick()">
            {{ 'publicProfiles.doctorHeader.buttonAppointment' | translate:locale.language }}
        </button>
        </span>
    </div>

</mat-toolbar>
