export enum WorkflowStepName {
    PATIENT_VERIFICATION = 'PATIENT_VERIFICATION',
    AUTHENTICATION = 'AUTHENTICATION',
    SELECT_PATIENT_APPOINTMENT_CHECKOUT = 'SELECT_PATIENT_APPOINTMENT_CHECKOUT',
    PATIENT_FORM_APPOINTMENT_CHECKOUT = 'PATIENT_FORM_APPOINTMENT_CHECKOUT',
    SELECT_PATIENT_RELATIONSHIP_APPOINTMENT_CHECKOUT = 'SELECT_PATIENT_RELATIONSHIP_APPOINTMENT_CHECKOUT',
    PATIENT_CONTACT_FORM_APPOINTMENT_CHECKOUT = 'PATIENT_CONTACT_FORM_APPOINTMENT_CHECKOUT',
    SUMMARY_APPOINTMENT_CHECKOUT = 'SUMMARY_APPOINTMENT_CHECKOUT',
    SUMMARY_MEDICAL_ORDER = 'SUMMARY_MEDICAL_ORDER',
    PAYMENT_AMOUNT = 'PAYMENT_AMOUNT',
    PAYMENT_FORM = 'PAYMENT_FORM',
    SELECT_COUNTRY = "SELECT_COUNTRY",
    SELECT_PATIENT_RELATIONSHIP = 'SELECT_PATIENT_RELATIONSHIP',
    CREATE_PATIENT_RELATIONSHIP = 'CREATE_PATIENT_RELATIONSHIP'
}