<div class="datepicker-flat-field-container">
    <p>{{ label }}</p>
    <form [formGroup]="formulario" class="d-flex">
        <mat-form-field appearance="outline">
            <mat-select formControlName="day" placeholder="Día">
                <mat-option *ngFor="let day of days" [value]="day">{{day}}</mat-option>
            </mat-select>
            <mat-error *ngIf="isTouched">
                {{ errors?.maxDate?.message | translate:locale.language }}
                {{ errors?.minDate?.message | translate:locale.language }}
                {{ errors?.required ? ('shared.datepicker.required' | translate:locale.language) : '' }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="month">
            <mat-select formControlName="month" placeholder="Mes">
                <mat-option *ngFor="let month of months" [value]="month.id">{{month.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-select formControlName="year" placeholder="Año">
                <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>