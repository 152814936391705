<div class="select-patient-relationship-appointment-container">
    <app-appointment-info-checkout [model]="model.appointmentInfo" [show]="showAppointmentInfo"></app-appointment-info-checkout>
    <mat-divider vertical *ngIf="showAppointmentInfo"></mat-divider>
    <div class="content">
        <span class="title">{{ 'shared.selectPatientRelationshipAppointmentCheckout.title' | translate:locale.language: { name: model.patientName } }}</span>

        <form class="patient-relationship-form" [formGroup]="patientRelationshipForm" (ngSubmit)="onClickContinue()">
            <div class="wrapper">
                <mat-form-field appearance="outline" class="without-label">  
                    <mat-label>{{ 'shared.selectPatientRelationshipAppointmentCheckout.defaultOption' | translate:locale.language }}</mat-label>                  
                    <mat-select formControlName="patientRelationship" [value]="model.patientRelationship">
                      <!--<mat-option value="9" disabled="disabled">{{ 'shared.selectPatientRelationshipAppointmentCheckout.defaultOption' | translate:locale.language }}</mat-option>-->
                      <mat-option value="295">{{ 'shared.selectPatientRelationshipAppointmentCheckout.option1' | translate:locale.language }}</mat-option>
                      <mat-option value="294">{{ 'shared.selectPatientRelationshipAppointmentCheckout.option2' | translate:locale.language }}</mat-option>
                      <mat-option value="36">{{ 'shared.selectPatientRelationshipAppointmentCheckout.option3' | translate:locale.language }}</mat-option>

                      <mat-option value="480">{{ 'shared.selectPatientRelationshipAppointmentCheckout.option4' | translate:locale.language }}</mat-option>
                      <mat-option value="481">{{ 'shared.selectPatientRelationshipAppointmentCheckout.option5' | translate:locale.language }}</mat-option>
                      <mat-option value="482">{{ 'shared.selectPatientRelationshipAppointmentCheckout.option6' | translate:locale.language }}</mat-option>
                      <mat-option value="483">{{ 'shared.selectPatientRelationshipAppointmentCheckout.option7' | translate:locale.language }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="isControlInvalid('patientRelationship')">
                        {{ 'shared.selectPatientRelationshipAppointmentCheckout.text1' | translate:locale.language }}
                    </mat-error>
                </mat-form-field>                
            </div>
            <div class="button-container">
                <div class="appointment-info-mobile" (click)="openAppointmentInfoDialog()">
                    <span>{{ model.appointmentInfo.appointmentDate | date:'d MMM, h:mm a': 'UTC': language }}</span>
                    <span> • </span>
                    <span>{{ model.appointmentInfo.attentionPlaceName }}</span>
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-arrow-down-big"></mat-icon>
                </div>
                <button mat-button mat-flat-button class="primary" [ngClass]="{'loading': loading }">
                    {{ 'shared.selectPatientRelationshipAppointmentCheckout.buttonText' | translate:locale.language }}
                </button>
            </div>
        </form>
    </div>
</div>
