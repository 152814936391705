<div class="login-form-google-container">
    <div class="subtitle">{{ 'loginFormGoogle.subtitle' | translate:locale.language }}</div>    
    <div class="title">{{ 'loginFormGoogle.title' | translate:locale.language }}</div>
    <div class="image-mobile"></div>
    <label class="label-username">{{ username }}</label>
    <div class="google-container">
        <button mat-button mat-flat-button class="primary outline" (click)="loginByGoogle()" [ngClass]="{'loading': loadingByGoogle}">
            <div class="google-icon" *ngIf="!loadingByGoogle"></div>
            <span >{{ 'loginFormGoogle.buttonText' | translate:locale.language }}</span>
        </button>
    </div>
    <div class="description">{{ 'loginFormGoogle.description' | translate:locale.language }}</div>
    <div class="cliniweb-logo-container">
        <img src="/assets/images/cliniweb/logo-cliniweb-phr.svg" />
    </div>
</div>
