import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ComponentName } from '../../enums/component-name.enum';
import { ComponentLookup } from '../../decorators/component-lookup.decorator';
import { IControlComponent } from '../../interfaces/control-component';
import { FormComponentData } from '../../models/people/form-control.model';
import { ComponentDataSetMessage, ComponentFinishLoadingMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { SelectPatientRelationshipModel } from '../../models/workflow/models/select-patient-relationship.model';
import { UserService } from 'src/app/core/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, zip } from 'rxjs';
import { Dependent } from '../../models/people/dependent.model';
import { BaseService } from 'src/app/core/services/base.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserPerson } from '../../models/people/user-person.model';
import { UtilsService } from 'src/app/core/services/utils.service';
import { SenderPatientRelationship } from '../../models/process/base-request.model';

@Component({
  selector: 'app-select-patient-relationship',
  templateUrl: './select-patient-relationship.component.html',
  styleUrls: ['./select-patient-relationship.component.css'],
  encapsulation: ViewEncapsulation.None
})

@ComponentLookup(ComponentName.SELECT_PATIENT_RELATIONSHIP)
export class SelectPatientRelationshipComponent implements IControlComponent, OnInit {
  data: FormComponentData;
  
  model:SelectPatientRelationshipModel = new SelectPatientRelationshipModel();
  private ngUnsubscribe = new Subject();
  formSubmitted: boolean = false;
  loading: boolean = false;
  user: UserPerson;
  dependents: Dependent[] = [];
  patientRelationshipTypeForm:FormGroup;
  showForm:boolean = false;

  constructor(
    private baseService: BaseService,
    private userService: UserService,
    private utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private messageBusService: MessageBusService,
    private translation: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) { }
  

  ngOnInit(): void {
    this.loadDataModel();

    const _getUserLoggedIn$ = this.userService.getUserPersonLoggedIn();
    const _getUserDependents$ = this.userService.getUserDependents();

    zip(_getUserLoggedIn$, _getUserDependents$)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(([user, dependents])=>{

        if(!user)
          return;
        
        this.user = user;
        this.dependents = dependents;
        // Order by age desc
        this.dependents = this.dependents.sort((d1: Dependent, d2: Dependent) => {
          let getTime = (date: Date) => {return date != null ? new Date(date).getTime() : 0;};
          
          return getTime(d1.fechaNacimiento) - getTime(d2.fechaNacimiento);
        });

        this.loadForm();

        this.sendComponentFinishLoadingMessage();
      },
      error=>{
        this.baseService.handleServiceError(error, "Error getting user info");
      });
  }

  getUserAvatar(user: UserPerson){    
    return user.ubicacionLogo
      ? user.ubicacionLogo
      : this.utilsService.getPersonDefaultAvatarUrl(user.fechaNacimiento, user.sexo);
  }

  getDependentAvatar(dependent: Dependent){
    return dependent.ubicacionLogo
      ? dependent.ubicacionLogo
      : this.utilsService.getPersonDefaultAvatarUrl(dependent.fechaNacimiento, dependent.sexo);
  }

  getRelationshipText(dependent: Dependent){
    return this.translation.translate(`shared.selectPatientAppointmentCheckout.relationship_${dependent.tipoRelacion}_${dependent.sexo}`);
  }

  onClickContinue(){
    this.formSubmitted = true;
    
    if (this.patientRelationshipTypeForm.invalid) {
      return;
    }

    this.loading = true;

    let _valueSelected = +(this.patientRelationshipTypeForm.controls.relationship.value);

    let _dependentSelected = this.dependents.find(d=> d.idPersona == _valueSelected);

    if(_dependentSelected){
      this.model.patientRelationship = _dependentSelected.tipoRelacion;
      this.model.patientIdRelationship = _dependentSelected.idPersona;
    }
    else if(this.user.id == _valueSelected){
      this.model.patientIdRelationship = this.user.id;
      this.model.patientRelationship = SenderPatientRelationship.USUARIO;
    }
    else {
      this.model.patientIdRelationship = 0;
      this.model.patientRelationship = SenderPatientRelationship.OTRO;
    }

    this.continueFlow();
  }

  private continueFlow() {
    let message = new ComponentDataSetMessage();
    message.componentName = ComponentName.SELECT_PATIENT_RELATIONSHIP;

    message.data = this.model;

    this.messageBusService.onComponentDataSetMessage(message); 
  }

  private loadDataModel() {
    if (this.data && this.data.configurationData) {
      this.model = this.data.configurationData as SelectPatientRelationshipModel;        
    }     
  }

  private loadForm(){
    this.patientRelationshipTypeForm = this.formBuilder.group(
      {
        relationship: [this.model.patientIdRelationship, [Validators.required]]
      }
    );

    this.showForm = true;
  }

  private sendComponentFinishLoadingMessage(){
    let event = new ComponentFinishLoadingMessage();
      event.idFormulario = this.data.idFormulario;
      event.idControl = this.data.idControlPadre;

    this.messageBusService.componentFinishLoading(event);   
  }
}
