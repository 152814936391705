<div class="view-user-container">    
    <mat-card class="payment-card" *ngIf="loggedInUser">

        <div class="title">
            {{'shared.viewUser.text1' | translate:locale.language}} {{loggedInUser.nombre}}
            <span>({{'shared.viewUser.text2' | translate:locale.language}})</span>
        </div>
        <div class="avatar-container">
            <app-avatar-upload
                [fileUrl]="loggedInUser.ubicacionLogo"
                [id]="loggedInUser.id"
                [options]="avatarUploadOptions"
                (onFileUploaded)="onAvatarUploaded($event)"
            ></app-avatar-upload>
        </div>

        <div class="readonly-data-container">
            <div class="item" *ngIf="loggedInUser.fullName">
                <div class="item-title">
                    {{'shared.viewUser.text4' | translate:locale.language}}
                </div>
                <div class="item-value">
                    {{loggedInUser.fullName}}
                </div>
            </div>
            <div class="item" *ngIf="fechaNacimiento">
                <div class="item-title">
                    {{'shared.viewUser.text5' | translate:locale.language}}
                </div>
                <div class="item-value">
                    <span>{{ fechaNacimiento }}</span>                    
                </div>
            </div>
            <div class="item" *ngIf="loggedInUser.sexName">
                <div class="item-title">
                    {{'shared.viewUser.text6' | translate:locale.language}}
                </div>
                <div class="item-value" style="text-transform: capitalize;">
                    <mat-icon fontSet="phr-iconset" class="{{loggedInUser.sexIconClass}} icon phr-iconset"></mat-icon>
                    {{loggedInUser.sexName}}
                </div>
            </div>
            <div class="item" *ngIf="loggedInUser.residenceCountryName">
                <div class="item-title">
                    {{ 'shared.viewUser.text11' | translate:locale.language }}
                </div>
                <div class="item-value" style="text-transform: capitalize;">
                    {{loggedInUser.residenceCountryName | i18nText:locale.language}}
                </div>
            </div>
        </div>

    </mat-card>

    <div class="buttons-container">
        <button mat-button mat-flat-button class="primary" (click)="onUserEditClick()">{{'shared.viewUser.text7' | translate:locale.language}}</button>
    </div>
</div>