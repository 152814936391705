export default class CommonTypesHelper {
    
    static dateHasValue(dateToValidate?: Date | null) : boolean {
        if (dateToValidate && new Date(dateToValidate) > new Date(1900, 1, 1)) {
            return true;
        } else {
            return false; 
        }   
    }

    static stringHasValue(stringToValidate?: string) : boolean {
        if (stringToValidate && stringToValidate != '9') {
            return true;
        } else {
            return false;
        }
   }
}