import { Component, Inject, OnInit, ViewEncapsulation, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup.decorator';
import { L10nLocale, L10N_LOCALE, L10nTranslationService } from 'angular-l10n';
import { PatientAppointment } from 'src/app/shared/models/process/appointment.model';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseService } from 'src/app/core/services/base.service';
import { Directory } from 'src/app/shared/models/systems/directory.model';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { Dependent } from 'src/app/shared/models/people/dependent.model';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';
import { AppointmentCancelationRouteDialogModel, AppointmentSummaryRouteDialogModel, PublicProfileRouteDialogModel, RescheduleAppointmentRouteDialogModel } from '../../dialogs/route-dialog/route-dialog.component';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { PublicProfileVersion } from 'src/app/shared/models/systems/portal-phr.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { PublicProfileAvatarComponentModel } from '../../public-profile-avatar/public-profile-avatar.component';
import { PublicProfileType } from 'src/app/shared/models/people/profile-search-results.model';

@Component({
  selector: 'app-cliniweb-appointment',
  templateUrl: './cliniweb-appointment.component.html',
  styleUrls: ['./cliniweb-appointment.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('CliniwebAppointmentsComponent')
export class CliniwebAppointmentsComponent implements OnInit, OnDestroy, IControlComponent {
  data: FormComponentData;
  inProgressState: string;
  appointment : CliniwebAppointmentModel;

  //subscribes
  private ngUnsubscribe = new Subject();
  
  // Input / Output
  @Input() loading : boolean = false;
  @Input() directory : Directory;
  @Input() dependents : Dependent[];
  @Input() loggedInUser: UserPerson;
  @Input() popupView: boolean = false;  
  @Input() set app(value: PatientAppointment) {
    if (!value)
      return;

    this.appointment = value as CliniwebAppointmentModel;

    this.avatarModel = new PublicProfileAvatarComponentModel();
    this.avatarModel.personId = this.appointment.idResponsableServicio;
    this.avatarModel.avatarUrl = this.appointment.urlAvatarResponsableServicio;
    this.avatarModel.fullName = this.appointment.nombreResponsableServicio;
    this.avatarModel.sex = this.appointment.sexoResponsableServicio;
    this.avatarModel.profileType = PublicProfileType.Doctor;
    this.avatarModel.defaultBorderColor = "var(--grayscale11)";    

    this.setAppointmentExtraData(this.appointment); 
  };
  @Output() onClose: EventEmitter<void> = new EventEmitter();

  translations = {
    day: "",
    hour: "",
    min: "",
    me: "",
    other: "",
    son: "",
    daughter: "",
    father: "",
    mother: "",
    completedStateName: "",
    notCompletedStateName: "",
    cancelledStateName: "",
    unconfirmedStateName: "",
    confirmedStateName: ""
  };
  avatarModel: PublicProfileAvatarComponentModel;
  
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale, 
    private translation: L10nTranslationService,
    public dialog: MatDialog,
    private router: Router,
    private baseService: BaseService,
    private publicProfileService: PublicProfileService,
    private routeDialogService: RouteDialogService,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver) {
  }

  ngOnInit(): void {
    this.translation.onChange()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
      next: () => {
          this.translations.day = this.translation.translate('days');                    
          this.translations.hour = this.translation.translate('hours');        
          this.translations.min = this.translation.translate('minutes');          
          this.translations.me = this.translation.translate('me');
          this.translations.other = this.translation.translate('other');
          this.translations.son = this.translation.translate('son');
          this.translations.daughter = this.translation.translate('daughter');
          this.translations.father = this.translation.translate('father');
          this.translations.mother = this.translation.translate('mother');
          this.translations.completedStateName = this.translation.translate('shared.cliniwebAppointment.text8');                    
          this.translations.notCompletedStateName = this.translation.translate('shared.cliniwebAppointment.text9');
          this.translations.cancelledStateName = this.translation.translate('shared.cliniwebAppointment.text10');
          this.translations.unconfirmedStateName = this.translation.translate('shared.cliniwebAppointment.text13'); 
          this.translations.confirmedStateName = this.translation.translate('shared.cliniwebAppointment.text14'); 
      }
    });    
    
    if (!this.loading)
        this.setAppointmentExtraData(this.appointment);    
  }  

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setAppointmentExtraData(app: CliniwebAppointmentModel) {
    app.patientFirstName = this.getName(app);
    app.locationDescription = this.getLocationDescription(app);
    app.patientRelationship = this.getPatientRelationship(app);
    app.doctorAvatarUrl = this.getAvatarUrl(app);

    app.appointmentExpired = this.isAppointmentExpired(app);
    app.appointmentFormattedDate = this.getAppointmentDateStr(app.fechaRequerido, !app.appointmentExpired);
    
    if (!app.appointmentExpired && (app.idEstado == 2 || app.idEstado == 22 || app.idEstado == 23)) {
      app.description = 
        this.translation.translate('shared.cliniwebAppointment.text1', { 
            especialidadResponsableServicio: app.especialidadResponsableServicio, 
            nombreResponsableServicio: app.patientFirstName});      
    }
    else {
      app.description = 
        this.translation.translate('shared.cliniwebAppointment.text18', { 
          especialidadResponsableServicio: app.especialidadResponsableServicio, 
          nombreResponsableServicio: app.patientFirstName});
    }    
    
    app.teleconsultation = this.isTeleconsultation(app);

    if (app.teleconsultation)
      app.teleconsultationUrl = this.buildTeleconsultationLink(app);

    app.appointmentStateDisplayName = this.getAppointmentStateName(app);
    app.appointmentStateClassName = this.getAppointmentStateClassName(app);
    app.appointmentStateIconClassName = this.getAppointmentStateIconClassName(app);
  }

  getPatientFirstName(item: CliniwebAppointmentModel) {
    return item.nombrePaciente.split(" ")[0];
  }

  getName(app: CliniwebAppointmentModel) {
    if (app.idPacienteOrigen == this.loggedInUser.id) {
      return this.loggedInUser.nombre;
    }

    let dependent = this.dependents.find(d => d.idPersona == app.idPacienteOrigen);
    if (dependent) {
      return dependent?.nombre;
    }    

    return this.getPatientFirstName(app);
  }

  getPatientRelationship(app: CliniwebAppointmentModel) : string {
    let relationship = this.translations.other;

    if (app.idPacienteOrigen != this.loggedInUser.id) {
      // Search for dependent
      let dependent = this.dependents.find(d => d.idPersona == app.idPacienteOrigen);
      
      relationship = this.userService.getDependentRelationshipName(dependent);     
    }
    else {
      relationship = this.translations.me;
    }

    return relationship;
  }  

  getLocationDescription(item : CliniwebAppointmentModel) {
    /*let desc = item.direccionLocalidad;

    if (!desc)
      desc = item.nombreLocalidad;   

    return desc;*/

    return item.nombreLocalidad;
  }

  getAvatarUrl(item : CliniwebAppointmentModel) {
    let avatarUrl = item.urlAvatarResponsableServicio;

    if (!avatarUrl)
      avatarUrl = "/assets/images/avatar_male_doctor.svg";

      return avatarUrl;
  }

  getAppointmentDateStr(date: Date, includeYear: boolean) {
    return this.baseService.getBasicDateFormat(date, includeYear, " ");
  }

  isAppointmentExpired(item : CliniwebAppointmentModel) {
    let now = new Date().getTime();
    let appDate = new Date(item.fechaRequerido).getTime();

    return appDate <= now;
  }

  getAppointmentRemainingTime(item : CliniwebAppointmentModel) {
    const dayDiff = 60 * 60 * 24;
    const hrDiff = 60 * 60;
    let now = new Date();
    let appDate = new Date(item.fechaRequerido);
    let timeDiff = (appDate.getTime() - now.getTime()) / 1000;
    let diffStr = "";

    if (timeDiff <= 0) {
      diffStr = this.inProgressState;
    }
    else if (timeDiff > dayDiff) {
      diffStr = (Math.ceil(timeDiff / dayDiff)) + " " + this.translations.day;
    }
    else if (timeDiff > hrDiff) {
      diffStr = (Math.floor(timeDiff / hrDiff)) + " " + this.translations.hour;
    }
    else {
      diffStr = (Math.floor(timeDiff / 60)) + " " + this.translations.min;
    }

    return diffStr;
  }

  isTeleconsultation(item : CliniwebAppointmentModel) : boolean {
    return (item.teleconsulta != undefined && item.teleconsulta.toLocaleUpperCase() === "S");
  }

  getAppointmentStateName(app: CliniwebAppointmentModel) : string {
    let stateName = app.nombreEstado;   
    
    if (this.isAppointmentExpired(app)) {
      // Cancelado = 7
      // No asistio = 28
      if (app.idEstado != 7 && app.idEstado != 28)
        stateName = this.translation.translate('shared.cliniwebAppointment.text19');
    }
    else {
      // Cobrado = 3
      // Sin Cobrar = 27
      if (app.idEstado == 3 || app.idEstado == 27)
        stateName = this.translation.translate('shared.cliniwebAppointment.text20');
     
    }
    
    return stateName;
  }

  getAppointmentStateClassName(app: CliniwebAppointmentModel) : string {
    let stateClassName = "";
    
    if (this.isAppointmentExpired(app)) {
      if (app.idEstado == 7)
        stateClassName = "cancelled";
      else if (app.idEstado == 28)
        stateClassName = "not-completted";      
    }
    else {
      if (app.idEstado == 23 || app.idEstado == 24 || app.idEstado == 30)
        stateClassName = "confirmed";
      else if (app.idEstado == 7)
        stateClassName = "cancelled";
      else if (app.idEstado == 28)
        stateClassName = "not-completted";
      else if (app.idEstado == 2)
        stateClassName = "not-confirmed";
      else if (app.idEstado == 22)
        stateClassName = "pending";
    }

    return stateClassName;
  }

  getAppointmentStateIconClassName(app: CliniwebAppointmentModel) : string {
    let stateIconClass = "phr-iconset-PHR-date-2";
    
    if (this.isAppointmentExpired(app)) {
      if (app.idEstado == 7 || app.idEstado == 28)
        stateIconClass = "phr-iconset-close-circle";
    }
    else {
      stateIconClass = "phr-iconset-validacion";

      if (app.idEstado == 7 || app.idEstado == 28)
        stateIconClass = "phr-iconset-close-circle";
      else if (app.idEstado == 22 || app.idEstado == 2)
        stateIconClass = "phr-iconset-alert";
      else if (app.idEstado == 3 || app.idEstado == 27)
        stateIconClass = "phr-iconset-PHR-date-2";
    }    
    
    return stateIconClass;
  }

  buildTeleconsultationLink(item : CliniwebAppointmentModel) {
    return this.directory.url + "/meet/" + item.teleconsultaId;
  }  
  
  onNewAppointmentClick() {    
    this.router.navigate(["/doctores"]);
  }

  onAppointmentViewMoreClick(appointment: CliniwebAppointmentModel) {
    let routeDialogModel = new AppointmentSummaryRouteDialogModel();
    const isMobile: boolean = this.breakpointObserver.isMatched('(max-width: 800px)');
    if (isMobile) routeDialogModel.headerName = 'DefaultBackHeaderComponent';
    routeDialogModel.route = "citas/" + appointment.idEtapaSolicitud;
    //routeDialogModel.state = null;
      
    this.routeDialogService.openDialog(routeDialogModel);
  }

  openVideConsultationLink(link: string) {
    window.open(link, "_blank");
  }

  onNavigateWithWaze(appointment: CliniwebAppointmentModel) {
    let coordinates = appointment.coordenadasLocalidad.replace(";", ",");
    let url = "https://www.waze.com/ul?ll=" + coordinates + "&navigate=yes&zoom=17";

    window.open(url, "_blank");
  }

  onCancelAppointment(appointment: CliniwebAppointmentModel) {
    let routeDialogModel = new AppointmentCancelationRouteDialogModel();
    routeDialogModel.route = "citas/cancelar/" + appointment.idEtapaSolicitud;
    routeDialogModel.state = null;

    this.routeDialogService.openDialog(routeDialogModel);
  }

  onUnconfirmedAppointmentClick() {
    let dialogData = new DialogData();
    
    dialogData.title = "";
    dialogData.message = this.translation.translate("shared.cliniwebAppointment.text15");    
    dialogData.confirmText = this.translation.translate("shared.cliniwebAppointment.text16");   
    dialogData.showHeaderIcon = false;
    dialogData.headerIconClass = "phr-iconset-info";   
    dialogData.showCancelButton = false;
    dialogData.cancelText = "";

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: dialogData,
        panelClass: ['confirm-logout-dialog', 'confirm-logout-animation-in', "unconfirmed-appointment-info-dialog"]
    });

    dialogRef.afterClosed().subscribe(accepted => {
        
    });
  }
  
  onReScheduleAppointment(item : CliniwebAppointmentModel) {
    this.goToPublicProfile(item);    
  }

  closePopupView() {
    this.onClose.emit();
  }

  goToPublicProfile(item : CliniwebAppointmentModel) {
    // If the doctor does not have a public profile
    if (!item.identificadorPerfilPublico) {
      let dialogData = new DialogData();
      dialogData.title = this.translation.translate("shared.pastAppointments.text4");
      dialogData.message = this.translation.translate("shared.pastAppointments.text5");
      dialogData.confirmText = this.translation.translate("shared.pastAppointments.text6");
      dialogData.showCancelButton = false;

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: dialogData
      });
    }
    else {
      let publicProfileVersion = this.baseService.getPublicProfileVersion();
      
      if (publicProfileVersion == PublicProfileVersion.V1) {
        let url = this.publicProfileService.getPublicProfileUrl(item.identificadorPerfilPublico);      
        this.router.navigate([url], { queryParams: {rs: true} });
      }
      else {
        /*let routeDialogModel = new RescheduleAppointmentRouteDialogModel();
        routeDialogModel.route = "perfil/reprogramar/" + item.identificadorPerfilPublico;
        routeDialogModel.state = null;*/
        
        let url = this.publicProfileService.getPublicProfileUrl(item.identificadorPerfilPublico);
        let routeDialogModel = new PublicProfileRouteDialogModel();
        routeDialogModel.route = url;
        
        this.routeDialogService.openDialog(routeDialogModel);
      }      
    }    
  }

}

export class CliniwebAppointmentModel extends PatientAppointment {
  appointmentExpired: boolean = false;
  teleconsultation: boolean = false;
  appointmentFormattedDate: string;
  description: string;
  patientFirstName: string;
  locationDescription: string;
  patientRelationship: string;
  doctorAvatarUrl: string;
  
  appointmentStateDisplayName: string;
  appointmentStateClassName: string;
  appointmentStateIconClassName: string;
  teleconsultationUrl: string;
}


