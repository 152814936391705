<div class="support-contact-component directory-background">
    <div class="container">
        <h2 class="title">{{'shared.supporContactMessageDialog.text4' | translate:locale.language}}</h2>
        <form [formGroup]="formulario" (ngSubmit)="formulario.valid && onSubmit()" class="template-panel row align-items-center">
            <mat-form-field class="item" appearance="outline">
                <textarea formControlName="texto" matInput placeholder="{{'shared.supporContactMessageDialog.text3' | translate:locale.language}}"></textarea>
                <!--<mat-error *ngIf="formulario.get('texto')?.invalid">Mensaje de error</mat-error>-->
            </mat-form-field>
            <div class="buttons-container">
                <button (click)="closeDialog()" [disabled]="loading" mat-button mat-flat-button class="secondary" type="button">
                    {{'shared.supporContactMessageDialog.text2' | translate:locale.language}}
                </button>
                <button mat-button mat-flat-button class="btn-spinner" [ngClass]="{'loading': loading}" color="primary" type="submit">
                    <mat-spinner *ngIf="loading" diameter="25" color="primary"></mat-spinner>
                    {{'shared.supporContactMessageDialog.text1' | translate:locale.language}}
                </button>
            </div>
        </form>
    </div>
</div>
