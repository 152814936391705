import { Component, Inject, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { MEDICAL_REQUEST_TYPE, MY_MEDICAL_ORDER_EMPTY_STATE_TEXT, MY_MEDICAL_ORDER_EMPTY_STATE_TITLE } from 'src/app/shared/enums/medical-request-type.enum';

@Component({
  selector: 'app-my-medical-orders-empty-state',
  templateUrl: './my-medical-orders-empty-state.component.html',
  styleUrls: ['./my-medical-orders-empty-state.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MyMedicalOrdersEmptyStateComponent implements OnChanges {

  @Input() tipoSolicitud = MEDICAL_REQUEST_TYPE.LABORATORY_ORDER;
  text:string = '';
  title: string = '';

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tipoSolicitud) {
      this.text = MY_MEDICAL_ORDER_EMPTY_STATE_TEXT[this.tipoSolicitud];
      this.title = MY_MEDICAL_ORDER_EMPTY_STATE_TITLE[this.tipoSolicitud];
    }
  }

}
