import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }      

  scrollTo(containerEl: Element, position: number) {
    containerEl.scrollTop = position;
  }

  scrollToWithAnimation(containerEl: Element, position: number) {
    // JQUERY dependency
    $(containerEl).animate({
      scrollTop: (position || 0)
    }, 500);
  }

  scrollToElementWithAnimation(containerEl: Element, element: Element) {
    let elOffset = $(element).offset();
    let containerOffset = $(containerEl).offset();
    let containerScrollTop = $(containerEl).scrollTop() || 0;

    let position = (elOffset? elOffset.top : 0) - (containerOffset? containerOffset.top : 0) + containerScrollTop;        
    
    // JQUERY dependency
    $(containerEl).animate({
      scrollTop: (position || 0)
    }, 500);
  }
  
  scrollBottom(containerEl: Element) {
    containerEl.scrollTop = containerEl.scrollHeight;
  }

  scrollBottomWithAnimation(containerEl: Element) {
    this.scrollToWithAnimation(containerEl, containerEl.scrollHeight);    
  }

  groupBy<T, K extends keyof any>(list: T[], keyGetter:(item: T) => K) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
  }

  containsValue(arrayString: string, separator: string, value: any) {

    if (!arrayString)
        return false;

    var array = arrayString.split(separator);

    return array.filter(elem => elem == value).length > 0;
  }

  containsAnyValue(arrayString: string, separator: string, valueArray: any[]) {

    if (!arrayString)
        return false;

    var array = arrayString.split(separator);

    return array.filter(elem => valueArray.includes(parseInt(elem))).length > 0;
  }  

  containsAnyNumber(arrayNumber: number[], valueArray: number[]) {
    if (!arrayNumber)
        return false;

        return arrayNumber.filter(elem => valueArray.indexOf(elem) > -1).length > 0;
  }

  /* Person Initials Utils */

  private getPersonInitialsString(personName: string) {
    let arrayText = personName.trim().replace(/\s\s+/g, ' ').split(" ");
    let result;

    if (arrayText.length > 1) {
        let lastnamePosition = arrayText.length - 1;

        if (lastnamePosition == 0 || (arrayText[lastnamePosition].length >= 2 && arrayText[lastnamePosition].includes('.'))) {
            result = arrayText[0][0] + arrayText[1][0];
        }
        else if (arrayText.length == 4) {
            result = arrayText[0][0] + arrayText[2][0];
        }
        else {
            result = arrayText[0][0] + arrayText[lastnamePosition][0];
        }
    }
    else {
        result = arrayText[0][0];
    }

    return result.toUpperCase();
  }

  private getHashCodeByString(personId: number, personName: string) {
    var hash = 0;

    for (var i = 0; i < personName.length; i++) {
        hash = personName.charCodeAt(i) + ((hash << 5) - hash);
    }

    return hash + personId;
  }

  private getPersonInitialsColor(personId: number, initials: string) {
    var hashCode = this.getHashCodeByString(personId, initials);

    var colors = [
        "#0ACBAC",
        "#17C65D",
        "#C801F2",
        "#FD3A58",
        "#F06C00",
        "#FFC000",
        "#296EFA",
        "#6B9DFF",
        "#7A1FA2",
        "#7C59BE"
    ];

    var index = hashCode % colors.length;

    return colors[index];
  };

  getPersonInitials(personId: number, personName: string) {
    let initials = this.getPersonInitialsString(personName);
    let color = this.getPersonInitialsColor(personId, initials);

    return {
        initials: initials,
        color: color
    };
  }

  getPersonDefaultAvatarUrl(birthDate: Date | null, sex: string) {
    const age = this.getPersonAge(birthDate);
    const avatarBaseUrl = "/assets/images/avatars/";
    let url = "Persona_anonima.svg";

    sex = sex.toLowerCase();
    if ( !Number.isFinite(age) || !sex || sex == '9')
      return url;

    const ageRange = this.getAgeRange(age);
    url = this.getPhotoByAgeRangeAndSex(ageRange, sex);

    return avatarBaseUrl + url;
  }

  private getAgeRange(age: number): string {
    let range = "76-99";
    if (age >= 0 && age <= 17) {
      range = "0-17";
    } else if (age >= 18 && age <= 45) {
      range = "18-45";
    } else if (age >= 46 && age <= 75) {
      range = "46-75";
    }
    return range;
}

private getPhotoBySex (sex: string): string {
  const sexo = sex === 'm' ? 'male' : (sex === 'f' ? 'female' : 'no_gender');
  return `profile_${sexo}.svg`;
}

private getPhotoByAgeRangeAndSex(ageRange: string, sex: string): string {
  const sexo = sex === 'm' ? 'Hombre': 'Mujer';
  return `${sexo}_${ageRange}.svg`;
}

  getPersonAge(birthDate: Date | null) {    
    if (!birthDate)
      return -1;

    if(typeof birthDate === 'string')
      birthDate = new Date(birthDate);  

    let now = new Date();
    let age = now.getFullYear() - birthDate.getFullYear();
    let m = now.getMonth() - birthDate.getMonth();
    
    if (m < 0 || (m === 0 && now.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
  }

  /* /Person Initials Utils */
}

