import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { error } from 'jquery';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppStorageService, STORAGE } from 'src/app/core/services/app-storage.service';
import { TerritorialDivisionsService } from 'src/app/core/services/territorial-divisions.service';
import { UserService } from 'src/app/core/services/user.service';
import { CountryModel, TerritorialDivision } from 'src/app/shared/models/systems/territorial-division.model';

@Component({
  selector: 'app-select-country-dialog',
  templateUrl: './select-country-dialog.component.html',
  styleUrls: ['./select-country-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SelectCountryDialogComponent implements OnInit {


  errorSubject = new BehaviorSubject<string>('');
  get errorObservable(): Observable<string> {
    return this.errorSubject.asObservable();
  }
  
  constructor(
    private userService: UserService,
    private territorialDivisionService: TerritorialDivisionsService,
    private appStorageService: AppStorageService,
    private mdDialogRef: MatDialogRef<SelectCountryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,    
    @Inject(L10N_LOCALE) public locale: L10nLocale,
  ) { }

  ngOnInit(): void {
  }

  countrySelected(country: TerritorialDivision){
    this.userService.updateUserResidenceCountry(country.idPais).subscribe(result=>{

      // SET COUNTRY APP
      this.territorialDivisionService.setCountryApp(country.idPais);

      // UPDATE USER
      this.userService.getUserPersonLoggedIn().subscribe(user=>{

        if(user){
          user.idPaisResidencia = country.idPais;

          this.userService.updateUserLoggedIn(user);
        }
      });

      this.mdDialogRef.close();
      this.appStorageService.removeSessionItem(STORAGE.SELECT_COUNTRY);
    }, error =>{
      
      this.errorSubject.next("Hubo un error al grabar el país, intente nuevamente");
    });
  }
}
