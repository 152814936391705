<div class="medicines-wrapper" ngClass.lt-md="mobile">
    <div class="medicines-card-wrapper" *ngFor="let item of items">
        <label>
            {{ 'shared.clinicHistory.medicines.text1' | translate:locale.language }}
            <b>
                {{ item.fecha | l10nDate:locale.language:{ day:'numeric', month: 'short'}  | uppercase }}, 
                {{ item.fecha | l10nDate:locale.language:{ year:'numeric' }  | uppercase }}
            </b>
        </label>
        <mat-card class="medicines-card">
            <mat-card-content>
                <div>
                    <!-- Links -->
                    <div class="link-wrapper">
                        <a class="link share">
                            <span fxHide.lt-md="true">{{'shared.clinicHistory.share' | translate:locale.language | uppercase}}</span>
                            <mat-icon>share</mat-icon>
                        </a>
                    </div>
                    <div class="title-wrapper">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-doctores"></mat-icon>
                        <span>{{ item.titulo }}</span>
                    </div>
                    <div class="subtitle-wrapper">
                        <span>{{ 'shared.clinicHistory.medicines.text2' | translate:locale.language }}</span>
                        <span>{{ item.responsable }}</span>
                    </div>
                    <div class="concepts-wrapper" *ngFor="let conceptGrouped of item.conceptosMedicamentos">
                        <span class="concept-type-title">{{ conceptGrouped.titulo }}</span>
                        <div *ngIf="conceptGrouped.tieneConceptos">
                            <div class="concept-container" *ngFor="let concept of conceptGrouped.conceptos">                                
                                <!-- <mat-icon fontSet="phr-iconset" class="phr-iconset-doctores"></mat-icon> -->
                                <span>{{ concept.nombreConcepto }}</span>
                                <span *ngIf="concept.valorConcepto">: {{ concept.valorConcepto }}</span>
                            </div>
                        </div>
                        <div class="files-wrapper" *ngIf="conceptGrouped.tieneDocumentos">
                            <div class="file-item" [ngClass]="getClassPreviewFile(fileConcept)" *ngFor="let fileConcept of conceptGrouped.documentos">
                                <img class="preview-image"
                                    *ngIf="getClassPreviewFile(fileConcept)"
                                    [src]="getPreviewFileUrl(fileConcept)" />
                                <div class="preview-image"
                                    *ngIf="!getClassPreviewFile(fileConcept)" 
                                    [style.background-image]="'url(' + getPreviewFileUrl(fileConcept) + ')'" >
                                </div>
                                <span class="file-title">{{fileConcept.nombreConcepto}}</span>
                                <span class="file-description">{{fileConcept.nombreDocumento}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="empty-state-container" *ngIf="!items || items.length == 0">
        <div class="title">{{ 'shared.clinicHistory.medicines.text3' | translate:locale.language }}</div>
        <div class="subtitle">{{ 'shared.clinicHistory.medicines.text4' | translate:locale.language }}</div>
    </div>
</div>
