import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AppStorageService } from '../services/app-storage.service';
import { catchError, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MessageBusService, SessionExpiredMessage } from 'src/app/core/services/message-bus.service';
import { Token } from 'src/app/shared/models/token.model';
import { JwtHelperService } from "@auth0/angular-jwt";

const TOKEN_HEADER_KEY = 'Authorization';
const AUTH_API = environment.apiUrl + 'oauth/token';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
};

@Injectable({
    providedIn: 'root'
  })
  export class HttpBaseService {
    private refreshSubject = new BehaviorSubject<void>(undefined);

    constructor(
        private http: HttpClient,
        private router: Router,
        private appStorageService: AppStorageService,
        private messageBusService: MessageBusService) { }

    public get<T>(url: string, options: any = null, useAuthentication: boolean = true) : Observable<T> {        
        if (!options) options = {};
        if (!options.headers) options.headers = new HttpHeaders();

        url = environment.apiUrl + url;

        if (!useAuthentication) {
            return this.http.get<T>(url, options as object);
        }
        else {
            return this.setAuthenticationHeader(options.headers).pipe(switchMap(header => {                
                options.headers = header;
                
                return this.http.get<T>(url, options as object)
            }))    
        }            
    }  

    public post<T>(url: string, body: any = null, options: any = null, useAuthentication: boolean = true) : Observable<T> {
        if (!options) options = {};
        if (!options.headers) options.headers = new HttpHeaders();

        url = environment.apiUrl + url;

        if (!useAuthentication) {
            return this.http.post<T>(url, body, options as object);
        }
        else {
            return this.setAuthenticationHeader(options.headers).pipe(switchMap(header => {
                options.headers = header;
                
                return this.http.post<T>(url, body, options as object)
            }))    
        }
    }

    public put<T>(url: string, body: any = null, options: any = null, useAuthentication: boolean = true) : Observable<T> {
        if (!options) options = {};
        if (!options.headers) options.headers = new HttpHeaders();

        url = environment.apiUrl + url;
        
        if (!useAuthentication) {
            return this.http.put<T>(url, body, options as object);
        }
        else {
            return this.setAuthenticationHeader(options.headers).pipe(switchMap(header => {
                options.headers = header;
                
                return this.http.put<T>(url, body, options as object)
            }))    
        }
    }

    public delete<T>(url: string, options: any = null, useAuthentication: boolean = true) : Observable<T> {
        if (!options) options = {};
        if (!options.headers) options.headers = new HttpHeaders();

        url = environment.apiUrl + url;
        
        if (!useAuthentication) {
            return this.http.delete<T>(url, options as object);
        }
        else {
            return this.setAuthenticationHeader(options.headers).pipe(switchMap(header => {
                options.headers = header;
                
                return this.http.delete<T>(url, options as object)
            }))    
        }
    }

    public sessionExpired(returnUrl: string) {
        // This case is when the "empty" page is loaded from a doctor public profile, in this case we do not want to show the session expired popup
        if(window.location.pathname.indexOf("empty") > -1) {
            this.router.navigate(['/login'], { queryParams: { returnUrl: "empty" } });
        }
        else
            // This if is just because when the user insert an invalid credential on login the server return a 401 (unauthorized)
            if(window.location.pathname.indexOf("login") == -1 )
                this.messageBusService.sessionExpired(new SessionExpiredMessage(returnUrl));
    }

    public isTokenExpired(token: Token): boolean {

        const helper = new JwtHelperService();
    
        return helper.isTokenExpired(token.access_token, 300);
    }

    public getValidAuthenticationToken(redirectToLogin:boolean = true) : Observable<Token | null> {
        let token = this.appStorageService.getToken();

        if (this.isTokenExpired(token)) {
            this.refreshSubject.next(); // Emitir un valor para refrescar el Observable

            return this.refreshSubject.pipe(
              switchMap(() => this.refreshAuthenticationToken(token)),
              tap((t) => {
                t.username = token.username;
                // Set token on local storage
                this.appStorageService.setToken(t);
              }),
              catchError((err) => {
                if (redirectToLogin) {
                  this.onAuthenticationRequired();
                  throw new BaseHttpServiceUnauthorizeException();
                } else {
                  return of(null);
                }
              }),
              shareReplay(1) // Compartir el resultado con las suscripciones
            );
        }
        else {
            return of(token);
        }
    }

    private setAuthenticationHeader(headers: HttpHeaders) : Observable<HttpHeaders> {
        let tokenObject = this.appStorageService.getToken();

        if (!tokenObject) {
            // If token is not present let just ask for one
            this.onAuthenticationRequired();       
            
            // Remove exception in order to avoid unnecessary errors in browser console.
            //throw new BaseHttpServiceUnauthorizeException();
            // Returnin NULL/Undefined will make the pipe to skip the result, so the service will never be called.
            return of();
        }
        else {
            const tokenIsExpired = this.isTokenExpired(tokenObject);

            // If the token is expired lets try to get a new one using the refresh token
            if (tokenIsExpired) {
                return this.refreshAuthenticationToken(tokenObject).pipe(                                       
                    switchMap(t => {
                        t.username = tokenObject.username;
                        // Set token on local storage
                        this.appStorageService.setToken(t);

                        // Set authentication header on request
                        headers = headers.set(TOKEN_HEADER_KEY, 'Bearer ' + t.access_token);                    

                        return of(headers);
                    }),
                    catchError(err => {
                        this.onAuthenticationRequired();

                        throw new BaseHttpServiceUnauthorizeException();
                    }));
            }
            else {
                // Set authentication header on request
                headers = headers.set(TOKEN_HEADER_KEY, 'Bearer ' + tokenObject.access_token);
                    
                return of(headers);
            }
        }        
    }    

    private refreshAuthenticationToken(token: any) {
        return this.refreshToken(token.refresh_token);            
    }

    private refreshToken(refreshToken: string): Observable<Token> {
        let body = new HttpParams()
        .set('refresh_token', refreshToken)
        .set('grant_type', 'refresh_token');
    
        return this.http.post<Token>(AUTH_API, body.toString(), httpOptions);
    }

    private onAuthenticationRequired() {
        this.sessionExpired(window.location.pathname);
    }  
    
  }

  export class BaseHttpServiceUnauthorizeException {

  }