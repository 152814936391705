<div class="clinic-history-tabs-container">
    <mat-tab-group animationDuration="0ms" color="accent" (selectedIndexChange)="onSelectTab()">
        <mat-tab *ngFor="let tab of tabs">
            <ng-template mat-tab-label>{{tab.titulo | i18nText:locale.language}}</ng-template>
            <div class="tab-content" fxLayout="column wrap">
                <div *ngFor="let component of tab.componentesArray">
                    <app-dynamic [componentName]="component"></app-dynamic>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
