import { Inject, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { ClinicHistoryService } from 'src/app/core/services/clinic-history.service';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { GroupConcept } from 'src/app/shared/enums/group-concept.enum';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { AttentionGroupItem, AttentionHistory, ClinicHistoryItem } from 'src/app/shared/models/clinic-history/attention-history.model';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { FileViewerDialogComponent } from '../../dialogs/file-viewer-dialog/file-viewer-dialog.component';
import { FileViewerDto, FileViewerItemDto } from '../../file-viewer/file-viewer.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-studies-laboratories',
  templateUrl: './studies-laboratories.component.html',
  styleUrls: ['./studies-laboratories.component.css']
})
@ComponentLookup('StudiesLaboratoriesComponent')
export class StudiesLaboratoriesComponent implements OnInit, OnDestroy, IControlComponent {
  data: FormComponentData;

  items: StudiesLaboratoriesViewDTO[] = [];

  //subscribes
  private ngUnsubscribe = new Subject();

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private messageBusService: MessageBusService,
    public dialog: MatDialog,
    private clinicHistoryService: ClinicHistoryService
  ) { }

  ngOnInit(): void {
    this.messageBusService.onSendClinicHistory()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(message =>{
      this.createViewDto(message.clinicHistory.historialAtenciones);
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  createViewDto(atenciones: AttentionHistory[]){

    let atencionesLaboratorios = this.clinicHistoryService.getAttentionsByGroup(atenciones, GroupConcept.LABORATORIOS);

    atencionesLaboratorios.forEach(attention => {

      let dto = new StudiesLaboratoriesViewDTO();

      dto.titulo = attention.contactoClinico.asunto;
      dto.fecha = attention.contactoClinico.fechaContacto;
      dto.responsable = attention.contactoClinico.nombreResponsableServicio;

      dto.conceptosLaboratorios = attention.atenciones.map(a => new StudiesLaboratoriesItemViewDTO(a));

      this.items.push(dto);
    });
  }

  getClassPreviewFile(concept: ClinicHistoryItem): string {
    return this.clinicHistoryService.getClassPreviewFile(concept.extensionDocumento);
  }

  getPreviewFileUrl(concept: ClinicHistoryItem): string {
    return this.clinicHistoryService.getPreviewFileUrl(concept.extensionDocumento, concept.ubicacionWebDocumento);
  }

  onFileClick(item: StudiesLaboratoriesItemViewDTO, file: ClinicHistoryItem) {
    let dialogData = new DialogData();
    let viewerDto = new FileViewerDto(); 
    
    viewerDto.selectedFileId = file.idDocumento;
    viewerDto.files = this.getConsultationDocumentAsViewerItems(item);    

    dialogData.data = viewerDto;

    const dialogRef = this.dialog.open(FileViewerDialogComponent, {
      panelClass: 'file-viewer-dialog',
      data: dialogData
    });
  }

  getConsultationDocumentAsViewerItems(item: StudiesLaboratoriesItemViewDTO): FileViewerItemDto[] {
    let result = item.documentos.map(d => {
      let fileDto = new FileViewerItemDto();

      fileDto.fileId = d.idDocumento;
      fileDto.fileUrl = d.ubicacionWebDocumento;
      fileDto.amazonBucketName = d.nombreBucketAmazon;
      fileDto.fileExtension = d.extensionDocumento;
      fileDto.webUrl = d.ubicacionWebDocumento;

      fileDto.createdAt = d.fechaRegistro;
      fileDto.title = d.nombreDocumento;
      fileDto.description = d.descripcion;

      return fileDto;
    });

    return result;
  }
}

export class StudiesLaboratoriesViewDTO{
  titulo: string;
  fecha: Date;
  responsable: string;
  conceptosLaboratorios: StudiesLaboratoriesItemViewDTO[];
}

export class StudiesLaboratoriesItemViewDTO {
  titulo: string;
  conceptos: ClinicHistoryItem[];
  documentos: ClinicHistoryItem[];
  tieneConceptos: boolean;
  tieneDocumentos: boolean;

  constructor (group: AttentionGroupItem){
    this.titulo = group.nombreTipoConcepto;
    this.conceptos = group.items.filter(c => c.idDocumento == 0);
    this.documentos = group.items.filter(c => c.idDocumento > 0);
    this.tieneConceptos = this.conceptos.length > 0;
    this.tieneDocumentos = this.documentos.length > 0;
  }
}
