export class Token {
    access_token: string;
    token_type: string;
    expires_in: number;
    refresh_token: string;
    username: string;
    isInvalid:boolean;
    invalidMessage: string;
    state: string;    
    userCreated: boolean;
}

export class TokenState {
    email: string;
    returnUrl: string;
    flowType: OAuthFlowType = OAuthFlowType.None;
    sourceType: OAuthSourceType = OAuthSourceType.None;
    
    // Only to solve PWA issues on OIDC callbaks
    workflowType: string = "";
    publicProfileIdentifier: string = "";
    targetDate: number = 0;
    locationId: number = 0;
}

export enum OAuthFlowType {
    None = 1,
    Login = 2,
    Signup = 3
}

export enum OAuthSourceType {
    None = 1,
    Sura = 2,
    Google = 3
}
