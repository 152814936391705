import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { L10nResolver } from 'angular-l10n';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HomeComponent } from 'src/app/modules/home/pages/home/home.component';
import { FormComponent } from 'src/app/shared/components/form/form.component';
import { Link } from 'src/app/shared/models/people/link.model';
import { MenuGuard } from '../guards/menu.guard';
import { BaseService } from './base.service';
import { AuthPrivateRoutesGuard } from '../guards/auth-private-routes.guard';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RouteLoaderService {

  constructor(private baseService: BaseService, private router: Router) { }

  public load():Observable<void>{
    return this.baseService.getMenuLinks().pipe(map((links: Link[])=> this.buildMenuRoutes(links)));
  }

  buildMenuRoutes(links: Link[]) {
    let alreadyAdded = this.router.config.find(r=> r.path == links[0].identificadorUrl);

    if(alreadyAdded) return;

    links.forEach(link=>{
      if (link.identificadorUrl) {
        this.createRoute(link);
      }
      
      if (link.subMenu?.length) {
        link.subMenu.forEach(subLink => this.createRoute(subLink, true));
      }
    });

    this.router.resetConfig(this.router.config);
  }

  private createRoute(link: Link, isSublink = false) {
    const canActivate: any[] = [MenuGuard];
    let path = link.identificadorUrl;

    if (!isSublink) {
      canActivate.push(AuthPrivateRoutesGuard);
      path = this.baseService.getBasePath(link.identificadorUrl)
    }

    const alreadyExists = this.router.config.find(r => r.path == path);

    if (!alreadyExists) {
      const childrenRoutes = this.buildChildrenRoutebyLink(link);
    
      this.router.config.unshift(
        {
          path, canActivate,
          component: HomeComponent,
          children: childrenRoutes,
          resolve: { l10n: L10nResolver },
          data: {
              l10nProviders: [
                { name: 'home', asset: './assets/i18n/home', options: { version: environment.version } },
                { name: 'shared', asset: './assets/i18n/shared', options: { version: environment.version } }
              ],
              animation: link.nombreAnimacion ?? '',
              isRestrictedRoute: link.accesoPublico === 'N'
          }
        });
    }
  }

  private buildChildrenRoutebyLink(link:Link): Route[] {
    let childrenRouteArray: Route[] = [];
    const paramsArray = link.parametrosUrl?.split('/');
    
    if (!paramsArray?.length) {
      childrenRouteArray.push(this.childrenRoute(link));
    } else {
      childrenRouteArray = childrenRouteArray.concat(this.ifHaveParamsArray(paramsArray, link));
    }

    return childrenRouteArray;
  }

  private ifHaveParamsArray(paramsArray: string[], link: Link): Route[] {
    return paramsArray?.map((_, i) => {
      const path = this.getPathByNumber(link.parametrosUrl, i);

      return this.childrenRoute(link, path);
    });
  }

  private childrenRoute(link: Link, path = ''): Route {
    return {
      path, component: FormComponent,
      data: {
        idAreaSistema: link.idAreaSistema,
        nombreComponenteAnonimo: link.nombreComponenteAnonimo,
        idsTipoContenidos: link.idsTipoContenidos?.split(',')
      }
    };
  }

  private getPathByNumber(originPath: string, index: number): string {
    return originPath.split("/").slice(0, index).join("/");
  }

}
