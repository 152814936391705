<div class="my-medical-orders-component-container">
    <div class="breadcrumbs">
        <a [routerLink]="['/mi-salud']" [queryParams]="{ p: patientId }">
            <mat-icon matListIcon fontSet="phr-iconset" class="phr-iconset-PHR-Volver"></mat-icon>
        </a>
        <span>{{ nombreTipo | translate:locale.language }}</span>
    </div>
    <ng-container *ngIf="!loading">
        <app-my-medical-orders-empty-state [tipoSolicitud]="idTipo"></app-my-medical-orders-empty-state>
        <mat-card *ngFor="let item of items">
            <mat-card-content>
                <div class="doctor-img">
                    <app-public-profile-avatar *ngIf="item.publicProfileAvatar.personId" 
                        [model]="item.publicProfileAvatar" 
                        [withoutBorder]="true">
                    </app-public-profile-avatar>
                </div>
                <div class="detail">
                    <div class="estado">
                        <span *ngIf="item.estado == estadoOrdenMedicaEnum.COMPLETED">{{'shared.myMedicalOrders.completedStatus' | translate:locale.language }}</span>
                        <span *ngIf="item.estado != estadoOrdenMedicaEnum.COMPLETED">{{'shared.myMedicalOrders.pendingStatus' | translate:locale.language }}</span>
                    </div>
                    <div class="titulo">
                        <span>{{ nombreTipoCardText | translate:locale.language }}</span>
                        <span *ngIf="idTipo == tipoSolitudEnum.MEDICAL_PRESCRIPTION"> N° {{item.numeroOrdenMedica}}</span>
                        <br />
                        <span>para {{patientData.nombre}}</span>
                        <img class="patient-img" [src]="patientData.ubicacionLogo">
                    </div>
                    <div class="description">
                        <span>Ordenado por {{item.nombreDoctor}}</span><br />
                        <span>el {{item.fecha | date:"dd 'de' MMM',' yyyy":'':locale.language}}</span><br />
                        <span>{{item.cantidadDetalles || 0}} {{nombreConteoDetalle + (item.cantidadDetalles != 1 ? 'Plural' : '') | translate:locale.language}}</span>
                    </div>
                </div>
                <div class="footer">
                    <a class="see-order-button" (click)="viewMoreClick(item.uidOrdenMedica)">
                        Ver {{nombreTipoButtonText | translate:locale.language}} <mat-icon fontSet="phr-iconset" class="phr-iconset-arrow-down"></mat-icon>
                    </a>
                </div>
            </mat-card-content>
        </mat-card>
    </ng-container>
    <div class="my-medical-order-skeleton" *ngIf="loading">
        <ngx-skeleton-loader [theme]="{ 'width': '320px', 'height': '194px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '20px', 'margin-bottom': '0px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{ 'width': '320px', 'height': '194px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '20px', 'margin-bottom': '0px' }"></ngx-skeleton-loader>
    </div>
</div>
