import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppStorageService, STORAGE } from 'src/app/core/services/app-storage.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { BaseService } from 'src/app/core/services/base.service';
import { ComponentDataSetMessage, ComponentFinishLoadingMessage, LoginCompletedMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { UserService } from 'src/app/core/services/user.service';
import { ComponentLookup } from '../../decorators/component-lookup.decorator';
import { IControlComponent } from '../../interfaces/control-component';
import { FormComponentData } from '../../models/people/form-control.model';
import { RequestingUser } from '../../models/process/base-request.model';
import { UserPerson } from '../../models/people/user-person.model';
import { ComponentName } from '../../enums/component-name.enum';
import { AuthenticationComponentData, AuthenticationDataModel } from '../../models/workflow/models/authentication-data.model';
import { PublicProfileAvatarComponentModel } from '../public-profile-avatar/public-profile-avatar.component';
import { OAuthFlowType, OAuthSourceType, TokenState } from '../../models/token.model';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { AuthOidcService } from 'src/app/core/services/auth-oidc.service';
import { LegalAgreementType } from '../../enums/legal-agreements-type.enum';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('AuthenticationComponent')
export class AuthenticationComponent implements IControlComponent, OnInit, OnDestroy {
  data: FormComponentData;
  componentData: AuthenticationComponentData;
  
  model: AuthenticationDataModel = new AuthenticationDataModel();

  private ngUnsubscribe = new Subject();
  loading:boolean = false;
  loadingGoogle: boolean = false;
  requestingUser:RequestingUser;
  isCliniweb: boolean;
  avatarModel: PublicProfileAvatarComponentModel;

  titleTextRefence: string;

  unlicensed:boolean;

  alreadyLoggedIn: boolean;

  constructor(
    private router: Router,
    private baseService: BaseService,
    private authService: AuthService,
    private userService: UserService,
    private appStorageService: AppStorageService,
    private messageBusService: MessageBusService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private workflowService: WorkflowService,
    private authOidcService: AuthOidcService
  ) {
  }

  ngOnInit(): void {
    this.parseControlData();

    this.init();

    this.sendComponentFinishLoadingMessage();

    this.isCliniweb = this.baseService.isCliniwebCompany();

    if(this.isCliniweb){
      this.unlicensed = false;
      this.titleTextRefence = this.getTitleTextReference();
    }
    else{
      this.baseService.isLicensedCompany()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result=>{
        this.unlicensed = !result;

        this.titleTextRefence = this.getTitleTextReference();
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  redirectToSignup(): void {

    this.appStorageService.setItemSession(STORAGE.SIGNUP_WORKFLOW_VERIFICATION_TYPE, this.componentData.signupWorkflowVerificationType);
    this.router.navigate(
      ['signup/checkin'],
      { 
        queryParams: {
          returnUrl: this.router.routerState.snapshot.url.split('?')[0],
          closeUrl: this.router.routerState.snapshot.url.split('?')[0] + '?close=true'
        }
      });
  }

  redirectToLogin(): void {
    this.router.navigate(['login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url.split('?')[0] } });
  }

  loginByGoogle(){
    this.loadingGoogle = true;

    let oidcModel: TokenState;
    let workflowType = this.workflowService.getWorkflowTypeActive();

    if (workflowType) {
      oidcModel = this.workflowService.getTokenStateFromWorkflow();
    }
    else {
      oidcModel = new TokenState();
    }

    oidcModel.returnUrl = this.router.url;
    oidcModel.flowType = OAuthFlowType.Login;
    oidcModel.sourceType = OAuthSourceType.Google;

    let stringState = JSON.stringify(oidcModel);

    this.authOidcService.startAuthentication(stringState);
  }

  openTermsDialog(){
    this.baseService.openLegalAgreementsDialog(LegalAgreementType.TERMINOS_CONDICIONES);
  }

  openPrivacyDialog(){
    this.baseService.openLegalAgreementsDialog(LegalAgreementType.POLITICA_PRIVACIDAD);
  }

  onAppointmentKnowMoreClick() {
    if(this.unlicensed){
      this.baseService.openLearnMoreDialog();
    }
  }

  private init() {
    this.authService.isLoggedInFull().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: this.isLoggedInFull.bind(this),
      error: error => this.baseService.handleServiceError(error, "Error getting user logged in")
    });
  }

  private isLoggedInFull(isLoggedIn: boolean): void {
    if(isLoggedIn){
      this.alreadyLoggedIn = true;
      // If we do not use a timeout the sendComponentFinishLoadingMessage will be send at the same time the worflow is loading
      // this will make the workflow advance two steps (beacuse it gets the step completed when it is already moving to next step).
      setTimeout(() => {
        this.getUserPersonLoggedIn();
      }, 1000);        
    }
    else if (this.model.idVerificacion){
      const workflowTypeActive: string = this.workflowService.getWorkflowTypeActive();
      const condicion = workflowTypeActive == STORAGE.MEDICAL_ORDER_WORKFLOW_STATE || workflowTypeActive == STORAGE.GROWTH_CURVES_WORKFLOW_STATE;
      if (this.model.idUsuario <= 0 && !condicion) {
        this.completeAuthentication();
      }
    }
  }

  private completeAuthentication() {
    this.loading = true;

    this.userService.postConfirmation(this.model.idVerificacion)
      .subscribe({next:(response) => {
        if(response.success) {
          this.getUserPersonLoggedIn();
        }
        else{
          setTimeout(() => this.loading = false, 500);
          this.baseService.handleServiceError(response.message, "user confirmation error");
        }
      },
      error: error => {
        this.loading = false;
        this.baseService.handleServiceError(error, "user confirmation error");
      }
    });
  }  

  private parseControlData(){
    this.loading = true;

    if (this.data && this.data.configurationData) {
      this.model = this.data.configurationData as AuthenticationDataModel;
      this.componentData = this.model.configData;      

      this.avatarModel = new PublicProfileAvatarComponentModel();
      this.avatarModel.personId = this.model.profile.id;
      this.avatarModel.avatarUrl = this.model.profile.ubicacionFoto;
      this.avatarModel.fullName = this.model.profile.nombrePersona;
      this.avatarModel.sex = this.model.profile.sexo;
      this.avatarModel.profileType = this.model.profile.tipoPerfil;
    }

    this.loading = false;
  }

  private getTitleTextReference(){
    let textReference:string = '';

    if(!this.componentData?.workflowType)
      return textReference;

    textReference = this.selectTitleTextByWorkFlowTyope();

    if(this.unlicensed)
      textReference += '_unlicensed';

    return textReference;
  }

  private selectTitleTextByWorkFlowTyope(): string {
    let textReference = 'shared.authentication.appointmentText';
    
    switch(this.componentData.workflowType){
      case 1:
        textReference = 'shared.authentication.appointmentText';
        break;
      case 2:
        textReference = 'shared.authentication.paymentText';
        break;
      case 3:
        textReference = 'shared.authentication.medicalOrderText';
        break;
      case 4:
        textReference = 'shared.authentication.growthChartText';
    }

    return textReference;
  }

  private sendComponentFinishLoadingMessage(){
    let event = new ComponentFinishLoadingMessage();
      event.idFormulario = this.data.idFormulario;
      event.idControl = this.data.idControlPadre;

    this.messageBusService.componentFinishLoading(event);   
  }

  private onGetUserPersonLoggedIn(user:UserPerson){
    this.messageBusService.loginCompleted(new LoginCompletedMessage(user));

    let message = new ComponentDataSetMessage();
    message.componentName = ComponentName.AUTHENTICATION;
    message.data = user;

    this.messageBusService.onComponentDataSetMessage(message);   
  }

  private getUserPersonLoggedIn(){
    this.loading = true;
    this.userService.getUserPersonLoggedIn()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(user => {
            this.loading = false;

            if (user)
              this.onGetUserPersonLoggedIn(user);
          },
          error=>{
            this.baseService.handleServiceError(error, "Error creating online payment request");
          });
  }
}

