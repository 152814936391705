import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { UserPerson } from '../../models/people/user-person.model';
import { ChatLoadedMessage, MessageBusService, ServiceErrorMessage } from 'src/app/core/services/message-bus.service';
import { BaseService } from 'src/app/core/services/base.service';
import { ChatService } from 'src/app/core/services/chat-service';
import { ChatCancellationMessage, ChatConversacionMessage, ChatConversacionMessageType } from '../../models/people/chat-conversation.model';
import { VirtualAttentionService } from 'src/app/core/services/virtual-attention.service';
import { VirtualAttention } from '../../models/process/virtual-attention.model';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ChatSignalRService } from 'src/app/core/services/signalR/chat-signalr.service';
import { MatDialog } from '@angular/material/dialog';
import { CancelVirtualAttentionDialogComponent } from '../dialogs/cancel-virtual-attention/cancel-virtual-attention-dialog.component';
import { DialogData } from '../../models/dialog-data.model';
import { ToastrService } from 'ngx-toastr';
import { UserActiveEvent } from '../../models/signalr/user-active-event.model';
import { VirtualAttentionEvent } from '../../models/signalr/virtual-attention-event.model';
import { ChatConversacionMessageEvent } from '../../models/signalr/chat-conversation-message-event.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ChatSignalRService]
})
export class ChatComponent implements OnInit, OnDestroy {
  companyId: number;
  virtualConsultationCompanyId : number | undefined;
  currentMessage: string;  
  etapaSolicitudId: number;  
  loggedUser: UserPerson;  
  virtualAttention: VirtualAttention;  
  chatWrapper : ChatWrapper;  

  messagesPageSize = 30;
  chatInitialized = false;
  userActiveIntervalId = 0;
  agentActiveIntervalId = 0;
  lastAgentActiveDate = 0;
  isAgentActive = false;

  day: string;
  hour: string;
  min: string;
  and: string;

  //subscribes
  private ngUnsubscribe = new Subject();

  constructor(    
    @Inject(L10N_LOCALE) public locale: L10nLocale,     
    private translation: L10nTranslationService, 
    private signalRService: ChatSignalRService,    
    private route: ActivatedRoute, 
    private chatService: ChatService,
    private messageBusService: MessageBusService,
    private baseService: BaseService,
    private virtualAttentionService: VirtualAttentionService,
    private utilsService : UtilsService,
    public dialog: MatDialog,
    private userService: UserService,
    private toastr: ToastrService) { 
  }

  ngOnInit(): void {    
    this.companyId = this.baseService.getCompanyId();

    // For sura, this will be the account id of SURA-CAISS
    var dir = this.baseService.getDirectory();
    this.virtualConsultationCompanyId = dir ? dir.idEmpresaDestinoConsultaVirtual : 0;

    // Subscribe to Load Chat message
    this.messageBusService.onLoadChat()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(msg => {
      if (!msg || !msg.idEtapaSolicitud)
        return;
      
      if (this.etapaSolicitudId == msg.idEtapaSolicitud && this.chatInitialized) {
        let hasAgentMessages = this.chatWrapper.hasAgentMessages();
        this.messageBusService.chatLoaded(new ChatLoadedMessage(hasAgentMessages));
        
        return;
      }

      this.loadChat(msg.idEtapaSolicitud);
    });

    // Open chat event
    this.messageBusService.onOpenChat()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(msg => {      
      if (this.chatWrapper)
        this.chatWrapper.show();
    });

    // Chat cancelled event
    this.messageBusService.onChatCancelled()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(msg => {
      this.chatWrapper.minimize();      

      // Send message that indicate que chat was cancelled
      this.sendCancellationMessage(msg.reason);

      this.chatWrapper = null as any;
      this.virtualAttention = null as any;

      this.virtualAttentionService.removeActiveFromCache();

      this.clearUserActiveInterval();
      this.clearAgentActiveInterval();     
    });

    this.messageBusService.onLogout()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(msg => {
      this.virtualAttention = null as any;
      this.chatWrapper = null as any;

      this.clearUserActiveInterval();
      this.clearAgentActiveInterval();
    });

    // Subscribe to route to load widget by QS
    this.route.params
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(params => {
      let etapaSolicitudId = params['supportId'];    

      if (etapaSolicitudId) {
        this.loadChat(etapaSolicitudId);
      }      
    });    

    this.translation.onChange()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
      next: () => {
          this.day = this.translation.translate('days');                    
          this.hour = this.translation.translate('hours');        
          this.min = this.translation.translate('minutes');
          this.and = this.translation.translate('and');
      }
    });

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    // Clear user action interval before destroy
    this.clearUserActiveInterval();
    this.clearAgentActiveInterval();
  }

  loadChat(idEtapaSolicitud: number) {
    
    // Check if the chat was already initialized
    if (this.etapaSolicitudId == idEtapaSolicitud && this.chatWrapper && this.chatWrapper.initialized)
      return;  

    this.etapaSolicitudId = idEtapaSolicitud;
    this.chatInitialized = true;

    // Get current user
    this.userService.getUserPersonLoggedIn().subscribe(logged => {
      if (logged) {
        this.loggedUser = logged;

        // Load virtual attention
        this.virtualAttentionService.getActiveWithCache().subscribe(va => {
          this.virtualAttention = va;

          if (this.virtualAttention) {
            
            this.chatWrapper = new ChatWrapper(this.loggedUser, this.virtualAttention);
            this.chatWrapper.min = this.min;
            this.chatWrapper.hour = this.hour;
            this.chatWrapper.initialized = true;

            this.lastAgentActiveDate = this.virtualAttention.fechaUnixUltimaVezActivoResponsableServicio;
            this.updateAgentActive();

            // Load chat messages
            this.loadChatMessages(this.virtualAttention.idSolicitud, 0);
          }
        },
        error => {
          this.baseService.handleServiceError(error, "Error getting past appointments");
        });

        // Load chat
        /*this.chatService.getByEtapaSolicitudId(this.etapaSolicitudId).subscribe(chat => {
          this.chat = chat;      
        });*/

      }          
    });   

    // Init signalR
    this.initSignalR();

    // Init interval that will keep the user as active
    this.initUserActiveInterval();

    this.initAgentActiveInterval();
  }

  loadChatMessages(idSolicitud: number, from: number, upDirection = false) {
    
    this.chatService.getChatMessages(idSolicitud, from, this.messagesPageSize).subscribe(messages => {
      this.chatWrapper.addMessages(messages, true, upDirection);

      this.chatWrapper.loading = false;

      let hasAgentMessages = this.chatWrapper.hasAgentMessages();
      this.messageBusService.chatLoaded(new ChatLoadedMessage(hasAgentMessages));
    },
    error => {      
      this.baseService.handleServiceError(error, "Error getting chat messages");
    });
  }

  sendMessage(): void { 
    
    // If it is an empty msg
    if (!this.currentMessage) {
      return;
    }

    let chatMessage = this.buildChatMessage();

    if (!chatMessage)
      return;    

    // Add msg to chat
    this.chatWrapper.addMessages([chatMessage]);

    // Scroll bottom
    this.scrollBottom();

    this.currentMessage = "";
    
    this.chatService.sendMessage(chatMessage.idChat, chatMessage).subscribe(result => {
      console.log(result);
    },
    error => {
      this.baseService.handleServiceError(error, "Error posting chat messages");      
    });
  }

  sendCancellationMessage(cancelationReason: string) {
    let chatMessage = this.buildChatMessage();
    let model = new ChatCancellationMessage();

    model.mensaje = chatMessage;
    model.lenguaje = this.locale.language;
    model.razonCancelacion = cancelationReason;
    model.idEmpresaTemplate = this.virtualConsultationCompanyId ? this.virtualConsultationCompanyId : this.companyId;

    this.chatService.sendCancellationMessage(chatMessage.idChat, model).subscribe(result => {
      // No reason to add the cancellation msg here as the chat is already finished
    },
    error => {
      this.baseService.handleServiceError(error, "Error posting chat cancellation message");      
    });
  }

  buildChatMessage() : ChatConversacionMessage {
    let chatMessage = new ChatConversacionMessage();
    var user = this.loggedUser;

    if (!user)
      return null as any;

    chatMessage.idChat = this.etapaSolicitudId;
    chatMessage.idEtapaSolicitud = this.etapaSolicitudId;
    chatMessage.idPacienteEmpresaOrigen = user.id;
    chatMessage.idEmpresa = this.virtualConsultationCompanyId ? this.virtualConsultationCompanyId : this.companyId;
    chatMessage.tipo = ChatConversacionMessageType.PersonMessaje;
    chatMessage.idEstado = 1; // pendiente
    chatMessage.valor = this.currentMessage;
    chatMessage.idEmisor = user.id;
    chatMessage.nombreEmisor = user.nombre + " " + user.apellido;
    chatMessage.urlImagenEmisor = user.ubicacionLogo;
    chatMessage.fechaEnviado = new Date();
    chatMessage.fechaCreacion = new Date(); // This will be override on server but it is needed on client when adding the message
    
    return chatMessage;
  }

  onChatBtnClick() {
    this.chatWrapper.toggleMinimize();

    if (!this.chatWrapper.minimized) {
      // Each time the user opens the chat lets scroll to the bottom of the message container
      this.scrollBottom();
    }      
  }

  scrollBottom() {
    let _this = this;
    let el = window.document.getElementsByClassName("chat-msgs-container")[0];

    if (el)
      setTimeout(function() {
        _this.utilsService.scrollBottomWithAnimation(el); 
      }, 100);   
  }

  onMinimizeClick() {
    this.chatWrapper.toggleMinimize();
  }
  
  onScrollUp() {
    // Get message count
    let messageCount = this.chatWrapper.messages.length;
    
    // Load more messages
    this.loadChatMessages(this.virtualAttention.idSolicitud, messageCount, true);

    this.chatWrapper.loading = true;
  }

  onCancelVirtualAttention() {
    let dialogData = new DialogData();
    dialogData.data = this.virtualAttention;

    const dialogRef = this.dialog.open(CancelVirtualAttentionDialogComponent, {
      data: dialogData,
      autoFocus: false //disable auto focus in dialog
    });    
  }

  initSignalR() {
    this.signalRService.onConnectionLost().subscribe(m => {
      
      if (!this.chatWrapper)
        return;

      // Only show the toast if the previous state was connected
      if (!this.chatWrapper.connectionLost) {
        this.toastr.warning(
          this.translation.translate("connectionLostToast.message"), 
          this.translation.translate("connectionLostToast.title"), 
          {
            disableTimeOut: true,
            toastClass: "chat-connection-lost-toast",
            positionClass: "toast-top-left"        
          }
        );
      }
      
      this.chatWrapper.onConnectionLost();     
    });

    this.signalRService.onConnectionStablished().subscribe(m => {
      
      this.toastr.clear();

      if (!this.chatWrapper)
        return;

      this.chatWrapper.onConnectionStablished();

      // Join group that will receive chat events
      this.signalRService.joinGroup(this.etapaSolicitudId);

      // Join group that will receive virtual attention events (like state changes)
      this.signalRService.joinGroup(this.virtualConsultationCompanyId ? this.virtualConsultationCompanyId : this.companyId);
    });
    
    // Init signalR
    this.signalRService.init();
    
    // Subscribe to events
    this.subscribeToSignalREvents();
  }

  subscribeToSignalREvents(): void {  
  
    // NEW MESSAGE
    this.signalRService.onNewMessageReceived().subscribe((message: ChatConversacionMessageEvent) => {        
      // If the message is for this chat AND
      // Is not a user message (the last message the user send)
      if (message && message.IdEtapaSolicitud == this.etapaSolicitudId && (message.Tipo != ChatConversacionMessageType.PersonMessaje || message.IdEmisor != this.loggedUser.id)) {
        // Map entity to VirtualAttention (same properties but ignore upper and lower case)
        let msg = this.baseService.parseObjectDeep<ChatConversacionMessage>(message);
        
        this.chatWrapper.addMessages([msg]);   
        
        let hasAgentMessages = this.chatWrapper.hasAgentMessages();
        this.messageBusService.chatLoaded(new ChatLoadedMessage(hasAgentMessages));

        // Scroll bottom
        this.scrollBottom();
      }      
    }); 
    
    // VIRTUAL ATTENTION STATE CHANGE
    this.signalRService.onVirtualAttentionChanged().subscribe((virtualAttention: VirtualAttentionEvent) => {
      if (virtualAttention) {
        this.virtualAttention.idEstado = virtualAttention.IdEstado;
        this.virtualAttention.idResponsableServicio = virtualAttention.IdResponsableServicio;
        this.virtualAttention.nombreResponsableServicio = virtualAttention.NombreResponsableServicio;
        this.virtualAttention.fechaUltimaActualizacionEstado = virtualAttention.FechaUltimaActualizacionEstado;
        this.virtualAttention.fechaFin = virtualAttention.FechaFin;
      
        // Map entity to VirtualAttention (same properties but ignore upper and lower case)
        let vAttention = this.baseService.parseObjectDeep<VirtualAttention>(virtualAttention);        

        // Update wrapper virtual attention
        this.chatWrapper.virtualAttention = vAttention;

        if (virtualAttention.IdEstado == 37) {
          // disable input chat since attention finished
          this.chatWrapper.finishChat();
          this.messageBusService.virtualRequestFinished();
        }
      }
    });

    // User Active
    this.signalRService.onUserActive().subscribe((userActive: UserActiveEvent) => {
      if (!this.virtualAttention)
        return;
      
      if (userActive.UserId == this.virtualAttention.idResponsableServicio)
        this.lastAgentActiveDate = userActive.LastActiveUnixDate;
    });
  }

  initUserActiveInterval() {
    if (this.userActiveIntervalId > 0)
      return;

    // Run this every 30 seconds
    this.userActiveIntervalId = window.setInterval(() => {
      
      // If the chat finished we will just clear the interval
      if (!this.virtualAttention) {
        this.clearUserActiveInterval();
        return;
      }

      let compId = this.virtualConsultationCompanyId ? this.virtualConsultationCompanyId : this.companyId;

      console.log("Post user active!!!");

      this.userService.postUserActive(compId, this.virtualAttention.idEtapaSolicitud).subscribe(() => {
      },
      error => {
        this.baseService.handleServiceError(error, "Error posting user active");        
      });      
    }, 30 * 1000)
  }

  initAgentActiveInterval() {
    if (this.agentActiveIntervalId > 0)
      return;

    // Run this every 30 seconds
    this.agentActiveIntervalId = window.setInterval(() => {
      this.updateAgentActive();      
    }, 30 * 1000)
  }

  updateAgentActive() {
    let tolerance = 90 * 1000; // After 90 seconds without events we will considere the agent as inactive
    let now = new Date().getTime(); 
    let diff = now - this.lastAgentActiveDate;

    this.isAgentActive = diff <= tolerance;
  }

  clearUserActiveInterval() {
    if (this.userActiveIntervalId > 0)
      clearInterval(this.userActiveIntervalId);
  }

  clearAgentActiveInterval() {
    if (this.agentActiveIntervalId > 0)
      clearInterval(this.agentActiveIntervalId);
  }

  getChatDate(date: Date) {
    return this.baseService.getBasicDateFormat(date, false);
  }

  getSymptoms() {
    let symptoms = this.virtualAttention.data.sintomasPaciente.split(",");
    let last = symptoms.pop();    

    return symptoms.join(",") + " " + this.and + " " + last;
  }
}

export class ChatWrapper {
  virtualAttention: VirtualAttention;
  messages: ChatMessageWrapper[] = [];  
  user: UserPerson;  
  hour: string;
  min: string;
  minimized: boolean = true;
  unreadMessagesCount: number = 0;
  loading = false;
  initialized = false;
  connectionLost = false;
  isVirtualAttentionFinished = false;

  constructor(user: UserPerson, va: VirtualAttention) {
    this.user = user;
    this.virtualAttention = va;
    this.messages = [];
  }

  addMessages(messages: ChatConversacionMessage[], avoidUnreadCountUpdate: boolean = false, upDirection: boolean = false) {   
    
    if (!messages)
      return;

    // If we are scrolling up, the service will alway return first the oldest message
    // So in order to add it one by one (at the top) we need to revert the array
    if (upDirection)
      messages = messages.reverse();

    // Check if the msg was already added (when adding the msg the same one can came from signalR)
    for(let i = 0; i < messages.length; i++) {
      let msg = null;

      if (messages[i].id) {
        msg = this.messages.find(m => m.message.id == messages[i].id);
      }
      
      if (!msg) {

        // If the chat is minimized when adding the messages, then add this to the unread counter
        if (this.minimized && !avoidUnreadCountUpdate)
          this.unreadMessagesCount += 1;

          if (upDirection)
            this.messages.unshift(new ChatMessageWrapper(this.user, messages[i]));
          else
            this.messages.push(new ChatMessageWrapper(this.user, messages[i]));
      }      
    }
  }

  getDoctorName() {
    return this.virtualAttention.nombreResponsableServicio? 
      this.virtualAttention.nombreResponsableServicio.toLowerCase():
      "";
  }

  getChatTime() {
    let startTime = new Date(this.virtualAttention.fechaInicio).getTime();
    let now = new Date().getTime();
    let timeDiffInMin = (now - startTime) / (1000 * 60);

    if (timeDiffInMin < 60)
      return Math.floor(timeDiffInMin) + " " + this.min;
    else
      return Math.floor(timeDiffInMin / 60) + " " + this.hour;
  }

  isUserBlockMessage(currentMessageIndex: number) {
    if (currentMessageIndex == 0)
      return false;

    // Get current and previous msg
    let previous = this.messages[currentMessageIndex - 1];
    let current = this.messages[currentMessageIndex];

    // If we need to show the date stamp for this message then it is a new group
    if (this.showDateStamp(currentMessageIndex))
      return false;

    // We will only show user msg together if the previous msg was an user message and current is as well
    if (previous.isUserMessage() && current.isUserMessage())
      return true;

    return false;
  }

  isAgentBlockMessage(currentMessageIndex: number) {
    if (currentMessageIndex == 0)
      return false;

    // Get current and previous msg
    let previous = this.messages[currentMessageIndex - 1];
    let current = this.messages[currentMessageIndex];

    // If we need to show the date stamp for this message then it is a new group
    if (this.showDateStamp(currentMessageIndex))
      return false;

    // We will only show agent msg together if the previous msg was an agent message and current is as well
    if (previous.isAgentMessage() && current.isAgentMessage())
      return true;

    return false;
  }

  showDateStamp(currentMessageIndex: number) {
    if (currentMessageIndex == 0)
      return true;

     // Get current and previous msg
    let previous = new Date(this.messages[currentMessageIndex - 1].message.fechaCreacion);
    let current = new Date(this.messages[currentMessageIndex].message.fechaCreacion);
    
    // If the days are differents, then show the date time stampt
    if (previous.getDate() != current.getDate())
      return true;

    return false;
  }

  toggleMinimize() {
    this.minimized = !this.minimized;

    // If the user open the chat let mark all msg as readed
    if (!this.minimized)
      this.unreadMessagesCount = 0;
  }

  minimize() {
    this.minimized = true;
  }

  showMessageTime(currentMessageIndex: number) {
    // Only show the time for the last message
    return currentMessageIndex === (this.messages.length - 1);
  }

  isWaitingForAttention() {
    // It will be waiting for attention ONLY if it does not have any message sent from the agent.
    let isWaiting = (!this.messages.find(m => m.isAgentMessage()));

    return isWaiting;
  }

  isWaitingForTriage() {
    return this.virtualAttention.idEstado == 100;
  }

  requestIsFinished() : boolean {
    return this.virtualAttention.idEstado == 37;
  }

  getFirstMessage() : ChatConversacionMessage {
    return this.messages[0].message;
  }

  show() {
    if (this.initialized)
      this.minimized = false;
  }

  onConnectionStablished() {
    this.connectionLost = false;
  }

  onConnectionLost() {
    this.connectionLost = true;
  }

  hasAgentMessages() {
    return this.messages.find(m => m.isAgentMessage()) != null;
  }

  finishChat() {
    this.isVirtualAttentionFinished = true;
  }
}

export class ChatMessageWrapper {
  message: ChatConversacionMessage;
  user: UserPerson;  

  constructor(user: UserPerson, message: ChatConversacionMessage) {
    this.user = user;
    this.message = message;
  }

  isUserMessage() {
    return this.message.idEmisor == this.user.id && this.message.tipo == ChatConversacionMessageType.PersonMessaje
  }

  isAgentMessage() {
    return this.message.idEmisor != this.user.id && this.message.tipo == ChatConversacionMessageType.PersonMessaje;
  }  
}