import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ComponentLookup } from '../../decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { DocumentService } from 'src/app/core/services/document.service';
import { SystemDocument } from '../../models/process/document.model';

@Component({
  selector: 'file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('FileViewerComponent')
export class FileViewerComponent implements IControlComponent, OnInit {
    data: FormComponentData;

    // Input / Output
    @Input() viewerDto : FileViewerDto;
    @Output() onClose = new EventEmitter();

    // Model
    selectedFile: FileViewerItemDto;
    selectedFileIndex: number = 0;
    selectedFileUrl: string;
    loadingFileUrl = false;
    selectedImgZooms = 0;

    files : FileViewerItemDto[];        
    googleFileViewerBaseUrl = "";
    dragPosition = {x: 0, y: 0};
    
    @ViewChild('selectedImg') selectedImageEl: ElementRef<HTMLImageElement>;

    constructor(
      private documentService : DocumentService,      
      @Inject(L10N_LOCALE) public locale: L10nLocale) {
    }

    ngOnInit(): void {
      this.googleFileViewerBaseUrl = environment.googleFileViewerUrl;

      if (this.viewerDto.useExternalDocumentProvider && !this.viewerDto.documentProvider) {
        throw "Invalid file viewer configuration";
      }

      this.files = this.viewerDto.files;
      for(let i=0; i < this.viewerDto.files.length; i++) {
        if (this.viewerDto.files[i].fileId == this.viewerDto.selectedFileId) {
          this.selectedFile = this.viewerDto.files[i];
          this.selectedFileIndex = i;

          break;
        }
      }

      this.onSelectedFileChange(this.selectedFileIndex);
    }

    onCloseClick() {
      this.onClose.emit(); 
    }

    openOnNewTab() {
      this.getFileUrl(this.selectedFile, false, false).subscribe(url => {
        window.open(url, '_blank')?.focus();
      });      
    }

    isNextVisible() {
      return this.selectedFileIndex < (this.files.length - 1);
    }

    next() {      
      this.selectedFileIndex++;
      this.onSelectedFileChange(this.selectedFileIndex);
    }

    isPreviousVisible() {
      return this.selectedFileIndex > 0 && this.files.length > 1;
    }

    previous() {      
      this.selectedFileIndex--;
      this.onSelectedFileChange(this.selectedFileIndex);
    }

    clearViewCustomizations() {
      this.selectedImgZooms = 0;      

      // Re-center img
      if (this.selectedImageEl)
        this.selectedImageEl.nativeElement.style.transform = "none";

      this.dragPosition = {x: 0, y: 0};
    }

    showZoomIn() {
      return this.selectedImgZooms == 0;
    }

    showZoomOut() {
      return this.selectedImgZooms > 0;
    }

    zoomIn() {
      this.selectedImgZooms++;
      this.resizeSelectedImg(true);
    }

    zoomOut() {
      this.selectedImgZooms--;
      this.resizeSelectedImg(false);

      if (this.selectedImgZooms == 0)
        this.clearViewCustomizations();
    }

    onFileClick(file: FileViewerItemDto) {
      if (this.selectedImgZooms == 0)
        this.zoomIn();      
    }

    resizeSelectedImg(zoomIn : boolean) {
      let scale = (zoomIn) ? 150 / 100 : 100 / 150;
      let width = this.selectedImageEl.nativeElement.width;
      let height = this.selectedImageEl.nativeElement.height;

      // Set new dimensions
      this.selectedImageEl.nativeElement.width = width * scale;
      this.selectedImageEl.nativeElement.height = height * scale;
    }

    resetZoom() {
      this.clearViewCustomizations();
    }

    selectedFileHasPreview() {
      let file = this.selectedFile;      

      if (!file)
        return false;

      return file.fileExtension == "jpg"
        || file.fileExtension == "jpeg"
        || file.fileExtension == "bmp"
        || file.fileExtension == "gif"
        || file.fileExtension == "png";        
    }

    useGoogleViewer(file: FileViewerItemDto) {           

      if (!file)
        return false;

      return file.fileExtension == "pdf";
    }

    onSelectedFileChange(selectedFileIndex: number) {
      this.clearViewCustomizations();      
      this.selectedFile = this.files[selectedFileIndex];
      this.selectedFileUrl = null as any;
      
      this.getFileUrl(this.selectedFile).subscribe(url => {
        this.selectedFileUrl = url;
      });
    }

    getFileUrl(file: FileViewerItemDto, useExtensionImage = true, useLoading = true) : Observable<string> {
      let url = file.fileUrl;
      let imageUrl = ''; 
      let hasExtensionImage= true;     

      // Check if it is a partial URL
      if (url.indexOf("http") < 0) 
        url = document.location.protocol + "//" + document.location.host + "/" + url; 
      
      if (useExtensionImage) {
        switch (file.fileExtension) {
          case 'jpg':
          case 'jpeg':
          case 'bmp':
          case 'gif':
          case 'png':
          case 'pdf':
              imageUrl = url.replace('\\/gi', '/');
              hasExtensionImage = false;
              break;
          case 'xls':
          case 'xlsx':
          case 'xlt':
              imageUrl = '/assets/images/HC/img_preview_xls.png';
              break;
          case 'doc':
          case 'docx':
              imageUrl = '/assets/images/HC/img_preview_doc.png';
              break;
          case 'mp3':
              imageUrl = '/assets/images/HC/img_preview_mp3.png';
              break;
          case 'mp4':
              imageUrl = '/assets/images/HC/img_preview_mp4.png';
              break;
          default:
              imageUrl = '/assets/images/HC/img_preview_default.png';
              break;
        }
      }
      else {
        imageUrl = url.replace('\\/gi', '/');
        hasExtensionImage = false;
      }      

      if (!hasExtensionImage) {
        
        // Check if the file has a bucket
        if (file.amazonBucketName) {

          if(useLoading)
            this.loadingFileUrl = true;

          /*return this.documentService.getDocumentUrl(file.fileId).pipe(map((url: string) => {            
            this.loadingFileUrl = false;
  
            return url;
          }));*/

          return this.getFileDocumentUrl(file).pipe(map((url: string) => {            
            this.loadingFileUrl = false;
  
            return url;
          }));
        }
        else 
          return of(imageUrl);
      }
      // If we do not render this image just use the extension one
      else 
        return of(imageUrl);
    }    

    getSelectedFileViewerUrl() : string {
      let result = this.googleFileViewerBaseUrl + encodeURIComponent(this.selectedFileUrl);

      return result;
    }

    getFileDocumentUrl(file: FileViewerItemDto) : Observable<string> {
      if (this.viewerDto.useExternalDocumentProvider) {
        return this.viewerDto.documentProvider.getDocument(file.fileId).pipe(map((doc: SystemDocument) => {
          return doc.url;
        }));
      }
      else {
        return this.documentService.getDocumentUrl(file.fileId);
      }
    }
}

/* External classes */
export class FileViewerDto {
  selectedFileId: number;
  files : FileViewerItemDto[];

  // In case of public profile images, we will use the public profile service to get images in order to avoid authentication
  useExternalDocumentProvider: boolean = false; 
  documentProvider: IDocumentProvider;
}

export class FileViewerItemDto {
  fileId: number;
  fileUrl: string;
  fileExtension: string;
  amazonBucketName: string;
  webUrl: string;

  title: string;  
  description: string;
  createdAt: Date;  

  creatorName: string;
  creatorImageUrl: string;  
}

export interface IDocumentProvider {
  getDocument(documentId: number) : Observable<SystemDocument>;
}
