
<div class="header-container" fxFlex fxLayout="column" [ngClass.lt-md]="{'mobile': true,'small':showHeaderSmall}" *ngIf="!hideHeader">
    <mat-toolbar class="header-toolbar">
        <a class="logo" (click)="goToHome()"><img src="/assets/images/sura/logo-sura.svg"/></a>
        <span fxFlex="auto"></span>
        <button class="btn-menu-mobile" color="primary" mat-icon-button (click)="openMenuMobile()" *ngIf="userLoggedIn">
            <mat-icon>menu</mat-icon>
        </button>        
        <app-policy-menu class="btn-menu-desktop"></app-policy-menu>
    </mat-toolbar>
    <div class="header-banner primary-background-color" fxFlex fxLayoutAlign=" center" [ngClass.lt-md]="{ 'hide': showHeaderSmall }">
        <span>{{ 'headerSura.text1' | translate:locale.language | uppercase }}</span>
    </div>
</div>