import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppStorageService } from '../app-storage.service';
import { BaseService } from '../base.service';

/*
It is important for this Service to NOT be a Singleton (avoid adding "providedIn: 'root'")
in order to work properly. If not all the event related to connection and re-connection get mixed between all the clients that use the service
*/
@Injectable() 
export abstract class SignalrService {  
  protected url: any = environment.cloudHubUrl;
  protected hubName : string;
  protected connection: any;
  protected proxy: any;  
  protected connectionLostRetryTimeInSeconds : number = 2;
  protected waitingConnectionLostRetry = false;  
  protected connectionEstablishedSubject = new Subject<void>();
  protected connectionLostSubject = new Subject<void>();  

  constructor(
    protected baseService: BaseService,
    protected appStorageService: AppStorageService) {  
  }  

  public init() {
    
    // Create proxy
    this.createConnection();
    
    // Call proper registration methods (this will be implemented in each particular service)
    this.registerOnServerEvents();

    // Stablish connection
    this.connect();
  }

  public createConnection(): void {    
    this.connection = $.hubConnection(this.url);        
    this.proxy = this.connection.createHubProxy(this.hubName); 

    this.connection.disconnected(() => {
      this.handleConnectionLost();
    });  
  }

  protected getAuthorizationToken() {
    let token = this.appStorageService.getToken();

    return token.access_token;
  }

  public connect(): void {    
    let token = this.getAuthorizationToken();

    // Set authorization token
    this.connection.qs = { 'authorization': token };

    // Connection expired after 7 min, so lets send a ping every 5 min to keep connection active
    let pingTime = 5 * 60 * 1000;

    this.connection.start({ 
      pingInterval: 5 * 60 * 1000      
    }).done((data: any) => {
        console.log('Connected to Processing Hub');
        this.connectionLostRetryTimeInSeconds = 2;     
        this.connectionEstablishedSubject.next();        
    })
    .catch((error: any) => {
        console.log('Hub error -> ' + error);
    });
  }

  protected handleConnectionLost() {
    if (this.waitingConnectionLostRetry)
      return;

    // Skip the first re-connection intent
    if (this.connectionLostRetryTimeInSeconds > 2)
      this.connectionLostSubject.next();

    this.waitingConnectionLostRetry = true;

    console.log("SignalR Connection Lost. Waiting to reconnect: " + this.connectionLostRetryTimeInSeconds + " seconds");

    setTimeout(() => {
      this.connectionLostRetryTimeInSeconds = this.connectionLostRetryTimeInSeconds * 2;
      this.waitingConnectionLostRetry = false;

      // Re-connect
      this.connect();
    }, this.connectionLostRetryTimeInSeconds * 1000);
  }

  public joinGroup(groupId: number): void {
    this.proxy.invoke('JoinGroup', groupId)
    .catch((error: any) => {
        console.log('JoinGroup error -> ' + error); 
    });
  }

  abstract registerOnServerEvents(): void;

  onConnectionStablished() : Observable<void> {
    return this.connectionEstablishedSubject.asObservable();
  }

  onConnectionLost() : Observable<void> {
    return this.connectionLostSubject.asObservable();
  }
}

