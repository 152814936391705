import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { BaseService } from 'src/app/core/services/base.service';
import { AppointmentCancelledMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { PatientAppointment } from 'src/app/shared/models/process/appointment.model';
import { CancelOption } from '../cancel-virtual-attention/cancel-virtual-attention-dialog.component';


@Component({
  selector: 'app-cancel-appointment-dialog',
  templateUrl: './cancel-appointment-dialog.component.html',
  styleUrls: ['./cancel-appointment-dialog.component.css']  
})
export class CancelAppointmentDialogComponent implements OnInit {   
    solicitudPaciente: PatientAppointment;    
    loading = false;
    completed = false;
    selectionControl: FormControl; 
    reasonText: string;
    options: CancelOption[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private mdDialogRef: MatDialogRef<CancelAppointmentDialogComponent>,    
        private baseService: BaseService,    
        private appointmentService: AppointmentService, 
        private messageBusService: MessageBusService,
        @Inject(L10N_LOCALE) public locale: L10nLocale
    ) {    
        this.selectionControl = new FormControl('auto');
        this.solicitudPaciente = this.baseService.parseObject<PatientAppointment>(data.data);

        console.log(this.solicitudPaciente);      

        this.options = [
            new CancelOption(1, "No puedo asistir en el horario pactado"),
            new CancelOption(2, "Deseo tener atención virtual"),
            new CancelOption(3, "Lo resolví de otra forma"),
            new CancelOption(4, "Ya me siento mucho mejor, no necesito la atención"),
            new CancelOption(5, "Otro motivo", true)            
        ];
    }

    ngOnInit(): void {
    }

    public cancel() {
        this.close(false);
    }

    public close(value:any) {
        this.mdDialogRef.close(value);
    } 

    isSaveEnabled() {
        let reason = this.getSelectedReason();

        return !this.loading && (reason && reason.trim());
    }

    showTextarea() : boolean {
        let option = this.options.find(o => o.id == this.selectionControl.value);

        return option != undefined && option.showTextarea;
    }

    getSelectedReason() : string {
        let reasonId = this.selectionControl.value;
        let option = this.options.find(o => o.id == reasonId);

        if (!option)
            return "";

        return reasonId == 5? this.reasonText : option.text;
    }

    confirm() {
        this.loading = true;
        let reason = this.getSelectedReason();

        this.appointmentService.cancelAppointment(this.solicitudPaciente.idSolicitud, this.solicitudPaciente.idEtapaSolicitud, reason).subscribe(r => {
            // Send message so each control can handle the appointment state change
            let msg = new AppointmentCancelledMessage();
            msg.idEtapaSolicitud = this.solicitudPaciente.idEtapaSolicitud;
            this.messageBusService.appointmentCancelled(msg);
            
            this.loading = false;    
            this.completed = true;            
        });
    }
  
}
