import { Component, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { L10nLocale, L10N_LOCALE, L10nTranslationService } from 'angular-l10n';
import { ComponentDataSetMessage, ComponentFinishLoadingMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { ComponentLookup } from '../../decorators/component-lookup.decorator';
import { IControlComponent } from '../../interfaces/control-component';
import { FormComponentData } from '../../models/people/form-control.model';
import { PersonDataModel, SelectPatientDataModel } from '../../models/workflow/models/select-patient-data.model';
import { ComponentName } from '../../enums/component-name.enum';
import { Dependent } from '../../models/people/dependent.model';
import { UserService } from 'src/app/core/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UtilsService } from 'src/app/core/services/utils.service';
import { SenderPatientRelationship } from '../../models/process/base-request.model';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentInfoDialogComponent } from '../dialogs/appointment-info-dialog/appointment-info-dialog.component';
import { BaseService } from 'src/app/core/services/base.service';

@Component({
  selector: 'app-select-patient-appointment-checkout',
  templateUrl: './select-patient-appointment-checkout.component.html',
  styleUrls: ['./select-patient-appointment-checkout.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('SelectPatientAppointmentCheckoutComponent')
export class SelectPatientAppointmentCheckoutComponent implements IControlComponent, OnInit, OnDestroy {
  data: FormComponentData;
  language: string;

  model: SelectPatientDataModel = new SelectPatientDataModel();
  showAppointmentInfo: boolean;
  patientRelationshipTypeForm:FormGroup;
  showForm:boolean = false;
  showInitialView:boolean = false;
  loading: boolean = false;  
  dependents: Dependent[] = [];
  private ngUnsubscribe = new Subject();
  formSubmitted: boolean = false;

  constructor(
    private baseService: BaseService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private messageBusService: MessageBusService,
    private userService: UserService,
    private utilsService: UtilsService,
    private translation: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {
    this.language = locale.dateLanguage ?? 'es-AR';
  }
  
  ngOnInit(): void {
    this.loadDataModel();

    this.userService.getUserDependents()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((dependents:Dependent[]) =>{
        this.dependents = dependents;

         // Order by age desc
        this.dependents = this.dependents.sort((d1: Dependent, d2: Dependent) => {
          let getTime = (date: Date) => {return date != null ? new Date(date).getTime() : 0;};
          
          return getTime(d1.fechaNacimiento) - getTime(d2.fechaNacimiento);
        });

        // let exampleDependent = new Dependent();
        // exampleDependent.id = 5871197;
        // exampleDependent.nombre = 'test';
        // exampleDependent.apellido = 'local';
        // exampleDependent.sexo = 'M';
        // exampleDependent.fechaNacimiento = new Date();
        // exampleDependent.tipoRelacion = 295;

        // this.dependents = [exampleDependent];

        this.showInitialView = this.dependents.length == 0;

        this.loadForm();

        this.sendComponentFinishLoadingMessage();      
      },
      error=>{
        this.baseService.handleServiceError(error, "Error getting user dependents");
      }
    );

    this.showAppointmentInfo = window.innerWidth > 799;
  }

  ngOnDestroy():void{
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  loadForm(){
    let _relationshipStringValue;

    if(this.showInitialView){
      _relationshipStringValue = this.model.relationship > 0 
        ? this.model.relationship.toString() 
        : '';
    }
    else{
      _relationshipStringValue = this.model.patientSelected
        ? this.model.patientSelected.id > 0
          ? this.model.patientSelected.id.toString()
          : '2'
        : this.model.user.id.toString();

      this.model.relationship = +_relationshipStringValue;
    }

    this.patientRelationshipTypeForm = this.formBuilder.group(
      {
        relationship: [_relationshipStringValue, [Validators.required]]
      }
    );

    this.showForm = true;
  }

  sendComponentFinishLoadingMessage(){
    let event = new ComponentFinishLoadingMessage();
      event.idFormulario = this.data.idFormulario;
      event.idControl = this.data.idControlPadre;

    this.messageBusService.componentFinishLoading(event);   
  }

  getControl(controlName: string) {
    return this.patientRelationshipTypeForm.controls[controlName];
  }

  isControlInvalid(controlName: string) {
    let control = this.getControl(controlName);

    // First check if the form is submitted or control is dirty
    if (!this.formSubmitted && !control.touched)
      return false;

    return control.invalid;
  }

  onClickContinue(){
    this.formSubmitted = true;
    
    if (this.patientRelationshipTypeForm.invalid) {
      return;
    }

    this.loading = true;

    let _valueSelected = +(this.patientRelationshipTypeForm.controls.relationship.value);

    if(this.showInitialView){
      this.model.patientSelected = new PersonDataModel();
      this.model.relationship = _valueSelected;

      this.userService.getUserPersonLoggedInFromService()
      .subscribe(u => {
        // updates necessary fields.
        if (u?.nombre)
          this.model.user.firstname = u.nombre;

        if (u?.apellido)
          this.model.user.lastname = u.apellido;

        if (u?.fechaNacimiento)
          this.model.user.birthDate = u.fechaNacimiento;

        if (u?.sexo)
          this.model.user.gender = u.sexo;

        this.continueFlow();
      },
      error=>{
        this.baseService.handleServiceError(error, "Error getting user logged in");
      });
    }
    else {
      let _dependentSelected = this.dependents.find(d=> d.idPersona == _valueSelected);

      // a dependent has been selected
      if(_dependentSelected){         
        this.model.patientSelected = new PersonDataModel();

        this.model.patientSelected.id = _dependentSelected.idPersona;
        this.model.patientSelected.firstname = _dependentSelected.nombre;
        this.model.patientSelected.lastname = _dependentSelected.apellido;
        this.model.patientSelected.avatar = _dependentSelected.ubicacionLogo;
        this.model.patientSelected.birthDate = _dependentSelected.fechaNacimiento;
        this.model.patientSelected.gender = _dependentSelected.sexo;
        this.model.patientSelected.email = _dependentSelected.emailParticular;
        this.model.patientSelected.cellPhone = _dependentSelected.numeroTelCelular;

        this.model.relationship = _dependentSelected.tipoRelacion;
        this.continueFlow();

      } else if (this.model.user.id == _valueSelected) {
        // the user has been selected
        this.userService.getUserPersonLoggedInFromService()
        .subscribe(u => {
          // updates necessary fields.
          if (u?.nombre)
            this.model.user.firstname = u.nombre;

          if (u?.apellido)
            this.model.user.lastname = u.apellido;

          if (u?.fechaNacimiento)
            this.model.user.birthDate = u.fechaNacimiento;

          if (u?.sexo)
            this.model.user.gender = u.sexo;
 
          this.model.patientSelected = this.model.user;
          this.model.relationship = SenderPatientRelationship.USUARIO;
          this.continueFlow();
        },
        error=>{
          this.baseService.handleServiceError(error, "Error getting user logged in");
        });        
      }
      // someone else has been selected
      else {
        this.model.patientSelected = new PersonDataModel();

        this.model.relationship = _valueSelected;
        this.continueFlow();
      }
    }

 
  }

  continueFlow() {
    let message = new ComponentDataSetMessage();
    message.componentName = ComponentName.SELECT_PATIENT;

    message.data = this.model;

    this.messageBusService.onComponentDataSetMessage(message); 
  }

  buttonDisabled():boolean{
    return this.patientRelationshipTypeForm.invalid;
  }

  getUserAvatar(user: PersonDataModel){    
    return user.avatar
      ? user.avatar
      : this.utilsService.getPersonDefaultAvatarUrl(user.birthDate, user.gender);
  }

  getDependentAvatar(dependent: Dependent){
    return dependent.ubicacionLogo
      ? dependent.ubicacionLogo
      : this.utilsService.getPersonDefaultAvatarUrl(dependent.fechaNacimiento, dependent.sexo);
  }

  getRelationshipText(dependent: Dependent){
    return this.translation.translate(`shared.selectPatientAppointmentCheckout.relationship_${dependent.tipoRelacion}_${dependent.sexo}`);
  }

  openAppointmentInfoDialog(){
    this.dialog.open(AppointmentInfoDialogComponent, {          
      panelClass: ['appointment-info-dialog', 'appointment-info-animation-in'],
      data: this.model.appointmentInfo
    });
  }

  private loadDataModel() {
    if (this.data && this.data.configurationData) {
      this.model = this.data.configurationData as SelectPatientDataModel;        
    }     
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.showAppointmentInfo = event.target.innerWidth > 799;
  }
}
