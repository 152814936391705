import { SignupWorkflowVerificationType } from "src/app/shared/enums/signup-workflow-verification.enum";
import { PublicProfile } from "../../people/public-profile.model";

export class PatientVerificationDataModel{
    configData: PatientVerificationComponentData;
    idResponsableServicio: number;
    idVerificacion: string;
    idPaciente: number;
    nombrePaciente: string; 
    profile: PublicProfile;
}

export class PatientVerificationComponentData{    
    showCliniwebSection: boolean;
    signupWorkflowVerificationType: SignupWorkflowVerificationType;
    workflowType: number;
}