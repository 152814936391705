import { PublicProfile } from "../../people/public-profile.model";

export class OnlinePaymentModel {
    idResponsableServicio: number;
    urlAvatarResponsableServicio: string;
    nombreResponsableServicio: string;
    especialidadResponsableServicio: string;
    nickname: string;  
    idVerificacion: string;
    idEmpresa: number;

    emailResponsableServicio: string;
    nombreEmpresa: string;
    idEmpresaResponsableServicio: number;

    perfilPublico: PublicProfile;
}