export class ChatConversacion {
    id: number;
    fecha: Date;
    idPublico: string;
    tipo: number;
    idEstado: number;
    idEmpresa: number;
    idContactoClinico: number;
    idSolicitud: number;
    idEtapaSolicitud: number;
    idSujeto: number;
    idDestinatario: number;
    idPersonasParticipantes: string;
    idPersonasInvolucradas: string;
    fechaActualizacion: Date;
    idResponsableActualizacion: string;
    eliminado: string;
    fechaEliminacion: Date;
    idResponsableEliminacion: number;
    mensajes: ChatConversacionMessage[];
}

export class ChatConversacionMessage {
    id: number;
    idChat: number;
    fechaCreacion: Date;
    tipo: ChatConversacionMessageType;
    idEstado: number;
    valor: string;
    idSolicitud: number;
    idEtapaSolicitud: number;
    idEmpresa: number;
    idEmisor: number;
    nombreEmisor: string;
    urlImagenEmisor: string;
    idReceptor: number;
    idPersonasInvolucradas: string;
    fechaEnviado: Date;
    fechaRecibido: Date;
    fechaLeido: Date;
    eliminado: string;
    fechaEliminacion: Date;
    idResponsableEliminacion: number;
    idPacienteEmpresaOrigen: number;
    IdPacienteEmpresaDestino: number | undefined;    
}

export enum ChatConversacionMessageType {
    PersonMessaje = 1,
    StandardSystemaMessage = 2
}

export class ChatCancellationMessage {    
    mensaje : ChatConversacionMessage;
    lenguaje: string;
    razonCancelacion: string;
    idEmpresaTemplate: number;
}