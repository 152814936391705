import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { AuthService } from "src/app/core/services/auth.service";
import { UserService } from "src/app/core/services/user.service";
import { UserPerson } from "../../people/user-person.model";
import { WorkflowItem, WorkflowItemData } from "../../systems/workflow.model";
import { BaseStep } from "./base-step.model";
import { ComponentDataSetMessage, MessageBusService, ServiceErrorMessage, WorkflowStateUpdateMessage } from "src/app/core/services/message-bus.service";
import { ComponentName } from "src/app/shared/enums/component-name.enum";
import { AuthenticationDataModel } from "../models/authentication-data.model";
import { IWorkflowDataState } from "src/app/shared/interfaces/workflow-data-state";
import { WorkflowService } from "src/app/core/services/workflow.service";
import { BaseService } from "src/app/core/services/base.service";
import { PublicProfile } from "../../people/public-profile.model";

@Injectable({
    providedIn: 'root',
  })
export class AuthenticationStep extends BaseStep<IWorkflowDataState & IAuthenticationWorkflowState> {
    constructor(
        private authService: AuthService,
        private userService: UserService,
        protected messageBusService: MessageBusService,        
        private workflowService: WorkflowService,
        private baseService: BaseService
    ) {
        super(messageBusService);

        this.showHeader = true;
        this.showProgressBar = true;
        this.showButtonClose = true;

        this.withoutFooter = true;
    }

    setItem(item: WorkflowItem): void {
        this.item = item;
    }

    setState(stateData: any): void {
        this.stateData = this.workflowService.getWorkflowDataState();
    }

    isCompleted():Observable<boolean>{
        return this.authService.isLoggedInFull().pipe(
            switchMap((isLoggedIn)=>{

                return (!isLoggedIn)
                    ? of(isLoggedIn)
                    : this.completeUserDateState().pipe(
                        map((result)=>{

                        // If the user is logged in when the workflow started the auth step will be completed and 
                        // the auth component will not be loadded. Bacause of this we need to send a message to the 
                        // workflow with the state date updated
                        let msg = new WorkflowStateUpdateMessage();
                        msg.state = this.stateData;

                        this.messageBusService.onWorkflowStateUpdateMessage(msg);

                        return result;
                    }));
        }));
    }

    showStepInActionBack(): boolean {
        return true;
    }

    showStep(): boolean {
        return true;
    }

    protected handleComponentSetDataMessage(message: ComponentDataSetMessage) {
        if (message.componentName == ComponentName.AUTHENTICATION){

            let user = message.data as UserPerson;

            this.stateData.setUserData(user);
            this.stateData.setEmmiterPersonId(user.id);
        }
    }

    protected buildComponentConfigurationData() : { [componentName: string]: any; } {
        let formConfigurationData : { [componentName: string]: any; } = {};
        
        formConfigurationData[ComponentName.AUTHENTICATION] = this.getAuthenticationDataModel();

        return formConfigurationData;
    }

    protected messageForThisStep(message: ComponentDataSetMessage): boolean {
        return message.componentName == ComponentName.AUTHENTICATION;
    }

    private getAuthenticationDataModel(): AuthenticationDataModel{
        let dataModel = new AuthenticationDataModel();

        dataModel.idVerificacion = this.stateData.getIdVerificacion();
        dataModel.idTipoSolicitud = this.stateData.getIdTipoSolicitud();
        dataModel.idUsuario = this.stateData.getUserId();
        dataModel.doctorAvatarUrl = this.stateData.getDoctorAvatarUrl();
        dataModel.profile = this.stateData.getPublicProfile();

        if (this.item.valor) {            
            let stepItemData = this.baseService.parseObjectDeep<WorkflowItemData>(JSON.parse(this.item.valor));

            if (stepItemData.data)
                dataModel.configData = stepItemData.data;
        }

        return dataModel;
    }

    private completeUserDateState():Observable<boolean> {        
        return this.userService.getUserPersonLoggedIn()
            .pipe(
                map((userPerson: UserPerson | null)=>{
                if(userPerson){
                    this.stateData.setUserData(userPerson);
                    this.stateData.setEmmiterPersonId(userPerson.id);

                    return true;
                }
                else{
                    return false;
                }
            }),
            catchError(error=>{
                this.messageBusService.serviceError(new ServiceErrorMessage("Error getting user logged in"));
                return of(false);
            }));
    }
}

export interface IAuthenticationWorkflowState{
    getIdVerificacion(): string;
    getDoctorAvatarUrl():string;
    getUserId():number;
    getPublicProfile():PublicProfile;
    getIdTipoSolicitud(): number;

    setUserData(userPerson: UserPerson): void;
    setEmmiterPersonId(personId: number):void;
    setPublicProfile(profile: PublicProfile):void;
}
