import { FormControl } from "./form-control.model";

export class Form {
    id: number;
    nombre: string;
    descripcion: string;
    valor: string;
    configuracion: FormConfiguration;
    controles: FormControl[];

    constructor();
    constructor(obj:Form);
    constructor(obj?: any){
        this.id = obj && obj.id || 0;
        this.nombre = obj && obj.nombre || '';
        this.descripcion = obj && obj.descripcion || '';
        this.valor = obj && obj.valor || '';
        this.controles = obj && obj.controles || null;
    }
}

/*
This is used by some components to customize how the form is shown
For example: symptoms components will hide form title, subtitile and controlTitle if the user already have a virtual attention in progress
*/
export class FormConfiguration {
    formId: number;
    showFormTitle : boolean = true;
    showFormSubtitle : boolean = true;
    showControlTitle : boolean = true;
    waitForComponents : boolean = false;

    constructor();
    constructor(obj:FormConfiguration);
    constructor(obj?: any){
        if(obj){
            this.formId = obj.formId;
            this.showFormTitle = obj.showFormTitle;
            this.showFormSubtitle = obj.showFormSubtitle;
            this.showControlTitle = obj.showControlTitle;
            this.waitForComponents = obj.waitForComponents;
        }
        else{
            this.formId = 0;
            this.showFormTitle = false;
            this.showFormSubtitle = false;
            this.showControlTitle = false;
            this.waitForComponents = false;
        }
    }
}
