import { IWorkflowDataState } from "../../../interfaces/workflow-data-state";
import { IAuthenticationWorkflowState } from "../steps/authentication-step.model";
import { OnlinePaymentRequest } from "../../process/online-payment-request.model";
import { PayModel, RequestingUser } from "../../process/base-request.model";
import { BaseWorkflowState } from "./base-workflow-state.model";
import { IPaymentAmountWorkflowState } from "../steps/payment/payment-amount-step.model";
import { IPaymentWorkflowState } from "../steps/payment/payment-form-step.model";
import { UserPerson } from "../../people/user-person.model";
import { PublicProfile } from "../../people/public-profile.model";
import { ISelectCountryWorkflowState } from "../steps/select-country-step.model";
import { TerritorialDivision } from "../../systems/territorial-division.model";

export class OnlinePaymentWorkflowState 
    extends BaseWorkflowState 
    implements 
        IWorkflowDataState,
        IAuthenticationWorkflowState,
        IPaymentAmountWorkflowState, 
        IPaymentWorkflowState,
        ISelectCountryWorkflowState {

    requestModel: OnlinePaymentRequest;
    idVerificacion: string;
    request: any;

    constructor();    
    
    constructor(obj: OnlinePaymentWorkflowState);
    constructor(obj?:any){
      super();

      this.requestModel = obj && obj.requestModel || new OnlinePaymentRequest();
      this.idVerificacion = obj && obj.idVerificacion || null;      
    }    
    
    /* IWorkflowDataState Members */
    public getPosition(): number {
        return this.requestModel.workflowPosition;
    }

    public setPosition(position: number): void {
        this.requestModel.workflowPosition = position;
    }
    
    /* IAuthenticationWorkflowState Members */    

    public getIdVerificacion(): string {
        return this.idVerificacion;
    }

    getIdTipoSolicitud(): number {
        return -1;
    }

    public getDoctorAvatarUrl(): string {
        return this.requestModel.urlAvatarResponsableServicioDestino;
    }

    public setUserData(userPerson: UserPerson): void {        
        this.requestModel.emailPersonaEmisora = userPerson.emailParticular;

        if(!this.requestModel.usuarioSolicitante){
            this.requestModel.usuarioSolicitante = new RequestingUser();
        }

        this.requestModel.usuarioSolicitante.id = userPerson.id;
        this.requestModel.usuarioSolicitante.username = userPerson.emailParticular;
        this.requestModel.usuarioSolicitante.nombre = userPerson.nombre;
        this.requestModel.usuarioSolicitante.apellido = userPerson.apellido;
        this.requestModel.usuarioSolicitante.sexo = userPerson.sexo;
        this.requestModel.usuarioSolicitante.fechaNacimiento = userPerson.fechaNacimiento;
        this.requestModel.usuarioSolicitante.urlAvatar = userPerson.ubicacionLogo;
        this.requestModel.usuarioSolicitante.idEstadoValidacionIdentidadPersona = userPerson.idEstadoValidacionIdentidadPersona;
        this.requestModel.usuarioSolicitante.idPaisResidencia = userPerson.idPaisResidencia;
    }

    public getUserId(): number {
        return this.requestModel.usuarioSolicitante && this.requestModel.usuarioSolicitante.id
            ? this.requestModel.usuarioSolicitante.id 
            : 0;
    }

    public setEmmiterPersonId(personId: number): void {
        this.requestModel.idPersonaEmisora = personId;
    }    

    /* IPaymentAmountWorkflowState Members */    
    getTargetUrlAvatar(): string {
        return this.requestModel.urlAvatarResponsableServicioDestino;
    }
    getTargetUsername(): string {
        return this.requestModel.nombreResponsableServicioDestino
    }
    getTargetSpecialty(): string {
        return this.requestModel.especialidadResponsableServicioDestino
    }
    getUserName(): string {
        return this.requestModel.usuarioSolicitante?.username;
    }
    getAmount(): string {
        if (this.requestModel.pagoDetalle && this.requestModel.pagoDetalle.monto)
            return this.requestModel.pagoDetalle.monto;
        else 
            return "0.00";
    }
    setAmount(amount: string): void {
        if (!this.requestModel.pagoDetalle)
            this.requestModel.pagoDetalle = new PayModel();

        this.requestModel.pagoDetalle.monto = amount;
    }

    setPublicProfile(profile: PublicProfile):void {
        this.requestModel.perfilPublico = profile;
    }

    getPublicProfile():PublicProfile {
        return this.requestModel.perfilPublico;
    }

    /* IPaymentWorkflowState Members */
    setCreditCardType(creditCardType: string) {
        if (!this.requestModel.pagoDetalle)
            this.requestModel.pagoDetalle = new PayModel();

        this.requestModel.pagoDetalle.tipoTarjeta = creditCardType;
    }

    getCreditCardNumber(): string {
        return this.requestModel.pagoDetalle?.numeroTarjeta;
    }
    setCreditCardNumber(cardNumber: string): void {
        if (!this.requestModel.pagoDetalle)
            this.requestModel.pagoDetalle = new PayModel();
        
        this.requestModel.pagoDetalle.numeroTarjeta = cardNumber;
    }
    getCreditCardExpirationMonth(): string {
        return this.requestModel.pagoDetalle?.mesExpiracionTarjeta;
    }
    setCreditCardExpirationMonth(expMonth: string): void {
        if (!this.requestModel.pagoDetalle)
            this.requestModel.pagoDetalle = new PayModel();
        
        this.requestModel.pagoDetalle.mesExpiracionTarjeta = expMonth;
    }
    getCreditCardExpirationYear(): string {
        return this.requestModel.pagoDetalle?.anioExpiracionTarjeta;
    }
    setCreditCardExpirationYear(expYear: string): void {
        if (!this.requestModel.pagoDetalle)
            this.requestModel.pagoDetalle = new PayModel();
        
        this.requestModel.pagoDetalle.anioExpiracionTarjeta = expYear;
    }
    getCreditCardCvc(): string {
        return this.requestModel.pagoDetalle?.cVVTarjeta;
    }
    setCreditCardCvc(cvc: string): void {
        if (!this.requestModel.pagoDetalle)
            this.requestModel.pagoDetalle = new PayModel();
        
        this.requestModel.pagoDetalle.cVVTarjeta = cvc;
    }
    getCreditCardFullName(): string {
        let result = "";

        if (this.requestModel.pagoDetalle) {
            if (this.requestModel.pagoDetalle.nombre)
                result = this.requestModel.pagoDetalle.nombre + " ";

            if (this.requestModel.pagoDetalle.apellido)
                result += this.requestModel.pagoDetalle?.apellido;
        }
        
        return result.trim();
    }
    setCreditCardFullName(fullName: string): void {
        let [firstName, ...rest] = fullName.split(' ');
        let lastName = rest.join('_');

        if (!this.requestModel.pagoDetalle)
            this.requestModel.pagoDetalle = new PayModel();

        this.requestModel.pagoDetalle.nombre = firstName;
        this.requestModel.pagoDetalle.apellido = lastName;
    }
    getPhoneNumber(): string {
        return this.requestModel.pagoDetalle?.numeroTelefono;
    }
    setPhoneNumber(fullName: string): void {
        if (!this.requestModel.pagoDetalle)
            this.requestModel.pagoDetalle = new PayModel();
        
        this.requestModel.pagoDetalle.numeroTelefono = fullName;
    }

    public getCountryId(): number {
        return this.requestModel.usuarioSolicitante.idPaisResidencia;
      }

    public setCountry(country: TerritorialDivision): void {
        this.requestModel.usuarioSolicitante.idPaisResidencia = country.idPais;
    }
}