<div class="email-callback-container">
    <app-company-logo #appCompanyLogo class="app-company-logo"></app-company-logo>
    <img *ngIf="appCompanyLogo.isLicensed" src="assets/images/cliniweb/Maibox.gif"/>
    <span class="title">{{ model.titleReference | translate:locale.language }}</span>
    <span class="description" [innerHtml]="model.description"></span>
    <div class="item-link">
        <span>{{ 'emailCallback.item1Text' | translate:locale.language }}</span>
        <a class="resend-link" (click)="resendRequest()" [ngClass]="{'disabled': forwardingCount >= model.forwardingLimit }">
            <span *ngIf="!emailSent">{{ 'emailCallback.item1Link' | translate:locale.language }}</span>
            <mat-spinner *ngIf="loading && !emailSent" diameter="18"></mat-spinner>
            <label *ngIf="emailSent">{{ 'emailCallback.emailSent' | translate:locale.language }}</label>
        </a>
    </div>
    <div class="item-link">
        <span>{{ 'emailCallback.item2Text' | translate:locale.language }} </span>
        <a (click)="enterDataAgain()">{{ 'emailCallback.item2Link' | translate:locale.language }}</a>
    </div>
</div>
