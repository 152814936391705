import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { AppointmentInfoDataModel } from 'src/app/shared/models/workflow/models/appointment-info-data.model';

@Component({
  selector: 'app-appointment-info-dialog',
  templateUrl: './appointment-info-dialog.component.html',
  styleUrls: ['./appointment-info-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppointmentInfoDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AppointmentInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AppointmentInfoDataModel,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) { }

  ngOnInit(): void {
  }

  onClose(){
    this.dialogRef.close();
  }
}
