import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { BaseService } from 'src/app/core/services/base.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';

@Component({
  selector: 'app-preventive-control-tips',
  templateUrl: './preventive-control-tips.component.html',
  styleUrls: ['./preventive-control-tips.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('PreventiveControlTipsComponent')
export class PreventiveControlTipsComponent implements OnInit, IControlComponent {
  data: FormComponentData;

  constructor(
    private router: Router,
    private baseService: BaseService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) { }

  ngOnInit(): void {

  }
  
  onGeneralMedicineClick() {
    this.router.navigate(["/doctores/especialidad/medicina-general"], {queryParams: {idC: 35, idTc: 5 }} );
  }

  onDermatologyClick() {
    this.router.navigate(["/doctores/especialidad/dermatologia"], {queryParams: {idC: 19, idTc: 5 }} );
  }

  onDentistClick() {
    this.router.navigate(["/doctores/especialidad/odontologia"], {queryParams: {idC: 47, idTc: 5 }} );
  }
  
  onOphthalmologyClick() {
    this.router.navigate(["/doctores/especialidad/oftalmologia"], {queryParams: {idC: 49, idTc: 5 }} );
  } 
}