<div class="appointment-tabs-container">
    <mat-tab-group animationDuration="0ms" color="accent">
        
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="tab-header">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-citas-medicas tab-icon"></mat-icon>                
                    <span class="tab-title">{{ 'shared.appointmentTabs.text1' | translate:locale.language }}</span>      
                </div>                          
            </ng-template>    
            <div class="tab-content">                
                <div class="search-container">
                    <search-doctors-container [parentData]="data"></search-doctors-container>
                </div>
                <app-future-appointment-cards-container></app-future-appointment-cards-container>
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>     
                <div class="tab-header">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-clock tab-icon"></mat-icon>
                    <span class="tab-title">{{ 'shared.appointmentTabs.text2' | translate:locale.language }}</span> 
                </div>                               
            </ng-template> 
            <!-- Lazy load this tab content (it will be loaded when clicking the tab) -->
            <ng-template matTabContent>
                <div class="tab-content">  
                    <app-past-appointment-container></app-past-appointment-container>                
                </div>
            </ng-template>                        
        </mat-tab>  

    </mat-tab-group>
</div>






