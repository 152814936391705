import { Injectable } from "@angular/core";
import { BaseStep } from "./base-step.model";
import { IWorkflowDataState } from "src/app/shared/interfaces/workflow-data-state";
import { ComponentDataSetMessage, MessageBusService } from "src/app/core/services/message-bus.service";
import { Observable, of } from "rxjs";
import { WorkflowService } from "src/app/core/services/workflow.service";
import { WorkflowItem } from "../../systems/workflow.model";
import { PatientService } from "src/app/core/services/patient.service";
import { Relation } from "../../people/relation.model";
import { ComponentName } from "src/app/shared/enums/component-name.enum";
import { SelectPatientRelationshipModel } from "../models/select-patient-relationship.model";
import { map, switchMap } from "rxjs/operators";
import { Dependent } from "../../people/dependent.model";
import { UserService } from "src/app/core/services/user.service";

@Injectable({
    providedIn: 'root',
  })
export class SelectPatientRelationshipStep extends BaseStep<IWorkflowDataState & ISelectPatientRelationshipWorkflowState> {
    constructor(
        protected messageBusService: MessageBusService,
        private workflowService: WorkflowService,
        private userService: UserService
    ){
        super(messageBusService);

        this.showHeader = true;
        this.showButtonBack = false;
        this.showProgressBar = true;
        this.showButtonClose = true;
    }

    setItem(item: WorkflowItem): void {
        this.item = item;
    }

    setState(stateData: any): void {
        this.stateData = this.workflowService.getWorkflowDataState();
    }

    isCompleted(): Observable<boolean> {
        return this.containsDependents().pipe(map((containsDependents: boolean)=>{
            return !containsDependents || this.stateData.getPatientRelationship() > 0 || this.stateData.getUserPhrId() > 0;
        }));
    }

    showStepInActionBack(): boolean {
        return true;
    }
    
    showStep(): boolean {
        return true;
    }

    protected handleComponentSetDataMessage(message: ComponentDataSetMessage) {
        if (message.componentName == ComponentName.SELECT_PATIENT_RELATIONSHIP){
            this.stateData.setPatientIdRelationship(message.data.patientIdRelationship);
            this.stateData.setPatientRelationship(message.data.patientRelationship);
        }
    }
    
    protected buildComponentConfigurationData() : { [componentName: string]: any; } {
        let formConfigurationData : { [componentName: string]: any; } = {};
        
        formConfigurationData[ComponentName.SELECT_PATIENT_RELATIONSHIP] = this.getSelectPatientRelationshipModel();

        return formConfigurationData;
    }

    protected messageForThisStep(message: ComponentDataSetMessage): boolean {
        return message.componentName == ComponentName.SELECT_PATIENT_RELATIONSHIP;
    }

    containsDependents(): Observable<boolean>{
        let dependents = this.stateData.getDependents();

        if(dependents){
            return of(dependents.length > 0);
        }
        else{
            return this.userService.getUserDependents().pipe(
                switchMap((dependents)=>{
                    if(dependents.length > 0){
                        this.stateData.setDependents(dependents);
                        return of(true);
                    }
                    else{
                        return of(false);
                    }
                }));
        }
    }

    private getSelectPatientRelationshipModel():SelectPatientRelationshipModel{
        let dataModel = new SelectPatientRelationshipModel();

        dataModel.patientRelationship = this.stateData.getPatientRelationship();
        dataModel.patientIdRelationship = this.stateData.getPatientIdRelationship();

        return dataModel;
    }
}

export interface ISelectPatientRelationshipWorkflowState{
    getUserPhrId(): number;
    getDependents(): Dependent[];
    getPatientRelationship(): number;
    getPatientIdRelationship(): number;

    setDependents(dependents: Dependent[]): void;
    setPatientRelationship(patientRelationshipType:number):void;
    setPatientIdRelationship(patientId:number):void;
}