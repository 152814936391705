export class ProfileDetailModel {
    idPerfilPublico : number;
    idTipoPerfilPublicoDetalle : number;
    idPersona : number;
    idObjetoDetalle : number;
    objetoDetalle : string;
    yipoObjetoDetalle : number;
    localizacion : string;
    institucionTituloObtenido : string;
    fechaTituloObtenido : Date;
    ubicacionFoto : string;
    fechaCreacion : Date;
    idResponsableCreacion : number;
    fechaActualizacion : Date;
    idResponsableActualizacion : number;
    eliminado : string;
    fechaEliminacion : Date;
    idResponsableEliminacion : number;
    descipcionEliminacion : string;
    idEmpresa : number;
    idPais : number;
    idProvincia : number;
    idCiudad : number;
    nombrePais : string;
    nombreProvincia : string;
    nombreCiudad : string;
    descripcion1 : string;
    descripcion2 : string;
    descripcion3 : string;
    descripcion4 : string;
    identificadorPaisCiudad : string;
    idEmpresaLocalidad : number;
    idEmpresaObjetoDetalle : number;
    empresaObjetoDetalle : string;
    nombreEmpresaPerfilPublico : string;
    direccionLocalidad1 : string;
    direccionLocalidad2 : string;
    detalleDestacado : string;
    citaEnLineaHabilitada : string;
    prioridadOrden : number;
    teleconsulta : string;
    idDivisionTerritorial : number;
    identificadorDivisionTerritorial : string;
    nombreCanonicoDivisionTerritorial : string;
}

export enum PublicProfileDetailType {
    Language = 1,
    Title = 2,
    Treatments = 5,
    Conditions = 6,
    Photo = 7,
    Locality = 8,
    Specialty = 9,
    Subspecialty = 10,
    SocialNetworks = 11,
    FocusAreas = 14, // MCWNG-169
    AutobiographicalDescription = 17,
    LaborExperience = 22,
    BrowserTitle = 23,
    BrowserDescription = 24,
    KeywordsSEO = 25,
    Posts = 27,
    Titles = 48,
    Curses = 43,
    Workouts = 45,
    Licenses = 50,
    Affiliations = 4,
    Positions = 53,
    AboutMe = 49,
    PhotoGallery = 56,
    FacebookPixel = 57
}