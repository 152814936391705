import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { Subject } from 'rxjs';
import { LoginService } from '../../login.service';
import { UserService } from 'src/app/core/services/user.service';
import { first } from 'rxjs/operators';
import { LoginResponse } from 'src/app/shared/models/login-response.model';
import { LoginCompletedMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { BaseService } from 'src/app/core/services/base.service';

@Component({
  selector: 'app-login-form-cliniweb',
  templateUrl: './login-form-cliniweb.component.html',
  styleUrls: ['./login-form-cliniweb.component.css']
})
export class LoginFormCliniwebComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe = new Subject();

  loginForm: FormGroup;
  error: string = '';
  submitted: boolean = false;
  showPassword: boolean = false;
  returnUrl: string;
  closeOnBack: boolean = false;
  loading: boolean = false;

  @Input() username:string;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private baseService: BaseService,
    private loginService: LoginService,
    private userService: UserService,
    private messageBusService: MessageBusService,
    private translation: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) { }

  ngOnInit(): void {
    this.getQsParams();

    this.loginForm = this.formBuilder.group(
      {
        password: ['', [Validators.required]]
      },
      { 
        updateOn: "submit" 
      }
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getQsParams(): void {
    this.closeOnBack = this.route.snapshot.queryParams['closeOnBack'] == "true";
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];

    if (this.returnUrl)
      this.returnUrl = decodeURIComponent(this.returnUrl);
  }

  onSubmit(): void {
    this.error = '';    
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.loginService.login(this.username, this.loginForm.controls.password.value)
      .pipe(first())
      .subscribe({ next: this.loginNext.bind(this), error: this.onError.bind(this) });

  }

  private loginNext(args: LoginResponse): void {
    if (args.success) {           
      this.userService.getUserPersonLoggedIn().subscribe(user => {
        if (user) {
          this.messageBusService.loginCompleted(new LoginCompletedMessage(user));
          this.baseService.goToRoute(this.returnUrl);
        } else {
          this.onError();
        }        
      });                   
    } else {
      this.onError(args.message);
    }
  }

  private onError(error?: any): void {
    this.loading = false;
    this.error = error?.message ?? error ?? "Hubo un error, intente nuevamente";
  }
}
