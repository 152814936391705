import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from 'src/app/core/guards/login.guard';
import { AuthCallbackPopupComponent } from './pages/auth-callback-popup/auth-callback-popup.component';
import { AuthCallbackComponent } from './pages/auth-callback/auth-callback.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { LoginFormManagerComponent } from './components/login-form-manager/login-form-manager.component';

const loginRoutes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [LoginGuard],
    children:[
      {
        path: '',
        component: LoginFormManagerComponent
      }
    ],
  },
  {
    path:'callback',
    component: AuthCallbackComponent,
  },
  {
    path:'callback/popup',
    component: AuthCallbackPopupComponent,
  },
  {
    path:'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path:'restablecer/:idVerificacion',
    component: ResetPasswordComponent,
  },
  {
    path:'restablecer',
    component: ResetPasswordComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(loginRoutes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
