export enum ClassConcept {
    PROCEDIMIENTO = 1,
    DIAGNOSTICO = 2,
    HALLAZGO = 3,
    SINTOMA = 4,
    ESPECIALIDAD = 5,
    AREAS_DE_ENFOQUE = 6,
    MISCELANEO = 7,
    ASEGURADORA = 8,
    ENFOQUE_POBLACION = 9,
    IDIOMA = 10,
    RED_MEDICA = 11,
    ATRIBUTOS_ENTIDAD_SALUD = 12,
    CENTROS_ATENCION = 13,
    SERVICIOS_ENTIDAD_SALUD = 14
}
