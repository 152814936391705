<div class="legal-agreements-dialog-container">
    <div class="dialog-header">
        <button class="button-close" mat-icon-button tabindex="-1" (click)="close()">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-close icon"></mat-icon>
        </button>
    </div>

    <div mat-dialog-content>
        <app-legal-agreements [type]="data.type"></app-legal-agreements>
    </div>
</div>