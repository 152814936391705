import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout.service';
import { DialogData } from 'src/app/shared/models/dialog-data.model';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorDialogComponent implements OnDestroy, OnInit {

  private ngUnsubscribe = new Subject();
  licenseLoading: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject(DOCUMENT) private document: Document,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private layoutService: LayoutService,
    private mdDialogRef: MatDialogRef<ErrorDialogComponent>
  ) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    if(this.data.loadLicense){
      this.layoutService.loadLicenseTheme('error-dialog-wrapper')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(_ => this.licenseLoading = false);
    }
    else{
      this.licenseLoading = false;
    }
  }

  public close(value = false): void {
    if (this.data.animationOut) {
      this.document.getElementsByClassName(this.data.id)[0].classList.remove(this.data.animationIn);
      this.document.getElementsByClassName(this.data.id)[0].classList.add(this.data.animationOut);

      setTimeout(()=> this.mdDialogRef.close(value), 300);
    } else {
      this.mdDialogRef.close(value);
    }
  }


}
