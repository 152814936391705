
<div class="states-request-verify-container directory-background">
    <img class="logo" [src]="model.companyLogoUrl" />
    <div class="wrapper">
        <img class="main-image" [src]="model.mainImageUrl" />
        <span class="title">
            {{ model.textRefI18n | translate:locale.language }}
        </span>
        <span class="description" *ngIf="model.textDescriptionRefI18n">
            {{ model.textDescriptionRefI18n | translate:locale.language }}
        </span>
        <div class="button-container">
            <button mat-button mat-flat-button color="primary" *ngIf="model.showActionButton" (click)="model.buttonActionFn()">
                {{ model.buttonTextRefI18n | translate:locale.language }}
            </button>    
            <button mat-button mat-flat-button class="secondary" *ngIf="model.showHomeButton" (click)="gotoHome()">
                {{ (model.secondaryButtonTextRefI18n? model.secondaryButtonTextRefI18n : 'shared.statesRequestVerify.buttonHomeText') | translate:locale.language }}
            </button>
        </div>
    </div>
</div>