import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
@Pipe({
    name: 'customCurrency',
  })
  export class CustomCurrencyPipe implements PipeTransform {
    transform(
        value: string,
        currencyCode: string = 'USD',
        display:
            | 'code'
            | 'symbol'
            | 'symbol-narrow'
            | string
            | boolean = 'symbol',
        digitsInfo: string = '2.2-2',
        locale: string = 'en-US',
    ): string | null {
        if (typeof(value) != undefined)
            value = value.toString();
        else 
            value = "";

        // Remove any character
        value = value.replace(new RegExp("[^0-9|\.]+", "g"), "");

        // Remove any comma before calling the method
        value = value.replace(/,/g, "");

        let number = parseFloat(value);
        let displayStr = display? getCurrencySymbol(currencyCode, 'wide') : "";

        let result = formatCurrency(
          number,
          locale,
          displayStr,
          currencyCode,
          digitsInfo,
        );        

        return result;
    }
}