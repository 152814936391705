<div class="cliniweb-learn-more-container">
    <!--  -->
    
    <mat-card>
        <mat-card-content>
            <div class="title">
                {{ 'shared.learnMore.title' | translate:locale.language }}
            </div>
            <div class="sub-title">
                {{ 'shared.learnMore.subtitle1' | translate:locale.language }}
            </div>
            <p>
                {{ 'shared.learnMore.text1' | translate:locale.language }}
            </p>
            <div class="sub-title">
                {{ 'shared.learnMore.subtitle2' | translate:locale.language }}
            </div>
            <p>
                {{ 'shared.learnMore.text2' | translate:locale.language }}
            </p>
        </mat-card-content>
    </mat-card>
</div>