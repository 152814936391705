import { ChangeDetectorRef, Directive, OnInit } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({
  selector: 'mat-select'
})
export class MatSelectCustomDirective implements OnInit {

  private alturaQuitada = 43;
  private animacion = '0.2s ease-out';
  private clase =  'panel-no-sobre-select';

  constructor(private cd: ChangeDetectorRef, private matSelect: MatSelect) {
    this.matSelect.disableOptionCentering = true;
    this.matSelect.openedChange.subscribe(this.matSelectOpenedChange.bind(this));
  }

  ngOnInit(): void {
    if (!this.matSelect.panelClass) this.matSelect.panelClass = [];
    this.agregarClaseParaManipularUbicacionDeOptiones();
    this.cd.detectChanges();
  }

  private agregarClaseParaManipularUbicacionDeOptiones() {
    if (this.matSelect.panelClass instanceof Set) {
      this.matSelect.panelClass.add(this.clase);
    } else if (typeof this.matSelect.panelClass === 'object' && !Array.isArray(this.matSelect.panelClass)) {
      this.matSelect.panelClass[this.clase] = true;
    } else if (Array.isArray(this.matSelect.panelClass)) {
      this.matSelect.panelClass.push(this.clase)
    } else {
      this.matSelect.panelClass += ` ${this.clase}`;
    }
  }

  private matSelectOpenedChange(opened: boolean): void {
    const matSelectPanel: HTMLElement = this.matSelect.panel?.nativeElement;
    if (opened && matSelectPanel.classList.contains(this.clase)) this.procesarPanelPadreAbiertoYqueContieneLaClase(matSelectPanel);
  }

  private procesarPanelPadreAbiertoYqueContieneLaClase(matSelectPanel: HTMLElement): void {
    const cdkOverlayPanel: HTMLElement | null | undefined = matSelectPanel.parentElement?.parentElement;
    if (cdkOverlayPanel) this.moficarLaPosicionDelPanel(cdkOverlayPanel);
  }

  private moficarLaPosicionDelPanel(cdkOverlayPanel: HTMLElement): void {
    const bottom = parseInt(cdkOverlayPanel.style.bottom);
    const top = parseInt(cdkOverlayPanel.style.top);
    if (!isNaN(bottom)) {
      //cdkOverlayPanel.style.transition = `bottom ${this.animacion}`;
      //cdkOverlayPanel.style.bottom = `${bottom + this.alturaQuitada}px`;
    }
    if (!isNaN(top)) {
      //cdkOverlayPanel.style.transition = `top ${this.animacion}`;
      //cdkOverlayPanel.style.top = `${top + this.alturaQuitada}px`;
    }
  }

}
