import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  licenseLoading: boolean = true;
  
  constructor(private layoutService: LayoutService) { }

  ngOnInit(): void {
    this.layoutService.loadLicenseTheme('login-page-container')
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(result=>{
      this.licenseLoading = false;
    });
  }
  
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
