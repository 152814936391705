import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup.decorator';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { PatientAppointment } from 'src/app/shared/models/process/appointment.model';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { MatDialog } from '@angular/material/dialog';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { BaseService } from 'src/app/core/services/base.service';
import { Directory } from 'src/app/shared/models/systems/directory.model';
import { UserService } from 'src/app/core/services/user.service';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { Dependent } from 'src/app/shared/models/people/dependent.model';
import { SearchProvidersService } from 'src/app/core/services/search-providers.service';

@Component({
  selector: 'app-cliniweb-future-appointment',
  templateUrl: './cliniweb-future-appointments.component.html',
  styleUrls: ['./cliniweb-future-appointments.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('CliniwebFutureAppointmentsComponent')
export class CliniwebFutureAppointmentsComponent implements OnInit, OnDestroy, IControlComponent {
  data: FormComponentData;  
  appointments : PatientAppointment[];
  appointmentsToShow : PatientAppointment[];    
  inProgressState: string;  
  loading = true;
  skeletonItems: string[] = ["", ""];
  directory: Directory;
  showAll: boolean = true;
  dependents: Dependent[];  
  loggedInUser: UserPerson;
  intervalId: number;

  private ngUnsubscribe = new Subject();

  constructor(
    private appointmentService: AppointmentService, 
    @Inject(L10N_LOCALE) public locale: L10nLocale, 
    public dialog: MatDialog,
    private messageBusService: MessageBusService,
    private baseService: BaseService,
    private userService: UserService,
    private searchProvidersService: SearchProvidersService) {
  }

  ngOnInit(): void {
      
    this.loading = true;

    this.messageBusService.onAppointmentChangeReceived()
    .pipe(takeUntil(this.ngUnsubscribe))  
    .subscribe(appointments => {
      if (appointments.citasFuturas) {
        this.appointments = appointments.citasFuturas.sort(this.sortByAppointmentState());        
        this.appointmentsToShow = this.showAll ? this.appointments : this.appointments.slice(0, 1);
      }
      this.loading = false;
    });     

    let observables: Observable<any>[] = [];
    observables.push(this.getDirectory());
    observables.push(this.getUser());
    observables.push(this.getDependents());
    observables.push(this.getAppointments());

    forkJoin(observables)
    .pipe(takeUntil(this.ngUnsubscribe))  
    .subscribe(results => { 
      this.directory = results[0] as Directory;
      this.loggedInUser = results[1] as UserPerson;
      this.dependents = results[2] as Dependent[];
      
      var appointments = results[3] as PatientAppointment[];
      this.appointments = appointments.sort(this.sortByAppointmentState());
      this.appointmentsToShow = this.showAll ? this.appointments : this.appointments.slice(0, 1);

      this.loading = false;
    });

    // Check appointment every 20 seconds
    this.intervalId = window.setInterval(() => {
      this.checkFutureAppointmentTime();
    }, 20 * 1000);
  }
  
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    window.clearInterval(this.intervalId);
  }  

  getDirectory() : Observable<Directory> {
    let companyId = this.baseService.getCliniwebDirectoryCompanyId();

    return this.baseService.getCliniwebDirectory(companyId);
  }

  getUser() : Observable<UserPerson | null> {
    return this.userService.getUserPersonLoggedIn().pipe(
      tap(() => {
        
      }),
      catchError((e => {        
        this.baseService.handleServiceError(e, "Error getting user"); 
        
        return of(null);
      })));
  }

  getDependents() : Observable<Dependent[]>{
    return this.userService.getUserDependents().pipe(
      tap(() => {
        
      }),
      catchError((e => {        
        this.baseService.handleServiceError(e, "Error getting dependents"); 
        
        return of([]);
      })));
  }

  getAppointments() : Observable<PatientAppointment[]> {
    this.loading = true;

    return this.appointmentService.getFutureAppointments(this.locale.language).pipe(
      tap(() => {
        
      }),
      catchError((e => {
        this.baseService.handleServiceError(e, "Error getting future appointment");  
        
        return of([]);
      })));
  }    

  refreshAppointments() {
    this.loading = true;

    this.appointmentService.clearAppointmentsCache();

    this.getAppointments().subscribe(apps => {
      this.appointments = apps;

      this.loading = false;
    });
  }

  onShowAll(){ 
    this.showAll = true;
    this.appointmentsToShow = this.appointments;
  }

  appointmentTrackBy(index : number, item: PatientAppointment){
    return item.idSolicitud; 
  }  
  
  onNewAppointmentClick() {
    this.searchProvidersService.openSearch();
  }  

  private sortByAppointmentState(): ((a: PatientAppointment, b: PatientAppointment) => number) | undefined {
    return (a1, a2) => {
      if (a1.idEstado != a2.idEstado) {
        // si la cita esta cancelada o no asistio va a lo ultimo
        if (a2.idEstado == 7)
          return -1;

        if (a1.idEstado == 7 || a1.idEstado == 28)
          return 1;
      }

      return 0;
    };
  }

  private checkFutureAppointmentTime() {
    if (!this.appointments || this.appointments.length == 0)
      return;

    this.appointments.forEach(app => {
      if (this.isAppointmentExpired(app)) {
        this.refreshAppointments();
        return;
      }
    });
  }

  private isAppointmentExpired(item : PatientAppointment) {
    let now = new Date().getTime();
    let appDate = new Date(item.fechaRequerido).getTime();

    return appDate <= now;
  }

}


