import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { OpenSnackBarMessage, SnackBarType } from 'src/app/core/services/message-bus.service';

@Component({
  selector: 'app-custom-snack-bar',
  templateUrl: './custom-snack-bar.component.html',
  styleUrls: ['./custom-snack-bar.component.css']
})
export class CustomSnackBarComponent implements OnInit {

  text: string;

  constructor(
    public snackBarRef: MatSnackBarRef<CustomSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: OpenSnackBarMessage
  ) { }

  ngOnInit(): void {
    this.text = this.data.text;
  }

  onClose(){
    this.snackBarRef.dismiss();
  }

  getIconClassname(){
    switch(this.data.type){
      case SnackBarType.SUCCESS:
        return 'phr-iconset-PHR-Tup-2';
      case SnackBarType.ERROR:
        return 'phr-iconset-error';
      case SnackBarType.INFO:
        return 'phr-iconset-info';
      case SnackBarType.ALERT:
        return 'phr-iconset-alert';
    }
  }

  getTypeClassname(){
    switch(this.data.type){
      case SnackBarType.SUCCESS:
        return 'success';
      case SnackBarType.ERROR:
        return 'error';
      case SnackBarType.INFO:
        return 'info';
      case SnackBarType.ALERT:
        return 'alert';
    }    
  }
}
