export class SearchQsParametersModel{
    t:string;
    filtros:string;
    idC: string;
    idTc: string;
}

export class SearchQsParamMapModel{
    param1:string;
    param2:string;
    param3:string;
    param4:string;
    param5:string;
}