import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup.decorator';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { PatientAppointment } from 'src/app/shared/models/process/appointment.model';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { Router } from '@angular/router';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { TimelineWrapperItem, TimelineWrapperItemType, TimelineWrapperSkeletonItem } from '../../timeline-list/timeline-list.component';
import { BaseService } from 'src/app/core/services/base.service';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-past-appointment-container',
  templateUrl: './past-appointments.component.html',
  styleUrls: ['./past-appointments.component.css']
})
@ComponentLookup('PastAppointmentsComponent')
export class PastAppointmentsComponent implements IControlComponent, OnInit, OnDestroy, AfterViewInit  {
  data: FormComponentData;  
  appointments: TimelinePatientAppointment[];  
  completedStateName: string;
  notCompletedStateName: string;
  cancelledStateName: string;
  loading = true;
  skeletonItems: TimelineWrapperSkeletonItem[] = [new TimelineWrapperSkeletonItem(), new TimelineWrapperSkeletonItem()];

  //subscribes
  private ngUnsubscribe = new Subject();

  ngAfterViewInit() {    
  }  

  constructor(
    private appointmentService: AppointmentService, 
    private translation: L10nTranslationService,
    private router: Router,
    private baseService: BaseService,
    public dialog: MatDialog,
    private publicProfileService: PublicProfileService,
    private messageBusService: MessageBusService,
    @Inject(L10N_LOCALE) public locale: L10nLocale) {
  }
  
  ngOnInit(): void {
    this.translation.onChange()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
      next: () => {
          this.completedStateName = this.translation.translate('shared.pastAppointments.text2');                    
          this.notCompletedStateName = this.translation.translate('shared.pastAppointments.text3');      
          this.cancelledStateName = this.translation.translate('shared.pastAppointments.text7');                       
      }
    });

    this.appointmentService.getPastAppointments(this.locale.language)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(appointments =>{
      this.appointments = appointments.map(a => new TimelinePatientAppointment(a));            

      this.loading = false;
    },
    error => {
      this.loading = false;

      this.baseService.handleServiceError(error, "Error getting past appointments");      
    });

    this.messageBusService.onAppointmentChangeReceived()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      appointments => {
        this.appointments = appointments.citasPasadas.map(a => new TimelinePatientAppointment(a)); 
        this.loading = false;
      },
      error => { this.baseService.handleServiceError(error, "Error getting past appointments") }      
    );
  }

  ngOnDestroy():void{
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  appointmentTrackBy(index : number, item: PatientAppointment){
    return item.idSolicitud; 
  }

  getLocationDescription(item : PatientAppointment) {
    let desc = item.direccionLocalidad;

    if (!desc)
      desc = item.nombreLocalidad;

      return desc;
  }

  getAvatarUrl(item : PatientAppointment) {
    let avatarUrl = item.urlAvatarResponsableServicio;

    if (!avatarUrl)
      avatarUrl = "/assets/Images/avatar_male_doctor.svg";

      return avatarUrl;
  }

  getStateName(item : PatientAppointment) {
    if (item.idEstado == 7)
      return this.cancelledStateName;
    if (item.idEstado == 28 || item.idEstado == 22)
      return this.notCompletedStateName;
    else 
      return this.completedStateName;
  }

  getStateClass(item : PatientAppointment) {
    if (item.idEstado == 7)
      return "cancelled";
    if (item.idEstado == 28)
      return "incomplete";
    else 
      return "completed";
  }

  isIncomplete(item : PatientAppointment) {
    return item.idEstado == 7 || item.idEstado == 28;
  }
  
  navigateToPublicProfile(item : PatientAppointment) {    

    // If the doctor does not have a public profile
    if (!item.identificadorPerfilPublico) {
      let dialogData = new DialogData();
      dialogData.title = this.translation.translate("shared.pastAppointments.text4");
      dialogData.message = this.translation.translate("shared.pastAppointments.text5");
      dialogData.confirmText = this.translation.translate("shared.pastAppointments.text6");
      dialogData.showCancelButton = false;

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: dialogData
      });
    }
    else {
      //let url = "/perfil/" + item.identificadorPerfilPublico;    
      let url = this.publicProfileService.getPublicProfileUrl(item.identificadorPerfilPublico);  

      this.router.navigate([url], { queryParams: {rs: true} });
    }
  }

  navigateToAppointmentPage() {
    window.location.reload();
  }
}

export class TimelinePatientAppointment implements TimelineWrapperItem {  
  patient : PatientAppointment;

  constructor(patient : PatientAppointment) {
    this.patient = patient;
  }
  
  getGroupKey() : number {
    return new Date(this.patient.fechaInicio).getFullYear();
  }

  getDate() : Date {
    return new Date(this.patient.fechaInicio);
  }

  getItemType(): TimelineWrapperItemType{
    return this.patient.idEstado == 7 || this.patient.idEstado == 28 
      ? TimelineWrapperItemType.CANCELLED
      : TimelineWrapperItemType.CHECKED;
  }

  showTime():boolean{
    return true;
  }

  showDate():boolean{
    return true;
  }
}