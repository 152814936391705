import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { RouteDialogHeaderModel } from '../../../dialogs/route-dialog/route-dialog.component';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { Router } from '@angular/router';
import { HeaderComponentPublicProfileMenuModel } from '../../header/header.component';
import { MessageBusService, PublicProfileActionMessage } from 'src/app/core/services/message-bus.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-public-profile-header',
  templateUrl: './public-profile-header.component.html',
  styleUrls: ['./public-profile-header.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('PublicProfileHeaderComponent')
export class PublicProfileHeaderComponent implements OnInit, IControlComponent, OnDestroy {
  data: RouteDialogHeaderModel;
  urlPublicProfile: string;
  publicProfileMenuModel: HeaderComponentPublicProfileMenuModel;
  private ngUnsubscribe = new Subject();
  
  constructor(
    private router: Router,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private messageBusService: MessageBusService) { }

  ngOnInit(): void {
    this.urlPublicProfile = this.data.dialogModel.route;

    this.messageBusService.onPublicProfileHeaderVisibilityChange()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((message)=> {
      if (!message.fromRouteDialog)
        return;

      this.publicProfileMenuModel = new HeaderComponentPublicProfileMenuModel();
      this.publicProfileMenuModel.headerVisible = message.headerVisible;
      this.publicProfileMenuModel.profile = message.profile;
      this.publicProfileMenuModel.avatarModel = message.publicProfileAvatarComponentModel;
      this.publicProfileMenuModel.avatarModel.useAvatarPadding = message.headerVisible;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onCloseClick(){
    this.data.closeFn();
  }

  gotoPayment() {
    this.messageBusService.sendPublicProfileAction(new PublicProfileActionMessage(true, false));
  }

  onAppointmentBtnClick() {
    this.messageBusService.sendPublicProfileAction(new PublicProfileActionMessage(false, true));
  }
}
