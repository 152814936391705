<div class="medical-history-wrapper" ngClass.lt-md="mobile">
    <div class="medical-history-card-wrapper" *ngFor="let item of items">
        <mat-card class="medical-history-card">
            <mat-card-title>
                <mat-icon fontSet="phr-iconset" [ngClass]="item.icono"></mat-icon>
                <span class="title">{{item.titulo}}</span>
            </mat-card-title>
            <mat-card-content>
                <div class="concept-wrapper" *ngFor="let concept of item.conceptos">
                    <span>{{concept.nombreConcepto}}</span>                    
                    <span>{{concept.valorConcepto}}</span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="empty-state-container" *ngIf="!items || items.length == 0">
        <div class="title" [innerHTML]="'shared.clinicHistory.medicalHistory.text1' | translate:locale.language"></div>
        <div class="subtitle">{{ 'shared.clinicHistory.medicalHistory.text2' | translate:locale.language }}</div>
    </div>
</div>