<div class="cliniweb-past-appointment-container">
  
    <app-timeline-list-container class="primary-timeline" [items]="appointments" [skeletonItems]="skeletonItems" [loading]="loading">    
      
      <!-- Skeleton -->
      <ng-template let-item="item" #TimelineSkeletonContent>
        <app-cliniweb-appointment [loading]="true"></app-cliniweb-appointment>
      </ng-template>       
  
      <!-- Content -->
      <ng-template let-item="item" #TimelineContent *ngIf="appointments">
        <app-cliniweb-appointment [directory]="directory" [dependents]="dependents" [loggedInUser]="loggedInUser" [app]="item.appointment">
        </app-cliniweb-appointment>
        <div class="left-card-indicator"></div>
      </ng-template>
  
      <!-- Empty State -->
      <ng-template let-item="item" #TimelineEmptyStateContent>

        <div class="empty-state" *ngIf="!loading && (!this.appointments || this.appointments.length == 0)">
            <mat-card>            
                <mat-card-header>
                    <img src="/assets/images/cliniweb/future-appointments-empty-state.png">
                </mat-card-header>
                
                <mat-card-content>
                    <span>{{'shared.cliniwebPastAppointments.text1' | translate:locale.language}}</span>
                    <a (click)="onNewAppointmentClick()">
                      {{ 'shared.cliniwebPastAppointments.text2' | translate:locale.language }}
                    </a>
                </mat-card-content>
            </mat-card>
        </div>
      
    </ng-template>
  
    </app-timeline-list-container>
      
    <div class="mobile-overlay">

    </div>

    <button mat-button mat-flat-button class="new-appointment-btn" (click)="onNewAppointmentClick()">
      <mat-icon fontSet="phr-iconset" class="phr-iconset-plus-heavier"></mat-icon>
      <span class="desktop">{{'shared.cliniwebPastAppointments.text3' | translate:locale.language}}</span>
      <span class="mobile">{{'shared.cliniwebPastAppointments.text4' | translate:locale.language}}</span>
    </button>

  </div>