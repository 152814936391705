<div class="create-patient-relationship-container">
    <div class="content">
        <span class="title">{{ title | translate:locale.language }}</span>
        <form class="create-patient-relationship-form" [formGroup]="createPatientRelationshipForm" (ngSubmit)="onClickContinue()" *ngIf="showForm">
            <mat-form-field appearance="outline">
                <mat-select formControlName="relationship" placeholder="{{ 'shared.medicalOrder.patientVerification.text2' | translate:locale.language }}">
                    <mat-option *ngFor="let relationType of relationTypes" [value]="relationType.value">
                        {{ relationType.viewValue | translate:locale.language }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="button-container">
                <button mat-button mat-flat-button class="primary" [ngClass]="{'loading': loading }">
                    {{ 'shared.createPatientRelationship.buttonText' | translate:locale.language }}
                </button>
            </div>
        </form>
    </div>
</div>