import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { OnlinePaymentWorkflowState } from "../../states/online-payment-workflow-state.model";
import { PaymentAmountComponentDataModel } from "src/app/shared/components/payment-amount/payment-amount.component";
import { ComponentName } from "src/app/shared/enums/component-name.enum";
import { ComponentDataSetMessage, MessageBusService } from "src/app/core/services/message-bus.service";
import { BaseStep } from "../base-step.model";
import { PublicProfile } from "../../../people/public-profile.model";

@Injectable({
    providedIn: 'root',
})
export class PaymentAmountStep extends BaseStep<OnlinePaymentWorkflowState> {
    
    constructor(
        protected messageBusService: MessageBusService,
    ){    
        super(messageBusService);
        
        this.showHeader = true;
        this.showProgressBar = true;
        this.showButtonClose = true;
    }    

    protected handleComponentSetDataMessage(message: ComponentDataSetMessage) {
        if (message.componentName == ComponentName.PAYMENT_AMOUNT)
            this.stateData.setAmount(message.data);        
    }   

    protected buildComponentConfigurationData() : { [componentName: string]: any; } {
        let formConfigurationData : { [componentName: string]: any; } = {};
        
        formConfigurationData[ComponentName.PAYMENT_AMOUNT] = this.getPaymentAmountComponentData();

        return formConfigurationData;
    }

    protected messageForThisStep(message: ComponentDataSetMessage): boolean {
        return message.componentName == ComponentName.PAYMENT_AMOUNT;
    }

    private getPaymentAmountComponentData() {
        var data = new PaymentAmountComponentDataModel();

        data.targetUserName = this.stateData.getTargetUsername();
        data.targetUserAvatarUrl = this.stateData.getTargetUrlAvatar();
        data.specialty = this.stateData.getTargetSpecialty();
        data.userName = this.stateData.getUserName();
        data.paymentAmount = this.stateData.getAmount();
        data.publicProfile = this.stateData.getPublicProfile();

        return data;
    }   

    setState(stateData: any): void {
        this.stateData = new OnlinePaymentWorkflowState(stateData);
    }

    isCompleted(): Observable<boolean> {
        return of(this.stateData && this.stateData.getAmount() != "0.00");
    }

    showStepInActionBack(): boolean {
        return true;
    }

    showStep(): boolean {
        return true;
    }
}

export interface IPaymentAmountWorkflowState{
    getTargetUrlAvatar():string;
    getTargetUsername():string;
    getTargetSpecialty():string;
    getUserName():string;
    getAmount():string;
    getPublicProfile(): PublicProfile;

    setAmount(amount: string):void;
    setPublicProfile(profile: PublicProfile):void;
}