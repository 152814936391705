import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { UserService } from 'src/app/core/services/user.service';
import { Policy } from 'src/app/shared/models/people/policy.model';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';

@Component({
  selector: 'app-policy-menu',
  templateUrl: './policy-menu.component.html',
  styleUrls: ['./policy-menu.component.css']
})

export class PolicyMenuComponent implements OnInit, OnDestroy {  
  private ngUnsubscribe = new Subject();
  user: UserPerson;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private messageBusService: MessageBusService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,   
  ) {    
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.userService.getUserPersonLoggedIn()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(p => {
        if (p)
          this.user = p;
      });
    }

    // On SSO when the ngOnInit the user is not yet logged in, so for those cases we will subscribe to this event
    this.messageBusService.onLoginCompleted()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(msg => {
      if (msg.user)  
        this.user = msg.user;
    });

    this.messageBusService.onLogout()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(msg => {
      this.user = null as any;      
    });
  } 

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getUserName() {
    return this.user.nombre + " " + this.user.apellido;
  }

  policyTrackBy(index : number, item: Policy){
    return item.numeroPoliza; 
  }

}
