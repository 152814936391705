import { Injectable } from '@angular/core';
import { PortalPhr } from 'src/app/shared/models/systems/portal-phr.model';
import { HttpBaseService } from './http-base-service';

@Injectable({
  providedIn: 'root'
})
export class PortalPHRService {

  constructor(private http: HttpBaseService) { }      

  getByUrl(serverUrl: string) {
    let url = "api/portalphr?url=" + serverUrl;

    return this.http.get<PortalPhr>(url, null, false)
  } 
  
}