<div class="user-payments-list-container directory-background">
    
    <!-- Skeleton -->
    <mat-card class="skeleton-card" *ngIf="loading">
        <div class="title">
            <ngx-skeleton-loader [theme]="{'width': '45%', 'height': '24px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
        </div>
        
        <mat-card-content>
            <div class="payment-item">
                <div class="left">
                    <div class="doc-name">
                        <ngx-skeleton-loader [theme]="{'width': '205px', 'height': '18px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
                    </div>
                    <div class="payment-date">
                        <ngx-skeleton-loader [theme]="{'width': '80px', 'height': '18px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
                    </div>
                </div>
                <div class="right">
                    <div class="payment-amount">
                        <span><ngx-skeleton-loader [theme]="{'width': '35px', 'height': '18px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader></span>                        
                    </div>
                </div>
            </div>
            <div class="payment-item">
                <div class="left">
                    <div class="doc-name">
                        <ngx-skeleton-loader [theme]="{'width': '205px', 'height': '18px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
                    </div>
                    <div class="payment-date">
                        <ngx-skeleton-loader [theme]="{'width': '80px', 'height': '18px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
                    </div>
                </div>
                <div class="right">
                    <div class="payment-amount">
                        <span><ngx-skeleton-loader [theme]="{'width': '35px', 'height': '18px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader></span>                        
                    </div>
                </div>
            </div>
            <div class="payment-item">
                <div class="left">
                    <div class="doc-name">
                        <ngx-skeleton-loader [theme]="{'width': '205px', 'height': '18px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
                    </div>
                    <div class="payment-date">
                        <ngx-skeleton-loader [theme]="{'width': '80px', 'height': '18px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
                    </div>
                </div>
                <div class="right">
                    <div class="payment-amount">
                        <span><ngx-skeleton-loader [theme]="{'width': '35px', 'height': '18px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader></span>                        
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    
    <mat-card *ngIf="!loading && payments && payments.length > 0">
        <div class="title">
            {{'shared.userPaymentsList.text1' | translate:locale.language}}
        </div>
        
        <mat-card-content>            
            <div class="payment-item" *ngFor="let payment of payments; index as i; trackBy: paymentTrackBy" (click)="onPaymentClick(payment)">
                <div class="left">
                    <div class="doc-name">
                        {{payment.nombreResponsableServicio | lowercase }}
                    </div>
                    <div class="payment-date">
                        {{payment.fechaPagoElectronico | date:'d MMM, y' }}
                    </div>
                </div>
                <div class="right">
                    <div class="payment-amount">
                        <span>${{formatPaymentAmount(payment.montoPago)}}</span>
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-arrow-down"></mat-icon>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <!-- Empty state -->
    <mat-card *ngIf="!loading && payments && payments.length == 0">
        <div class="title">
            {{'shared.userPaymentsList.text1' | translate:locale.language}}
        </div>
        
        <mat-card-content>            
            <div class="empty-state-container">
                {{'shared.userPaymentsList.text7' | translate:locale.language}}
            </div>
        </mat-card-content>
    </mat-card>

    <div class="buttons-container">
        <button mat-button mat-flat-button class="primary" (click)="onNewPaymentClick()">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-plus-heavier"></mat-icon>
            <span>{{'shared.userPaymentsList.text2' | translate:locale.language}}</span>
        </button>
    </div>
</div>