import { TextLanguage } from "../people/text-language.model";

export class TerritorialDivision {    
    id: number;
    idEmpresa?: number;
    nombre: string;
    nombreCanonico: string;
    identificador: string;
    nivel: number;
    paisISO: string;
    idPais:number;
    nombreI18n: TextLanguage[];
}

export class CountryModel{
    territorialDivision: TerritorialDivision;
    ip: string;    
}