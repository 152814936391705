import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-singup-error-banner',
  templateUrl: './login-singup-error-banner.component.html',
  styleUrls: ['./login-singup-error-banner.component.css']
})
export class LoginSingupErrorBannerComponent implements OnInit {

  @Input() error: string;

  constructor() { }

  ngOnInit(): void {
  }

}
