import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { MessageBusService } from '../services/message-bus.service';
import { RouteLoaderService } from '../services/route-loader.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private messageBusService: MessageBusService,
    private routeLoaderService: RouteLoaderService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot
    ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {

      return this.routeLoaderService.load().pipe(switchMap(()=>{

        return this.authService.hasPermissions(state.url).pipe(map((result:boolean) => {
          if(route.queryParams.authIframe){
            this.messageBusService.authIframe();
          }
  
          return result;
        }));
      }));
  }
}
