import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';

@Component({
  selector: 'app-cliniweb-appointment-tabs',
  templateUrl: './cliniweb-appointment-tabs.component.html',
  styleUrls: ['./cliniweb-appointment-tabs.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('CliniwebAppointmentTabsComponent')
export class CliniwebAppointmentTabsComponent implements IControlComponent, OnInit {
    data: FormComponentData;

    constructor(
      @Inject(L10N_LOCALE) public locale: L10nLocale) {
    }

    ngOnInit(): void {
    }
}