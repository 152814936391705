import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { BaseService } from 'src/app/core/services/base.service';
import { CloseRouteDialogMessage, MessageBusService, OpenSnackBarMessage, SnackBarType } from 'src/app/core/services/message-bus.service';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { UserService } from 'src/app/core/services/user.service';
import { RouteDialogType } from 'src/app/shared/components/dialogs/route-dialog/route-dialog.component';
import { UpdatePasswordRequestModel } from 'src/app/shared/models/people/update-password-request.model';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';
import { LayoutService } from 'src/app/core/services/layout.service';
import { StatesRequestVerifyDataModel } from 'src/app/shared/components/states-request-verify/states-request-verify.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  
  idVerificacion: string;
  showPassword: boolean = false;
  resetPasswordForm: FormGroup;
  submitted:boolean = false;
  error = '';
  loading: boolean = false;
  showCustomHeader = false;
  currentUser: UserPerson;
  changePassword: boolean = false;
  showCurrentPassword: boolean = false;
  licenseLoading: boolean = true;

  statesRequestVerifyDataModel: StatesRequestVerifyDataModel;
  isRequestExpired: boolean = false;
  isRequestCompleted: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private messageBusService: MessageBusService,
    private translation: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private authService: AuthService,
    private baseService: BaseService,
    private routeDialogService: RouteDialogService,
    private layoutService: LayoutService,
  ) { }

  ngOnInit(): void {
    this.layoutService.loadLicenseTheme('reset-password-container')
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(result=>{
      this.licenseLoading = false;
    });

    if(this.route.snapshot.params.idVerificacion){
      this.idVerificacion = this.route.snapshot.params.idVerificacion;
      this.showCustomHeader = true;
    }
    else if (this.authService.isLoggedIn()) {
      this.changePassword = true;
    }
    else{
      this.router.navigate(['not-found']);
    }

    if (this.idVerificacion) {
      this.userService.getUserUnverifiedRequest(this.idVerificacion).subscribe(
        request => {
          this.isRequestExpired = request.vencida;
          this.isRequestCompleted = request.idEstado != 2;

          this.configStatusView();
        },
        error => {
          console.error(error);
        }
      )
    }

    this.resetPasswordForm = this.formBuilder.group(
      {
        password: ['', [
          Validators.required,
          Validators.minLength(6),
          Validators.pattern('^(?=.*[A-Za-zÀ-ȕ])(?=.*\\d)[A-Za-zÀ-ȕ\\d$@$`~^()-_+={};:"#|!%*?& ]{2,30}$'),
          this.noWhitespaceValidator
        ]],
        currentPassword: ['', [
          Validators.required,
        ]]
      },
      { 
        updateOn: "change" 
      }
    );

    if (this.idVerificacion) this.resetPasswordForm.get('currentPassword')?.clearValidators();

    this.userService.getUserPersonLoggedIn()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(p => {
      if (p)
        this.currentUser = p;        
    });

    // On Header Back click
    this.messageBusService.onNavigateBack()
      .pipe(takeUntil(this.ngUnsubscribe))  
      .subscribe(m => {
        if (!this.routeDialogService.isOpen(RouteDialogType.CHANGE_PASSWORD))
          this.router.navigate(['/']);
        else 
          this.messageBusService.closeRouteDialog(new CloseRouteDialogMessage());
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSubmit() {
    if (this.changePassword) {
      this.onChangePassword();
    }
    else {
      this.onResetPassword();
    }
  }

  private onChangePassword() {
    this.submitted = true;

    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.loading = true;

    let model = new UpdatePasswordRequestModel();

    this.baseService.getDirectoryDomain()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((domain:string)=>{
      model.dominio = domain;
      model.emailUsuario = this.currentUser.emailParticular;
      model.contraseniaAnterior = this.resetPasswordForm.controls.currentPassword.value.trim();
      model.contraseniaNueva = this.resetPasswordForm.controls.password.value;
  
      this.userService.updatePassword(model)
        .subscribe(r => {
          this.loading = false;
  
          this.showSuccessToast();
  
          if (!this.routeDialogService.isOpen(RouteDialogType.CHANGE_PASSWORD))
            this.router.navigate(['/']);
          else 
            this.messageBusService.closeRouteDialog(new CloseRouteDialogMessage());
        },
        error => {
          //this.resetPasswordForm.controls.currentPassword.setValue("");
          this.resetPasswordForm.controls.currentPassword.setErrors({'incorrect': true});
  
          this.loading = false;
  
          this.showInvalidCurrentPasswordToast();
        });
    });
  }

  showInvalidCurrentPasswordToast() {
    let message = new OpenSnackBarMessage();
    message.text = this.translation.translate('resetPassword.invalidCurrentPassword');
    message.type = SnackBarType.ERROR;
    message.duration = 3000;
   
    this.messageBusService.openSnackBar(message);
  }

  showSuccessToast() {
    let message = new OpenSnackBarMessage();
    message.text = this.translation.translate('resetPassword.changePasswordSuccess');
    message.type = SnackBarType.SUCCESS;
    message.duration = 3000;
   
    this.messageBusService.openSnackBar(message);
  }

  private onResetPassword(){
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.loading = true;

    this.userService.postConfirmationResetPassword(
      this.idVerificacion,
      this.resetPasswordForm.controls.password.value)
      .pipe(first())
      .subscribe({
        next:(response:void) =>{
          this.loading = false;

          let snackBarMessage = new OpenSnackBarMessage();

          snackBarMessage.type = SnackBarType.SUCCESS;
          snackBarMessage.text = this.translation.translate('resetPassword.newPasswordSuccess');

          this.messageBusService.openSnackBar(snackBarMessage);

          this.router.navigate(['/login']);
        },
        error: (error:void) =>{
          this.loading = false;

          let snackBarMessage = new OpenSnackBarMessage();

          snackBarMessage.type = SnackBarType.ERROR;
          snackBarMessage.text = this.translation.translate('resetPassword.newPasswordError');

          this.messageBusService.openSnackBar(snackBarMessage);
        }
      });
  }

  getControl(controlName: string) {
    return this.resetPasswordForm.controls[controlName];
  }
  isControlInvalid(controlName: string) {
    let control = this.getControl(controlName);

    // First check if the form is submitted or control is dirty
    if (!this.submitted && !control.touched)
      return false;

    return control.invalid;
  }

  private noWhitespaceValidator(control: FormControl){
    let valueTrim = control.value.trim();

    return valueTrim.length != control.value.length ? {'whitespace': true} : null;
  }

  configStatusView() {
    if (!this.isRequestExpired && !this.isRequestCompleted)
      return;

    this.statesRequestVerifyDataModel = new StatesRequestVerifyDataModel();

    this.statesRequestVerifyDataModel.companyLogoUrl = 'assets/images/cliniweb/logo-cliniweb-phr.svg';
    this.statesRequestVerifyDataModel.mainImageUrl = 'assets/images/cliniweb/Campana.gif';
    this.statesRequestVerifyDataModel.showActionButton = true;
    this.statesRequestVerifyDataModel.showHomeButton = true;
    this.statesRequestVerifyDataModel.textRefI18n = 'resetPassword.expiredRequestTitle';
    this.statesRequestVerifyDataModel.buttonTextRefI18n = 'resetPassword.expiredRequestRetryBtnText';    
    this.statesRequestVerifyDataModel.secondaryButtonTextRefI18n = 'resetPassword.expiredRequestLoginBtnText';    
    
    this.statesRequestVerifyDataModel.buttonActionFn = this.goToForgotPassword.bind(this);
    this.statesRequestVerifyDataModel.buttonSecondaryActionFn = this.goToLogin.bind(this);

    if (this.isRequestCompleted && !this.isRequestExpired) {
      this.statesRequestVerifyDataModel.textRefI18n = 'resetPassword.completedRequestTitle';      
    }
  }

  goToForgotPassword() {
    this.router.navigate(['/welcome/login/forgot-password'], {queryParams: {"closeOnBack": false}});
  }
  goToLogin() {    
    this.router.navigate(['/welcome/login']);
  }
}
