<div class="anonymous-login-signup-menu-container">
    <button mat-button class="menu-button" [matMenuTriggerFor]="anonymousmenu" yPosition="above">
      <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-menu icon phr-iconset"></mat-icon>
      <mat-icon fontSet="phr-iconset" class="user-icon phr-iconset-avatar icon phr-iconset"></mat-icon>          
    </button>
    <mat-menu #anonymousmenu="matMenu" class="cliniweb-header-mat-menu-container">
      <div class="company-logo-container">
        <app-company-logo></app-company-logo>
      </div>
      <span class="menu-title">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-ISO icon phr-iconset"></mat-icon>
        <span>{{'cliniwebHeader.text1' | translate:locale.language}}</span>            
      </span>          
      
      <!-- Login -->
      <button mat-menu-item (click)="onLoginClick()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-login icon phr-iconset"></mat-icon>
        <span>{{'cliniwebHeader.text3' | translate:locale.language}}</span>            
      </button>
      <!-- Signup -->
      <button mat-menu-item (click)="onSignupClick()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Smile icon phr-iconset"></mat-icon>
        <span>{{'cliniwebHeader.text2' | translate:locale.language}}</span>            
      </button>
      <!-- Appointments -->
      <button mat-menu-item (click)="onViewAppointmentsClick()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-date-2 icon phr-iconset"></mat-icon>
        <span>{{'cliniwebHeader.text4' | translate:locale.language}}</span>            
      </button>
      <!-- Cliniweb for patients -->
      <button mat-menu-item (click)="onCliniwebForPatientsClick()">            
        <mat-icon fontSet="phr-iconset" class="phr-iconset-help icon phr-iconset"></mat-icon>
        <span>{{'cliniwebHeader.text5' | translate:locale.language}}</span>            
      </button>          
      
      <mat-divider></mat-divider>                    
      <span class="menu-title cliniweb-title">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-ISO icon phr-iconset"></mat-icon>
        <span>{{'cliniwebHeader.text6' | translate:locale.language}}</span>            
      </span>

      <!-- Login -->
      <button mat-menu-item (click)="goToPortal()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-login icon phr-iconset"></mat-icon>
        <span>{{'cliniwebHeader.text7' | translate:locale.language}}</span>            
      </button>
      <!-- Create public profile -->
      <button mat-menu-item (click)="goToPortalPublicProfilePage()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-star icon phr-iconset"></mat-icon>
        <span>{{'cliniwebHeader.text8' | translate:locale.language}}</span>            
      </button> 
      <!-- Cliniweb for doctors -->
      <button mat-menu-item (click)="onCliniwebForDoctorsClick()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-help icon phr-iconset"></mat-icon>
        <span>{{'cliniwebHeader.text9' | translate:locale.language}}</span>            
      </button> 
      
    </mat-menu>
</div>