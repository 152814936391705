import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { BaseService } from 'src/app/core/services/base.service';
import { ChatCancelledMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { VirtualAttentionService } from 'src/app/core/services/virtual-attention.service';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { VirtualAttention } from 'src/app/shared/models/process/virtual-attention.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-cancel-virtual-attention-dialog',
  templateUrl: './cancel-virtual-attention-dialog.component.html',
  styleUrls: ['./cancel-virtual-attention-dialog.component.css']  
})
export class CancelVirtualAttentionDialogComponent implements OnInit {   
    virtualAttention: VirtualAttention;    
    reasonText: string;
    loading = false;
    completed = false;            
    selectionControl: FormControl; 
    options: CancelOption[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private mdDialogRef: MatDialogRef<CancelVirtualAttentionDialogComponent>,    
        private baseService: BaseService,    
        private virtualAttentionService: VirtualAttentionService, 
        private messageBusService: MessageBusService,
        @Inject(L10N_LOCALE) public locale: L10nLocale,
        private translation: L10nTranslationService,
        private dialog: MatDialog
    ) {    
        this.selectionControl = new FormControl('auto');
        this.virtualAttention = this.baseService.parseObject<VirtualAttention>(data.data);

        this.options = [
            new CancelOption(1, "Llevo demasiado tiempo en espera"),
            new CancelOption(2, "No necesito la atención, estaba probando la plataforma"),
            new CancelOption(3, "Deseo tener atención presencial"),
            new CancelOption(4, "Lo resolví de otra forma"),
            new CancelOption(5, "Ya me siento mucho mejor, no necesito la atención"),
            new CancelOption(6, "Otro motivo", true),
        ];
    }

    ngOnInit(): void {
    }

    public cancel() {
        this.close(false);
    }

    public close(value:any) {
        this.mdDialogRef.close(value);
    }    

    isSaveEnabled() {
        let reason = this.getSelectedReason();

        return !this.loading && (reason && reason.trim());
    }

    showTextarea() : boolean {
        let option = this.options.find(o => o.id == this.selectionControl.value);

        return option != undefined && option.showTextarea;
    }

    getSelectedReason() : string {
        let reasonId = this.selectionControl.value;
        let option = this.options.find(o => o.id == reasonId);

        if (!option)
            return "";

        return reasonId == 6? this.reasonText : option.text;
    }

    confirm() {
        this.loading = true;   

        let reason = this.getSelectedReason();
       
        this.virtualAttentionService.cancelVirtualAttention(this.virtualAttention.data.codigoSolicitudPorVerificar, reason).subscribe(r => {            
            if (!r.success) {
                this.showErrorDialog(parseInt(r.errorMessage));

                this.loading = false; 
            }
            else {
                // Send event indicating the chat finished
                this.messageBusService.chatCancelled(new ChatCancelledMessage(reason));

                this.loading = false; 
                this.completed = true;
            }            
        });              
    }

    showErrorDialog(previousState: number) {
        let dialogData = new DialogData();
        let stateText = "";

        switch(previousState) {
            case 100:
                stateText = this.translation.translate("shared.cancelVirtualAppointmentDialog.states.waitingTriage");
                break;
            case 79:
                stateText = this.translation.translate("shared.cancelVirtualAppointmentDialog.states.onRevision");
                break;
            case 99:
                stateText = this.translation.translate("shared.cancelVirtualAppointmentDialog.states.onTriage");
                break;
            case 101:
                stateText = this.translation.translate("shared.cancelVirtualAppointmentDialog.states.waitingAlly");
                break;
            case 24:
                stateText = this.translation.translate("shared.cancelVirtualAppointmentDialog.states.waitingDoctor");
                break;
            case 30:
                stateText = this.translation.translate("shared.cancelVirtualAppointmentDialog.states.attention");
                break;
            case 37:
                stateText = this.translation.translate("shared.cancelVirtualAppointmentDialog.states.ended");
                break;
            case 7:
                stateText = this.translation.translate("shared.cancelVirtualAppointmentDialog.states.canceled");
                break;
        }
        
        if (previousState == 37 || previousState == 7) {
            dialogData.message = this.translation.translate("shared.cancelVirtualAppointmentDialog.text11");
        }
        else 
            dialogData.message = this.translation.translate("shared.cancelVirtualAppointmentDialog.text10");

        dialogData.title = this.translation.translate("shared.cancelVirtualAppointmentDialog.text7");
        //dialogData.message = this.translation.translate("shared.cancelVirtualAppointmentDialog.text8") + stateText;
        dialogData.confirmText = this.translation.translate("shared.cancelVirtualAppointmentDialog.text9");   
        dialogData.showHeaderIcon = true;
        dialogData.headerIconClass = "phr-iconset-info";   
        dialogData.showCancelButton = false;

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(accepted => {    
            location.reload();
        });
    }  
}

export class CancelOption {
    id: number;
    text: string;
    showTextarea: boolean;

    constructor(id: number, text: string, showTextarea: boolean = false) {
        this.id = id;
        this.text = text;
        this.showTextarea = showTextarea;
    }
}
