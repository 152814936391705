<div class="my-health-container">
    <div class="header-mobile">
        <img class="phr-logo" src="/assets/images/cliniweb/logo-cliniweb-phr.svg">
    </div>
    <div class="title1">{{ 'shared.myHealth.title' | translate:locale.language }}</div>
    <mat-tab-group #tabGroup  [ngClass]="{'add-family-tab': dependents.length == 0, 'mobile' : platform.ANDROID || platform.IOS }"
        [(selectedIndex)]="selectedTabIndex"
        (selectedTabChange)="setTabSelected($event)">
        <mat-tab *ngIf="user">
            <ng-template mat-tab-label>
                <div class="avatar-container">
                    <div class="avatar-img" *ngIf="user.ubicacionLogo" [ngStyle]="{backgroundImage:'url(' + user.ubicacionLogo + ')'}"></div>                
                </div>
                <div class="name-container">
                    <span class="name">{{user.nombre}}</span>
                    <span class="relationship">{{'shared.myHealth.relationship1' | translate:locale.language}}</span>
                </div>
            </ng-template>
            <div class="link-content">
                <mat-card *ngFor="let item of items" role="button" (click)="onLinkSelected(item)">            
                    <mat-card-content>
                        <div class="left-icon">
                            <mat-icon matListIcon *ngIf="item.nombreIcono" fontSet="phr-iconset" [ngClass]=" 'phr-iconset-' +  item.nombreIcono"></mat-icon>
                        </div>
                        <div class="center-content">
                            <div class="title">{{item.texto}}</div>
                            <div class="body">{{item.descripcion}}</div>
                        </div>
                        <div class="right-icon">
                            <mat-icon matListIcon fontSet="phr-iconset" class="phr-iconset-PHR-Volver"></mat-icon>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-tab>
        <mat-tab *ngFor="let dependent of dependents; let i = index">
            <ng-template mat-tab-label>
                <div class="avatar-container">
                    <div class="avatar-img" *ngIf="dependent.ubicacionLogo" [ngStyle]="{backgroundImage:'url(' + dependent.ubicacionLogo + ')'}"></div>                
                </div>
                <div class="name-container">
                    <span class="name">{{dependent.nombre.toLowerCase()}}</span>
                    <span class="relationship">{{'shared.myHealth.relationship2' | translate:locale.language: { relacion: dependent.relationshipName } }}</span>
                </div>
            </ng-template>
            <div class="link-content">
                <mat-card *ngFor="let item of items" role="button" (click)="onLinkSelected(item, dependent.idPersona)">            
                    <mat-card-content>
                        <div class="left-icon">
                            <mat-icon matListIcon *ngIf="item.nombreIcono" fontSet="phr-iconset" [ngClass]=" 'phr-iconset-' +  item.nombreIcono"></mat-icon>
                        </div>
                        <div class="center-content">
                            <div class="title">{{item.texto}}</div>
                            <div class="body">{{item.descripcion}}</div>
                        </div>
                        <div class="right-icon">
                            <mat-icon matListIcon fontSet="phr-iconset" class="phr-iconset-PHR-Volver"></mat-icon>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-tab>
        <mat-tab *ngIf="!loading">
            <ng-template mat-tab-label>
                <div class="add-family" (click)="onAddFamily($event)">
                    <div class="icon-container">
                        <mat-icon matListIcon fontSet="phr-iconset" class="phr-iconset-plus-heavier"></mat-icon>
                    </div>
                    <span class="add-text">{{'shared.myHealth.addFamily' | translate:locale.language}}</span>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>