export class ProfileFilterSearchResult {
    tc: number;
    n_tc: string;
    conceptos: ProfileFilterSearchResultConcept[];
}

export class ProfileFilterSearchResultConcept {
    cc: number;
    n_cc: string;
    num: number;

    constructor(){
        this.cc = 0;        
    }
}

export class ProfileFilterSearchResultGroup {
    titulo: string;
    tooltip: string;
    idsConceptosClinicos: number[];
    results: ProfileFilterSearchResultConcept[];
}