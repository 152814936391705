import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { AuthService } from 'src/app/core/services/auth.service';
import { BaseService } from 'src/app/core/services/base.service';
import { LayoutService } from 'src/app/core/services/layout.service';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { UserService } from 'src/app/core/services/user.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { Direction } from 'src/app/shared/enums/direction.enum';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { MenuMobileComponent } from '../../dialogs/menu-mobile/menu-mobile.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header-sura',
  templateUrl: './header-sura.component.html',
  styleUrls: ['./header-sura.component.css']
})
@ComponentLookup('HeaderSuraComponent')
export class HeaderSuraComponent implements OnInit, OnDestroy, IControlComponent {
  data: FormComponentData;

  showHeaderSmall:boolean = false;
  lastHeaderChangeTime : Date = new Date();
  hideHeader = false;
  urlCompany:string;
  userLoggedIn = false;

  private ngUnsubscribe = new Subject();

  constructor(
    private baseService:BaseService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private layoutService: LayoutService,
    public dialog: MatDialog,
    private router: Router,
    private userService: UserService,
    private authService: AuthService,    
    private messageBusService: MessageBusService,   
    private route : ActivatedRoute
  ) {

    this.layoutService.scrollingBodySource$
    .subscribe((dir:Direction)=>{      
      let showSmall =  dir === Direction.Down;

      if (showSmall != this.showHeaderSmall) {
          let timeDiff = (new Date().getTime() - this.lastHeaderChangeTime.getTime());

          // This will prevent a case in wich when header show/hide the scroll change and the animation enter in a loop
          if (timeDiff >= 500)
            this.lastHeaderChangeTime = new Date();
          else
            return;
      }

      this.showHeaderSmall = showSmall;
    });
  }

  ngOnInit(): void {
    this.urlCompany = this.baseService.getUrlCompany();

    // Get QS parameters
    this.route.queryParams
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(params => {
        if (params && params.hideHeader === "true")
          this.hideHeader = true;
    });

    if (this.authService.isLoggedIn()) {
      this.userService.getUserPersonLoggedIn()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(p => {
        if (p)
          this.userLoggedIn = true;
      });
    }

    // On SSO when the ngOnInit the user is not yet logged in, so for those cases we will subscribe to this event
    this.messageBusService.onLoginCompleted()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(msg => {
      this.userLoggedIn = true;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openMenuMobile(){
    const dialogRef = this.dialog.open(MenuMobileComponent, {
      panelClass: "menu-mobile"
    });
  }

  goToHome() {
    this.router.navigate(['/'], { });
  }
}
