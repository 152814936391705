import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, InjectionToken, LOCALE_ID, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoginModule } from './modules/login/login.module';
import { HomeModule } from './modules/home/home.module';
import { SupportModule } from './modules/support/support.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { L10nIntlModule, L10nLoader, L10nRoutingModule, L10nTranslationModule, L10nValidationModule } from 'angular-l10n';
import { AppStorage, HttpTranslationLoader, initL10n, l10nConfig, LocaleValidation } from './core/l10n-config';
import { appInitializer } from './core/helpers/app.initializer';
import { BaseService } from './core/services/base.service';
import { LoadingInterceptor } from './core/interceptors/loading.interceptor';
import { suraTheme } from 'src/themes/sura/sura';
import { cliniwebTheme } from 'src/themes/cliniweb/cliniweb';
import { THEMES } from 'src/themes/theme';
import { PrivacyPoliciesModule } from './modules/privacy-policies/privacy-policies.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorI18nService } from './core/services/mat-paginator-i18n.service';
import { mapfrePanamaTheme } from 'src/themes/mapfre-panama/mapfre-panama';
import { mapfreGuatemalaTheme } from 'src/themes/mapfre-guatemala/mapfre-guatemala';
import { mapfrePanamaAsistenciaTheme } from 'src/themes/mapfre-panama-asistencia/mapfre-panama-asistencia';
import { RouteLoaderService } from './core/services/route-loader.service';
import { MessageBusService } from './core/services/message-bus.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { townCenterTheme } from 'src/themes/town-center/town-center';
import { hospitalPaitillaTheme } from 'src/themes/hospital-paitilla/hospital-paitilla';
import { ServiceWorkerModule } from '@angular/service-worker';
import localeEsES from '@angular/common/locales/es';
import localeEnUS from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import { WidgetModule } from './modules/widget/widget.module';
import { AuthService } from './core/services/auth.service';
import { TerritorialDivisionsService } from './core/services/territorial-divisions.service';
import { OidcWorkflowInterceptor } from './core/interceptors/oidc-workflow.interceptor';
import { pacificaSaludPhrTheme } from 'src/themes/pacifica-salud-phr/pacifica-salud-phr';
import { hsantafePhrTheme } from 'src/themes/hsantafe-phr/hsantafe-phr';
import { GlobalErrorHandler } from './core/handlers/global-error.handler';
import { environment } from 'src/environments/environment';

registerLocaleData(localeEsES, 'es');
registerLocaleData(localeEsES, 'es-ES');
registerLocaleData(localeEnUS, 'en-US');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    CoreModule,
    LoginModule,
    HomeModule,
    SupportModule,
    WidgetModule,
    SharedModule,
    PrivacyPoliciesModule,
    L10nTranslationModule.forRoot(
      l10nConfig,
      {
          storage: AppStorage,
          translationLoader: HttpTranslationLoader
      }
    ),
    L10nIntlModule,
    L10nValidationModule.forRoot({ validation: LocaleValidation }),
    L10nRoutingModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {enabled: true})
  ],
  providers: [
    //{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: OidcWorkflowInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initL10n,
      deps: [L10nLoader],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [AuthService, BaseService, RouteLoaderService, MessageBusService, TerritorialDivisionsService],
      multi: true
    },
    {
      provide: THEMES,
      useValue: [
        cliniwebTheme,
        suraTheme,
        cliniwebTheme,
        mapfrePanamaTheme, 
        mapfreGuatemalaTheme, 
        mapfrePanamaAsistenciaTheme,
        townCenterTheme,
        hospitalPaitillaTheme,
        pacificaSaludPhrTheme,
        hsantafePhrTheme
      ]
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService,
    },
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {provide: LOCALE_ID, useValue: 'es' },
    {provide: LOCALE_ID, useValue: 'es-ES' },
    {provide: LOCALE_ID, useValue: 'en-US' },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    MessageBusService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }