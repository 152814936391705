import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { PublicProfileType } from 'src/app/shared/models/people/profile-search-results.model';
import { ProfileFilterSearchResult, ProfileFilterSearchResultConcept } from 'src/app/shared/models/people/profile-filter-search-result.model';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { MessageBusService, ProviderSearchMessage } from 'src/app/core/services/message-bus.service';
import { BaseService } from 'src/app/core/services/base.service';
import { LayoutService } from 'src/app/core/services/layout.service';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { TextLanguage } from 'src/app/shared/models/people/text-language.model';
import { ProviderFilterModel, ProviderFilterModelItem } from '../provider-filters-content/provider-filters-content.component';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-provider-filters-body',
  templateUrl: './provider-filters-body.component.html',
  styleUrls: ['./provider-filters-body.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProviderFiltersBodyComponent implements OnInit, OnChanges, OnDestroy {
  
  filterSelectedCount:number = 0;
  
  //subscribes
  private ngUnsubscribe = new Subject();

  @Input() filterModel: ProviderFilterModel;
  @Input() publicProfileTypes:PublicProfileType[] ;
  @Input() providerSearchMessage: ProviderSearchMessage;

  private onChanges = new Subject<SimpleChanges>();

  constructor(
    public platform: Platform,
    private baseService: BaseService,
    private layoutService: LayoutService,
    private messageBusService: MessageBusService,
    private publicProfileService: PublicProfileService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translation: L10nTranslationService
  ) { }

  ngOnChanges(changes: SimpleChanges){
    this.onChanges.next(changes);
  }

  ngOnInit(): void {
    this.onChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data:SimpleChanges)=>{
        this.filterModel.clear();
      });

    this.messageBusService.onClearProviderFilters()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(()=>{
        this.clearAll();
      });
  }

  ngOnDestroy():void{
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  clearAll(){
    let conceptIds = this.getFiltersIds();

    this.fireFilterEvent(conceptIds);

    this.filterModel.clear();
  }

  filterTrackBy(index : number, item: ProviderFilterModelItem){
    return item.clinicConceptType; 
  }

  loadFilters(item: ProviderFilterModelItem, prefilters:number[] = []) {
    let searchText = this.providerSearchMessage.searchText;
    let conceptType = "";
    let concept = "";

    let filtersString = this.getFiltersIds().join(',');

    let accountName = this.baseService.getCliniwebDirectoryCompanyName();
    let accountId = this.baseService.getCliniwebDirectoryCompanyId();
    let locationCompanyIds = this.baseService.getCliniwebDirectoryLocationCompanyIds();        

    let location = this.baseService.getLocationFromIdentifier(this.providerSearchMessage.territorialDivisionIdentification);

    if(!location.country && this.providerSearchMessage.countryIdentification){
      location.country = this.providerSearchMessage.countryIdentification;
    }

    if (!accountName)
        accountName = this.baseService.getCompanyName();

    if (this.providerSearchMessage.conceptNav) {
        // Some time the navigation contains '/' like: '/especialidad/cardiologia' in those cases filter the empty strings after split
        let parts = this.providerSearchMessage.conceptNav.split("/").filter(e => e);
        conceptType = parts[0];
        concept = parts[1];
    }

    item.loading = true;

    return this.publicProfileService.getPublicProfileFilters(
      this.baseService.getLanguage(),
      conceptType,
      searchText, 
      concept,
      filtersString,
      accountName,
      location.country,
      location.state,
      location.city,
      item.clinicConceptType,
      this.publicProfileTypes,
      accountId,
      locationCompanyIds,
      this.providerSearchMessage.conceptId,
      this.providerSearchMessage.conceptClaseId
      ).pipe(
        map((result:ProfileFilterSearchResult[]) =>{
          let resultItem = result[0];

          if (resultItem) {
            // Mark the items as loaded
            item.loadOption(resultItem.conceptos, prefilters);
          }
          else{
            item.loadOption([], prefilters);
          }

          item.loading = false;

          return true;
        }),
        catchError((err) => {
          this.baseService.handleServiceError(err, "Error getting profile filters");          
          return of(false);
        })
      );
  }

  getFiltersIds(){
    let filterByParams = this.providerSearchMessage.filtros 
      ? this.providerSearchMessage.filtros.split(',').map(f => +f)
      : [];

    let filterSelected = this.filterModel.getSelectedConcepts();

    let filterIds = filterByParams.concat(filterSelected);

    filterIds = filterIds.filter((item, index)=> { return (filterIds.indexOf(item) == index)});

    return filterIds;
  }

  onFilterItemClick(item: ProviderFilterModelItem) {
    item.onClick();

    this.messageBusService.closeCheckAutocomplete(item.controlId);

    if (item.needToLoadOptions()){
      this.loadFilters(item)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result =>{
        this.filterSelectedCount = this.getFiltersIds().length;
      });
    }

    if (item.needToFireSearchOnClick()){
      let conceptDeleted = item.active ? null : [item.clinicConceptId];
      this.fireFilterEvent(conceptDeleted);
      this.filterSelectedCount = this.getFiltersIds().length;
    }   
  }

  fireFilterEvent(conceptsDeleted:number[] | null = null) {

    let conceptIds = this.getFiltersIds();

    if(conceptsDeleted){
      conceptIds = conceptIds.filter(c=> conceptsDeleted.indexOf(c) === -1);
    }

    this.providerSearchMessage.filtros = conceptIds.join(',');

    this.messageBusService.providerSearch(this.providerSearchMessage);

    this.filterModel.items.forEach(_item => {_item.loaded = false;});
  }

  getNameI18n(name: TextLanguage[]){
    return this.layoutService.getTextByLanguage(name, this.locale.language);
  }

  getIdAttributeName(){
    return Object.getOwnPropertyNames(new ProfileFilterSearchResultConcept())[0];
  }

  onOptionChanged(optionsDeleted: ProfileFilterSearchResultConcept[] | null, item: ProviderFilterModelItem) {

    if(optionsDeleted && optionsDeleted?.length > 0)
      item.selectedConcepts = item.selectedConcepts.filter(concept => !optionsDeleted.find(conceptDeleted=> conceptDeleted.cc == concept.cc));
    
    this.fireFilterEvent(optionsDeleted ? optionsDeleted.map(o=> o.cc) : null);

    this.loadFilters(item)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result =>{
        this.filterSelectedCount = this.getFiltersIds().length;
      });
  }

  getFilterMobileClassName(){
    return {
      'selected': this.filterSelectedCount > 0 && this.filterModel.getSelectedConcepts().length > 0
    }
  }

  getFilterMobileText(){
    let filterText = this.translation.translate('shared.searchResults.providerFilters.filters');
    return this.filterSelectedCount > 0 && this.filterModel.getSelectedConcepts().length > 0
      ? `${filterText} (${this.filterModel.getSelectedConcepts().length})`
      : filterText;
  }

  isMobile(){
    return this.platform.ANDROID || this.platform.IOS;
  }
}
