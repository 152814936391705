<div class="future-appointment-cards-container" >
    
    <div class="future-appointment-cards skeleton-cards" fxLayout="row wrap" fxLayoutAlign="space-between center" *ngIf="loading">
        <mat-card  class="future-appointment-card" *ngFor="let sk of skeletonItems" [ngClass]="{'single-item': pageSize == 1}">            
            <mat-card-content>
                <div class="future-app-date">
                    <ngx-skeleton-loader [theme]="{'width': '45%', 'height': '14px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
                </div>
                <div class="future-app-location truncate">
                    <div>
                        <ngx-skeleton-loader [theme]="{'width': '35%', 'height': '14px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
                    </div>
                    <div style="margin-top: -5px;">
                        <ngx-skeleton-loader [theme]="{'width': '70%', 'height': '14px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
                    </div>
                </div>
                <div class="future-app-doctor-container">
                    <span class="skeleton-img">
                        <ngx-skeleton-loader [theme]="{'width': '70px', 'height': '70px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '50%'}"></ngx-skeleton-loader>        
                    </span>                    
                    <div class="future-app-doctor-right-container">
                        <div class="future-app-doctor-specialty truncate">
                            <ngx-skeleton-loader [theme]="{'width': '40%', 'height': '14px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
                        </div>
                        <div class="future-app-doctor-name">
                            <ngx-skeleton-loader [theme]="{'width': '80%', 'height': '14px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
                        </div>                                       
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    
    <div class="future-appointment-cards" fxLayout="row wrap" fxLayoutAlign="space-between center" *ngIf="!loading">
        <mat-card class="future-appointment-card" *ngFor="let app of pageAppointments; index as i; trackBy: appointmentTrackBy" [ngClass]="{'single-item': pageSize == 1, 'cancelled': app.idEstado == 7}">        
            <mat-card-header>
                <mat-card-title *ngIf="app.idEstado != 7">{{ 'shared.futureAppointments.cardTitle' | translate:locale.language }} {{getAppointmentRemainingTime(app)}}</mat-card-title>
                <mat-card-title *ngIf="app.idEstado == 7">{{ 'cancelled' | translate:locale.language | titlecase }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="future-app-date">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-citas-medicas"></mat-icon>
                    <span>{{getAppointmentDateStr(app.fechaRequerido)}} - {{app.fechaRequerido | date:'H:mm' }}</span>
                </div>
                <div class="future-app-location truncate" *ngIf="!isTeleconsultation(app)">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-place"></mat-icon>                    
                    <span>{{getLocationDescription(app)}}</span>
                </div>
                <div class="future-app-location teleconsultation truncate" *ngIf="isTeleconsultation(app)">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-videoconsulta"></mat-icon>
                    <span>{{ 'shared.futureAppointments.text9' | translate:locale.language }} 
                        <a target="_blank" [href]="buildTeleconsultationLink(app)">{{ 'shared.futureAppointments.text10' | translate:locale.language }}</a>
                    </span>                    
                </div>
                
                <a *ngIf="app.idEstado != 7 && !isAppointmentExpired(app)" class="future-app-cancel-action" (click)="openCancelAppointmentDialog(app)">{{ 'cancel' | translate:locale.language }}</a>
                <a *ngIf="app.idEstado == 7" class="future-app-cancel-action" (click)="onReprogramAppointment(app)">{{ 'shared.futureAppointments.text7' | translate:locale.language }}</a>
                
                <div class="future-app-doctor-container">
                    <img class="future-app-doctor-img" [src]="getAvatarUrl(app)"/>
                    <div class="future-app-doctor-right-container">
                        <div class="future-app-doctor-specialty truncate">{{app.especialidadResponsableServicio}}</div>
                        <div class="future-app-doctor-name">
                            {{app.nombreResponsableServicio}} 
                            <span *ngIf="app.sedeResponsable">{{ 'shared.futureAppointments.text1' | translate:locale.language }}</span>
                        </div>
                        <div class="future-app-frequent-doctor">
                            {{ 'shared.futureAppointments.text2' | translate:locale.language }}
                        </div>                    
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-paginator [pageSize]="pageSize" [length]="resultsCount" (page)="onPageChange($event)" [hidePageSize]="true" class="future-app-paginator" *ngIf="appointments && appointments.length > 0">
        </mat-paginator>
    </div>
          
</div>
    
