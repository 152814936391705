<div class="medical-order-workflow-container" *ngIf="!showState">
    <app-logout-bar [closeFn]="logoutFn"></app-logout-bar>
    <app-workflow-progress-bar [model]="progressBarModel"></app-workflow-progress-bar>
    <img *ngIf="!progressBarModel.show" class="phr-logo" src="/assets/images/cliniweb/logo-cliniweb-phr.svg">
    <div class="workflow-content" *ngIf="showWorkflowContent()">
        <app-form [idAreaSistema]="getIdAreaSistema()" [formConfigurationData]="formConfigurationData"></app-form>
    </div>
</div>
<div class="states-container" *ngIf="showState">
    <app-states-request-verify [model]="statesRequestVerifyDataModel"></app-states-request-verify>
</div>

<app-spinner *ngIf="checkingStep"></app-spinner>