import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup.decorator';
import { L10nLocale, L10N_LOCALE, L10nTranslationService } from 'angular-l10n';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { PatientAppointment } from 'src/app/shared/models/process/appointment.model';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { MatDialog } from '@angular/material/dialog';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { BaseService } from 'src/app/core/services/base.service';
import { Directory } from 'src/app/shared/models/systems/directory.model';
import { UserService } from 'src/app/core/services/user.service';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Dependent } from 'src/app/shared/models/people/dependent.model';
import { TimelineWrapperItem, TimelineWrapperItemType } from '../../timeline-list/timeline-list.component';
import { SearchProvidersService } from 'src/app/core/services/search-providers.service';

@Component({
  selector: 'app-cliniweb-past-appointment',
  templateUrl: './cliniweb-past-appointments.component.html',
  styleUrls: ['./cliniweb-past-appointments.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('CliniwebPastAppointmentsComponent')
export class CliniwebPastAppointmentsComponent implements OnInit, OnDestroy, IControlComponent {
  data: FormComponentData;  
  appointments : CliniwebTimelinePatientAppointment[];    
  inProgressState: string;  
  loading = true;
  skeletonItems: string[] = ["", ""];
  directory: Directory;  
  dependents: Dependent[];  
  loggedInUser: UserPerson;

  private ngUnsubscribe = new Subject();

  constructor(
    private appointmentService: AppointmentService, 
    @Inject(L10N_LOCALE) public locale: L10nLocale, 
    private translation: L10nTranslationService,
    public dialog: MatDialog,
    private messageBusService: MessageBusService,
    private baseService: BaseService,
    private userService: UserService,
    private searchProvidersService: SearchProvidersService) {
  }

  ngOnInit(): void {
    let observables: Observable<any>[] = [];
    
    this.loading = true;

    this.messageBusService.onAppointmentChangeReceived()
    .subscribe(appointments => {
      if (appointments.citasFuturas) {
        this.appointments = appointments.citasPasadas.map(app => new CliniwebTimelinePatientAppointment(app));
      }
      this.loading = false;
    });   

    observables.push(this.getDirectory());
    observables.push(this.getUser());
    observables.push(this.getDependents());
    observables.push(this.getAppointments());

    forkJoin(observables).subscribe(results => { 
      this.directory = results[0] as Directory;
      this.loggedInUser = results[1] as UserPerson;
      this.dependents = results[2] as Dependent[];

      let appointments = results[3] as PatientAppointment[];
      this.appointments = appointments.map(app => new CliniwebTimelinePatientAppointment(app));

      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getDirectory() : Observable<Directory> {
    let companyId = this.baseService.getCliniwebDirectoryCompanyId();

    return this.baseService.getCliniwebDirectory(companyId);
  }

  getUser() : Observable<UserPerson | null> {
    return this.userService.getUserPersonLoggedIn().pipe(
      tap(() => {
        
      }),
      catchError((e => {        
        this.baseService.handleServiceError(e, "Error getting user"); 
        
        return of(null);
      })));
  }

  getDependents() : Observable<Dependent[]>{
    return this.userService.getUserDependents().pipe(
      tap(() => {
        
      }),
      catchError((e => {        
        this.baseService.handleServiceError(e, "Error getting dependents"); 
        
        return of([]);
      })));
  }

  getAppointments() : Observable<PatientAppointment[]> {
    this.loading = true;

    return this.appointmentService.getPastAppointments(this.locale.language).pipe(
      tap(() => {
        
      }),
      catchError((e => {
        this.baseService.handleServiceError(e, "Error getting appointments");  
        
        return of([]);
      })));
  }    

  appointmentTrackBy(index : number, item: PatientAppointment){
    return item.idSolicitud; 
  }  

  getAppointmentsToShow() {
    return this.appointments.map(a => a.appointment);
  } 
  
  onNewAppointmentClick() {
    this.searchProvidersService.openSearch();
  }
}

export class CliniwebTimelinePatientAppointment implements TimelineWrapperItem {  
  appointment : PatientAppointment;

  constructor(appointment : PatientAppointment) {
    this.appointment = appointment;
  }
  
  getGroupKey() : number {
    return new Date(this.appointment.fechaInicio).getFullYear();
  }

  getDate() : Date {    
    return new Date(this.appointment.fechaInicio);
  }

  getItemType(): TimelineWrapperItemType{
    let type : TimelineWrapperItemType;

    /*return this.appointment.idEstado == 7 || this.appointment.idEstado == 28 
      ? TimelineWrapperItemType.CANCELLED
      : TimelineWrapperItemType.CHECKED;*/

    if (this.appointment.idEstado == 7 || this.appointment.idEstado == 28)
      type = TimelineWrapperItemType.CANCELLED   
    else 
      type = TimelineWrapperItemType.CHECKED;

    return type;
  }

  showTime():boolean{
    return false;
  }

  showDate():boolean{
    return false;
  }
}


