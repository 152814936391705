import { Injectable } from "@angular/core";
import { HttpBaseService } from "./http-base-service";
import { Observable } from "rxjs";
import { GrowthMeasurements } from "src/app/shared/models/clinic-history/growth-measurements.model";
import { GrowthPercentile } from "src/app/shared/models/clinic-history/growth-percentile.model";
import { ChartType, GrowthMeasurementType } from "src/app/shared/models/clinic-history/growth-measurement-type.model";

@Injectable({
    providedIn: 'root'
})
export class GrowthMeasurementsService{

    constructor(
        private http: HttpBaseService
    ) { }

    getByPatientId(idPaciente:number, idEmpresa: number, idResponsableServicio: number, idTipoConcepto?:number): Observable<GrowthMeasurements>{
        let url = `api/mediciones-crecimiento/paciente/${idPaciente}`;
        url += `?idEmpresa=${idEmpresa}`;
        url += `&idResponsableServicio=${idResponsableServicio}`;

        if(idTipoConcepto && idTipoConcepto > 0)
            url += `&idTipoConcepto=${idTipoConcepto}`;

        return this.http.get<GrowthMeasurements>(url);
    }

    getPercentiles(
        idEstandarMedicion: number, 
        idTipoMedicion:number, 
        sexo:string, 
        rangoInicial: number, 
        rangoFinal: number, 
        idUnidadMedidaRango:number): Observable<GrowthPercentile[]>{
        let url = `api/mediciones-crecimiento/percentiles`
        url += `?idEstandarMedicion=${idEstandarMedicion}`;
        url+=`&idTipoMedicion=${idTipoMedicion}`;
        url+=`&sexo=${sexo}`;
        url+=`&rangoInicial=${rangoInicial}`;
        url+=`&rangoFinal=${rangoFinal}`;
        url+=`&idUnidadMedidaRango=${idUnidadMedidaRango}`;

        return this.http.get<GrowthPercentile[]>(url);
    }

    getMeasurementTypes(): Observable<GrowthMeasurementType[]>{
        return this.http.get<GrowthMeasurementType[]>("api/mediciones-crecimiento/tipos");
    }

    getHcaFieldByType(chartType:ChartType):string{
        switch(chartType){
            case ChartType.SIZE_FOR_AGE:
            default:
                return 'TallaEdad';
            case ChartType.WEIGHT_FOR_AGE:
                return 'PesoEdad';
            case ChartType.WEIGHT_FOR_SIZE:
                return 'PesoTalla';
            case ChartType.BMI_FOR_AGE:
                return 'IMCEdad';
            case ChartType.HEAD_CIRCUMFERENCE_FOR_AGE:
                return 'CirunferenciaCefalicaEdad';
        }
    }

    getIntervaleByRangeAndType(rangeId: number, typeId:number){
    }
}