import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Link } from 'src/app/shared/models/people/link.model';
import { AppStorageService, STORAGE } from '../services/app-storage.service';
import { BaseService } from '../services/base.service';
import { LayoutService } from '../services/layout.service';

@Injectable({
  providedIn: 'root'
})
export class MenuGuard implements CanActivate {

  constructor(
    private baseService: BaseService,
    private layoutService: LayoutService,
    private router: Router,
    private appStorageService: AppStorageService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      let menu = this.appStorageService.getItemSession(STORAGE.MENU, true);
      if(menu && menu.length > 0){
        return this.canGo(menu, state.url);
      }
      else{
        return this.layoutService.getMenuLinks().pipe(
          map((links)=>{
            this.appStorageService.setItemSession(STORAGE.MENU, links, true);

            return this.canGo(links, state.url);
          }),
          catchError((err) => {
            this.router.navigate(['/not-found']);
            return of(false);
          })
        );
      }
  }
  
  canGo(links: Link[], path: string): boolean {
    path = path.replace("/", "").split("/")[0].split('?')[0];
    if(path == ''){
      return true;
    }
    else {
      let linkSelected = links.find(link => this.baseService.getBasePath(link.identificadorUrl) == decodeURIComponent(path));

      let sublinkSelected = false;

      links.forEach(link => {

        let sublink = link.subMenu 
          ? link.subMenu.find(link => this.baseService.getBasePath(link.identificadorUrl) == decodeURIComponent(path))
          : null;

        if(sublink)
          sublinkSelected = true;
      });

      if(linkSelected || sublinkSelected || path == "empty") {
        return true;
      } else{
        this.router.navigate(['/not-found']);
        return false;
      }
    }
  }
}
