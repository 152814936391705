<div class="public-profile-avatar-container" *ngIf="model" [ngClass]="{'not-avatar-padding': !model.useAvatarPadding}">

    <!-- New implementation -->
    <span *ngIf="!legacyAvatar">
        <!-- Doctor -->
        <span class="doc-avatar-container" *ngIf="model.profileType == profileType.Doctor">
                
            <span class="profile-avatar-container" [ngClass]="{'without-border': this.withoutBorder}" [style.color]="profileInitialsColor">
                <img [src]="model.avatarUrl" *ngIf="model.avatarUrl">
                <span *ngIf="!model.avatarUrl">
                    <span class="default-avatar" *ngIf="model.sex == 'm'">
                        <img src="assets/images/avatars/profile_male.svg"/>
                    </span>
                    <span class="default-avatar" *ngIf="model.sex == 'f'">
                        <img src="assets/images/avatars/profile_female.svg"/>
                    </span>
                    <span class="default-avatar" *ngIf="model.sex != 'm' && model.sex != 'f'">
                        <img src="assets/images/avatars/profile_no_gender.svg"/>
                    </span>
                </span>
            </span>

        </span>

        <!-- Not doctor -->
        <span class="not-doc-avatar-container" *ngIf="model.profileType != profileType.Doctor">
            <span class="profile-avatar-container" [style.border-color]="profileInitialsColor" *ngIf="model.avatarUrl">
                <img [src]="model.avatarUrl" *ngIf="model.avatarUrl">
            </span>        
            <span class="icon-avatar-container" *ngIf="!model.avatarUrl" [style.border-color]="profileInitialsColor">
                <mat-icon fontSet="phr-iconset" [ngClass]="profileTypeIconClassName"></mat-icon>
            </span>
        </span>
    </span>
   
    <!-- Legacy implementation -->
    <span class="legacy-avatar" *ngIf="legacyAvatar">        
        <app-avatar-initials class="initials-container" *ngIf="model.avatarUrl || model.profileType == profileType.Doctor"
            [url]="model.avatarUrl"
            [text]="model.fullName"
            [id]="model.personId">
        </app-avatar-initials>
        <div class="avatar-icon" *ngIf="!(model.avatarUrl || model.profileType == profileType.Doctor)">
            <mat-icon fontSet="phr-iconset" [ngClass]="profileTypeIconClassName"></mat-icon>
        </div>        
    </span>

</div>