<div class="health-allies-container">
    <mat-card class="card">
        
        <mat-card-header>
            <mat-card-title>
                {{ 'shared.healthAllies.text1' | translate:locale.language }}
            </mat-card-title>
            <img src="assets/images/cliniweb/corazon.png">           
        </mat-card-header>
        
        <mat-card-content>
            <div class="item-container">
                <div *ngFor="let ally of healthAllies" class="item" (click)="navigateToSearch(ally.id)">
                    <img [src]="ally.logo" alt="ally.title | translate:locale.language">
                    <div class="text-container">
                        <span class="title">{{ ally.title | translate:locale.language }}</span>
                        <span class="sub-title">{{ ally.subTitle | translate:locale.language }}</span>
                    </div>
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-right-arrow icon phr-iconset"></mat-icon>
                </div>
            </div>            
        </mat-card-content>
    </mat-card>
</div>