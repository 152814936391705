import { Component, HostBinding, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { ProfileLocationResultModel } from 'src/app/shared/models/people/profile-search-results.model';

@Component({
  selector: 'app-provider-card-contacts',
  templateUrl: './provider-card-contacts.component.html',
  styleUrls: ['./provider-card-contacts.component.css']
})
export class ProviderCardContactsComponent implements OnChanges {

  @HostBinding('style.margin-top') flexGrow = 'auto';
  @Input() location: ProfileLocationResultModel;
  @Input() isDoctorProfile = true;

  locations: any[];

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.location) {
      this.mapObjetoToArray();
    }
  }

  private mapObjetoToArray() {
    this.locations = [
      {
        icon: 'whatsapp',
        value: this.location.objetoDetalle1
      },
      {
        icon: 'PHR-phone',
        value: this.location.objetoDetalle2
      },
      {
        icon: 'PHR-mail',
        value: this.location.objetoDetalle4
      },
    ].filter(x => x.value);
  }

}
