export const Debounce = (delay: number = 300): any => {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
      let timeout: number | null | undefined =  null;
  
      const original = descriptor.value;
  
      descriptor.value = function (...args: any) {
        if(timeout)
            clearTimeout(timeout);
        timeout = window.setTimeout(() => original.apply(this, args), delay);
      };
  
      return descriptor;
    };
}