import { BaseService } from "../services/base.service";
import { MessageBusService, ServiceErrorMessage } from "../services/message-bus.service";
import { RouteLoaderService } from "../services/route-loader.service";
import { AuthService } from "../services/auth.service";
import { take } from "rxjs/operators";
import { TerritorialDivisionsService } from "../services/territorial-divisions.service";
import { forkJoin } from "rxjs";

export function appInitializer(
    authService: AuthService,
    baseService: BaseService,
    routeLoader: RouteLoaderService,
    messageBusService: MessageBusService,
    territorialDivisionService: TerritorialDivisionsService) {
    return () => new Promise(resolve => {
        baseService.checkVersion();

        const countryObservable = territorialDivisionService.getCountryByIp()
            .pipe(take(1));
        const appConfigObservable = baseService.getAppConfig()
            .pipe(take(1));

        forkJoin([countryObservable, appConfigObservable]).subscribe(([country, results]) => {

            routeLoader.load().subscribe().add(resolve);

            const urlParams = new URLSearchParams(window.location.search);
            const transferToken = urlParams.get('token');

            if (transferToken) {
                authService.loginByTransferToken(transferToken).subscribe().add(resolve);
            }

            console.log("app initializer finished");
        }, error => {
            console.log("app initializer error");
            
            // It is very important to resolve this promise, 
            // if not the initialization process will be interrupted and the app.component (and others) will NOT run the initialize
            resolve("");

            setTimeout(() => {
                messageBusService.serviceError(new ServiceErrorMessage(""));
            }, 100);            
        });
    });
}