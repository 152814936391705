<div class="medical-order-patient-verification-container" *ngIf="!loading">
    <div class="content">
        <!-- Appointment -->
        <div class="chat-bubble">
            <span class="title"  [innerHTML]="titleTextRefence | translate:locale.language | safeHtml"></span>
        </div>
        <app-cola-goblo-dialogo></app-cola-goblo-dialogo>
        <div class="doctor-container">
            <div class="avatar" *ngIf="avatarModel">                
                <app-public-profile-avatar [model]="avatarModel"></app-public-profile-avatar>
            </div>
            <div class="name">{{avatarModel.fullName}}</div>
        </div>
        <form class="form-container" [formGroup]="formulario" (ngSubmit)="enviar()">
            <app-datepicker-flat-field
                [label]="'shared.medicalOrder.patientVerification.text4' | translate:locale.language:{patientName:model.nombrePaciente}"
                [maxDate]="maxDate"
                [minDate]="minDate"
                [errorMessage]="'shared.editDependent.text5' | translate:locale.language"
                formControlName="patientBirthday"
            ></app-datepicker-flat-field>

            <app-login-singup-error-banner [error]="(errorMessage | translate:locale.language) || ''"></app-login-singup-error-banner>

            <div class="button-container">
                <button mat-button mat-flat-button class="primary" type="submit" [ngClass]="{'loading': sending }">
                    {{ 'shared.patientFormAppointmentCheckout.buttonText' | translate:locale.language}}
                </button>
            </div>
        </form>

    </div>
</div>

<app-spinner *ngIf="loading" spinnerText="shared.onlineAppointmentCliniweb.spinnerText"></app-spinner>