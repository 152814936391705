import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base-service';
import { TeleconsultaInfo } from 'src/app/shared/interfaces/teleconsulta-info';

@Injectable({
  providedIn: 'root'
})
export class TeleconsultaService {

  private url = 'api/teleconsultas';

  constructor(private http: HttpBaseService) { }

  getByIdEstado(id: number) {
    return this.http.get<TeleconsultaInfo>(`${this.url}?idEtapaSolicitud=${id}`);
  }

}
