import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';
import { ClinicHistoryService } from 'src/app/core/services/clinic-history.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics.service';
import { ClinicHistoryMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { ClinicHistory } from 'src/app/shared/models/clinic-history/clinic-history.model';
import { ClinicHistoryConfiguration, TabClinicHistoryConfiguration } from 'src/app/shared/models/people/clinic-history-configuration.model';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';

@Component({
  selector: 'app-clinic-history-tabs',
  templateUrl: './clinic-history-tabs.component.html',
  styleUrls: ['./clinic-history-tabs.component.css']
})
@ComponentLookup('ClinicHistoryTabsComponent')
export class ClinicHistoryTabsComponent implements OnInit, OnDestroy, IControlComponent {
  data: FormComponentData;
  tabs: TabClinicHistoryConfiguration[];

  private clinicHistory: ClinicHistory;

  //subscribes
  private ngUnsubscribe = new Subject();
  
  constructor(
    private baseService: BaseService,
    private clinicHistoryService: ClinicHistoryService, 
    private messageBusService: MessageBusService,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject(L10N_LOCALE) public locale: L10nLocale) {

  }

  ngOnInit(): void {

    this.getClinicHistory();
    this.parseControlData();

    this.googleAnalyticsService.sendEventOpenClinicHistory();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSelectTab(){
    // se comenta la actualización de la historia clínica al seleccionar un tab
    // una idea para la segunda instancia es de iniciar la actualización por un mensaje del cloudhub
    // this.getClinicHistory();
  }

  getClinicHistory(){
    this.clinicHistoryService.getClinicHistory()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(clinicHistory =>{
      this.clinicHistory = clinicHistory;

      this.messageBusService.sendClinicHistory(new ClinicHistoryMessage(this.clinicHistory));
    },
    error => {
      this.baseService.handleServiceError(error, "Error getting clinic history");      
    });
  }

  private parseControlData() {
    if(this.data && this.data.valor){
      try {
        this.data.valor = JSON.parse(this.data.valor);       

        let config = <ClinicHistoryConfiguration>this.data.valor;

        this.tabs = config.tabs.map(t => new TabClinicHistoryConfiguration(t));
      } catch (ex) {}  
    }
  }
}
