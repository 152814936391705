import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChatComponent } from 'src/app/shared/components/chat/chat.component';
import { SupportContactComponent } from './pages/support-contact/support-contact.component';

const supportRoutes: Routes = [
  {
    path: 'contactus',
    component: SupportContactComponent
  },
  {
    path: ':supportId',
    component: ChatComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(supportRoutes)],
  exports: [RouterModule]
})

export class SupportRoutingModule { }
