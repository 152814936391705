
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { SystemAreas } from 'src/app/shared/enums/system-areas.enum';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit {

  idAreaSistema:number = SystemAreas.BUSCADOR_WIDGET_PERFILES_PUBLICOS;

  constructor(private route:ActivatedRoute, private messageBusService: MessageBusService) { 

    this.messageBusService.hideHeader();

  }

  ngOnInit(): void {

    if(this.route.snapshot.params.idAreaSistema){
      this.idAreaSistema = this.route.snapshot.params.idAreaSistema;
    }
  }
}
