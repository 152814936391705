import { AbstractControl } from '@angular/forms';

export function CustomEmailValidator(control: AbstractControl) {
    if (control.value) {
        const matches = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
        
        return matches ? null : { 'email': true };
    } 
    else {
        return null;
    }
}