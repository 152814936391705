import { Inject, Injectable } from '@angular/core';
import { User, UserManager } from 'oidc-client';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Token, TokenState } from 'src/app/shared/models/token.model';
import { BaseService } from './base.service';
import { HttpBaseService } from './http-base-service';
import { OidcUserManagerSettings } from 'src/app/shared/models/systems/portal-phr.model';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';

@Injectable({
  providedIn: 'root'
})
export class AuthOidcService {

  private clientOidc: OidcUserManagerSettings | null = null;
  private manager: UserManager;
  private user: User | null = null;
  
  constructor(
    private http: HttpBaseService,
    private baseService: BaseService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translation: L10nTranslationService
  ) {
  }

  initManager(){
    this.clientOidc = this.baseService.getDirectoryClientOidc();

    if(this.clientOidc){
      this.manager = new UserManager(this.clientOidc);

      this.manager.getUser().then(user => {
        this.user = user;
      }, 
      error => {
        this.baseService.handleServiceError(error, "Error getting user info");        
      });
    }
  }

  redirectToAuthOidc(): boolean{
    console.log('redirectToAuthOidc executed!');

    if(!this.clientOidc)
      this.initManager();

    return this.clientOidc != null && this.clientOidc.authority != null && !this.clientOidc.authority?.includes('google');
  }

  existsManager(): boolean {
    return this.manager != null;
  }

  isLoggedIn(): boolean {
    return this.user != null && !this.user.expired;
  }

  startAuthentication(state:string, username: string = ''): Promise<void> {
    const args = {
      data: state,
      extraQueryParams: username ? { login_hint: username } : undefined
    };

    return this.getManager().signinRedirect(args).catch(error => {
      this.baseService.handleServiceError(error, "Error redirecting to login page");      
    });
  }

  completeAuthentication():Observable<Token> {
    return this.requestTokenFromUser(this.getManager().signinRedirectCallback());
  }

  startAuthenticationPopup():Observable<Token>{
    return this.requestTokenFromUser(this.getManager().signinPopup());
  }

  completeAuthenticationPopup():Observable<Token> {
    return this.requestTokenFromUser(this.getManager().signinPopupCallback());
  }

  private requestTokenFromUser(promiseUser: Promise<User | undefined>): Observable<Token>{
    return from(promiseUser)
      .pipe(
        switchMap((user:User|undefined) => {

          if(user){
            this.user = user;

            const clientOidc = this.baseService.getDirectoryClientOidc();

            if(clientOidc){
              return this.baseService.getDirectoryDomain()
              .pipe(switchMap((domain:string)=>{

                const _manager = this.getManager();

                var urlUserInfo = _manager.settings.metadata 
                  ? _manager.settings.metadata.userinfo_endpoint
                  : clientOidc.authority;

                var tokenState = this.parseTokenState(user.state);

                var email = tokenState && tokenState.email ? tokenState.email : '';

                if(email && user.profile && user.profile.email && email != user.profile.email){
                  console.log("el email ingresado es diferente al email del OIDC");
                  const dummyToken = new Token();
                  dummyToken.isInvalid = true;
                  dummyToken.invalidMessage = this.translation.translate("invalidEmailOidc", this.locale.language);
                  dummyToken.state = user.state;
                  return of(dummyToken);
                }                

                const model = {
                  email: email,
                  token: user.access_token,
                  clientAuthority: urlUserInfo,
                  domain: domain,
                  proveedorServicioAutenticacion: clientOidc.authenticationServiceProvider
                }
                
                return this.http.post<Token>("api/authentication/oidc", model, null, false)
                  .pipe(map((token:Token) => {
                    token.state = user.state;
                    return token;
                  }),
                  catchError(err => {
                    console.log("error complete authentication: " + err);

                    if(email && user.profile && user.profile.email && email != user.profile.email){
                      console.log("el email ingresado es diferente al email del OIDC");
                      const dummyToken = new Token();
                      dummyToken.isInvalid = true;
                      dummyToken.invalidMessage = this.translation.translate("invalidEmailOidc", this.locale.language);
                      dummyToken.state = user.state;
                      return of(dummyToken);
                    }
                    else{
                      throw err;
                    }
                  }));
              }));
            }
            else{
              console.log("cliente oidc inexistente");
              const dummyToken = new Token();
              dummyToken.isInvalid = true;
              return of(dummyToken);
            }
          }
          else {
            console.log("usuario inexistente");
            const dummyToken = new Token();
            dummyToken.isInvalid = true;
            return of(dummyToken);
          }
        }),
        catchError(err => {
          console.log("error complete authentication: " + err);
          const dummyToken = new Token();
          dummyToken.isInvalid = true;          
          dummyToken.invalidMessage = err.message;
          this.baseService.handleServiceError(err, "OIDC Error");

          return of(dummyToken);
        })
    );
  }

  private getManager(): UserManager{
    if(this.manager == null){
      this.initManager();
    }

    return this.manager;
  }

  private parseTokenState(state: string): TokenState {
    let tokenState: TokenState;
    
    try {
      tokenState = JSON.parse(state) as TokenState;
    } 
    catch (error) {
      tokenState = new TokenState();
      tokenState.returnUrl = state;
    }

    return tokenState;
  }
}