import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { WorkflowItem } from "../../systems/workflow.model";
import { OnlineAppointmentCliniwebState } from "../states/online-appointment-cliniweb-state.model";
import { AppointmentInfoDataModel } from "../models/appointment-info-data.model";
import { BaseStep } from "./base-step.model";
import { ComponentDataSetMessage, MessageBusService } from "src/app/core/services/message-bus.service";
import { ComponentName } from "src/app/shared/enums/component-name.enum";
import { PersonDataModel, SelectPatientDataModel } from "../models/select-patient-data.model";
import { RequestingPatient, RequestingUser } from "../../process/base-request.model";

@Injectable({
    providedIn: 'root',
})
export class SelectPatientAppointmentCheckoutStep extends BaseStep<OnlineAppointmentCliniwebState> {

    constructor(protected messageBusService: MessageBusService){
        super(messageBusService);
        
        this.showHeader = true;
        this.showButtonBack = true;
        this.showProgressBar = true;
        this.showButtonClose = true;
    }

    setItem(item: WorkflowItem): void {
        this.item = item;
    }

    setState(stateData: any): void {
        this.stateData = new OnlineAppointmentCliniwebState(stateData);
    }

    isCompleted():Observable<boolean>{
        return of(this.relationshipSelected());
    }

    showStepInActionBack(): boolean {
        return true;
    }
    
    showStep(): boolean {
        return true;
    }
    
    protected handleComponentSetDataMessage(message: ComponentDataSetMessage) {
        if (message.componentName == ComponentName.SELECT_PATIENT){

            let _dataModel = message.data as SelectPatientDataModel;

            this.stateData.setPatientDataInitiallyIncomplete(false);

            if(_dataModel.patientSelected && _dataModel.patientSelected.id > 0){
                this.stateData.setPatient(_dataModel.patientSelected);
                this.stateData.setPatientRelationship(_dataModel.relationship);
                this.stateData.setDependentsFlow();
            }
            else{
                this.stateData.setPatient(new PersonDataModel());
                this.stateData.setRelationship(_dataModel.relationship);
            }
        }
    }

    protected buildComponentConfigurationData() : { [componentName: string]: any; } {
        let formConfigurationData : { [componentName: string]: any; } = {};
        
        formConfigurationData[ComponentName.SELECT_PATIENT] = this.getSelectPatientDataModel();

        return formConfigurationData;
    }

    protected messageForThisStep(message: ComponentDataSetMessage): boolean {
        return message.componentName == ComponentName.SELECT_PATIENT;
    }

    private getSelectPatientDataModel():SelectPatientDataModel{
        let dataModel = new SelectPatientDataModel();

        dataModel.appointmentInfo = this.stateData.getAppointmentInfo();
        dataModel.relationship = this.stateData.getRelationship();

        let requestingUser = this.stateData.getRequestingUser();

        dataModel.user = new PersonDataModel();
        dataModel.user.id = requestingUser.id;
        dataModel.user.email = requestingUser.username;
        dataModel.user.firstname = requestingUser.nombre;
        dataModel.user.lastname = requestingUser.apellido;
        dataModel.user.gender = requestingUser.sexo;
        dataModel.user.avatar = requestingUser.urlAvatar;

        if(requestingUser.fechaNacimiento)
            dataModel.user.birthDate = requestingUser.fechaNacimiento;

        let requestingPatient = this.stateData.getRequestingPatient();

        if(requestingPatient){
            dataModel.patientSelected = new PersonDataModel();
            dataModel.patientSelected.id = requestingPatient.id;
            dataModel.patientSelected.firstname = requestingPatient.nombre;
            dataModel.patientSelected.lastname = requestingPatient.apellido;
            dataModel.patientSelected.email = requestingPatient.email;
            dataModel.patientSelected.gender = requestingPatient.sexo;
    
            if(requestingPatient.fechaNacimiento)
                dataModel.patientSelected.birthDate = requestingPatient.fechaNacimiento;
        }

        return dataModel;
    }

    private relationshipSelected():boolean{
        let relationship = this.stateData.getRelationship();

        if(relationship == 1){
            let userId = this.stateData.getUserId();

            this.stateData.setPatientId(userId);
        }

        return relationship > 0;
    }
}

export interface ISelectPatientAppointmentCheckoutWorkflowState{
    getAppointmentInfo():AppointmentInfoDataModel;
    getRelationship(): number;

    getRequestingPatient():RequestingPatient;
    getRequestingUser():RequestingUser;

    setRelationship(relationshipType:number):void;
    setPatientRelationship(patientRelationshipType: number):void;
    setPatient(patient:PersonDataModel):void;
    setPatientId(patientId: number): void;
    setDependentsFlow():void
}
