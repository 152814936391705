import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { MessageBusService } from 'src/app/core/services/message-bus.service';

@Component({
  selector: 'app-states-request-verify',
  templateUrl: './states-request-verify.component.html',
  styleUrls: ['./states-request-verify.component.css']
})
export class StatesRequestVerifyComponent implements OnInit {

  @Input() model: StatesRequestVerifyDataModel;

  constructor(
    private router: Router,
    private messageBusService: MessageBusService,
    @Inject(L10N_LOCALE) public locale: L10nLocale) { }

  ngOnInit(): void {
  }

  gotoHome(){
    if (this.model.buttonSecondaryActionFn) {
      this.model.buttonSecondaryActionFn();
    }
    else {
      this.messageBusService.showHeader();
      this.router.navigate(['/']);
    }    
  }
}

export class StatesRequestVerifyDataModel {
  companyLogoUrl: string;
  mainImageUrl: string;
  textRefI18n: string;
  textDescriptionRefI18n: string;
  buttonActionFn: any;
  buttonSecondaryActionFn: any;
  buttonTextRefI18n: string;
  secondaryButtonTextRefI18n: string;
  showActionButton: boolean;
  showHomeButton: boolean;
}