<div class="location-language-container directory-background">
    <mat-card>      

        <mat-card-content>
            
            <div class="title">
                {{'shared.locationLanguage.text1' | translate:locale.language}}
            </div>

            <!-- Language -->
            <div class="content-container">                
                <mat-radio-group class="options-radio-group" [(ngModel)]="selectedLanguage" color="primary">
                    <mat-radio-button class="radio-option" *ngFor="let item of schema" value="{{item.locale.language}}" >
                        {{ item.locale.language | l10nDisplayNames:locale.language:{ type: 'language' } | titlecase }}
                    </mat-radio-button>
                </mat-radio-group>                
            </div>
            

        </mat-card-content>
    </mat-card>

    <div class="buttons-container">
        <button mat-button mat-flat-button class="primary" (click)="onSaveClick()" [ngClass]="{'loading': saving}">
            {{'shared.locationLanguage.text2' | translate:locale.language}}
        </button>
    </div>
</div>