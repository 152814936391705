<div class="clinic-history-shared-dialog-wrapper" ngClass.lt-md="mobile">
    <div class="header" [ngClass]="{'disabled': loading}">
        <button class="close" mat-icon-button (click)="cancel()" *ngIf="currentView == viewEnum.SelectItems">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-close"></mat-icon>
        </button>
        <button class="back" mat-icon-button (click)="onBackClick()" *ngIf="currentView == viewEnum.FilterItems">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-back"></mat-icon>
        </button>
    </div>
    <div mat-dialog-content>       

        <!-- Select what to share -->
        <div *ngIf="currentView == viewEnum.SelectItems">
            <h3 mat-dialog-title>{{'shared.clinicHistory.shareDialog.title2' | translate:locale.language }}</h3>
            <div class="areas-wrapper">
                <!--<div>
                    <mat-checkbox [checked]="allAreasSelected" 
                                  [indeterminate]="someAreaSelected()"
                                  (change)="setAllAreas($event.checked)">
                        {{'shared.clinicHistory.shareDialog.checkAll'| translate:locale.language}}
                    </mat-checkbox>
                </div>-->
                <div *ngFor="let area of shareDialogData.areas" class="export-items-container">
                    <mat-checkbox [(ngModel)]="area.check">
                        {{ area.nombre | i18nText:locale.language }}
                    </mat-checkbox>
                    <span *ngIf="optionHasFilters(area.valor)" class="export-item-filter" (click)="onFilterClick(area.valor)" [ngClass]="{'disabled': !area.check}">
                        {{'shared.clinicHistory.shareDialog.text5' | translate:locale.language }}
                    </span>
                    <div *ngIf="optionHasFilters(area.valor) && !filters.areEmpty()" class="export-item-filter-summary" > 
                        <span class="filyters-summary-text" [innerHtml]="getSummary(filters)"></span>  
                        <span class="clear-filters" (click)="filters.clear()">{{'shared.clinicHistory.shareDialog.text6' | translate:locale.language }}</span>                     
                    </div>
                </div>                
            </div>            
        </div>        
    </div>

    <!-- Filters -->
    <div *ngIf="currentView == viewEnum.FilterItems">
        <h3 mat-dialog-title>{{'shared.clinicHistory.shareDialog.title3'| translate:locale.language}}</h3>

        <div class="filters-wrapper">            
            
            <label class="input-label">{{'shared.clinicHistory.shareDialog.dateRangeLabel'| translate:locale.language}}</label>
            <mat-form-field floatLabel="auto" class="date-input mat-form-field-hide-label-on-focus">
                <mat-label>{{'shared.clinicHistory.shareDialog.text4'| translate:locale.language}}</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate placeholder="Fecha inicial" [(ngModel)]="filters.startDate">
                  <input matEndDate placeholder="Fecha final" [(ngModel)]="filters.endDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <div class="input-footer">
                {{'shared.clinicHistory.shareDialog.dateRangeHelperText'| translate:locale.language}}
            </div>

            <div class="doctors-wrapper">
                <label class="input-label">{{'shared.clinicHistory.shareDialog.specialtiesLabel'| translate:locale.language}}</label>                
                <div class="doctors-container">
                    <autocomplete-multiple-selection 
                        placeholder="{{'shared.clinicHistory.shareDialog.text7'| translate:locale.language}}" 
                        cssClass="" 
                        [filteredOptions]="filters.filteredDoctors"
                        [selectedOptions]="filters.selectedDoctors"
                        [displayName]="getDoctorDisplayName"
                        (onFilter)="filterDoctors($event)"        
                        (onSelectionChanged)="onDoctorSelectionChanged($event)"                
                        ></autocomplete-multiple-selection>
                </div>
                <div class="input-footer">
                    {{'shared.clinicHistory.shareDialog.specialtiesHelperText'| translate:locale.language}}
                </div>
            </div>            

        </div>
    </div>

    <div class="footer">
        <div class="terms-condition-container" *ngIf="currentView == viewEnum.SelectItems">
            <div>
                <mat-icon fontSet="phr-iconset" class="phr-iconset-lock"></mat-icon>
                <span>{{'shared.clinicHistory.shareDialog.text1'| translate:locale.language}}</span>
            </div>
            <div class="link">
                <span>{{'shared.clinicHistory.shareDialog.link1Text1'| translate:locale.language}}</span>
                <a href="/politicas" target="_blank">{{'shared.clinicHistory.shareDialog.link1Text2'| translate:locale.language}}</a>
            </div>   
        </div>

        <!-- Buttons -->
        <div mat-dialog-actions 
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap="40px"
            fxLayoutGap.lt-md="20px"
            [ngClass]="{'disabled': loading}">        

            <button mat-stroked-button
                color="primary"
                *ngIf="currentView == viewEnum.SelectItems"
                (click)="cancel()">
                {{ 'shared.clinicHistory.shareDialog.button1' | translate:locale.language | uppercase }}
            </button>      
            <button mat-raised-button 
                color="primary" 
                *ngIf="currentView == viewEnum.SelectItems"
                (click)="share()"            
                [disabled]="!shareButtonEnabled()"
                >                
                <span>{{ 'shared.clinicHistory.shareDialog.button2' | translate:locale.language | uppercase }}</span>                
                <mat-spinner class="spinner white" *ngIf="loading" diameter="25"></mat-spinner>
            </button>
            <button mat-raised-button 
                color="primary" 
                *ngIf="currentView == viewEnum.FilterItems"
                (click)="confirmFilters()">
                {{ 'shared.clinicHistory.shareDialog.button3' | translate:locale.language | uppercase }}
            </button>
            <button mat-raised-button 
                color="primary" 
                *ngIf="currentView == viewEnum.Finished"
                (click)="close()">
                {{ 'shared.clinicHistory.shareDialog.button4' | translate:locale.language | uppercase }}
            </button>
        </div>
    </div>

    
</div>
