import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'autocomplete-multiple-selection',
    templateUrl: './autocomplete-multiple-selection.component.html',
    styleUrls: ['./autocomplete-multiple-selection.component.css'],
    encapsulation: ViewEncapsulation.None
  })
  export class AutocompleteMultipleSelectionComponent implements OnInit {       
    _selectedOptions: any[];    
    searchInputCtrl = new FormControl();   

    @Input() placeholder: string;    
    @Input() cssClass: string;        
    @Input() filteredOptions: any[];
    @Input() selectedOptions: any[];
    @Input() displayName: (option: any) => string;  

    @Output() onFilter = new EventEmitter();
    @Output() onSelectionChanged = new EventEmitter();

    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
    
    constructor(       
        @Inject(L10N_LOCALE) public locale: L10nLocale) {
    }

    ngOnInit(): void {     
      this._selectedOptions = this.selectedOptions? this.selectedOptions : [];

      // On search
      this.searchInputCtrl.valueChanges.subscribe(t => {
          if (typeof(t) == "string") {
            this.onFilter.emit(t);      
          }
      });
    }

    removeOption(option: any) {
      const index = this._selectedOptions.indexOf(option, 0);

      if (index > -1) {
        this._selectedOptions.splice(index, 1);        

        this.onSelectionChanged.emit(this._selectedOptions);

        // When we remove an item lets update the filter list so the item we removed apears on the results
        this.onFilter.emit("");
      }
    }
    
    selected(event: MatAutocompleteSelectedEvent): void {        
        this._selectedOptions.push(event.option.value);
        
        this.searchInputCtrl.setValue("");

        this.onSelectionChanged.emit(this._selectedOptions);
        
        // Keep panel open after selection
        setTimeout(() => {
          this.trigger.openPanel()
        }, 50);
    }

    autocompleteOpened():void {
      // Update the list each time it open
      this.onFilter.emit("");  
    }

    autocompleteClosed() {
        
    }
  }  