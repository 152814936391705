<div class="forgot-password-container directory-background" [hidden]="licenseLoading">
    <mat-card class="card-container" *ngIf="!showCallback">
        <mat-card-header>
            <mat-card-title>{{'forgotPassword.title' | translate:locale.language }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form class="forgot-password-form" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
                <mat-form-field appearance="outline" class="form-field-outline-simple">
                    <input matInput formControlName="username" placeholder="{{ 'forgotPassword.inputPlaceholder' | translate:locale.language }}">
                    <mat-error *ngIf="forgotPasswordForm.controls.username.errors?.required">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-close-circle-line"></mat-icon>
                        {{ 'forgotPassword.usernameRequired' | translate:locale.language }}
                    </mat-error>
                    <mat-error *ngIf="forgotPasswordForm.controls.username.errors?.email">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-close-circle-line"></mat-icon>
                        {{ 'forgotPassword.usernameFormatRequired' | translate:locale.language }}
                    </mat-error>
                </mat-form-field>
                <button mat-button mat-flat-button color="primary" [ngClass]="{'loading': loading }">
                    {{ 'forgotPassword.buttonContinue' | translate:locale.language }}
                </button>
                <button mat-button mat-flat-button (click)="onCancel()" class="secondary">
                    {{ 'forgotPassword.buttonCancel' | translate:locale.language }}
                </button>
            </form>
        </mat-card-content>
    </mat-card>
    <div class="callback-container" *ngIf="showCallback">
        <button mat-icon-button class="btn-back" (click)="onCancel()">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Cerrar"></mat-icon>
        </button>
        <app-email-callback [model]="emailCallbackModel"></app-email-callback>
    </div>
</div>