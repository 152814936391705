import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { L10nLocale, L10N_LOCALE, L10nTranslationService } from 'angular-l10n';
import { CloseRouteDialogMessage, ComponentFinishLoadingMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { ComponentLookup } from '../../decorators/component-lookup.decorator';
import { IControlComponent } from '../../interfaces/control-component';
import { FormComponentData } from '../../models/people/form-control.model';
import { AppointmentSummaryDataModel } from '../../models/workflow/models/appointment-summary-data.model';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { PatientAppointmentInformation } from '../../models/process/appointment.model';
import { AppointmentInfoDataModel, AppointmentType } from '../../models/workflow/models/appointment-info-data.model';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { Dependent } from '../../models/people/dependent.model';
import { UserService } from 'src/app/core/services/user.service';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';
import { UserPerson } from '../../models/people/user-person.model';
import { AppointmentCancelationRouteDialogModel, RouteDialogType } from '../dialogs/route-dialog/route-dialog.component';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/core/services/auth.service';
import { DialogData, MapDialogData } from '../../models/dialog-data.model';
import { DoctorMessageDialogComponent } from '../dialogs/doctor-message-dialog/doctor-message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { PublicProfile } from '../../models/people/public-profile.model';
import * as moment from 'moment';
import { TeleconsultaService } from 'src/app/core/services/teleconsulta.service';
import { Directory } from '../../models/systems/directory.model';
import { DOCUMENT } from '@angular/common';
import { MapDialogComponent } from '../dialogs/map-dialog/map-dialog.component';

@Component({
  selector: 'app-summary-appointment-checkout',
  templateUrl: './summary-appointment-checkout.component.html',
  styleUrls: ['./summary-appointment-checkout.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('SummaryAppointmentCheckoutComponent')
export class SummaryAppointmentCheckoutComponent implements IControlComponent, OnInit {
  data: FormComponentData;
  model: AppointmentSummaryDataModel = new AppointmentSummaryDataModel();
  directory?: Directory;
  dependents: Dependent[];  
  loggedInUser: UserPerson;
  private ngUnsubscribe = new Subject();
  appointmentState = 22;
  appointmentStateName = "";
  appointmentExpired: boolean = false;
  appointmentStateClassName: string = "";
  appointmentStateIconClassName: string = "";
  idTeleconsulta?: string;
  licensedCompany: boolean = false;
  isCliniwebCompany: boolean = false;
  show:boolean = false;
  isAndroid = false;
  enablePublicProfileLink: boolean = true;
  initialLoading = true;

  @Input() appointment: PatientAppointmentInformation;
  @Input() inWorflow: boolean = true;

  constructor(
    private routeDialogService: RouteDialogService,
    private messageBusService: MessageBusService,
    private userService: UserService,
    private baseService: BaseService,
    private authService: AuthService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private dialog: MatDialog,
    private publicProfileService: PublicProfileService,
    private appointmentService: AppointmentService,
    private teleconsultaService: TeleconsultaService,
    @Inject(DOCUMENT) private document: Document,
    private translation: L10nTranslationService
  ) {
    this.model.appointmentInfo = new AppointmentInfoDataModel();
  }

  ngOnInit(): void {
    this.isAndroid = navigator.userAgent.toLowerCase().includes('android');
    this.document.body.classList.add('summary-appointment');

    // If we are on a worklfow let just disabled the public profile link
    if (this.inWorflow)
      this.enablePublicProfileLink = false;

    this.loadDataModel();

    this.loadFromAppointment();
    this.getTeleconsultaInfoIfVirtual(this.model?.appointmentInfo.idEtapaSolicitud ?? this.appointment?.idEtapaSolicitud);
    
    // On Header Back click
    this.messageBusService.onNavigateBack()
      .pipe(takeUntil(this.ngUnsubscribe))  
      .subscribe(m => {
        if (m.dialogId != RouteDialogType.APPOINTMENT_SUMMARY)
          return;        

        // Only apply when component was loadad with an appointment (just summary mode)
        if(this.appointment)
          this.messageBusService.closeRouteDialog(new CloseRouteDialogMessage());
      }
    );

    this.baseService.isLicensedCompany()
    .pipe(takeUntil(this.ngUnsubscribe))  
    .subscribe((result:boolean)=>{
      this.licensedCompany = result;

      this.show = true;
    },
    error=>{
      this.baseService.handleServiceError(error, "Error licenced company");
    });

    // Appointment cancelled
    this.messageBusService.onAppointmentChangeReceived()
      .pipe(takeUntil(this.ngUnsubscribe)) 
      .subscribe(message => {
        let idEtapa = this.getAppointmentId();

        this.appointmentService.getAppointmentById(idEtapa, this.locale.language).subscribe(app => {
          let utcDate = moment.utc(app.fechaRequerido).toDate();
          app.fechaRequerido = utcDate;
        
          let endDateUtc = moment.utc(app.fechaFinalizacion).toDate();
          app.fechaFinalizacion = endDateUtc;
          
          this.appointment = app;

          this.loadFromAppointment();
        });        
      }
    );

    this.isCliniwebCompany = this.baseService.isCliniwebCompany();
  }

  private loadFromAppointment() {
    if (this.appointment) {
      let observables: Observable<any>[] = [];

      this.appointmentExpired = this.isAppointmentExpired(this.appointment.fechaInicio);
      this.appointmentState = this.appointment.idEstado;
      this.appointmentStateName = this.getAppointmentStateName(this.appointment.fechaInicio, this.appointment.nombreEstado, this.appointment.idEstado);
      this.appointmentStateClassName = this.getAppointmentStateClassName(this.appointment.fechaInicio, this.appointment.idEstado);
      this.appointmentStateIconClassName = this.getAppointmentStateIconClassName(this.appointment.fechaInicio, this.appointment.idEstado);

      observables.push(this.getUser());
      observables.push(this.getDependents());

      forkJoin(observables).subscribe(results => { 
        
        this.loggedInUser = results[0] as UserPerson;
        this.dependents = results[1] as Dependent[];        

        this.loadModelFromAppointment();  

        this.sendComponentFinishLoadingMessage();
      });
    }
    else {
      this.sendComponentFinishLoadingMessage();
    }
  }

  private getTeleconsultaInfoIfVirtual(idEtapa: number): void {
    if (!!idEtapa && (this.model?.appointmentInfo.appointmentType === AppointmentType.VIRTUAL || this.appointment?.teleconsulta.toLocaleLowerCase() == "s")) {
      const companyId = this.baseService.getCliniwebDirectoryCompanyId();

      this.baseService.getCliniwebDirectory(companyId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(x => this.directory = x, error => { this.baseService.handleServiceError(error, "Error getting directory"); });

      this.teleconsultaService.getByIdEstado(idEtapa)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(x => this.idTeleconsulta = x.idTeleconsulta, error => { this.baseService.handleServiceError(error, "Error getting teleconsultation"); });
    }
  }

  ngOnDestroy() {
    this.document.body.classList.remove('summary-appointment');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  isAppointmentExpired(fechaInicio: Date) {
    let now = new Date().getTime();
    let appDate = new Date(fechaInicio).getTime();

    return appDate <= now;
  }

  getAppointmentStateName(fechaInicio: Date, nombreEstado: string, idEstado: number) : string {
    let stateName = nombreEstado;   
    
    if (this.isAppointmentExpired(fechaInicio)) {
      if (idEstado != 7 && idEstado != 28)
        stateName = this.translation.translate('shared.cliniwebAppointment.text19');
    }
    else {
      // Cobrado = 3
      // Sin Cobrar = 3
      if (idEstado == 3 || idEstado == 27)
        stateName = this.translation.translate('shared.cliniwebAppointment.text20');;     
    }
    
    return stateName;
  }

  getAppointmentStateClassName(fechaInicio: Date, idEstado: number) {
    let stateClassName = "";
    
    if (this.isAppointmentExpired(fechaInicio)) {
      if (idEstado == 7)
        stateClassName = "cancelled";
      else if (idEstado == 28)
        stateClassName = "not-completted";      
    }
    else {
      if (idEstado == 23 || idEstado == 24 || idEstado == 30)
        stateClassName = "confirmed";
      else if (idEstado == 7 || idEstado == 28)
        stateClassName = "cancelled";
      else if (idEstado == 2)
        stateClassName = "not-confirmed";
      else if (idEstado == 22)
        stateClassName = "pending";
    }

    return stateClassName;
  }

  getAppointmentStateIconClassName(fechaInicio: Date, idEstado: number) {
    let stateIconClass = "phr-iconset-PHR-date-2";
    
    if (this.isAppointmentExpired(fechaInicio)) {
      if (idEstado == 7 || idEstado == 28)
        stateIconClass = "phr-iconset-close-circle";
    }
    else {
      stateIconClass = "phr-iconset-validacion";

      if (idEstado == 7 || idEstado == 28)
        stateIconClass = "phr-iconset-close-circle";
      else if (idEstado == 22 || idEstado == 2)
        stateIconClass = "phr-iconset-alert";   
      else if (idEstado == 3 || idEstado == 27)
        stateIconClass = "phr-iconset-PHR-date-2";   
    }    
    
    return stateIconClass;
  }

  getDependents() : Observable<Dependent[]>{
    return this.userService.getUserDependents().pipe(
      tap(() => {
        
      }),
      catchError((e => {        
        this.baseService.handleServiceError(e, "Error getting dependents");
        return of([]);
      })));
  }

  getUser() : Observable<UserPerson | null> {
    return this.userService.getUserPersonLoggedIn().pipe(
      tap(() => {
        
      }),
      catchError((e => {        
        this.baseService.handleServiceError(e, "Error getting user"); 
        
        return of(null);
      })));
  }

  loadModelFromAppointment() {
    let appointmentInfoDataModel = new AppointmentInfoDataModel();    
    
    appointmentInfoDataModel.appointmentType = (this.appointment.teleconsulta && this.appointment.teleconsulta.toLocaleLowerCase() == "s"
      ? AppointmentType.VIRTUAL
      : AppointmentType.FACE_TO_FACE);    
    appointmentInfoDataModel.appointmentDate = this.appointment.fechaRequerido;
    appointmentInfoDataModel.cancellationDate = this.appointment.fechaFinalizacion;
    appointmentInfoDataModel.doctorAvatarUrl = this.appointment.urlAvatarResponsableServicio;
    appointmentInfoDataModel.doctorName = this.appointment.nombreResponsableServicio;
    appointmentInfoDataModel.doctorSpecialty = this.appointment.especialidadResponsableServicio;
    appointmentInfoDataModel.attentionPlaceName = this.appointment.nombreLocalidad;
    appointmentInfoDataModel.attentionPlaceAddress = this.appointment.direccionLocalidad;
    appointmentInfoDataModel.attentionPlaceLatLng = this.appointment.coordenadasLocalidad;
    appointmentInfoDataModel.medicalConsultationType = "";
    appointmentInfoDataModel.medicalConsultationAmount = 0;
    appointmentInfoDataModel.patientName = this.getName(this.appointment);
    appointmentInfoDataModel.patientGender = this.getPatientGender(this.appointment);
    appointmentInfoDataModel.patientRelationshipType = this.getPatientRelationship(this.appointment);

    appointmentInfoDataModel.idEmpresa = this.appointment.idEmpresa;
    appointmentInfoDataModel.idResponsableServicio = this.appointment.idResponsableServicio;
    appointmentInfoDataModel.idEtapaSolicitud = this.appointment.idEtapaSolicitud;

    appointmentInfoDataModel.perfilPublico = new PublicProfile();
    appointmentInfoDataModel.perfilPublico.id = this.appointment.idResponsableServicio;
    appointmentInfoDataModel.perfilPublico.nombrePersona = this.appointment.nombreResponsableServicio;
    appointmentInfoDataModel.perfilPublico.sexo = this.appointment.sexoResponsableServicio;
    appointmentInfoDataModel.perfilPublico.ubicacionFoto = this.appointment.urlAvatarResponsableServicio;
    appointmentInfoDataModel.perfilPublico.tipoPerfil = 1; 
    appointmentInfoDataModel.perfilPublico.username = this.appointment.identificadorPerfilPublico;    

    this.model =  new AppointmentSummaryDataModel();
    var coordinates = this.appointment.coordenadasLocalidad
      ? this.appointment.coordenadasLocalidad.split(";").map(c => parseFloat(c))
      : [0, 0];

    this.model.appointmentInfo = appointmentInfoDataModel;
    this.model.specialty = this.appointment.especialidadResponsableServicio;
    this.model.pendingApproval = false;
    this.model.locationLat = coordinates[0];
    this.model.locationLng = coordinates[1];
    this.model.phoneNumber = this.appointment.telefonoLocalidad1? this.appointment.telefonoLocalidad1 : this.appointment.telefonoLocalidad2;   

    //console.log(this.model);
  }

  getPatientRelationship(app: PatientAppointmentInformation) : number {
    let relationship = 0;

    if (app.idPacienteOrigen != this.loggedInUser.id) {
      // Search for dependent
      let dependent = this.dependents.find(d => d.idPersona == app.idPacienteOrigen);

      if (dependent) {
        relationship = dependent.tipoRelacion;       
      }
    }    

    return relationship;
  }

  getPatientGender(app: PatientAppointmentInformation) : string {
    let gender = this.loggedInUser.sexo;

    if (app.idPacienteOrigen != this.loggedInUser.id) {
      // Search for dependent
      let dependent = this.dependents.find(d => d.idPersona == app.idPacienteOrigen);

      if (dependent) {
        gender = dependent.sexo;       
      }
    }     

    return gender;
  }

  
  getName(app: PatientAppointmentInformation) {
    if (app.idPacienteOrigen == this.loggedInUser.id) {
      return this.loggedInUser.nombre;
    }

    let dependent = this.dependents.find(d => d.idPersona == app.idPacienteOrigen);
    if (dependent) {
      return dependent?.nombre;
    }    

    return app.nombrePaciente;
  }
  
  sendComponentFinishLoadingMessage(){
    if (!this.data || !this.data.idFormulario)
      return;
    
    let event = new ComponentFinishLoadingMessage();
    event.idFormulario = this.data.idFormulario;
    event.idControl = this.data.idControlPadre;

    this.messageBusService.componentFinishLoading(event);   
  }

  gotoHome(){
    //this.routeDialogService.closeDialogsAndNavigate('/'); 
    this.messageBusService.closeRouteDialog(new CloseRouteDialogMessage());

    window.location.href = environment.cliniwebPhrUrl;
  }

  showMyAppointments(){
    if(this.licensedCompany || this.isCliniwebCompany){
      //this.routeDialogService.closeDialogsAndNavigate('mis-citas');
      this.messageBusService.closeRouteDialog(new CloseRouteDialogMessage());

      window.location.href = environment.cliniwebPhrUrl + 'mis-citas';
    }
    else{
      this.authService.getTransferToken()
      .subscribe(token=>{
        window.location.href = environment.cliniwebPhrUrl + 'mis-citas?token=' + token;
      },
      error=>{
        this.baseService.handleServiceError(error, "Error getting token transfer");
      });
    }    
  }

  getCallNumber(){
    return `tel:${this.model.phoneNumber}`;
  }

  getWazeUrl(){
    return `https://waze.com/ul?navigate=yes&zoom=${this.model.locationZoom}&ll=${this.model.locationLat},${this.model.locationLng}`;
  }

  getVirtualConsultation(): string {
    return `${this.directory?.url}/meet/${this.idTeleconsulta}`;
  }

  openDialogContact() {
    /*let routeDialogModel = new ContactRouteDialogModel();
    routeDialogModel.route = 'support/contactus';
    routeDialogModel.queryParams = { returnUrl: this.router.url }
    this.routeDialogService.openDialog(routeDialogModel);*/

    let data = new DialogData();

    data.data = {
      idEmpresa: this.model.appointmentInfo.idEmpresa,
      id: this.model.appointmentInfo.idResponsableServicio,
      nombrePersona: this.model.appointmentInfo.doctorName
    };

    this.dialog.open(DoctorMessageDialogComponent, {          
      panelClass: 'doc-message-dialog',
      data: data
    });    
  }

  private loadDataModel() {
    if (this.data && this.data.configurationData) {
      this.model = this.data.configurationData as AppointmentSummaryDataModel;     
      
      if (this.model.appointmentInfo && this.model.appointmentInfo.idEstado) {
        this.appointmentState = this.model.appointmentInfo.idEstado;

        this.appointmentStateName = this.getAppointmentStateName(
          this.model.appointmentInfo.appointmentDate, 
          this.model.appointmentInfo.nombreEstado, 
          this.model.appointmentInfo.idEstado);

        this.appointmentStateClassName = this.getAppointmentStateClassName(this.model.appointmentInfo.appointmentDate ,this.model.appointmentInfo.idEstado);
        this.appointmentStateIconClassName = this.getAppointmentStateIconClassName(this.model.appointmentInfo.appointmentDate ,this.model.appointmentInfo.idEstado);
        
      }        
    }
    setTimeout(() => this.initialLoading = false, 500);
  }

  private getAppointmentId() {
    var idEtapaSolicitud = 0;
    
    if (this.appointment)
      idEtapaSolicitud = this.appointment.idEtapaSolicitud;
    else 
      idEtapaSolicitud = this.model.appointmentInfo.idEtapaSolicitud;    

    return idEtapaSolicitud;
  }  

  onCancelAppointmentClick() {
    var idEtapaSolicitud = this.getAppointmentId();

    let routeDialogModel = new AppointmentCancelationRouteDialogModel();
    routeDialogModel.route = "citas/cancelar/" + idEtapaSolicitud;
    //routeDialogModel.queryParams = { returnUrl: this.router.url };
    routeDialogModel.queryParams = { returnUrl: window.location.pathname };

    //routeDialogModel.state = null;

    this.routeDialogService.openDialog(routeDialogModel);
  }

  onReScheduleAppointmentClick() {
    let publicProfileId = this.model.appointmentInfo.idResponsableServicio;

    this.publicProfileService.getPublicProfileById(publicProfileId, this.locale.language, this.baseService.getCompanyName())
      .subscribe(pp => {
          if(pp) {
            //let route = "perfil/reprogramar/" + pp.username;

            // Close all popups            
            this.messageBusService.closeRouteDialog(new CloseRouteDialogMessage());

            // Open re-schedule popup
            setTimeout(() => {
              /*let routeDialogModel = new RescheduleAppointmentRouteDialogModel();
              routeDialogModel.route = route;
              routeDialogModel.state = null;        
              this.routeDialogService.openDialog(routeDialogModel);*/

              let url = this.publicProfileService.getPublicProfileUrl(pp.username);
              this.publicProfileService.openPublicProfile(url);
            }, 1500);            
          }
      },
      error=>{
        this.baseService.handleServiceError(error, "Error getting public profile");
      });    
  }
  get isVirtual() { return this.model?.appointmentInfo?.appointmentType == AppointmentType.VIRTUAL; }

  openMap(){
    let data = new MapDialogData();

    data.title = this.model.appointmentInfo.attentionPlaceName;    
    data.message = (this.model.locationLat + ";" + this.model.locationLng);
    data.animationIn = 'map-phr-animation-in';
    data.animationOut = 'map-phr-animation-out';
    data.showCloseTextButton = true;
    data.showWazeButton = false;

    this.dialog.open(MapDialogComponent, {          
      panelClass: ['map-dialog','map-phr-dialog', 'map-phr-animation-in'],
      data: data
    });
  }
}
