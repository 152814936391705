import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { BaseService } from 'src/app/core/services/base.service';
import { ClinicHistoryService } from 'src/app/core/services/clinic-history.service';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { GroupConcept } from 'src/app/shared/enums/group-concept.enum';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { AttentionGroupItem, ClinicHistoryItem } from 'src/app/shared/models/clinic-history/attention-history.model';
import { ClinicHistory } from 'src/app/shared/models/clinic-history/clinic-history.model';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { FileViewerDialogComponent } from '../../dialogs/file-viewer-dialog/file-viewer-dialog.component';
import { FileViewerDto, FileViewerItemDto } from '../../file-viewer/file-viewer.component';
import { TimelineWrapperItem, TimelineWrapperItemType, TimelineWrapperSkeletonItem } from '../../timeline-list/timeline-list.component';

@Component({
  selector: 'app-attention-history',
  templateUrl: './attention-history.component.html',
  styleUrls: ['./attention-history.component.css']
})
@ComponentLookup('AttentionHistoryComponent')
export class AttentionHistoryComponent implements OnInit, IControlComponent {
  data: FormComponentData;

  timelineAttentions: TimelineAttentionHistory[] = [];
  skeletonItems: TimelineWrapperSkeletonItem[] = [new TimelineWrapperSkeletonItem(), new TimelineWrapperSkeletonItem()];
  loading = true;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private messageBusService: MessageBusService,
    private clinicHistoryService: ClinicHistoryService,
    private baseService: BaseService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.messageBusService.onSendClinicHistory().subscribe(message =>{
      this.createTimelineAttentions(message.clinicHistory);
      this.loading = false;
    });
  }

  createTimelineAttentions(history: ClinicHistory){
    let dtos: AttentionHistoryViewDTO[] = [];
    let conclusionGroup = [GroupConcept.PLAN, GroupConcept.PRESCRIPCION_MEDICA, GroupConcept.LABORATORIOS];

    // For each attention wil will create a card
    history.historialAtenciones.forEach(attention => {            

      var dto = new AttentionHistoryViewDTO();      

      dto.fecha = attention.contactoClinico.fechaContacto;
      dto.avatarUrl = attention.contactoClinico.avatarResponsableServicio;
      dto.titulo = attention.contactoClinico.nombreResponsableServicio; //TODO agregar especialidad

      // If this is a virtual attention that was cancelled it will not contain a co_responsable
      if (!dto.titulo) {          
          dto.titulo = "Centro de atención integral de salud SURA";
          dto.avatarUrl = "assets/images/sura/logo-sura-simple.png";
      }

      if (!dto.avatarUrl) {
        let avatarInitialsObject = this.baseService.getAvatarInitialObject(attention.contactoClinico.idResponsableServicio, dto.titulo);

        dto.iniciales = avatarInitialsObject.initials;
        dto.colorIniciales = avatarInitialsObject.color;
      }

      dto.claveSubtitulo = attention.contactoClinico.idTipoSolicitud == 53 || attention.contactoClinico.idTipoSolicitud == 54
        ? 'virtualConsultation'
        : 'faceToFaceConsultation';


      // Get conclusion items
      let conclusionItems =  this.clinicHistoryService.getClinicHistoryItemsByGroup(attention.atenciones, conclusionGroup);
      
      // Get all items that are NOT conclusions
      let basicItems = this.clinicHistoryService.getClinicHistoryItemsByExcludedGroup(attention.atenciones, conclusionGroup);

      // Map to UI objects
      dto.conceptosConclusiones = conclusionItems.map(i => new AttentionHistoryItemViewDTO(i));
      dto.conceptosBasicos = basicItems.map(i => new AttentionHistoryItemViewDTO(i));      
      dto.existenConclusiones = conclusionItems.length > 0;      

      dtos.push(dto);      
    });

    this.timelineAttentions = dtos.map(dto=> new TimelineAttentionHistory(dto));
  }

  getClassPreviewFile(concept: ClinicHistoryItem): string {
    return this.clinicHistoryService.getClassPreviewFile(concept.extensionDocumento);
  }

  getPreviewFileUrl(concept: ClinicHistoryItem): string {
    return this.clinicHistoryService.getPreviewFileUrl(concept.extensionDocumento, concept.ubicacionWebDocumento);
  }

  onFileClick(item: AttentionHistoryItemViewDTO, file: ClinicHistoryItem) {
    let dialogData = new DialogData();
    let viewerDto = new FileViewerDto(); 
    
    viewerDto.selectedFileId = file.idDocumento;
    viewerDto.files = this.getConsultationDocumentAsViewerItems(item);    

    dialogData.data = viewerDto;

    const dialogRef = this.dialog.open(FileViewerDialogComponent, {
      panelClass: 'file-viewer-dialog',
      data: dialogData
    });
  }

  getConsultationDocumentAsViewerItems(item: AttentionHistoryItemViewDTO): FileViewerItemDto[] {
    let result = item.documentos.map(d => {
      let fileDto = new FileViewerItemDto();

      fileDto.fileId = d.idDocumento;
      fileDto.fileUrl = d.ubicacionWebDocumento;
      fileDto.amazonBucketName = d.nombreBucketAmazon;
      fileDto.fileExtension = d.extensionDocumento;
      fileDto.webUrl = d.ubicacionWebDocumento;

      fileDto.createdAt = d.fechaRegistro;
      fileDto.title = d.nombreDocumento;
      fileDto.description = d.descripcion;

      return fileDto;
    });

    return result;
  }
}

export class TimelineAttentionHistory implements TimelineWrapperItem {  
  attention : AttentionHistoryViewDTO;
  viewMore: boolean;

  constructor(attention : AttentionHistoryViewDTO) {
    this.attention = attention;
  }

  getItemType(): TimelineWrapperItemType{
    return TimelineWrapperItemType.DOT;
  }
  
  getGroupKey() : number {
    return new Date(this.attention.fecha).getFullYear();
  }

  getDate() : Date {
    return new Date(this.attention.fecha);
  }

  showTime():boolean{
    return false;
  }

  showDate():boolean{
    return true;
  }

  onClickLinkViewMore(){
    this.viewMore = !this.viewMore;
  }
}

export class AttentionHistoryViewDTO{
  claveSubtitulo: string;
  avatarUrl: string;
  titulo: string;
  iniciales: string;
  colorIniciales: string;
  fecha: Date;
  existenConclusiones: boolean;

  conceptosBasicos: AttentionHistoryItemViewDTO[];  
  conceptosConclusiones: AttentionHistoryItemViewDTO[];  
}

export class AttentionHistoryItemViewDTO{
  titulo: string;
  descripcion: string;
  conceptos: ClinicHistoryItem[];
  documentos: ClinicHistoryItem[];
  tieneConceptosBasicos: boolean;
  tieneDocumentos: boolean;

  constructor (group: AttentionGroupItem){
    this.titulo = group.nombreTipoConcepto;    
    this.conceptos = group.items.filter(c => c.idDocumento == 0);
    this.documentos = group.items.filter(c => c.idDocumento > 0);
    this.descripcion = this.conceptos.map(c => c.descripcion.replace("::", ":")).join(', ');
    this.tieneConceptosBasicos = this.conceptos.length > 0;
    this.tieneDocumentos = this.documentos.length > 0;
  }
}