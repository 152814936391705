import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SystemDocument } from 'src/app/shared/models/process/document.model';
import { HttpBaseService } from './http-base-service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpBaseService) { }

  getDocumentUrl(documentId: number) : Observable<string> {
    let url = "api/documentos/" + documentId + "/url";
   
    return this.http.get<string>(url);
  } 

  getDocumens(documentIds: string) : Observable<SystemDocument[]> {
    let url = "api/documentos/batch?idsDocumento=" + documentIds;
   
    return this.http.get<SystemDocument[]>(url);
  } 
}