<div class="provider-card-contacts-container">

    <div class="attention-schedule-wrapper" *ngIf="!isDoctorProfile">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-clock icon"></mat-icon>
        <span *ngIf="location.objetoDetalle5">
            {{location.objetoDetalle5}}
        </span>
        <span class="opacity" *ngIf="!location.objetoDetalle5">
            {{ 'shared.searchResults.providerCard.unspecified' | translate:locale.language }}
        </span>
    </div>

    <div class="contact-location-wrapper" *ngFor="let item of locations">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-{{item.icon}} icon"></mat-icon>
        <span [title]="item.value">{{ item.value }}</span>
    </div>

    <div class="contact-location-wrapper" *ngIf="locations.length <= 0">
        <div class="contact-wrapper">
            <div class="contact-text">
                <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-phone icon"></mat-icon>
                <span class="opacity">
                    {{ 'shared.searchResults.providerCard.unspecified' | translate:locale.language }}
                </span>
            </div>
        </div>
    </div>

</div>