import { Component, HostBinding, Inject, Input, OnInit } from '@angular/core';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { NavigationService } from 'src/app/core/services/navigation.service';

@Component({
  selector: 'app-summary-order-download-button',
  templateUrl: './summary-order-download-button.component.html',
  styleUrls: ['./summary-order-download-button.component.css']
})
export class SummaryOrderDownloadButtonComponent implements OnInit {

  @HostBinding('style.flex-grow') flexGrow = '1';
  @HostBinding('style.max-width') maxWidth = '464px';
  @Input() share = false;
  @Input() file: File;

  buttonText:string = '';
  loading: boolean = false;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private navigationService: NavigationService
  ) {    
  }

  ngOnInit(): void {
    this.buttonText = this.share 
      ? 'shared.medicalOrder.summary.buttonShareOrder' 
      : 'shared.medicalOrder.summary.buttonDownloadOrder';
  }

  onClick(): void{
    this.loading = true;
    if(this.share){
      this.compartirArchivo(this.file);
    }
    else{
      this.fallbackDownload(this.file);
    }
    this.loading = false;
  }

  private compartirArchivo(file: File): void {
    if (this.navigationService.isNavigatorAvailable() && this.navigationService.canShare({ files: [file] })) {
      this.navigationService.share({
        title: 'Compartir reporte',
        text: 'Revisa este reporte médico',
        files: [file],
      }).then(() => {
        console.log('Compartido exitosamente');
      }).catch((error) => {
        console.error('Error al compartir', error);
        // this.fallbackDownload(file);
      });
    } else {
      console.error('El navegador no soporta compartir archivos.');
      this.fallbackDownload(file);
    }
  }

  private fallbackDownload(file: File): void {
    const blobUrl = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
