import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(DOCUMENT) private document: Document,
    private router: Router) { 

    }

  ngOnInit(): void {
    this.document.body.classList.add('not-found');
  }

  ngOnDestroy():void{
    this.document.body.classList.remove('not-found');
  }

  goHome() {
    this.router.navigate(['/'], { });
  }

}
