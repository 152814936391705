<div class="past-consultations-container">
  
    <app-timeline-list-container [items]="consultations" [skeletonItems]="skeletonItems" [loading]="loading">    
      
      <!-- Skeleton -->
      <ng-template let-item="item" #TimelineSkeletonContent>
        <mat-card class="past-consultation-card skeleton" *ngIf="item">               
          <mat-card-content>
            
            <div class="past-consultation-upper-container">
              <div class="upper-container-img">
                <ngx-skeleton-loader [theme]="{ 'width': '50px', 'height': '50px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '50%'}"></ngx-skeleton-loader>
              </div>              
              <div class="right-container">
                  <div class="first-text">
                    <ngx-skeleton-loader [theme]="{ 'width': '40%', 'height': '13px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>
                  </div>
                  <div class="second-text">
                    <ngx-skeleton-loader [theme]="{ 'width': '51px', 'height': '13px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>
                  </div>
              </div>
              <a class="action">
                <ngx-skeleton-loader [theme]="{ 'width': '62px', 'height': '14px', 'background-color': 'rgba(0, 174, 199, 0.3)', 'border-radius': '2px'}"></ngx-skeleton-loader>                
              </a>
            </div>            
            <div class="footer-container">
              <div class="footer-first-text">
                <ngx-skeleton-loader [theme]="{ 'width': '203px', 'height': '13px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>                
              </div>
              <div class="state">
                <ngx-skeleton-loader [theme]="{ 'width': '96px', 'height': '13px', 'background-color': 'rgba(0, 174, 199, 0.3)', 'border-radius': '2px'}"></ngx-skeleton-loader>                
              </div>
            </div>
    
          </mat-card-content>
        </mat-card> 
      </ng-template>
      
      <!-- Content -->
      <ng-template let-item="item" #TimelineContent>
        
        <mat-card class="past-consultation-card {{getStateClass(item.consultation)}}" *ngIf="item">               
          <mat-card-content>
            <div class="past-consultation-upper-container">
              <img class="upper-container-img" src="assets/images/sura/logo-sura-simple.png"/>
              <div class="right-container">
                  <div class="first-text">{{ 'shared.pastVirtualConsultations.text1' | translate:locale.language }}</div>
                  <div class="second-text">{{ 'shared.pastVirtualConsultations.text2' | translate:locale.language }}</div>                                
              </div>
              <a class="action" [ngClass]="{'disabled': item.consultation.idEstado == 7}" (click)="openChatHistory(item.consultation)">{{ 'shared.pastVirtualConsultations.text3' | translate:locale.language }}</a>
            </div>
            
            <div class="footer-container">
              <div class="footer-first-text">{{ 'shared.pastVirtualConsultations.text4' | translate:locale.language }}</div>
              <div class="state">{{ getStateName(item.consultation) }}</div>
            </div>
    
          </mat-card-content>
        </mat-card> 
  
      </ng-template>

      <!-- Empty State -->
      <ng-template let-item="item" #TimelineEmptyStateContent>
        <div class="empty-state-title">{{ 'shared.pastVirtualConsultations.text5' | translate:locale.language }}</div>
        <div class="empty-state-text">
          <span>{{ 'shared.pastVirtualConsultations.text6' | translate:locale.language }}</span>
          <a (click)="navigateToVirtualConsultationPage()">{{ 'shared.pastVirtualConsultations.text7' | translate:locale.language }}</a>
        </div>
      </ng-template>

    </app-timeline-list-container>
      
  </div>