import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DropdownComponent implements DropdownPanel {

  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;
  @Output() closed = new EventEmitter<void>();
  @Output() overlayRef = new EventEmitter<OverlayRef>();
  @Input() panelClass: string;
  @Input() fullscreen: boolean = false;
  @Input() overlayTransparent: boolean = true;

  constructor() { }
}

export interface DropdownPanel {
  templateRef: TemplateRef<any>;
  readonly closed: EventEmitter<void>;
  readonly overlayRef: EventEmitter<OverlayRef>;
  readonly panelClass: string;
  readonly fullscreen: boolean;
  readonly overlayTransparent: boolean;
}