import { Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { Subject } from 'rxjs';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { PublicProfileDetailType } from 'src/app/shared/models/people/profile-detail.model';
import { ProfileLocationResultModel, ProfileResultModel, PublicProfileType } from 'src/app/shared/models/people/profile-search-results.model';
import { TextLanguage } from 'src/app/shared/models/people/text-language.model';

@Component({
  selector: 'app-provider-card',
  templateUrl: './provider-card.component.html',
  styleUrls: ['./provider-card.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProviderCardComponent implements OnInit, OnDestroy {

  provider: ProfileResultModel;
  @Input() set profile(provider: ProfileResultModel) {
    this.provider = provider;   
  }
  @Input() locations: ProfileLocationResultModel[] = [];

  locationSelected: ProfileLocationResultModel | null;

  socialNetworksList:SocialNetworkDto[] = [];
  socialNetworksLoaded:boolean = false;
  socialNetworksLoading:boolean = false;

  //subscribes
  private ngUnsubscribe = new Subject();

  constructor(
    private translation: L10nTranslationService,
    private profileService: PublicProfileService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) { }

  ngOnInit(): void {
    this.locationSelected = this.locations.length > 0 ? this.locations[0] : null;
  }

  ngOnDestroy():void{
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  goToProfile($event:Event) {
    $event.preventDefault();
    $event.stopPropagation();

    let url = this.profileService.getPublicProfileUrl(this.provider.rutaNavegacion);    

    switch(this.provider.tipoPerfil) {
      case PublicProfileType.Doctor:
      case PublicProfileType.Farmacia:      
      case PublicProfileType.Clinica:
      case PublicProfileType.Fisioterapia:      
      case PublicProfileType.Laboratorio:
      case PublicProfileType.CentroDeImagenes:
      case PublicProfileType.ServiciosOdontologicos:
      case PublicProfileType.Opticas:
      case PublicProfileType.ClinicasDeAtencionPrimaria:
      default:
          url = `/${url}`;
          break;
    }

    this.profileService.openPublicProfile(url);
  }

  locationTrackBy(index : number, item: ProfileLocationResultModel){
    return item.id; 
  }

  getLocationTabTitle(location:ProfileLocationResultModel) {
    let isTeleconsultation = location.teleconsulta == "S";

    let textTitle = isTeleconsultation
      ? this.translation.translate("shared.searchResults.providerCard.labelTabVirtualLocation", this.locale.language)
      : this.isDoctorProfile()
        ? this.translation.translate("shared.searchResults.providerCard.labelTabLocationDoctor", this.locale.language)
        : this.translation.translate("shared.searchResults.providerCard.labelTabLocation", this.locale.language);
    
    let i = isTeleconsultation
      ? this.locations.filter(l => l.teleconsulta == "S").indexOf(location)
      : this.locations.filter(l => l.teleconsulta != "S").indexOf(location);

    return this.locations.length > 1
      ? `${textTitle} ${i + 1 }`
      : textTitle;
  }

  isDoctorProfile(){
    return this.provider.tipoPerfil == PublicProfileType.Doctor;
  }

  isClinicProfile(){
    return this.provider.tipoPerfil == PublicProfileType.Clinica;
  }

  getWebsiteUrl() {
    let url = this.provider.sitioWeb;

    if (!url.startsWith("http"))
      url = "http://" + url;

      return url;
  }

  loadSocialNetworks() {
    this.socialNetworksLoading = true;
    this.profileService.getProfileDetails(
      this.provider.id,
      PublicProfileDetailType.SocialNetworks)
      .subscribe(data => {
        this.socialNetworksLoaded = true;
        this.socialNetworksLoading = false;

        if(!data || data.length == 0){
          return;
        }

        this.socialNetworksList = data.map(sn => {
          let dto = new SocialNetworkDto();

          dto.networkType = sn.idObjetoDetalle;
          dto.url = sn.objetoDetalle;
          dto.iconClassName = this.getSocialNetworkIconClassName(sn.idObjetoDetalle);
          dto.text = this.getOptionTextSocialNetwork(sn.idObjetoDetalle);

          return dto;
        });
      });
  }

  getSocialNetworkIconClassName(networkType:number){
    let iconClassName = 'phr-iconset-';

    switch (networkType) {
      // Facebook
      case 24:
          iconClassName += "facebook";
          break;
      // Twitter
      case 25:
          iconClassName += "twitter";
          break;
      // Youtube
      case 26:
          iconClassName += "youtube";
          break;
      // Linkedin
      case 27:
          iconClassName += "linked-in";
          break;
      // Instagram
      case 28:
          iconClassName += "instagram";
          break;
    }

    return iconClassName;
  }

  getOptionTextSocialNetwork(networkType:number){    
    switch (networkType) {
      // Facebook
      case 24:
          return "Facebook";
      // Twitter
      case 25:
          return "Twitter";
      // Youtube
      case 26:
          return "Youtube";
      // Linkedin
      case 27:
          return "Linkedin";
      // Instagram
      case 28:
          return "Instagram";
      default:
          return "";
    }
  }

  setLocationSelected(tabEvent:MatTabChangeEvent) {    
    this.locationSelected = this.locations[tabEvent.index];
  }
}

export class SocialNetworkDto{
  networkType: number;
  url: string;
  iconClassName: string;
  text: string;
}

export class FeatureConceptDto{
  text: TextLanguage[];
  iconClassName: string;
}