import { Component, HostListener, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { ComponentDataSetMessage, ComponentFinishLoadingMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { ComponentLookup } from '../../decorators/component-lookup.decorator';
import { IControlComponent } from '../../interfaces/control-component';
import { FormComponentData } from '../../models/people/form-control.model';
import { SenderPatientRelationship } from '../../models/process/base-request.model';
import { SelectPatientRelationshipDataModel } from '../../models/workflow/models/select-patient-relationship-data.model';
import { ComponentName } from '../../enums/component-name.enum';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentInfoDialogComponent } from '../dialogs/appointment-info-dialog/appointment-info-dialog.component';

@Component({
  selector: 'app-select-patient-relationship-appointment-checkout',
  templateUrl: './select-patient-relationship-appointment-checkout.component.html',
  styleUrls: ['./select-patient-relationship-appointment-checkout.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('SelectPatientRelationshipAppointmentCheckoutComponent')
export class SelectPatientRelationshipAppointmentCheckoutComponent implements IControlComponent,OnInit {
  data: FormComponentData;

  model:SelectPatientRelationshipDataModel = new SelectPatientRelationshipDataModel();

  language: string;
  patientRelationshipForm: FormGroup;
  showAppointmentInfo: boolean;

  loading: boolean = false;
  formSubmitted: boolean = false;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private messageBusService: MessageBusService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {
    this.language = locale.dateLanguage ?? 'es-AR';
  }
  
  ngOnInit(): void {
    this.loadDataModel();
    
    this.loadForm();

    this.sendComponentFinishLoadingMessage();
    
    this.showAppointmentInfo = window.innerWidth > 799;
  }

  getControl(controlName: string) {
    return this.patientRelationshipForm.controls[controlName];
  }
  
  isControlInvalid(controlName: string) {
    let control = this.getControl(controlName);

    // First check if the form is submitted or control is dirty
    if (!this.formSubmitted && !control.touched)
      return false;

    return control.invalid;
  }
  
  loadForm(){
    this.patientRelationshipForm = this.formBuilder.group(
      {
        patientRelationship: ['', [Validators.required]]
      }
    );

    if (this.model.patientRelationship && this.model.patientRelationship != 9)
      this.patientRelationshipForm.controls.patientRelationship.setValue(this.model.patientRelationship.toString());
  }

  sendComponentFinishLoadingMessage(){
    let event = new ComponentFinishLoadingMessage();
      event.idFormulario = this.data.idFormulario;
      event.idControl = this.data.idControlPadre;

    this.messageBusService.componentFinishLoading(event);   
  }

  onClickContinue(){

    this.formSubmitted = true;

    if (this.patientRelationshipForm.invalid || this.patientRelationshipForm.controls.patientRelationship.value == SenderPatientRelationship.DESCONOCIDO) {
      return;
    }

    this.loading = true;

    this.model.patientRelationship = +(this.patientRelationshipForm.controls.patientRelationship.value);

    let message = new ComponentDataSetMessage();
    message.componentName = ComponentName.SELECT_PATIENT_RELATIONSHIP_APPOINTMENT;
    message.data = this.model;

    this.messageBusService.onComponentDataSetMessage(message);  
  }

  openAppointmentInfoDialog(){
    this.dialog.open(AppointmentInfoDialogComponent, {          
      panelClass: ['appointment-info-dialog', 'appointment-info-animation-in'],
      data: this.model.appointmentInfo
    });
  }

  buttonDisabled():boolean{
    return this.patientRelationshipForm.controls.patientRelationship.value == SenderPatientRelationship.DESCONOCIDO;
  }

  private loadDataModel() {
    if (this.data && this.data.configurationData) {
      this.model = this.data.configurationData as SelectPatientRelationshipDataModel;        
    }     
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.showAppointmentInfo = event.target.innerWidth > 799;
  }
}