import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SystemAreas } from 'src/app/shared/enums/system-areas.enum';
import { FormComponentData, FormControl, FormControlComponent } from 'src/app/shared/models/people/form-control.model';
import { Form } from 'src/app/shared/models/people/form.model';
import { SearchProvidersConfiguration } from 'src/app/shared/models/search-results/search-providers-configuration.model';
import { LayoutService } from './layout.service';
import { FloatingComponentsMessage, MessageBusService } from './message-bus.service';

@Injectable({
  providedIn: 'root'
})
export class SearchProvidersService {

  constructor(
    private layoutService: LayoutService, 
    private messageBusService: MessageBusService
  ) { }

  searchProviderComponentName= 'SearchProvidersV2Component';
  systemArea = SystemAreas.BUSCAR_PERFILES_PUBLICOS;

  openSearch(systemArea?: number){

    if(systemArea)
      this.systemArea = systemArea;

    this.getControlConfig().subscribe(config=>{
      if(config){
        let message = new FloatingComponentsMessage();
    
        message.componentsData = config;
    
        this.messageBusService.sendFloatingComponents(message);
      }
    });
  }

  getControlConfig(){

    return this.layoutService.getForm(this.systemArea)
    .pipe(map(
      (form:Form)=>{

      if(!form) return;
      let searchControl = form.controles.map(c=> { return new FormControl(c) });
    
      let searchComponent:FormControlComponent[] = [];

      searchControl.forEach(sc => {
        searchComponent = searchComponent.concat(sc.componentes);    
      });

      let componentsData = searchComponent.map(c=> this.getComponentData(form, c));

      return componentsData.filter(d => d.nombreComponente == this.searchProviderComponentName);
    }));

  }

  getComponentData(form:Form , component:FormControlComponent): FormComponentData{
    let componentData = new FormComponentData();
    componentData.nombreComponente = component.nombre;
    componentData.contenedor = component.contenedor;
    componentData.valor = component.valor;
    componentData.idControlPadre = component.idControl;
    componentData.idFormulario = form.id;
    componentData.nombreFormulario = form.nombre;

    return componentData;  
  }
}
