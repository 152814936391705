
<div class="chat" *ngIf="virtualAttention" [ngClass]="{'minimized': chatWrapper.minimized, 'connection-lost': chatWrapper.connectionLost}">
  <form [hidden]="chatWrapper.minimized">

    <!--Chat Waiting for attention -->
    <div class="waiting-room" *ngIf="chatWrapper.isWaitingForAttention() && !chatWrapper.requestIsFinished()">
      <div class="chat-header">
        <div class="header-title">{{ 'shared.chatWidget.text1' | translate:locale.language }}</div> 
        <!--<div class="header-subtitle">{{ 'shared.chatWidget.text2' | translate:locale.language }}</div>-->        
        <div class="actions">          
          <mat-icon fontSet="phr-iconset" class="phr-iconset-arrown-down" (click)="onMinimizeClick()"></mat-icon>                     
        </div>
      </div>
      <div class="waiting-room-content-container">
        <!--<div class="title-text">
          <div>{{ 'shared.chatWidget.text3' | translate:locale.language }}</div>
          <b>{{ 'shared.chatWidget.text4' | translate:locale.language }}</b>
        </div> -->
        <img class="" *ngIf="chatWrapper.isWaitingForTriage()" src="assets/images/sura/waiting-room.png"/>
        <img class="" *ngIf="!chatWrapper.isWaitingForTriage()" src="assets/images/sura/waiting-room-2.png"/>
        <div class="waiting-img-description">
          <div *ngIf="chatWrapper.isWaitingForTriage()">
            <div>{{ 'shared.chatWidget.text9' | translate:locale.language }}</div>
            <b>{{ 'shared.chatWidget.text10' | translate:locale.language }}</b>
          </div>
          <div *ngIf="!chatWrapper.isWaitingForTriage()">
            <b>
              {{ 'shared.chatWidget.text11' | translate:locale.language }}
            </b>
          </div>          
        </div>
        <div class="progress-bar">
          <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
        </div>        
        <div class="symptom-text">
          {{ 'shared.chatWidget.text5' | translate:locale.language }}<br> 
          <b>{{getSymptoms()}}</b>
        </div>
        <div class="btn-container">
          <button mat-flat-button class="site-button" (click)="onCancelVirtualAttention()">{{ 'shared.chatWidget.text6' | translate:locale.language }}</button>
        </div>
      </div>
    </div>

    <!--Chat Main Container -->
    <div class="chat-main-container" *ngIf="!chatWrapper.isWaitingForAttention() || chatWrapper.requestIsFinished()" [ngClass]="{'loading': chatWrapper.loading}">

      <!--Chat Header -->
      <div class="chat-header">
        <div class="agent-name">{{chatWrapper.getDoctorName()}}</div>        
        <div class="agent-state">
          <span class="agent-active-icon" [ngClass]="{'inactive': !isAgentActive}"></span>
          <span class="agent-state-text" *ngIf="isAgentActive">{{ 'shared.chatWidget.text7' | translate:locale.language }}</span>
          <span class="agent-state-text" *ngIf="!isAgentActive">{{ 'shared.chatWidget.text8' | translate:locale.language }}</span> |
          <span class="chat-time">{{ 'time' | translate:locale.language }}: <b>{{chatWrapper.getChatTime()}}</b></span>
        </div>
        <div class="actions">          
          <mat-icon fontSet="phr-iconset" class="phr-iconset-arrown-down" (click)="onMinimizeClick()"></mat-icon>                     
        </div>
        <div class="loading" *ngIf="chatWrapper.loading">
          <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
        </div>
      </div>

      <!--Chat Messages Container -->
      <div class="chat-msgs-container"
        infiniteScroll [fromRoot]="true" [infiniteScrollDistance]="2" [infiniteScrollContainer]="'.chat-msgs-container'" 
        [infiniteScrollThrottle]="50" (scrolledUp)="onScrollUp()" >
        <div *ngFor="let messageWrapper of chatWrapper.messages; index as i">          
          
          <div class="date-timestamp" *ngIf="chatWrapper.showDateStamp(i)">
            {{ getChatDate(messageWrapper.message.fechaCreacion) }}
          </div>

          <!--User Message -->
          <div class="user-message" *ngIf="messageWrapper.isUserMessage()" [ngClass]="{'user-msg-block': chatWrapper.isUserBlockMessage(i)}">              
            <div class="message-box">
              <span class="message-text" [innerHtml]="messageWrapper.message.valor"></span>
            </div>  
            <span class="message-date" *ngIf="chatWrapper.showMessageTime(i)">
              {{messageWrapper.message.fechaCreacion | date:'shortTime' }}
            </span> 
          </div>

          <!--Agent Message -->
          <div class="agent-message" *ngIf="!messageWrapper.isUserMessage()" [ngClass]="{'agent-msg-block': chatWrapper.isAgentBlockMessage(i)}">              
            <img class="agent-img" [src]="messageWrapper.message.urlImagenEmisor"/>
            <span class="agent-msg">
              <span class="agent-name">{{chatWrapper.getDoctorName()}}</span>  
              <div class="message-box">
                <span class="message-text" [innerHtml]="messageWrapper.message.valor"></span>
              </div>  
              <span class="message-date" *ngIf="chatWrapper.showMessageTime(i)">{{messageWrapper.message.fechaCreacion | date:'shortTime' }}</span>
            </span>               
          </div>
          
        </div> 
      </div>

      <!--Chat Footer -->
      <div class="chat-footer" *ngIf="!chatWrapper.isWaitingForAttention() || chatWrapper.requestIsFinished()">
        <input type="text" [(ngModel)]="currentMessage" (keydown.enter)="sendMessage()" placeholder="Escribe tu mensaje" name="chatMessage" class="input-message" [disabled]="chatWrapper.isVirtualAttentionFinished">
        <span class="footer-actions">
          <mat-icon fontSet="phr-iconset" class="phr-iconset-nearme" [ngClass]="{'icon-enabled':!chatWrapper.isVirtualAttentionFinished,'icon-disabled':chatWrapper.isVirtualAttentionFinished}" (click)="sendMessage()" ></mat-icon> 
        </span>
      </div>

    </div>

  </form>

  <!--Chat Button Container -->
  <div class="chat-btn-container" [ngClass]="{'open': !chatWrapper.minimized}" (click)="onChatBtnClick()">
    <div class="chat-btn">
      <img src="assets/images/sura/chat-icon.svg" />
      <mat-icon fontSet="phr-iconset" class="phr-iconset-close"></mat-icon>          
      <span class="chat-new-msgs-budget" *ngIf="chatWrapper.unreadMessagesCount > 0">
        {{chatWrapper.unreadMessagesCount}}
      </span>
    </div>
  </div>

</div>