<div class="next-appointment-dialog-container" ngClass.lt-md="mobile">
    
    <!--<a class="btn-close" (click)="close()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Cerrar"></mat-icon> 
    </a>-->

    <div class="appointment-card-container">
        <!-- Appointment Card -->
        <app-cliniweb-appointment [loading]="loading" [directory]="directory" 
            [dependents]="dependents" [loggedInUser]="loggedInUser" [app]="appointment" [popupView]="true" (onClose)="close()" >
        </app-cliniweb-appointment>
        <div class="card-left-indicator"></div>
    </div>

</div>