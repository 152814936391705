<div class="cliniweb-menu-container">
    <div class="nav-container">
        <mat-nav-list [ngClass]="{'profile-menu-open': profileMenuOpen, 'mi-salud-menu-open': miSaludMenuOpen}">
            <menu-link *ngFor="let link of links" [link]="link" (click)="closeProfileMenu()"></menu-link>        
            
            <input [matMenuTriggerFor]="profileFloatingMenu" type="hidden" />
            <a *ngIf="loggedInUser" class="profile-btn" (click)="openProfile($event)">
                <img [src]="loggedInUser.ubicacionLogo" />
                <span>{{'shared.cliniwebMenu.profileText' | translate:locale.language}}</span>
            </a>        
        </mat-nav-list>
    </div>  

    <mat-menu
        #profileFloatingMenu="matMenu"
        backdropClass="backdrop-without-footer"
        class="profile-menu-floating-container"
        hasBackdrop="true"
        [overlapTrigger]="false"
    >
        <a class="btn-close" (click)="closeProfileMenu()">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Cerrar"></mat-icon> 
        </a>
        <app-profile-menu *ngIf="loggedInUser" (click)="$event.stopPropagation();"></app-profile-menu>
    </mat-menu>
</div>
