import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacyPoliciesComponent } from './pages/privacy-policies/privacy-policies.component';
import { PrivacyPoliciesSuraComponent } from './components/privacy-policies-sura/privacy-policies-sura.component';
import { PrivacyPoliciesRoutingModule } from './privacy-policies-routing.module';
import { CoreModule } from 'src/app/core/core.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { L10nTranslationModule } from 'angular-l10n';

@NgModule({
  declarations: [
    PrivacyPoliciesComponent, 
    PrivacyPoliciesSuraComponent],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    PrivacyPoliciesRoutingModule,
    L10nTranslationModule    
  ],
  /* Every component (that is render dinamically) needs to be added here in order to be rendered after deploy
     If the component is NOT added when angular run the compilaiton and optimization, as these components are render dinamically  
     and NOT refereced in any page, angular will remove it from the bundle result.
  */
  bootstrap: [
    PrivacyPoliciesComponent,
    PrivacyPoliciesSuraComponent
  ]
})
export class PrivacyPoliciesModule { }
