<div class="cliniweb-medical-appointments-linbox-container">
    <mat-card class="linkbox-card" (click)="onLinkClick()">
        <mat-card-content>
            <div class="linkbox-upper-container">
                <span>{{ 'shared.cliniwebMedicalAppointmentsLinbox.text1' | translate:locale.language }}</span>
                <img class="card-img" src="assets/images/cliniweb/agenda.png" />
            </div>
            <div class="linkbox-container">
                <span class="mobile">{{ 'shared.cliniwebMedicalAppointmentsLinbox.text1' | translate:locale.language }}</span>
                <span class="desktop">{{ 'shared.cliniwebMedicalAppointmentsLinbox.text2' | translate:locale.language }}</span>
                <mat-icon fontSet="phr-iconset" class="phr-iconset-right-arrow icon phr-iconset"></mat-icon>
            </div>            
        </mat-card-content>
    </mat-card>
</div>

