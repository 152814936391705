import { SignupWorkflowVerificationType } from "src/app/shared/enums/signup-workflow-verification.enum";
import { PublicProfile } from "../../people/public-profile.model";

export class AuthenticationDataModel{
    idVerificacion: string;
    idTipoSolicitud: number;
    idUsuario: number;
    doctorAvatarUrl: string;  
    configData: AuthenticationComponentData;
    profile: PublicProfile;
}

export class AuthenticationComponentData{    
    showCliniwebSection: boolean;
    signupWorkflowVerificationType: SignupWorkflowVerificationType;
    workflowType: number;
  }