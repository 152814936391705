
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { L10nLocale, L10N_LOCALE, L10nTranslationService } from 'angular-l10n';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { AppStorageService, STORAGE } from 'src/app/core/services/app-storage.service';
import { AuthOidcService } from 'src/app/core/services/auth-oidc.service';
import { BaseService } from 'src/app/core/services/base.service';
import { CloseRouteDialogMessage, LoginCompletedMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { UserService } from 'src/app/core/services/user.service';
import { LoginService } from '../../login.service';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { RouteDialogType } from 'src/app/shared/components/dialogs/route-dialog/route-dialog.component';
import { OAuthFlowType, OAuthSourceType, TokenState } from 'src/app/shared/models/token.model';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { UserExistsResponseModel } from 'src/app/shared/models/people/user-exists-response.model';
import { LoginResponse } from 'src/app/shared/models/login-response.model';
import { CustomEmailValidator } from 'src/app/shared/validators/custom-email.validator';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginFormComponent implements OnInit, OnDestroy {
  alreadyExistsGoogleUser = false;
  showForm:boolean = false;
  loginForm: FormGroup;
  loading = false;
  loadingByGoogle = false;
  submitted = false;
  error = '';
  showPassword: boolean = false;
  companyName: string;
  returnUrl: string;
  homeUrl: string;
  closeOnBack: boolean = false;

  protected ngUnsubscribe = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private baseService: BaseService,
    private authOidcService: AuthOidcService,
    private appStorageService: AppStorageService,
    private loginService: LoginService,    
    private userService: UserService,
    private routeDialogService: RouteDialogService,
    private messageBusService: MessageBusService,
    private translation: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private workflowService: WorkflowService
  ) {

    this.loginService.isLoggedIn()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(isLoggedIn => {
        this.showForm = true;

        if(isLoggedIn) {
          this.userService.getUserPersonLoggedIn().subscribe(user => {
            if (user)
              this.messageBusService.loginCompleted(new LoginCompletedMessage(user));

              this.getQsParams();
              //this.router.navigate([returnUrl || '/']); 

              this.goToHomePage();
          }); 
        }
      });

    this.baseService.isLicensedCompany()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(result=>{
      this.companyName = result 
        ? this.baseService.getDirectoryTitle()
        : 'Cliniweb';
    });
  }

  getQsParams(): void {
    this.closeOnBack = "true" == this.route.snapshot.queryParams['closeOnBack'];
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    this.homeUrl = this.route.snapshot.queryParams['homeUrl'];

    if (this.returnUrl)
      this.returnUrl = decodeURIComponent(this.returnUrl);
  }

  ngOnInit(): void {
    this.getQsParams();

    this.loginForm = this.formBuilder.group(
      {
        username: ['', [Validators.required, CustomEmailValidator]],
        password: ['', [Validators.required]]
      },
      { 
        updateOn: "submit" 
      }
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSubmit(): void {
    this.error = '';    
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.userService.postExistsUser(this.loginForm.controls.username.value).subscribe({
      next: this.existsUserNext.bind(this),
      error: this.onError.bind(this)
    });

  }
  
  private existsUserNext(arg: UserExistsResponseModel): void {
    this.alreadyExistsGoogleUser = arg.googleAuthentication;
    if (!arg.googleAuthentication) {
      this.loginService.login(this.loginForm.controls.username.value, this.loginForm.controls.password.value)
        .pipe(first())
        .subscribe({ next: this.loginNext.bind(this), error: this.onError.bind(this) });
    } else {
      this.loading = false;
    }
  }
  
  private loginNext(args: LoginResponse): void {
    if (args.success) {
      
      if (location.pathname.indexOf("welcome/login") == -1)
        this.messageBusService.showHeader();
      
      this.userService.getUserPersonLoggedIn().subscribe(user => {
        if (user) {
          this.messageBusService.loginCompleted(new LoginCompletedMessage(user));
          this.goToHomePage();
        } else {
          this.onError();
        }                
        // get return url from route parameters or default to '/'
        //this.router.navigate([this.returnUrl || '/']);  
        
      });                   
    } else {
      this.onError(args.message);
    }
  }
  private onError(error?: any): void {
    this.loading = false;
    this.error = error?.message ?? error ?? "Hubo un error, intente nuevamente";
  }

  loginByGoogle(){
    this.loadingByGoogle = true;
    this.alreadyExistsGoogleUser = false;

    var url = this.returnUrl? this.returnUrl : this.homeUrl;

    if (!url)
      url = "/";

    let oidcModel: TokenState;
    let workflowType = this.workflowService.getWorkflowTypeActive();

    if (workflowType) {
      oidcModel = this.workflowService.getTokenStateFromWorkflow();
    }
    else {
      oidcModel = new TokenState();
    }

    oidcModel.returnUrl = url;
    oidcModel.flowType = OAuthFlowType.Login;
    oidcModel.sourceType = OAuthSourceType.Google;

    let stringState = JSON.stringify(oidcModel);

    this.authOidcService.startAuthentication(stringState);

    /*this.authOidcService.startAuthenticationPopup()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(token => {
      this.loadingByGoogle = false;

      if(token.isInvalid) {
        if (token.invalidMessage == "Popup window closed") {
          return;
        } else {
          this.error = "Hubo un error, intente nuevamente";
        }        
      } else {
        this.appStorageService.setToken(token);

        this.userService.getUserPersonLoggedIn()
        .subscribe(user => {
          if (user) {
            this.messageBusService.loginCompleted(new LoginCompletedMessage(user));

            if (token.userCreated) {
              let snackBarMessage = new OpenSnackBarMessage();

              snackBarMessage.type = SnackBarType.SUCCESS;
              snackBarMessage.text = this.translation.translate('login.userCreated');
              snackBarMessage.duration = 3000;
              this.messageBusService.openSnackBar(snackBarMessage);
            }

            this.goToHomePage();
          } else {
            this.error = "Hubo un error, intente nuevamente";
          }          
        },
        error => {
          this.loadingByGoogle = false;
          this.error = "Hubo un error, intente nuevamente";
        });
      }
    });*/
  }

  onForgotPassword() {
  }

  goToHomePage() {
    if(this.closeOnBack && this.routeDialogService.isOpen(RouteDialogType.LOGIN)) {
      this.messageBusService.closeRouteDialog(new CloseRouteDialogMessage());
    } else {
      let homeUrl = this.homeUrl;

      if (!homeUrl) {
        homeUrl = this.returnUrl || '/';
      }

      this.baseService.goToRoute(homeUrl);
    }    
  }

  onClickCreateAccount(){
    let activeWorkflowType = this.workflowService.getWorkflowTypeActive();

    switch(activeWorkflowType){
      case STORAGE.ONLINE_APPOINTMENT_CLINIWEB_STATE:
      case STORAGE.ONLINE_PAYMENT_WORKFLOW_STATE:
        if(this.returnUrl?.includes('checkout')){
          this.router.navigate(['signup/checkin'], { queryParams: {returnUrl: this.returnUrl, closeUrl: this.returnUrl + '?close=true' }});
        }
        else{
          this.router.navigate(['signup'], { queryParams: {returnUrl: this.returnUrl, homeUrl: this.homeUrl}});
        }
      break;
      case STORAGE.WELCOME_CLINIWEB_STATE:
        this.router.navigate(['welcome/signup'], { queryParams: {returnUrl: this.returnUrl, homeUrl: this.homeUrl}});
        break;
      default:
        this.router.navigate(['signup'], { queryParams: {returnUrl: this.returnUrl, homeUrl: this.homeUrl}});
      break;
    }

  }
}
