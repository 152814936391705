import { Dependent } from "../people/dependent.model";
import { PublicProfile } from "../people/public-profile.model";
import { Relation } from "../people/relation.model";
import { BaseRequestModel } from "./base-request.model";

export class WorkflowInitialData extends BaseRequestModel {
    idPaciente?: number;
    idTipoSolicitud?: number;
    emailPaciente: string;
    nombrePaciente: string;
    isValid: boolean;
    publicProfile: PublicProfile;
    relations: Relation[];
    dependents: Dependent[];
    workflowPosition:number = 0;
    idUsuario: number;
}