import { TextLanguage } from "../people/text-language.model";

export class GrowthMeasurementType{
    id: ChartType;
    rangos: GrowthMeasurementRange[];
}

export class GrowthMeasurementRange{
    id: number;
    nombre: string;
    rangoInicial: number;
    rangoFinal: number;
    codigoUnidadMedida: number;
    nombreUnidadMedida: string;
    codigoEstandar: GrowthMeasurementStandard;
    intervaloX: number;
    intervaloY: number;
}

export enum ChartType{
    SIZE_FOR_AGE = 1,
    WEIGHT_FOR_AGE = 2,
    WEIGHT_FOR_SIZE = 3,
    BMI_FOR_AGE = 4,
    HEAD_CIRCUMFERENCE_FOR_AGE = 5
}

export enum GrowthMeasurementStandard{
    OMS = 1,
    CDC = 2
}

export enum GrowthMeasurementUnitType{
    YEARS = 479,
    MONTHS = 481,
    WEEKS = 486 
}