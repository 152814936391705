import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';
import { LayoutService } from 'src/app/core/services/layout.service';
import { Link } from '../../models/people/link.model';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'menu-link',
  templateUrl: './menu-link.component.html',
  styleUrls: ['./menu-link.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MenuLinkComponent implements OnChanges, OnInit, OnDestroy {
  ID_AREA_SISTEMA_MIS_CITAS: number = 3459;
  ID_AREA_SISTEMA_MI_SALUD: number = 4240;

  expanded: boolean = true;
  hasOptions = false;
  showNotification: boolean = false;
  notificationValue: number = 0;
  private ngUnsubscribe = new Subject();

  @Input() link: Link;
  isMobile$: Observable<boolean>;

  constructor(
    private router: Router,
    private baseService: BaseService,
    private mediaObserver: MediaObserver,
    private layoutService: LayoutService,
    private messageBusService: MessageBusService,
    private appointmentService: AppointmentService,
    private authService: AuthService,
    @Inject(L10N_LOCALE) public locale: L10nLocale, 
    public dialog: MatDialog
  ) {
    this.isMobile$ = this.mediaObserver
      .asObservable()
      .pipe(
        map((changes) =>
          changes.some(
            (change: MediaChange) => change.mqAlias === "lt-md"
          )
        )
    );

    this.messageBusService.onToggleBottomSheetSubject()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(this.onToggleBottomSheetSubjectNext.bind(this));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.link) {
      if (this.link.idAreaSistema == this.ID_AREA_SISTEMA_MI_SALUD) {
        this.expanded = false;
        this.hasOptions = false;
      } else {
        this.hasOptions = this.link.subMenu?.length > 0;
      }
    }  
  }

  private onToggleBottomSheetSubjectNext(args: { action: string, component?: any}) {
    const menuItem: Element | null = document.querySelector('.mi-salud > a');
    if (args.action == 'open') {
      menuItem?.classList.add('active', 'open');
    } else
      menuItem?.classList.remove('active', 'open');
  }

  ngOnInit(): void {
    this.messageBusService.onLoginCompleted()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(msg => {
        if (this.link.idAreaSistema == this.ID_AREA_SISTEMA_MIS_CITAS) {
          this.getAppointments();
        }
      }
    );

    if (!this.authService.isLoggedIn()) {
      return;
    }      

    if (this.link.idAreaSistema == this.ID_AREA_SISTEMA_MIS_CITAS) {
        this.getAppointments();
    }    
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getAppointments() {
    this.appointmentService.getAppointmentsWithCache(this.locale.language)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      appointments => {
          this.showNotification = appointments.cantidadCitasFuturas > 0;
          this.notificationValue = appointments.cantidadCitasFuturas;
        } 
    );

    this.messageBusService.onAppointmentChangeReceived()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      appointment =>  {
          this.showNotification = appointment.cantidadCitasFuturas > 0;
          this.notificationValue = appointment.cantidadCitasFuturas;
      }
    );    
  }

  onLinkSelected(link:Link): void {
    const isMiSalud = this.link.idAreaSistema == this.ID_AREA_SISTEMA_MI_SALUD;
    if (isMiSalud || !link.subMenu?.length) {
      let linkUrl = link.identificadorUrl;
      
      // For search result lets add the current URL to the paremerters
      if (link.identificadorUrl.startsWith("busqueda")) {
        linkUrl += (linkUrl.indexOf("?") > 0? "&" : "?") + "rUrl=" + this.router.url;
      }
      
      this.baseService.goToRoute(linkUrl);
      this.layoutService.postNavigate();
    }

    if (link.subMenu?.length && !isMiSalud) {
      this.expanded = !this.expanded;
    }
  }

  isLinkActive(link:Link){    
    let url = this.router.url.substr(1).split("/")[0].split("?")[0];

    if (!url)
      url = "inicio";

    return this.baseService.getBasePath(link.identificadorUrl) == url;
  }

  isSublinkActive(link:Link){
    let url = this.router.url.substr(1).split("/")[0].split("?")[0];

    if (!url)
      url = "inicio";

    return link.subMenu && link.subMenu.length > 0
      ? link.subMenu.filter(sublink => this.baseService.getBasePath(sublink.identificadorUrl) == url).length > 0
      : false;
  }

  getTooltip(link:Link) {
    return link.tooltip? link.tooltip : "";
  }

  getIconName(link:Link) {
    let isLinkActive = this.isLinkActive(link);
    let iconName = link.nombreIcono;

    if (isLinkActive) {
      switch(link.nombreIcono) {
        case "PHR-inicio-1":
          iconName = "PHR-inicio-2";
          break;
        case "PHR-date-2":
          iconName = "PHR-date-1";
          break;
        case "PHR-buscar":
          iconName = "PHR-buscar-2";
          break;
        case "fav":
          iconName = "favfill";
          break;
      }
    }

    return iconName;
  }

}
