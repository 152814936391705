import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { UserService } from 'src/app/core/services/user.service';
import { RouteDialogType } from 'src/app/shared/components/dialogs/route-dialog/route-dialog.component';
import { PeopleSupportRequest } from 'src/app/shared/interfaces/people-support-request';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';

@Component({
  selector: 'app-support-contact',
  templateUrl: './support-contact.component.html',
  styleUrls: ['./support-contact.component.css']
})
export class SupportContactComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  formulario = this.fb.group({
    texto: ['', [Validators.required]]
  });
  loading = false;

  constructor(
    private baseService: BaseService,
    private fb: FormBuilder,
    private messageBusService: MessageBusService,
    private patientService: PatientService,
    private route: ActivatedRoute,
    private routeDialogService: RouteDialogService,
    private router: Router,
    private toastrService: ToastrService,
    private translation: L10nTranslationService,
    private userService: UserService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {
    this.messageBusService.onNavigateBack()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(x => this.closeDialog());
  }
  
  ngOnInit(): void {
  }
  
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  closeDialog(): void {
    const returnUrl: string = this.route.snapshot.queryParams['returnUrl'];
    this.routeDialogService.closeDialog(RouteDialogType.CONTACT);
    this.router.navigateByUrl(returnUrl ?? '/');
  }

  onSubmit(): void {
    this.loading = true;
    this.userService.getUserPersonLoggedIn()
    .subscribe({
      next: this.procesarGetUserPersonLoggedIn.bind(this),
      error: this.postSupportError.bind(this)
    });
  }

  private procesarGetUserPersonLoggedIn(user: UserPerson | null): void {
    this.baseService.getDirectoryDomain()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((domain:string)=>{
      const directoryDomain:string = domain;

      const returnUrl:string = this.route.snapshot.queryParams['returnUrl'];

      const model: PeopleSupportRequest = {
        idEmpresa: user?.idEmpresa ?? 0,
        idUsuario: user?.id ?? 0,
        contenido: this.formulario.get('texto')?.value,
        emailParticular: user?.emailParticular ?? '',
        fecha: new Date().toJSON(),
        nombre: `${user?.nombre} ${user?.apellido}`,
        nombreEmpresa: directoryDomain,
        numeroTelCelular: user?.numeroTelCelular ?? '',
        pagina: returnUrl ?? 'No se especificó'
      };

      this.patientService.postSupport(model).subscribe({
        next: this.postSupportNext.bind(this),
        error: this.postSupportError.bind(this)
      });

    });
  }

  private postSupportNext(args: any): void {
    this.loading = false;
    this.closeDialog();
    this.toastrService.success(this.translation.translate("shared.supporContactMessageDialog.text6"), "");
  }

  private postSupportError(error: any): void {
    this.loading = false;
    this.toastrService.error(this.translation.translate("shared.supporContactMessageDialog.text8"), this.translation.translate("shared.supporContactMessageDialog.text7"));
  }

}
