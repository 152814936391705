import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { ClinicHistory } from 'src/app/shared/models/clinic-history/clinic-history.model';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import * as moment from 'moment';
import { ClinicHistoryService } from 'src/app/core/services/clinic-history.service';
import { UserService } from 'src/app/core/services/user.service';
import { BaseService } from 'src/app/core/services/base.service';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics.service';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.css']
})
export class ShareDialogComponent implements OnInit {  
  shareDialogData: ShareDialogData;
  allAreasSelected: boolean = false;
  currentView: HcShareView = HcShareView.FilterItems;
  viewEnum = HcShareView;
  clinicHistory: ClinicHistory;  
  filters: AttentionFilters;
  loading = false;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private userService: UserService,
    private clinicHistoryService: ClinicHistoryService,
    private baseService: BaseService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private mdDialogRef: MatDialogRef<ShareDialogComponent> 
  ) { 
    this.shareDialogData = JSON.parse(data.message);

    // By Default all areas will be checked
    this.shareDialogData.areas.forEach(a => a.check = true);

    this.clinicHistory = data.data;    
  }

  ngOnInit(): void {
    this.currentView = HcShareView.SelectItems;

    this.filters = new AttentionFilters();

    this.loadDoctordata();
  }

  loadDoctordata() {
    var data : any = {};

    for (let index = 0; index < this.clinicHistory.historialAtenciones.length; index++) {
      const contact = this.clinicHistory.historialAtenciones[index].contactoClinico;

      if (!data.hasOwnProperty(contact.idResponsableServicio))
        data[contact.idResponsableServicio] = new DoctorData(contact.idResponsableServicio, contact.nombreResponsableServicio);     
      else if (!data[contact.idResponsableServicio].name && contact.nombreResponsableServicio) 
        data[contact.idResponsableServicio].name = contact.nombreResponsableServicio;
    }

    this.filters.initDoctorData(Object.keys(data).map(key => data[key]));
  }  

  public cancel() {
    this.closeDialog(false);    
  }

  public close() {
    this.closeDialog(false);    
  }

  private closeDialog(value:any) {
    this.mdDialogRef.close(value);
  } 

  optionHasFilters(value: number) {
    return value == 1; // For not only "Attention will have filters"
  }

  onBackClick() {
    this.currentView = HcShareView.SelectItems;
  }

  onFilterClick(value: number) {
    if (value == 1)
      this.currentView = HcShareView.FilterItems;
  }

  confirmFilters() {
    this.currentView = HcShareView.SelectItems;
  }

  shareButtonEnabled() {
    return this.shareDialogData.areas.filter(a => a.check).length > 0;
  }  

  public someAreaSelected(){
    if (this.shareDialogData == null || this.shareDialogData.areas == null) {
      return false;
    }
    return this.shareDialogData.areas.filter(t => t.check).length > 0 && !this.allAreasSelected;
  }

  filterDoctors(searchText: string) {
    return this.filters.filterDoctors(searchText);
  }  

  getDoctorDisplayName(doctor: DoctorData) {
    return doctor.name;
  }

  onDoctorSelectionChanged(selectedDoctors: DoctorData[]) {
    this.filters.setSelectedDoctors(selectedDoctors);    
  }

  getSummary(filter: AttentionFilters) {
    var result = "";    

    if (filter.startDate) {
      let startDate = this.baseService.getBasicDateFormat(new Date(filter.startDate));
      result += startDate;
    }

    if (filter.endDate) {
      let endDate = this.baseService.getBasicDateFormat(new Date(filter.endDate));
      result += result? (" - " +  endDate) : endDate;
    }      

    let selectedDocs = filter.getSelectedDoctors();

    if (selectedDocs.length > 0) {
      result += result? ("<br/>" +  selectedDocs.map(d => d.name).join(", ")) : selectedDocs.map(d => d.name).join(", ");
    }

    return result;
  }

  share() {
    let model: any = {};

    // Sections
    let selectedSections = this.shareDialogData.areas.filter(a => a.check);

    if (selectedSections.length > 0)
      model.Secciones = selectedSections.map(s => s.valor);

    // Filters
    if (!this.filters.areEmpty()) {      
      if (this.filters.startDate)
        model.FechaIncio = this.filters.startDate;
      if (this.filters.endDate)
        model.FechaFin = this.filters.endDate;
      
      let selectedDocs = this.filters.getSelectedDoctors();
      if (selectedDocs.length > 0)
        model.IdsResponsablesServicios = selectedDocs.map(d => d.id);
    }

    // User
    this.userService.getUserPersonLoggedIn().subscribe(p => {
      if (p) {
        model.IdPaciente = p.id;

        console.log(model);
        this.loading = true;

        this.clinicHistoryService.getClinicHistoryPdf(model).subscribe((data) => {

          this.googleAnalyticsService.sendEventDownloadClinicHistory();

          let blob = new Blob([data], {type: 'application/pdf'});
        
          var downloadURL = window.URL.createObjectURL(data);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = this.getDownloadFileName(p);

          link.click();    

          this.loading = false;
          this.close();
        },
        (error => {
          console.error("Error getting pdf");

          this.loading = false;
          this.close();
        }));
      }     
      
    });    
  }

  getDownloadFileName(user: UserPerson) {
    let name = "Historia clínica - " + this.capitalize(user.nombre) + " " + this.capitalize(user.apellido) + " - ";
    let now = moment(new Date());

    // Ej: Historia clínica - Juan Pérez - 23/feb/2022 3:44 PM.pdf

    name += now.format("DD_MMM_YYYY_hh_mm_") + now.format("a").toUpperCase();

    return name + ".pdf";
  }

  capitalize = function(str: string) {
    str = str.toLowerCase();
    const arr = str.split(" ");

    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    return arr.join(" ");
  }
}

export enum HcShareView {
    SelectItems,
    FilterItems,
    Finished
}

export class ShareDialogData{
  areas:AreaShareDialogData[]
}
export class AreaShareDialogData{
  nombre: string;
  valor: number;
  check: boolean;
}

export class DoctorData {
  name: string;
  id: number;
  checked: boolean;

  constructor(id: number, name: string) {
    this.name = name;
    this.id = id;
  }

  getIdentifier() {
    return this.id.toString();
  }

  getDisplayName() {
    return this.name;
  }
}

export class AttentionFilters {
  startDate: string;
  endDate: string;

  doctors: DoctorData[];
  filteredDoctors : DoctorData[];
  selectedDoctors : DoctorData[];  

  constructor() {
    this.doctors = [];
    this.filteredDoctors = [];
    this.selectedDoctors = [];
  }

  initDoctorData(doctors: DoctorData[]) {
    this.doctors = doctors;
    this.filteredDoctors = doctors;    

    this.addAllDoctorsSelection();
  }

  addAllDoctorsSelection() {
    this.selectedDoctors = [new DoctorData(-1, "Todos")];    
  }

  removeAllDoctorsSelection() {
    this.selectedDoctors = this.selectedDoctors.filter(d => d.id > 0);    
  }

  getSelectedDoctors() {
    return this.selectedDoctors.filter(d => d.id > 0);
  }

  setSelectedDoctors(selectedDoctors : DoctorData[]) {
    this.selectedDoctors = selectedDoctors;

    if (selectedDoctors.length == 0)
      this.addAllDoctorsSelection();
    else if (selectedDoctors.length > 1)
      this.removeAllDoctorsSelection();
  }

  areEmpty() {
    return !this.startDate && this.getSelectedDoctors().length == 0;
  }

  clear() {
    this.startDate = undefined as any;
    this.endDate = undefined as any;
    this.filteredDoctors = this.doctors;
    this.addAllDoctorsSelection();
  }

  filterDoctors(searchText: string) {
    searchText = searchText.toLowerCase();
    let selectedIds = this.selectedDoctors.map(d => d.id);    

    this.filteredDoctors = this.doctors.filter(d => d.id > 0 && d.name.toLowerCase().indexOf(searchText) == 0 && selectedIds.indexOf(d.id) == -1);
  }  
} 