<div class="patient-form-container" appTouchMoveListener>
    <app-appointment-info-checkout [model]="model.appointmentInfo" [show]="showAppointmentInfo"></app-appointment-info-checkout>
    <mat-divider vertical *ngIf="showAppointmentInfo"></mat-divider>
    <div class="content">
        <span class="title">{{ 'shared.patientFormAppointmentCheckout.title' + model.relationship | translate:locale.language }}</span>
        
        <!--Form-->
        <form class="patient-form" [formGroup]="patientForm" (ngSubmit)="onClickContinue()">
            <div class="patient-form-content">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'formfield.firstname' | translate:locale.language }}</mat-label>
                    <input appCenterInput matInput formControlName="firstname" class="capitalize-text" appCapitalize>
                    <mat-error *ngIf="isControlInvalid('firstname')">
                        {{'validation.firstnameRequired' | translate:locale.language}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'formfield.lastname' | translate:locale.language }}</mat-label>
                    <input appCenterInput matInput formControlName="lastname" class="capitalize-text" appCapitalize>
                    <mat-error *ngIf="isControlInvalid('lastname')">
                        {{'validation.lastnameRequired' | translate:locale.language}}
                    </mat-error>
                </mat-form-field>
                <app-datepicker-flat-field
                    [label]="'formfield.birthdate' | translate:locale.language"
                    [maxDate]="maxDate"
                    [minDate]="minDate"
                    [errorMessage]="'validation.birthdateRequired' | translate:locale.language"
                    formControlName="birthdate"
                ></app-datepicker-flat-field>
                <div class="gender-container form-field-container">
                    <mat-label>{{ 'formfield.gender' | translate:locale.language }}</mat-label>
                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" formControlName="gender" 
                        [ngClass]="{ 'invalid-control': isControlInvalid('gender') }">
                        <mat-button-toggle value="M">
                            <mat-icon fontSet="phr-iconset" class="icon phr-iconset phr-iconset-hombre"></mat-icon>
                            <span>{{'formfield.gender1' | translate:locale.language}}</span>
                        </mat-button-toggle>
                        <mat-button-toggle value="F">
                            <mat-icon fontSet="phr-iconset" class="icon phr-iconset phr-iconset-mujer"></mat-icon>
                            <span>{{'formfield.gender2' | translate:locale.language}}</span>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                    <mat-error *ngIf="isControlInvalid('gender')">
                        {{'validation.genderRequired' | translate:locale.language}}
                    </mat-error>
                </div>
            </div>

            <!--Buttons container-->
            <div class="button-container">
                <div class="appointment-info-mobile" (click)="openAppointmentInfoDialog()">
                    <span>{{ model.appointmentInfo.appointmentDate | date:'d MMM, h:mm a': 'UTC': language }}</span>
                    <span> • </span>
                    <span>{{ model.appointmentInfo.attentionPlaceName }}</span>
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-arrow-down-big"></mat-icon>
                </div>
                <button mat-button mat-flat-button class="primary" [ngClass]="{'loading': loading }">
                    {{ 'shared.patientFormAppointmentCheckout.buttonText' | translate:locale.language }}
                </button>
            </div>
        </form>
    </div>
</div>