import { IWorkflowDataState } from "../../../interfaces/workflow-data-state";
import { OnlineAppointmentRequest } from "../../process/online-appointment-request.model";
import { AppointmentSummaryDataModel } from "../models/appointment-summary-data.model";
import { IAuthenticationWorkflowState } from "../steps/authentication-step.model";
import { IPatientContactFormAppointmentCheckoutWorkflowState } from "../steps/patient-contact-form-appointment-checkout-step.model";
import { IPatientFormAppointmentCheckoutWorkflowState } from "../steps/patient-form-appointment-checkout-step.model";
import { ISelectPatientAppointmentCheckoutWorkflowState } from "../steps/select-patient-appointment-checkout-step.model";
import { ISelectPatientRelationshipAppointmentCheckoutWorkflowState } from "../steps/select-patient-relationship-appointment-checkout-step.model";
import { ISummaryAppointmentCheckoutWorkflowState } from "../steps/summary-appointment-checkout-step.model";
import { RequestingPatient, RequestingUser, SenderPatientRelationship } from "../../process/base-request.model";
import { BaseWorkflowState } from "./base-workflow-state.model";
import * as moment from "moment";
import { AppointmentInfoDataModel, AppointmentType } from "../models/appointment-info-data.model";
import { PatientFormAppointmentDataModel } from "../models/patient-form-appointment-data.model";
import { UserPerson } from "../../people/user-person.model";
import { PersonDataModel } from "../models/select-patient-data.model";
import { PublicProfile } from "../../people/public-profile.model";
import { StateType } from "src/app/shared/enums/state-type.enum";
import CommonTypesHelper from "src/app/shared/helpers/common-types-helper";
import { ISelectCountryWorkflowState } from "../steps/select-country-step.model";
import { TerritorialDivision } from "../../systems/territorial-division.model";

export class OnlineAppointmentCliniwebState 
  extends BaseWorkflowState 
  implements 
    IWorkflowDataState, 
    IAuthenticationWorkflowState,
    ISelectCountryWorkflowState,
    ISelectPatientAppointmentCheckoutWorkflowState,
    IPatientFormAppointmentCheckoutWorkflowState,
    ISelectPatientRelationshipAppointmentCheckoutWorkflowState,
    IPatientContactFormAppointmentCheckoutWorkflowState,
    ISummaryAppointmentCheckoutWorkflowState{

    onlineAppointmentRequest: OnlineAppointmentRequest;
    idVerificacion: string;

    request: any;

    constructor();
    constructor(obj: OnlineAppointmentCliniwebState);
    constructor(obj?:any){
      super();
      
      this.onlineAppointmentRequest = obj && obj.onlineAppointmentRequest || new OnlineAppointmentRequest();
      this.idVerificacion = obj && obj.idVerificacion || null;
      
      if (obj)
        this.request = obj.request;
    }

    public getPosition(): number {
      return this.onlineAppointmentRequest.workflowPosition;
    }

    public setPosition(position: number): void {
      this.onlineAppointmentRequest.workflowPosition = position;
    }

    public getIdVerificacion(): string {
      return this.idVerificacion;
    }

    getIdTipoSolicitud(): number {
      return -1;
    }

    public getDoctorAvatarUrl(): string {
      return this.onlineAppointmentRequest.urlAvatarResponsableServicioDestino;
    }

    setPublicProfile(profile: PublicProfile):void {
      this.onlineAppointmentRequest.perfilPublico = profile;
    }

    getPublicProfile():PublicProfile {
      return this.onlineAppointmentRequest.perfilPublico;
    }

    public setUserData(userPerson: UserPerson): void {
      this.onlineAppointmentRequest.emailPersonaEmisora = userPerson.emailParticular;

      if(!this.onlineAppointmentRequest.usuarioSolicitante){
        this.onlineAppointmentRequest.usuarioSolicitante = new RequestingUser();
      }

      this.onlineAppointmentRequest.usuarioSolicitante.id = userPerson.id;
      this.onlineAppointmentRequest.usuarioSolicitante.username = userPerson.emailParticular;      
      this.onlineAppointmentRequest.usuarioSolicitante.sexo = userPerson.sexo;
      this.onlineAppointmentRequest.usuarioSolicitante.fechaNacimiento = userPerson.fechaNacimiento;
      this.onlineAppointmentRequest.usuarioSolicitante.urlAvatar = userPerson.ubicacionLogo;
      this.onlineAppointmentRequest.usuarioSolicitante.nombre = userPerson.nombre;
      this.onlineAppointmentRequest.usuarioSolicitante.apellido = userPerson.apellido;
      this.onlineAppointmentRequest.usuarioSolicitante.idEstadoValidacionIdentidadPersona = userPerson.idEstadoValidacionIdentidadPersona;
      this.onlineAppointmentRequest.usuarioSolicitante.idPaisResidencia = userPerson.idPaisResidencia;

      if(userPerson.numeroTelCelular) {
        this.onlineAppointmentRequest.numeroCelularContacto = userPerson.numeroTelCelular;
      }
    }

    public getUserId(): number {
      return this.onlineAppointmentRequest.usuarioSolicitante && this.onlineAppointmentRequest.usuarioSolicitante.id
        ? this.onlineAppointmentRequest.usuarioSolicitante.id
        : 0;
    }

    public setEmmiterPersonId(personId: number): void {
      this.onlineAppointmentRequest.idPersonaEmisora = personId;
    }

    public getAppointmentInfo(): AppointmentInfoDataModel {
      let appointmentInfo = new AppointmentInfoDataModel();
    
      appointmentInfo.appointmentType = this.onlineAppointmentRequest.teleconsulta == 'S' 
        ? AppointmentType.VIRTUAL 
        : AppointmentType.FACE_TO_FACE;
      appointmentInfo.appointmentDate = moment.utc(this.onlineAppointmentRequest.fechaInicio).toDate();    
      appointmentInfo.doctorName = this.onlineAppointmentRequest.nombreResponsableServicioDestino;
      appointmentInfo.doctorSpecialty = this.onlineAppointmentRequest.especialidadResponsableServicioDestino;
      appointmentInfo.doctorAvatarUrl = this.onlineAppointmentRequest.urlAvatarResponsableServicioDestino;
      appointmentInfo.perfilPublico = this.onlineAppointmentRequest.perfilPublico;
      appointmentInfo.attentionPlaceName = this.onlineAppointmentRequest.nombreLocalidad;
      appointmentInfo.attentionPlaceAddress = this.onlineAppointmentRequest.direccionLocalidad;
      appointmentInfo.attentionPlaceLatLng = this.onlineAppointmentRequest.coordenadasLocalidad;
      appointmentInfo.medicalConsultationType = this.onlineAppointmentRequest.nombreProcedimiento;
      appointmentInfo.idEstado = this.onlineAppointmentRequest.idEstado;
      appointmentInfo.nombreEstado = this.onlineAppointmentRequest.nombreEstado;

      if(this.onlineAppointmentRequest.pacienteSolicitante)
        appointmentInfo.patientName = this.onlineAppointmentRequest.pacienteSolicitante.nombre;

      if(this.onlineAppointmentRequest.pacienteSolicitante && this.onlineAppointmentRequest.pacienteSolicitante.sexo)
        appointmentInfo.patientGender = this.onlineAppointmentRequest.pacienteSolicitante.sexo;

      if(this.onlineAppointmentRequest.relacionEmisorPaciente)
        appointmentInfo.patientRelationshipType = this.onlineAppointmentRequest.relacionEmisorPaciente;
      
      if (this.request && this.request.etapasSolicitud && this.request.etapasSolicitud.length > 0) {
        appointmentInfo.idEtapaSolicitud = this.request.etapasSolicitud[0].id;
        appointmentInfo.idResponsableServicio = this.request.etapasSolicitud[0].idResponsableServicio;
        appointmentInfo.idEmpresa = this.request.etapasSolicitud[0].idEmpresa;
        appointmentInfo.idEstado = this.request.etapasSolicitud[0].idEstado;
        appointmentInfo.nombreEstado = this.request.etapasSolicitud[0].nombreEstado;
      }

      return appointmentInfo;
    }

    public getRelationship(): number {
      return this.onlineAppointmentRequest.relacionEmisorPaciente > 0
        ? this.onlineAppointmentRequest.relacionEmisorPaciente == SenderPatientRelationship.USUARIO 
          ? 1 
          : 2
        : 0;
    }

    public setRelationship(relationshipType: number): void {
      this.onlineAppointmentRequest.relacionEmisorPaciente = relationshipType == 1 
        ? SenderPatientRelationship.USUARIO
        : SenderPatientRelationship.DESCONOCIDO;
    }

    public setPatientId(patientId: number): void {
      if(!this.onlineAppointmentRequest.pacienteSolicitante){
        this.onlineAppointmentRequest.pacienteSolicitante = new RequestingPatient();
      }

      this.onlineAppointmentRequest.pacienteSolicitante.id = patientId;
    }

    public setDependentsFlow(): void {
      this.onlineAppointmentRequest.flujoDependientes = true;
    }

    public getUsername(): string {
      return this.onlineAppointmentRequest.usuarioSolicitante.nombre;
    }

    public getRequestingUser(): RequestingUser {
      return this.onlineAppointmentRequest.usuarioSolicitante;
    }

    public getRequestingPatient(): RequestingPatient {
      return this.onlineAppointmentRequest.pacienteSolicitante;
    }

    public isDependentsFlow(): boolean {
      return this.onlineAppointmentRequest.flujoDependientes;
    }

    public setPatientDataInitiallyIncomplete(incomplete: boolean):void {
      this.onlineAppointmentRequest.patientDataInitiallyIncomplete = incomplete;
    }
    public getPatientDataInitiallyIncomplete(): boolean {
      return this.onlineAppointmentRequest.patientDataInitiallyIncomplete;
    }

    public setPatient(patient: PersonDataModel): void {
      if(!this.onlineAppointmentRequest.pacienteSolicitante)
        this.onlineAppointmentRequest.pacienteSolicitante = new RequestingPatient();

      this.onlineAppointmentRequest.pacienteSolicitante.id = patient.id;
      this.onlineAppointmentRequest.pacienteSolicitante.nombre = patient.firstname;
      this.onlineAppointmentRequest.pacienteSolicitante.apellido = patient.lastname;
      this.onlineAppointmentRequest.pacienteSolicitante.email = patient.email;
      this.onlineAppointmentRequest.pacienteSolicitante.fechaNacimiento = patient.birthDate;
      this.onlineAppointmentRequest.pacienteSolicitante.sexo = patient.gender;
      this.onlineAppointmentRequest.pacienteSolicitante.numeroCelularParticular = patient.cellPhone;
      this.onlineAppointmentRequest.pacienteSolicitante.idEstadoValidacionIdentidadPersona = patient.identityPersonValidationStateId;

      this.onlineAppointmentRequest.pacienteSolicitante.idPaisResidencia = this.onlineAppointmentRequest.usuarioSolicitante.idPaisResidencia;
    }

    public getPatientFormData(): PatientFormAppointmentDataModel {
      
      let patientForm = new PatientFormAppointmentDataModel();

      patientForm.appointmentInfo = this.getAppointmentInfo();
      patientForm.relationship = this.getRelationship();

      if(this.onlineAppointmentRequest.pacienteSolicitante){
        var isUserPatient = this.onlineAppointmentRequest.relacionEmisorPaciente == SenderPatientRelationship.USUARIO;

        patientForm.firstname = isUserPatient && !this.onlineAppointmentRequest.pacienteSolicitante.nombre
          ? this.onlineAppointmentRequest.usuarioSolicitante.nombre
          : this.onlineAppointmentRequest.pacienteSolicitante.nombre;

        patientForm.lastname = isUserPatient && !this.onlineAppointmentRequest.pacienteSolicitante.apellido
          ? this.onlineAppointmentRequest.usuarioSolicitante.apellido
          : this.onlineAppointmentRequest.pacienteSolicitante.apellido;

        patientForm.gender = isUserPatient && !this.onlineAppointmentRequest.pacienteSolicitante.sexo
          ? this.onlineAppointmentRequest.usuarioSolicitante.sexo
          : this.onlineAppointmentRequest.pacienteSolicitante.sexo;

        if (isUserPatient) {
          if (CommonTypesHelper.dateHasValue(this.onlineAppointmentRequest.pacienteSolicitante.fechaNacimiento)) {
            patientForm.birthdate = (this.onlineAppointmentRequest.pacienteSolicitante.fechaNacimiento as Date);
          } else if (CommonTypesHelper.dateHasValue(this.onlineAppointmentRequest.usuarioSolicitante.fechaNacimiento)){
            patientForm.birthdate = (this.onlineAppointmentRequest.usuarioSolicitante.fechaNacimiento as Date);
          }
        } else if (CommonTypesHelper.dateHasValue(this.onlineAppointmentRequest.pacienteSolicitante.fechaNacimiento)) {
          patientForm.birthdate = (this.onlineAppointmentRequest.pacienteSolicitante.fechaNacimiento as Date);
        }

        patientForm.email = this.onlineAppointmentRequest.pacienteSolicitante.email;

        if (isUserPatient) {
          patientForm.identityPersonValidationStateId = this.onlineAppointmentRequest.pacienteSolicitante.idEstadoValidacionIdentidadPersona
          ? this.onlineAppointmentRequest.pacienteSolicitante.idEstadoValidacionIdentidadPersona
          : this.onlineAppointmentRequest.usuarioSolicitante.idEstadoValidacionIdentidadPersona;
        } else {
          // el paciente (dependiente) se crea a partir de la pantalla de dependientes donde todos los campos son requeridos
          patientForm.identityPersonValidationStateId = StateType.NombreApellidoSexoFechaNacimientoValidados;
        }
      }
      
      return patientForm;
    }

    public setPatientFormData(data: PatientFormAppointmentDataModel): void {      
      if(!this.onlineAppointmentRequest.pacienteSolicitante){
        this.onlineAppointmentRequest.pacienteSolicitante = new RequestingPatient();
      }

      this.onlineAppointmentRequest.pacienteSolicitante.nombre = data.firstname;
      this.onlineAppointmentRequest.pacienteSolicitante.apellido = data.lastname;
      this.onlineAppointmentRequest.pacienteSolicitante.denominacion = `${data.firstname} ${data.lastname}`;
      this.onlineAppointmentRequest.pacienteSolicitante.fechaNacimiento = data.birthdate;
      this.onlineAppointmentRequest.pacienteSolicitante.sexo = data.gender;
      this.onlineAppointmentRequest.pacienteSolicitante.idEstadoValidacionIdentidadPersona = data.identityPersonValidationStateId;

      this.onlineAppointmentRequest.pacienteSolicitante.idPaisResidencia = this.onlineAppointmentRequest.usuarioSolicitante.idPaisResidencia;
    }

    public getPatientRelationship(): number {
      return this.onlineAppointmentRequest.relacionEmisorPaciente;
    }

    public setPatientRelationship(patientRelationshipType: number): void {
      this.onlineAppointmentRequest.relacionEmisorPaciente = patientRelationshipType;
    }

    public getPatientName():string{
      return this.onlineAppointmentRequest.pacienteSolicitante.nombre;
    }
    
    public getUserPhone(): string {
      return this.onlineAppointmentRequest.numeroCelularContacto;
    }

    public setUserPhone(userPhone: string): void {
      this.onlineAppointmentRequest.numeroCelularContacto = userPhone;
    }

    public getPatientPhone(): string {
      return this.onlineAppointmentRequest.pacienteSolicitante.numeroCelularParticular;
    }

    public setPatientPhone(patientPhone: string): void {
      this.onlineAppointmentRequest.numeroCelularContacto = patientPhone;
      this.onlineAppointmentRequest.pacienteSolicitante.numeroCelularParticular = patientPhone;
    }

    public getPatientEmail(): string {
      return this.onlineAppointmentRequest.pacienteSolicitante.email;
    }

    public setPatientEmail(patientEmail: string): void {
      this.onlineAppointmentRequest.pacienteSolicitante.email = patientEmail;
    }

    public getContactType(): number {
      return this.onlineAppointmentRequest.tipoContacto;
    }

    public setContactType(contactType: number): void {
      this.onlineAppointmentRequest.tipoContacto = contactType;
    }

    public getAppointmentSummary(): AppointmentSummaryDataModel {
      
      let summary = new AppointmentSummaryDataModel();

      summary.appointmentInfo = this.getAppointmentInfo();

      summary.specialty = this.onlineAppointmentRequest.especialidadResponsableServicioDestino;

      if(this.onlineAppointmentRequest.coordenadasLocalidad){
        summary.locationLat = +this.onlineAppointmentRequest.coordenadasLocalidad.split(';')[0];
        summary.locationLng = +this.onlineAppointmentRequest.coordenadasLocalidad.split(';')[1];
      }

      if(this.onlineAppointmentRequest.telefonoLocalidad){
        summary.phoneNumber = this.onlineAppointmentRequest.telefonoLocalidad;
      }
      
      return summary;
    }

    public getCountryId(): number {
      return this.onlineAppointmentRequest.usuarioSolicitante.idPaisResidencia;
    }

    public setCountry(country: TerritorialDivision): void {
      this.onlineAppointmentRequest.usuarioSolicitante.idPaisResidencia = country.idPais;
    }
}
