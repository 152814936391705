import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { L10N_CONFIG, L10N_LOCALE, L10nConfig, L10nLocale } from 'angular-l10n';
import { BaseService } from 'src/app/core/services/base.service';

@Component({
  selector: 'app-anonymous-language-menu',
  templateUrl: './anonymous-language-menu.component.html',
  styleUrls: ['./anonymous-language-menu.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AnonymousLanguageMenuComponent {

  schema = this.l10nConfig.schema;

  constructor(
    @Inject(L10N_CONFIG) private l10nConfig: L10nConfig,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private baseService: BaseService
  ) { }

  setLocale(locale: L10nLocale): void {
    this.baseService.setLocale(locale);
  }

}
