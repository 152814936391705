<div class="login-form-cliniweb-container">
    <div class="subtitle">{{ 'loginFormClinweb.subtitle' | translate:locale.language }}</div>    
    <div class="title">{{ 'loginFormClinweb.title' | translate:locale.language }}</div>
    <div class="image-mobile"></div>
    <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">

        <label class="label-username">{{username}}</label>
        <mat-form-field appearance="outline" class="active form-field-outline-simple">
            <input matInput 
                [type]="showPassword ? 'text' : 'password'" 
                formControlName="password" 
                placeholder="{{ 'login.password' | translate:locale.language }}">
            <button type="button" class="show-password-btn" mat-icon-button matSuffix (click)="showPassword = !showPassword">
                <mat-icon fontSet="phr-iconset" [ngClass]="{'phr-iconset-eye': showPassword, 'phr-iconset-blind-eye': !showPassword}" class="phr-iconset-close-circle-line"></mat-icon>
            </button>
            <mat-error *ngIf="submitted && loginForm.controls.password.errors">
                <mat-icon fontSet="phr-iconset" class="phr-iconset-close-circle-line"></mat-icon>
                {{ 'login.passwordRequired' | translate:locale.language }}
            </mat-error>
        </mat-form-field>
        <app-login-singup-error-banner [error]="error"></app-login-singup-error-banner>
        <div class="forgot-container">                
            <a [routerLink]="['./forgot-password']" [queryParams]="{ returnUrl: returnUrl, closeOnBack: closeOnBack }">{{ 'login.forgotPassword' | translate:locale.language }}</a>
        </div>
        <button mat-button mat-flat-button class="btn-login primary" [ngClass]="{'loading': loading }">
            {{ 'loginFormClinweb.buttonText' | translate:locale.language }}
        </button>                
    </form>
    <div class="description">{{ 'loginFormClinweb.description' | translate:locale.language }}</div>
    <div class="cliniweb-logo-container">
        <img src="/assets/images/cliniweb/logo-cliniweb-phr.svg" />
    </div>
</div>