
<div class="authentication-container" *ngIf="!loading && !alreadyLoggedIn">
    <div class="content">
        <!-- Appointment -->
        <div class="chat-bubble">
            <span class="title" (click)="onAppointmentKnowMoreClick()" [innerHTML]="titleTextRefence | translate:locale.language | safeHtml"></span>
        </div>
        <app-cola-goblo-dialogo></app-cola-goblo-dialogo>
        <div class="doctor-container">
            <div class="avatar" *ngIf="avatarModel">                
                <app-public-profile-avatar [model]="avatarModel"></app-public-profile-avatar>
            </div>
            <div class="name">{{avatarModel.fullName}}</div>
        </div>
        <div class="button-container">
            <button mat-button mat-flat-button class="primary outline btn-google" (click)="loginByGoogle()" [ngClass]="{'loading': loadingGoogle }">
                <div class="google-icon"></div>
                <span>{{ 'shared.authentication.buttonGoogle' | translate:locale.language }}</span>
            </button>
            <div class="separator">
                <mat-divider></mat-divider>
                <span>{{ 'shared.authentication.separator' | translate:locale.language }}</span>
                <mat-divider></mat-divider>
            </div>
            <button mat-button mat-flat-button class="primary outline btn-signup" (click)="redirectToSignup()">
                {{ 'shared.authentication.buttonSignup' | translate:locale.language }}
            </button>
        </div>
        <app-company-logo [showCompanyImage]="false"></app-company-logo>

        <div class="login-container">
            <span>{{ 'shared.authentication.loginText' | translate:locale.language }} </span>
            <a class="login-link" (click)="redirectToLogin()">{{ 'shared.authentication.loginLinkText' | translate:locale.language }}</a>
        </div>
        <div class="terms-container">
            <span>{{ 'shared.authentication.termsText' | translate:locale.language }} </span>
            <span>
                <a (click)="openTermsDialog()">{{ 'shared.authentication.termsLink' | translate:locale.language }}</a>
                <span> {{ 'shared.authentication.and' | translate:locale.language }} </span>
                <a (click)="openPrivacyDialog()">{{ 'shared.authentication.privacyLink' | translate:locale.language }}</a>
            </span>
        </div>
    </div>
</div>

<app-spinner *ngIf="loading || alreadyLoggedIn" spinnerText="shared.onlineAppointmentCliniweb.spinnerText"></app-spinner>