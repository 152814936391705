import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
 name: "orderBy"
})
export class OrderByPipe implements PipeTransform { 

  transform(array: any, field: string, order?: string): any[] {
    if (!Array.isArray(array)) {
      return array;
    }

    array.sort((a: any, b: any) => {
        let result: number;

      if (a[field] < b[field]) {
        result = -1;
      } else if (a[field] > b[field]) {
        result = 1;
      } else {
        result = 0;
      }

      return order == "desc"? result * -1 : result;
    });

    return array;
  }
}