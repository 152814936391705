import { Component, Inject, Input, OnDestroy, ViewEncapsulation, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { maxDate, minDate } from '../../validators/max-min-date.validator';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import * as moment from 'moment';
import { MAT_SELECT_CONFIG } from '@angular/material/select';

@Component({
  selector: 'app-datepicker-flat-field',
  templateUrl: './datepicker-flat-field.component.html',
  styleUrls: ['./datepicker-flat-field.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerFlatFieldComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatepickerFlatFieldComponent),
      multi: true
    },
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'datepicker-flat-field-select' }
    }
  ]
})
export class DatepickerFlatFieldComponent implements ControlValueAccessor, OnDestroy, Validator {

  @Input() errorMessage: string | null = '';
  @Input() label: string | null = '';
  @Input() maxDate: Date = new Date();
  @Input() minDate: Date | null;

  days = Array.from({ length:31 }, (_, i) => i + 1);
  formulario = this.fb.group({
    day: [null, [Validators.required]],
    month: [null, [Validators.required]],
    year: [null, [Validators.required]],
  });
  months: {id: number, nombre: string}[] = [];
  years = Array.from({ length: 120 }, (_, i) => (-(new Date().getFullYear()) + i) * -1);

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};
  private ngUnsubscribe = new Subject();
  thisControl: AbstractControl;

  get errors(): ValidationErrors | null | undefined { return this.formulario.get('day')?.errors; }
  get isTouched(): boolean { 
    if (!this.formulario.touched && this.thisControl.touched) {
      this.formulario.markAllAsTouched();
      if (this.thisControl.errors) this.setErrors(this.thisControl.errors);
    }
    return this.thisControl?.touched;
  }

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private fb: FormBuilder) {
    moment.locale(locale.language);
    this.months = Array.from({ length: 12 }, this.mapMonth);
    this.formulario.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe({next: this.onSelectChanges.bind(this)});
  }

  private mapMonth(_: number, i: number): {id: number, nombre: string} {
    const fecha = moment(new Date(0, i)) ;
    const monthName = fecha.format('MMMM')
      return { id : i, nombre: monthName.charAt(0).toUpperCase() + monthName.slice(1) };
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.formulario.disable();
    } else {
      this.formulario.enable();
    }
  }

  writeValue(value: Date | moment.Moment | string): void {
    let dateResult: Date | moment.Moment = typeof value == 'string' ? new Date(value) : value;
    this.updateForm(dateResult);
  }

  onSelectChanges(args: DatePickerFlatField): void {
    if (this.isChanged(args)) {
      let dateResult = new Date(args.year, args.month, args.day, 5);
      if (args.day != dateResult.getDate() && args.month != dateResult.getMonth()) {
        this.setErrorsToThisControl();
      } else {
        this.changesThisControl(dateResult)
      }
    }
  }

  private isChanged(args: DatePickerFlatField): boolean {
    return !!args.day && isFinite(args.month) && args.month >= 0 && !!args.year;
  }

  private setErrorsToThisControl(): void {
    this.setErrors({ maxDate: { valid: false, message: 'shared.datepicker.maxDate'} });
    this.thisControl?.setErrors({ maxDate: { valid: false, message: 'shared.datepicker.maxDate'} });
    this.thisControl?.markAsTouched();
  }

  private changesThisControl(dateResult: Date): void {
    this.onChange(dateResult);
    this.onTouched();
  }

  private updateForm(value: Date | moment.Moment, emitEvent = true): void {
    let day: number | null = null;
    let month: number | null = null;
    let year: number | null = null;

    if (value) {
      day = value instanceof Date ? value.getDate() : (value as moment.Moment).date();
      month = value instanceof Date ? value.getMonth() : (value as moment.Moment).month();
      year = value instanceof Date ? value.getFullYear() : (value as moment.Moment).year();
    }
    
    const formValue = {day, month, year };
  
    this.formulario.setValue(formValue, { onlySelf: !emitEvent});
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.thisControl = control;
    let val: ValidationErrors | null = null
    val = maxDate(this.maxDate)(control);
    if (!val) val = minDate(this.minDate)(control);
    return this.setErrors(val);
  }

  private setErrors(errors: ValidationErrors | null, emitEvent = true) : ValidationErrors | null {
    this.formulario.get('day')?.setErrors(errors, { emitEvent });
    this.formulario.get('month')?.setErrors(errors, { emitEvent });
    this.formulario.get('year')?.setErrors(errors, { emitEvent });
    if (errors) {
      this.formulario.get('day')?.markAsTouched({ onlySelf: emitEvent });
      this.formulario.get('month')?.markAsTouched({ onlySelf: emitEvent });
      this.formulario.get('year')?.markAsTouched({ onlySelf: emitEvent });
    }
    return errors
  }

}

export interface DatePickerFlatField {
  day: number, month: number, year: number
}