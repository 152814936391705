import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IframeMessageService {

    private IFRAME_RESIZE_EVENT_NAME = "iframe_resize";
    private MODAL_OPEN_EVENT_NAME = "modal_open";
    private MODAL_CLOSE_EVENT_NAME = "modal_close";
    private REQUEST_USER_ID_EVENT_NAME = "request_user_id";
    private SCROLL_TOP_EVENT_NAME = "scroll_top";
    private MANAGE_APPOINTMENTS_EVENT_NAME = "manage_appointments";
    private PUBLIC_PROFILE_LOADED_EVENT_NAME = "public_profile_finish_loading";
    private REFRESH_USER = "refresh_user_info";

    private iframeResizeSubject = new Subject<IframeResizeMessage>();
    private modalOpenSubject = new Subject<ModalEventMessage>();
    private modalCloseSubject = new Subject<ModalEventMessage>();
    private requestUserIdSubject = new Subject<RequestUserIdEventMessage>();
    private scrollTopSubject = new Subject<IframeScrollTopMessage>();
    private manageAppointmentsSubject = new Subject<ManageAppointmentsMessage>();
    private publicProfileFinishLoadingSubject = new Subject<PublicProfileFinishLoading>();
    private refreshUserSubject = new Subject<RefreshUserMessage>();

    constructor(private baseService:BaseService) { 

        // Listen to iframe events
        window.addEventListener("message", (e : any) => {
            if (!e.data)
                return;

            if (typeof e.data == 'string') {
                // For iframe resize we use 'iframe-resizer' lib as we do not want to handle the logic to fire an event each time the 
                // iframte content height change, like when someone expand a div, collapsable.
                // This library will send and event like the next one
                let match = e.data.match(/[iFrameSizer].*:(\d+):(\d+)/);
                if (match) {
                    var height = match[1];
                    var width = match[2];

                    var model = new IframeMessage<IframeResizeMessage>();
                    model.eventName = this.IFRAME_RESIZE_EVENT_NAME;
                    model.model = new IframeResizeMessage();

                    model.model.height = height;
                    model.model.width = width;

                    this.handleIframeEvent(model);

                    return;
                }
            }            

            this.handleIframeEvent(e.data);
          }, false);
    }

    handleIframeEvent(event: IframeMessage<any>) {
        
        switch(event.eventName){
            case this.IFRAME_RESIZE_EVENT_NAME:
                this.iframeResizeSubject.next(this.baseService.parseObject<IframeResizeMessage>(event.model));
                break;
            case this.MODAL_OPEN_EVENT_NAME:
                this.modalOpenSubject.next(this.baseService.parseObject<ModalEventMessage>(event.model));
                break;
            case this.MODAL_CLOSE_EVENT_NAME:
                this.modalCloseSubject.next(this.baseService.parseObject<ModalEventMessage>(event.model));
                break;
            case this.REQUEST_USER_ID_EVENT_NAME:
                this.requestUserIdSubject.next(this.baseService.parseObject<RequestUserIdEventMessage>(event.model));
                break;
            case this.SCROLL_TOP_EVENT_NAME:
                this.scrollTopSubject.next(this.baseService.parseObject<IframeScrollTopMessage>(event.model));
                break;
            case this.MANAGE_APPOINTMENTS_EVENT_NAME:
                this.manageAppointmentsSubject.next(this.baseService.parseObject<ManageAppointmentsMessage>(event.model));
                break;
            case this.PUBLIC_PROFILE_LOADED_EVENT_NAME:
                this.publicProfileFinishLoadingSubject.next(this.baseService.parseObject<PublicProfileFinishLoading>(event.model));
                break;
            case this.REFRESH_USER:
                this.refreshUserSubject.next(this.baseService.parseObject<RefreshUserMessage>(event.model));
                break;
        }
    }

    onIframeResize(): Observable<IframeResizeMessage> {
        return this.iframeResizeSubject.asObservable();
    }

    onModalOpen(): Observable<ModalEventMessage> {
        return this.modalOpenSubject.asObservable();
    }

    modalClose(msg: ModalEventMessage) {
        this.modalCloseSubject.next(msg);
    }
    onModalClose(): Observable<ModalEventMessage> {
        return this.modalCloseSubject.asObservable();
    }
    
    onRequestUserId(): Observable<RequestUserIdEventMessage>{
        return this.requestUserIdSubject.asObservable();
    }

    postMessage(eventName: string, data: any) {
        window.postMessage({ eventName: eventName, model: data }, '*');
    }

    onScrollTop(): Observable<IframeScrollTopMessage> {
        return this.scrollTopSubject.asObservable();
    }

    onManageAppointments(): Observable<ManageAppointmentsMessage> {
        return this.manageAppointmentsSubject.asObservable();
    }

    onPublicProfileLoaded(): Observable<PublicProfileFinishLoading> {
        return this.publicProfileFinishLoadingSubject.asObservable();
    }

    onRefreshUser(): Observable<RefreshUserMessage> {
        return this.refreshUserSubject.asObservable();
    }
}

export class IframeMessage<T> {
    eventName: string;
    model: T;
}

export class IframeResizeMessage {
    width: number;
    height: number;
}

export class ModalEventMessage {
}

export class RequestUserIdEventMessage {
}

export class IframeScrollTopMessage {
    position: number;
}

export class ManageAppointmentsMessage {
}

export class PublicProfileFinishLoading {
}

export class RefreshUserMessage {
}