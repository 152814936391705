import { Component, ViewChild, Inject, OnInit, OnDestroy } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup.decorator';
import { L10nLocale, L10N_LOCALE, L10nTranslationService } from 'angular-l10n';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { PatientAppointment } from 'src/app/shared/models/process/appointment.model';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { LayoutService } from 'src/app/core/services/layout.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { MatDialog } from '@angular/material/dialog';
import { CancelAppointmentDialogComponent } from '../../dialogs/cancel-appointment/cancel-appointment-dialog.component';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { Router } from '@angular/router';
import { BaseService } from 'src/app/core/services/base.service';
import { Directory } from 'src/app/shared/models/systems/directory.model';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-future-appointment-cards-container',
  templateUrl: './future-appointments.component.html',
  styleUrls: ['./future-appointments.component.css']
})
@ComponentLookup('FutureAppointmentsComponent')
export class FutureAppointmentsComponent implements OnInit, OnDestroy, IControlComponent {
  data: FormComponentData;
  pageSize: number = 2;
  appointments : PatientAppointment[];
  pageAppointments : PatientAppointment[];
  day: string;
  hour: string;
  min: string;
  inProgressState: string;
  resultsCount: number = 0;
  loading = true;
  skeletonItems: string[] = ["", ""];
  directory: Directory;

  //subscribes
  private ngUnsubscribe = new Subject();

  constructor(
    private appointmentService: AppointmentService, 
    @Inject(L10N_LOCALE) public locale: L10nLocale, 
    private translation: L10nTranslationService,
    public dialog: MatDialog,
    private messageBusService: MessageBusService,
    private router: Router,
    private baseService: BaseService,
    private publicProfileService: PublicProfileService,
    private layoutService: LayoutService) {
  }
  
  @ViewChild(MatPaginator) paginator: MatPaginator;    

  ngOnInit(): void {
    this.parseControlData();    

    this.messageBusService.onAppointmentChangeReceived()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(message => {
      // Refresh the appointmen
      this.getAppointments();
    });

    this.translation.onChange()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
      next: () => {
          this.day = this.translation.translate('days');                    
          this.hour = this.translation.translate('hours');        
          this.min = this.translation.translate('minutes');    
          this.inProgressState = this.translation.translate('shared.futureAppointments.text8');     
      }
    });

    this.getDirectory();
  }

  ngOnDestroy():void{
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getAppointments() {
    this.loading = true;

    this.appointmentService.getFutureAppointments(this.locale.language).subscribe(appointments =>{
      this.loading = false;
      
      this.appointments = appointments;
      this.resultsCount = appointments.length;

      this.setPageResults(0, this.pageSize)

      if(this.appointments == null || this.appointments.length == 0 && this.data){
        this.layoutService.hideComponent(this.data);
      }
    },
    error => {
      this.loading = false;

      this.baseService.handleServiceError(error, "Error getting future appointment");      
    });   
  }

  onPageChange(event: PageEvent) {
    let startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    
    if(endIndex > this.appointments.length){
      endIndex = this.appointments.length;
    }
    
    this.setPageResults(startIndex, endIndex);  
  }

  private setPageResults(startIndex: number, endIndex: number) {
      this.pageAppointments = this.appointments.slice(startIndex, endIndex);
  }

  appointmentTrackBy(index : number, item: PatientAppointment){
    return item.idSolicitud; 
  }

  getLocationDescription(item : PatientAppointment) {
    let desc = item.direccionLocalidad;

    if (!desc)
      desc = item.nombreLocalidad;

      return desc;
  }

  getAvatarUrl(item : PatientAppointment) {
    let avatarUrl = item.urlAvatarResponsableServicio;

    if (!avatarUrl)
      avatarUrl = "/assets/images/avatar_male_doctor.svg";

      return avatarUrl;
  }

  getAppointmentDateStr(date: Date) {
    return this.baseService.getBasicDateFormat(date)
  }

  isAppointmentExpired(item : PatientAppointment) {
    let now = new Date().getTime();
    let appDate = new Date(item.fechaRequerido).getTime();

    return appDate <= now;
  }

  getAppointmentRemainingTime(item : PatientAppointment) {
    const dayDiff = 60 * 60 * 24;
    const hrDiff = 60 * 60;
    let now = new Date();
    let appDate = new Date(item.fechaRequerido);
    let timeDiff = (appDate.getTime() - now.getTime()) / 1000;
    let diffStr = "";

    if (timeDiff <= 0) {
      diffStr = this.inProgressState;
    }
    else if (timeDiff > dayDiff) {
      diffStr = (Math.ceil(timeDiff / dayDiff)) + " " + this.day;
    }
    else if (timeDiff > hrDiff) {
      diffStr = (Math.floor(timeDiff / hrDiff)) + " " + this.hour;
    }
    else {
      diffStr = (Math.floor(timeDiff / 60)) + " " + this.min;
    }

    return diffStr;
  }

  isTeleconsultation(item : PatientAppointment) {
    return item.teleconsulta && item.teleconsulta.toLocaleUpperCase() === "S";
  }

  buildTeleconsultationLink(item : PatientAppointment) {
    return this.directory.url + "/meet/" + item.teleconsultaId;
  }

  getDirectory() {
    let companyId = this.baseService.getCliniwebDirectoryCompanyId();

    this.baseService.getCliniwebDirectory(companyId).subscribe(d => {
      this.directory = d;

      this.getAppointments();
    });    
  }

  openCancelAppointmentDialog(item : PatientAppointment) {
    let dialogData = new DialogData();
    dialogData.data = item;

    const dialogRef = this.dialog.open(CancelAppointmentDialogComponent, {
      data: dialogData
    });    
  }
  
  onReprogramAppointment(item : PatientAppointment) {
    
    // If the doctor does not have a public profile
    if (!item.identificadorPerfilPublico) {
      let dialogData = new DialogData();
      dialogData.title = this.translation.translate("shared.pastAppointments.text4");
      dialogData.message = this.translation.translate("shared.pastAppointments.text5");
      dialogData.confirmText = this.translation.translate("shared.pastAppointments.text6");
      dialogData.showCancelButton = false;

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: dialogData
      });
    }
    else {
      //let url = "/perfil/" + item.identificadorPerfilPublico;    
      let url = this.publicProfileService.getPublicProfileUrl(item.identificadorPerfilPublico);
      
      this.router.navigate([url], { queryParams: {rs: true} });
    }    
  }

  private parseControlData() {
    if (this.data && this.data.valor) {
      try {
        this.data.valor = JSON.parse(this.data.valor);       

        let data = <FutureAppointmentConfigurationDataModel>this.data.valor;

        this.pageSize = data.appointments;

        this.skeletonItems = ' '.repeat(this.pageSize).split('')
      } catch (ex) {}      
    }    
  }
}

export interface FutureAppointmentConfigurationDataModel {
  appointments: number;
}
