import { Directive, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { merge, Observable, Subscription } from 'rxjs';
import { DropdownPanel } from './dropdown.component';
import { TemplatePortal } from '@angular/cdk/portal';

@Directive({
  selector: '[dropdownTriggerFor]',
  host: {
    '(click)': 'toggleDropdown()'
  }
})
export class DropdownTriggerForDirective implements OnDestroy {
  private isDropdownOpen = false;
  private overlayRef: OverlayRef;
  private dropdownClosingActionsSub = Subscription.EMPTY;

  @Input('dropdownTriggerFor') public dropdownPanel: DropdownPanel;
  @Output('dropdownOverlay') public dropdownOverlay = new EventEmitter<OverlayRef>();

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef<HTMLElement>,
    private viewContainerRef: ViewContainerRef
  ) { }

  toggleDropdown(): void {
    this.isDropdownOpen ? this.destroyDropdown() : this.openDropdown();
  }

  openDropdown(): void {
    this.isDropdownOpen = true;

    let overlayConfig = new OverlayConfig();
    overlayConfig.hasBackdrop = true;
    overlayConfig.backdropClass = this.dropdownPanel.overlayTransparent
      ? 'cdk-overlay-transparent-backdrop'
      : 'cdk-overlay-dark-backdrop';
    overlayConfig.panelClass = this.dropdownPanel.panelClass;

    if(this.dropdownPanel.fullscreen){
      overlayConfig.scrollStrategy = this.overlay.scrollStrategies.block();
    }
    else{
      overlayConfig.scrollStrategy = this.overlay.scrollStrategies.close();
      overlayConfig.positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            offsetY: 8
          }
        ]);
    }

    this.overlayRef = this.overlay.create(overlayConfig);

    const templatePortal = new TemplatePortal(
      this.dropdownPanel.templateRef,
      this.viewContainerRef
    );
    
    this.overlayRef.attach(templatePortal);

    this.dropdownPanel.overlayRef.emit(this.overlayRef);

    this.dropdownClosingActionsSub = this.dropdownClosingActions().subscribe(
      () => this.destroyDropdown()
    );
  }

  private dropdownClosingActions(): Observable<MouseEvent | void> {
    const backdropClick$ = this.overlayRef.backdropClick();
    const detachment$ = this.overlayRef.detachments();
    const dropdownClosed = this.dropdownPanel.closed;

    return merge(backdropClick$, detachment$, dropdownClosed);
  }

  private destroyDropdown(): void {
    if (!this.overlayRef || !this.isDropdownOpen) {
      return;
    }

    this.dropdownClosingActionsSub.unsubscribe();
    this.isDropdownOpen = false;
    this.overlayRef.detach();
  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }
}
