import { AfterViewInit, Component, ViewChild, Inject, OnInit, OnDestroy } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup.decorator';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { PatientAppointment } from 'src/app/shared/models/process/appointment.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { Router } from '@angular/router';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cancelled-appointment-container',
  templateUrl: './cancelled-appointments.component.html',
  styleUrls: ['./cancelled-appointments.component.css']
})
@ComponentLookup('CancelledAppointmentsComponent')
export class CancelledAppointmentsComponent implements IControlComponent, OnInit, OnDestroy, AfterViewInit  {
  data: FormComponentData;
  appointments : MatTableDataSource<PatientAppointment>;
  appointmentsArray: PatientAppointment[];
  appointmentsPage: PatientAppointment[];
  resultsCount: number;
  displayedColumns: string[] = ['name', 'specialty', 'date', 'state', 'actions'];    
  canceledStateName: string;
  missedStateName: string;

  //subscribes
  private ngUnsubscribe = new Subject();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {    
  }  

  constructor(
    private appointmentService: AppointmentService, 
    private translation: L10nTranslationService,
    private router: Router,
    public dialog: MatDialog,
    private messageBusService: MessageBusService,
    private publicProfileService: PublicProfileService,
    @Inject(L10N_LOCALE) public locale: L10nLocale) {
  }
  
  ngOnInit(): void {
    this.translation.onChange()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
      next: () => {
          this.canceledStateName = this.translation.translate('shared.cancelledAppointments.text2');                    
          this.missedStateName = this.translation.translate('shared.cancelledAppointments.text7');                       
      }
    });

    // Subscribe to appointment cancelled event
    this.messageBusService.onAppointmentCancelled()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(message => {
        // Refresh the appointments
        this.getAppointments();
    });

    this.getAppointments();
  }

  ngOnDestroy():void{
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getAppointments() {
    this.appointmentService.getIncompletedAppointments()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(appointments =>{
        this.appointmentsArray = appointments;
        this.appointments = new MatTableDataSource<PatientAppointment>(appointments);      
        this.appointments.paginator = this.paginator;
        this.resultsCount = appointments.length;         
        
        this.setPageResults(0, this.appointments.paginator.pageSize);    
      },
      error => {
        
      });
  }

  OnPageChange(event: PageEvent){
    let startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    
    if(endIndex > this.appointmentsArray.length){
      endIndex = this.appointmentsArray.length;
    }
    
    this.setPageResults(startIndex, endIndex);    
  }

  private setPageResults(startIndex: number, endIndex: number) {
    this.appointmentsPage = this.appointmentsArray.slice(startIndex, endIndex);
  }

  appointmentTrackBy(index : number, item: PatientAppointment){
    return item.idSolicitud; 
  }

  getLocationDescription(item : PatientAppointment) {
    let desc = item.direccionLocalidad;

    if (!desc)
      desc = item.nombreLocalidad;

      return desc;
  }

  getAvatarUrl(item : PatientAppointment) {
    let avatarUrl = item.urlAvatarResponsableServicio;

    if (!avatarUrl)
      avatarUrl = "/assets/Images/avatar_male_doctor.svg";

      return avatarUrl;
  }  
  
  navigateToPublicProfile(item : PatientAppointment) {    

    // If the doctor does not have a public profile
    if (!item.identificadorPerfilPublico) {
      let dialogData = new DialogData();
      dialogData.title = this.translation.translate("shared.cancelledAppointments.text4");
      dialogData.message = this.translation.translate("shared.cancelledAppointments.text5");
      dialogData.confirmText = this.translation.translate("shared.cancelledAppointments.text6");
      dialogData.showCancelButton = false;

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: dialogData
      });
    }
    else {
      //let url = "/busqueda/perfil/" + item.identificadorPerfilPublico;
      let url = this.publicProfileService.getPublicProfileUrl(item.identificadorPerfilPublico);

      this.router.navigate([url]);
    }
  }

  getStateName(item : PatientAppointment) {
    if (item.idEstado == 7)
      return this.canceledStateName;      
    else 
      return this.missedStateName;  
  }
}
