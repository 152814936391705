import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'intlTelInputPlaceholder'
})
export class IntlTelInputPlaceholderPipe implements PipeTransform {

  transform(value: any): unknown {
    if(value){
      return value.placeHolder.substring(value.dialCode.length + 1, value.placeHolder.length);
    }
    else{
      return '';
    }
  }

}
