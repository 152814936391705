export class ProfileSearchResultsModel {
    
    cantidadTotalResulados: number;
    perfiles: ProfileResultModel[];
    localidades: ProfileLocationResultModel[];
}

export class ProfileResultModel {
    id: number;
    idEmpresa: number;
    idPersona: number;
    nombre: string;
    sexo: string;
    pagoEnLineaHab: string;
    citaEnLineaHab: string;
    foto: string;
    rutaNavegacion: string;
    especialidad: string;
    subespecialidad: string;
    email: string;
    sitioWeb: string;
    telefonoLocalidad: string;
    tipoPerfil: PublicProfileType;   
    redes: string;    
    delivery: string; 
    conceptosDestacados: string;
    empresasRelacionadas: string;
}

export enum PublicProfileType {
    Todos = -1,
    Doctor = 1,
    Clinica = 2,
    Laboratorio = 3,
    CentroDeImagenes = 4,
    Farmacia = 5,
    SedeSura = 7,
    Fisioterapia = 8,
    SaludYBelleza = 9,
    ServiciosOdontologicos = 10,
    Opticas = 11,
    ClinicasDeAtencionPrimaria = 12
}

export class ProfileLocationResultModel {
    id: number;
    idPersona: number;
    empresaObjetoPerfilPublico: string;
    empresaPerfilPublico: string;
    objetoDetalle: string;
    objetoDetalle1: string;
    objetoDetalle2: string;
    objetoDetalle3: string;
    objetoDetalle4: string;
    objetoDetalle5: string;
    diLocalidad1: string;
    diLocalidad2: string;
    ciudad: string;
    pais: string;
    canonicoDivisionTerritorial: string;
    teleconsulta: string;
    citaEnLineaHabilitada: string;
}