import { Component, Inject, Input, OnInit } from '@angular/core';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { Gender } from 'src/app/shared/enums/person-gender.enum';

@Component({
  selector: 'app-growth-charts-tab-data-type',
  templateUrl: './growth-charts-tab-data-type.component.html',
  styleUrls: ['./growth-charts-tab-data-type.component.css']
})
export class GrowthChartsTabDataTypeComponent implements OnInit {

  @Input() gender: string;

  private genderClassMap: {[gender: string]: string} = {
    [Gender.MALE]: 'ninio',
    [Gender.FEMALE]: 'ninia',
    [Gender.OTHER]: ''
  };
  private genderTextMap: {[gender: string]: string} = {
    [Gender.MALE]: 'shared.growthCharts.omsDataBoy',
    [Gender.FEMALE]: 'shared.growthCharts.omsDataGirl',
    [Gender.OTHER]: ''
  };

  get omsDataColor(): string {
    return this.genderClassMap[this.gender] || '';
  }

  get omsDataText(): string {
    return this.genderTextMap[this.gender] || '';
  }

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) { }

  ngOnInit(): void {
  }

}
