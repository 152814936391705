import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { WorkflowItem } from "../../systems/workflow.model";
import { OnlineAppointmentCliniwebState } from "../states/online-appointment-cliniweb-state.model";
import { AppointmentInfoDataModel } from "../models/appointment-info-data.model";
import { PatientFormAppointmentDataModel } from "../models/patient-form-appointment-data.model";
import { BaseStep } from "./base-step.model";
import { ComponentDataSetMessage, MessageBusService } from "src/app/core/services/message-bus.service";
import { ComponentName } from "src/app/shared/enums/component-name.enum";
import { RequestingPatient } from "../../process/base-request.model";
import { StateType } from "src/app/shared/enums/state-type.enum";
import CommonTypesHelper from "src/app/shared/helpers/common-types-helper";


@Injectable({
    providedIn: 'root',
})
export class PatientFormAppointmentCheckoutStep extends BaseStep<OnlineAppointmentCliniwebState> {

    constructor(protected messageBusService: MessageBusService){
        super(messageBusService);
        
        this.showHeader = true;
        this.showButtonBack = true;
        this.showProgressBar = true;
        this.showButtonClose = true;
    }
    
    setItem(item: WorkflowItem): void {
        this.item = item;
    }

    setState(stateData: any): void {
        this.stateData = new OnlineAppointmentCliniwebState(stateData);
    }

    isCompleted(): Observable<boolean> {
        let isCompleted = this.patientFormDataCompleted();

        if (!isCompleted) {
            this.stateData.setPatientDataInitiallyIncomplete(true);
        }

        return of(isCompleted);
    }

    showStepInActionBack(): boolean {
        let patient = this.stateData.getRequestingPatient();
        let isDependentsFlow = this.stateData.isDependentsFlow();
        let patientDataInitiallyIncomplete = this.stateData.getPatientDataInitiallyIncomplete();

        return patient.id == 0 || patientDataInitiallyIncomplete;        
    }
    
    showStep(): boolean {
        let patient = this.stateData.getRequestingPatient();
        let isDependentsFlow = this.stateData.isDependentsFlow();

        return patient.id == 0 || !isDependentsFlow;
    }

    protected handleComponentSetDataMessage(message: ComponentDataSetMessage) {
        if (message.componentName == ComponentName.PATIENT_FORM_APPOINTMENT){
            this.stateData.setPatientFormData(message.data);
        }
    }

    protected buildComponentConfigurationData() : { [componentName: string]: any; } {
        let formConfigurationData : { [componentName: string]: any; } = {};
        
        formConfigurationData[ComponentName.PATIENT_FORM_APPOINTMENT] = this.stateData.getPatientFormData();

        return formConfigurationData;
    }

    protected messageForThisStep(message: ComponentDataSetMessage): boolean {
        return message.componentName == ComponentName.PATIENT_FORM_APPOINTMENT;
    }

    private patientFormDataCompleted():boolean{
        let patientData = this.stateData.getPatientFormData();

        let containsGender = CommonTypesHelper.stringHasValue(patientData.gender);
        let isBirthdateValid = CommonTypesHelper.dateHasValue(patientData.birthdate);

        if (patientData.firstname && patientData.lastname && isBirthdateValid && containsGender)
            return true;
        else
            return false;
    }
}

export interface IPatientFormAppointmentCheckoutWorkflowState{
    getAppointmentInfo():AppointmentInfoDataModel;
    getRelationship(): number;
    getPatientFormData():PatientFormAppointmentDataModel;
    setPatientFormData(data:PatientFormAppointmentDataModel):void;
    getRequestingPatient():RequestingPatient;
    isDependentsFlow():boolean;
    setPatientDataInitiallyIncomplete(incomplete: boolean):void;
    getPatientDataInitiallyIncomplete():boolean;
}
