import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { L10nTranslationService } from 'angular-l10n';
import { MessageBusService, OpenSnackBarMessage, SnackBarType } from 'src/app/core/services/message-bus.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-avatar-upload',
  templateUrl: './avatar-upload.component.html',
  styleUrls: ['./avatar-upload.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AvatarUploadComponent {

  @Input() options : AvatarUploadComponentOptions;
  @Input() fileUrl = "";
  @Input() id: number;
  @Input() uploadData: AvatarUploadModel;

  @Output() onFileUploaded = new EventEmitter<AvatarUploadedFileModel>();

  uploading : boolean = false;

  constructor(
    private messageBusService: MessageBusService,
    private translation: L10nTranslationService,
    private userService: UserService,
    private cd: ChangeDetectorRef
  ) { }

  onFileSelected(event: any) {
    const file : File = event.target.files[0];
    if (file) {
      this.uploading = true;
      this.userService.uploadProfilePicture(file).subscribe({
        next: this.uploadProfilePictureNext.bind(this),
        error: this.uploadProfilePictureError.bind(this)
      });
    }
  }

  deletePicture() {
    this.uploading = true;
    this.uploadProfilePictureNext();
  }

  private uploadProfilePictureNext(args?: AvatarUploadedFileModel): void {
    this.onFileUploaded.emit(args);
    if (!this.options.edit) {
      const uploadData = this.uploadData ?? {ubicacionLogo: null};
      uploadData.ubicacionLogo = args?.webUrl;
      this.userService.updateProfilePicture(uploadData).subscribe({
        next: this.updateProfilePictureNext.bind(this),
        error: this.uploadProfilePictureError.bind(this)
      });
    } else {
      this.uploading = false;
    }
  }

  private updateProfilePictureNext(_: any): void {
    this.showToast(this.translation.translate('shared.editUser.text12'), SnackBarType.SUCCESS);
    this.uploading = false;
  }

  private uploadProfilePictureError(args: any): void {
    this.uploading = false;
    this.showToast(args, SnackBarType.ERROR);
    this.cd.detectChanges();
  }

  private showToast(messageText: string, snackBarType: SnackBarType) {
    const message = new OpenSnackBarMessage();
    message.text = messageText;
    message.type = snackBarType;
    message.duration = 3000;
    this.messageBusService.openSnackBar(message);
  }

}

export class AvatarUploadComponentOptions {
    requiredFileType: string; // List of file types like 'image/png,image/jpeg'    
    showFileOnceUpload: boolean = true;
    edit = false;
    // Labels
    selectFileLabel: string;
    changeFileLabel: string;
    changePictureLabel: string;
    deleteFileLabel: string;
}

export class AvatarUploadedFileModel {
  webUrl: string;
  fileName: string;
  fileExtension: string;
}

export class AvatarUploadModel {
  idPersona?: number;
  idRelacionDependiente?: number;
  ubicacionLogo?: string;
}