import { Injectable } from '@angular/core';
import { Licence } from 'src/app/shared/models/systems/license.model';
import { HttpBaseService } from './http-base-service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppStorageService, STORAGE } from './app-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  constructor(
    private http: HttpBaseService,
    private appStorageService: AppStorageService
  ) { }

  get(idEmpresa:number, idTipo:number, idResponsableServicio:number):Observable<Licence>{
      const url = `api/licencias?idTipo=${idTipo}&idEmpresa=${idEmpresa}&idResponsableServicio=${idResponsableServicio}`;
  
      return this.http.get<Licence>(url, null, false);
  }

  getLicensePhr(idEmpresa: number, idResponsableServicio?: number):Observable<Licence>{

    idResponsableServicio = idResponsableServicio ?? -1;

    const url = `api/licencias?idTipo=${LICENSE_TYPE.PHR}&idEmpresa=${idEmpresa}&idResponsableServicio=${idResponsableServicio}`;

    if(idResponsableServicio < 0){
      const license = this.appStorageService.getItemSession(STORAGE.LICENSE, environment.encryptLocalStorage);

      if(!license || license === undefined){
        return this.http.get<Licence>(url, null, false).pipe(map((license:Licence)=>{

          this.appStorageService.setItemSession(STORAGE.LICENSE, license, environment.encryptLocalStorage);

          return license;
        }));
      }
      else{
        return of(license);
      }
    }
    else{
      return this.http.get<Licence>(url, null, false);
    }    
  }
}

export enum LICENSE_TYPE{
  PHR = 6
}
