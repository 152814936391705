import { Component, Inject, OnInit } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';

@Component({
  selector: 'appointment-tabs-container',
  templateUrl: './appointment-tabs.component.html',
  styleUrls: ['./appointment-tabs.component.css']
})
@ComponentLookup('AppointmentTabsComponent')
export class AppointmentsTabsComponent implements IControlComponent, OnInit {
    data: FormComponentData;

    constructor(   
      @Inject(L10N_LOCALE) public locale: L10nLocale) {
    }

    ngOnInit(): void {

    }
}