import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDisableAutoFocusIfMobile]'
})
export class DisableAutoFocusIfMobileDirective {

  private isManualFocus = false;
  private isMobile: boolean = this.breakpointObserver.isMatched([Breakpoints.Handset, Breakpoints.Tablet]);
  private thisElementRef: HTMLInputElement;

  constructor(private breakpointObserver: BreakpointObserver, private elementRef: ElementRef) {
    this.setElementRef();
    this.setTabIndex();
  }

  private setTabIndex(index = -1): void {
    if (this.isMobile) {
      this.thisElementRef.tabIndex = index;
    }
  }

  @HostListener('focus', ['$event'])
  onFocus(_: FocusEvent): void {
    this.setElementRef();
    if (this.isMobile && !this.isManualFocus) {
      this.isManualFocus = true;
      this.setTabIndex(0);
    }
  }

  private setElementRef(): void {
    if (!this.thisElementRef) {
      this.thisElementRef = this.elementRef.nativeElement;
    }
  }

}
