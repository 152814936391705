import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras, Router, Routes } from '@angular/router';
import { RouteDialogComponent, RouteDialogModel, RouteDialogType } from 'src/app/shared/components/dialogs/route-dialog/route-dialog.component';
import { CloseRouteDialogMessage, MessageBusService } from './message-bus.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class RouteDialogService {

  private dialogs:MatDialogRef<RouteDialogComponent, any>[] = [];

  constructor(
    private router: Router,
    private messageBusService: MessageBusService,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  openDialog(model: RouteDialogModel){

    // this.checkRouteConfig(model);

    this.document.body.classList.add('route-dialog-opened');

    let currentRoute = window.location.pathname + window.location.search;

    let dialogRef = this.dialog.open(RouteDialogComponent, {
      data:model,
      panelClass: ['route-dialog-modal', model.className, model.animationIn, model.backgroundClassName],
      id: model.id.toString(),
      disableClose: model.disableClose
    });    

    dialogRef.beforeClosed().subscribe(x=>{      
      dialogRef.componentInstance.onCloseClick(true);         
    });

    dialogRef.afterClosed().subscribe(x=>{
      this.router.navigateByUrl(currentRoute);      
    });

    this.dialogs.push(dialogRef);

    this.messageBusService.openRouteDialog();
  }

  closeDialog(type:RouteDialogType){

    let dialogRef = this.dialogs.find(d=> d.id == type.toString());

    if(dialogRef){
      let comp = dialogRef.componentInstance as RouteDialogComponent;

      comp.onCloseClick();

      this.dialogs = this.dialogs.filter(d => d.id != type.toString());
    }

    if(this.dialogs.length == 0){
      this.messageBusService.allRouteDialogClosed();
    }
  }

  isOpen(type:RouteDialogType){

    let dialogRef = this.dialog.getDialogById(type.toString());

    return dialogRef ? true : false;
  }

  closeDialogsAndNavigate(url: string, params: NavigationExtras | null = null){
    this.messageBusService.closeRouteDialog(new CloseRouteDialogMessage());

    if (!params)
      this.router.navigate([url]);
    else 
      this.router.navigate([url], params);

    setTimeout(()=>{
      window.location.reload();
    }, 200);    
  }

  anyOpen(): boolean{
    return this.dialogs.length > 0;
  }

  checkRouteConfig(model: RouteDialogModel){
    if(this.router.config.filter(r => r.outlet == model.id.toString()).length == 0){


      const route = this.router.config.find(r => r.path && model.route.includes(r.path));

      if(route)
        route.outlet = '3';



      // const routesForDialogType = this.duplicateRoutes(this.router.config, model.id.toString());

      // this.router.config.unshift(...routesForDialogType);

      this.router.resetConfig(this.router.config);
    }
  }

  duplicateRoutes(routes:Routes, outlet: string){

    return routes.map(route => {
      const duplicatedRoute = { ...route };
      if (route.children) {
        duplicatedRoute.children = this.duplicateRoutes(route.children, outlet);
      }
      duplicatedRoute.outlet = outlet;
      return duplicatedRoute;
    });
  }
}
