import { Location } from "./location.model";

export class PublicProfile {
    id: number;
    tipoPerfil: number;
    idPerfilPublico : number;
    idPersona?: number;
    idEmpresa: number;
    idPais: number;
    denominacionEmpresa: string;
    username: string;
    nombrePersona: string;
    sexo: string;
    citaEnLineaHabilitada: string;
    ubicacionFoto: string;
    especialidad: string;
    subEspecialidad: string;
    atencionBebes: boolean;
    atencionNinos: boolean;
    atencionAdultos: boolean;
    atencionAdultosMayores: boolean;
    atencionAdolescentes: boolean;
    mensajePublico: string;
    emailRemitenteNotificacion: string;
    sitioWeb: string;
    receptorPagoElectronico: string;
    idEmpresaReceptorPagoElectronico: number;
    empresasRelacionadas: string;
    generarTareaConfirmacionCita: string;
    redesSociales: SocialNetworkPublicProfile[];
    localidades: LocationPublicProfile[];
    enfoquePoblacional: PublicProfileDetailLightweight[];
    especialidades: PublicProfileDetailLightweight[];
    subEspecialidades: PublicProfileDetailLightweight[];
    condiciones: PublicProfileDetailLightweight[];
    tratamientos: PublicProfileDetailLightweight[];
    idiomas: PublicProfileDetailLightweight[];
    fotos: string[];
    fotosGaleria: DocumentPublicProfile[];
    publicaciones: PublicProfileDetailLightweight[];
    browserTitle: string;
    browserDescription: string;
    keywords: string[];
    descripcionAutobiografica: string[];
    titles: EducationPublicProfile[];
    curses: EducationPublicProfile[];
    entrenamientos: EducationPublicProfile[];
    licenses: LicensePublicProfile[];
    affiliations: PublicProfileDetailLightweight[];
    positions: PositionPublicProfile[];
    redes: PublicProfileDetailLightweight[];
    sobreMi: string[];
    facebookPixel: FacebookPixelPublicProfile[];
    experienciaLaboral: string[];
    aprobado: string;
    eliminado: string;
    descuentos: PublicProfileDetailLightweight[];
    firmaImg: string;
}

export class SocialNetworkPublicProfile{
    tipo: number;
    url: string;
}

export class LocationPublicProfile{
    idPerfilPublicoDetalle: number;
    idEmpresa?: number;
    localidad: Location
}

export class PublicProfileDetailLightweight {
    id: number;
    idTipoPerfilPublicoDetalle: number;
    idObjetoDetalle?: number;
    objetoDetalle: string;
    detalleDestacado: string;
}

export class DocumentPublicProfile{
    id: number;
    idPerfilPublico: number;
    idTipoPerfilPublicoDetalle: number;
    idEmpresa?: number;
    idPersona: number;
    idObjetoDetalle?: number;
    nombreUsuario: string;
    citaEnLineaHabilitada: string;
    nombreObjetoDetalle: string;
    ubicacionFoto: string;
    idResponsableRegistro?: number;
    idResponsableActualizacion?: number;
    prioridadOrden?: number;
    eliminado: string;
    nombreBucketAmazonS3: string;
}

export class EducationPublicProfile{
    idDetalle: number;
    idObjetoDetalle?: number;
    nombre: string;
    mesInicio: string;
    anioInicio: string;
    mesFin: string;
    anioFin: string;
    institucion: string;
}

export class LicensePublicProfile{
    idDetalle: number;
    idObjetoDetalle?: number;
    codigo: string;
    especialidad: string;
    nombre: string;
}

export class PositionPublicProfile{
    idDetalle: number;
    idObjetoDetalle?: number;
    titulo: string;
    mesInicio: string;
    anioInicio: string;
    mesFin: string;
    anioFin: string;
    descripcion: string;
}

export class FacebookPixelPublicProfile{
    idDetalle: number;
    idObjetoDetalle?: number;
    pixelId: string;
    pixelEvent: string;
}