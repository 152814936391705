<div class="profile-menu-container">     

    <div class="scroll-container">
        <!-- Loggedin User Item -->
        <div class="menu-item user-item" *ngIf="loggedInUser" (click)="onEditUserClick()">
            <span class="menu-item-content">
                <span class="avatar-container">
                    <div class="avatar-img" *ngIf="loggedInUser.ubicacionLogo" [ngStyle]="{backgroundImage:'url(' + loggedInUser.ubicacionLogo + ')'}"></div>                
                </span>    
                <span class="name-container">
                    <span class="name">{{loggedInUser.nombre}}</span>
                    <span class="relationship">({{'shared.profileMenu.text1' | translate:locale.language}})</span>
                    <span class="user-email">{{loggedInUser.username}}</span>
                    <span class="google-user" *ngIf="loggedInUser.autenticadoPorGoogle">{{'shared.profileMenu.text16' | translate:locale.language}}</span>
                </span>
            </span>
            <mat-icon fontSet="phr-iconset" class="phr-iconset-right-arrow icon phr-iconset"></mat-icon>
        </div>
        <!-- Dependents Items -->
        <div class="dependents-container" *ngIf="dependents != null && dependents.length > 0">
            <div class="menu-item" *ngFor="let dependent of dependents; index as i;" (click)="onEditDependentClick(dependent)">
                <span class="menu-item-content">
                    <span class="avatar-container">
                        <span class="avatar-img" *ngIf="dependent.ubicacionLogo" [ngStyle]="{backgroundImage:'url(' + dependent.ubicacionLogo + ')'}"></span>                    
                    </span>
                    <span class="name-container">
                        <span class="name">{{dependent.nombre.toLowerCase()}}</span>
                        <span class="relationship"> ({{dependent.relationshipName}})</span>
                    </span>
                </span>
                <mat-icon fontSet="phr-iconset" class="phr-iconset-right-arrow icon phr-iconset"></mat-icon>
            </div>
        </div>

        <!-- Add Dependent Item -->
        <div class="menu-item add-dependent-item" (click)="onAddDependentClick()">
            <span class="menu-item-content">                
                <span class="icon-content-item">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-plus icon phr-iconset"></mat-icon>
                    <span>{{'shared.profileMenu.text2' | translate:locale.language}}</span>
                </span>                
                <span class="icon-content-description">{{'shared.profileMenu.text3' | translate:locale.language}}</span>
            </span>
            <mat-icon fontSet="phr-iconset" class="phr-iconset-right-arrow icon phr-iconset"></mat-icon>
        </div>  
        
    </div>

    <div class="bottom-content">
            
        <!-- Bottom Items -->
        <div class="bottom-container">
            
            <!-- Payments -->
            <div class="menu-item" (click)="onPaymentsClick()">
                <span class="menu-item-content">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-money icon phr-iconset"></mat-icon>
                    <span>{{'shared.profileMenu.text4' | translate:locale.language}}</span>
                </span>
                <mat-icon fontSet="phr-iconset" class="phr-iconset-right-arrow icon phr-iconset"></mat-icon>
            </div>

            <!-- Language and Location -->
            <div class="menu-item" (click)="onLocaleEditClick()">
                <span class="menu-item-content">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-url icon phr-iconset"></mat-icon>
                    <span>{{'language' | translate:locale.language}}</span>
                </span>
                <mat-icon fontSet="phr-iconset" class="phr-iconset-right-arrow icon phr-iconset"></mat-icon>
            </div>

            <!-- Help -->
            <!--<div class="menu-item">
                <span class="menu-item-content">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-support icon phr-iconset"></mat-icon>
                    <span>{{'shared.profileMenu.text5' | translate:locale.language}}</span>
                </span>
                <mat-icon fontSet="phr-iconset" class="phr-iconset-right-arrow icon phr-iconset"></mat-icon>
            </div>-->
            
            <!-- Account / Password -->
            <div class="menu-item" (click)="onChangePasswordClick()" *ngIf="!loggedInUser.autenticadoPorGoogle">
                <span class="menu-item-content">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-lock icon phr-iconset"></mat-icon>
                    <span>{{'shared.profileMenu.text6' | translate:locale.language}}</span>
                </span>
                <mat-icon fontSet="phr-iconset" class="phr-iconset-right-arrow icon phr-iconset"></mat-icon>
            </div>
            
            <!-- Close session -->
            <div class="menu-item" (click)="closeSession()">
                <span class="menu-item-content">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-logout icon phr-iconset"></mat-icon>
                    <span>{{'shared.profileMenu.text7' | translate:locale.language}}</span>
                </span>
                <mat-icon fontSet="phr-iconset" class="phr-iconset-right-arrow icon phr-iconset"></mat-icon>
            </div>

        </div>
    </div>
    
</div>