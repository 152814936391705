import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Routes, RouterModule } from '@angular/router';
import { L10nResolver } from 'angular-l10n';
import { AuthGuard } from './core/guards/auth.guard';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { ViewUserComponent } from './shared/components/user/view-user/view-user.component';
import { EditUserComponent } from './shared/components/user/edit-user/edit-user.component';
import { ViewDependentComponent } from './shared/components/dependent/view-dependent/view-dependent.component';
import { EditDependentComponent } from './shared/components/dependent/edit-dependent/edit-dependent.component';
import { ProfileMenuComponent } from './shared/components/menu/profile/profile-menu.component';
import { LocationLanguageComponent } from './shared/components/location-language/location-language.component';
import { ShowNonProdGuard } from './core/guards/show-non-prod.guard';
import { LearnMoreComponent } from './shared/components/learn-more/learn-more.component';
import { LegalAgreementsComponent } from './shared/components/legal-agreements/legal-agreements.component';
import { UserPaymentsListComponent } from './shared/components/user-payments/user-payments-list/user-payments-list.component';
import { UserPaymentComponent } from './shared/components/user-payments/user-payment/user-payment.component';
import { LegalAgreementType } from './shared/enums/legal-agreements-type.enum';
import { AuthPrivateRoutesGuard } from './core/guards/auth-private-routes.guard';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard],
    resolve: { l10n: L10nResolver },
    data: {
        l10nProviders: [
          { name: 'home', asset: './assets/i18n/home', options: { version: environment.version  } },
          { name: 'shared', asset: './assets/i18n/shared', options: { version: environment.version  } }
        ],
        animation: 'HomePage'
    }
  },
  {
    path: 'welcome',
    loadChildren: () => import('./modules/welcome/welcome.module').then(m => m.WelcomeModule),
    resolve: { l10n: L10nResolver },
    data: {
        l10nProviders: [
          { name: 'welcome', asset: './assets/i18n/welcome', options: { version: environment.version  } },
          { name: 'shared', asset: './assets/i18n/shared', options: { version: environment.version  } }
        ]
    }
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
    resolve: { l10n: L10nResolver },
    data: {
        l10nProviders: [
          { name: 'shared', asset: './assets/i18n/shared', options: { version: environment.version } },
          { name: 'login', asset: './assets/i18n/login', options: { version: environment.version  } }
        ]
    }
  },
  {
    path: 'signup',
    loadChildren: () => import('./modules/signup/signup.module').then(m => m.SignupModule),
    resolve: { l10n: L10nResolver },
    data: {
        l10nProviders: [
          { name: 'shared', asset: './assets/i18n/shared', options: { version: environment.version  } },
          { name: 'signup', asset: './assets/i18n/signup', options: { version: environment.version  } }
        ]
    }
  },
  {
    path: 'busqueda',
    loadChildren: () => import('./modules/search-results/search-results.module').then(m => m.SearchResultsModule),
    canActivate: [],
    resolve: { l10n: L10nResolver },
    data: {
        l10nProviders: [          
          { name: 'shared', asset: './assets/i18n/shared', options: { version: environment.version  } },
          { name: 'search', asset: './assets/i18n/search', options: { version: environment.version  } },
        ]
    }
  },
  {
    path: 'perfil-v1',
    loadChildren: () => import('./modules/search-results/search-results.module').then(m => m.SearchResultsModule),
    canActivate: [],
    resolve: { l10n: L10nResolver },
    data: {
        l10nProviders: [          
          { name: 'shared', asset: './assets/i18n/shared', options: { version: environment.version  } },
          { name: 'search', asset: './assets/i18n/search', options: { version: environment.version  } },
        ],
        animation: 'PublicProfilePage'
    }
  },
  {
    path: 'perfil',
    loadChildren: () => import('./modules/public-profiles/public-profiles.module').then(m => m.PublicProfilesModule),
    canActivate: [],
    resolve: { l10n: L10nResolver },
    data: {
        l10nProviders: [          
          { name: 'shared', asset: './assets/i18n/shared', options: { version: environment.version  } },
          { name: 'publicProfiles', asset: './assets/i18n/public-profiles', options: { version: environment.version  } },
        ],
        animation: 'PublicProfilePage'
    }
  },
  {
    path: 'citas',
    loadChildren: () => import('./modules/appointments/appointments.module').then(m => m.AppointmentsModule),
    canActivate: [],
    resolve: { l10n: L10nResolver },
    data: {
        l10nProviders: [          
          { name: 'shared', asset: './assets/i18n/shared', options: { version: environment.version  } },
        ],
        animation: 'AppointmentsPage'
    }
  },
  {
    path: 'pagos',
    loadChildren: () => import('./modules/payment/payment.module').then(m => m.PaymentsModule),
    canActivate: [],
    resolve: { l10n: L10nResolver },
    data: {
        l10nProviders: [          
          { name: 'shared', asset: './assets/i18n/shared', options: { version: environment.version  } },
        ],
        animation: 'AppointmentsPage'
    }
  },
  {
    path: 'ordenes-medicas',
    loadChildren: () => import('./modules/medical-orders/medical-orders.module').then(m => m.MedicalOrdersModule),
    canActivate: [],
    resolve: { l10n: L10nResolver },
    data: {
        l10nProviders: [          
          { name: 'shared', asset: './assets/i18n/shared', options: { version: environment.version  } },
        ],
        animation: 'AppointmentsPage'
    }
  },
  {
    path: 'curvas-crecimiento',
    loadChildren: () => import('./modules/growth-curves/growth-curves.module').then(m => m.GrowthCurvesModule),
    canActivate: [],
    resolve: { l10n: L10nResolver },
    data: {
        l10nProviders: [          
          { name: 'shared', asset: './assets/i18n/shared', options: { version: environment.version  } },
        ],
        animation: 'AppointmentsPage'
    }
  },
  { 
    path: 'not-found', component: NotFoundComponent 
  },
  {
    path: 'support',
    loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule),
    resolve: { l10n: L10nResolver },
    data: {
      l10nProviders: [          
        { name: 'shared', asset: './assets/i18n/shared', options: { version: environment.version  } },
      ]
    }
  },
  {
    path: 'politicas',
    loadChildren: () => import('./modules/privacy-policies/privacy-policies.module').then(m => m.PrivacyPoliciesModule),
    resolve: { l10n: L10nResolver },
    data: {
      l10nProviders: [          
        { name: 'privacypolicies', asset: './assets/i18n/privacy-policies', options: { version: environment.version  } }        
      ]
    }
  },
  {
    path: 'configuracion',    
    canActivate: [AuthGuard, AuthPrivateRoutesGuard],
    resolve: { l10n: L10nResolver },
    data: {
        l10nProviders: [          
          { name: 'shared', asset: './assets/i18n/shared', options: { version: environment.version  } },
          { name: 'search', asset: './assets/i18n/search', options: { version: environment.version  } },
        ],
        isRestrictedRoute: true
    },
    children:[
      {
        path: 'menu',
        component: ProfileMenuComponent
      },
      {
        path: 'usuario',
        component: ViewUserComponent
      },
      {
        path: 'usuario/edicion',
        component: EditUserComponent
      },
      {
        path: 'dependiente/:id',
        component: ViewDependentComponent
      }, 
      {
        path: 'dependiente/edicion/:id',
        component: EditDependentComponent
      },
      {
        path: 'locale',
        component: LocationLanguageComponent
      },
      {
        path: 'pagos',
        component: UserPaymentsListComponent
      },
      {
        path: 'pagos/:id',
        component: UserPaymentComponent
      }
    ]
  },
  {
    path: 'plantilla',
    canActivate: [ShowNonProdGuard],
    loadChildren: () => import('./modules/template-panel/template-panel.module').then(m => m.TemplatePanelModule)
  },
  {
	path: 'terminos-y-condiciones',
    component: LegalAgreementsComponent,
    data: { id: LegalAgreementType.TERMINOS_CONDICIONES }
  },
  {
	path: 'disclaimer',
    component: LegalAgreementsComponent,
    data: { id: LegalAgreementType.DISCLAIMER }
  },
  {
    path: 'politicas-de-privacidad',
    data: {id: LegalAgreementType.POLITICA_PRIVACIDAD},
    component: LegalAgreementsComponent
  },
  {
    path: 'conoce-mas',
    resolve: { l10n: L10nResolver },
    data: {
        l10nProviders: [          
          { name: 'shared', asset: './assets/i18n/shared', options: { version: environment.version  } },
          { name: 'search', asset: './assets/i18n/search', options: { version: environment.version  } },
        ]
    },
    component: LearnMoreComponent
  },
  {
    path: 'widget',
    loadChildren: () => import('./modules/widget/widget.module').then(m => m.WidgetModule),
    resolve: { l10n: L10nResolver },
    data:{
      showHeader: false,
      l10nProviders: [
        { name: 'shared', asset: './assets/i18n/shared', options: { version: environment.version  } }
      ]
    }
  },
  {
    path: 'charts',
    loadChildren: () => import('./modules/charts/charts.module').then(m => m.ChartsModule),
    resolve: { l10n: L10nResolver },
    data:{
      showHeader: false,
      l10nProviders: [
        { name: 'shared', asset: './assets/i18n/shared', options: { version: environment.version  } }
      ]
    }
  },
  {
    path: '**',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard],
    resolve: { l10n: L10nResolver },
    data: {
        l10nProviders: [
          { name: 'home', asset: './assets/i18n/home', options: { version: environment.version  } },
          { name: 'shared', asset: './assets/i18n/shared', options: { version: environment.version  } }
        ]
    }
  }, 
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'corrected',
      // initialNavigation: 'disabled'
      
      /* useHash: true */
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
