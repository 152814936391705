import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { Subject, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { STORAGE } from 'src/app/core/services/app-storage.service';
import { LayoutService } from 'src/app/core/services/layout.service';
import { CloseRouteDialogMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { UserService } from 'src/app/core/services/user.service';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { RouteDialogType } from 'src/app/shared/components/dialogs/route-dialog/route-dialog.component';
import { EmailCallbackComponentData } from 'src/app/shared/components/email-callback/email-callback.component';
import { ResetPasswordModel } from 'src/app/shared/models/people/reset-password.model';
import { CustomEmailValidator } from 'src/app/shared/validators/custom-email.validator';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  forgotPasswordForm: FormGroup;
  returnUrl: string;
  homeUrl: string;
  closeOnBack: string;
  submitted:boolean = false;
  email: string;
  showCallback: boolean = false;
  loading:boolean= false;

  licenseLoading: boolean = true;

  model: ResetPasswordModel = new ResetPasswordModel();

  emailCallbackModel: EmailCallbackComponentData;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private translation: L10nTranslationService,
    private workflowService: WorkflowService,
    private layoutService: LayoutService,
    private messageBusService: MessageBusService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private routeDialogService: RouteDialogService
  ) { }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    this.layoutService.loadLicenseTheme('forgot-password-container')
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(result=>{
      this.licenseLoading = false;
    });

    this.closeOnBack = this.route.snapshot.queryParams['closeOnBack']
      ? this.route.snapshot.queryParams['closeOnBack']
      : 'false';

    this.homeUrl = this.route.snapshot.queryParams['homeUrl']
      ? this.route.snapshot.queryParams['homeUrl']
      : '/';

    this.forgotPasswordForm = this.formBuilder.group(
      {
        username: ['', [Validators.required, CustomEmailValidator]]
      },
      { 
        updateOn: "submit" 
      }
    );

    this.emailCallbackModel = new EmailCallbackComponentData();
    this.emailCallbackModel.titleReference = 'forgotPassword.callback.title';
    
    this.emailCallbackModel.forwardingLimit = 3;
    this.emailCallbackModel.enterDataAgainFn = this.enterDataAgain.bind(this);
    this.emailCallbackModel.resend$ = this.userService.postResetPassword(this.model)
      .pipe(
        map((result:void)=>{
          this.loading = false;
          this.showCallback = true;
        }),
        catchError((err) => {
          this.loading = false;
          let message = this.translation.translate('forgotPassword.callback.errorMessage');

          return of(message);
        })
      );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSubmit(): void{
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.model.email = this.forgotPasswordForm.controls.username.value;
    this.model.url =  `${window.location.origin}/${this.locale.language}/login/restablecer`;

    this.emailCallbackModel.description = this.translation.translate('forgotPassword.callback.description', { email: this.model.email });

    this.postResetPassword();
  }

  onCancel(){
    let activeWorkflowType = this.workflowService.getWorkflowTypeActive();
    this.messageBusService.showWelcomeImage(true);

    switch(activeWorkflowType){
      case STORAGE.ONLINE_PAYMENT_WORKFLOW_STATE:
      case STORAGE.ONLINE_APPOINTMENT_CLINIWEB_STATE:
      default:

        if(this.returnUrl.includes('checkout')){
          this.router.navigateByUrl(decodeURI(this.returnUrl));
        }
        else{
          this.router.navigate(['login'], { queryParams: { returnUrl: this.returnUrl, closeOnBack: this.closeOnBack, homeUrl: this.homeUrl } });
        }
      break;
      case STORAGE.WELCOME_CLINIWEB_STATE:
        this.router.navigate(['../../login'], { queryParams: { returnUrl: this.returnUrl, closeOnBack: this.closeOnBack, homeUrl: this.homeUrl }, relativeTo: this.route });
      break;
    }    
  }

  enterDataAgain(){
    this.showCallback = false;
    this.messageBusService.showWelcomeImage(true);
  }

  private postResetPassword(){
    this.loading = true;
    this.emailCallbackModel.resend$.subscribe((_: any) => {
      if (this.workflowService.getWorkflowTypeActive() === STORAGE.WELCOME_CLINIWEB_STATE) {
        this.messageBusService.showWelcomeImage(false);
      }
    });
  }

}
