<div class="auth-callback-container">
    <!-- Skeleton -->
    <mat-sidenav-container fxFlexFill class="side-nav-sk" class="" *ngIf="!showErrorView && isSura">
        
        <!-- Left rail -->
        <mat-sidenav class="sk-sidenav" mode="side" opened fxHide.lt-md>        
            <div class="sk-w3">
                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h3, 'background-color': skeletonColorStyles.primary, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>
            </div>        
            <div class="sk-w2b">
                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h4, 'background-color': skeletonColorStyles.linkColor, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>
            </div>
            <mat-divider class="divider"></mat-divider>
            <div class="sk-w2">
                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h4, 'background-color': skeletonColorStyles.primary, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>
            </div>
            <mat-divider class="divider"></mat-divider>
            <div class="sk-w1">
                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h4, 'background-color': skeletonColorStyles.primary, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>
            </div>        
            <div class="sk-w3">
                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h4, 'background-color': skeletonColorStyles.gray, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>
            </div>        
            <div class="sk-w2b">
                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h4, 'background-color': skeletonColorStyles.gray, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>
            </div>
            <div class="sk-w2">
                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h4, 'background-color': skeletonColorStyles.gray, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>
            </div>        
            <div class="sk-w2b">
                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h4, 'background-color': skeletonColorStyles.gray, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>
            </div>
            <div class="sk-w2" style="margin-top: 50px;">
                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h4, 'background-color': skeletonColorStyles.linkColor, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>
            </div>
        </mat-sidenav>

        <!-- Right rail -->
        <mat-sidenav-content class="sidenav-content" >
            <div class="sidenav-body-container">            

                <div class="sk-title">
                    <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h3, 'background-color': skeletonColorStyles.primary, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>
                </div>

                <!-- Cards -->
                <div class="card-container-sk" fxLayout="row nowrap" fxLayout.lt-md="column" dir="ltr">                
                    <mat-card class="sk-card" *ngFor="let item of [1,2,3]; index as i;" [fxFlex]="30" >
                        <mat-card-content>
                            <div class="sk-w1">
                                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h2, 'background-color': skeletonColorStyles.yellow, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>
                            </div>
                            <div class="sk-w3">
                                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h4, 'background-color': skeletonColorStyles.primary, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>
                            </div>
                            <div class="sk-w4" style="margin-bottom: 0px;">
                                <ngx-skeleton-loader count="2" [theme]="{'height': skeletonHeightStyles.h6, 'background-color': skeletonColorStyles.gray, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>                            
                            </div>
                            <div class="sk-w1">
                                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h6, 'background-color': skeletonColorStyles.gray, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>                            
                            </div>
                            <div class="sk-w2">
                                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h5, 'background-color': skeletonColorStyles.linkColor, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>
                            </div>                
                        </mat-card-content>
                    </mat-card>        
                </div>

                <div class="card-container-sk" fxLayout="row nowrap" fxLayout.lt-md="column" dir="ltr">
                    <mat-card class="sk-card two-items" >
                        <mat-card-content>
                            <div class="sk-w1" style="position: absolute; right: 24px; top: 19px; width: 100%;">
                                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h5, 'background-color': skeletonColorStyles.linkColor, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>
                            </div>
                            <div class="sk-w2">
                                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h4, 'background-color': skeletonColorStyles.primary, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>
                            </div>                        
                            <div class="sk-w2" style="margin-bottom: 0px;">
                                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h6, 'background-color': skeletonColorStyles.gray, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>                            
                            </div>
                            <div class="sk-w3">
                                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h6, 'background-color': skeletonColorStyles.gray, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>                            
                            </div>
                            <div>
                                <span class="left">
                                    <ngx-skeleton-loader appearance="circle" [theme]="{'height': '70px', 'width': '70px', 'background-color': skeletonColorStyles.yellow}"></ngx-skeleton-loader>     
                                </span>
                                <span class="right">
                                    <div class="sk-w2b" style="margin-bottom: 0px;">
                                        <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h6, 'background-color': skeletonColorStyles.gray, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader> 
                                    </div>
                                    <div class="sk-w3" style="margin-bottom: 0px;">
                                        <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h6, 'background-color': skeletonColorStyles.gray, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader> 
                                    </div>
                                    <div class="sk-w2" style="margin-bottom: 0px;">
                                        <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h6, 'background-color': skeletonColorStyles.primary, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader> 
                                    </div>                                
                                </span>                            
                            </div>             
                        </mat-card-content>
                    </mat-card>

                    <mat-card class="sk-card two-items" >
                        <div class="border primary-background-color"></div>
                        <mat-card-content style="padding-left: 8px;">                        
                            <div class="sk-w1">
                                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h1, 'background-color': skeletonColorStyles.yellow, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>
                            </div>                        
                            <div class="sk-w2b" style="margin-bottom: 0px;">
                                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h4, 'background-color': skeletonColorStyles.primary, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>                            
                            </div>
                            <div class="sk-w3">
                                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h5, 'background-color': skeletonColorStyles.gray, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>                            
                            </div>
                            <div class="sk-w2">
                                <ngx-skeleton-loader [theme]="{'height': skeletonHeightStyles.h5, 'background-color': skeletonColorStyles.linkColor, 'border-radius': skeletonBorderStyles.b1}"></ngx-skeleton-loader>                            
                            </div>
                        </mat-card-content>
                    </mat-card>  
                </div>

            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>

    <div class="general-loading-icon" *ngIf="!showErrorView && !isSura">
        <mat-spinner diameter="150" color=""></mat-spinner>
    </div>


    <div class="error-container" *ngIf="showErrorView">
        <div class="title primary-color">
            {{ 'authCallback.text1' | translate:locale.language }}
        </div>
        <div class="subtitle">
            {{ 'authCallback.text2' | translate:locale.language }}
        </div>
        <button mat-raised-button class="retry-btn" color="primary" (click)="onRetryClick()">{{ 'authCallback.text3' | translate:locale.language }}</button>    
    </div>
</div>


