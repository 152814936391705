import { PublicProfileType } from "../people/profile-search-results.model";

export class DoctorSearchResult {
    id : number;
    claseId : number;   
    nombre : string;   
    descripcion : string;   
    clase : string;   
    urlImagen : string;   
    url : string;   
    inRedirect : boolean;   
    prioridadBusqueda : number;   
    idFuenteReferencia : number;
    ciudades : string;
    idEmpresa : number;
    empresasRelacionadas : string;
    idPerfilPublico: number;
    idPersona: number;
    tipoPerfilPublico: PublicProfileType;
    sexo: string;
}