import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { WorkflowItem } from "../../systems/workflow.model";
import { AppointmentSummaryDataModel } from "../models/appointment-summary-data.model";
import { OnlineAppointmentCliniwebState } from "../states/online-appointment-cliniweb-state.model";
import { AppointmentInfoDataModel } from "../models/appointment-info-data.model";
import { BaseStep } from "./base-step.model";
import { ComponentDataSetMessage, MessageBusService } from "src/app/core/services/message-bus.service";
import { ComponentName } from "src/app/shared/enums/component-name.enum";

@Injectable({
    providedIn: 'root',
})
export class SummaryAppointmentCheckoutStep extends BaseStep<OnlineAppointmentCliniwebState> {

    constructor(protected messageBusService: MessageBusService){
        super(messageBusService);
        this.showHeader = false;
    }

    setItem(item: WorkflowItem): void {
        this.item = item;
    }

    setState(stateData: any): void {
        this.stateData = new OnlineAppointmentCliniwebState(stateData);
    }

    isCompleted(): Observable<boolean> {
        return of(false);
    }

    showStepInActionBack(): boolean {
        return true;
    }

    showStep(): boolean {
        return true;
    }

    protected handleComponentSetDataMessage(message: ComponentDataSetMessage) {
        if (message.componentName == ComponentName.SUMMARY_APPOINTMENT){
        }
    }

    protected buildComponentConfigurationData() : { [componentName: string]: any; } {
        let formConfigurationData : { [componentName: string]: any; } = {};
        
        formConfigurationData[ComponentName.SUMMARY_APPOINTMENT] = this.stateData.getAppointmentSummary();

        return formConfigurationData;
    }

    protected messageForThisStep(message: ComponentDataSetMessage): boolean {
        return message.componentName == ComponentName.SUMMARY_APPOINTMENT;
    }
}

export interface ISummaryAppointmentCheckoutWorkflowState{
    getAppointmentInfo():AppointmentInfoDataModel;
    getAppointmentSummary(): AppointmentSummaryDataModel;
}
