import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { MapDialogData } from 'src/app/shared/models/dialog-data.model';

@Component({
  selector: 'map-dialog',
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.css']  
})
export class MapDialogComponent implements AfterViewInit {
  @ViewChild('titleTag') private divTitle: ElementRef<HTMLDivElement>;
    title: string;
    description: string;
    showWazeButton: boolean = false; 
    lat = 8.537981;
    lng = -80.782127;
    zoom = 15;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: MapDialogData,
      private mdDialogRef: MatDialogRef<MapDialogComponent>,
      private breakpointObserver: BreakpointObserver,
      @Inject(L10N_LOCALE) public locale: L10nLocale,
      @Inject(DOCUMENT) private document: Document
  ) {
    
    this.title = data.title;
    this.description = data.description;
    this.showWazeButton = data.showWazeButton;
    var coordinates = data.message.split(";");

    this.lat = Number(coordinates[0]);
    this.lng = Number(coordinates[1]);
  }

  ngAfterViewInit(): void {
    console.log(this.breakpointObserver.isMatched('(max-width:800px)'))
    if (!this.breakpointObserver.isMatched('(max-width:800px)')) return;
    const width = this.divTitle.nativeElement.clientWidth;
    if (width >= this.medicionTitulo())
    this.divTitle.nativeElement.style.display = 'flex';
  }

  private medicionTitulo() {
    const span = this.document.createElement('span');
    span.style.visibility = 'hidden';
    span.style.whiteSpace = 'nowrap';
    span.textContent = this.title;
    this.document.body.appendChild(span);
    const width = span.offsetWidth
    this.document.body.removeChild(span);
    return width;
  }

  onCloseClick() {
    if(this.data.animationOut){
      this.document.getElementsByClassName('map-dialog')[0].classList.remove(this.data.animationIn);
      this.document.getElementsByClassName('map-dialog')[0].classList.add(this.data.animationOut);
      setTimeout(()=>{      
        this.mdDialogRef.close();
      }, 300);
    }
    else{
      this.mdDialogRef.close();
    }
  }

  buildWazeLink() {
    let coordinates = this.lat + "," + this.lng;

    return "https://www.waze.com/ul?ll=" + coordinates + "&navigate=yes&zoom=17";
  }
  
}