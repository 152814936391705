export class ClinicConcept {
    id: number = 0;
    nombre: string = '';
    idTerminoReferencia: number = 0;
    valorTerminoReferencia: string = '';
    idFuenteReferencia: number = 0;
    nombreClase: string = '';
    valorRutaNavegacion: string = '';
    idReferenciaConcepto: number = 0;
    idTipoConcepto: number;
    nombreTipoConcepto: string = '';
    nombreFuenteReferencia: string = '';
}
