import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthOidcService } from '../services/auth-oidc.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private authOidcService: AuthOidcService) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if(this.authOidcService.redirectToAuthOidc()){

        const url = route.queryParams['returnUrl'] || '/';

        this.authOidcService.startAuthentication(url);
        return false;
      }
      else{
        return true;
      }
  }  
}
