import { NgModule } from '@angular/core';
import { SupportComponent } from './pages/support/support.component';
import { CoreModule } from 'src/app/core/core.module';
import { CommonModule } from '@angular/common';
import { SupportRoutingModule } from './support-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SupportContactComponent } from './pages/support-contact/support-contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { L10nIntlModule, L10nTranslationModule } from 'angular-l10n';

@NgModule({
  declarations: [SupportComponent, SupportContactComponent],
  imports: [
    CommonModule,
    CoreModule,
    L10nTranslationModule,
    L10nIntlModule,
    ReactiveFormsModule,
    SharedModule,
    SupportRoutingModule
  ]
})

export class SupportModule { }
