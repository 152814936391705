import { Component, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MessageBusService, ProviderSearchMessage } from 'src/app/core/services/message-bus.service';
import { BaseService } from 'src/app/core/services/base.service';
import { PublicProfileType } from 'src/app/shared/models/people/profile-search-results.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProviderFiltersDialogComponent, ProviderFiltersDialogComponentData } from '../provider-filters-dialog/provider-filters-dialog.component';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { ProfileFilterSearchResult, ProfileFilterSearchResultConcept } from 'src/app/shared/models/people/profile-filter-search-result.model';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { TextLanguage } from 'src/app/shared/models/people/text-language.model';
import { DirectoryFiltersItemType } from 'src/app/shared/models/systems/directory.model';

@Component({
  selector: 'app-provider-filters-content',
  templateUrl: './provider-filters-content.component.html',
  styleUrls: ['./provider-filters-content.component.css']
})
export class ProviderFiltersContentComponent implements OnInit, OnChanges, OnDestroy {

  private ngUnsubscribe = new Subject();
  private onChanges = new Subject<SimpleChanges>();

  filterModel: ProviderFilterModel;
  publicProfileTypes:PublicProfileType[] = [PublicProfileType.Doctor];

  show: boolean = false;
  isMobile:boolean = false;
  mdDialogRef: MatDialogRef<ProviderFiltersDialogComponent>;
  isDialogOpened: boolean = false;

  loading:boolean = false;

  selectedConcepts:ProfileFilterSearchResultConcept [] = [];

  @Input() profileTypes:string;
  @Input() providerSearchMessage: ProviderSearchMessage;

  constructor(
    private baseService: BaseService,
    private dialog: MatDialog,
    private messageBusService: MessageBusService,
    private publicProfileService: PublicProfileService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) { }

  ngOnInit(): void {
    this.isMobile = window.innerWidth <= 800;

    if(this.profileTypes)
      this.publicProfileTypes = this.profileTypes.split(',').map(pt => +pt);

    this.getDirectoryFilters();

    this.messageBusService.onOpenProviderFilters()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(()=>{
        this.openDialog();
      });

    this.onChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data:SimpleChanges)=>{
        this.onChangesInputs();
      });
    
    this.messageBusService.onClearProviderFilters()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(()=>{
        this.filterModel.clear();
      });

    this.preloadSelectedFilters();

    this.messageBusService.onLocaleChange()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(message =>{
      // Re load filtros     
      setTimeout(() => {
        window.location.reload();
      }, 500);
    });
  }

  ngOnDestroy():void{
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onChanges.next(changes);
  }

  openDialog(){

    this.preloadSelectedFilters();

    let dataDialog = new ProviderFiltersDialogComponentData();
    
    dataDialog.filterModel = this.filterModel;
    dataDialog.providerSearchMessage = this.providerSearchMessage;
    dataDialog.publicProfileTypes = this.publicProfileTypes;

    this.mdDialogRef = this.dialog.open(ProviderFiltersDialogComponent, 
      { 
        data: dataDialog,
        panelClass: ['provider-filters-dialog', 'provider-filters-dialog-in']
      });

    this.isDialogOpened = true;

    this.mdDialogRef.afterClosed().subscribe(accepted => {
      this.isDialogOpened = false;

      this.getSelectedFilters();
    });
  }

  getSelectedFilters(){
    this.loading = false;

    this.selectedConcepts = this.filterModel.getSelectedConceptsObject();

    this.messageBusService.providerFiltersCount(this.selectedConcepts.length);
  }

  removeFilter(concept:ProfileFilterSearchResultConcept){

    this.filterModel.remove(concept.cc);

    let conceptIds = this.providerSearchMessage.filtros.split(',');

    this.providerSearchMessage.filtros = conceptIds.filter(c=> +c != concept.cc).join(',');

    this.messageBusService.providerSearch(this.providerSearchMessage);

    this.getSelectedFilters();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = event.target.innerWidth <= 800;

    if(!this.isMobile && this.mdDialogRef){
      this.mdDialogRef.close();
    }
  }

  private getDirectoryFilters() {
    this.filterModel = new ProviderFilterModel();
    let directory = this.baseService.getDirectory();

    if (directory && directory.filtros) {
      let filtersObj = JSON.parse(directory.filtros);
      let directoryFilters = this.baseService.parseObject<DirectoryFiltersModel>(filtersObj);            

      // Find the filter for this provider
      let provider = directoryFilters.providers.find(p => this.publicProfileTypes.includes(p.type));

      if (provider) {
        this.filterModel.loadFromModel(provider.filters);      
        
        this.show = true;
      }
    }
  }

  private preloadSelectedFilters(){
    if(!this.providerSearchMessage.filtros)
      return;

    this.loading = true;

    let filters = this.providerSearchMessage.filtros.split(',').map(f=> +f);

    let loadFiltersArray$:Observable<boolean>[] = [];

    this.filterModel.items.forEach(item => {

      if(item.isDropdown()){
        loadFiltersArray$.push(this.loadFilters(item, filters));
      }
      else{
        item.active = filters.filter(f=> f == item.clinicConceptId).length > 0;
      }
    });

    if(loadFiltersArray$.length > 0){
      forkJoin(loadFiltersArray$)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result =>{
        this.getSelectedFilters();
      });
    }
    else{
      this.getSelectedFilters();
    }

  }
  
  private loadFilters(item: ProviderFilterModelItem, prefilters:number[] = []) {
    let searchText = this.providerSearchMessage.searchText;
    let conceptType = "";
    let concept = "";

    let filtersString = this.getFiltersIds().join(',');

    let accountName = this.baseService.getCliniwebDirectoryCompanyName();
    let accountId = this.baseService.getCliniwebDirectoryCompanyId();
    let locationCompanyIds = this.baseService.getCliniwebDirectoryLocationCompanyIds();        

    let location = this.baseService.getLocationFromIdentifier(this.providerSearchMessage.territorialDivisionIdentification);

    if (!accountName)
        accountName = this.baseService.getCompanyName();

    if (this.providerSearchMessage.conceptNav) {
        // Some time the navigation contains '/' like: '/especialidad/cardiologia' in those cases filter the empty strings after split
        let parts = this.providerSearchMessage.conceptNav.split("/").filter(e => e);
        conceptType = parts[0];
        concept = parts[1];
    }

    item.loading = true;

    return this.publicProfileService.getPublicProfileFilters(
      this.baseService.getLanguage(),
      conceptType,
      searchText, 
      concept,
      filtersString,
      accountName,
      location.country,
      location.state,
      location.city,
      item.clinicConceptType,
      this.publicProfileTypes,
      accountId,
      locationCompanyIds,
      this.providerSearchMessage.conceptId,
      this.providerSearchMessage.conceptClaseId
      ).pipe(
        map((result:ProfileFilterSearchResult[]) =>{
          let resultItem = result[0];

          if (resultItem) {
            // Mark the items as loaded
            item.loadOption(resultItem.conceptos, prefilters);
          }

          item.loading = false;

          return true;
        }),
        catchError((err) => {
          this.baseService.handleServiceError(err, "Error getting profile filters");          
          return of(false);
        })
      );
  }

  private getFiltersIds(){
    let filterByParams = this.providerSearchMessage.filtros 
      ? this.providerSearchMessage.filtros.split(',').map(f => +f)
      : [];

    let filterSelected = this.filterModel.getSelectedConcepts();

    let filterIds = filterByParams.concat(filterSelected);

    filterIds = filterIds.filter((item, index)=> { return (filterIds.indexOf(item) == index)});

    return filterIds;
  }

  private onChangesInputs(){
    this.filterModel.clear();

    if(this.providerSearchMessage.filtros){      
      // si hay filtros en el mensaje es por un aseguradora seleccionada
      
      this.loading = true;

      let filters = this.providerSearchMessage.filtros.split(',').map(f=> +f);
      let insuranceFilter = this.filterModel.items.find(i=> i.type == DirectoryFiltersItemType.Aseguradora);

      if(insuranceFilter){
        this.loadFilters(insuranceFilter, filters)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(result =>{
            this.getSelectedFilters();
          });
      }      
    }
    else{
      this.getSelectedFilters();
    }
  }
}

export class ProviderFilterModel{
  controlId:number = 1;
  loaded = false;
  items: ProviderFilterModelItem[];

  loadFromModel(filters: DirectoryFiltersItemsModel[]){
    this.items = [];

    let favoriteFilter = filters.find(f => f.tipo == DirectoryFiltersItemType.RedFavorita);

    filters.map(f => {
      let item = new ProviderFilterModelItem();
      item.loadFromModel(f, this.controlId);

      // se completa el campo para excluirlo del filtro que no es favorito y que es del mismo tipo de concepto
      item.favoriteFilterId = favoriteFilter && favoriteFilter.idClaseConceptoClinico == item.clinicConceptType 
        ? favoriteFilter.idConceptoClinico 
        : 0;

      this.controlId++;

      this.items.push(item);
    });

    this.loaded = true;
  }

  getSelectedConcepts() {
    let selectedList:number[] = [];

    if(this.items){
      this.items.forEach(item => {
        let  conceptIds = item.getSelectedConceptIds();
        selectedList = selectedList.concat(conceptIds);
      });
    }

    return selectedList;
  }

  getSelectedConceptsObject(){
    let selectedList:ProfileFilterSearchResultConcept[] = [];

    if(this.items){
      this.items.forEach(item => {
          let  concepts = item.getSelectedConcepts();
          selectedList = selectedList.concat(concepts);
      });
    }

    return selectedList;
  }

  clear() {
    if (this.items)
        this.items.map(i => i.clear());
  }

  remove(conceptId:number){
    this.items.forEach(item=> {
      if(item.isDropdown() && item.selectedConcepts.find(sc=>sc.cc == conceptId) || item.clinicConceptId == conceptId){
        item.remove(conceptId);
      }
    });
  }
}

export class ProviderFilterModelItem {
  controlId:number = 0;
  name: TextLanguage[];
  showInputSearch: boolean;
  type: DirectoryFiltersItemType;
  clinicConceptType:number = 0;
  clinicConceptId:number = 0;
  concepts: ProfileFilterSearchResultConcept[] = [];
  filteredConcepts: Observable<ProfileFilterSearchResultConcept[]>;
  loading:boolean;
  loaded:boolean;    
  active:boolean;
  selectedConcepts: ProfileFilterSearchResultConcept [] = [];
  favoriteFilterId: number;
  hideOnDesktop: boolean;
  singleSelection: boolean;
  groups: DirectoryFiltersGroupModel[];
  viewConfig: DirectoryFiltersViewConfig;

  optionsSubj = new Subject<ProfileFilterSearchResultConcept[]>();

  loadFromModel(item: DirectoryFiltersItemsModel, controlId:number) {
    this.controlId = controlId;
    this.name = item.nombreClaseConceptoClinico;
    this.showInputSearch = item.mostrarInputSearch;
    this.type = item.tipo;
    this.clinicConceptType = item.idClaseConceptoClinico;
    this.clinicConceptId = item.idConceptoClinico;
    this.hideOnDesktop = item.ocultarEnDesktop;
    this.singleSelection = item.seleccionSimple;
    this.groups = item.grupos;
    this.viewConfig = item.configuracionVista;
  }

  isDropdown() {
    return this.clinicConceptId <= 0;
  }

  isFavoriteFilter(){
    return this.type == DirectoryFiltersItemType.RedFavorita;
  }

  needToLoadOptions() {
    return this.isDropdown() && !this.loaded;
  }

  needToFireSearchOnClick() {
    return !this.isDropdown();
  }

  clear() {
    this.loaded = false;
    this.active = false;
    this.selectedConcepts = [];
    this.optionsSubj.next([]);
  }

  remove(conceptId:number){
    this.loaded = false;
    this.active = false;
    this.selectedConcepts = this.selectedConcepts.filter(selectedConcept=> selectedConcept.cc != conceptId);
    this.optionsSubj.next(this.selectedConcepts);
  }

  getSelectedConceptIds(): number[] {
    return this.isDropdown() && this.selectedConcepts.length > 0
      ? this.selectedConcepts.map(c => c.cc)
      : (!this.isDropdown() && this.active)
        ? [this.clinicConceptId]
        : [];
  }

  getSelectedConcepts(): ProfileFilterSearchResultConcept[]{
    return this.isDropdown() && this.selectedConcepts.length > 0
      ? this.selectedConcepts
      : (!this.isDropdown() && this.active)
        ? [{cc: this.clinicConceptId, n_cc: JSON.stringify(this.name)} as ProfileFilterSearchResultConcept ]
        : [];
  }

  loadOption(options: ProfileFilterSearchResultConcept[], prefilters: number[] = []) {
    this.concepts = this.favoriteFilterId > 0 
      ? options.filter(op => op.cc != this.favoriteFilterId)
      : options;    
    
    this.loaded = true;

    if(prefilters.length > 0){

      prefilters.forEach(pf => {
        let concept = this.concepts.find(c => c.cc == pf);

        if(concept && this.selectedConcepts.filter(sc=> sc.cc === concept?.cc).length == 0)
            this.selectedConcepts.push(concept);
      });
    }

    // Refresh observer
    this.optionsSubj.next(this.concepts);
  }

  onClick() {
    // Chip
    if (!this.isDropdown())
        this.active = !this.active; 
    // Dropdown
    else {
        
    }
  }

  getIconClass() {
    let iconClass = "";

    switch (this.type) {
        case DirectoryFiltersItemType.RedFavorita:
          iconClass = "phr-iconset-fav";
          break;
        case DirectoryFiltersItemType.RedesSura:
          iconClass = "phr-iconset-shield";
          break;
        case DirectoryFiltersItemType.Especialidad:
          iconClass = "phr-iconset-estetoscopio";
          break;
        case DirectoryFiltersItemType.AreaEnfoque:
          iconClass = "phr-iconset-PHR-card";
          break;
        case DirectoryFiltersItemType.Diagnostico:
          iconClass = "phr-iconset-illness";
          break;
        case DirectoryFiltersItemType.Procedimiento:
          iconClass = "phr-iconset-procedimientos";
          break;
        case DirectoryFiltersItemType.Teleconsulta:
            iconClass = "phr-iconset-video-consulta";
            break;
        case DirectoryFiltersItemType.SedeSura:
          iconClass = "phr-iconset-place";
          break;
        case DirectoryFiltersItemType.Abierto24Hrs:
            iconClass = "phr-iconset-clock";
            break;
        case DirectoryFiltersItemType.Delivery:
            iconClass = "phr-iconset-delivery";
            break;
        case DirectoryFiltersItemType.TipoCentroImagenes:
            iconClass = "phr-iconset-imagenologia";
            break;
        case DirectoryFiltersItemType.Urgencias:
            iconClass = "phr-iconset-urgencia";
            break;
        case DirectoryFiltersItemType.Hospitalizacion:
            iconClass = "phr-iconset-suero";
            break;
        case DirectoryFiltersItemType.Cirugia:
            iconClass = "phr-iconset-bisturi";
            break;
        case DirectoryFiltersItemType.LugarAtencion:
          iconClass = "phr-iconset-hospital";
          break;
        case DirectoryFiltersItemType.AgendamientoEnLinea:
          iconClass = "phr-iconset-suradate";
          break;
        case DirectoryFiltersItemType.Aseguradora:
          iconClass = "phr-iconset-aseguradora2";
          break; 
        default:
            break;
    }

    return iconClass;
  }

  getOptionsObserver() {
    return this.optionsSubj.asObservable();
  }

  filterOptions = (searchText: any) => {
    // When an option is selected, here we receive the complete object instead of the search string        
    if (searchText && searchText.id)
        searchText = searchText.n_cc;

    const filterValue = searchText.toLowerCase();

    return this.concepts.filter(c => c.n_cc.toLowerCase().indexOf(filterValue) === 0);
  }

  getDisplayName = (option: ProfileFilterSearchResultConcept) => {
    if (!option)
        return "";

    return this.singleSelection
      ? `<span class="text-item">${option.n_cc}</span>`
      : `<span class="text-item">${option.n_cc}</span><span> (${option.num})</span>`;
  }

  getDisplayTitleName = (option: ProfileFilterSearchResultConcept) => {
    if (!option)
        return "";
    
    return option.n_cc;
  }

  getSelectedOptionDisplayName = (option: ProfileFilterSearchResultConcept) => {
    let displayValue = '';
    if(this.selectedConcepts.length > 0){
      this.selectedConcepts.forEach((concept, index) => {
        if (index === 0) {
          displayValue = concept.n_cc;
        } else {
          displayValue += ', ' + concept.n_cc;
        }
      });
    }
    
    return displayValue;
  }

  selectOption(option: ProfileFilterSearchResultConcept) {
    this.selectedConcepts.push(option);
  }
}

export class DirectoryFiltersModel {
  providers: DirectoryFiltersNetworkModel[];
}

export class DirectoryFiltersNetworkModel {
  type: PublicProfileType;
  searchProviderTypes: PublicProfileType[];
  filters: DirectoryFiltersItemsModel[];
}

export class DirectoryFiltersItemsModel {
  tipo: DirectoryFiltersItemType;
  idConceptoClinico: number;
  idClaseConceptoClinico: number;
  nombreClaseConceptoClinico: TextLanguage[];
  mostrarInputSearch:boolean;
  ocultarEnDesktop: boolean = false;
  seleccionSimple:boolean;
  grupos: DirectoryFiltersGroupModel[];
  configuracionVista: DirectoryFiltersViewConfig;
}

export class DirectoryFiltersGroupModel {
  idsConceptosClinicos: number[];
  tituloGrupo: TextLanguage[];
  tooltip: TextLanguage[];
}

export class DirectoryFiltersViewConfig {
  usarIcono: boolean;
  claseCssIcono: string;
}