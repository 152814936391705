import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { L10nLocale, L10N_LOCALE, L10nTranslationService } from 'angular-l10n';
import { Subject } from 'rxjs';
import { CloseRouteDialogMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { takeUntil } from 'rxjs/operators';
import { PaymentService } from 'src/app/core/services/payment-service';
import { UserPaymentItem } from 'src/app/shared/models/finance/payments/user-payment-item.model';
import { BaseService } from 'src/app/core/services/base.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { CustomCurrencyPipe } from 'src/app/shared/pipes/custom-currency.pipe';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-user-payments-list',
  templateUrl: './user-payments-list.component.html',
  styleUrls: ['./user-payments-list.component.css'],
  providers: [CustomCurrencyPipe],
  encapsulation: ViewEncapsulation.None
})
export class UserPaymentsListComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  payments: UserPaymentItem[];
  loading: boolean = false;
  
  constructor(    
    @Inject(L10N_LOCALE) public locale: L10nLocale,   
    private translation: L10nTranslationService,
    private messageBusService: MessageBusService,
    private paymentsService: PaymentService,
    private baseService: BaseService,
    private router: Router,
    private dialogRef: MatDialog,
    private routeDialogService: RouteDialogService,
    private currencyPipe : CustomCurrencyPipe,
    @Inject(DOCUMENT) private document: Document
  ) {    
  }

  ngOnInit(): void {
    this.document.body.classList.add('user-payments-list');
    this.loading = true;

    this.paymentsService.getUserPayments()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(payments => {      
      this.payments = payments;      

      this.loading = false;  
    },
    error => {
        this.baseService.handleServiceError(error, "Error getting payments");

        this.loading = false;
    });

    // On Header Back click
    this.messageBusService.onNavigateBack()
      .pipe(takeUntil(this.ngUnsubscribe))  
      .subscribe(m => {
        this.messageBusService.closeRouteDialog(new CloseRouteDialogMessage());
      }
    );
  }

  ngOnDestroy() {
    this.document.body.classList.remove('user-payments-list');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  paymentTrackBy(index : number, item: UserPaymentItem){
    return item.idPagoElectronico; 
  }
  
  onPaymentClick(payment: UserPaymentItem) {
    this.router.navigate(["/configuracion/pagos/" + payment.idTransaccionFacturaProveedor]);
  }

  formatPaymentAmount(amount: number) {
    return this.currencyPipe.transform(amount.toFixed(2), 'USD','','1.2-2','en-US');
  }

  onNewPaymentClick() {
    let dialogData = new DialogData();
    
    dialogData.title = this.translation.translate("shared.userPaymentsList.text3");   
    dialogData.message = this.translation.translate("shared.userPaymentsList.text4");    
    dialogData.confirmText = this.translation.translate("shared.userPaymentsList.text5");   
    dialogData.showHeaderIcon = false;
    dialogData.showHeader = true;
    //dialogData.headerIconClass = "phr-iconset-info";   
    dialogData.showCancelButton = true;
    dialogData.cancelText = this.translation.translate("shared.userPaymentsList.text6");

    const dialogRef = this.dialogRef.open(ConfirmDialogComponent, {
        data: dialogData,
        panelClass: ['confirm-logout-dialog', 'confirm-logout-animation-in', 'new-payment-info-dialog']
    });

    dialogRef.afterClosed().subscribe(accepted => {    
        if (accepted) {          
          let cliniPayEnabledFilter = 61903;
          let qs = {filtros: cliniPayEnabledFilter};
          this.routeDialogService.closeDialogsAndNavigate("/doctores/todos", {queryParams: qs});
        }
    });    
  }  
}



