import { Injectable } from '@angular/core';
import { IWorkflowDataState } from 'src/app/shared/interfaces/workflow-data-state';
import { Workflow } from 'src/app/shared/models/systems/workflow.model';
import { OnlineAppointmentCliniwebState } from 'src/app/shared/models/workflow/states/online-appointment-cliniweb-state.model';
import { AppStorageService, STORAGE } from './app-storage.service';
import { HttpBaseService } from './http-base-service';
import { OnlinePaymentWorkflowState } from 'src/app/shared/models/workflow/states/online-payment-workflow-state.model';
import { environment } from 'src/environments/environment';
import { LocationPublicProfile, PublicProfile } from 'src/app/shared/models/people/public-profile.model';
import { OnlinePaymentModel } from 'src/app/shared/models/workflow/models/online-payment.model';
import { Router } from '@angular/router';
import { AppointmentCheckout } from 'src/app/shared/models/workflow/models/appointment-checkout.model';
import { TokenState } from 'src/app/shared/models/token.model';
import { MedicalOrderWorkflowState } from 'src/app/shared/models/workflow/states/medical-order-workflow-state.model';
import { GrowthCurvesWorkflowState } from 'src/app/shared/models/workflow/states/growth-curves-workflow-state.model';
import { IAuthenticationWorkflowState } from 'src/app/shared/models/workflow/steps/authentication-step.model';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  encryptSessionData = environment.encryptLocalStorage;

  constructor(
    private http: HttpBaseService,
    private appStorageService: AppStorageService,
    private router: Router
  ) { }   

  get(idTipoFlujoTrabajo: number, idEmpresa?: number, idResponsableServicio?:number){    
    let url = `api/flujos-trabajo?idTipoFlujoTrabajo=${idTipoFlujoTrabajo}`;
    
    if(idEmpresa)
      url += `&idEmpresa=${idEmpresa}`;
      
    if(idResponsableServicio)
      url += `&idResponsableServicio=${idResponsableServicio}`;

    return this.http.get<Workflow>(url, null, false);
  }  

  setWorkflowTypeActive(type:string){
    this.appStorageService.setItemSession(STORAGE.WORKFLOW_TYPE, type, this.encryptSessionData);
  }
  
  getWorkflowTypeActive():string{
    return this.appStorageService.getItemSession(STORAGE.WORKFLOW_TYPE, this.encryptSessionData);
  }

  getWorkflowDataState(type?:string):any{

    let workflowType = type ?? this.appStorageService.getItemSession(STORAGE.WORKFLOW_TYPE, this.encryptSessionData);

    let workflowState = this.appStorageService.getItemSession(workflowType, this.encryptSessionData);

    if(!workflowState)
      return null;

    switch(workflowType){
      case STORAGE.ONLINE_APPOINTMENT_CLINIWEB_STATE:
        workflowState = new OnlineAppointmentCliniwebState(workflowState);
        break;
      case STORAGE.ONLINE_PAYMENT_WORKFLOW_STATE:
        if (workflowState)
          workflowState = new OnlinePaymentWorkflowState(workflowState);
        break;
      case STORAGE.MEDICAL_ORDER_WORKFLOW_STATE:
        if (workflowState)
          workflowState = new MedicalOrderWorkflowState(workflowState);
        break;
      case STORAGE.GROWTH_CURVES_WORKFLOW_STATE:
        if (workflowState)
          workflowState = new GrowthCurvesWorkflowState(workflowState);
        break;
      default:        
        break;
    }
    return workflowState;
  }

  setWorkflowDataState(workflowDataState: IWorkflowDataState | IAuthenticationWorkflowState, type?:string):void{
    
    let workflowType = type ?? this.appStorageService.getItemSession(STORAGE.WORKFLOW_TYPE, this.encryptSessionData);

    this.appStorageService.setItemSession(workflowType, workflowDataState, this.encryptSessionData);
  }

  removeWorkflowData(type?:string) {
    let workflowType = type ?? this.appStorageService.getItemSession(STORAGE.WORKFLOW_TYPE, this.encryptSessionData);

    this.appStorageService.removeSessionItem(workflowType);
  }

  getTokenStateFromWorkflow() : TokenState {
    let tokenState: TokenState = new TokenState();
    let workflowState = this.getWorkflowDataState();    

    if (workflowState instanceof OnlineAppointmentCliniwebState) {      
      let appointmentState = workflowState as OnlineAppointmentCliniwebState;

      tokenState.workflowType = this.getWorkflowTypeActive();

      tokenState.publicProfileIdentifier = appointmentState.getPublicProfile().username;      
      tokenState.locationId = appointmentState.onlineAppointmentRequest.idLocalidad;
      tokenState.targetDate = appointmentState.getAppointmentInfo().appointmentDate.getTime();
    }
    else if (workflowState instanceof OnlinePaymentWorkflowState) {      
      let paymentState = workflowState as OnlinePaymentWorkflowState;

      tokenState.workflowType = this.getWorkflowTypeActive();
      tokenState.publicProfileIdentifier = paymentState.getPublicProfile().username;
    }

    return tokenState;
  }

  initPaymentWorkflow(publicProfile: PublicProfile, companyName: string) {
    let dto = new OnlinePaymentModel();
    
    dto.idResponsableServicio = publicProfile.id;
    dto.nombreResponsableServicio = publicProfile.nombrePersona;
    dto.urlAvatarResponsableServicio = publicProfile.ubicacionFoto;
    dto.nickname = publicProfile.username;
    dto.especialidadResponsableServicio = publicProfile.especialidad;
    dto.idEmpresa = publicProfile.idEmpresa;  
    dto.nombreEmpresa = companyName;
    dto.emailResponsableServicio = publicProfile.emailRemitenteNotificacion;  
    dto.idEmpresaResponsableServicio = publicProfile.idEmpresa;
    
    dto.perfilPublico = publicProfile;

    this.appStorageService.setItemSession(STORAGE.ONLINE_PAYMENT_WORKFLOW_BOOTSTRAP_STATE, dto);    
    this.router.navigate(["/pagos/checkout"]);
  }

  initAppointmentWorkflow(publicProfile: PublicProfile, location: LocationPublicProfile, dateTimestamp: number) {
    let dto = new AppointmentCheckout();
    let date = new Date(dateTimestamp);
    let endDate = new Date(dateTimestamp + (30 * 60 * 1000));

    dto.fechaInicio = date;
    dto.fechaFin = endDate;
    dto.idLocalidad = location.localidad.id;
    dto.nombreLocalidad = location.localidad.nombre;
    dto.direccionLocalidad = location.localidad.direccion1;
    dto.coordenadasLocalidad = location.localidad.coordenadas;
    dto.telefonoLocalidad = location.localidad.tel 
      ? location.localidad.tel 
      : location.localidad.tel2;
    dto.idResponsableServicio = publicProfile.id;
    dto.nombreResponsableServicio = publicProfile.nombrePersona;
    dto.urlAvatarResponsableServicio = publicProfile.ubicacionFoto;
    dto.nickname = publicProfile.username;
    dto.especialidadResponsableServicio = publicProfile.especialidad;
    dto.idEmpresa = location.idEmpresa ?? publicProfile.idEmpresa;
    dto.teleconsulta = location.localidad.teleconsulta;
    
    dto.perfilPublico = publicProfile;

    this.appStorageService.setItemSession(STORAGE.ONLINE_APPOINTMENT_CLINIWEB_BOOTSTRAP_STATE, dto);    
    this.router.navigate(["/citas/checkout"]);
  }
}
