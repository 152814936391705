<div class="country-form-container" appTouchMoveListener >
    <span class="title">{{ 'shared.countryForm.title' | translate:locale.language }}</span>

    <form class="country-form" [formGroup]="countryForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="outline" class="form-field-outline-simple">
            <mat-select formControlName="country" [value]="countryIdSelected">
                <mat-option *ngFor="let availableCountry of availableCountries" [value]="availableCountry.id">
                    {{ availableCountry.nombreI18n | i18nText:locale.language }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="(submitted || countryForm.controls['country'].touched) && countryForm.controls.country.errors?.required">
                <mat-icon fontSet="phr-iconset" class="phr-iconset-close-circle-line"></mat-icon>
                {{ 'shared.countryForm.countryRequired' | translate:locale.language }}
            </mat-error>            
        </mat-form-field>
        <app-login-singup-error-banner [error]="error"></app-login-singup-error-banner>
        <div class="button-container">
            <button mat-button mat-flat-button color="primary" class="btn-continue" [ngClass]="{'loading': loading }">
                {{ 'shared.countryForm.buttonText' | translate:locale.language }}
            </button>
        </div>
    </form>
</div>