<!-- Timeline -->
<div class="timeline-container" *ngIf="groups || loading">
      
    <div class="timeline-line"></div>

    <!-- Skeleton -->
    <div class="timeline-group sk-group" *ngIf="loading">
      <span class="group-title">
        <ngx-skeleton-loader [theme]="{ 'width': '43px', 'height': '13px', 'margin-top': '7px', 'background-color': 'rgb(178, 193, 226)', 'border-radius': '2px'}"></ngx-skeleton-loader>        
      </span>
      <span class="group-icon">        
      </span>
      <div class="group-items-container">

        <!-- Groups -->
        <div class="group-item" *ngFor="let item of skeletonItems">          
          <div class="item-key">
            <span class="date-day">
              <ngx-skeleton-loader [theme]="{ 'width': '65px', 'height': '13px', 'background-color': 'rgba(83, 86, 90, 0.3)', 'border-radius': '2px'}"></ngx-skeleton-loader>
            </span>
            <span class="icon">
              <ngx-skeleton-loader [theme]="{ 'width': '15px', 'height': '15px', 'background-color': 'rgb(178, 231, 238)', 'border-radius': '50%'}"></ngx-skeleton-loader>
            </span>            
            <span class="date-time">
              <ngx-skeleton-loader [theme]="{ 'width': '55px', 'height': '10px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>
            </span>
          </div>
          <!-- Item Content -->
          <div class="item-content">
            <ng-content *ngTemplateOutlet="TimelineSkeletonContent; context : {item : item}"></ng-content>
          </div>

        </div>              
      </div>
    </div>

    <!-- Groups -->
    <ng-container *ngIf="!loading && groups">
      <div class="timeline-group" *ngFor="let group of groups; index as i;">        
        <span class="group-title">{{group.key}}</span>
        <mat-icon fontSet="phr-iconset" class="group-icon phr-iconset-minus" *ngIf="!group.collapsed" (click)="group.onClick()"></mat-icon> 
        <mat-icon fontSet="phr-iconset" class="group-icon phr-iconset-plus" *ngIf="group.collapsed" (click)="group.onClick()"></mat-icon> 
        <div class="group-items-container" [hidden]="group.collapsed">
            
          <!-- Items -->
          <div class="group-item" [ngClass]="{'cancelled': item.getItemType() === _itemType.CANCELLED, 'dot': item.getItemType() === _itemType.DOT }" *ngFor="let item of group.items">
              
            <!-- Item Key -->
            <div class="item-key">
              <!--<span class="date-day">{{item.getDate() | l10nDate:locale.language:{ day:'numeric', month: 'short' } | uppercase  }}</span>-->
              <span class="date-day" *ngIf="item.showDate()">{{getDateAsStr(item.getDate()) | uppercase  }}</span>
              <mat-icon fontSet="phr-iconset" class="icon phr-iconset-round-check" *ngIf="item.getItemType() === _itemType.CHECKED"></mat-icon> 
              <mat-icon fontSet="phr-iconset" class="icon phr-iconset-round-close" *ngIf="item.getItemType() === _itemType.CANCELLED"></mat-icon>
              <mat-icon fontSet="phr-iconset" class="icon" *ngIf="item.getItemType() === _itemType.DOT"></mat-icon>
              <span class="date-time" *ngIf="item.showTime()">{{item.getDate() | date: 'h:mm a'}}</span>
            </div>

            <!-- Item Content -->
            <div class="item-content">

                <!-- Template Content -->
                <!-- Expose item object to child template -->
                <ng-content *ngTemplateOutlet="TimelineContent; context : {item : item}"></ng-content>

            </div>              
          </div>
        </div>
      </div>
    </ng-container>    

  </div>

  <!-- Empty State -->
  <div class="timeline-container" *ngIf="(!groups || groups.length == 0) && !loading">
    <!-- Template Content -->                
    <ng-content *ngTemplateOutlet="TimelineEmptyStateContent"></ng-content>
  </div>