import { AppointmentInfoDataModel } from "./appointment-info-data.model";

export class SelectPatientDataModel{
    relationship: number = 0;
    appointmentInfo: AppointmentInfoDataModel;

    user: PersonDataModel;
    patientSelected: PersonDataModel;
}

export class PersonDataModel {
    id: number = 0;
    firstname: string;
    lastname: string;
    avatar: string;
    gender: string;
    email: string;
    cellPhone: string;
    birthDate: Date;
    identityPersonValidationStateId: number;
}