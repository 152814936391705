import { Component, ComponentFactoryResolver, ElementRef, HostListener, OnDestroy, Type, ViewContainerRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MessageBusService } from 'src/app/core/services/message-bus.service';

@Component({
  selector: 'app-bottom-sheet-menu',
  templateUrl: './bottom-sheet-menu.component.html',
  styleUrls: ['./bottom-sheet-menu.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BottomSheetMenuComponent implements OnDestroy
{
  @ViewChild('bottomSheetBackdrop', {read: ElementRef}) private backdrop: ElementRef<HTMLDivElement>;
  @ViewChild('bottomSheetContainer', {read: ElementRef}) private container: ElementRef<HTMLDivElement>;
  @ViewChild('bottomSheetNgContainer', {read: ViewContainerRef}) private containerRef: ViewContainerRef;

  private currentComponent?: Type<unknown>;
  private eventoClickAsignado = false;

  constructor(
    private messageBusService: MessageBusService,
    private resolver: ComponentFactoryResolver
  ) {
    this.messageBusService.onToggleBottomSheetSubject().subscribe({next: this.openCloseBottomSheet.bind(this)});
  }

  ngOnDestroy(): void {
    this.currentComponent = undefined;
  }

  private openCloseBottomSheet(args: {action: string, component?: Type<unknown>}) {
    if (args.action === 'open') {
      this.loadComponent(args.component);
      if (!this.container.nativeElement.classList.contains('confirm-logout-animation-in')) {
        this.open();
      } else {
        this.close();
      }
   } else {
    this._close();
   }
  }

  @HostListener("keydown.esc") 
  public onEsc(e: Event): void {
    console.log(e)
    this.close();
  }

  private loadComponent(component: Type<unknown> | undefined): void {
    if (!component || this.currentComponent == component) return;
    this.currentComponent = component;
    const factory = this.resolver.resolveComponentFactory(component);
    this.containerRef.clear();
    const componentRef = this.containerRef.createComponent(factory);
  }

  private open(): void {
    this.container.nativeElement.classList.remove('confirm-logout-animation-out');
    this.backdrop.nativeElement.classList.add('cdk-overlay-backdrop-showing');
    this.container.nativeElement.classList.add('confirm-logout-animation-in');
    if (!this.eventoClickAsignado)
      this.backdrop.nativeElement.addEventListener('click', () => this.close());
  }

  public close(): void {
      this.messageBusService.sendToggleBottomSheetSubject({action: 'close', component: this.currentComponent});
  }

  private _close(): void {
    setTimeout(() => {
      this.backdrop.nativeElement.classList.remove('cdk-overlay-backdrop-showing');
      this.container.nativeElement.classList.remove('confirm-logout-animation-in');
    }, 400);
    this.container.nativeElement.classList.add('confirm-logout-animation-out');
  }

}
