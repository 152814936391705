<mat-card class="unread-card">
    <div class="border primary-background-color"></div>
    <mat-card-content>
        <img src="assets/images/sura/resultados.svg" />
        <p class="primary-color">Examen de glucosa en sangre</p>
        <div class="date-container">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-citas-medicas"></mat-icon>
            <span>Fecha de realización del examen: 17/12/2020</span>
        </div>
        <button mat-button color="accent">{{ 'shared.unreadClinicalExam.buttonText' | translate:locale.language | uppercase }}</button>
    </mat-card-content>
</mat-card>
