import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoginResponse } from 'src/app/shared/models/login-response.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private authService: AuthService){ }

  isLoggedIn(): Observable<boolean>{
    return this.authService.isLoggedInFull();
  }

  login(username: string, password: string): Observable<LoginResponse>{
      return this.authService.login(username, password);
  }
}
