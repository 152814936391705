import { AppointmentInfoDataModel } from "./appointment-info-data.model";

export class PatientFormAppointmentDataModel{
    relationship: number;
    appointmentInfo: AppointmentInfoDataModel;

    firstname: string;
    lastname: string;
    birthdate: Date;
    gender: string;
    email: string;
    identityPersonValidationStateId: number;
}