import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { RequestingPatient, SenderPatientRelationship } from "../../process/base-request.model";
import { WorkflowItem } from "../../systems/workflow.model";
import { OnlineAppointmentCliniwebState } from "../states/online-appointment-cliniweb-state.model";
import { AppointmentInfoDataModel } from "../models/appointment-info-data.model";
import { BaseStep } from "./base-step.model";
import { ComponentDataSetMessage, MessageBusService } from "src/app/core/services/message-bus.service";
import { ComponentName } from "src/app/shared/enums/component-name.enum";
import { SelectPatientRelationshipDataModel } from "../models/select-patient-relationship-data.model";

@Injectable({
    providedIn: 'root',
})
export class SelectPatientRelationshipAppointmentCheckoutStep extends BaseStep<OnlineAppointmentCliniwebState> {

    constructor(protected messageBusService: MessageBusService){
        super(messageBusService);

        this.showHeader = true;
        this.showButtonBack = true;
        this.showProgressBar = true;
        this.showButtonClose = true;
    }

    setItem(item: WorkflowItem): void {
        this.item = item;
    }

    setState(stateData: any): void {
        this.stateData = new OnlineAppointmentCliniwebState(stateData);
    }

    isCompleted(): Observable<boolean> {
        let patientRelationship = this.stateData.getPatientRelationship();

        return of(patientRelationship != SenderPatientRelationship.DESCONOCIDO);
    }

    showStepInActionBack(): boolean {
        let patientRelationship = this.stateData.getPatientRelationship();

        let patient = this.stateData.getRequestingPatient();

        return patient.id == 0 && patientRelationship != SenderPatientRelationship.USUARIO;
    }

    showStep(): boolean {
        let patientRelationship = this.stateData.getPatientRelationship();

        let patient = this.stateData.getRequestingPatient();

        return patient.id == 0 && patientRelationship != SenderPatientRelationship.USUARIO;
    }

    protected handleComponentSetDataMessage(message: ComponentDataSetMessage) {
        if (message.componentName == ComponentName.SELECT_PATIENT_RELATIONSHIP_APPOINTMENT){
            this.stateData.setPatientRelationship(message.data.patientRelationship);
        }
    }

    protected buildComponentConfigurationData() : { [componentName: string]: any; } {
        let formConfigurationData : { [componentName: string]: any; } = {};
        
        formConfigurationData[ComponentName.SELECT_PATIENT_RELATIONSHIP_APPOINTMENT] = this.getSelectPatientRelationshipDataModel();

        return formConfigurationData;
    }

    protected messageForThisStep(message: ComponentDataSetMessage): boolean {
        return message.componentName == ComponentName.SELECT_PATIENT_RELATIONSHIP_APPOINTMENT;
    }

    private getSelectPatientRelationshipDataModel():SelectPatientRelationshipDataModel{
        let dataModel = new SelectPatientRelationshipDataModel();

        dataModel.appointmentInfo = this.stateData.getAppointmentInfo();
        dataModel.patientRelationship = this.stateData.getPatientRelationship();
        dataModel.patientName = this.stateData.getPatientName();

        return dataModel;
    }

    
}

export interface ISelectPatientRelationshipAppointmentCheckoutWorkflowState{
    getAppointmentInfo():AppointmentInfoDataModel;
    getPatientRelationship(): number;    
    getPatientName():string;
    getRequestingPatient():RequestingPatient;

    setPatientRelationship(patientRelationshipType:number):void;
}
