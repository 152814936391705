import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuGuard } from 'src/app/core/guards/menu.guard';
import { FormComponent } from 'src/app/shared/components/form/form.component';
import { EmptyHomeComponent } from './pages/empty-home/empty-home.component';
import { HomeComponent } from './pages/home/home.component';


export const homeRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [MenuGuard],
    children: [
      {
        path: '',
        component: FormComponent,
      }
    ]
  },
  {
    path: 'empty',
    component: EmptyHomeComponent,
    canActivate: [MenuGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(homeRoutes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
