<div class="policy-menu-container" *ngIf="user">

    <!-- Menu Button -->
    <button class="menu-btn" mat-flat-button [matMenuTriggerFor]="policyMenu" aria-label="Menu Poliza">
        <span>{{getUserName() | titlecase}}</span>
        <mat-icon fontSet="phr-iconset" class="phr-iconset-arrown-down icon"></mat-icon>    
    </button>

    <!-- Menu Content -->
    <mat-menu #policyMenu="matMenu">
        <div class="policy-menu-content">

            <div class="menu-title">{{ 'policyMenu.text1' | translate:locale.language }}</div>
            <!--<div class="divider"></div>-->
    
            <!-- Policy content -->
            <app-policy-menu-content></app-policy-menu-content>

        </div>        
    </mat-menu>
</div>
