import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { LogService } from '../services/log.service';
import { AuthService } from '../services/auth.service';
import { MessageBusService, ServiceErrorMessage } from '../services/message-bus.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private logService: LogService,
    private authService: AuthService,
    private messageBusService: MessageBusService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
//    this.messageBusService.serviceError(new ServiceErrorMessage(''));
    return next.handle(request).pipe(catchError(this.catchError.bind(this)));
  }

  private catchError(err: HttpErrorResponse): Observable<never> {
    const error: string = err?.error?.message || err.statusText;
      
    /*if ([401, 403].includes(err.status) || (err.error && err.error.error == 'invalid_grant')) {
      this.authService.sessionExpired(this.router.routerState.snapshot.url);       
     
      //this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
    }
    else if(err && err.error && err.error.error && err.error.error != 'invalid_grant') {
      this.logService.logError(error);
    }*/

    this.sendLogs(err, error);

    this.showErrorDialog(err);

    return throwError(error);
  }

  private sendLogs(err: any, error: string) {
    if(err?.error?.error && err.error.error != 'invalid_grant') {
      this.logService.logError(error);
    }
  }

  private showErrorDialog(err: any): void {
    if (err.status > 404) {
      this.messageBusService.serviceError(new ServiceErrorMessage(''));
  }
}

}
