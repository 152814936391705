import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreditCardFormatDirective, CreditCardValidators } from 'angular-cc-library';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { BaseService } from 'src/app/core/services/base.service';
import { ComponentDataSetMessage, ComponentFinishLoadingMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { CustomCurrencyPipe } from '../../pipes/custom-currency.pipe';
import { ComponentName } from '../../enums/component-name.enum';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { LegalAgreementsRouteDialogModel } from '../dialogs/route-dialog/route-dialog.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { PublicProfile } from '../../models/people/public-profile.model';

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [CustomCurrencyPipe]
  })
  @ComponentLookup(ComponentName.PAYMENT_FORM)
  export class PaymentComponent implements OnInit, OnDestroy, IControlComponent {
    
    data: FormComponentData;    
    paymentForm: FormGroup;    
    paymentAmount: string = "0.0";
    creditCardLastFourDigits: string = "";
    creditCardType: string = "";
    creditCardTypeSubscribe: boolean = false;
    creditCardOwnerName: string;    
    cvcFocus: boolean = false;
    phoneNumber: string;
    formSubmitted: boolean = false;
    creditCardNumberFocus: boolean = false;
    profileName: string;
    profileSpecialty: string;
      
    @ViewChild('creditCardNumber') creditCardNumberEl: ElementRef;
    @ViewChild('creditCardExpiration') creditCardExpirationEl: ElementRef;
    @ViewChild('creditCardCvc') creditCardCvcEl: ElementRef;

    constructor(
        protected messageBusService: MessageBusService,
        @Inject(L10N_LOCALE) public locale: L10nLocale,
        protected baseService: BaseService,
        private breakpointObserver : BreakpointObserver,
        private formBuilder: FormBuilder,
        private routeDialogService: RouteDialogService,
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    ngOnInit(): void {
      this.document.body.classList.add('payment-form');

      this.paymentForm = this.formBuilder.group({
        creditCardControl: ['', [CreditCardValidators.validateCCNumber]],
        expirationDateControl: ['', [CreditCardValidators.validateExpDate]],
        cvcControl: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
        fullNameControl: ['', Validators.required],
        phoneNumberControl: ['', Validators.required] 
      });

      this.loadFromModel();

      this.sendComponentFinishLoadingMessage();

      setTimeout(() => {
        this.creditCardNumberEl.nativeElement.focus();
      }, 500);      
    }
    
    ngOnDestroy(): void {
      this.document.body.classList.remove('payment-form');
    }

    sendComponentFinishLoadingMessage(){
      if (!this.data)
        return;

      let event = new ComponentFinishLoadingMessage();
      
      event.idFormulario = this.data.idFormulario;
      event.idControl = this.data.idControlPadre;
  
      this.messageBusService.componentFinishLoading(event);   
    }

    loadFromModel() {
      if (this.data && this.data.configurationData) {
        let model = this.data.configurationData as PaymentComponentData;

        if (model.publicProfile) {
          this.profileName = model.publicProfile.nombrePersona;
          this.profileSpecialty = model.publicProfile.especialidades.map(s => s.objetoDetalle).join(", ");
        }         

        if (model.creditCardNumber) {
          let cardExp = model.creditCardExpMonth + "/" + model.creditCardExpYear

          this.setControlValue(ControlNames.CardNumber, model.creditCardNumber);
          this.setControlValue(ControlNames.CardExpirationDate, cardExp);
          this.setControlValue(ControlNames.CardCvc, model.creditCardCvc);
        }        

        if (model.creditCardFullName)
          this.setControlValue(ControlNames.FullName, model.creditCardFullName);

        if (model.phoneNumber)
          this.setControlValue(ControlNames.PhoneNumber, model.phoneNumber);    

        this.paymentAmount = model.amount;
      }      
    }

    /************************* Form control methods ********************************/    

    getControl(controlName: string) {
      return this.paymentForm.controls[controlName];
    }
    isControlInvalid(controlName: string) {
      let control = this.getControl(controlName);

      // First check if the form is submitted or control is dirty
      if (!this.formSubmitted && !control.touched)
        return false;

      return control.invalid;
    }
    controlHasError(controlName: string, errorName: string) {
      return this.getControl(controlName).hasError(errorName);
    }
    getControlValue(controlName: string) {
      return this.getControl(controlName).value;
    }
    setControlValue(controlName: string, value: string) {
      this.getControl(controlName).setValue(value);
    }
   
    /*********************** Credit card input methods **********************************/
    
    onCreditCardNumberFocus(element: CreditCardFormatDirective) {
      this.creditCardNumberFocus = true;
      
      if (!this.creditCardTypeSubscribe) {
        this.creditCardTypeSubscribe = true;

        element.resolvedScheme$.subscribe(creditCardType => {
          if (creditCardType && creditCardType != 'unknown')
            this.creditCardType = creditCardType.toUpperCase();
          else 
            this.creditCardType = "";
        });
      }
    }

    onCreditCardNumberBlur() {
      this.creditCardNumberFocus = false;
    }

    onCreditCardNumberKeyup(event: any) {      
      let el = event.target;

      let creditCardNumber = this.getControlValue(ControlNames.CardNumber);
      let isCursorAtEnd =  (el.selectionStart == creditCardNumber.length);

      this.creditCardLastFourDigits = creditCardNumber.substring(creditCardNumber.length - 4);

      // If credit card number is valid and user is at the end of the input let move to expiration date
      if (!this.getControl(ControlNames.CardNumber).invalid && isCursorAtEnd) {
        this.creditCardExpirationEl.nativeElement.focus();
      }
    }

    isCreditCardExpirationValid(forceValidation: boolean = false) {
      let control = this.getControl(ControlNames.CardExpirationDate);

      // First check if the form is submitted or control is dirty
      if (!forceValidation && !this.formSubmitted && !control.touched)
        return true;

      let dateStr = control.value;

      if (!dateStr)
        return false;
     
      var expParts = dateStr.split("/");

      if (!expParts[0] || !expParts[1])
          return false;

      expParts[0] = expParts[0].trim();
      expParts[1] = expParts[1].trim();

      let now = new Date();
      let expYear = parseInt(now.getFullYear().toString().substring(0, 2) + expParts[1]);
      let expDate = new Date(expYear, expParts[0] - 1, 1).getTime();

      return now.getTime() < expDate;
    }

    onCreditCardExpirationKeyup() {
      var expValue = this.getControlValue(ControlNames.CardExpirationDate);
      var expEl = this.creditCardExpirationEl.nativeElement;

      let isCursorAtEnd =  (expEl.selectionStart == expValue.length);

      if (isCursorAtEnd && this.isCreditCardExpirationValid(true)) {
        this.creditCardCvcEl.nativeElement.focus();
      }
    }

    onCvcFocus() {
      this.cvcFocus = true;
    }
    onCvcBlur() {
      this.cvcFocus = false;
    }
    onCreditCardSecretKeyup(event: any) {
      
    }

    onMobileLastFourDigitsClick() {
      this.creditCardNumberFocus = true;
      this.creditCardNumberEl.nativeElement.focus();
    }

    /*********************** Buttons methods **********************************/  

    onPayClick() {
      this.paymentForm.markAllAsTouched();
      this.formSubmitted = true;

      if (!this.paymentForm.valid || !this.isCreditCardExpirationValid(true))
        return;

      let creditCardExpiration = this.getControlValue(ControlNames.CardExpirationDate);      
      let payAmount = parseFloat(this.paymentAmount);
      let expirationParts = creditCardExpiration.split("/").map((p: string) => p.trim());

      // Build out model
      let model = new PaymentComponentData();
      
      model.amount = this.paymentAmount;
      model.creditCardType = this.creditCardType;
      model.creditCardNumber = this.getControlValue(ControlNames.CardNumber).replace(/\s/g, "");
      model.creditCardExpMonth = expirationParts[0];
      model.creditCardExpYear = expirationParts[1];
      model.creditCardCvc = this.getControlValue(ControlNames.CardCvc);
      model.creditCardFullName = this.getControlValue(ControlNames.FullName);
      model.phoneNumber = this.getControlValue(ControlNames.PhoneNumber);
      
      // Format full name
      let regEx = /^\s*([a-zA-Z]{2,25})\s+([a-zA-Z]{2,25}([a-zA-Z]|\s)*)$/;
      let match = model.creditCardFullName.match(regEx);

      if (!match || match.length < 3)
        return;

      model.creditCardFullName = match[1] + " " + match[2].trim().replace(/\s+/, " ");

      // Send message indicating that the model was completed
      let message = new ComponentDataSetMessage();
      
      message.componentName = ComponentName.PAYMENT_FORM;
      message.data = model;

      this.messageBusService.onComponentDataSetMessage(message);
    }

    openDialogLegalAgreements(): void {
      const routeDialogModel = new LegalAgreementsRouteDialogModel();
      routeDialogModel.route = 'terminos-y-condiciones';
      this.routeDialogService.openDialog(routeDialogModel);
    }

    isMobile(): boolean {
      return this.breakpointObserver.isMatched([Breakpoints.Handset, Breakpoints.Tablet]);
    }

  }

  export class PaymentComponentData {
    creditCardType: string;
    creditCardNumber: string;
    creditCardExpMonth: string;
    creditCardExpYear: string;
    creditCardCvc: string;
    creditCardFullName: string;
    phoneNumber: string;
    amount: string;

    publicProfile: PublicProfile;
  }

  export enum ControlNames {
    CardNumber = "creditCardControl",
    CardExpirationDate = "expirationDateControl",
    CardCvc = "cvcControl",
    FullName = "fullNameControl",
    PhoneNumber = "phoneNumberControl"
  }