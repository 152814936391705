import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LegalAgreementType } from 'src/app/shared/enums/legal-agreements-type.enum';

@Component({
  selector: 'app-legal-agreements-dialog',
  templateUrl: './legal-agreements-dialog.component.html',
  styleUrls: ['./legal-agreements-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LegalAgreementsDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LegalAgreementsDialogData,
    private mdDialogRef: MatDialogRef<LegalAgreementsDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  close(){
    this.mdDialogRef.close();
  }
}
export class LegalAgreementsDialogData{
  type: LegalAgreementType;
}
