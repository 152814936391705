import { Inject, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { Subject } from 'rxjs';
import { ClinicHistoryService } from 'src/app/core/services/clinic-history.service';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { GroupConcept } from 'src/app/shared/enums/group-concept.enum';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { AttentionGroupItem, AttentionHistory, ClinicHistoryItem } from 'src/app/shared/models/clinic-history/attention-history.model';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';

@Component({
  selector: 'app-medicines',
  templateUrl: './medicines.component.html',
  styleUrls: ['./medicines.component.css']
})
@ComponentLookup('MedicinesComponent')
export class MedicinesComponent implements OnInit, OnDestroy, IControlComponent {
  data: FormComponentData;

  attentions: AttentionHistory[] = [];
  items: MedicinesViewDTO[] = [];

  //subscribes
  private ngUnsubscribe = new Subject();

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private messageBusService: MessageBusService,
    private clinicHistoryService: ClinicHistoryService
  ) { }

  ngOnInit(): void {
    this.messageBusService.onSendClinicHistory()
    .subscribe(message =>{
      this.createViewDto(message.clinicHistory.historialAtenciones);
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  createViewDto(atenciones: AttentionHistory[]){

    let atencionesLaboratorios = this.clinicHistoryService.getAttentionsByGroup(atenciones, GroupConcept.PRESCRIPCION_MEDICA);

    atencionesLaboratorios.forEach(attention =>{

      let dto = new MedicinesViewDTO();

      dto.titulo = attention.contactoClinico.asunto;
      dto.fecha = attention.contactoClinico.fechaContacto;
      dto.responsable = attention.contactoClinico.nombreResponsableServicio;

      dto.conceptosMedicamentos = attention.atenciones.map(a => new MedicinesItemViewDTO(a));

      this.items.push(dto);
    });
  }  

  getClassPreviewFile(concept: ClinicHistoryItem): string {
    return this.clinicHistoryService.getClassPreviewFile(concept.extensionDocumento);
  }

  getPreviewFileUrl(concept: ClinicHistoryItem): string {
    let url = concept.nombreBucketAmazon ? concept.nombreBucketAmazon : concept.ubicacionWebDocumento;

    return this.clinicHistoryService.getPreviewFileUrl(concept.extensionDocumento, url);
  }

}

export class MedicinesViewDTO{
  titulo: string;
  fecha: Date;
  responsable: string;
  conceptosMedicamentos: MedicinesItemViewDTO[];
}

export class MedicinesItemViewDTO {
  titulo: string;
  conceptos: ClinicHistoryItem[];
  documentos: ClinicHistoryItem[];
  tieneConceptos: boolean;
  tieneDocumentos: boolean;

  constructor (group: AttentionGroupItem){
    this.titulo = group.nombreTipoConcepto;    
    this.conceptos = group.items.filter(c => c.idDocumento == 0);
    this.documentos = group.items.filter(c => c.idDocumento > 0);
    this.tieneConceptos = this.conceptos.length > 0;
    this.tieneDocumentos = this.documentos.length > 0;
  }
}
