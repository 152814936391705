export class AttentionHistory {
    contactoClinico: ClinicalContact;
    atenciones: AttentionGroupItem[];   
}

export class ClinicalContact {
    id: number;
    idSolicitud: number;
    idTipoSolicitud: number;
    fechaContacto: Date;
    idResponsableServicio: number;
    nombreResponsableServicio: string;
    avatarResponsableServicio: string;
    asunto: string;
    edadPaciente: string;
    idEstatus: number;
}

export class AttentionGroupItem {
    idTipoConcepto: number;
    nombreTipoConcepto: string;
    agrupadores: number[];

    items: ClinicHistoryItem[];
}

export class ClinicHistoryItem {
    id: number;
    idTipoConcepto: number;
    idConcepto: number;
    nombreConcepto: string;
    nombreTipoConcepto: string;
    valorConcepto: string;
    valorHtmlConcepto: string;
    fecha: Date;
    descripcion: string;
    fechaRegistro: Date;
    idPaciente: number;
    nombrePaciente: string;
    idResponsableAtencion: number;
    nombreResponsableAtencion: string;
    idContactoClinico: number;
    nombreEmisorAtencion: string;
    idFormulario: number;
    idControlFormulario: number;
    idAgrupador: string;
    idDocumento: number;
    nombreDocumento: string;
    idTipoDocumento: number;
    extensionDocumento: string;
    ubicacionWebDocumento: string;
    nombreBucketAmazon: string;
}