<div class="view-dependent-container">    
    <mat-card class="payment-card" *ngIf="dependent">

        <div class="title">
            {{'shared.viewDependent.text1' | translate:locale.language}} {{dependent.nombre}}
            <span>({{dependent.relationshipName}})</span>
        </div>
        <div class="user-avatar-container">
            <app-avatar-upload
                [uploadData]="{idPersona: dependent.idPersona, idRelacionDependiente: dependent.id}"
                [fileUrl]="dependent.ubicacionLogo"
                [options]="avatarUploadOptions"
                (onFileUploaded)="onAvatarUploaded($event)"
            ></app-avatar-upload>
        </div>

        <div class="readonly-data-container">
            <div class="item">
                <div class="item-title">
                    {{'shared.viewDependent.text4' | translate:locale.language}}
                </div>
                <div class="item-value">
                    {{dependent.fullName}}
                </div>
            </div>
            <div class="item" *ngIf="fechaNacimiento">
                <div class="item-title" >
                    {{'shared.viewDependent.text5' | translate:locale.language}}
                </div>
                <div class="item-value">
                    <span>{{ fechaNacimiento }}</span>                    
                </div>
            </div>
            <div class="item" *ngIf="dependent.sexName">
                <div class="item-title">
                    {{'shared.viewDependent.text6' | translate:locale.language}}
                </div>
                <div class="item-value" style="text-transform: capitalize;">
                    <mat-icon fontSet="phr-iconset" class="{{dependent.sexIconClass}} icon phr-iconset"></mat-icon>
                    {{dependent.sexName}}
                </div>
            </div>            
        </div>

    </mat-card>

    <div class="buttons-container">
        <button mat-button mat-flat-button class="secondary" (click)="onDeleteClick()" [ngClass]="{'loading': saving}">
            {{'shared.viewDependent.text8' | translate:locale.language}}            
        </button>
        <button mat-button mat-flat-button class="primary" (click)="onEditDependentClick()" [disabled]="saving">
            {{'shared.viewDependent.text7' | translate:locale.language}}
        </button>
    </div>
</div>