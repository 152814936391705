import { Component, HostListener, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { inOutAnimation } from '../../animations';
import { AppointmentInfoDataModel, AppointmentType } from '../../models/workflow/models/appointment-info-data.model';
import { SenderPatientRelationship } from '../../models/process/base-request.model';
import { MapDialogData } from '../../models/dialog-data.model';
import { MatDialog } from '@angular/material/dialog';
import { MapDialogComponent } from '../dialogs/map-dialog/map-dialog.component';
import { PublicProfileAvatarComponentModel } from '../public-profile-avatar/public-profile-avatar.component';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { PublicProfileRouteDialogModel, RouteDialogType } from '../dialogs/route-dialog/route-dialog.component';

@Component({
  selector: 'app-appointment-info-checkout',
  templateUrl: './appointment-info-checkout.component.html',
  styleUrls: ['./appointment-info-checkout.component.css'],
  animations: [ inOutAnimation ]
})
export class AppointmentInfoCheckoutComponent implements OnChanges, OnInit {

  language: string;
  @Input() model:AppointmentInfoDataModel;
  @Input() show: boolean = true;
  @Input() showRelative: boolean = false;
  @Input() enablePublicProfileLinks: boolean = false;
  @Input() initialLoading = false;
  attentionPlaceLabel = 'shared.appointmentInfoCheckout.attentionPlaceLabel';
  isDesktop: boolean;
  avatarModel: PublicProfileAvatarComponentModel;
  isVirtualAppointment: boolean = false;

  constructor(
    private dialog: MatDialog,
    private translation: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private publicProfileService: PublicProfileService,
    private routeDialogService: RouteDialogService
  ) {
    this.language = locale.dateLanguage ?? 'es-AR';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model && this.model.perfilPublico) {
      this.avatarModel = new PublicProfileAvatarComponentModel();
      this.avatarModel.personId = this.model.perfilPublico.id;
      this.avatarModel.avatarUrl = this.model.perfilPublico.ubicacionFoto;
      this.avatarModel.fullName = this.model.perfilPublico.nombrePersona;
      this.avatarModel.sex = this.model.perfilPublico.sexo;
      this.avatarModel.profileType = this.model.perfilPublico.tipoPerfil;
    }
  }

  ngOnInit(): void {
    this.isDesktop = window.innerWidth > 799;

    this.setAttentionPlaceLabel();

    this.isVirtualAppointment = (this.model.appointmentType == AppointmentType.VIRTUAL);
  }
  
  private setAttentionPlaceLabel(): void {
    if (this.model.appointmentType == AppointmentType.VIRTUAL)
      this.attentionPlaceLabel = 'shared.appointmentInfoCheckout.attentionPlaceLabel2';
  }

  getAppointmentTypeText():string{
    return this.model.appointmentType == AppointmentType.FACE_TO_FACE 
      ? this.translation.translate("shared.appointmentInfoCheckout.faceToFaceAppointment")
      : this.translation.translate("shared.appointmentInfoCheckout.virtualAppointment");
  }

  getAppointmentTypeClasname():string{
    return this.model.appointmentType == AppointmentType.FACE_TO_FACE
      ? 'phr-iconset-home'
      : 'phr-iconset-PHR-video';
  }

  showRelationship(){

    return this.model.patientRelationshipType > 0 
      && this.model.patientRelationshipType != SenderPatientRelationship.USUARIO
      && this.model.patientRelationshipType != SenderPatientRelationship.DESCONOCIDO;

  }

  getRelationshipText(){

    this.model.patientGender = this.model.patientGender == '9' 
      ? 'M' 
      : this.model.patientGender;

    let _textRef = `shared.appointmentInfoCheckout.relationship_${this.model.patientRelationshipType}_${this.model.patientGender}`;
    
    return this.translation.translate(_textRef);
  }

  openMap(){
    let data = new MapDialogData();
    
    data.title = this.model.attentionPlaceName;
    data.message = this.model.attentionPlaceLatLng;
    data.animationIn = 'map-phr-animation-in';
    data.animationOut = 'map-phr-animation-out';
    data.showCloseTextButton = true;
    data.showWazeButton = false;

    this.dialog.open(MapDialogComponent, {          
      panelClass: ['map-dialog','map-phr-dialog', 'map-phr-animation-in'],
      data: data
    });
  }

  goToPublicProfile() {    
    if(!this.enablePublicProfileLinks)
      return;

    let url = this.publicProfileService.getPublicProfileUrl(this.model.perfilPublico.username);

    if (this.routeDialogService.isOpen(RouteDialogType.APPOINTMENT_SUMMARY))
      this.routeDialogService.closeDialog(RouteDialogType.APPOINTMENT_SUMMARY);
    
    // Final step from appointment creation
    if (this.routeDialogService.isOpen(RouteDialogType.PUBLIC_PROFILE)) {
      this.routeDialogService.closeDialogsAndNavigate(url);
    }
    else {
      setTimeout(() => {
        let routeDialogModel = new PublicProfileRouteDialogModel();
        routeDialogModel.route = url;
  
        this.routeDialogService.openDialog(routeDialogModel);
      }, 1000);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isDesktop = event.target.innerWidth > 799;
  }
}