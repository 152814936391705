export class Workflow {
    id: number;
    nombre: string;
    nombreComponente: string;
    tipo: number;
    idEmpresa?: number;
    idResponsableServicio?: number;
    items: WorkflowItem[];
}

export class WorkflowItem {
    id: number;
    idFlujoTrabajo: number;
    numeroPaso: number;
    idAreaSistema: number;
    valor: string;
    nombreClaseItem: string;
}

export class WorkflowItemData{
    endWorkflow: boolean;
    data: any;

    constructor();
    constructor(obj:WorkflowItemData);
    constructor(obj?: any){
        this.endWorkflow = obj && obj.endWorkflow ? obj.endWorkflow : false;
    }
}