import { Injectable } from '@angular/core';
import { Directory, DirectoryType } from 'src/app/shared/models/systems/directory.model';
import { HttpBaseService } from './http-base-service';
import { Observable } from 'rxjs';
import { DirectorySearchTitle } from 'src/app/shared/interfaces/directory-search-title';

@Injectable({
  providedIn: 'root'
})
export class DirectoryService {

  constructor(
    private http: HttpBaseService
  ) { }      

  getByType(companyId: number, type: DirectoryType) {
    let url = "api/directorios/" + companyId + "?tipo=" + type;

    return this.http.get<Directory>(url, null, false);
  } 

  getByUrl(serverUrl: string) {
    let url = "api/directorios?url=" + serverUrl;

    return this.http.get<Directory>(url, null, false)
  }

  getSearchTitle(
    language:string,
    publicProfileType:number, 
    searchText:string, 
    conceptTypeId: number, 
    conceptId:number, 
    contryCityIdentifier:string, 
    filters:string):Observable<DirectorySearchTitle>{

    let url = 'api/directorios/titulo-busqueda';
    url += `?localizacion=${language}`;

    if(publicProfileType)
      url += `&tipoPerfilPublico=${publicProfileType}`;

    if(searchText)
      url += '&textoBusqueda=' + encodeURIComponent(searchText);

    if(conceptTypeId)
      url += `&idTipoConcepto=${conceptTypeId}`;

    if(conceptId)
      url += `&idConcepto=${conceptId}`;

    if(contryCityIdentifier)
      url += `&identificadorPaisCiudad=${contryCityIdentifier}`;

    if(filters)
      url += `&filtros=${filters}`;

    return this.http.get<DirectorySearchTitle>(url, null, false);
  }
}