
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-learn-more-dialog',
  templateUrl: './learn-more-dialog.component.html',
  styleUrls: ['./learn-more-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LearnMoreDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mdDialogRef: MatDialogRef<LearnMoreDialogComponent>,    
  ) { }

  ngOnInit(): void {
  }

  close(){
    this.mdDialogRef.close();
  }
}
