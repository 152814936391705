<div class="cliniweb-home-title" *ngIf="user">
    <img class="phr-logo mobile" src="/assets/images/cliniweb/logo-cliniweb-phr.svg">
    <div>
        <span>{{ 'shared.cliniwebHomeLayout.text1' | translate:locale.language }} {{userName}},</span>
        <span>{{ 'shared.cliniwebHomeLayout.text2' | translate:locale.language }}</span>
    </div>    
</div>

<div class="cliniweb-home-layout desktop">
    <!-- Column 1 -->
    <div class="column">

        <!-- Row 1 -->
        <div class="column-row">
            <div class="item">
                <app-cliniweb-medical-appointments-linkbox></app-cliniweb-medical-appointments-linkbox>
            </div>
            <div class="item">
                <app-cliniweb-book-appointment-linkbox></app-cliniweb-book-appointment-linkbox>            
            </div>
        </div>
        <div class="column-row">
            <div class="item">
                <app-latest-doctors></app-latest-doctors>
            </div>
        </div>        
        <div class="column-row">
            <div class="item">
                <app-most-common-specialties></app-most-common-specialties>
            </div>
        </div>
    </div>

    <!-- Column 2 -->
    <div class="column">
        <div class="column-row">
            <div class="item">
                <app-health-allies></app-health-allies>
            </div>
        </div>
        <div class="column-row">
            <div class="item">
                <app-preventive-control-tips></app-preventive-control-tips>
            </div>
        </div>
    </div>    
</div>

<div class="cliniweb-home-layout mobile">
    <!-- Column 1 -->
    <div class="column">        
        <div class="column-row">
            <div class="item">
                <app-cliniweb-medical-appointments-linkbox></app-cliniweb-medical-appointments-linkbox>
            </div>
            <div class="item">
                <app-cliniweb-book-appointment-linkbox></app-cliniweb-book-appointment-linkbox>            
            </div>
        </div>
        <div class="column-row">
            <div class="item">
                <app-latest-doctors></app-latest-doctors>
            </div>
        </div>
        <div class="column-row">
            <div class="item">
                <app-health-allies></app-health-allies>
            </div>
        </div>        
        <div class="column-row">
            <div class="item">
                <app-most-common-specialties></app-most-common-specialties>
            </div>
        </div>        
        <div class="column-row">
            <div class="item">
                <app-preventive-control-tips></app-preventive-control-tips>
            </div>
        </div>
    </div>  
</div>