<div class="latest-doctors-container">    
    <mat-card class="card">        
        <mat-card-header>
            <mat-card-title>
                {{ 'shared.latestDoctors.text1' | translate:locale.language }}
            </mat-card-title>
        </mat-card-header>
        
        <mat-card-content>
            <div class="box-container" *ngIf="finishedLoading && latestDoctors && latestDoctors.length > 0">
                <div class="box" *ngFor="let doctor of latestDoctors" (click)="onDoctorClick(doctor)">
                    <!--<div class="picture" *ngIf="doctor.urlAvatarResponsableServicio">
                        <img src="{{doctor.urlAvatarResponsableServicio}}" />
                    </div>
                    <div class="picture" *ngIf="!doctor.urlAvatarResponsableServicio">
                        <div class="empty-image">{{doctor.iniciales}}</div>                        
                    </div>-->
                    <div class="picture">
                        <app-public-profile-avatar [model]="getAvatarModel(doctor)"></app-public-profile-avatar>
                    </div>
                    <div class="specialty">
                        {{ doctor.nombreResponsableServicio }}
                    </div>                    
                    <div class="description">
                        {{ doctor.especialidadResponsableServicio }}
                    </div>
                </div>            
                <div class="box" (click)="onOtherClick()">
                    <div class="picture gray-outer-circle">
                        <img src="assets/images/cliniweb/zoom-desktop.png" />
                    </div>
                    <div class="text">
                        {{ 'shared.latestDoctors.text4' | translate:locale.language }}
                    </div>
                </div>
                <div class="box-invisible" *ngFor="let item of getMissingDoctorCount()">&nbsp;</div>
            </div>

            <div class="box-container" *ngIf="finishedLoading && (!latestDoctors || latestDoctors.length == 0)">
                <!-- Empty state  -->
                <div class="empty-state">
                    <div class="zoom-icon">
                        <img src="assets/images/zoom-icon.png" />
                        <span class="empty-text">
                            {{ 'shared.latestDoctors.text2' | translate:locale.language }}
                        </span>
                        <span class="empty-text">
                            <a class="appointments-link" (click)="onNavigateToAppointmentClick()">{{ 'shared.latestDoctors.text3' | translate:locale.language }}</a>    
                        </span>
                    </div>
                </div>
            </div>

            <div class="box-container" *ngIf="!finishedLoading">
                <div class="box" *ngFor="let item of [1, 2, 3]">
                    <div class="picture gray-outer-circle">
                        <ngx-skeleton-loader [theme]="{ 'width': '100px', 'height': '100px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '50%', 'margin-bottom': '0px' }"></ngx-skeleton-loader>
                    </div>
                    <div class="specialty">
                        <ngx-skeleton-loader [theme]="{ 'width': '140px', 'height': '18px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'margin-bottom': '0px' }"></ngx-skeleton-loader>
                    </div>
                    <div class="description">
                        <ngx-skeleton-loader [theme]="{ 'width': '100px', 'height': '18px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'margin-bottom': '0px' }"></ngx-skeleton-loader>
                    </div>                    
                </div>                
            </div>
        </mat-card-content>
    </mat-card>
</div>