import { Person } from "./person.model";

export class UserPerson extends Person {
    idEmpresa: number;
    numeroTelCelular: string;
    numeroCedula: string;
    titulo: string;
    idPaisResidencia: number;
    idPaisNacimiento: number;
    emailParticular: string;
    idEstadoValidacionIdentidadPersona: number;
    autenticadoPorGoogle: boolean;
    username: string;
}

export class UpdateUser {
    nombre: string;
    apellido: string;
    sexo: string;
    fechaNacimiento: Date;
    ubicacionLogo: string;
    idEstadoValidacionIdentidadPersona?: number;
    idPais: number;
}