import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';
import { ClinicalConceptService } from 'src/app/core/services/clinical-concept.service';
import { ComponentFinishLoadingMessage, FormConfigurationMessage, LoadChatMessage, MessageBusService, OpenChatMessage, ServiceErrorMessage } from 'src/app/core/services/message-bus.service';
import { ChatSignalRService } from 'src/app/core/services/signalR/chat-signalr.service';
import { UserService } from 'src/app/core/services/user.service';
import { VirtualAttentionService } from 'src/app/core/services/virtual-attention.service';
import { ComponentLookup } from '../../decorators/component-lookup.decorator';
import { IControlComponent } from '../../interfaces/control-component';
import { ClinicConcept } from '../../models/cdr/clinic-concept.model';
import { DialogData } from '../../models/dialog-data.model';
import { FormComponentData } from '../../models/people/form-control.model';
import { FormConfiguration } from '../../models/people/form.model';
import { UserPerson } from '../../models/people/user-person.model';
import { VirtualAttention } from '../../models/process/virtual-attention.model';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics.service';

@Component({
  selector: 'app-select-symptoms',
  templateUrl: './select-symptoms.component.html',
  styleUrls: ['./select-symptoms.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ChatSignalRService]
})
@ComponentLookup('SelectSymptomsComponent')
export class SelectSymptomsComponent implements OnInit, OnDestroy, IControlComponent {
  private ngUnsubscribe = new Subject();
  data: FormComponentData;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  symptomCtrl = new FormControl();
  filteredSymptoms$: Observable<ClinicConcept[]>;
  frequentSymptoms: ClinicConcept[] = [];
  symptoms: ClinicConcept[] = [];
  selectedFrequentSymptoms: ClinicConcept[] = [];
  existsResults: boolean = false;
  dropdownAbovePosition: boolean = false;
  autoCompleteOpen = false;
  virtualAttention : VirtualAttention;
  loaded = false;
  loggedUser: UserPerson; 
  hasAgentMessage = false;
  virtualAttentionFinished = false;

  @Input() parentData: FormComponentData;

  @ViewChild('symptomInput') symptomInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private clinicalConceptService: ClinicalConceptService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public dialog: MatDialog,
    private translation: L10nTranslationService,
    private baseService: BaseService,
    private messageBusService: MessageBusService,
    private virtualAttentionService : VirtualAttentionService,
    private userService: UserService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private signalRService: ChatSignalRService
  ) {
    this.filteredSymptoms$ = this.symptomCtrl.valueChanges
    .pipe(
      debounceTime(300),
      switchMap(value => this.clinicalConceptService.getSymptoms(value)
        .pipe(
          map(symptoms => {

            let filteredSymptoms = symptoms ? symptoms.filter(item => {
              let symptomSelectedIds =  this.symptoms.map(s => s.id);
              return symptomSelectedIds.indexOf(item.id) === -1;
            }): [];

            this.existsResults = filteredSymptoms.length > 0;

            return filteredSymptoms;
          })
        )
      )
    );
  }

  ngOnInit(): void {
    if (!this.data)
      this.data = this.parentData;

    this.clinicalConceptService.getFrequentSymptoms()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(frequentSymptoms => {
      this.frequentSymptoms = frequentSymptoms;
    },
    (error : any)=> {
      this.showErrorDialog();      
    });

    let _this = this;
    setTimeout(function() {
      _this.checkActiveVirtualAttention();
    }, 10);
    
    // If the chat was cancelled
    this.messageBusService.onChatCancelled()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(msg => {
      this.virtualAttention = null as any;
      this.configureForm(true);      
    });

    this.messageBusService.onChatLoaded()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(m => {
      this.hasAgentMessage = m.hasAgentMessage;
    });

    this.messageBusService.onOnVirtualRequestFinished()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(m => {
      this.virtualAttentionFinished = true;
    });

    this.userService.getUserPersonLoggedIn()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(logged => {
      if (logged) {
        this.loggedUser = logged;     
      }
    });    
  }

  ngOnDestroy():void{
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  
  goToPastVirtualAttentions() {
    this.virtualAttentionFinished = false;
    this.virtualAttention = null as any;
    this.messageBusService.goToPastVirtualRequests();
  }

  checkActiveVirtualAttention() {
    // Get active virtual atenttion
    this.virtualAttentionService.getActive()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(va => {
      if (va) {        
        this.configureForm();
        
        let msg = new LoadChatMessage();
        msg.idEtapaSolicitud = va.idEtapaSolicitud;
        this.messageBusService.loadChat(msg);

        this.messageBusService.openChat(new OpenChatMessage());
      }

      this.virtualAttention = va;

      // Send event to parent indicating that this control finish loading
      let event = new ComponentFinishLoadingMessage();
      event.idFormulario = this.data.idFormulario;
      event.idControl = this.data.idControlPadre;

      this.messageBusService.componentFinishLoading(event);    

      this.loaded = true;      
    }, 
    (error : any)=> {
      this.showErrorDialog();

      this.loaded = true;
    });    
  }

  configureForm(showItems: boolean = false) {
    // Send form configuration event
    let config = new FormConfiguration();

    config.formId = this.data.idFormulario;
    config.showFormTitle = true;
    config.showFormSubtitle = showItems;
    config.showControlTitle = showItems;

    let msg = new FormConfigurationMessage();
    msg.config = config;

    this.messageBusService.setFormConfiguration(msg);
  }

  addSymptom(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      var currentSymptom = value.trim();

      if (this.symptoms.filter(s=> s.nombre.toLowerCase() == currentSymptom.toLowerCase()).length == 0) {
        let newSymptom = new ClinicConcept();
        newSymptom.nombre = currentSymptom;
        newSymptom.idTipoConcepto = 1866; // concepto = sintomas
        newSymptom.nombreTipoConcepto = "Síntomas";
        newSymptom.id = 0;
        newSymptom.idFuenteReferencia = 15;
        newSymptom.idTerminoReferencia = 0;

        this.symptoms.push(newSymptom);
      }
    }

    if (input) {
      input.value = '';
    }
  }

  addSymptomClick(){    
    let value = this.symptomCtrl.value;
    
    if ((value || '').trim()) {
      value = value.trim();

      if (this.symptoms.filter(s => s.nombre.toLowerCase() == value.toLowerCase()).length == 0) {
        let newSymptom = new ClinicConcept();
        newSymptom.nombre = value.trim();
        newSymptom.idTipoConcepto = 1866; // concepto = sintomas
        newSymptom.nombreTipoConcepto = "Síntomas";
        newSymptom.id = 0;
        newSymptom.idFuenteReferencia = 15;
        newSymptom.idTerminoReferencia = 0;
        
        this.symptoms.push(newSymptom);
      }
    }
    if (this.symptomCtrl.value) {      
      //this.symptomCtrl.setValue(null);
      this.symptomInput.nativeElement.value = '';
      this.symptomInput.nativeElement.focus();
    }
  }

  removeSymptom(symptom: ClinicConcept): void{
    const index = this.symptoms.indexOf(symptom, 0);
    if (index > -1) {
      this.symptoms.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    var  currentSymptom = event.option.value;

    if (currentSymptom) {
      // remove if another one has the same name.
      var symptomName = currentSymptom.nombre.trim();

      this.symptoms = this.symptoms.filter(s=> s.nombre.toLowerCase() != symptomName.toLowerCase());

      this.symptoms.push(currentSymptom);
    }

    this.symptomInput.nativeElement.value = '';
    this.symptomCtrl.setValue(null);
    this.existsResults = false;
  }
  
  autocompleteOpened():void{

      if (this.matAutocomplete.panel && this.matAutocomplete.panel.nativeElement) {
        const parentPanelContainer =  this.matAutocomplete.panel.nativeElement.parentNode;

        if(parentPanelContainer){
          const classList = parentPanelContainer.classList.value.split(' ');
          this.dropdownAbovePosition = classList.filter((c:string) => c == 'mat-autocomplete-panel-above').length > 0; 
        }
      }      

      this.autoCompleteOpen = true;
  }

  autocompleteClosed() {
      this.autoCompleteOpen = false;
  }

  isSelectedFrequentSymptom(frequentSymptom: ClinicConcept): boolean {
    const index = this.selectedFrequentSymptoms.indexOf(frequentSymptom);
    return index >= 0;
  }

  toggleFrequentSymptom(frequentSymptom: ClinicConcept): void {
    let index = this.selectedFrequentSymptoms.indexOf(frequentSymptom);

    if (index >= 0) {
      this.selectedFrequentSymptoms.splice(index, 1);
    } else {
      this.selectedFrequentSymptoms.push(frequentSymptom);
    }
  }

  onClickStartConsultation(): void {
    if(this.symptoms.length == 0 && this.selectedFrequentSymptoms.length == 0){
      this.openEmptySymptomsDialog();
    }
    else{
      this.openPoliciesDialog();
    }
  }

  private openPoliciesDialog() {
    let dialogData = new DialogData();
    dialogData.title = this.translation.translate("shared.selectSymptoms.policiesDialog.title");
    dialogData.message = this.translation.translate("shared.selectSymptoms.policiesDialog.body");
    dialogData.confirmText = this.translation.translate("shared.selectSymptoms.policiesDialog.confirmButtonText");
    dialogData.cancelText = this.translation.translate("shared.selectSymptoms.policiesDialog.cancelButtonText");

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(acceptedPolicies => {
      if(acceptedPolicies) {
        this.startClinicalContact();
      }
    });
  }

  private openEmptySymptomsDialog() {
    let dialogData = new DialogData();
    dialogData.title = this.translation.translate("shared.selectSymptoms.emptySymptomsDialog.title");
    dialogData.message = this.translation.translate("shared.selectSymptoms.emptySymptomsDialog.body");
    dialogData.confirmText = this.translation.translate("shared.selectSymptoms.emptySymptomsDialog.confirmButtonText");
    dialogData.showCancelButton = false;
    dialogData.colorConfirmButton = "accent";

    this.dialog.open(ConfirmDialogComponent, { data: dialogData });
  }

  private startClinicalContact(){

    let allSelectedSymptoms = this.symptoms.concat(this.selectedFrequentSymptoms.filter(s => this.symptoms.find(sy => sy.id == s.id) == null));        
    
    let idEmpresaDestinoConsultaVirtual = this.baseService.getDirectory().idEmpresaDestinoConsultaVirtual;
    let idEmpresaDestino = idEmpresaDestinoConsultaVirtual ? idEmpresaDestinoConsultaVirtual : this.baseService.getCompanyId();
    let idAreaSistemaDestinoConsultaVirtual = this.baseService.getDirectory().idAreaSistemaDestinoConsultaVirtual;
    let nombreEmisor = this.loggedUser.nombre + ' ' + this.loggedUser.apellido;
    let urlImagenEmisor = this.loggedUser.ubicacionLogo;
    let email = this.loggedUser.emailParticular;

    this.loaded = false;

    // Post user active so the user shows as online when the Virtual attention is created
    this.userService.postUserActive(idEmpresaDestino, -1).subscribe(() => {
    },
    error => {
      this.baseService.handleServiceError(error, "Error posting user active");      
    });

    this.virtualAttentionService.startVirtualAttention(
      idEmpresaDestino,
      idAreaSistemaDestinoConsultaVirtual,
      allSelectedSymptoms,
      nombreEmisor,
      urlImagenEmisor,
      email)
      .subscribe(
        idEtapaSolicitud => {
            this.selectedFrequentSymptoms = [];
            this.symptoms = [];

          // Get active virtual attention and load chat  
          this.checkActiveVirtualAttention();

          this.googleAnalyticsService.sendEventInitVirtualConsultation();
        },  
        (error : any)=> {
          this.showErrorDialog();
          this.loaded = true;
        }
    );    
  }

  showErrorDialog() {
    let dialogData = new DialogData();
    dialogData.title = this.translation.translate("shared.selectSymptoms.errorDialog.title");
    dialogData.message = this.translation.translate("shared.selectSymptoms.errorDialog.description");
    dialogData.confirmText = this.translation.translate("shared.selectSymptoms.errorDialog.btnText");
    dialogData.showCancelButton = false;
    dialogData.colorConfirmButton = "accent";

    this.dialog.open(ConfirmDialogComponent, { data: dialogData });
  }  
}