import { IWorkflowDataState } from "src/app/shared/interfaces/workflow-data-state";
import { BaseStep } from "../base-step.model";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ComponentDataSetMessage, MessageBusService } from "src/app/core/services/message-bus.service";
import { ComponentName } from "src/app/shared/enums/component-name.enum";
import { BaseService } from "src/app/core/services/base.service";
import { WorkflowService } from "src/app/core/services/workflow.service";
import { WorkflowItemData } from "../../../systems/workflow.model";
import { PatientVerificationDataModel } from "../../models/patient-verification-data.model";
import { PublicProfile } from "../../../people/public-profile.model";

@Injectable({ providedIn: 'root' })
export class SummaryMedicalOrderStep extends BaseStep<IWorkflowDataState & ISummaryMedicalOrderWorkflowState> {

    constructor(protected messageBusService: MessageBusService, private baseService: BaseService, private workflowService: WorkflowService) {
        super(messageBusService);
        this.showHeader = false;
        this.showProgressBar = true;
        this.showButtonClose = true;
        this.withoutFooter = true;
    }

    protected handleComponentSetDataMessage(message: ComponentDataSetMessage): void {
    }
    protected buildComponentConfigurationData(): { [componentName: string]: any; } {
        const formConfigurationData : { [componentName: string]: any; } = {};
        
        formConfigurationData[ComponentName.SUMMARY_MEDICAL_ORDER] = this.getDataModel();

        return formConfigurationData;
    }

    private getDataModel(): PatientVerificationDataModel {
        let dataModel = new PatientVerificationDataModel();

        dataModel.idVerificacion = this.stateData.getIdVerificacion();
        dataModel.idPaciente = this.stateData.getPatientId();
        dataModel.nombrePaciente = this.stateData.getPatientName();
        dataModel.profile = this.stateData.getPublicProfile();

        if (this.item.valor) {            
            let stepItemData = this.baseService.parseObjectDeep<WorkflowItemData>(JSON.parse(this.item.valor));

            if (stepItemData.data) dataModel.configData = stepItemData.data;
        }

        return dataModel;
    }

    protected messageForThisStep(message: ComponentDataSetMessage): boolean {
        return message.componentName == ComponentName.SUMMARY_MEDICAL_ORDER;
    }
    setState(stateData: IWorkflowDataState): void {
        this.stateData = this.workflowService.getWorkflowDataState();;
    }
    isCompleted(): Observable<boolean> {
       return of(false);
    }
    showStepInActionBack(): boolean {
        return true;
    }
    showStep(): boolean {
        return true;
    }
}

export interface ISummaryMedicalOrderWorkflowState {
    getIdVerificacion(): string;
    getUserId():number;
    getPublicProfile(): PublicProfile;
    getPatientName(): string;
    getPatientId(): number;

    setEmmiterPersonId(personId: number):void;
}
