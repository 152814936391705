<div class="doctor-message-dialog-container">
    <div class="header">
        <div class="dialog-title">{{ 'shared.doctorMessageDialog.text1' | translate:locale.language }}</div>
        <button mat-icon-button class="no-focus-overlay close-btn" (click)="onCloseClick()" [disabled]="loading" tabindex="-1">
            <mat-icon fontSet="phr-iconset" class="header-icon phr-iconset-close icon-close"></mat-icon>  
        </button>
    </div>
    <div mat-dialog-content appTouchMoveListener>        
        <form [formGroup]="messageForm">
            
            <mat-form-field appearance="outline" class="input-field">
                <mat-label>{{ 'shared.doctorMessageDialog.text2' | translate:locale.language }}</mat-label> 
                <input type="email" matInput placeholder="" formControlName="email" tabindex="1">
                <mat-error *ngIf="!messageForm.get('email')?.hasError('required') && messageForm.get('email')?.hasError('email')">{{ 'shared.doctorMessageDialog.text9' | translate:locale.language }}</mat-error>
                <mat-error *ngIf="messageForm.get('email')?.hasError('required') ">{{ 'shared.doctorMessageDialog.text10' | translate:locale.language }}</mat-error>
            </mat-form-field>          
    
            <mat-form-field appearance="outline" class="input-field">
                <mat-label>{{ 'shared.doctorMessageDialog.text3' | translate:locale.language }}</mat-label> 
                <input type="text" matInput placeholder="" formControlName="fullName" tabindex="2">
                <mat-error *ngIf="messageForm.get('fullName')?.hasError('required') ">{{ 'shared.doctorMessageDialog.text11' | translate:locale.language }}</mat-error>
            </mat-form-field>
            
            <mat-form-field appearance="outline" class="input-field">
                <mat-label>{{ 'shared.doctorMessageDialog.text4' | translate:locale.language }}</mat-label> 
                <textarea matInput placeholder="" formControlName="message" tabindex="3"></textarea>
                <mat-error *ngIf="messageForm.get('message')?.hasError('required') ">{{ 'shared.doctorMessageDialog.text12' | translate:locale.language }}</mat-error>
            </mat-form-field>

            <div class="captcha-container">
                <re-captcha (resolved)="resolved($event)" siteKey="{{captchaSiteKey}}"></re-captcha>
            </div>
        </form>        
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayout.lt-md="column-reverse" fxLayoutAlign="center center" fxLayoutGap="40px" fxLayoutGap.lt-md="20px">        
        <button mat-button mat-flat-button class="primary" [disabled]="!isSaveEnabled()" (click)="confirm()" [ngClass]="{'loading': loading}">            
            <span>{{ 'shared.doctorMessageDialog.text5' | translate:locale.language }}</span>
        </button>
    </div>  
    

</div>