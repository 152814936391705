import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { delay, map, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { MessageBusService } from 'src/app/core/services/message-bus.service';

@Component({
    selector: 'app-unlicensed-menu',
    templateUrl: './unlicensed-menu.component.html',
    styleUrls: ['./unlicensed-menu.component.css'],
    encapsulation: ViewEncapsulation.None
  })
  export class UnlicensedMenuComponent implements OnDestroy, OnInit {
    showAnonymousMenu: Observable<boolean>;
    private ngUnsubscribe = new Subject();
  
    constructor(      
      @Inject(L10N_LOCALE) public locale: L10nLocale,
      private messageBusService: MessageBusService
    ) {
        const onShowSignupLoginHeaderMenu = this.messageBusService.onShowSignupLoginHeaderMenu();
        this.showAnonymousMenu = this.messageBusService.onHideAnonymousMenu()
        .pipe(takeUntil(this.ngUnsubscribe), map(() => false), startWith(true), withLatestFrom(onShowSignupLoginHeaderMenu), map(x => x[0] && x[1]), delay(100));
    }

    ngOnInit(): void {
        
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onViewAppointmentsClick(): void {
        var cliniwebPhrLink = environment.cliniwebPhrUrl;
        
        window.open(cliniwebPhrLink + "/mis-citas", '_blank');        
    }

    onCliniwebForPatientsClick(): void {
        let cliniwebUrl = environment.cliniwebUrl + "##comoFuncionaPacientes";
        
        window.open(cliniwebUrl, '_blank');
    }

    goToPortal(): void {
        let portalUrl = environment.portalUrl;        

        window.open(portalUrl, '_blank');
    }
    
    goToPortalPublicProfilePage(): void {
        let portalUrl = environment.portalUrl + "perfiles";    
        
        window.open(portalUrl, '_blank');
    }

    onCliniwebForDoctorsClick(): void {
        let cliniwebUrl = environment.cliniwebUrl + "Doctor##comoFuncionaDoctores";

        window.open(cliniwebUrl, '_blank');
    }
}