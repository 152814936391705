import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { BaseService } from 'src/app/core/services/base.service';
import { ComponentDataSetMessage, ComponentFinishLoadingMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { CustomCurrencyPipe } from '../../pipes/custom-currency.pipe';
import { ComponentName } from '../../enums/component-name.enum';
import { PublicProfileAvatarComponentModel } from '../public-profile-avatar/public-profile-avatar.component';
import { PublicProfile } from '../../models/people/public-profile.model';
import { UtilsService } from 'src/app/core/services/utils.service';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-payment-amount',
    templateUrl: './payment-amount.component.html',
    styleUrls: ['./payment-amount.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [CustomCurrencyPipe]
  })
  @ComponentLookup(ComponentName.PAYMENT_AMOUNT)
  export class PaymentAmountComponent implements OnInit, OnDestroy, IControlComponent {
    
    data: FormComponentData;      
    model: PaymentAmountComponentDataModel = new PaymentAmountComponentDataModel();  
    state: any;
    invalidInput = false;
    avatarModel: PublicProfileAvatarComponentModel;
    @ViewChild('amountInputEl') amountInputEl: ElementRef;

    constructor(
        protected messageBusService: MessageBusService,
        @Inject(L10N_LOCALE) public locale: L10nLocale,
        protected baseService: BaseService,
        private currencyPipe : CustomCurrencyPipe,
        private utilsService: UtilsService,
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    ngOnInit(): void {
        this.loadDataModel();

        this.sendComponentFinishLoadingMessage();

        setTimeout(() => {
          if (this.amountInputEl && this.amountInputEl.nativeElement)
          this.amountInputEl.nativeElement.click();
          this.amountInputEl.nativeElement.focus();
        }, 100);        
    }
    
    ngOnDestroy(): void {
      
    }    

    sendComponentFinishLoadingMessage(){
      if (!this.data)
        return;

      let event = new ComponentFinishLoadingMessage();
      
      event.idFormulario = this.data.idFormulario;
      event.idControl = this.data.idControlPadre;
  
      this.messageBusService.componentFinishLoading(event);   
    }
    
    private loadDataModel() {
      if (this.data && this.data.configurationData) {
        this.model = this.data.configurationData as PaymentAmountComponentDataModel;  
        
        if (this.model.publicProfile) {
          this.avatarModel = new PublicProfileAvatarComponentModel();
          this.avatarModel.personId = this.model.publicProfile.id;
          this.avatarModel.avatarUrl = this.model.publicProfile.ubicacionFoto;
          this.avatarModel.fullName = this.model.publicProfile.nombrePersona;
          this.avatarModel.sex = this.model.publicProfile.sexo;
          this.avatarModel.profileType = this.model.publicProfile.tipoPerfil;
        }
      }     
    }
    
    /*********************** Payment amount input methods **********************************/

    onAmountInputClick(event: any) {
      // Move cursor if the user already type something
      if (event.target.value && event.target.value != "0.00")
        event.target.selectionStart = event.target.selectionEnd = event.target.value.length;
      else 
        event.target.selectionStart = event.target.selectionEnd = 0;

      // Scroll top
      setTimeout(() => {
        let container = document.body.classList.contains('route-dialog-opened') 
          ? this.document.getElementsByClassName("route-dialog-content")[0]
          : this.document.getElementsByClassName("body-container")[0];

        let el = this.document.getElementsByClassName("payment-input")[0] as Element;        

        this.utilsService.scrollToElementWithAnimation(container, el);
      }, 100);
    }

    onAmountInputKeyup(element: any) {
      //console.log(element);
      //console.log("Old value: " + element.target.value);      

      element.preventDefault();
      element.stopPropagation();

      // Enter
      if (element.keyCode == 13)
        return;

      if (!element.target.value)
        element.target.value = "0.00";
      
      let currentValue = element.target.value + element.key;
      let newChar = currentValue.substring(currentValue.length - 1);
      let amount: string | null;  

      // If the new character is a number or backspace
      if (element.keyCode == 8 || /\d/.test(newChar)) {
        // Replace all commas
        currentValue = currentValue.replace(",", "");
      
        // We need to insert the number at the end and move the comma one place
        let number = parseFloat(currentValue);

        // In some cases if you type fast we can get this event with 2 or 3 inserted digits
        // In those cases we want to move the comma by the number of decimal less 2
        // For example 0.0023 will move decimal 2 positions to get 0.23
        let multiplier = Math.pow(10, this.countDecimals(number) - 2);

        // In case the user enter '0' and 10 ^ 0 = 1
        if (multiplier < 10)
          multiplier = 10;

        // If the new character is a backspace move the comma to the left
        if (element.keyCode == 8)
          number = (number / 10);
        else
          number = (number * multiplier);

        // Truncate number to 2 digits
        number = Math.round(number * 100) / 100;

        amount = this.currencyPipe.transform(number.toFixed(2), 'USD','','1.2-2','en-US');
      }
      else {
        amount = this.currencyPipe.transform(element.target.value, 'USD','','1.2-2','en-US');
      }      

      if (!amount)
        amount = "0.00";

      this.model.paymentAmount = amount;

      // Set value to input and move cursor
      element.target.value = amount;
      element.target.selectionStart = element.target.selectionEnd = amount.length;

      //console.log("New value: " + amount);
    }
    
    validateInputNumber(): boolean {
      let amount = parseFloat(this.model.paymentAmount.replace(/,/g, ""));

      return (amount >= 1 && amount <= 10000);
    }

    onNextClick() {
      if (!this.validateInputNumber()) {
        this.invalidInput = true;
        return;
      }
      else 
        this.invalidInput = false;
      
      let payAmount = parseFloat(this.model.paymentAmount);

      if (payAmount) {
        let message = new ComponentDataSetMessage();
        message.componentName = ComponentName.PAYMENT_AMOUNT;
        message.data = this.model.paymentAmount;

        this.messageBusService.onComponentDataSetMessage(message);     
      }
    }    

    private countDecimals = function (number: number) {
      if(Math.floor(number) === number) return 0;
      return number.toString().split(".")[1].length || 0; 
    }    
  }

  export class PaymentAmountComponentDataModel {
    targetUserName: string = "";
    targetUserAvatarUrl: string = "";

    userName: string = "";
    specialty: string = "";
    paymentAmount: string = "0.00";
    publicProfile: PublicProfile;
  }
