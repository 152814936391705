import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppStorageService, STORAGE } from 'src/app/core/services/app-storage.service';
import { CloseRouteDialogMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { RouteDialogType } from 'src/app/shared/components/dialogs/route-dialog/route-dialog.component';

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginHeaderComponent implements OnDestroy, OnInit {

  @Input() closeOnBack: boolean;
  @Input() returnUrl: string;
  private isModalOpened = false;
  private showAll = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private appStorageService: AppStorageService, private messageBusService: MessageBusService, 
    private routeDialogService: RouteDialogService, private router: Router
  ) { }

  ngOnDestroy(): void {
    if (!this.isModalOpened && this.showAll) {
      this.messageBusService.showHeader();
    }
  }

  ngOnInit(): void {
    this.isModalOpened = this.document.body.classList.contains('route-dialog-opened');
    this.showAll = this.appStorageService.getItemSession(STORAGE.SIGNUP_WORKFLOW_VERIFICATION_TYPE) !== null;
    if (!this.isModalOpened && this.showAll) {
      this.messageBusService.hideHeader();
    }
    if (!this.showAll) this.messageBusService.hideAnonymousMenu();
  }

  onBackClick() {
    const isDialog = this.closeOnBack || (this.routeDialogService.isOpen(RouteDialogType.LOGIN) && this.isModalOpened);
    if(isDialog) {
      this.messageBusService.closeRouteDialog(new CloseRouteDialogMessage());
    } else {
      this.router.navigate([this.returnUrl || '/']);
    }    
  }

}
