import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { ToastrService } from 'ngx-toastr';
import { BaseService } from 'src/app/core/services/base.service';
import { DoctorService } from 'src/app/core/services/doctors.service';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { DoctorMessage } from 'src/app/shared/models/people/doctor-message.model';
import { PublicProfile } from 'src/app/shared/models/people/public-profile.model';
import { CustomEmailValidator } from 'src/app/shared/validators/custom-email.validator';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'doctor-message-dialog',
  templateUrl: './doctor-message-dialog.component.html',
  styleUrls: ['./doctor-message-dialog.component.css'],
  encapsulation: ViewEncapsulation.None  
})
export class DoctorMessageDialogComponent implements OnInit {    

    loading: boolean = false;
    publicProfile: PublicProfile;   
    messageForm: FormGroup;
    captchaSuccess: boolean = false;
    captchaSiteKey: string;    

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
      private mdDialogRef: MatDialogRef<DoctorMessageDialogComponent>,
      private formBuilder: FormBuilder,
      private baseService: BaseService,
      private doctorService: DoctorService,
      private toastr: ToastrService,
      private translation: L10nTranslationService, 
      @Inject(L10N_LOCALE) public locale: L10nLocale) {
    
        this.publicProfile = this.baseService.parseObject<PublicProfile>(data.data);

        this.captchaSiteKey = environment.googleCaptchaSiteKey;
  }

  ngOnInit(): void {
    this.messageForm = this.formBuilder.group({
        email: ['', [Validators.required, CustomEmailValidator]],
        fullName: ['', Validators.required],
        message: ['', Validators.required],
      });      
  }

  get f() { return this.messageForm.controls; }

  onCloseClick() {
    this.mdDialogRef.close();
  } 

  isSaveEnabled() {
    return this.captchaSuccess && !this.messageForm.invalid;
  }  

  resolved(captchaResponse: any) {
    this.captchaSuccess = true;
  }

  confirm() {
    if (!this.captchaSuccess)
      return;

    // stop here if form is invalid
    if (this.messageForm.invalid) {
        this.messageForm.markAllAsTouched();
        return;
    }

    let model = new DoctorMessage();

    model.IdEmpresa = this.publicProfile.idEmpresa;
    model.IdPersona = this.publicProfile.id;
    model.NombreDoctor = this.publicProfile.nombrePersona;

    model.Localizacion = this.locale.language;
    model.Email = this.messageForm.get("email")?.value;
    model.NombreCompleto = this.messageForm.get("fullName")?.value;
    model.Mensaje = this.messageForm.get("message")?.value;    

    this.loading = true;

    this.doctorService.postMessageToDoctor(model).subscribe(r => {      
      this.loading = false;
      this.mdDialogRef.close();

      this.toastr.success(this.translation.translate("shared.doctorMessageDialog.text6"), "");
    },
    (error: any) => {
      this.loading = false;

      this.toastr.error(this.translation.translate("shared.doctorMessageDialog.text8"), this.translation.translate("shared.doctorMessageDialog.text7"));
    });
  }
  
}

