import { Injectable } from '@angular/core';
import { Location } from "@angular/common";
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private history: string[] = [];

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    }
    else {
      this.router.navigateByUrl("/");
    }
  }

  getNavigator(): Navigator {
    return window.navigator;
  }

  canShare(data: { files: File[] }): boolean {
    const navigator = this.getNavigator();
    return !!navigator.canShare ? navigator.canShare(data) : false;
  }

  share(data: ShareData): Promise<void> {
    const nav = this.getNavigator();
    if (nav.share) {
      return nav.share(data);
    } else {
      return Promise.reject('Share API no soportada en este navegador.');
    }
  }

  isNavigatorAvailable(): boolean {
    return !!window.navigator && !!window.navigator.share;
  }
}

interface ShareData {
  title?: string;
  text?: string;
  url?: string;
  files?: File[];
}

interface Navigator {
  canShare?: (data?: ShareData) => boolean;
  share?: (data: ShareData) => Promise<void>;
}