import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';
import { LayoutService } from 'src/app/core/services/layout.service';
import { DialogData } from 'src/app/shared/models/dialog-data.model';

@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MenuMobileComponent implements OnInit, OnDestroy {
  public menuComponentName: string = "";

  //subscribes
  private ngUnsubscribe = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private mdDialogRef: MatDialogRef<MenuMobileComponent>,
    private layoutService: LayoutService,
    private baseService: BaseService
  ) { 
    this.layoutService.postNavigateSource$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data=>{
      this.close();
    });
  }

  ngOnInit(): void {
    this.menuComponentName = this.baseService.getMenuComponentName();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public close() {
    this.mdDialogRef.close();
  }
}
