import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ClinicConcept } from 'src/app/shared/models/cdr/clinic-concept.model';
import { BaseService } from './base.service';
import { HttpBaseService } from './http-base-service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class ClinicalConceptService {

  constructor(
    private http: HttpBaseService,
    private baseService: BaseService,
    private logService: LogService
  ) { }

  getSymptoms(text: string): Observable<ClinicConcept[]>{

    this.logService.logVerbose("getSymptoms: " + text);

    if(!text) return of([]);

    let language = this.baseService.getLanguage();

    return this.http.get<ClinicConcept[]>("api/conceptos-clinicos/sintomas?localizacion=" + language + "&textoBusqueda=" + encodeURIComponent(text));
  }

  getFrequentSymptoms():Observable<ClinicConcept[]>{
    let language = this.baseService.getLanguage();

    return this.http.get<ClinicConcept[]>("api/conceptos-clinicos/sintomas/frecuentes?localizacion=" + language);
  }

  getInsuraceConcepts():Observable<ClinicConcept[]>{
    let language = this.baseService.getLanguage();

    return this.http.get<ClinicConcept[]>("api/conceptos-clinicos/aseguradoras?localizacion=" + language, null, false);
  }
}
