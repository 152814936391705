import { NgModule } from '@angular/core';
import { LoginComponent } from './pages/login/login.component';
import { CoreModule } from 'src/app/core/core.module';
import { CommonModule } from '@angular/common';
import { LoginRoutingModule } from './login-routing.module';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { L10nTranslationModule } from 'angular-l10n';
import { AuthCallbackComponent } from './pages/auth-callback/auth-callback.component';
import { AuthCallbackPopupComponent } from './pages/auth-callback-popup/auth-callback-popup.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { LoginHeaderComponent } from './components/login-header/login-header.component';
import { LoginFormManagerComponent } from './components/login-form-manager/login-form-manager.component';
import { LoginFormGoogleComponent } from './components/login-form-google/login-form-google.component';
import { LoginFormCliniwebComponent } from './components/login-form-cliniweb/login-form-cliniweb.component';


@NgModule({
  declarations: [
    LoginComponent, 
    LoginFormComponent, 
    AuthCallbackComponent,
    AuthCallbackPopupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LoginHeaderComponent,
    LoginFormManagerComponent,
    LoginFormGoogleComponent,
    LoginFormCliniwebComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    LoginRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    L10nTranslationModule    
  ]
})
export class LoginModule { }
