export class FormControl {
    id: number;
    titulo: string;
    numeroControlesPorFila?: number;
    componentes: FormControlComponent[];
    valor: string;
    className: string;

    constructor();
    constructor(obj:FormControl);
    constructor(obj?:any){
        this.id = obj && obj.id || 0;
        this.titulo = obj && obj.titulo || '';
        this.numeroControlesPorFila = obj && obj.numeroControlesPorFila || null;
        this.componentes = obj && obj.componentes 
            ?  obj.componentes.map((componente:FormControlComponent)=> {
                componente.idControl = this.id;
                return new FormControlComponent(componente);
            }) 
            : [];
        this.valor = obj && obj.valor || '';
    }

    isVisible(){
        return this.componentes.find(c=> c.isVisible()) != null;
    }
}
export class FormControlComponent{
    tipo: FormComponentType;
    nombre: string;
    valor: any;
    contenedor: string;
    idControl: number;
    visible: boolean;

    constructor();
    constructor(obj:FormControlComponent);
    constructor(obj?:any){
        this.tipo = obj && obj.tipo || FormComponentType.CARD;
        this.nombre = obj && obj.nombre || '';
        this.valor = obj && obj.valor || null;
        this.contenedor = obj && obj.contenedor || '';
        this.idControl = obj && obj.idControl || 0;
        this.visible = obj && obj.visible || true;
    }

    isVisible(){
        return this.visible;
    }
}

export class FormComponentData {
    nombreComponente: string;
    contenedor: string;
    valor: any;
    configurationData: any;
    idControlPadre: number;
    idFormulario: number;
    nombreFormulario: string;
}

export enum FormComponentType{
    CARD = 0,
    LINK = 1
}