export enum ComponentName {
    PAYMENT_AMOUNT = "PaymentAmountComponent",
    PAYMENT_FORM = "PaymentComponent",
    AUTHENTICATION = "AuthenticationComponent",
    SELECT_PATIENT = "SelectPatientAppointmentCheckoutComponent",
    SELECT_PATIENT_RELATIONSHIP_APPOINTMENT = "SelectPatientRelationshipAppointmentCheckoutComponent",
    PATIENT_FORM_APPOINTMENT = "PatientFormAppointmentCheckoutComponent",
    PATIENT_CONTACT_FORM = "PatientContactFormAppointmentCheckoutComponent",
    PATIENT_VERIFICATION = "PatientVerificationComponent",
    SUMMARY_APPOINTMENT = "SummaryAppointmentCheckoutComponent",
    SUMMARY_MEDICAL_ORDER = "SummaryMedicalOrderComponent",
    SELECT_COUNTRY = "CountryFormCheckoutComponent",
    SELECT_PATIENT_RELATIONSHIP = "SelectPatientRelationshipComponent",
    CREATE_PATIENT_RELATIONSHIP = "CreatePatientRelationshipComponent"
}