import { Component, Inject, OnInit } from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';

@Component({
  selector: 'privacy-policies-sura',
  templateUrl: './privacy-policies-sura.component.html',
  styleUrls: ['./privacy-policies-sura.component.css']
})
@ComponentLookup('PrivacyPoliciesSuraComponent')
export class PrivacyPoliciesSuraComponent implements OnInit, IControlComponent {  
  data: FormComponentData;
  
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {     
  }
  
  ngOnInit(): void {
    
  }

}
