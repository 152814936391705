import { Component, OnInit } from '@angular/core';
import { AppStorageService } from 'src/app/core/services/app-storage.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'empty-home',
  templateUrl: './empty-home.component.html',
  styles: ['']  
})

// Dummy class just to run the login if the user is not logged in
// This is used from Cliniweb before creating an appointment for SURA
export class EmptyHomeComponent implements OnInit {  

  constructor(
    private userService: UserService,
    private appStorageService: AppStorageService,
    private authService : AuthService
  ) {}

  ngOnInit(): void {
    let token = this.authService.getAuthenticationToken().subscribe(token => {
      this.userService.getUserPersonLoggedIn().subscribe(user => {      
        if(user) {
              let model = { 
                  userId: user.id, 
                  token: token?.access_token 
              };
          
              // Post message indicating a successfull login. Other events like "before login" and "login error" will be fired on "auth-callback-component"
              //this.iframeMessageService.postMessage("user_id", model);           
  
              window.parent.postMessage({ eventName: 'user_id', model: { userId: user.id, compId: user.idEmpresa, tel: user.numeroTelCelular, token: token?.access_token } }, '*');
          }
        });    
    });   
  }  
}
