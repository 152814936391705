import { IControlComponent } from "../interfaces/control-component";

export type ControlComponentClass = { new (): IControlComponent };

export const ComponentLookupRegistry: Map<string, ControlComponentClass> = new Map();

export const ComponentLookup = (key: string): any => {
    return (cls: ControlComponentClass) => {
        ComponentLookupRegistry.set(key, cls);
    };
};