import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'ageFromBirthday'
})
export class AgeFromBirthdayPipe implements PipeTransform {

  transform(value?: string | Date): string {
    if (!value) return `0 años, 0 meses y 0 días`;
    const ahora = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0});
    const nacimiento = moment(value).set({ hour: 0, minute: 0, second: 0, millisecond: 0});
    const edad = moment.duration(ahora.diff(nacimiento));
    return `${edad.years()} años, ${edad.months()} meses y ${edad.days() + 1} días`;
  }

}
