import { Component, HostListener, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { FileViewerDto } from '../../file-viewer/file-viewer.component';

@Component({
  selector: 'file-viewer-dialog',
  templateUrl: './file-viewer-dialog.component.html',
  styleUrls: ['./file-viewer-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FileViewerDialogComponent implements OnInit {
    viewerData : FileViewerDto;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private mdDialogRef: MatDialogRef<FileViewerDialogComponent>
  ) {
    this.viewerData = data.data;
  }

  ngOnInit(): void {

  } 

  public close() {
    this.mdDialogRef.close();
  }
  
  @HostListener("keydown.esc") 
  public onEsc() {
    this.close();
  }
}