import { AppointmentInfoDataModel } from "./appointment-info-data.model";

export class PatientContactFormDataModel{
    appointmentInfo: AppointmentInfoDataModel;
    patientRelationship: number;
    userPhone: string;
    patientName: string;
    patientPhone: string;
    patientEmail: string;
    contactType: number = 0;
}