<div class="growth-charts-container" *ngIf="!loading">

    <div class="breadcrumbs">
        <a [routerLink]="['/mi-salud']" [queryParams]="{ p: patientId }">
            <mat-icon matListIcon fontSet="phr-iconset" class="phr-iconset-PHR-Volver"></mat-icon>
        </a>
        <span>{{ 'shared.growthCharts.breadcrumbs2' | translate:locale.language }}</span>
    </div>
    <div class="card">
        <div class="avatar-container">
            <app-avatar-upload
                [fileUrl]="model.patientAvatar"
                [id]="model.patientId"
                [options]="avatarUploadOptions"
                (onFileUploaded)="onAvatarUploaded($event)"
            ></app-avatar-upload>
        </div>
        <span class="patient-name">{{model.patientFirstName + ' ' + model.patientLastName}}</span>
        <span class="patient-age">{{ model.patientBirthDate | ageFromBirthday }}</span>
        <div class="doctor-container" 
            *ngIf="showDoctorSelect && model.growthMeasurements && model.growthMeasurements.responsablesAsociados.length > 1">
            <mat-form-field appearance="outline">
                <mat-select [(value)]="selectedDoctorId" (selectionChange)="updateDoctor($event)">
                    <mat-option *ngFor="let doctor of model.growthMeasurements.responsablesAsociados" [value]="doctor.id">
                        {{ doctor.nombre }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <mat-tab-group (selectedIndexChange)="onTabChange($event)" class="skeleton-item">
            <mat-tab *ngFor="let tab of model.growthTabsModel; let i = index" 
                label="{{ 'shared.growthCharts.labeltab_' + tab.id | translate:locale.language }}">
                <ng-container *ngIf="initializedTabs[i]">
                    <app-growth-charts-tab
                        [loading]="tabLoading"
                        [type]="tab.id"
                        [ranges]="tab.rangos"
                        [gender]="model.patientGender" 
                        [measurements]="model.growthMeasurements">
                    </app-growth-charts-tab>
                </ng-container>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>