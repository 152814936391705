import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { BaseService } from 'src/app/core/services/base.service';
import { SearchProvidersService } from 'src/app/core/services/search-providers.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';

@Component({
  selector: 'app-cliniweb-book-appointment-linkbox',
  templateUrl: './cliniweb-book-appointment-linkbox.component.html',
  styleUrls: ['./cliniweb-book-appointment-linkbox.component.css']
})
@ComponentLookup('CliniwebBookAppointmentLinkboxComponent')
export class CliniwebBookAppointmentLinkboxComponent implements OnInit, IControlComponent {
  data: FormComponentData;

  constructor(
    private router: Router,
    private baseService: BaseService,
    private searchProvidersService: SearchProvidersService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) { }

  ngOnInit(): void {

  }
  
  onLinkClick(){
    this.searchProvidersService.openSearch();
  }
}