import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseService } from 'src/app/core/services/base.service';

@Component({
  selector: 'app-login-form-manager',
  templateUrl: './login-form-manager.component.html',
  styleUrls: ['./login-form-manager.component.css']
})
export class LoginFormManagerComponent implements OnInit {

  loginTypeEnum = LoginFormType;
  provider:LoginFormType = LoginFormType.DEFAULT;
  username: string;
  workflowType:number;

  constructor(
    private router: Router,
    private baseService: BaseService
  ) {

    let state = this.getState();

    if(!state)
      return;

    this.username = state.email;
    this.workflowType = state.workflowType;

    let providers = this.baseService.getAuthenticationProviders();

    if (!state.idAuthProvider){
      this.provider = LoginFormType.CLINIWEB;
      return;
    }

    let idAuthProvider = state.idAuthProvider;
    let providerSelected = providers.find(p => p.proveedorServicioAutenticacion == idAuthProvider);

    if(!providerSelected)
      return;

    switch(+providerSelected.proveedorServicioAutenticacion){
      case LoginFormType.CLINIWEB:
        this.provider = LoginFormType.CLINIWEB;
        break;
      case LoginFormType.GOOGLE:
        this.provider = LoginFormType.GOOGLE;
        break;
      default:
        this.provider = LoginFormType.DEFAULT;
        break;
    }

  }

  ngOnInit(): void {
  }

  private getState(): any | undefined {
    let nav = this.router.getCurrentNavigation();

    if(!nav)
      return;

    if(!nav.extras)
      return;

    return nav.extras.state;
  }

}

export enum LoginFormType{
  DEFAULT = 1,
  GOOGLE = 14,
  CLINIWEB = 33
}
