import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { BaseService } from 'src/app/core/services/base.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';

@Component({
  selector: 'app-results-linkbox',
  templateUrl: './results-linkbox.component.html',
  styleUrls: ['./results-linkbox.component.css']
})
@ComponentLookup('ResultsLinkboxComponent')
export class ResultsLinkboxComponent implements OnInit, IControlComponent {
  data: FormComponentData;

  constructor(
    private router: Router,
    private baseService: BaseService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) { }

  ngOnInit(): void {
  }

  navigate(){
    let path = this.baseService.getRouteLinkByArea(3460);
    this.router.navigate(['/' + path]);
  }
}
