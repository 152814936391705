import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppStorageService, STORAGE } from 'src/app/core/services/app-storage.service';
import { AuthOidcService } from 'src/app/core/services/auth-oidc.service';

@Component({
  selector: 'app-auth-callback-popup',
  templateUrl: './auth-callback-popup.component.html',
  styleUrls: ['./auth-callback-popup.component.css']
})
export class AuthCallbackPopupComponent implements OnInit, OnDestroy  {
  private ngUnsubscribe = new Subject();

  constructor(
    private authOidcService: AuthOidcService,
    private appStorageService: AppStorageService
  ) { }

  ngOnInit(): void {

    this.authOidcService.completeAuthenticationPopup()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(token => {
      if(token.isInvalid) {
        //TODO agregar en la vista informacion de que el usuario no existe
      }
      else {
        this.appStorageService.setToken(token);

        this.appStorageService.removeSessionItem(STORAGE.USER);
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
