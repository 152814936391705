import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';

@Component({
  selector: 'app-most-common-specialties',
  templateUrl: './most-common-specialties.component.html',
  styleUrls: ['./most-common-specialties.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('MostCommonSpecialtiesComponent')
export class MostCommonSpecialtiesComponent implements OnInit, IControlComponent {
  data: FormComponentData;

  constructor(
    private router: Router,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) { }

  ngOnInit(): void {

  }
  
  onDentistClick() {
    this.router.navigate(["/doctores/especialidad/odontologia"], {queryParams: {idC: 47, idTc: 5 }} );
  }

  onNutritionClick() {
    this.router.navigate(["/doctores/especialidad/nutricion"], {queryParams: {idC: 46, idTc: 5 }} );
  }

  onGynecologyClick() {
    this.router.navigate(["/doctores/especialidad/ginecologia"], {queryParams: {idC: 28, idTc: 5 }} );
  }

  onPediatricsClick() {    
    this.router.navigate(["/doctores/especialidad/pediatria"], {queryParams: {idC: 58, idTc: 5 }} );
  }
}