import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginRouteDialogModel } from '../../dialogs/route-dialog/route-dialog.component';
import { AppStorageService, STORAGE } from 'src/app/core/services/app-storage.service';
import { SignupWorkflowVerificationType } from 'src/app/shared/enums/signup-workflow-verification.enum';
import { Router } from '@angular/router';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

@Component({
  selector: 'app-anonymous-login-signup-menu',
  templateUrl: './anonymous-login-signup-menu.component.html',
  styleUrls: ['./anonymous-login-signup-menu.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AnonymousLoginSignupMenuComponent {

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private appStorageService: AppStorageService,
    private routeDialogService: RouteDialogService,
    private router: Router
  ) { }

  onCliniwebForDoctorsClick(): void {
    let cliniwebUrl = environment.cliniwebUrl + "Doctor##comoFuncionaDoctores";
    window.location.href = cliniwebUrl;
  }

  onCliniwebForPatientsClick(): void {
    let cliniwebUrl = environment.cliniwebUrl + "##comoFuncionaPacientes";
    window.location.href = cliniwebUrl;    
  }

  onLoginClick(): void {
    let path = encodeURIComponent(window.location.pathname + window.location.search);

    this.appStorageService.setItemSession(STORAGE.SIGNUP_WORKFLOW_VERIFICATION_TYPE, SignupWorkflowVerificationType.SIMPLE);
    this.router.navigate(['welcome', 'login'], { queryParams: { returnUrl: path } });
    
    /*let routeDialogModel = new LoginRouteDialogModel();
    routeDialogModel.route = "login";    
    routeDialogModel.queryParams = { returnUrl: path, closeOnBack: true }
    this.routeDialogService.openDialog(routeDialogModel);*/
  }

  /**
   * Redirige al welcome según MCWNG-6496
   */
  onSignupClick(): void {
    let path = encodeURIComponent(window.location.pathname + window.location.search);

	  this.appStorageService.setItemSession(STORAGE.SIGNUP_WORKFLOW_VERIFICATION_TYPE, SignupWorkflowVerificationType.SIMPLE);
    this.router.navigate(['welcome', 'signup'], { queryParams: { returnUrl: path } });
  }

  goToPortal(): void {
    let portalUrl = environment.portalUrl;
    window.location.href = portalUrl;
  }

  goToPortalPublicProfilePage(): void {
    let portalUrl = environment.portalUrl + "perfiles";    
    window.location.href = portalUrl;    
  }

  onViewAppointmentsClick(): void {
    this.router.navigate(['/mis-citas']);
  }

}
