<div class="online-payment-container">
    <app-workflow-progress-bar [model]="progressBarModel"></app-workflow-progress-bar>
    
    <div class="workflow-content" *ngIf="showWorkflowContent()">
        <app-form [idAreaSistema]="getIdAreaSistema()" [formConfigurationData]="formConfigurationData"></app-form>
    </div>
</div>
<div class="states-container" *ngIf="showState">
    <app-states-request-verify [model]="statesRequestVerifyDataModel"></app-states-request-verify>
</div>

<app-spinner *ngIf="activatingAccount" spinnerText="shared.onlineAppointmentCliniweb.spinnerText"></app-spinner>

<app-spinner *ngIf="checkingStep"></app-spinner>