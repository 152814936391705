<div class="avatar-upload-container">
    
    <input type="file" class="file-input"
       [accept]="options.requiredFileType"
       (change)="onFileSelected($event)" #fileUpload>
    
    <div class="file-container">
        <div class="avatar-container">
            
            <!-- No file uploaded yet -->
            <img *ngIf="!uploading && fileUrl" [src]="fileUrl">           
            
            <!-- No file uploaded yet -->
            <a *ngIf="!uploading && !fileUrl" (click)="fileUpload.click()">{{options.selectFileLabel}}</a>

            <!-- Uploading -->
            <div class="uploading" *ngIf="uploading">
                <mat-spinner class="uploading-spinner" color="on-primary"></mat-spinner>
            </div>
        </div>
        <a *ngIf="!uploading && fileUrl" [matMenuTriggerFor]="menu">
            <span>{{options.changeFileLabel}}</span> <mat-icon matDatepickerToggleIcon fontSet="phr-iconset" class="phr-iconset-arrow-down"></mat-icon>
        </a>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="fileUpload.click()" class="avatar-upload-container-mat-menu-item-custom">
                <mat-icon matDatepickerToggleIcon fontSet="phr-iconset" class="phr-iconset-loop"></mat-icon> <span>{{options.changePictureLabel}}</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="deletePicture()" class="avatar-upload-container-mat-menu-item-custom">
                <mat-icon matDatepickerToggleIcon fontSet="phr-iconset" class="phr-iconset-trash"></mat-icon> <span>{{options.deleteFileLabel}}</span>
            </button>
        </mat-menu>
    </div>

</div>