import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { ClinicHistory } from 'src/app/shared/models/clinic-history/clinic-history.model';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { ShareDialogComponent } from '../share-dialog/share-dialog.component';

@Component({
  selector: 'app-clinic-history-share',
  templateUrl: './clinic-history-share.component.html',
  styleUrls: ['./clinic-history-share.component.css']
})
@ComponentLookup('ClinicHistoryShareComponent')
export class ClinicHistoryShareComponent implements OnInit, IControlComponent {
  data: FormComponentData;
  clinicHistory: ClinicHistory;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private messageBusService: MessageBusService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.messageBusService.onSendClinicHistory().subscribe(message =>{
      this.clinicHistory = message.clinicHistory;
    });
  }

  public onClickShareButton(){
    let dialogData = new DialogData();

    dialogData.message = this.data.valor;
    dialogData.data = this.clinicHistory;

    const dialogRef = this.dialog.open(ShareDialogComponent, { data: dialogData, panelClass: "common-dialog-container", width: '680px', height: '600px' });    
  }
}
