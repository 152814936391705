<div class="search-doctors-container" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="box-container">
        <form [formGroup]="searchDoctorsForm">           

            <div class="left-box">
                <!-- Search Box -->
                <mat-form-field class="input-field" floatLabel="never">                
                    <input matInput placeholder="{{ getPlaceholderText() }}" 
                        formControlName="searchText" name="searchText" autocomplete="off" (ngModelChange)="onSearch($event)" (click)="onSearchInputClick()">
                    <span class="search-box-autocomplete-caret">                        
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-close" *ngIf="searchText.value.length > 0" (click)="clearSelectedName()"></mat-icon>        
                    </span>
                </mat-form-field>
            </div>
            
            <span class="divider"></span>
            
            <div class="right-box">
                <!-- City Autocomplete -->
                <mat-form-field class="input-field" floatLabel="never">                
                    <input #cityTrigger="matAutocompleteTrigger" type="text" placeholder="{{ 'shared.searchDoctors.text2' | translate:locale.language }}" 
                        matInput [matAutocomplete]="auto" [formControl]="cityControl" formControlName="city" >
                    <mat-autocomplete #auto="matAutocomplete" 
                        (optionSelected)="onOptionSelected($event)" 
                        [displayWith]="autoCompleteDisplayWith" 
                        (opened)="onAutocompleteOpened()"
                        (closed)="onAutocompleteClosed()">
                        
                        <mat-optgroup *ngFor="let group of filteredLocationsGroups" [label]="group.displayName">                            
                            <mat-option *ngFor="let option of group.values" [value]="option" [ngClass]="{'selected-item': selectedCity && selectedCity.id == option.id}">
                                <span style="text-transform: capitalize;">{{option.nombre}}</span>
                            </mat-option>
                        </mat-optgroup>                       
                       
                    </mat-autocomplete>
                    <span class="city-autocomplete-caret">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-arrow-drop-down" *ngIf="!selectedCity"></mat-icon>
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-close" *ngIf="selectedCity" (click)="clearSelectedLocation(cityTrigger)"></mat-icon>        
                    </span>
                </mat-form-field>                
            </div>

            <button mat-flat-button class="site-button" (click)="onSearchClick()">
                <mat-icon>search</mat-icon>
                <span>{{ 'shared.searchDoctors.text3' | translate:locale.language }}</span>
            </button>

        </form>        
    </div>

    <!-- Search results -->
    <div class="search-doctor-search-results" [hidden]="!resultWrapper || resultWrapper.isEmpty() || resultWrapper.isHidden()">
        <div class="search-result-area-container site-custom-scroll">
            
            <!-- Clinical Concepts -->
            <div class="search-result-area" *ngFor="let wrapper of resultWrapper.getClassItems(); index as i; trackBy: wrappersTrackBy">
                <div class="title">{{wrapper.className}}</div>
                <div class="results-container" 
                    *ngFor="let result of wrapper.results; index as i; trackBy: resultsTrackBy"
                    (click)="setSelectedClinicalConcept(result, cityTrigger); $event.stopPropagation()" >
                    <span class="result-name" innerHTML="{{result.nombre | highlight : searchValue : 'span'}}">                       
                    </span>                
                </div>
            </div>

            <!-- Directory Doctors -->
            <div class="search-result-area" *ngFor="let wrapper of resultWrapper.getDirectoryItems(); index as i; trackBy: wrappersTrackBy">
                <div class="title">{{ 'shared.searchDoctors.text4' | translate:locale.language }}</div>
                <!-- <div class="subtitle">{{ 'shared.searchDoctors.text5' | translate:locale.language }}</div> -->            

                <table mat-table [dataSource]="wrapper.results" class="desktop-solution">                    
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef class="column-header"> {{ 'name' | translate:locale.language }} </th>
                      <td mat-cell *matCellDef="let result" class="row-cell name" innerHTML="{{result.nombre | highlight : searchValue : 'span'}}"></td>
                    </ng-container>                    
                    <ng-container matColumnDef="specialty">
                      <th mat-header-cell *matHeaderCellDef class="column-header"> {{ 'specialty' | translate:locale.language }} </th>
                      <td mat-cell *matCellDef="let result" class="row-cell specialty"> {{result.descripcion}} </td>
                    </ng-container>                    
                    <ng-container matColumnDef="city">
                      <th mat-header-cell *matHeaderCellDef class="column-header"> {{ 'city' | translate:locale.language }} </th>
                      <td mat-cell *matCellDef="let result" class="row-cell"> {{result.ciudades}} </td>
                    </ng-container>                  
                  
                    <tr mat-header-row *matHeaderRowDef="['name', 'specialty', 'city']"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['name', 'specialty', 'city'];" class="past-app-row" (click)="onDoctorClick(row)"></tr>        
                </table>
                <div class="mobile-solution">
                    <div class="mobile-result-item" *ngFor="let result of wrapper.results; index as i">
                        <div class="name" innerHTML="{{result.nombre | highlight : searchValue : 'span'}}"></div>
                        <div class="specialty">{{result.descripcion}}</div>
                        <div class="city">{{result.ciudades}}</div>
                    </div>
                </div>                   
            </div>

            <!-- Other Doctors -->
            <div class="search-result-area" *ngFor="let wrapper of resultWrapper.getDoctorItems(); index as i; trackBy: wrappersTrackBy">
                <div class="title">{{ 'shared.searchDoctors.text6' | translate:locale.language }}</div>
                <!-- <div class="subtitle" *ngIf="areDoctorsResults()">{{ 'shared.searchDoctors.text7' | translate:locale.language }}</div> -->               

                <table mat-table [dataSource]="wrapper.results" class="desktop-solution">                    
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef class="column-header"> {{ 'name' | translate:locale.language }} </th>
                      <td mat-cell *matCellDef="let result" class="row-cell name" innerHTML="{{result.nombre | highlight : searchValue : 'span'}}"></td>
                    </ng-container>                    
                    <ng-container matColumnDef="specialty" *ngIf="areDoctorsResults()">
                      <th mat-header-cell *matHeaderCellDef class="column-header"> {{ 'specialty' | translate:locale.language }} </th>
                      <td mat-cell *matCellDef="let result" class="row-cell specialty"> {{result.descripcion}} </td>
                    </ng-container>                    
                    <ng-container matColumnDef="city">
                      <th mat-header-cell *matHeaderCellDef class="column-header"> {{ 'city' | translate:locale.language }} </th>
                      <td mat-cell *matCellDef="let result" class="row-cell"> {{result.ciudades}} </td>
                    </ng-container>                  
                  
                    <tr mat-header-row *matHeaderRowDef="getDisplayColumns()"></tr>
                    <tr mat-row *matRowDef="let row; columns: getDisplayColumns();" class="past-app-row" (click)="onDoctorClick(row)"></tr>        
                </table>
                <div class="mobile-solution">
                    <div class="mobile-result-item" *ngFor="let result of wrapper.results; index as i">
                        <div class="name" innerHTML="{{result.nombre | highlight : searchValue : 'span'}}"></div>
                        <div class="specialty">{{result.descripcion}}</div>
                        <div class="city">{{result.ciudades}}</div>
                    </div>
                </div>                   
            </div>


        </div>        
    </div>

    <!-- Empty Results -->
    <div class="search-doctor-search-results-empty-state" [hidden]="!(resultWrapper.isEmpty() && resultWrapper.searchDone) || resultWrapper.isHidden()">
        <div class="empty-title">
            {{ 'shared.searchDoctors.text8' | translate:locale.language }}
        </div>
        <ul>
            <li>
                <span>{{ 'shared.searchDoctors.text9' | translate:locale.language }}</span>
            </li>
            <li>
                <span>{{ 'shared.searchDoctors.text10' | translate:locale.language }}</span>
            </li>
            <li>
                <span>{{ 'shared.searchDoctors.text11' | translate:locale.language }}</span>
            </li>
        </ul>
    </div>

</div>