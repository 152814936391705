import { Component, Inject, OnInit } from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { ComponentLookup } from '../../decorators/component-lookup.decorator';
import { IControlComponent } from '../../interfaces/control-component';
import { FormComponentData } from '../../models/people/form-control.model';

@Component({
  selector: 'app-unread-clinical-exam',
  templateUrl: './unread-clinical-exam.component.html',
  styleUrls: ['./unread-clinical-exam.component.css']
})
@ComponentLookup('UnreadClinicalExamComponent')
export class UnreadClinicalExamComponent implements OnInit, IControlComponent {
  data: FormComponentData;
  
  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) { }

  ngOnInit(): void {
  }
}
