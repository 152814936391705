import { AfterContentChecked, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppStorageService, STORAGE } from 'src/app/core/services/app-storage.service';
import { BaseService } from 'src/app/core/services/base.service';
import { LayoutService } from 'src/app/core/services/layout.service';
import { ComponentFinishLoadingMessage, FloatingComponentsMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { FormControlComponent, FormComponentData, FormControl, FormComponentType } from '../../models/people/form-control.model';
import { Form, FormConfiguration } from '../../models/people/form.model';
import { Link } from '../../models/people/link.model';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit, OnDestroy, AfterContentChecked {
  private ngUnsubscribe = new Subject();

  private _idAreaSistema:number;

  @Input() set idAreaSistema(value:number){

    if(this._idAreaSistema == value)
      return;

    this._idAreaSistema = value;
    
    this.loadForm();
  }

  // Dictionary where key is componentName and value is object
  @Input() formConfigurationData : {[componentName: string] : any; };

  data: any;  
  form: Form;
  controles: FormControl[];
  formConfiguration: FormConfiguration;
  tipoComponente = FormComponentType;
  loadingForm: boolean = false;
  
  // Logic to wait for all component to finish loading before showing the form
  waitForComponentsToFinish = false;
  componentsFinishLoading = false;
  componentsConfirmedFinishLoading = 0;

  anonymousComponentName:string;

  constructor(
    private baseService: BaseService,
    private layoutService: LayoutService,
    private activatedRoute: ActivatedRoute,
    private appStorageService: AppStorageService,    
    private messageBusService: MessageBusService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    @Inject(L10N_LOCALE) public locale: L10nLocale) 
  {
    this.form = new Form();
    this.controles = [];

    this.layoutService.hideComponentSource$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data:FormComponentData)=>{
      this.changeVisibilityComponent(data);
    });
  }

  ngOnInit(): void {

    // Se comenta el subcribe para recargar el formulario ya que no falla en las pruebas (mi salud) y es innecesario.
    // this.messageBusService.onReloadFormComponent().pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.loadFromIdAreaCliente.bind(this));    
    // this.messageBusService.sendReloadFormComponent(this._idAreaSistema);

    this.loadFromIdAreaCliente();

    // This will be use in case the form is configured to wait for all components to finish loading before showing the content
    this.messageBusService.onComponentFinishLoading()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(message => {
      this.onComponentFinishLoading(message);
    });

    // Form configuration event
    this.messageBusService.onFormConfiguration()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(message => {
      this.formConfiguration = message.config;
    });

    // Control compoenent configuration classname
    this.messageBusService.onControlComponentClassname()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(message => {
      const controlComponent = this.controles.find(control=> control.componentes.filter(c => c.nombre === message.conponentName).length > 0);

      if(controlComponent)
        controlComponent.className = message.classname;
    });
  }

  private loadFromIdAreaCliente(): void {
    if(this._idAreaSistema > 0 && !this.loadingForm){
      this.loadForm();
    }
    else{
      this.activatedRoute.data
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
  
        let tokenObject = this.appStorageService.getToken();
        if(!tokenObject && data.nombreComponenteAnonimo){
          this.anonymousComponentName = data.nombreComponenteAnonimo;
        }else if(data.idAreaSistema){
          this._idAreaSistema = data.idAreaSistema;
          this.loadForm();
        }
        else{
          this.getDataByMenu();
        }
      });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  loadForm() {
    this.loadingForm = true;

    this.layoutService.getForm(this._idAreaSistema)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((form:Form)=>{
      this.loadingForm = false;

      if(!form) return;

      this.form = new Form(form);
      this.controles = form ? form.controles.map(c=> { return new FormControl(c) }) : [];

      this.parseFormData(form);
      
      this.sendFloatingComponents();
    });
  }

  parseFormData(form: Form) {
    if (form.valor) {
      try {
        this.form.configuracion = new FormConfiguration(JSON.parse(form.valor));

        if (this.form.configuracion){
          this.waitForComponentsToFinish = this.form.configuracion.waitForComponents;

          this.formConfiguration = this.form.configuracion;
        }    
        
        if(!this.waitForComponentsToFinish){
          this.sendFormFinishLoadingMessage(this._idAreaSistema);
        }
      } 
      catch (error) {}
    }
  }

  sendFloatingComponents(){

    let components: FormControlComponent[] = [];

    this.controles.forEach(c=>{
      components = components.concat(c.componentes.filter(cp=>cp.contenedor));
    });

    let message = new FloatingComponentsMessage();

    message.componentsData = components.map(c => this.getComponentData(c));


    this.messageBusService.sendFloatingComponents(message);
    
  }

  onComponentFinishLoading(message: ComponentFinishLoadingMessage) {
    
    // If this form is not configured to wait for components to load lets just ignore this event
    if (!this.waitForComponentsToFinish)
      return;

    if (message.idFormulario == this.form.id) {
      this.componentsConfirmedFinishLoading += 1;

      if (this.controles.length == this.componentsConfirmedFinishLoading){
        this.componentsFinishLoading = true;

        this.sendFormFinishLoadingMessage(this._idAreaSistema);
      }
    }
  }

  isFormVisible() {
    let result = (!this.waitForComponentsToFinish || this.componentsFinishLoading) && !this.anonymousComponentName;

    return result;
  }

  isAnonymousComponentVisible(){
    return this.anonymousComponentName != undefined;
  }

  getForm(){
    return this.layoutService.getForm(this._idAreaSistema);
  }

  getDataByMenu(){
    let links: Link[] = this.appStorageService.getItemSession(STORAGE.MENU, true);
    const cleanUrl = this.router.url.replace("/", "");
    const completePath = cleanUrl.split("?")[0]
    const path = completePath.split("/")[0];

    if(path == '') {
      let firstLink = links[0].identificadorUrl
        ? links[0].identificadorUrl
        : links[0].subMenu.length > 0
          ? links[0].subMenu[0].identificadorUrl
          : links[1].identificadorUrl

      this.baseService.goToRoute(firstLink);
    }
    else{
      let link = this.findLink(links, completePath) ?? links.find((l:Link)=> l.identificadorUrl == path);

      if(link){
        let tokenObject = this.appStorageService.getToken();

        if(!tokenObject && link.nombreComponenteAnonimo){
          this.anonymousComponentName = link.nombreComponenteAnonimo;
        }else{
          this._idAreaSistema = link.idAreaSistema;
          this.loadForm();
        }
      }
    }
  }

  private findLink(arg0:Link[] | null, arg1: string): Link | undefined {
    let resultado: Link | undefined;
    for (let item of (arg0 ?? [])) {
      resultado = item.identificadorUrl == arg1 ? item : this.findLink(item.subMenu, arg1);
      if (resultado) break;
    }
    return resultado;
  }

  getFlexStyle(control: FormControl){
    if(!control) return 0;
    let porc = control.numeroControlesPorFila ?  100 / control.numeroControlesPorFila : 100;
    return `1 1 ${porc}%`;
  }

  leakedComponents(components:FormControlComponent[]){
    return components.filter(c=> !c.contenedor);
  }

  getComponentData(component:FormControlComponent){
    let componentData = new FormComponentData();
    componentData.nombreComponente = component.nombre;
    componentData.contenedor = component.contenedor;
    componentData.valor = component.valor;
    componentData.idControlPadre = component.idControl;
    componentData.idFormulario = this.form.id;
    componentData.nombreFormulario = this.form.nombre;

    if (this.formConfigurationData && this.formConfigurationData[componentData.nombreComponente] != undefined) {
      componentData.configurationData = this.formConfigurationData[componentData.nombreComponente];
    }

    return componentData;  
  }

  changeVisibilityComponent(data:FormComponentData){
    let control = this.controles.find(c => c.id == data.idControlPadre);

    if(!control) return;

    let component = control.componentes.find(c => c.nombre == data.nombreComponente);
    
    if(!component) return;
    
    component.visible = false;
  }

  sendFormFinishLoadingMessage(idAreaSistema: number){

    this.messageBusService.formFinishLoading(idAreaSistema);
  }
}
