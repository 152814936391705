import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Valida que el valor del control no tenga espacios en blanco al inicio ni al final.
 * 
 * Valida que contenga letras acentuadas tanto en mayusculas como minúsculas
 * 
 * MCWNG-6194: Permite que exista hasta un espacio en blanco al inicio y/o al final.
 * @param allowSpaces Si es false, no permite ninguna espacio en blanco al inicio ni al final. Default: false
 * @returns ValidatorFn
 */
export function ValidatePersonName(allowSpaces = false): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let regex = /^[A-Za-zÁÉÍÓÚÑáéíóúñ0-9']+(?: [A-Za-zÁÉÍÓÚÑáéíóúñ0-9']+)*$/;
    if (allowSpaces) regex = /^( ?)[A-Za-zÁÉÍÓÚÑáéíóúñ0-9']+(?: [A-Za-zÁÉÍÓÚÑáéíóúñ0-9']+)*( ?)$/;
    const validacion: boolean = regex.test(control.value);
    return validacion ? null : { whitespace: true };
  }
}
