import { Component, ComponentFactoryResolver, ComponentRef, Input, OnInit, ViewChild } from '@angular/core';
import { ComponentLookupRegistry } from '../../decorators/component-lookup.decorator';
import { DynamicHostDirective } from '../../directives/dynamic-host.directive';
import { IControlComponent } from '../../interfaces/control-component';

@Component({
  selector: 'app-dynamic',
  templateUrl: './dynamic.component.html',
  styleUrls: ['./dynamic.component.css']
})
export class DynamicComponent implements OnInit {

  @Input() componentName: string;
  @Input() componentData: any;
  @ViewChild(DynamicHostDirective, {static: true}) dynamicHost: DynamicHostDirective;

  componentRef: ComponentRef<IControlComponent>;

  constructor(private resolveComponentFactory: ComponentFactoryResolver){}

  ngOnInit(): void {
    this.loadComponent();
  }

  loadComponent(){

    if(!this.componentName) return;

    const factoryClass = ComponentLookupRegistry.get(this.componentName);

    if(!factoryClass) return;

    const factory = this.resolveComponentFactory.resolveComponentFactory(factoryClass);

    const viewContainerRef = this.dynamicHost.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent<IControlComponent>(factory);

    this.componentRef.instance.data = this.componentData;
  }
}
