<div class="payment-container">
    <mat-card class="payment-card">

        <!-- Step 2 -->
        <div class="step-2">
            <div class="title desktop">{{ 'shared.payment.text4' | translate:locale.language }}</div>
            <div class="title mobile">{{ 'shared.payment.text27' | translate:locale.language }}</div>
            <div class="title-profile-name desktop truncate">{{profileName}}</div>

            <div class="content">
                <form [formGroup]="paymentForm">

                    <!-- Credit card input container -->
                    <div class="credit-card-input" [ngClass]="{focus: creditCardNumberFocus, 'credit-card-invalid': isControlInvalid('creditCardControl'), 'credit-card-valid': !isControlInvalid('creditCardControl')}">
                        <span class="pf-items">                            
                            <span class="pf-text">
                                <mat-icon fontSet="phr-iconset" class="phr-iconset-filled-lock"></mat-icon>                                
                                <span>{{ 'shared.payment.text5' | translate:locale.language }}</span>
                            </span>
                            <span class="pf-credit-card-img">
                                <img src="/assets/images/payment/card-visa.svg">
                                <img src="/assets/images/payment/card-master.svg">
                            </span>
                        </span>
                        
                        <img class="credi-card-img" *ngIf="creditCardType == '' && !cvcFocus" src="/assets/images/payment/card-front.svg">
                        <img class="credi-card-img visa" *ngIf="creditCardType == 'VISA' && !cvcFocus" src="/assets/images/payment/card-visa.svg">
                        <img class="credi-card-img master" *ngIf="creditCardType == 'MASTERCARD' && !cvcFocus" src="/assets/images/payment/card-master.svg">
                        <img class="credi-card-img amex" *ngIf="creditCardType == 'AMEX' && !cvcFocus" src="/assets/images/payment/card-american.svg">
                        <img class="credi-card-img cvc" *ngIf="cvcFocus" src="/assets/images/payment/card-back.svg">

                        <label>{{ 'shared.payment.text6' | translate:locale.language }}</label>
                        <input appCenterInput type="tel" tabindex="2" maxlength="23" class="credit-card-number" placeholder="Número de tu tarjeta" 
                            required="required" formControlName="creditCardControl"
                            [ngClass]="{'invalid': isControlInvalid('creditCardControl') || isControlInvalid('expirationDateControl') || !isCreditCardExpirationValid() || isControlInvalid('cvcControl')}"
                            ccNumber #ccNumber="ccNumber" #creditCardNumber
                            (focus)="onCreditCardNumberFocus(ccNumber)" (keyup)="onCreditCardNumberKeyup($event)" (focusout)="onCreditCardNumberBlur()"/>

                        <span class="mobile-last-four-digits" (click)="onMobileLastFourDigitsClick()">{{creditCardLastFourDigits}}</span>

                        <input appCenterInput tabindex="3" type="tel" class="credit-card-exp-date" placeholder="MM/YY" required="required" formControlName="expirationDateControl"
                            ccExp #creditCardExpiration maxlength="7"
                            (keyup)="onCreditCardExpirationKeyup()"/>

                        <input appCenterInput class="credit-card-secret" type="tel" tabindex="4" placeholder="CVC" formControlName="cvcControl"
                            ccCVC #creditCardCvc
                            (focus)="onCvcFocus();" (blur)="onCvcBlur();" (keyup)="onCreditCardSecretKeyup($event)" />

                        <div class="error-container" *ngIf="isControlInvalid('creditCardControl')">
                            <span *ngIf="controlHasError('creditCardControl', 'required')">{{ 'shared.payment.text9' | translate:locale.language }}</span>
                            <span *ngIf="!controlHasError('creditCardControl', 'required') && controlHasError('creditCardControl', 'ccNumber')">{{ 'shared.payment.text10' | translate:locale.language }}</span>
                        </div>
                        <div class="error-container" *ngIf="isControlInvalid('expirationDateControl') || !isCreditCardExpirationValid()">
                            <span *ngIf="controlHasError('expirationDateControl', 'required')">{{ 'shared.payment.text11' | translate:locale.language }}</span>
                            <span *ngIf="!controlHasError('expirationDateControl', 'required')">{{ 'shared.payment.text12' | translate:locale.language }}</span>
                        </div>
                        <div class="error-container" *ngIf="isControlInvalid('cvcControl')">
                            <span *ngIf="controlHasError('cvcControl', 'required')">{{ 'shared.payment.text13' | translate:locale.language }}</span>
                            <span *ngIf="!controlHasError('cvcControl', 'required')">{{ 'shared.payment.text14' | translate:locale.language }}</span>
                        </div>    
                    </div>

                    <div>
                        <mat-form-field appearance="outline" [floatLabel]="'always'">
                            <mat-label>{{ 'shared.payment.text7' | translate:locale.language }}</mat-label>
                            <input appCenterInput matInput required="required" formControlName="fullNameControl" pattern="^\s*([a-zA-Z]{2,25})\s+([a-zA-Z]{2,25}([a-zA-Z]|\s)*)$" maxlength="50" placeholder="{{'shared.payment.text26' | translate:locale.language}}">
                            <mat-error *ngIf="controlHasError('fullNameControl', 'required')">{{ 'shared.payment.text15' | translate:locale.language }}</mat-error>
                            <mat-error *ngIf="!controlHasError('fullNameControl', 'required') && controlHasError('fullNameControl', 'pattern')">{{ 'shared.payment.text25' | translate:locale.language }}</mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" floatLabel="always" class="phone-number-container" >
                            <mat-label>{{ 'shared.payment.text8' | translate:locale.language }}</mat-label>
                            <ngx-mat-intl-tel-input
                                [preferredCountries]="['pa']"
                                [enablePlaceholder]="true"
                                inputPlaceholder="{{phoneNumber.selectedCountry | intlTelInputPlaceholder}}"
                                [enableSearch]="false"
                                name="phoneNumber"
                                formControlName="phoneNumberControl"                                
                                #phoneNumber>
                            </ngx-mat-intl-tel-input>
                            <mat-error *ngIf="controlHasError('phoneNumberControl', 'required')">{{ 'shared.payment.text16' | translate:locale.language }}</mat-error>
                            <mat-error *ngIf="isControlInvalid('phoneNumberControl') && !controlHasError('phoneNumberControl', 'required')">{{ 'shared.payment.text17' | translate:locale.language }}</mat-error>
                        </mat-form-field>
                    </div>
                
                    <div class="mobile">
                        <div class="terms">
                            <div>{{ 'shared.payment.text18' | translate:locale.language }} <br />
                                <a (click)="openDialogLegalAgreements()">{{ 'shared.payment.text19' | translate:locale.language }}</a>
                            </div>
                        </div>
                        <div class="cliniweb">
                            {{ 'shared.payment.text20' | translate:locale.language }}
                            {{ 'shared.payment.text21' | translate:locale.language }}<br *ngIf="!isMobile()" />
                            {{ 'shared.payment.text22' | translate:locale.language }}
                        </div>
                    </div>
                    
                </form>
            </div>
            <div class="btn-container">
                <div class="profile-info mobile">
                    <div>{{ 'shared.payment.text28' | translate:locale.language }}</div>
                    <div class="truncate">
                        <span class="profile-name">{{profileName}}.</span>
                        <span>{{profileSpecialty}}</span>
                    </div>
                </div>

                <button mat-button mat-flat-button (click)="onPayClick()">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-filled-lock"></mat-icon>
                    <span>{{ 'shared.payment.text23' | translate:locale.language }} $</span>
                    <span>{{paymentAmount}}</span>
                </button>

                <div class="desktop">
                    <div class="terms">
                        {{ 'shared.payment.text18' | translate:locale.language }}
                        <a (click)="openDialogLegalAgreements()">{{ 'shared.payment.text19' | translate:locale.language }}</a>
                    </div>
                    <div class="cliniweb">
                        {{ 'shared.payment.text20' | translate:locale.language }}
                        {{ 'shared.payment.text21' | translate:locale.language }}
                        {{ 'shared.payment.text22' | translate:locale.language }}
                    </div>
                </div>
            </div>
        </div>
        

    </mat-card>
</div>
