import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CountryModel, TerritorialDivision } from 'src/app/shared/models/systems/territorial-division.model';
import { HttpBaseService } from './http-base-service';
import { HttpClient } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { AppStorageService, STORAGE } from './app-storage.service';
import { BaseService } from './base.service';
import { MessageBusService, TerritorialDivionCountryMessage } from './message-bus.service';

@Injectable({
  providedIn: 'root'
})
export class TerritorialDivisionsService {

  constructor(
    private httpClient: HttpClient,
    private http: HttpBaseService,
    private baseService: BaseService,
    private messageBusService: MessageBusService,
    private appStorageService: AppStorageService
  ) { } 
  
  getByLevels(language: string, searchText: string, levels: string, countryId: number = -1):Observable<TerritorialDivision[]> {
    let url = `api/divisiones-territoriales?localizacion=${language}&texto=${encodeURIComponent(searchText)}&niveles=${levels}&codigoPais=${countryId}`;

    return this.http.get<TerritorialDivision[]>(url, null, false);    
  }

  getBySwiftCode(language: string, swift:string):Observable<TerritorialDivision>{
    let url = `api/divisiones-territoriales/swift/${swift}?localizacion=${language}`;

    return this.http.get<TerritorialDivision>(url, null, false);
  }

  getByIp(language: string, ip:string):Observable<TerritorialDivision>{
    let url = `api/divisiones-territoriales/ip/${ip}?localizacion=${language}`;

    return this.http.get<TerritorialDivision>(url, null, false);
  }

  getByCountryId(language: string, countryId:number){
    let url = `api/divisiones-territoriales/pais/${countryId}?localizacion=${language}`;

    return this.http.get<TerritorialDivision>(url, null, false);
  }

  getCountryByIp(): Observable<CountryModel>{
    return this.getIp().pipe(switchMap((ip:string)=>{
      
      let countryObject = this.appStorageService.getItem(STORAGE.COUNTRY, true);

      if(countryObject && (countryObject.ip && countryObject.ip == ip || countryObject.territorialDivision)){
        return of(countryObject);
      }

      const language = this.baseService.getLanguage();

      return this.getByIp(language, ip)
        .pipe(map((t:TerritorialDivision)=>{

          countryObject = new CountryModel();
          countryObject.ip = ip;
          countryObject.territorialDivision = t;

          this.appStorageService.setItem(STORAGE.COUNTRY, countryObject, true);

          return countryObject; 
        }));
    }));
  }

  setCountryApp(countryId: number){
    this.getCountryApp().subscribe(countryApp=>{

      let country = this.getAvailableCountries().find(c=>c.idPais == countryId);

      if(!country)
        return;
  
      countryApp.territorialDivision = country;
  
      this.appStorageService.setItem(STORAGE.COUNTRY, countryApp, true);    
  
      let msg = new TerritorialDivionCountryMessage();
      msg.country = country;
  
      this.messageBusService.sendTerritorialDivisionCountry(msg);
    });
  }

  getCountryApp():Observable<CountryModel>{
    let country = this.appStorageService.getItem(STORAGE.COUNTRY, true);

    if(country){
      return of(country);
    }
    else{
      return this.getCountryByIp();
    }
  }

  getAvailableCountries(): TerritorialDivision[]{

    let countries: TerritorialDivision[] = [];
    
    let panama = new TerritorialDivision();
    panama.id = 19;
    panama.nombre = "Panamá";
    panama.nombreI18n = [{lenguaje: 'es', texto: 'Panamá'}, {lenguaje: 'en', texto: 'Panamá'}];
    panama.nombreCanonico = "Panamá";
    panama.nivel = 1;
    panama.identificador = "panama";
    panama.paisISO = "PA";
    panama.idPais = 28;

    countries.push(panama);

    let ecuador = new TerritorialDivision();
    ecuador.id = 4;
    ecuador.nombre = "Ecuador";
    ecuador.nombreI18n = [{lenguaje: 'es', texto: 'Ecuador'}, {lenguaje: 'en', texto: 'Ecuador'}];
    ecuador.nombreCanonico = "Ecuador";
    ecuador.nivel = 1;
    ecuador.identificador = "ecuador";
    ecuador.paisISO = "EC";
    ecuador.idPais = 13;

    countries.push(ecuador);

    let costaRica = new TerritorialDivision();
    costaRica.id = 14;
    costaRica.nombre = "Costa Rica";
    costaRica.nombreI18n = [{lenguaje: 'es', texto: 'Costa Rica'}, {lenguaje: 'en', texto: 'Costa Rica'}];
    costaRica.nombreCanonico = "Costa Rica";
    costaRica.nivel = 1;
    costaRica.identificador = "costa-rica";
    costaRica.paisISO = "CR";
    costaRica.idPais = 23;

    countries.push(costaRica);

    let honduras = new TerritorialDivision();
    honduras.id = 16;
    honduras.nombre = "Honduras";
    honduras.nombreI18n = [{lenguaje: 'es', texto: 'Honduras'}, {lenguaje: 'en', texto: 'Honduras'}];
    honduras.nombreCanonico = "Honduras";
    honduras.nivel = 1;
    honduras.identificador = "honduras";
    honduras.paisISO = "HN";
    honduras.idPais = 25;

    countries.push(honduras);

    let republicaDominicana = new TerritorialDivision();
    republicaDominicana.id = 29;
    republicaDominicana.nombre = "República Dominicana";
    republicaDominicana.nombreI18n = [{lenguaje: 'es', texto: 'República Dominicana'}, {lenguaje: 'en', texto: 'Dominican Republic'}];
    republicaDominicana.nombreCanonico = "República Dominicana";
    republicaDominicana.nivel = 1;
    republicaDominicana.identificador = "republica-dominicana";
    republicaDominicana.paisISO = "DO";
    republicaDominicana.idPais = 38;

    countries.push(republicaDominicana);

    let otros = new TerritorialDivision();
    otros.id = 6213;
    otros.nombre = "Otros";
    otros.nombreI18n = [{lenguaje: 'es', texto: 'Otros'}, {lenguaje: 'en', texto: 'Others'}];
    otros.nombreCanonico = "Otros";
    otros.nivel = 1;
    otros.identificador = "otros";
    otros.paisISO = "1";
    otros.idPais = 1;

    countries.push(otros);

    return countries;
  }

  private getIp():Observable<string>{
    return this.httpClient.post<string>("https://ip.aspsoluciones.com/ServiciosIP.asmx/IP", {})
    .pipe(map((ipObject:any)=>{
      return ipObject.d;
    }));
  }
}