import { Injectable } from "@angular/core";
import { BaseStep } from "./base-step.model";
import { IWorkflowDataState } from "src/app/shared/interfaces/workflow-data-state";
import { ComponentDataSetMessage, MessageBusService } from "src/app/core/services/message-bus.service";
import { ComponentName } from "src/app/shared/enums/component-name.enum";
import { TerritorialDivision } from "../../systems/territorial-division.model";
import { WorkflowItem } from "../../systems/workflow.model";
import { WorkflowService } from "src/app/core/services/workflow.service";
import { Observable, of } from "rxjs";
import { TerritorialDivisionsService } from "src/app/core/services/territorial-divisions.service";
import { UserService } from "src/app/core/services/user.service";
import { switchMap } from "rxjs/operators";

@Injectable({
    providedIn: 'root',
})
export class SelectCountryStep extends BaseStep<IWorkflowDataState & ISelectCountryWorkflowState> {
    constructor(
        private userService: UserService,
        private territorialDivisionService: TerritorialDivisionsService,
        private workflowService: WorkflowService,
        protected messageBusService: MessageBusService,
    ) {
        super(messageBusService);

        this.showHeader = true;
        this.showProgressBar = true;
        this.showButtonClose = true;

        this.withoutFooter = true;
    }
    
    setItem(item: WorkflowItem): void {
        this.item = item;
    }

    setState(stateData: any): void {
        this.stateData = this.workflowService.getWorkflowDataState();
    }

    isCompleted():Observable<boolean>{
        return this.countrySelected();
    }

    showStepInActionBack(): boolean {
        return true;
    }

    showStep(): boolean {
        return true;
    }

    protected handleComponentSetDataMessage(message: ComponentDataSetMessage) {
        if (message.componentName == ComponentName.SELECT_COUNTRY){

            let country = message.data as TerritorialDivision;

            this.stateData.setCountry(country);
        }
    }

    protected buildComponentConfigurationData() : { [componentName: string]: any; } {
        let formConfigurationData : { [componentName: string]: any; } = {};
        
        formConfigurationData[ComponentName.SELECT_COUNTRY] = this.getCountryDataModel();

        return formConfigurationData;
    }

    protected messageForThisStep(message: ComponentDataSetMessage): boolean {
        return message.componentName == ComponentName.SELECT_COUNTRY;
    }

    private countrySelected(): Observable<boolean>{

        let countryId = this.stateData.getCountryId();

        if(countryId == 9){
            return this.userService.getUserCountry()
            .pipe(switchMap((country: TerritorialDivision | null)=>{
                if(country){
                    this.stateData.setCountry(country);
    
                    return of(true);
                }
                else{
                    return of(false);
                }            
            }));
        }else{
            let availableCountries = this.territorialDivisionService.getAvailableCountries();

            let condition = countryId > 0 && availableCountries.filter(c=>c.idPais == countryId).length > 0;
            
            return of(condition);
        }
    }

    private getCountryDataModel(): TerritorialDivision | undefined{
        let countryId = this.stateData.getCountryId();

        let availableCountries = this.territorialDivisionService.getAvailableCountries();

        return availableCountries.find(c => c.idPais == countryId);
    }
}

export interface ISelectCountryWorkflowState{
    getCountryId():number;
    setCountry(country: TerritorialDivision):void;
}