import { Component, Inject, OnInit } from '@angular/core';
import { ComponentLookup } from '../../decorators/component-lookup.decorator';
import { ComponentName } from '../../enums/component-name.enum';
import { IControlComponent } from '../../interfaces/control-component';
import { FormComponentData } from '../../models/people/form-control.model';
import { CreatePatientRelationshipDataModel } from '../../models/workflow/models/create-patient-relationship-data.model';
import { Subject } from 'rxjs';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SenderPatientRelationship } from '../../models/process/base-request.model';
import { ComponentDataSetMessage, ComponentFinishLoadingMessage, MessageBusService } from 'src/app/core/services/message-bus.service';

@Component({
  selector: 'app-create-patient-relationship',
  templateUrl: './create-patient-relationship.component.html',
  styleUrls: ['./create-patient-relationship.component.css']
})
@ComponentLookup(ComponentName.CREATE_PATIENT_RELATIONSHIP)
export class CreatePatientRelationshipComponent implements IControlComponent, OnInit {
  data: FormComponentData;

  title: string;
  model:CreatePatientRelationshipDataModel = new CreatePatientRelationshipDataModel();

  formSubmitted: boolean = false;
  loading: boolean = false;
  relationTypes: RelationTypeOption[] = [];
  createPatientRelationshipForm:FormGroup;
  showForm:boolean = false;

  constructor(
    private messageBusService: MessageBusService,
    private formBuilder: FormBuilder,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) { }
  
  ngOnInit(): void {

    this.loadDataModel();

    this.configTitle();

    this.loadForm();

    this.sendComponentFinishLoadingMessage();
  }

  onClickContinue(){
    this.formSubmitted = true;
    
    if (this.createPatientRelationshipForm.invalid) {
      return;
    }

    this.loading = true;

    let _valueSelected = +(this.createPatientRelationshipForm.controls.relationship.value);

    this.model.patientRelationship = _valueSelected;

    this.continueFlow();
  }
  
  private continueFlow() {
    let message = new ComponentDataSetMessage();
    message.componentName = ComponentName.CREATE_PATIENT_RELATIONSHIP;

    message.data = this.model;

    this.messageBusService.onComponentDataSetMessage(message); 
  }

  private configTitle(){
    this.title = this.model.patientRelationship == SenderPatientRelationship.OTRO
      ? 'shared.createPatientRelationship.title1'
      : 'shared.createPatientRelationship.title2'
  }

  private loadDataModel() {
    if (this.data && this.data.configurationData) {
      this.model = this.data.configurationData as CreatePatientRelationshipDataModel;        
    }     
  }

  private loadForm(){

    if(this.model.patientRelationship != SenderPatientRelationship.OTRO){
      this.relationTypes.push({
        value: SenderPatientRelationship.USUARIO, 
        viewValue: 'shared.createPatientRelationship.relationship_' + SenderPatientRelationship.USUARIO
      });
    }

    let relationTypeIds = [
      SenderPatientRelationship.PROGENITOR_DE,
      SenderPatientRelationship.HIJO_DE,
      SenderPatientRelationship.ESPOSO,
      SenderPatientRelationship.HERMANO,
      SenderPatientRelationship.ABUELO,
      SenderPatientRelationship.TUTOR,
      SenderPatientRelationship.DEPENDIENTE_FAMILIAR_DE
    ];

    relationTypeIds.forEach(relationTypeId=> {
      this.relationTypes.push({value: relationTypeId, viewValue: 'shared.createPatientRelationship.relationship_' + relationTypeId });
    });

    this.createPatientRelationshipForm = this.formBuilder.group(
      {
        relationship: [this.model.patientRelationship, [Validators.required]]
      }
    );

    this.showForm = true;
  }

  private sendComponentFinishLoadingMessage(){
    let event = new ComponentFinishLoadingMessage();
      event.idFormulario = this.data.idFormulario;
      event.idControl = this.data.idControlPadre;

    this.messageBusService.componentFinishLoading(event);   
  }
}

export interface RelationTypeOption{
  value: number;
  viewValue: string;
}