import { IWorkflowComponent } from "../interfaces/workflow-component";

export type WorkflowComponentClass = { new (): IWorkflowComponent };

export const WorkflowLookupRegistry: Map<string, WorkflowComponentClass> = new Map();

export const WorkflowLookup = (key: string): any => {
    return (cls: WorkflowComponentClass) => {
        WorkflowLookupRegistry.set(key, cls);
    };
};