import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';
import { UserService } from 'src/app/core/services/user.service';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { Dependent } from 'src/app/shared/models/people/dependent.model';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';
import { PatientAppointment } from 'src/app/shared/models/process/appointment.model';
import { Directory } from 'src/app/shared/models/systems/directory.model';

@Component({
  selector: 'app-next-appointment-dialog',
  templateUrl: './next-appointment-dialog.component.html',
  styleUrls: ['./next-appointment-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NextAppointmentDialogComponent implements OnInit {     
    appointment : PatientAppointment;        
    loading = true;    
    directory: Directory;
    showAll: boolean = true;
    dependents: Dependent[];  
    loggedInUser: UserPerson;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject(DOCUMENT) private document: Document,
    public mdDialogRef: MatDialogRef<NextAppointmentDialogComponent>,
    private baseService: BaseService,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
    this.loading = true;

    let observables: Observable<any>[] = [];
    observables.push(this.getDirectory());
    observables.push(this.getUser());
    observables.push(this.getDependents());

    forkJoin(observables)    
    .subscribe(results => { 
      this.directory = results[0] as Directory;
      this.loggedInUser = results[1] as UserPerson;
      this.dependents = results[2] as Dependent[];
      
      this.appointment = this.baseService.parseObject<PatientAppointment>(this.data.data);
      
      this.loading = false;
    });
  } 

  getDirectory() : Observable<Directory> {
    let companyId = this.baseService.getCliniwebDirectoryCompanyId();

    return this.baseService.getCliniwebDirectory(companyId);
  }

  getUser() : Observable<UserPerson | null> {
    return this.userService.getUserPersonLoggedIn().pipe(
      tap(() => {
        
      }),
      catchError((e => {        
        this.baseService.handleServiceError(e, "Error getting user"); 
        
        return of(null);
      })));
  }

  getDependents() : Observable<Dependent[]>{
    return this.userService.getUserDependents().pipe(
      tap(() => {
        
      }),
      catchError((e => {        
        this.baseService.handleServiceError(e, "Error getting dependents"); 
        
        return of([]);
      })));
  }

  public close() {
    if(this.data.animationOut){
      this.document.getElementsByClassName(this.data.id)[0].classList.remove(this.data.animationIn);
      this.document.getElementsByClassName(this.data.id)[0].classList.add(this.data.animationOut);

      setTimeout(()=>{      
        this.mdDialogRef.close();
      }, 300);
    }
    else{
      this.mdDialogRef.close();
    }
  }  
  
  @HostListener("keydown.esc") 
  public onEsc() {
    this.close();
  }
}