import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-cola-goblo-dialogo',
  templateUrl: './cola-goblo-dialogo.component.html',
  styleUrls: ['./cola-goblo-dialogo.component.css']
})
export class ColaGobloDialogoComponent implements OnInit {

  @HostBinding('style.width') width = '100%';

  constructor() { }

  ngOnInit(): void {
  }

}
