import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, Inject, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { UserService } from 'src/app/core/services/user.service';
import { MEDICAL_REQUEST_TYPE } from 'src/app/shared/enums/medical-request-type.enum';
import { AttentionsHistory } from 'src/app/shared/interfaces/attentions-history';
import { ClinicalHistoryItem } from 'src/app/shared/interfaces/clinical-history-item';

@Component({
  selector: 'app-summary-order-footer',
  templateUrl: './summary-order-footer.component.html',
  styleUrls: ['./summary-order-footer.component.css']
})
export class SummaryOrderFooterComponent implements OnDestroy, OnChanges {

  @Input() idPaciente = -1;
  @Input() inWorflow: boolean = true;
  @Input() idTipoSolicitud: number;
  @Input() nombreTipoSolicitud: string;
  @Input() file: File;

  buttonSecondaryText = 'shared.medicalOrder.summary.buttonText2';
  buttonPrimaryText = 'shared.medicalOrder.summary.buttonTextPrescriptions';

  private ngUnsubscribe = new Subject();

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private breakpointObserver: BreakpointObserver,
    private messageBusService: MessageBusService,
    private userService: UserService
  ) {
    this.breakpointObserver.observe(Breakpoints.Handset).pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.mobileNext.bind(this));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.nombreTipoSolicitud) {
      this.buttonPrimaryText = `${this.nombreTipoSolicitud}Button`;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private mobileNext(x: BreakpointState): void {
    if (!x.matches) return;
    this.buttonSecondaryText = 'shared.medicalOrder.summary.buttonTextMobile2';
  }

  verOrdenes(): void {

    this.userService.getUserIdLoggedIn()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((userId: number) => {
      let pagina = MEDICAL_REQUEST_TYPE.MEDICAL_PRESCRIPTION == this.idTipoSolicitud 
        ? 'recetas' 
        : 'laboratorio';
    
      let url = `mi-salud/${pagina}`;

      if (userId != this.idPaciente) {
        url += `?p=${this.idPaciente}`;
      }

      this.messageBusService.closeWorkFlowRequest(url);
    });
  }

  irInicio(): void {
    this.messageBusService.closeWorkFlowRequest('inicio');
  }

}
