
<div class="select-patient-container">
    <app-appointment-info-checkout [model]="model.appointmentInfo" [show]="showAppointmentInfo"></app-appointment-info-checkout>
    <mat-divider vertical *ngIf="showAppointmentInfo"></mat-divider>
    <div class="content">
        <span class="title">
            <span *ngIf="!showInitialView">{{model.user.firstname}}, </span>
            <span *ngIf="!showInitialView">{{ 'shared.selectPatientAppointmentCheckout.title' | translate:locale.language }}</span>
            <span *ngIf="showInitialView">{{ 'shared.selectPatientAppointmentCheckout.title2' | translate:locale.language }}</span>
        </span>

        <form class="patient-relationship-type-form" [formGroup]="patientRelationshipTypeForm" (ngSubmit)="onClickContinue()" *ngIf="showForm">

            <!-- User or Dependent selection -->
            <div *ngIf="showInitialView" class="relationship-wrapper" [ngClass]="{'invalid': isControlInvalid('relationship')}">
                <mat-radio-group formControlName="relationship" [value]="model.relationship">
                    <mat-radio-button value="1">
                        {{'shared.selectPatientAppointmentCheckout.radioOption1' | translate:locale.language}}
                    </mat-radio-button>
                    <mat-divider></mat-divider>
                    <mat-radio-button value="2">
                        {{'shared.selectPatientAppointmentCheckout.radioOption2' | translate:locale.language}}
                    </mat-radio-button>
                </mat-radio-group>
                <div>
                    <mat-error *ngIf="isControlInvalid('relationship')">{{'shared.selectPatientAppointmentCheckout.selectRadioValidation' | translate:locale.language}}</mat-error>
                </div>
            </div>

            <!-- Dependents List -->
            <div *ngIf="!showInitialView" class="patient-relationship-wrapper">
                <mat-radio-group formControlName="relationship" [value]="model.relationship" *ngIf="patientRelationshipTypeForm.get('relationship') as relationshipControl">
                    <mat-radio-button [value]="model.user.id" [checked]="relationshipControl.value == model.user.id">
                        <img [src]="getUserAvatar(model.user)"> 
                        <span class="name">{{ model.user.firstname }}</span>
                        <span>{{'shared.selectPatientAppointmentCheckout.userRelationship' | translate:locale.language}}</span>
                    </mat-radio-button>                    
                    <mat-radio-button *ngFor="let dependent of dependents" [value]="dependent.idPersona" [checked]="relationshipControl.value == dependent.idPersona">
                        <img [src]="getDependentAvatar(dependent)"> 
                        <span class="name">{{dependent.nombre}}</span>
                        <span>{{getRelationshipText(dependent)}}</span>
                    </mat-radio-button>
                    <mat-radio-button value="2" [checked]="relationshipControl.value == 2">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-plus-heavier"></mat-icon>
                        <span class="name">{{'shared.selectPatientAppointmentCheckout.otherText' | translate:locale.language}}</span>
                        <span>{{'shared.selectPatientAppointmentCheckout.otherDescription' | translate:locale.language}}</span>
                    </mat-radio-button>
                </mat-radio-group>                
            </div>

            <!-- Buttons -->
            <div class="button-container">
                <div class="appointment-info-mobile" (click)="openAppointmentInfoDialog()">
                    <span>{{ model.appointmentInfo.appointmentDate | date:'d MMM, h:mm a': 'UTC': language }}</span>
                    <span> • </span>
                    <span>{{ model.appointmentInfo.attentionPlaceName }}</span>
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-arrow-down-big"></mat-icon>
                </div>
                <button mat-button mat-flat-button class="primary" [ngClass]="{'loading': loading }">
                    {{ 'shared.selectPatientAppointmentCheckout.buttonText' | translate:locale.language }}
                </button>
            </div>

            <app-company-logo></app-company-logo>
        </form>
    </div>
</div>