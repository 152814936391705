import { PublicProfile } from "../people/public-profile.model";
import { BaseRequestModel } from "./base-request.model";

export class OnlineAppointmentRequest extends BaseRequestModel {
    // SolicitudCitaEnLinea
    idLocalidad: number;
    nombreLocalidad: string;
    direccionLocalidad: string;
    coordenadasLocalidad: string;
    telefonoLocalidad: string;
    teleconsulta: string;
    fechaInicio: Date;
    fechaFin: Date;
    idProcedimiento: number;
    nombreProcedimiento: string;
    numeroCelularContacto: string;
    tipoContacto: number = 0;
    workflowPosition:number = 0;
    flujoDependientes: boolean = false;
    patientDataInitiallyIncomplete: boolean = false;

    idEstado: number;
    nombreEstado: string;

    profile: PublicProfile;
}

