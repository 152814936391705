<div class="menu-link-container {{(link.identificadorUrl || '').replace('/', '-')}}" ngClass.lt-lg="mobile" [ngClass]="{'with-submenu': hasOptions }">
    
    <a mat-list-item [class.active]="isLinkActive(link)" [class.sublink-active]="isSublinkActive(link)" (click)="onLinkSelected(link)" 
        [ngClass]="{'link-expand': hasOptions}" [matTooltip]="getTooltip(link)">
        <div fxLayout="row" fxLayoutAlign="center center"  class="menu-link-wrapper">    
            <mat-icon matListIcon *ngIf="link.nombreIcono" fontSet="phr-iconset" [ngClass]=" 'phr-iconset-' +  getIconName(link)"></mat-icon>
            <span class="menu-link-text">{{link.texto}}</span>            
            
            <mat-icon matListIcon fontSet="phr-iconset" class="phr-iconset-arrown-down expand"
                *ngIf="hasOptions"  
                [ngClass]="expanded ? 'expanded': 'collapsed'">
                <!-- expand_more -->
            </mat-icon>
            <span *ngIf="showNotification"  class="notification-red-circle">
                {{notificationValue}}
            </span>
            <div class="mi-salud-mat-icon-after"></div>
        </div>
    </a>

    <div *ngIf="expanded" class="submenu-container">
        <menu-link *ngFor="let subLink of link.subMenu | orderBy:'orden':'asc'" [link]="subLink"></menu-link>
    </div>
    
</div>