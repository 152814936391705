import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { Subject } from 'rxjs';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { GroupConcept } from 'src/app/shared/enums/group-concept.enum';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { ClinicHistoryItem } from 'src/app/shared/models/clinic-history/attention-history.model';
import { MedicalHistory } from 'src/app/shared/models/clinic-history/medical-history.model';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';

@Component({
  selector: 'app-medical-history',
  templateUrl: './medical-history.component.html',
  styleUrls: ['./medical-history.component.css']
})
@ComponentLookup('MedicalHistoryComponent')
export class MedicalHistoryComponent implements OnInit, OnDestroy, IControlComponent {
  data: FormComponentData;
  items: MedicalHistoryViewDTO[] = [];

  //subscribes
  private ngUnsubscribe = new Subject();

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private messageBusService: MessageBusService
  ) { }

  ngOnInit(): void {
    this.messageBusService.onSendClinicHistory().subscribe(message =>{
      this.items = message.clinicHistory.antecedentes.map(a => new MedicalHistoryViewDTO(a));
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

export class MedicalHistoryViewDTO {
  id: number;
  titulo: string;
  icono: string;
  conceptos: ClinicHistoryItem[];

  constructor(group: MedicalHistory){
    this.id = group.idTipoConcepto;
    this.titulo = group.nombreTipoConcepto;
    this.icono = this.getIconNameByGroup(group.agrupadores);
    this.conceptos = group.items;
  }

  getIconNameByGroup(groupArray: number[]):string {
    let iconname = 'phr-iconset-laboratorios';

    if(groupArray.filter(a => a == GroupConcept.ALERGIAS).length > 0){
      iconname = "phr-iconset-polen";
    } else if(groupArray.filter(a => a == GroupConcept.VACUNAS).length > 0){
      iconname = "phr-iconset-vacuna";
    } else if(groupArray.filter(a => a == GroupConcept.ENFERMEDADES).length > 0){
      iconname = "phr-iconset-termometro";
    } else if(groupArray.filter(a => a == GroupConcept.ENFERMEDADES_FAMILIARES).length > 0){
      iconname = "phr-iconset-adn";
    } else if(groupArray.filter(a => a == GroupConcept.CIRUGIAS).length > 0){
      iconname = "phr-iconset-bisturi";
    } else if(groupArray.filter(a => a == GroupConcept.HABITOS).length > 0){
      iconname = "phr-iconset-apple";
    }

    return iconname;
  }
}
