import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout.service';
import { DialogData } from 'src/app/shared/models/dialog-data.model';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe = new Subject();
  licenseLoading: boolean = true;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject(DOCUMENT) private document: Document,
    private layoutService: LayoutService,
    private mdDialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {
  }

  ngOnInit(): void {

    if(this.data.loadLicense){
      this.layoutService.loadLicenseTheme('confirm-dialog-wrapper')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result=>{
        this.licenseLoading = false;
      });
    }
    else{
      this.licenseLoading = false;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public cancel() {
    this.close(false);
  }

  public close(value:any) {
    if(this.data.animationOut){
      this.document.getElementsByClassName(this.data.id)[0].classList.remove(this.data.animationIn);
      this.document.getElementsByClassName(this.data.id)[0].classList.add(this.data.animationOut);

      setTimeout(()=>{      
        this.mdDialogRef.close(value);
      }, 300);
    }
    else{
      this.mdDialogRef.close(value);
    }
  }

  public confirm() {
    this.close(true);
  }
  
  @HostListener("keydown.esc") 
  public onEsc() {
    this.close(false);
  }
}