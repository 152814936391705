import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { AppStorageService, STORAGE } from 'src/app/core/services/app-storage.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { DialogData } from '../../models/dialog-data.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-logout-bar',
  templateUrl: './logout-bar.component.html',
  styleUrls: ['./logout-bar.component.css']
})
export class LogoutBarComponent implements OnInit, OnDestroy {

  show: boolean = false;
  profileImg: string;
  profileName: string;

  private ngUnsubscribe = new Subject();

  @Input()closeFn: any;
  
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private authService: AuthService,
    private userService: UserService,
    private appStoreService: AppStorageService,
    private translation: L10nTranslationService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    let existsTokenInSession = this.appStoreService.getItemSession(STORAGE.TOKEN) ? true : false;

    if(!existsTokenInSession){
      this.authService.isLoggedInFull()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result:boolean)=>{
        if(result){
          this.userService.getUserPersonLoggedIn()
          .subscribe((user)=>{
            if(user){
              this.show = true;

              this.profileImg = user.ubicacionLogo;
              this.profileName = user.nombre;
            }
          });
        }
      });
    }
    else{
      this.show = false;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onClick(){
    let dialogData = new DialogData();
    dialogData.title = this.translation.translate("shared.logoutDialog.title", { name: this.profileName });
    dialogData.confirmText = this.translation.translate("shared.logoutDialog.confirmText");   
    dialogData.cancelText = this.translation.translate("shared.logoutDialog.cancelText");
    dialogData.showHeaderIcon = false;
    dialogData.showCancelButton = true;
    dialogData.id = 'confirm-logout-dialog';
    dialogData.animationIn = 'confirm-logout-animation-in';
    dialogData.animationOut = 'confirm-logout-animation-out';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      panelClass: ['confirm-logout-dialog', 'confirm-logout-animation-in']
    });

    dialogRef.afterClosed().subscribe(accepted => {

      if(accepted){
        this.closeFn();

        this.show = false;
      }
    });
  }
}