<div class="edit-user-container" appTouchMoveListener>    
    <mat-card class="payment-card" *ngIf="loggedInUser">

        <div class="title">
            {{'shared.editUser.text1' | translate:locale.language}}            
        </div>
        <div class="user-avatar-container">
            <app-avatar-upload
                [fileUrl]="loggedInUser.ubicacionLogo"
                [id]="loggedInUser.id"
                [options]="avatarUploadOptions"
                (onFileUploaded)="onAvatarUploaded($event)"
            ></app-avatar-upload>
        </div>

        <div class="form-container">
            <form [formGroup]="formGroup">                
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{'shared.editUser.text3' | translate:locale.language}}</mat-label>
                        <input appCenterInput matInput required="required" formControlName="nameControl" class="capitalize-text" appCapitalize>
                        <mat-error *ngIf="isControlInvalid('nameControl')">{{'shared.editUser.text4' | translate:locale.language}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{'shared.editUser.text5' | translate:locale.language}}</mat-label>
                        <input appCenterInput matInput required="required" formControlName="lastNameControl" class="capitalize-text" appCapitalize>
                        <mat-error *ngIf="isControlInvalid('lastNameControl')">{{'shared.editUser.text6' | translate:locale.language}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <app-datepicker-flat-field
                        [label]="'shared.editUser.text7' | translate:locale.language"
                        [maxDate]="maxDate"
                        [minDate]="minDate"
                        [errorMessage]="'shared.editUser.text8' | translate:locale.language"
                        formControlName="birthDateControl"
                    ></app-datepicker-flat-field>
                </div>
                <div>
                    <div class="gender-container form-field-container">
                        <label>{{'shared.editUser.text9' | translate:locale.language}}</label>
                        <!-- <mat-radio-group formControlName="sexControl">
                            <mat-radio-button value="M">Hombre</mat-radio-button>
                            <mat-radio-button value="F">Mujer</mat-radio-button>
                        </mat-radio-group> -->

                        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" formControlName="sexControl" [ngClass]="{'invalid-control': isControlInvalid('sexControl')}">
                            <mat-button-toggle value="M">
                                <mat-icon fontSet="phr-iconset" class="icon phr-iconset phr-iconset-hombre"></mat-icon>
                                <span>{{'man' | translate:locale.language}}</span>
                            </mat-button-toggle>
                            <mat-button-toggle value="F">
                                <mat-icon fontSet="phr-iconset" class="icon phr-iconset phr-iconset-mujer"></mat-icon>
                                <span>{{'women' | translate:locale.language}}</span>
                            </mat-button-toggle>                            
                        </mat-button-toggle-group>
                        <mat-error *ngIf="isControlInvalid('sexControl')">{{'shared.editUser.text10' | translate:locale.language}}</mat-error>

                    </div>
                </div>
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{'shared.editUser.text13' | translate:locale.language}}</mat-label>
                        <mat-select formControlName="countryControl">
                            <mat-option *ngFor="let availableCountry of availableCountries" [value]="availableCountry.idPais">
                                {{availableCountry.nombreI18n | i18nText:locale.language}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="isControlInvalid('nameControl')">
                            {{ 'shared.editUser.text14' | translate:locale.language }}
                        </mat-error>            
                    </mat-form-field>
                </div>
            </form>
        </div>

    </mat-card>

    <div class="buttons-container">
        <button mat-button mat-flat-button class="primary" (click)="onUserSaveClick()" [ngClass]="{'loading': saving}">
            {{'shared.editUser.text11' | translate:locale.language}}
        </button>
    </div>
</div>