import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClinicConcept } from 'src/app/shared/models/cdr/clinic-concept.model';
import { HttpResponseModel } from 'src/app/shared/models/http-response-model';
import { VirtualAttention } from 'src/app/shared/models/process/virtual-attention.model';
import { environment } from 'src/environments/environment';
import { AppStorageService, STORAGE } from './app-storage.service';
import { BaseService } from './base.service';
import { HttpBaseService } from './http-base-service';

@Injectable({
  providedIn: 'root'
})
export class VirtualAttentionService {

  constructor(
    private http: HttpBaseService,
    private baseService:BaseService,
    private appStorageService: AppStorageService
  ) { }

  getActive():Observable<VirtualAttention> {
    let idEmpresaDestinoConsultaVirtual = this.baseService.getDirectory().idEmpresaDestinoConsultaVirtual;
    let idEmpresaDestino = idEmpresaDestinoConsultaVirtual ? idEmpresaDestinoConsultaVirtual : this.baseService.getCompanyId();

    let url = "api/consultas-virtuales/activa?idEmpresaDestino=" + idEmpresaDestino

    return this.http.get<VirtualAttention>(url).pipe(map((virtualAttention: VirtualAttention) => {
        this.appStorageService.setItem(STORAGE.ACTIVE_VIRTUAL_ATTENTION, virtualAttention);

        return virtualAttention;
    }));    
  }
  
  getActiveWithCache():Observable<VirtualAttention> {
    let result = this.appStorageService.getItem(STORAGE.ACTIVE_VIRTUAL_ATTENTION)
    
    if (result)
        return of(result);

    return this.getActive();
  }

  getPastAttentions():Observable<VirtualAttention[]> {
    let idEmpresaDestinoConsultaVirtual = this.baseService.getDirectory().idEmpresaDestinoConsultaVirtual;
    let idEmpresaDestino = idEmpresaDestinoConsultaVirtual ? idEmpresaDestinoConsultaVirtual : this.baseService.getCompanyId();

    let url = "api/consultas-virtuales/pasadas?idEmpresaDestino=" + idEmpresaDestino

    return this.http.get<VirtualAttention[]>(url);
  }

  removeActiveFromCache() {
    this.appStorageService.setItem(STORAGE.ACTIVE_VIRTUAL_ATTENTION, null)
  }

  startVirtualAttention(
    idEmpresaDestino: number,
    idAreaSistemaDestino: number,
    symptoms: ClinicConcept[],
    nombreEmisor: string,
    urlImagenEmisor: string,
    email: string) : Observable<number> {
    let request = {
      IdEmpresaDestino: idEmpresaDestino,
      idAreaSistemaDestino: idAreaSistemaDestino,
      ConceptosClinicos: symptoms,
      Emisor: {
        NombreEmisor: nombreEmisor,
        UrlImagenEmisor: urlImagenEmisor,
        Email: email
      }
    };

    return this.http.post<number>("api/consultas-virtuales/inicio/online", request);
  }

  cancelVirtualAttention(idSolicitudPorVerificar: string, razon: string) {
    let url = "api/consultas-virtuales/cancelar?idSolicitudPorVerificar=" + idSolicitudPorVerificar;

    if (razon)
      url += "&razon=" + razon;

    return this.http.put<HttpResponseModel<string>>(url, {});
  }

}