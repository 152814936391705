import { PublicProfile } from "../people/public-profile.model";

export class BaseRequestModel {
    // Base
    id: number;   

    // SolicitudConsultaBase
    idResponsableServicioDestino: number;
    nombreResponsableServicioDestino: string;
    nicknameResponsableServicioDestino: string;
    urlAvatarResponsableServicioDestino: string;
    especialidadResponsableServicioDestino: string;
    perfilPublico: PublicProfile;
    tipoNotificacion: number;
    tipoSolicitud: number;
    idPersonaEmisora: number;
    nombrePersonaEmisora: string;
    emailPersonaEmisora: string;
    idEmpresaCorporativa: number;
    idEmpresaOrigen: number;
    idEmpresaDestino: number;
    idAreaSistemaDestino: number;
    localizacion: string;
    comentarioEmisor: string;
    codigoVerificacion: string;
    registrosClinicos: ClinicalRecord[];
    relacionEmisorPaciente: SenderPatientRelationship;
    pacienteSolicitante: RequestingPatient;
    usuarioSolicitante: RequestingUser;
    checkoutUrl:string;
    
    pagoDetalle: PayModel;

    emailResponsableServicio: string;
    nombreEmpresaOrigen: string;
    legacyRequestGuid: string;
    idEmpresaResponsableServicio: number;

    idEtapaSolicitud: number;
}

export class ClinicalRecord{
    idConceptoClinico: number;
    nombreConceptoClinico: string;
    idTipoConcepto: number;
    nombreTipoConcepto: string;
    valorRutaNavegacion: string;
    idFuenteReferencia: number;
    nombreFuenteReferencia: string;
    idTerminoReferencia: number;
    valorTerminoReferencia: string;
    nombreClase:  string;   
}

export class RequestingPatient{
    id: number;
    idEmpresa: number;
    denominacion: string;
    nombre: string;
    apellido: string;
    sexo: string;
    email: string;
    numeroTelefonoParticular: string;
    numeroCelularParticular: string;
    fechaNacimiento?: Date;
    idEstadoValidacionIdentidadPersona: number;
    idPaisResidencia: number;
}

export class RequestingUser{
    id: number;
    username: string;
    password: string;
    passwordSalt: string;
    passwordHash: string;
    nombre: string;
    apellido: string;
    sexo: string;
    fechaNacimiento?: Date;
    urlAvatar: string;
    idEstadoValidacionIdentidadPersona: number;
    idPaisResidencia: number;
}

export enum SenderPatientRelationship{
    OTRO = 2,
    DESCONOCIDO = 9,
    USUARIO = 12,
    HIJO_DE = 295,
    PROGENITOR_DE = 294,
    DEPENDIENTE_FAMILIAR_DE = 36,    
    ESPOSO = 480,
    HERMANO = 481,
    ABUELO = 482,
    TUTOR = 483
}

export class PayModel
{
	// Credit card data
	tipoTarjeta : string; // 'VISA'
	numeroTarjeta : string;
	anioExpiracionTarjeta : string;
	mesExpiracionTarjeta : string;
	cVVTarjeta : string;
	nombre : string;
	apellido : string;

	// Pasarela data
	idSistema : string;
	idEmpresa : string;
	idPais : string;
	idTipoPago : string;

	// Payment data
	monto : string;
	montoImpuestos : string;
	concepto : string;
	descripcion : string;

	// User data
	email : string;
	numeroTelefono : string;
	direccion : string;
}