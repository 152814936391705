import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';

@Component({
  selector: 'app-privacy-policies',
  templateUrl: './privacy-policies.component.html',
  styleUrls: ['./privacy-policies.component.css']
})
export class PrivacyPoliciesComponent implements OnInit {

  policiesControlName: string;

  constructor(
    private baseService: BaseService
  ) {}

  ngOnInit(): void {
    this.policiesControlName = this.baseService.getPoliciesControlName();
  }
}
