<div class="autocomplete-multiple-selection {{cssClass}}">
    
    <div class="select-options-container" fxLayout="column">
        <mat-form-field class="select-form-field" fxFlex="100" appearance="outline" color="accent" floatLabel="never" >
            
            <!-- Selected items -->
            <mat-chip-list #chipList>
                <mat-chip
                    color="accent"
                    *ngFor="let option of selectedOptions"
                    selectable="true"
                    removable="true"
                    (removed)="removeOption(option)">
                    <mat-icon matChipRemove>clear</mat-icon>
                    <span class="truncate">{{displayName(option)}}</span>
                </mat-chip>
                <input
                    class="select-search-input"                    
                    [placeholder]="placeholder"
                    #searchInput
                    [formControl]="searchInputCtrl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList">                
            </mat-chip-list>

            <!-- Autocomplete -->
            <mat-autocomplete #auto="matAutocomplete" class="options-dropdown-container no-shadow"
                (optionSelected)="selected($event)"
                (opened)="autocompleteOpened()"
                (closed)="autocompleteClosed()" >
                <mat-option *ngFor="let option of filteredOptions" [value]="option">
                    {{displayName(option)}}
                </mat-option>
            </mat-autocomplete>            

        </mat-form-field>          
      
    </div>

</div>