export const environment = {
    version: '3.70.1.6-hxy',
  production: true,
  apiUrl: 'https://api.cliniweb.com/',
  cloudHubUrl: "https://apps.aspsoluciones.com/CloudHubWebApi/cloudhub",
  cliniwebUrl: "https://www.cliniweb.com/",
  logLevel: 1,
  searchProfilesPageSize: 8,
  googleApiKey: 'AIzaSyAhEjpF14LorjB9dHPlSMDr0GP81hy2IAU',
  cKey: '2e35f242a46d67eeb74aabc37d5e5d05',
  googleFileViewerUrl: 'https://drive.google.com/viewerng/viewer?embedded=true&url=',
  useElasticsearchForProfiles: true,
  googleCaptchaSiteKey: '6Lf6gBUUAAAAAHb2HNwaQx5TpP6FJcTHjF-yPAjO',
  encryptLocalStorage: true,
  portalUrl: 'https://professional.cliniweb.com/',
  idEmpresaCliniwebPHR: 4243012,
  nombreEmpresaCliniwebPHR: 'cliniweb-phr',
  cliniwebPhrUrl: 'https://app.cliniweb.com/'
};
