<div class="search-providers-container" *ngIf="model">
    <div class="search-wrapper" fxLayout="row" fxLayoutAlign="center center">
        <div class="search-outline-wrapper" 
            [ngClass]="{
                'search-opened': autocompleteOpen == true,
                'location-opened': locationAutocompleteOpen == true,
                'provider-selected': searchProviderCtrl.value != null,
                'provider-object-selected': providerSelected != null,
                'location-selected': searchLocationCtrl.value != null,
                'location-object-selected': locationObjectSelected != null,
                'without-location': model.hideLocationAutocomplete
            }">
            <!-- AUTOCOMPLETE PROVIDERS -->
            <mat-form-field class="search-form-field" floatLabel="never" appearance="outline">
                <button class="btn-cancel" mat-icon-button *ngIf="showingCancelSearchProvider()" (click)="onCancelSearchProvider()">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-back"></mat-icon>
                </button>
                <mat-icon fontSet="phr-iconset" class="phr-iconset-search icon-autocomplete"></mat-icon>
                <input #searchInput
                    matInput 
                    autocomplete="off"
                    type="text"
                    [placeholder]="getPlaceholderProvider()"
                    class="search-provider-input"
                    (keydown)="onKeydownInputSearch($event)"
                    (focus)="onFocusInputSearch()"
                    (blur)="onBlurInputSearch()"
                    [formControl]="searchProviderCtrl"
                    [matAutocomplete]="auto">
                
                <button class="btn-remove" *ngIf="showCloseIcon()" mat-icon-button (click)="removeSearchProviderSelected()">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-close"></mat-icon>
                </button>
                
                <mat-spinner *ngIf="loadingSearchProvider" [diameter]="18"></mat-spinner>
                
                <mat-autocomplete #auto="matAutocomplete"
                    class="search-providers-dropdown-container"
                    (optionSelected)="profileSelected($event)"
                    (opened)="autocompleteProfileOpened()"
                    (closed)="autocompleteClosed()"
                    [displayWith]="autoCompleteProviderDisplayWith">
                    
                    <!-- Auto complete search results -->
                    <mat-optgroup *ngFor="let group of filteredResults$ | async" [label]="group.className">
                        <mat-option *ngFor="let result of group.results" [value]="result">
                            <div class="result-wrapper" [ngClass]="{'profile': showAvatarDropdownOption(result) }"> 
                                <mat-icon *ngIf="showIconDropdownOption(result)" fontSet="phr-iconset" class="{{getIconClassNameDropdownOption(result)}}"></mat-icon>

                                <!-- <app-avatar-initials class="avatar" *ngIf="showAvatarDropdownOption(result) && (isDoctor(result) || result.urlImagen)"
                                    [url]="result.urlImagen"
                                    [text]="result.nombre"
                                    [id]="result.idPerfilPublico">
                                </app-avatar-initials>
                                <div class="avatar icon" *ngIf="showAvatarDropdownOption(result) && !isDoctor(result) && !result.urlImagen">
                                    <mat-icon fontSet="phr-iconset" [ngClass]="getProfileTypeIconClassName(result)"></mat-icon>
                                </div> -->

                                <div class="avatar" *ngIf="showAvatarDropdownOption(result)">
                                    <app-public-profile-avatar [model]="getAvatarModel(result)"></app-public-profile-avatar>
                                </div>

                                <div class="text-container">
                                    <span class="title" innerHTML="{{result.nombre | highlight : highlightValue }}"></span>
                                    <span *ngIf="result.nombre != result.descripcion" class="description">{{result.descripcion}}</span>
                                </div>
                            </div>
                        </mat-option>
                    </mat-optgroup>

                </mat-autocomplete>
            </mat-form-field>

            <!-- AUTOCOMPLETE LOCATION -->
            <mat-form-field class="location-form-field" floatLabel="never" appearance="outline" *ngIf="!model.hideLocationAutocomplete">
                <button class="btn-cancel" mat-icon-button *ngIf="showingCancelSearchLocation()" (click)="onCancelSearchLocation()">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-back"></mat-icon>
                </button>
                <mat-icon fontSet="phr-iconset" class="phr-iconset-suraplace icon-autocomplete"></mat-icon>
                <input #searchLocationInput
                    matInput
                    autocomplete="off"
                    type="text"
                    [placeholder]="getPlaceholderLocation()"
                    class="search-location-input"
                    (keydown)="onKeydownInputLocationSearch($event)"
                    (focus)="onFocusInputLocationSearch()"
                    (blur)="onBlurInputLocationSearch()"
                    [formControl]="searchLocationCtrl"
                    [matAutocomplete]="autoLocation">
                <button class="btn-remove" mat-icon-button *ngIf="locationObjectSelected" (click)="removeSearchLocationSelected()">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-close"></mat-icon>
                </button>
                <mat-spinner *ngIf="loadingSearchLocation" [diameter]="18"></mat-spinner>
                <mat-autocomplete #autoLocation="matAutocomplete"
                    class="search-providers-dropdown-container"
                    (optionSelected)="locationSelected($event)"
                    (opened)="autocompleteLocationOpened()"
                    (closed)="autocompleteLocationClosed()"
                    [displayWith]="autoCompleteLocationDisplayWith">
                        <mat-optgroup *ngFor="let group of filteredLocationResults$ | async" 
                            [label]="group.displayName" 
                            [ngClass]="{'label-hidden': group.displayName == '' }">
                            <mat-option *ngFor="let result of group.results" [value]="result">
                                <div class="result-wrapper">
                                    <mat-icon fontSet="phr-iconset" *ngIf="result.icono" class="{{result.icono}}"></mat-icon>
                                    <span innerHTML="{{result.nombre | highlight : highlightValue }}"></span>
                                </div>
                            </mat-option>
                        </mat-optgroup>
                </mat-autocomplete>
            </mat-form-field>
            <div class="button-search-container">
                <button mat-button class="button-search" (click)="onClickSearchButton()">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-search"></mat-icon>
                    <span class="button-search-text">
                        {{ 'shared.searchResults.searchProviders.textSearchButton' | translate:locale.language }}
                    </span>
                </button>
            </div>

            <mat-divider class="divider-mobile"></mat-divider>
        </div>
    </div>
</div>