import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { HttpBaseService } from './http-base-service';

export enum LogLevel {
  Off = 0,
  Error = 1,
  Warning = 2,
  Info = 3,
  Verbose = 4
}

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    private http: HttpBaseService,
    private authService: AuthService
  ) { }

  logError(text:string){
    this.postLog(text, LogLevel.Error);
  }
  logWarning(text:string){
    this.postLog(text, LogLevel.Warning);
  }
  logInfo(text:string){
    this.postLog(text, LogLevel.Info);
  }
  logVerbose(text:string){
    this.postLog(text, LogLevel.Verbose);
  }

  private postLog(text:string, level:LogLevel){
    if(this.authService.isLoggedIn() && environment.logLevel == level){

      let body = {
        text: text,
        level: level
      }

      this.http.post("api/logs", body)
        .subscribe();
    }
  }
}
