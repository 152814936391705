<div class="preventive-control-tips-container">
    <mat-card class="card">
        
        <mat-card-header>
            <mat-card-title>
                {{ 'shared.preventiveControlTips.text1' | translate:locale.language }}
            </mat-card-title>           
        </mat-card-header>
        
        <mat-card-content>
            <div class="item">
                <div class="title">
                    {{ 'shared.preventiveControlTips.text2' | translate:locale.language }}
                </div>
                <div class="description">
                    {{ 'shared.preventiveControlTips.text3' | translate:locale.language }}
                </div>
                <button mat-button mat-flat-button class="secondary small" (click)="onGeneralMedicineClick()">{{'shared.preventiveControlTips.text10' | translate:locale.language}}</button>
                <div class="divider"></div>
            </div>
            <div class="item">
                <div class="title">
                    {{ 'shared.preventiveControlTips.text4' | translate:locale.language }}
                </div>
                <div class="description">
                    {{ 'shared.preventiveControlTips.text5' | translate:locale.language }}
                </div>
                <button mat-button mat-flat-button class="secondary small" (click)="onDermatologyClick()">{{'shared.preventiveControlTips.text10' | translate:locale.language}}</button>
                <div class="divider"></div>
            </div>
            <div class="item">
                <div class="title">
                    {{ 'shared.preventiveControlTips.text6' | translate:locale.language }}
                </div>
                <div class="description">
                    {{ 'shared.preventiveControlTips.text7' | translate:locale.language }}
                </div>
                <button mat-button mat-flat-button class="secondary small" (click)="onDentistClick()">{{'shared.preventiveControlTips.text10' | translate:locale.language}}</button>
                <div class="divider"></div>
            </div>
            <div class="item">
                <div class="title">
                    {{ 'shared.preventiveControlTips.text8' | translate:locale.language }}
                </div>
                <div class="description">
                    {{ 'shared.preventiveControlTips.text9' | translate:locale.language }}
                </div>
                <button mat-button mat-flat-button class="secondary small" (click)="onOphthalmologyClick()">{{'shared.preventiveControlTips.text10' | translate:locale.language}}</button>                
            </div>
        </mat-card-content>
    </mat-card>
</div>