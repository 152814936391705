import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject, of } from "rxjs";
import { IStepFlow } from "src/app/shared/interfaces/step-flow";
import { ComponentDataSetMessage, FlowStepCompletedMessage, MessageBusService } from "src/app/core/services/message-bus.service";
import { takeUntil } from "rxjs/operators";
import { IWorkflowDataState } from "src/app/shared/interfaces/workflow-data-state";
import { WorkflowItem } from "../../systems/workflow.model";

@Injectable({
    providedIn: 'root',
})
export abstract class BaseStep<T extends IWorkflowDataState> implements IStepFlow, OnDestroy {
    item: WorkflowItem;
    stateData: T;
    
    showHeader: boolean;
    showButtonBack: boolean;
    showProgressBar: boolean;
    showButtonClose: boolean;

    withoutFooter: boolean;

    attached: boolean = false;

    protected ngUnsubscribe = new Subject();
    formConfigurationData: { [componentName: string]: any; };

    constructor(
        protected messageBusService: MessageBusService,
    ){
    }    

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    
    onBeforeFormLoad(): Observable<void> {
        this.formConfigurationData = this.buildComponentConfigurationData();

        this.attachToComponentEvents();

        return of(undefined);
    }

    setItem(item: WorkflowItem): void {
        this.item = item;
    }

    private attachToComponentEvents() {
        if(this.attached)
            return;

        this.messageBusService.onSendComponentDataSetMessage()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(message => {
                if(this.messageForThisStep(message)){
                    this.handleComponentSetDataMessage(message);

                    // Send message to workflow to check if step is completed
                    this.sendFlowStepCompletedMessage();
                }
            });

        this.attached = true;
    }    

    private sendFlowStepCompletedMessage(){
        let message = new FlowStepCompletedMessage();
        message.state = this.stateData;    

        this.messageBusService.sendFlowStepCompleted(message);
    }

    /**
     * Handle components dataSet message. This message will be send by component when the component model if fully set
     * The message will contain the componentName (the component that generate the message) and the model of the component with the data.
     */
    protected abstract handleComponentSetDataMessage(message: ComponentDataSetMessage) : void;
    
    /*
    * Create base configuration data for each component
    * Return a dictionary where the key is the componentName and value is the component model
    */
    protected abstract buildComponentConfigurationData() : { [componentName: string]: any; };

    protected abstract messageForThisStep(message: ComponentDataSetMessage): boolean;

    abstract setState(stateData: IWorkflowDataState): void;
    abstract isCompleted(): Observable<boolean>;
    abstract showStepInActionBack(): boolean;
    abstract showStep(): boolean;
}