import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-virtual-consultation-tabs',
  templateUrl: './virtual-consultation-tabs.component.html',
  styleUrls: ['./virtual-consultation-tabs.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('VirtualConsultationTabsComponent')
export class VirtualConsultationTabsComponent implements IControlComponent, OnInit, OnDestroy {
  data: FormComponentData;

  private ngUnsubscribe = new Subject();
  selectedTabIndex: number;
  constructor(    
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private messageBusService: MessageBusService) {
  }


  ngOnInit(): void {

    this.messageBusService.onGoToPastVirtualRequests()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((result) => {
        // go to past virtual requests
        this.selectedTabIndex = 1;
    });
  }
  
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}