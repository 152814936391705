<div class="virtual-consultation-skeleton" *ngIf="!loaded">
    <div class="va-sk-search">
        <span>
            <ngx-skeleton-loader [theme]="{ 'width': '40%', 'height': '15px', 'margin-top': '17px','background-color': 'rgba(129, 137, 169, 0.18)', 'border-radius': '2px'}"></ngx-skeleton-loader>
        </span>
        <span class="sk-right">
            <ngx-skeleton-loader [theme]="{ 'width': '140px', 'height': '49px', 'background-color': 'rgba(129, 137, 169, 0.18)', 'border-radius': '25px'}"></ngx-skeleton-loader>
        </span>                
    </div>
    <div class="va-sk-text">
        <ngx-skeleton-loader [theme]="{ 'width': '20%', 'height': '12px', 'background-color': 'rgba(129, 137, 169, 0.18)', 'border-radius': '2px'}"></ngx-skeleton-loader>
    </div>
    <div class="va-sk-symptoms">
        <ngx-skeleton-loader [theme]="{ 'width': '15%', 'height': '14px', 'opacity': '0.3', 'margin-right': '21px', 'background-color': 'var(--accent)', 'border-radius': '2px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{ 'width': '10%', 'height': '35px', 'opacity': '0.3', 'margin-right': '8px', 'background-color': 'var(--accent)', 'border-radius': '65px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{ 'width': '13%', 'height': '35px', 'opacity': '0.3', 'margin-right': '8px', 'background-color': 'var(--accent)', 'border-radius': '65px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{ 'width': '16%', 'height': '35px', 'opacity': '0.3', 'margin-right': '8px', 'background-color': 'var(--accent)', 'border-radius': '65px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{ 'width': '20%', 'height': '35px', 'opacity': '0.3', 'margin-right': '8px', 'background-color': 'var(--accent)', 'border-radius': '65px'}"></ngx-skeleton-loader>
    </div>
    <div class="va-sk-footer-btn">
        <span class="sk-right">
            <ngx-skeleton-loader [theme]="{ 'width': '181px', 'height': '49px', 'background-color': 'rgba(129, 137, 169, 0.18)', 'border-radius': '25px'}"></ngx-skeleton-loader>    
        </span>        
    </div>
</div>

<div class="select-symptoms-container" fxLayout="column" *ngIf="!virtualAttention && loaded">
    <mat-form-field class="select-form-field" fxFlex="100" appearance="outline" color="accent" floatLabel="never" 
        [ngClass]="{ 'has-results' : existsResults && autoCompleteOpen, 'above': dropdownAbovePosition }">
        <mat-chip-list #chipList>
            <mat-chip
                color="accent"
                *ngFor="let symptom of symptoms"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="removeSymptom(symptom)">
                <mat-icon matChipRemove *ngIf="removable">clear</mat-icon>
                <span class="truncate">{{ symptom.nombre }}</span>
            </mat-chip>
            <input
                class="select-symptoms-input"
                [ngClass]="{'input-btn': symptomCtrl.value}"
                placeholder="{{ 'shared.selectSymptoms.inputPlaceholder' | translate:locale.language }}"
                #symptomInput
                [formControl]="symptomCtrl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addSymptom($event)">
            <button mat-raised-button color="primary"
                fxHide.lt-md
                (click)="addSymptomClick()"
                [disabled]="!symptomCtrl.value">
                <mat-icon>add</mat-icon>
                {{ 'shared.selectSymptoms.addButtonText' | translate:locale.language | uppercase }}
            </button>
            <button mat-button color="primary"
                fxHide.gt-sm
                (click)="addSymptomClick()"
                [fxHide.lt-md]="!symptomCtrl.value">
                <mat-icon>add</mat-icon>
                {{ 'shared.selectSymptoms.addButtonText' | translate:locale.language | uppercase }}
            </button>
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" class="symptoms-dropdown-container no-shadow"
            (optionSelected)="selected($event)"
            (opened)="autocompleteOpened()"
            (closed)="autocompleteClosed()" >
            <mat-option *ngFor="let symptom of filteredSymptoms$ | async" [value]="symptom">
                {{ symptom.nombre }}
            </mat-option>
        </mat-autocomplete>
        <mat-hint>{{ 'shared.selectSymptoms.mandatoryFieldText' | translate:locale.language }}</mat-hint>
    </mat-form-field>

    <mat-chip-list class="frequent-symptoms-container" [(ngModel)]="selectedFrequentSymptoms" multiple>
        <span>{{ 'shared.selectSymptoms.frequentSymptomsLabelText' | translate:locale.language }}</span>
        <mat-chip
            class="chip-outline accent"
            *ngFor="let frequentSymptom of frequentSymptoms"
            [selectable]="selectable"
            [selected]="isSelectedFrequentSymptom(frequentSymptom)"
            (click)="toggleFrequentSymptom(frequentSymptom)">
            {{ frequentSymptom.nombre }}
        </mat-chip>
    </mat-chip-list>
    <button mat-raised-button color="primary" class="btn-start-consultation"
        [ngClass]="{'disabled': symptoms.length == 0 && selectedFrequentSymptoms.length == 0 }"
        (click)="onClickStartConsultation()">
        {{ 'shared.selectSymptoms.startConsultationButtonText' | translate:locale.language | uppercase }}
    </button>
</div>

<div class="active-virtual-attention-container" *ngIf="virtualAttention && loaded">
    <div class="form-title accent-color" *ngIf="!hasAgentMessage && !virtualAttentionFinished">{{ 'shared.selectSymptoms.virtualAttentionQueue' | translate:locale.language }}</div>
    <div class="form-title accent-color" *ngIf="hasAgentMessage && !virtualAttentionFinished">{{ 'shared.selectSymptoms.virtualAttentionActive' | translate:locale.language }}</div>
    <div class="form-title accent-color" *ngIf="virtualAttentionFinished">{{ 'shared.selectSymptoms.finishedAttention' | translate:locale.language }}</div>

    <img class="active-va-img1" src="assets/images/sura/active-virtual-attention1.png"/>
    <img class="active-va-img2" src="assets/images/sura/active-virtual-attention2.png"/>

    <button class="tab-accept-btn" mat-raised-button color="primary" fxHide.lt-md *ngIf="virtualAttentionFinished" (click)="goToPastVirtualAttentions()" >
        {{ 'shared.selectSymptoms.acceptFinishedAttention' | translate:locale.language }}
    </button>
</div>