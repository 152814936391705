import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { RouteDialogHeaderModel } from '../../../dialogs/route-dialog/route-dialog.component';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  styleUrls: ['./default-header.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('DefaultHeaderComponent')
export class DefaultHeaderComponent implements OnInit, IControlComponent {

  constructor() { }
  data: RouteDialogHeaderModel;

  ngOnInit(): void {}

  onCloseClick(){
    this.data.closeFn();
  }

}
