<div class="attention-history-wrapper" ngClass.lt-md="mobile">
    <app-timeline-list-container [items]="timelineAttentions" [skeletonItems]="skeletonItems" [loading]="loading">

        <!-- Skeleton -->
        <ng-template let-item="item" #TimelineSkeletonContent>

            <mat-card class="attention-history-card">
                <mat-card-header class="card-header">
                    <div mat-card-avatar>
                        <span class="contact-avatar">
                            <ngx-skeleton-loader [theme]="{ 'width': '40px', 'height': '40px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '50%'}"></ngx-skeleton-loader>
                        </span>			
                    </div>
                    <mat-card-title class="title">
                        <ngx-skeleton-loader [theme]="{ 'width': '240px', 'height': '13px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>                  
                    </mat-card-title>
                    <mat-card-subtitle class="sub-title">
                        <ngx-skeleton-loader [theme]="{ 'width': '108px', 'height': '13px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>                  
                    </mat-card-subtitle>
                    <div class="link-wrapper">
                        <ngx-skeleton-loader [theme]="{ 'width': '84px', 'height': '14px', 'background-color': 'rgba(0, 174, 199, 0.3)', 'border-radius': '2px'}"></ngx-skeleton-loader>                                          
                    </div>
                </mat-card-header>                                
                <mat-card-content>
                    <div>
                        <ngx-skeleton-loader [theme]="{ 'width': '280px', 'height': '13px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>                  
                    </div>
                    <div>
                        <ngx-skeleton-loader [theme]="{ 'width': '400px', 'height': '13px', 'background-color': 'rgba(83, 86, 90, 0.14)', 'border-radius': '2px'}"></ngx-skeleton-loader>                  
                    </div>
                </mat-card-content>
            </mat-card>

        </ng-template>

        <!-- Card -->
        <ng-template let-item="item" #TimelineContent *ngIf="timelineAttentions">
            <mat-card class="attention-history-card">
                
                <!-- Header -->
                <mat-card-header class="card-header">
                    
                    <!-- Contact Info -->
                    <div mat-card-avatar>
                        <span class="contact-avatar" *ngIf="item.attention.avatarUrl">
                            <img src="{{item.attention.avatarUrl}}" />
                        </span>
                        <span class="contact-initials" *ngIf="!item.attention.avatarUrl" [style.background-color]="item.attention.colorIniciales">
                            <span class="initials">{{item.attention.iniciales}}</span>
                        </span>
                    </div>
                    <mat-card-title class="title">{{item.attention.titulo}}</mat-card-title>
                    <mat-card-subtitle class="sub-title">{{ 'shared.clinicHistory.attentionHistory.' + item.attention.claveSubtitulo | translate:locale.language }}</mat-card-subtitle>
                   
                    <!-- Links -->
                    <div class="link-wrapper">
                        <!--<a class="link share">
                            <span fxHide.lt-md="true">{{'shared.clinicHistory.share' | translate:locale.language | uppercase}}</span>
                            <mat-icon>share</mat-icon>
                        </a>-->
                        <a *ngIf="item.attention.existenConclusiones" class="link expand" (click)="item.onClickLinkViewMore()" fxHide.lt-md="true">
                            <span>{{ 'shared.clinicHistory.attentionHistory.expand' | translate:locale.language | uppercase }}</span>
                            <mat-icon [ngClass]="{'rotate': item.viewMore }" fontSet="phr-iconset" class="phr-iconset-arrown-down"></mat-icon>
                        </a>
                    </div>
                </mat-card-header>
                
                <!-- Content -->
                <mat-card-content>

                    <!-- Basic Concepts -->
                    <div class="basic-concepts-wrapper">
                        <div class="basic-concept-wrapper" *ngFor="let basicConceptGrouped of item.attention.conceptosBasicos">
                            <span>{{basicConceptGrouped.titulo}}: </span>
                            <span *ngIf="basicConceptGrouped.tieneConceptosBasicos">{{basicConceptGrouped.descripcion}}</span>
                            <div class="files-wrapper" *ngIf="basicConceptGrouped.tieneDocumentos">
                                <div class="file-item" [ngClass]="getClassPreviewFile(fileConcept)" *ngFor="let fileConcept of basicConceptGrouped.documentos">
                                    <img class="preview-image"
                                        *ngIf="getClassPreviewFile(fileConcept)"
                                        [src]="getPreviewFileUrl(fileConcept)"
                                        (click)="onFileClick(basicConceptGrouped, fileConcept)" />
                                    <div class="preview-image"
                                        *ngIf="!getClassPreviewFile(fileConcept)" 
                                        (click)="onFileClick(basicConceptGrouped, fileConcept)" 
                                        [style.background-image]="'url(' + getPreviewFileUrl(fileConcept) + ')'" >
                                    </div>
                                    <span class="file-title">{{fileConcept.nombreConcepto}}</span>
                                    <span class="file-description">{{fileConcept.nombreDocumento}}</span>
                                </div>
                            </div>
                        </div>                        
                    </div>

                    <!-- Conclusions -->
                    <a *ngIf="item.attention.existenConclusiones" class="link-view-more-mobile" (click)="item.onClickLinkViewMore()" fxHide.gt-sm="true">
                        <span *ngIf="!item.viewMore">{{ 'shared.clinicHistory.attentionHistory.viewMore' | translate:locale.language | uppercase }}</span>
                        <span *ngIf="item.viewMore">{{ 'shared.clinicHistory.attentionHistory.viewLess' | translate:locale.language | uppercase }}</span>
                        <mat-icon [ngClass]="{'rotate': item.viewMore }" fontSet="phr-iconset" class="phr-iconset-arrown-down"></mat-icon>
                    </a>
                    <div *ngIf="item.viewMore" class="conclusion-concepts-wrapper">
                        <span class="title">{{'shared.clinicHistory.attentionHistory.conclusionConceptsTitle' | translate:locale.language }}</span>
                        <div class="concept-wrapper" *ngFor="let conclusionConceptGrouped of item.attention.conceptosConclusiones">
                            <span class="title-concept">{{conclusionConceptGrouped.titulo}}</span>
                            <div *ngIf="conclusionConceptGrouped.tieneConceptosBasicos">
                                <div *ngFor="let conclusionConcept of conclusionConceptGrouped.conceptos">
                                    <p>{{conclusionConcept.descripcion}}</p>
                                </div>
                            </div>                            
                            <div class="files-wrapper" *ngIf="conclusionConceptGrouped.tieneDocumentos">
                                <div class="file-item" [ngClass]="getClassPreviewFile(fileConclusionConcept)" *ngFor="let fileConclusionConcept of conclusionConceptGrouped.documentos">
                                    <img class="preview-image"
                                        *ngIf="getClassPreviewFile(fileConclusionConcept)"
                                        [src]="getPreviewFileUrl(fileConclusionConcept)"
                                        (click)="onFileClick(conclusionConceptGrouped, fileConclusionConcept)" />
                                    <div class="preview-image"
                                        *ngIf="!getClassPreviewFile(fileConclusionConcept)" 
                                        (click)="onFileClick(conclusionConceptGrouped, fileConclusionConcept)" 
                                        [style.background-image]="'url(' + getPreviewFileUrl(fileConclusionConcept) + ')'" >
                                    </div>
                                    <span class="file-title">{{fileConclusionConcept.nombreConcepto}}</span>
                                    <span class="file-description">{{fileConclusionConcept.nombreDocumento}}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </ng-template>

        <!-- Empty State -->
        <ng-template let-item="item" #TimelineEmptyStateContent>
            <div class="empty-state-container">
                <div class="title">{{ 'shared.clinicHistory.attentionHistory.emptyStateMessage1' | translate:locale.language }}</div>
                <div class="subtitle">{{ 'shared.clinicHistory.attentionHistory.emptyStateMessage2' | translate:locale.language }}</div>
            </div>            
        </ng-template>
    </app-timeline-list-container>
</div>