<div class="provider-filters-body-container">
    <mat-chip-list class="provider-filter-wrapper" [ngClass]="{'mobile': isMobile() }" [multiple]="true" aria-label="Filter selection">
        
        <!-- Filter Item -->
        <div class="filter-item" [ngClass]="{'hide-on-desktop': filterItem.hideOnDesktop }" *ngFor="let filterItem of filterModel.items; index as i; trackBy: filterTrackBy">
            
            <!-- Chips -->
            <div class="filter-chip-container"
                *ngIf="!filterItem.isDropdown()"
                [ngClass]="{'selected':filterItem.active, 'favorite-filter': filterItem.isFavoriteFilter() }" 
                (click)="onFilterItemClick(filterItem)">
                <mat-icon fontSet="phr-iconset" class="{{filterItem.getIconClass()}}"></mat-icon> 
                <span>{{ getNameI18n(filterItem.name) }}</span>
            </div>

            <!-- Dropdown -->
            <div class="filter-autocomplete-container" *ngIf="filterItem.isDropdown()">
                <div class="filter-autocomplete">
                    <app-check-autocomplete
                        cssClass="filter-autocomplete"
                        [componentId]="filterItem.controlId"
                        [loading]="filterItem.loading"
                        [showInputSearch]="filterItem.showInputSearch"
                        [singleSelection]="filterItem.singleSelection"
                        [groups]="filterItem.groups"
                        [viewConfig]="filterItem.viewConfig"
                        [placeholder]="getNameI18n(filterItem.name)"
                        [iconClassName]="filterItem.getIconClass()"
                        [idAttributeName]="getIdAttributeName()"
                        [selectedOptions]="filterItem.selectedConcepts"
                        [optionsObs]="filterItem.getOptionsObserver()"
                        [optionFilterFtn]="filterItem.filterOptions"
                        [optionDisplayNameFtn]="filterItem.getDisplayName"
                        [optionDisplayTitleNameFtn]="filterItem.getDisplayTitleName"
                        [selectedOptionDisplayNameFtn]="filterItem.getSelectedOptionDisplayName"
                        (onAutocompleteClick)="onFilterItemClick(filterItem)"
                        (onAutocompleteOptionsChanged)="onOptionChanged($event, filterItem)">
                    </app-check-autocomplete>   
                </div>
            </div>
        </div>
    </mat-chip-list>
</div>
