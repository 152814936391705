import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { RouteDialogHeaderModel } from '../../../dialogs/route-dialog/route-dialog.component';
import { MessageBusService, NavigationBackMessage } from 'src/app/core/services/message-bus.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-default-back-header',
  templateUrl: './default-back-header.component.html',
  styleUrls: ['./default-back-header.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('DefaultBackHeaderComponent')
export class DefaultBackHeaderComponent implements OnInit, IControlComponent, OnDestroy {

  private ngUnsubscribe = new Subject();

  constructor(
    private messageBusService: MessageBusService    
  ) { }
  data: RouteDialogHeaderModel;

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onBackClick(){
    let dialogType = this.data.dialogModel.id;

    this.messageBusService.navigateBack(new NavigationBackMessage(dialogType));
  }  
}