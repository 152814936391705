import { Component, Input, OnInit } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';

@Component({
  selector: 'app-avatar-initials',
  templateUrl: './avatar-initials.component.html',
  styleUrls: ['./avatar-initials.component.css']
})
export class AvatarInitialsComponent implements OnInit {

  @Input() id:number;
  @Input() text:string;
  @Input() url:string;

  color:string;
  iniciales: string;

  constructor(private baseService: BaseService) { }

  ngOnInit(): void {
    if(!this.url){
      let avatarInitialsObject = this.baseService.getAvatarInitialObject(this.id, this.text);

      this.iniciales = avatarInitialsObject.initials;
      this.color = avatarInitialsObject.color;
    }
  }
}
