import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { ChatService } from 'src/app/core/services/chat-service';
import { BaseService } from 'src/app/core/services/base.service';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';
import { ChatConversacionMessage, ChatConversacionMessageType } from 'src/app/shared/models/people/chat-conversation.model';
import { VirtualAttention } from 'src/app/shared/models/process/virtual-attention.model';
import { UserService } from 'src/app/core/services/user.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'chat-history-dialog',
  templateUrl: './chat-history-dialog.component.html',
  styleUrls: ['./chat-history-dialog.component.css']  
})
export class ChatHistoryDialogComponent implements OnInit, OnDestroy {
  chatDate: Date;
  timeElapsed: string;
  messagesPageSize = 30;
  chatWrapper : ChatHistoryWrapper;
  etapaSolicitudId: number;
  loggedUser: UserPerson;  
  virtualAttention: VirtualAttention;  
  loading = false;

  //subscribes
  private ngUnsubscribe = new Subject();

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
      private mdDialogRef: MatDialogRef<ChatHistoryDialogComponent>,
      private baseService: BaseService,    
      private chatService: ChatService,
      private userService: UserService,
      private utilsService : UtilsService,
      @Inject(L10N_LOCALE) public locale: L10nLocale) {
        this.virtualAttention = this.baseService.parseObject<VirtualAttention>(data.data);
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadChat(this.virtualAttention.idEtapaSolicitud);
  }

  ngOnDestroy():void{
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onCloseClick() {
    this.mdDialogRef.close();
  }

  loadChat(idEtapaSolicitud: number) {
    
    // Check if the chat was already initialized
    if (this.etapaSolicitudId == idEtapaSolicitud && this.chatWrapper && this.chatWrapper.initialized)
      return;  

    this.etapaSolicitudId = idEtapaSolicitud;

    // Get current user
    this.userService.getUserPersonLoggedIn()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(logged => {
      if (logged) {
        this.loggedUser = logged;

          if (this.virtualAttention) {
            
            this.chatWrapper = new ChatHistoryWrapper(this.loggedUser, this.virtualAttention);
            /* this.chatWrapper.min = this.min;
            this.chatWrapper.hour = this.hour;*/
            this.chatWrapper.initialized = true;

            // Load chat messages
            this.loadChatMessages(this.virtualAttention.idSolicitud, 0);
          }
        }},
        error => {
          this.baseService.handleServiceError(error, "Error getting past appointments");
        }
      );

        // Load chat
        /*this.chatService.getByEtapaSolicitudId(this.etapaSolicitudId).subscribe(chat => {
          this.chat = chat;      
        });*/

  };  

  onScrollUp() {
    // Get message count
    let messageCount = this.chatWrapper.messages.length;
    
    // Load more messages
    this.loadChatMessages(this.virtualAttention.idSolicitud, messageCount, true);

    this.loading = true;
  }

  scrollBottom() {
    let _this = this;
    let el = window.document.getElementsByClassName("chat-history-msgs-container")[0];

    if (el)
      setTimeout(function() {
        _this.utilsService.scrollBottomWithAnimation(el); 
      }, 100);   
  }
  
  loadChatMessages(idSolicitud: number, from: number, upDirection = false) {
    
    this.chatService.getChatMessages(idSolicitud, from, this.messagesPageSize).subscribe(messages => {
      
      if (messages.length > 0) {
        this.chatDate = messages[0].fechaCreacion;
        this.timeElapsed = "16 hr. 20 min.";
      }

      this.chatWrapper.addMessages(messages, true, upDirection);
      this.scrollBottom();
      this.loading = false;
    },
    error => {      
      this.baseService.handleServiceError(error, "Error getting chat messages");
    });
  }
  
  getChatDate(date: Date, includeYear: boolean = false) {
    return this.baseService.getBasicDateFormat(date, includeYear);
  }
}

export class ChatHistoryWrapper {
  virtualAttention: VirtualAttention;
  messages: ChatHistoryMessageWrapper[] = [];  
  user: UserPerson;  
  hour: string;
  min: string;
  minimized: boolean = true;
  unreadMessagesCount: number = 0;
  loading = false;
  initialized = false;
  connectionLost = false;

  constructor(user: UserPerson, va: VirtualAttention) {
    this.user = user;
    this.virtualAttention = va;
    this.messages = [];
  }

  addMessages(messages: ChatConversacionMessage[], avoidUnreadCountUpdate: boolean = false, upDirection: boolean = false) {   
    
    if (!messages)
      return;

    // If we are scrolling up, the service will alway return first the oldest message
    // So in order to add it one by one (at the top) we need to revert the array
    if (upDirection)
      messages = messages.reverse();

    // Check if the msg was already added (when adding the msg the same one can came from signalR)
    for(let i = 0; i < messages.length; i++) {
      let msg = null;

      if (messages[i].id) {
        msg = this.messages.find(m => m.message.id == messages[i].id);
      }
      
      if (!msg) {

        // If the chat is minimized when adding the messages, then add this to the unread counter
        if (this.minimized && !avoidUnreadCountUpdate)
          this.unreadMessagesCount += 1;

          if (upDirection)
            this.messages.unshift(new ChatHistoryMessageWrapper(this.user, messages[i]));
          else
            this.messages.push(new ChatHistoryMessageWrapper(this.user, messages[i]));
      }      
    }
  }

  getDoctorName() {
    return this.virtualAttention.nombreResponsableServicio? 
      this.virtualAttention.nombreResponsableServicio.toLowerCase():
      "";
  }

  getChatTime() {
    let startTime = new Date(this.virtualAttention.fechaInicio).getTime();
    let now = new Date().getTime();
    let timeDiffInMin = (now - startTime) / (1000 * 60);

    if (timeDiffInMin < 60)
      return Math.floor(timeDiffInMin) + " " + this.min;
    else
      return Math.floor(timeDiffInMin / 60) + " " + this.hour;
  }

  isUserBlockMessage(currentMessageIndex: number) {
    if (currentMessageIndex == 0)
      return false;

    // Get current and previous msg
    let previous = this.messages[currentMessageIndex - 1];
    let current = this.messages[currentMessageIndex];

    // If we need to show the date stamp for this message then it is a new group
    if (this.showDateStamp(currentMessageIndex))
      return false;

    // We will only show user msg together if the previous msg was an user message and current is as well
    if (previous.isUserMessage() && current.isUserMessage())
      return true;

    return false;
  }

  isAgentBlockMessage(currentMessageIndex: number) {
    if (currentMessageIndex == 0)
      return false;

    // Get current and previous msg
    let previous = this.messages[currentMessageIndex - 1];
    let current = this.messages[currentMessageIndex];

    // If we need to show the date stamp for this message then it is a new group
    if (this.showDateStamp(currentMessageIndex))
      return false;

    // We will only show agent msg together if the previous msg was an agent message and current is as well
    if (previous.isAgentMessage() && current.isAgentMessage())
      return true;

    return false;
  }

  showDateStamp(currentMessageIndex: number) {
    if (currentMessageIndex == 0)
      return true;

     // Get current and previous msg
    let previous = new Date(this.messages[currentMessageIndex - 1].message.fechaCreacion);
    let current = new Date(this.messages[currentMessageIndex].message.fechaCreacion);
    
    // If the days are differents, then show the date time stampt
    if (previous.getDate() != current.getDate())
      return true;

    return false;
  }

  showMessageTime(currentMessageIndex: number) {
    // Only show the time for the last message
    return currentMessageIndex === (this.messages.length - 1);
  }

  getFirstMessage() : ChatConversacionMessage {
    return this.messages[0].message;
  }

  hasAgentMessages() {
    return this.messages.find(m => m.isAgentMessage()) != null;
  }
}

export class ChatHistoryMessageWrapper {
  message: ChatConversacionMessage;
  user: UserPerson;  

  constructor(user: UserPerson, message: ChatConversacionMessage) {
    this.user = user;
    this.message = message;
  }

  isUserMessage() {
    return this.message.idEmisor == this.user.id && this.message.tipo == ChatConversacionMessageType.PersonMessaje
  }

  isAgentMessage() {
    return this.message.idEmisor != this.user.id && this.message.tipo == ChatConversacionMessageType.PersonMessaje;
  }
}