<div class="patient-contact-form-container" appTouchMoveListener>
    <app-appointment-info-checkout [model]="model.appointmentInfo" [show]="showAppointmentInfo"></app-appointment-info-checkout>
    <mat-divider vertical *ngIf="showAppointmentInfo"></mat-divider>
    <div class="content">
        <span class="title">
            <span *ngIf="userPatientMode">
                {{ 'shared.patientContactFormAppointmentCheckout.title1' | translate:locale.language }}
            </span>
            <span *ngIf="!userPatientMode">
                {{ 'shared.patientContactFormAppointmentCheckout.title2' | translate:locale.language: { name: model.patientName } }}
            </span>
        </span>
        <form class="patient-contact-form" [formGroup]="patientContactForm" (ngSubmit)="onClickFinish()">
            <div class="wrapper" [ngClass]="{ 'user-patient-mode': userPatientMode }">
                <mat-radio-group formControlName="contactRadio" color="primary">
                    <mat-radio-button value="1">
                        {{'shared.patientContactFormAppointmentCheckout.radioOption1' | translate:locale.language}}
                    </mat-radio-button>
                    
                    <!--Contact user-->
                    <div class="form-wrapper" *ngIf="getControlValue('contactRadio') == '1'">
                        <mat-form-field appearance="outline" floatLabel="always" class="phone-number-container">
                            <mat-label>{{ 'shared.patientContactFormAppointmentCheckout.phoneText' | translate:locale.language }}</mat-label>
                            <ngx-mat-intl-tel-input
                                appCenterInput
                                [preferredCountries]="['pa']"
                                [enablePlaceholder]="true"
                                inputPlaceholder="{{userPhone.selectedCountry | intlTelInputPlaceholder}}"
                                [enableSearch]="false"
                                name="userPhone"
                                formControlName="userPhone"
                                #userPhone>
                            </ngx-mat-intl-tel-input>
                            <mat-error *ngIf="controlHasError('userPhone', 'required')">
                                {{ 'shared.patientContactFormAppointmentCheckout.fieldRequiredText' | translate:locale.language }}
                            </mat-error>
                            <mat-error *ngIf="isControlInvalid('userPhone') && !controlHasError('userPhone', 'required')">
                                {{ 'shared.patientContactFormAppointmentCheckout.invalidFormat' | translate:locale.language }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <mat-divider></mat-divider>
                    <mat-radio-button value="2">
                        {{'shared.patientContactFormAppointmentCheckout.radioOption2' | translate:locale.language: { name: model.patientName } }}
                    </mat-radio-button>
                    
                    <!--Contact patient-->
                    <div class="form-wrapper" *ngIf="getControlValue('contactRadio') == '2'">
                        <mat-form-field appearance="outline" floatLabel="always" class="phone-number-container">
                            <mat-label>{{ 'shared.patientContactFormAppointmentCheckout.phoneText' | translate:locale.language }}</mat-label>
                            <ngx-mat-intl-tel-input
                                appCenterInput
                                [preferredCountries]="['pa']"
                                [enablePlaceholder]="true"
                                inputPlaceholder="{{patientPhone.selectedCountry | intlTelInputPlaceholder}}"
                                [enableSearch]="false"
                                name="patientPhone"
                                formControlName="patientPhone"                                
                                #patientPhone>
                            </ngx-mat-intl-tel-input>
                            <mat-error *ngIf="controlHasError('patientPhone', 'required')">
                                {{ 'shared.patientContactFormAppointmentCheckout.fieldRequiredText' | translate:locale.language }}
                            </mat-error>
                            <mat-error *ngIf="isControlInvalid('patientPhone') && !controlHasError('patientPhone', 'required')">
                                {{ 'shared.patientContactFormAppointmentCheckout.invalidFormat' | translate:locale.language }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'shared.patientContactFormAppointmentCheckout.emailText' | translate:locale.language }}</mat-label>
                            <input #patientEmail appCenterInput matInput 
                                formControlName="patientEmail" 
                                name="patientEmail">          
                            <mat-error *ngIf="controlHasError('patientEmail', 'required')">
                                {{ 'shared.patientContactFormAppointmentCheckout.fieldRequiredText' | translate:locale.language }}
                            </mat-error>
                            <mat-error *ngIf="isControlInvalid('patientEmail') && !controlHasError('patientEmail', 'required')">
                                {{ 'shared.patientContactFormAppointmentCheckout.invalidFormat' | translate:locale.language }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </mat-radio-group>
            </div>
            <div class="button-container">
                <div class="appointment-info-mobile" (click)="openAppointmentInfoDialog()">
                    <span>{{ model.appointmentInfo.appointmentDate | date:'d MMM, h:mm a': 'UTC': language }}</span>
                    <span> • </span>
                    <span>{{ model.appointmentInfo.attentionPlaceName }}</span>
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-arrow-down-big"></mat-icon>
                </div>
                <div class="appointment-info-patient">
                    <span>Cita para {{model.patientName}} ({{getRelationship() | translate:locale.language}})</span>
                </div>
                <button mat-button mat-flat-button class="primary" [ngClass]="{'loading': loading }">
                    {{ 'shared.patientContactFormAppointmentCheckout.buttonText' | translate:locale.language }}
                </button>
            </div>
        </form>
    </div>
</div>
