import { IWorkflowDataState } from "src/app/shared/interfaces/workflow-data-state";
import { BaseStep } from "./base-step.model";
import { Observable, of } from "rxjs";
import { ComponentDataSetMessage, MessageBusService } from "src/app/core/services/message-bus.service";
import { WorkflowService } from "src/app/core/services/workflow.service";
import { Injectable } from "@angular/core";
import { ComponentName } from "src/app/shared/enums/component-name.enum";
import { PatientVerificationDataModel } from "../models/patient-verification-data.model";
import { WorkflowItemData } from "../../systems/workflow.model";
import { BaseService } from "src/app/core/services/base.service";
import { PublicProfile } from "../../people/public-profile.model";
import { AuthService } from "src/app/core/services/auth.service";
import { switchMap } from "rxjs/operators";
import { Relation } from "../../people/relation.model";
import { PatientService } from "src/app/core/services/patient.service";
import { PatientVerificationResponse } from "src/app/shared/interfaces/patient-verification-response";

@Injectable({ providedIn: 'root' })
export class PatientVerificationStep extends BaseStep<IWorkflowDataState & IPatientVerificationStep> {

    constructor(
        private patientService: PatientService,
        private authService: AuthService,
        protected messageBusService: MessageBusService, 
        private baseService: BaseService,
        private workflowService: WorkflowService
    ) {
        super(messageBusService);
        this.showHeader = true;
        this.showProgressBar = true;
        this.showButtonClose = true;
        this.withoutFooter = true;
    }

    protected handleComponentSetDataMessage(message: ComponentDataSetMessage): void {
        if (message.componentName == ComponentName.PATIENT_VERIFICATION){

            const data = message.data as PatientVerificationResponse;

            this.stateData.setPatientEmail(data.emailPaciente);
            this.stateData.setPatientValid(data.isValid);
            this.stateData.setPosition(this.stateData.getPosition() + 1);
        }
    }

    protected buildComponentConfigurationData(): { [componentName: string]: any; } {
        const formConfigurationData : { [componentName: string]: any; } = {};
        
        formConfigurationData[ComponentName.PATIENT_VERIFICATION] = this.getDataModel();

        return formConfigurationData;
    }

    private getDataModel(): PatientVerificationDataModel {
        let dataModel = new PatientVerificationDataModel();

        dataModel.idVerificacion = this.stateData.getIdVerificacion();;
        dataModel.nombrePaciente = this.stateData.getPatientName();
        dataModel.profile = this.stateData.getPublicProfile();

        if (this.item.valor) {            
            let stepItemData = this.baseService.parseObjectDeep<WorkflowItemData>(JSON.parse(this.item.valor));

            if (stepItemData.data) dataModel.configData = stepItemData.data;
        }

        return dataModel;
    }

    protected messageForThisStep(message: ComponentDataSetMessage): boolean {
        return message.componentName == ComponentName.PATIENT_VERIFICATION;
    }

    setState(stateData: IWorkflowDataState): void {
        this.stateData = this.workflowService.getWorkflowDataState();
    }

    isCompleted(): Observable<boolean> {
        return this.authService.isLoggedInFull().pipe(
            switchMap((isLoggedIn)=>{
                const userId = this.stateData.getUserPhrId();
                const patientId = this.stateData.getPatientId();

                return userId > 0
                    ? of(true)
                    : isLoggedIn && patientId > 0
                        ? this.containsRelations()
                        : of(this.stateData.getPatientValid());
        }));
    }

    showStepInActionBack(): boolean {
        return true;
    }
    
    showStep(): boolean {
        return true;
    }

    containsRelations(): Observable<boolean>{
        let relations = this.stateData.getRelations();

        if(relations){
            if(relations.length > 0){
                return of(true);
            }
            else{
                return of(this.stateData.getPatientValid());
            }
        }
        else{
            return this.patientService.getRelations(this.stateData.getPatientId()).pipe(
                switchMap((relations)=>{
                    if(relations.length > 0){
                        this.stateData.setRelations(relations);
                        return of(true);
                    }
                    else{
                        return of(this.stateData.getPatientValid());
                    }
                }));
        }
    }
}

export interface IPatientVerificationStep {

    getIdVerificacion(): string;
    getUserPhrId(): number;
    getPatientId(): number;
    getPatientName(): string;
    getPatientEmail(): string;
    getPatientValid(): boolean;
    getPublicProfile(): PublicProfile;
    getRelations(): Relation[];

    setPatientName(nombrePaciente: string): void;
    setPatientEmail(patientEmail: string): void;
    setPatientValid(valid: boolean): void;
    setRelations(relations: Relation[]): void;
}