import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppStorageService } from '../app-storage.service';
import { BaseService } from '../base.service';
import { SignalrService } from './signalr.service';
import { AppointmentEvent } from 'src/app/shared/models/signalr/appointment-event.model';

/*
It is important for this Service to NOT be a Singleton (avoid adding "providedIn: 'root'")
in order to work properly. If not all the event related to connection and re-connection get mixed between all the clients that use the service
In order to use the service on an component add it to the providers of the component like: "providers: [AppointmentSignalRService]""
*/
@Injectable()
export class AppointmentsSignalRService extends SignalrService {

  protected appointmentChangedSubject = new Subject<AppointmentEvent>();

  constructor(protected baseService: BaseService, protected appStorageService: AppStorageService) { 
    super(baseService, appStorageService);
        
    this.hubName = "CliniwebHub";
  }  

  registerOnServerEvents(): void {  

    // new appointment event received
    this.proxy.on('AppointmentChangeEvent', (appointmentEvent: AppointmentEvent) => {        
      
        console.log("SignalR: AppointmentChange event received.");
        
        this.appointmentChangedSubject.next(appointmentEvent);
    });
  }

  onAppointmentChangedReceived() : Observable<AppointmentEvent> {
    return this.appointmentChangedSubject.asObservable();
  }
}
